import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import theme from "../../../data/theme";
import { connect, useDispatch } from "react-redux";
import { Pagination, Search } from "../../DataTable";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Axios from "axios";
import { FetchListingBookingApprove } from "../../../store/ListingApprove";

function BookingListingApprove(props) {
    const dispatch = useDispatch();
    const { BookingApprove } = props
    const [BookingId, setBookingId] = useState("")
    console.log(BookingApprove)
    function ApproveBooking() {
        Axios.post(`${window.$name}/api/directory/consumer/approve/listings/booking/${BookingId}`)
            .then((response) => response.data);
        dispatch(FetchListingBookingApprove())
    };
    function DisapproveBooking() {
        Axios.post(`${window.$name}/api/directory/consumer/disapprove/listings/booking/${BookingId}`)
            .then((response) => response.data);
        dispatch(FetchListingBookingApprove())
    };
    const handleData = (id) => {
        setBookingId(id);
    }
    const Listings = (
        <>
            {BookingApprove.map((index) => (
                <tr>
                    <td>{index.booking_number}</td>
                    <td>{index.listing_title}</td>
                    <td>
                        {index.is_approved ?
                            <span className="text-success">
                                Approved
                        </span>
                            :
                            <span className="text-danger">
                                Not Approved
                        </span>
                        }
                    </td>
                    <td>
                        <Moment format="DD/MM/YYYY">
                            {index.created_at}
                        </Moment>
                    </td>
                    <td>
                        {!index.is_approved ?
                            <button
                                className="dropdown-item text-info"
                                onClick={() => handleData(index.id)}
                                data-toggle="modal"
                                data-target="#cancelModal2"
                            >
                                Approve
                        </button> :
                            <button
                            className="dropdown-item text-danger"
                            onClick={() => handleData(index.id)}
                            data-toggle="modal"
                            data-target="#cancelModal"
                        >
                           Disapprove
                    </button> 
                        }
                    </td>
                </tr>
            ))
            }

        </>

    );
    return (
        <React.Fragment>
            <Helmet>
                <title>{`add listing — ${theme.name}`}</title>
            </Helmet>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex align-items-end flex-column">
                            <div className="mt-auto p-2">
                                <Search
                                // onSearch={(value) => {
                                //     setSearch(value);
                                //     setCurrentPage(1);
                                // }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-divider" />
                    <div className="card-table">
                        <div className="table-responsive-sm">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Number</th>
                                        <th>Name</th>
                                        <th>status</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>{Listings}</tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-divider" />
                    <div className="card-footer">
                        <div className="d-flex align-items-center flex-column">
                            <Pagination
                            // total={totalItems}
                            // itemsPerPage={ITEMS_PER_PAGE}
                            // currentPage={currentPage}
                            // onPageChange={(page) => setCurrentPage(page)}
                            />
                        </div>
                    </div>
                </div>
                {/*delete listing*/}
                <div className="modal fade" id="cancelModal2" tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close"
                                    data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body"> Are you sure want to Approve this booking</div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close
                                </button>
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    onClick={ApproveBooking}
                                    className="btn btn-success">
                                    Accept
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="cancelModal" tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close"
                                    data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body"> Are you sure want to Disapprove this booking</div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close
                                </button>
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    onClick={DisapproveBooking}
                                    className="btn btn-success">
                                    Accept
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
           
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BookingListingApprove)
