// react
import React, {useEffect, useMemo, useState} from "react";
import axios from "axios";
// third-party
import {Helmet} from "react-helmet-async";
import theme from "../../../data/theme";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {connect, useDispatch, useSelector} from "react-redux";
import AddListing from "./add_listing";
import AvailableListing from "./availableLIstings";
import ListingAction from "./ListingAction";
import ListingBasicInfo from "./basicInfo";
import ListingService from "./Service";
import ListingWorkingHours from "./Working_hours";
import ListingLocation from "./ListingLocation";
import ListingImage from "./ListingImage";
import Axios from "axios";
import {customertoken} from "../../../api/shop";
import {FetchListingDetail} from "../../../store/ListingDetails/ListingDetailActions";

function Listing_Details(props) {
// const {SecureToken}=props
    const dispatch = useDispatch();
    const Token = props.location.state;
    useEffect(() => {
        // if (customertoken()) {
        dispatch(FetchListingDetail(Token));
        // }
    }, [dispatch]);
    const DataList = useSelector((state) => state.ListingDataDetail);
    // console.log(DataList)
    const [ListingsData, setListingValue] = useState([]);
    console.log(ListingsData)
    useEffect(() => {
        setListingValue(DataList);
    }, [DataList]);

    // console.log("ListingsData",ListingsData);
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Listings — ${theme.name}`}</title>
            </Helmet>
            <div className="card container mt-5">
                <Tabs>
                    <TabList>
                        <Tab>
                            <span className="text-uppercase text-dark font-size-10">Basic info</span>
                        </Tab>
                        <Tab>
                            <span className="text-uppercase text-dark font-size-10">Services</span>
                        </Tab>
                        <Tab>
                            <span className="text-uppercase text-dark font-size-10">Working Hours</span>
                        </Tab>
                        <Tab>
                            <span className="text-uppercase text-dark font-size-10">Location</span>
                        </Tab>
                        <Tab>
                            <span className="text-uppercase text-dark font-size-10">Images</span>
                        </Tab>
                    </TabList>
                    {
                        ListingsData.length <= 0 ?
                            <div className="text-center">loading dta now please wait</div>
                            :
                            <>     <TabPanel>
                                <ListingBasicInfo ListingInfo={ListingsData}/>
                            </TabPanel>
                                <TabPanel>
                                    <ListingService listing_services={ListingsData.listing.services} ListingToken={ListingsData.listing.secure_token}/>
                                </TabPanel>
                                <TabPanel>
                                    <ListingWorkingHours listing_time={ListingsData.listing.hours} schedule={ListingsData.listing.schedule}/>
                                </TabPanel>
                                <TabPanel>
                                    <ListingLocation listing_locations={ListingsData.listing_locations}/>
                                </TabPanel>
                                <TabPanel>
                                    <ListingImage listing_images={ListingsData.listing.images} ListingToken={ListingsData.listing.secure_token}/>
                                </TabPanel>
                            </>
                    }

                </Tabs>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Listing_Details)