import {DELETE_ORDER_DATA,ORDER_DATA} from "./orderActionTypes";

export default function orderReducer(state=[],action) {
    switch(action.type){
        case ORDER_DATA:
            return action.payload
        case DELETE_ORDER_DATA:
            return action.payload
        default:
            return state;
    }
}
