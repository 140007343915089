// react
import React, { useEffect, useState } from "react";
import axios from "axios";
// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import theme from "../../data/theme";
import AsyncAction from "../shared/AsyncAction";
import { connect, useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { green } from "@material-ui/core/colors";
import { Spinner } from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import { customertoken } from "../../api/shop";
import {
    AddMyShipping,
    FetchMyShipping,
    ChangeAddress,
    DeleteAddress,
    EditAddress,
} from "../../store/MyownDelivery";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
//creating the validation schema
const validationSchema = yup.object().shape({
    fullname: yup
        .string()
        .required("Please enter your full name")
        .min(4, "Fullname must be at least 4  characters"),
    idtype: yup.string().required("This field is required"),
    idnumber: yup.string().required("This field is required"),
    mobile: yup
        .string()
        .test("len", "Phone number too short must be 12 number.", (val) => {
            const val_length_without_dashes = val.replace(/-|_/g, "").length;
            return val_length_without_dashes === 13;
        })
        .required("Phone number is required!"),
});
const useStyles = makeStyles((theme) => ({
    root: {
        color: green[500],
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
}));
function AccountPageAddresses(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { AddMyShipping, ChangeAddress, DeleteAddress } = props;
    const AddressDataList = useSelector((state) => state.Myownaddresss);
    const [shippingAddress, setAddress] = useState([]);
    useEffect(() => {
        setAddress(AddressDataList);
    }, [AddressDataList]);
    // console.log(shippingAddress)
    useEffect(() => {
        if (customertoken()) {
            dispatch(FetchMyShipping());
        }
    }, [dispatch]);
    //using useFormik
    const formik = useFormik({
        initialValues: {
            fullname: "",
            mobile: "",
            idnumber: "",
            idtype: "",

        },
        validationSchema,
    });
       const [state, setState] = useState({
        fullname: "",
        mobile: "",
        idnumber: "",
        idtype: "",

    });  

    return (
        
        <div className="addresses-list">
            <Helmet>
                <title>{`Address List — ${theme.name}`}</title>
            </Helmet>
            <Link
                data-toggle="modal"
                data-target="#exampleModalLong"
                className="addresses-list__item addresses-list__item--new"
            >
                <div className="addresses-list__plus" />
                <div className="btn btn-secondary btn-sm">Add new</div>
            </Link>
            {/*{addnewaddress}*/}
            <div className="addresses-list__divider" />
            {shippingAddress.map((address) => (
                <>
                    <div className="addresses-list__item card address-card">
                        {address.is_active && (
                            <div className="address-card__badge">Active pickup</div>
                        )}
                        <div className="address-card__body">
                            <div className="address-card__name text-uppercase">
                                {address.address_fullname}
                            </div>
                            {/* <div className="address-card__row">
                                {address.country.country}
                                <br />
                                {address.region.region},{address.address_district},{address.area}
                            </div> */}
                            <div className="address-card__row">
                                <div className="address-card__row-title">Phone Number</div>
                                <div className="address-card__row-content">
                                    {address.address_mobileNumber}
                                </div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Id Type</div>
                                <div className="address-card__row-content">
                                    {address.id_type}
                                </div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Id Number</div>
                                <div className="address-card__row-content">
                                    {address.id_number}
                                </div>
                            </div>
                            <div className="address-card__footer">
                                {/*model for edit address */}
                                <Link
                                    className="text-primary"
                                    to={{
                                        pathname: "/account/addresses/5",
                                        state: []
                                    }}
                                >
                                    <i className="fa fa-edit"></i> Edit
         </Link>
         &nbsp;&nbsp;
         <AsyncAction
                                    action={() => DeleteAddress(address.secure_token)}
                                    render={({ run, loading }) => (
                                        <Link className="text-danger" onClick={run}>
                                            <i className="fa fa-trash"> </i> Delete
                                        </Link>
                                    )}
                                />
               &nbsp;&nbsp;
              <AsyncAction
                                    action={() => ChangeAddress(address.secure_token)}
                                    render={({ run, loading }) => (
                                        <Link className="text-success" onClick={run}>
                                            <i className="fa fa-check-square"> </i> Primary
                                        </Link>
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="addresses-list__divider" />
                </>

            ))}

            {/* model add new address  */}
            <div
                className="modal fade"
                id="exampleModalLong"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLongTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5
                                className="modal-title container d-flex justify-content-center"
                                id="exampleModalLongTitle"
                            >
                                ADD PICK UP DETAILS
                           </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="checkout-first-name">Full Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="fullname"
                                        value={formik.values.fullname}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.fullname && formik.errors.fullname ? (
                                        <div className="text-danger">
                                            {formik.errors.fullname}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="checkout-last-name">Phone Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="mobile"
                                        value={formik.values.mobile}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.mobile && formik.errors.mobile ? (
                                        <div className="text-danger">
                                            {formik.errors.mobile}
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="checkout-country">ID Type</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="idtype"
                                        value={formik.values.idtype}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.pidtype && formik.errors.idtype ? (
                                        <div className="text-danger">
                                            {formik.errors.idtype}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="checkout-country">
                                        ID Number
                          </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="idnumber"
                                        value={formik.values.idnumber}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.idnumber &&
                                        formik.errors.idnumber ? (
                                            <div className="text-danger">
                                                {formik.errors.idnumber}
                                            </div>
                                        ) : null}
                                </div>
                            </div>

                            <AsyncAction
                                action={() => AddMyShipping(formik.values)}
                                render={({ run, loading }) => (
                                    <button
                                        type="button"
                                        onClick={run}
                                        className={"btn btn-block btn-primary rounded "}
                                        disabled={!(formik.isValid && formik.dirty)}
                                        data-dismiss="modal"
                                    >
                                        Save Address
                                    </button>
                                )}
                            />
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                            >
                                Close
                           </button>
              -
            </div>
                    </div>
                </div>
            </div>
        </div>
        // )}
        // </div>
    );
}

const mapStateToProps = (state) => ({
    // shippingAddress: state.shippingAddress,
});
const mapDispatchToProps = {
    AddMyShipping,
    ChangeAddress,
    DeleteAddress,
    EditAddress,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPageAddresses);
