import React from 'react';
import {FaRegCalendarAlt} from 'react-icons/fa'
import '../../../assets/css/style.css'
import {useFormik} from "formik";
import * as yup from "yup";
import {connect} from "react-redux";
import {AddBooking} from "../../../store/booking/DirectoryActions";
import AsyncAction from "../../shared/AsyncAction";
import Button from "../bookmarkButton";
import Axios from "axios";
import {toast} from "react-toastify";
import {FetchListingDetail} from "../../../store/ListingDetails";

const validationSchema = yup.object().shape({
    start_date: yup
        .string()
        .required("This field is required"),
    end_date: yup
        .string()
        .required("This field is required"),
    start_time: yup
        .string()
        .required("This field is required"),
    end_time: yup
        .string()
        .required("This field is required"),
    adult: yup
        .string()
        .required("This field is required"),
    child: yup
        .string()
        .required("This field is required"),
    comment: yup
        .string()
        .required("This field is required"),
    first_name: yup
        .string()
        .required("This field is required"),
    last_name: yup
        .string()
        .required("This field is required"),
    phone_number: yup
        .string()
        .required("This field is required"),
    email: yup
        .string()
        .email('Invalid email')
        .required("This field is required"),

});

function NotLoginBooking(props) {
    const {Booking} = props
    // console.log(Booking)
    //using useFormik
    const formik = useFormik({
        initialValues: {
            listing_id: Booking.listing.id,
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            start_date: "",
            end_date: "",
            start_time: "",
            end_time: "",
            adult: "",
            child: "",
            comment: ""
        },
        validationSchema,

    });

    function MakeBookingLocal() {
        const dataNeeded={
            listing_id: Booking.listing.id,
            first_name: formik.values.first_name,
            last_name: formik.values.last_name,
            email: formik.values.email,
            phone_number: formik.values.phone_number,
            starting_date: formik.values.start_date,
            end_date: formik.values.end_date,
            starting_time: formik.values.start_time,
            end_time: formik.values.end_time,
            adult: formik.values.adult,
            child: formik.values.child,
            comment: formik.values.comment
        }
        const makeBooking = async () => {
            const data = await Axios.post(`${window.$name}/api/directory/make/booking`,dataNeeded)
                .then((response) => {
                    toast.success(` ${response.data.message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(` ${error.response.data.message}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000,
                        });
                    } else if (error.request) {
                        toast.error(` ${error.request}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000,
                        });
                    } else {
                        toast.error(` ${error.message}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000,
                        });
                    }
                });
        };
        makeBooking();
    }

    return (
        <>
            <div className="sidebar-widget date-widget">
                <h3 className="widget-title">
                    Booking
                </h3>
                <div className="title-shape"></div>
                <div className="contact-form-action padding-top-35px">

                    <form>
                        <div className="form-group">
                            <label htmlFor="inputCity">First Name</label>
                            <input
                                className="form-control"
                                onChange={formik.handleChange}
                                type="text"
                                id="first_name"
                                name="first_name"
                                onBlur={formik.handleBlur}
                                value={formik.values.first_name}
                            />
                            {formik.touched.first_name && formik.errors.first_name ? (
                                <div className="text-danger">{formik.errors.first_name}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputCity">LastName</label>
                            <input
                                className="form-control"
                                onChange={formik.handleChange}
                                type="text"
                                id="last_name"
                                name="last_name"
                                onBlur={formik.handleBlur}
                                value={formik.values.last_name}
                            />
                            {formik.touched.last_name && formik.errors.last_name ? (
                                <div className="text-danger">{formik.errors.last_name}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                             <label htmlFor="inputCity">Email</label>
                            <input
                                className="form-control"
                                onChange={formik.handleChange}
                                type="email"
                                id="email"
                                name="email"
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="text-danger">{formik.errors.email}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                              <label htmlFor="inputCity">Phone Number</label>
                            <input
                                className="form-control"
                                onChange={formik.handleChange}
                                type="text"
                                id="phone_number"
                                name="phone_number"
                                onBlur={formik.handleBlur}
                                value={formik.values.phone_number}
                            />
                            {formik.touched.phone_number && formik.errors.phone_number ? (
                                <div className="text-danger">{formik.errors.phone_number}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                                <span className="form-icon">
                                    <FaRegCalendarAlt/>
                                </span>
                            <input
                                className="date-range form-control"
                                onChange={formik.handleChange}
                                type="date"
                                id="start_date"
                                name="start_date"
                                onBlur={formik.handleBlur}
                                value={formik.values.start_date}
                            />
                            {formik.touched.start_date && formik.errors.start_date ? (
                                <div className="text-danger">{formik.errors.start_date}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                                <span className="form-icon">
                                    <FaRegCalendarAlt/>
                                </span>
                            <input
                                className="date-range form-control"
                                onChange={formik.handleChange}
                                type="date"
                                id="end_date"
                                name="end_date"
                                onBlur={formik.handleBlur}
                                value={formik.values.end_date}
                            />
                            {formik.touched.end_date && formik.errors.end_date ? (
                                <div className="text-danger">{formik.errors.end_date}</div>
                            ) : null}
                        </div>

                    </form>
                </div>
                <div className="mt-4 booking-content d-flex align-items-center justify-content-between text-center">
                    <div className="input-number-group">
                        <p>Time start</p>
                        <input
                            className="input-number"
                            type="time"
                            name="start_time"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.start_time}
                        />
                        {formik.touched.start_time && formik.errors.start_time ? (
                            <div className="text-danger">{formik.errors.start_time}</div>
                        ) : null}
                    </div>
                    <div className="input-number-group">
                        <p>Time End</p>
                        <input
                            className="input-number"
                            type="time"
                            name="end_time"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.end_time}
                        />
                        {formik.touched.end_time && formik.errors.end_time ? (
                            <div className="text-danger">{formik.errors.end_time}</div>
                        ) : null}

                    </div>
                </div>

                <div className="booking-content d-flex align-items-center justify-content-between text-center mt-4">
                    <div className="input-number-group">
                        <p>Adults</p>
                        <input
                            className="input-number"
                            type="number"
                            min="1"
                            max="1000"
                            name="adult"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.adult}
                        />
                        {formik.touched.adult && formik.errors.adult ? (
                            <div className="text-danger">{formik.errors.adult}</div>
                        ) : null}
                    </div>
                    <div className="input-number-group">
                        <p>Children</p>
                        <input
                            className="input-number"
                            type="number"
                            min="1"
                            max="1000"
                            name="child"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.child}
                        />
                        {formik.touched.child && formik.errors.child ? (
                            <div className="text-danger">{formik.errors.child}</div>
                        ) : null}
                    </div>

                </div>
                <div className="form-group mt-3">
                    <label htmlFor="inputCity">Comment</label>
                    <textarea
                        id="comment"
                        name="comment"
                        className="form-control"
                        value={formik.values.comment}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.comment && formik.errors.comment ? (
                        <div className="text-danger">{formik.errors.comment}</div>
                    ) : null}
                </div>
                <div className="mt-5">
                    <div className="booking-content d-flex align-items-center justify-content-between text-center">
                        <div className="input-number-group">
                            <button
                                type="button"
                                onClick={() =>MakeBookingLocal()}
                                className={"btn btn-block btn-primary rounded "}
                                disabled={!(formik.isValid && formik.dirty)}
                            >
                                Book Now
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    // shippingAddress: state.shippingAddress,
});
const mapDispatchToProps = {
    AddBooking
};
export default connect(mapStateToProps, mapDispatchToProps)(NotLoginBooking);
