// react
import React, { useState, useEffect } from "react";
// third-party
import { Helmet } from "react-helmet-async";
import PageHeader from "../shared/PageHeader";
import theme from "../../data/theme";
import { connect } from "react-redux";
import { RegisterUSer } from "../../store/auth";
import "./validationcss.css";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import AsyncAction from "../shared/AsyncAction";
import classNames from "classnames";
import InputMask from "react-input-mask";
import ReCAPTCHA from "react-google-recaptcha";
import {useHistory} from "react-router";
import axios from "axios";
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
//creating the validation schema
const validationSchema = yup.object().shape({
  // username: yup
  //   .string()
  //   .required("Username is required")
  //   .min(3, "Username must be at least 3 characters"),

  fullname: yup
      .string()
      .required("A FullName is required")
      .min(4, "Fullname must be at least 4  characters"),
  // email: yup.string().email().required("Email is a required field"),
  // user_phoneNumber: yup
  //   .string()
  //   .test("len", "Phone number too short must be 12 number.", (val) => {
  //   const val_length_without_dashes = val.replace(/-|_/g, "").length;
  //     return val_length_without_dashes === 13;
  //   })
  //   .required("Phone number is required!"),
  user_phoneNumber: yup
      .string()
      .required("phone number is required")
      .matches(phoneRegExp, "Phone Number is not valid")
      .min(10, "Must be at least 10 characters")
      .max(13, "Phone number is too long, Not Great than 13 characters"),
  // country: yup.string().required("Please select country"),
  // password: yup
  //   .string()
  //   .required("Please enter your New password")
  //   .matches(/^.*(?=.{6,}).*$/, "Password must contain at least 6 characters"),
  // confirmPassword: yup
  //   .string()
  //   .required("Please confirm your password")
  //   .when("password", {
  //     is: (password) => !!(password && password.length > 0),
  //     then: yup
  //       .string()
  //       .oneOf([yup.ref(" password")], "Password doesn't match"),
  //   }),
});

function AccountPageRegister(props) {
  const history=useHistory()
  const { RegisterUSer } = props;
  const [countries, setCountry] = useState([]);
  const [state, setState] = useState();
  const [regions, setRegions] = useState([]);
  // const [recaptchaValue,setRecaptcha]=useState();
  // function onChange(value) {
  //   setRecaptcha(value);
  // }
  const initialValues = {

    // username: "",
    fullname: "",
    email: "",
    user_phoneNumber: "",
    country: "",
    // password: "",
    // confirmPassword: "",
  };
  //using useFormik
  const formik = useFormik({
    initialValues,
    validationSchema,
  });

  useEffect(() => {
    const fetchData = async () => {
      const results = await axios
          .get(window.$name + "/api/adminstrator/get-countries")
          .then((response) => response.data);
      setCountry(results);
    };

    fetchData();
  }, []);

  const GetRegions = (e) => {
    const { id, value } = e.target;
    formik.values.country = e.target.value;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    axios
        .get(window.$name + "/api/adminstrator/get-regions", {
          params: {
            country_id: e.target.value,
          },
        })
        .then((res) => {
          setRegions(res.data);
        });
  };

  return (
      <React.Fragment>
        <Helmet>
          <title>{`Register — ${theme.name}`}</title>
        </Helmet>
        <PageHeader />
        <form onSubmit={formik.handleSubmit} >
          <div className="form-row">
            {/*  <div className="form-group col ">
            <label>UserName</label>
            <input
              type="text"
              className="form-control"
              id="username"
              placeholder="username"
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />

            {formik.touched.username && formik.errors.username ? (
              <div className="text-danger">{formik.errors.username}</div>
            ) : null}
          </div>*/}
            <div className="form-group col-md-12 ">
              <label>Full Name</label>
              <input
                  type="text"
                  className="form-control"
                  id="fullname"
                  placeholder="Full Name"
                  value={formik.values.fullname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
              />

              {formik.touched.fullname && formik.errors.fullname ? (
                  <div className="text-danger">{formik.errors.fullname}</div>
              ) : null}
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <label htmlFor="checkout-email">Email address</label>
              <input
                  id="email"
                  type="email"
                  className="form-control"
                  placeholder="Enter Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
              />
              {/*   {formik.touched.email && formik.errors.email ? (
              <div className="text-danger">{formik.errors.email}</div>
            ) : null}*/}
            </div>
            <div className="form-group col-md-6">
              <label htmlFor="checkout-phone">Phone</label>
              <input
                  type="tel"
                  className="form-control"
                  id="user_phoneNumber"
                  placeholder="Phone"
                  value={formik.values.user_phoneNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
              />
              {/* <InputMask*/}
              {/*   mask="+255-999999999"*/}
              {/*  onChange={formik.handleChange}*/}
              {/*  onBlur={formik.handleBlur}*/}
              {/*  value={formik.values.user_phoneNumber}*/}
              {/*>*/}
              {/*  {() => (*/}
              {/*    <input*/}
              {/*      type="text"*/}
              {/*      name="user_phoneNumber"*/}
              {/*      className="form-control"*/}
              {/*    />*/}
              {/*  )}*/}
              {/*</InputMask>*/}
              {formik.touched.user_phoneNumber &&
              formik.errors.user_phoneNumber ? (
                  <div className="text-danger">
                    {formik.errors.user_phoneNumber}
                  </div>
              ) : null}
            </div>
            <div className="form-group col-md-12">
              <select
                  className="form-control"
                  id="country"
                  name="country"
                  onChange={(e) => GetRegions(e)}
                  onBlur={formik.handleBlur}
                  value={formik.values.country}
              >
                <option value="">please select country.....</option>
                {countries.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.country}
                    </option>
                ))}
              </select>
              {/* {formik.touched.country && formik.errors.country ? (
                        <div className="text-danger">
                          {formik.errors.country}
                        </div>
                      ) : null}*/}

            </div>
          </div>

          <div className="form-row">
            {/*<div className="form-group col-md-6">*/}
            {/*  <label htmlFor="register-password">Password</label>*/}
            {/*  <input*/}
            {/*    id="password"*/}
            {/*    type="password"*/}
            {/*    className="form-control"*/}
            {/*    placeholder="Password"*/}
            {/*    value={formik.values.password}*/}
            {/*    onChange={formik.handleChange}*/}
            {/*    onBlur={formik.handleBlur}*/}
            {/*  />*/}

            {/*  {formik.touched.password && formik.errors.password ? (*/}
            {/*    <div className="text-danger">{formik.errors.password}</div>*/}
            {/*  ) : null}*/}
            {/*</div>*/}
            {/*<div className="form-group col-md-6">*/}
            {/*  <label htmlFor="register-confirm">Confirm Password</label>*/}
            {/*  <input*/}
            {/*    id="confirmPassword"*/}
            {/*    type="password"*/}
            {/*    className="form-control"*/}
            {/*    placeholder="Password"*/}
            {/*    value={formik.values.confirmPassword}*/}
            {/*    onChange={formik.handleChange}*/}
            {/*    onBlur={formik.handleBlur}*/}
            {/*  />*/}

            {/*  {formik.touched.confirmPassword && formik.errors.confirmPassword ? (*/}
            {/*    <div className="text-danger">{formik.errors.confirmPassword}</div>*/}
            {/*  ) : null}*/}
            {/*</div>*/}
            {/*<div className="form-group">*/}
            {/*  <ReCAPTCHA*/}
            {/*      className="w-full"*/}
            {/*      sitekey="6Ld50AUaAAAAAPKSmMLwuE0UhVtQ9wi9MuajGboX"*/}
            {/*      onChange={onChange}*/}
            {/*  />*/}
            {/*</div>*/}
            <AsyncAction
                action={() => RegisterUSer(formik.values,history)}
                render={({ run, loading }) => (
                    <button
                        type="submit"
                        onClick={run}
                        className={classNames("btn btn-primary rounded btn-block", {
                          "btn-loading": loading,
                        })}
                        disabled={!(formik.isValid && formik.dirty) }
                    >
                      Sign Up
                    </button>
                )}
            />
          </div>
          <div className="row mt-4 container">
            <Link to="/account/login" className="text-muted">
              Already have an account ?{" "}
              <span className="text-primary">Login now</span>
            </Link>
          </div>
        </form>
      </React.Fragment>
  );
}

const mapDispatchToProps = {
  RegisterUSer,
};

export default connect(() => ({}), mapDispatchToProps)(AccountPageRegister);
