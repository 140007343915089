// react
import React from "react";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import ShowMoreText from "react-show-more-text";

function ProductTabSpecification(props) {
  const { Product } = props;
  // console.log(Product)
  const description =ReactHtmlParser(Product.product.specs)
  return (
    <div>
      <h3>Specification</h3>
      <div className="review__text">     
        <ShowMoreText
          lines={2}
          more="Read more"
          less="Read less"
          className="content-css"
          anchorClass="my-anchor-css-class"
          expanded={false}
        >
          {description}
        </ShowMoreText>
      </div>
    </div>
  );
}

export default ProductTabSpecification;
