import { customertoken, agenttoken } from "../../api/shop";
import Axios from "axios";
import { AGENT_EDIT_PROFILE, AGENT_PROFILE_DATA } from "./AgentActionTypes";
import { toast } from "react-toastify";

export function FetchAgentprofile() {
  const token = agenttoken();
  const data = {
    secure_token: token,
  };
  return async (dispatch) =>
    Axios.post(window.$name + "/api/auth/get-agent", data)
      .then(function (response) {
        // console.log("agent", response.data);
        if (response.data.status === 401) {
          dispatch({ type: AGENT_PROFILE_DATA, payload: [] });
        } else {
          dispatch({ type: AGENT_PROFILE_DATA, payload: response.data });
        }
      })
      .catch(function (error) {
        if (error.response) {
          toast.error(
            `"${error.response.data.message}" Error Status  "${error.response.status}" `
          );
        } else if (error.request) {
          toast.error(`"${error.request}" `);
        } else {
          toast.error(`"${error.message}" `);
        }
      });
}

export function EditProfile(data) {
  const customer_token = agenttoken();
  return async (dispatch) =>
    Axios.post(window.$name + "/api/auth/edit-user-profile", data)
      .then(function (response) {
        toast.success(`"${response.data.message}" `);
        Axios.post(window.$name + "/api/auth/get-user", {
          secure_token: customer_token,
        }).then(function (response) {
          if (response.data.status === 401) {
            dispatch({ type: AGENT_EDIT_PROFILE, payload: [] });
          } else {
            dispatch({ type: AGENT_EDIT_PROFILE, payload: response.data });
          }
        });
      })
      .catch(function (error) {
        if (error.response) {
          toast.error(
            `"${error.response.data.message}" Error Status  "${error.response.status}" `
          );
        } else if (error.request) {
          toast.error(`"${error.request}" `);
        } else {
          toast.error(`"${error.message}" `);
        }
      });
}
export function AgentRegistration(data, Letter, isAlreadyRegistered,history) {
  // console.log(data.emailtrue);
  if (isAlreadyRegistered === false) {
    const values = {
      isCompany:false,
      first_name: data.firstname,
      middle_name: data.surname,
      last_name: data.lastname,
      username: data.username,
      password: data.password,
      user_phoneNumber: data.phone,
      email: data.email,
      isAlreadyRegistered: isAlreadyRegistered,
      company_registration_no: data.licensenumber,
      business_name: data.bussinessname,
      veo_image: Letter,
      PersonId_type: data.idtype,
      tin_no: data.tin,
      guarantorOneName: data.fullname1,
      guarantorOneEmail: data.email1,
      guarantorOnePhone: data.phone1,
      guarantorTwoId_type: data.idtype2,
      guarantorTwoId_No: data.idNumber2,
      guarantorTwoName: data.fullname2,
      guarantorTwoEmail: data.email2,
      guarantorTwoPhone: data.phone2,
      PersonId_No: data.idNumber,
      country: data.country,
      region: data.region,
      district: data.district,
      street: data.street,
      guarantorOneId_type: data.idtype1,
      guarantorOneId_No: data.idNumber1,
    };
    Axios.post(window.$name + "/api/agent/agent-registration", values)
      .then(function (response) {
        if (response.status === 403) {
          toast.danger(`${response.data.message}`);
        }
        else if (response.status === 201) {
          toast.success(`${response.data.message}`);
          history.push('/agent/login')
        }
      })
      .catch(function (error) {
        // if (error.response) {        
        if (error.response.status === 401 && error.response.data.email !== undefined && error.response.data.email !== "") {
          toast.error("The email has already been taken.");
        }
        else if (error.response.status === 401 && error.response.data.username !== undefined && error.response.data.username !== "") {
          toast.error("The username has already been taken.");
        }
        else if (error.response.status === 401 && error.response.data.user_phoneNumber !== undefined && error.response.data.user_phoneNumber !== "") {
          toast.error("The user phone number has already been taken.");
        }
        // }        
      });
  }
  else {
    const values = {
      isCompany:false,
      email: data.emailtrue,
      isAlreadyRegistered: isAlreadyRegistered,
      password: data.passwordtrue,
      business_name: data.bussinessname,
      company_registration_no: data.licensenumber,
      veo_image: Letter,
      PersonId_type: data.idtypetrue,
      tin_no: data.tin,
      guarantorOneName: data.fullname1,
      guarantorOneEmail: data.email1,
      guarantorOnePhone: data.phone1,
      guarantorTwoId_type: data.idtype2,
      guarantorTwoId_No: data.idNumber2,
      guarantorTwoName: data.fullname2,
      guarantorTwoEmail: data.email2,
      guarantorTwoPhone: data.phone2,
      PersonId_No: data.idNumbertrue,
      country: data.country,
      region: data.region,
      district: data.district,
      street: data.street,
      guarantorOneId_type: data.idtype1,
      guarantorOneId_No: data.idNumber1,
    };
    Axios.post(window.$name + "/api/agent/agent-registration", values)
      .then(function (response) {
        if (response.status === 401) {
          toast.warning(`${response.data.message}`);
        }
        else if (response.status === 403) {
          toast.error(`${response.data.message}`);
        }
        else if (response.status === 201) {
          toast.success(`${response.data.message}`);
          history.push('/agent/login')
        }
      })
      .catch(function (error) {
        if (error.response) {
          // console.log(error.response.data);
          toast.error(
            `"${error.response.data.message}" Error Status  "${error.response.status}" `
          );
        }
        else if (error.request) {
          toast.error(`"${error.request}" `);
        } else {
          toast.error(`"${error.message}" `);
        }
      });

  }

}

export function AgentIsCompanyRegistration(data, isAlreadyRegistered,history) {
  if (isAlreadyRegistered === true) {
    const values = {
      isCompany:true,
      password: data.password,
      email: data.email,
      isAlreadyRegistered: isAlreadyRegistered,
      company_registration_no: data.licensenumber,
      business_name: data.bussinessname,
      PersonId_type: data.idtype,
      tin_no: data.tin,
      PersonId_No: data.idNumber,
      country: data.country,
      region: data.region,
      district: data.district,
      street: data.street,
    };
    Axios.post(window.$name + "/api/agent/agent-registration", values)
        .then(function (response) {
          if (response.status === 403) {
            toast.error(`${response.data.message}`);
          }
          else if (response.status === 201) {
            toast.success(`${response.data.message}`);
            history.push('/agent/login')
          }
        })
        .catch(function (error) {
          // if (error.response) {
          if (error.response.status === 401 && error.response.data.email !== undefined && error.response.data.email !== "") {
            toast.error("The email has already been taken.");
          }
          else if (error.response.status === 401 && error.response.data.username !== undefined && error.response.data.username !== "") {
            toast.error("The username has already been taken.");
          }
          else if (error.response.status === 401 && error.response.data.user_phoneNumber !== undefined && error.response.data.user_phoneNumber !== "") {
            toast.error("The user phone number has already been taken.");
          }
          // }
        });
  }
  else {
    const values = {
      isCompany:true,
      email: data.emailtrue,
      isAlreadyRegistered: isAlreadyRegistered,
      password: data.passwordtrue,
      business_name: data.bussinessname,
      company_registration_no: data.licensenumber,
      PersonId_type: data.idtypetrue,
      tin_no: data.tin,
      first_name:data.firstname,
      middle_name:data.lastname,
      last_name:data.surname,
      username:data.username,
      user_phoneNumber:data.phone,
      PersonId_No:data.idNumbertrue,
      country: data.country,
      region: data.region,
      district: data.district,
      street: data.street,
    };
    Axios.post(window.$name + "/api/agent/agent-registration", values)
        .then(function (response) {
          if (response.status === 403) {
            toast.error(`${response.data.message}`);
          }
          else if (response.status === 201) {
            toast.success(`${response.data.message}`);
            history.push('/agent/login')
          }
        })
        .catch(function (error) {
          // if (error.response) {
          if (error.response.status === 401 && error.response.data.email !== undefined && error.response.data.email !== "") {
            toast.error("The email has already been taken.");
          }
          else if (error.response.status === 401 && error.response.data.username !== undefined && error.response.data.username !== "") {
            toast.error("The username has already been taken.");
          }
          else if (error.response.status === 401 && error.response.data.user_phoneNumber !== undefined && error.response.data.user_phoneNumber !== "") {
            toast.error("The user phone number has already been taken.");
          }
          // }
        });

  }

}

