// react
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import theme from "../../data/theme";
import Axios from "axios";
import PaymentOptioncomponent from "../shop/paymentOption";
import { connect, useDispatch, useSelector } from "react-redux";
import { FetchMasterNumber, PayViaMasterControlNumber, RemoveOrderNumber } from '../../store/masterNumber';
import CurrencyFormat from "react-currency-format";
import { Cross12Svg } from "../../svg";
import { agenttoken, PaymentToken, userCurrency } from "../../api/shop";
function AccountPagePayment(props) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(FetchMasterNumber());
    }, []);
    const { MasterNumber, PayViaMasterControlNumber, RemoveOrderNumber } = props;
    const [paymentOption_token, setState] = useState(false);
    const [Cancelnumber, setNumber] = useState([]);
    function toggle(value) {
        setState(value);
    }
    const AmountValue = MasterNumber.map(
        (amount, index) => Number(amount.order.total_amount)
    );
    const OrderNumbers = MasterNumber.map(
        (number, index) => Number(number.order.order_number)
    );
    const Totalvalue = AmountValue.reduce((a, b) => a + b, 0);
    const PayMasterNumber = {
        order_numbers: OrderNumbers,
        agent_token: agenttoken(),
        currency: userCurrency(),
        payment_option_token: PaymentToken(),
        total_amount: Totalvalue
    }


    return (
        <div>
            <Helmet>
                <title>{`Master-Payment — ${theme.name}`}</title>
            </Helmet>
            <div>
                {MasterNumber.length === 0 ? (
                    <div className="card">
                        <div className="card-header">
                            <div className="font-weight-bold d-flex justify-content-center">MASTER CONTROL NUMBER PAYMENT </div>
                           
                        </div>
                        <div className="card-divider" />
                        <div className="card-body">
                            <div className="d-flex justify-content-center">
                                <img src="images/nodata-found.png" alt="" />
                            </div>
                        </div>
                    </div>
                ) : (
                        <div className="card">
                            <div className="card-header">
                                <div className="font-weight-bold d-flex justify-content-center">MASTER CONTROL NUMBER PAYMENT </div>
                            </div>
                            {/* <div className="card-divider" /> */}
                            <div className="card-body">
                                <PaymentOptioncomponent onClick={toggle.bind(this)} />
                                <br />
                                <div className="card">
                                    <div className="card-divider" />
                                    <div className="card-table">
                                        <div className="table-responsive-sm">
                                            <table>
                                                <thead>
                                                    <tr className="text-center">
                                                        <th>Order No</th>
                                                        <th>Total VAT</th>
                                                        <th>Total Amount</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {MasterNumber.map((number) => (
                                                        <tr className="text-center">
                                                            <td>
                                                                {number.order.order_number}
                                                            </td>
                                                            <td>
                                                                <CurrencyFormat
                                                                    value={number.order.total_vat}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    renderText={(value) => (
                                                                        <div>
                                                                            {userCurrency()}{" "}{value}
                                                                        </div>
                                                                    )}
                                                                />
                                                            </td>
                                                            <td>
                                                                <CurrencyFormat
                                                                    value={number.order.total_amount}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true}
                                                                    renderText={(value) => (
                                                                        <div>
                                                                            {userCurrency()}{" "}{value}
                                                                        </div>
                                                                    )}
                                                                />
                                                            </td>
                                                            <td>
                                                                <button
                                                                    onClick={() =>
                                                                        setNumber(number.order.order_number)
                                                                    }
                                                                    type="button"
                                                                    className="btn btn-light btn-sm btn-svg-icon" data-toggle="modal"
                                                                    data-target="#cancelModal">
                                                                    <Cross12Svg />
                                                                </button>
                                                                {/* model for confirmation cancel order */}
                                                                <div className="modal fade" id="cancelModal" tabIndex="-1"
                                                                    role="dialog"
                                                                    aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                                    <div className="modal-dialog" role="document">
                                                                        <div className="modal-content">
                                                                            <div className="modal-header">
                                                                                {/*<h5 className="modal-title" id="exampleModalLabel">Modal title</h5>*/}
                                                                                <button type="button" className="close"
                                                                                    data-dismiss="modal" aria-label="Close">
                                                                                    <span aria-hidden="true">&times;</span>
                                                                                </button>
                                                                            </div>
                                                                            <div className="modal-body">
                                                                                Are you sure want to remove this order number ?
                                                                             </div>
                                                                            <div className="modal-footer">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-secondary"
                                                                                    onClick={() =>
                                                                                        setNumber([])
                                                                                    }
                                                                                    data-dismiss="modal">
                                                                                    Close
                                                                                </button>
                                                                                <button
                                                                                    type="button"
                                                                                    data-dismiss="modal"
                                                                                    onClick={() => RemoveOrderNumber(Cancelnumber)}
                                                                                    className="btn btn-danger">Accept
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="card-divider" />
                                    <tbody><br />
                                        <div className="row container">
                                            <div className="col d-flex justify-content-start">
                                                <span className="font-weight-bold text-lg">TOTAL </span >
                                            </div>
                                            <div className="col d-flex justify-content-end">
                                                <span className="font-weight-bold text-lg">
                                                    <CurrencyFormat
                                                        value={Totalvalue}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        renderText={(value) => (
                                                            <div>
                                                                {userCurrency()}{" "}{value}
                                                            </div>
                                                        )}
                                                    />
                                                </span >
                                            </div>
                                        </div>
                                        <br />
                                        {MasterNumber.length <= 1 ? (
                                            <div className="container d-flex justify-content-center">
                                                <p>To create Master control number must have more than one order added</p>
                                            </div>
                                        ) : (
                                                <div className="container d-flex justify-content-start">
                                                    <button
                                                        type="button"
                                                        onClick={() => PayViaMasterControlNumber(PayMasterNumber)}
                                                        className="btn btn-primary"
                                                        disabled={!paymentOption_token}
                                                    >
                                                        CREATE CONTROL NUMBER
                                                   </button>
                                                </div>
                                            )

                                        }




                                        <br />
                                    </tbody>
                                </div>


                            </div>
                        </div>
                    )}
            </div>
        </div >
    );
}
const mapStateToProps = (state) => ({
    MasterNumber: state.MasterNumber,

});
const mapDispatchToProps = {
    FetchMasterNumber,
    RemoveOrderNumber,
    PayViaMasterControlNumber
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountPagePayment);
