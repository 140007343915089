// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Scrollbars } from "react-custom-scrollbars";
import { Link } from 'react-router-dom';
// application
function Menu(props) {
    const {
        layout,
        withIcons,
        items,
    } = props
    
    function handleCurrency() {
        localStorage.setItem("userCurrency","");
        window.location.href = window.location.pathname;
    }    
    const classes = classNames(`menu menu--layout--${layout}`, {
        'menu--with-icons': withIcons,
    });

    return (

        <ul className={classes}>
            <Scrollbars style={{ height: 400}}>
            <Link 
            className="account-menu__links"
            onClick={()=>handleCurrency()}
            >Default</Link>
               <li>{items}</li>
            </Scrollbars>
        </ul >

    );
}

Menu.propTypes = {
    /** one of ['classic', 'topbar'] (default: 'classic') */
    layout: PropTypes.oneOf(['classic', 'topbar']),
    /** default: false */
    withIcons: PropTypes.bool,
    /** array of menu items */
    items: PropTypes.array,

};

Menu.defaultProps = {
    layout: 'classic',
    withIcons: false,
    items: [],

};

export default Menu;
