import React, {useEffect, useMemo, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import BlockLoader from "../../blocks/BlockLoader";
import Product from "./serviceProduct";
import RelatedServiceCarousel from "./RelatedServiceCarousel";
import BlockCategories from "../../blocks/BlockCategories";
import {Helmet} from "react-helmet-async";
import PageHeader from "../../shared/PageHeader";
import {url} from "../../../services/utils";
import ServiceCard from "./serviceCard";
import {useProductTabs} from "../../../services/hooks";
import AllServiceCard from "./AllServiceCard";

function AllServices(props) {
    const [isLoading, setIsLoading] = useState(true);

    let [ServiceProduct, setServiceProduct] = useState(null);
    useEffect(() => {
        let canceled = false;
        setIsLoading(true);
        axios.get(window.$name + "/api/consumer/all-service-product")
            .then(function (response) {
                    if (canceled) {
                        return;
                    }
                    ServiceProduct = response.data;
                    setServiceProduct(ServiceProduct);
                    setIsLoading(false);
                }
            )
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
        return () => {
            canceled = true;
        };
    }, [setIsLoading]);
    if (isLoading) {
        return <BlockLoader/>;
    }
    // const breadcrumb = [
    //     {title: "All Service", url: url.home()},
    // ];

    let content = (
        <React.Fragment>
            {/*<div className="block">*/}
            <div className="container">
                <div className="row">
                    {ServiceProduct.map((Values, index) => (
                        <div className="col-lg-3 mt-4">
                            <AllServiceCard product={Values}/>
                        </div>
                    ))}
                </div>
                {/*</div>*/}
            </div>
        </React.Fragment>
    )
    return (
        <React.Fragment>
            {/*<Helmet>*/}
            {/*    <title>All Service</title>*/}
            {/*</Helmet>*/}
            {/*<PageHeader breadcrumb={breadcrumb}/>*/}
            {content}
        </React.Fragment>
    );
}

export default AllServices