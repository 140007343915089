// react
import React from 'react';
import CurrencyFormat from "react-currency-format";
// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import { Cart16Svg } from '../../svg';
import { cartAddItem } from '../../store/cart';
import { url } from '../../services/utils';


function Suggestions(props) {
    const {
        context,
        className,
        products,
        cartAddItem,
        query,
        category
    } = props;
    const userCurrency = localStorage.getItem("userCurrency");
    const tokenNull="ezytrade"
    function handelquery() {
        localStorage.setItem("dataquery", query)
        localStorage.setItem("datacategory", category);
    }
    const rootClasses = classNames(`suggestions suggestions--location--${context}`, className);
    const list = (products && products.slice(0, 5).map((product) => (
        <Link
            target="_blank"
            to={`/shop/products/${product.product_token}/${product.outlets_token}/${product.Category}/${tokenNull}`}
        >
            <li key={product.id} className="suggestions__item">
                {/* {product.images && product.images.length > 0 && ( */}
                <div className="suggestions__item-image product-image">
                    <div className="product-image__body">
                        <img className="product-image__img" src={product.images} alt="" />
                    </div>
                </div>
                {/* )} */}
                <div className="suggestions__item-info">
                    {/* <Link className="suggestions__item-name" to={url.product(product)}> */}
                    {product.name}
                    {/* </Link> */}
                    <div className="suggestions__item-meta">SKU: {product.number}</div>
                </div>
                <div className="suggestions__item-price">
                    {userCurrency === product.currency ? (
                        <CurrencyFormat
                            value={Math.round(((Number(product.price) + Number(product.vat)) / Number(product.rate[product.currency])) * Number(product.rate[userCurrency]))
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                                <div>
                                    {userCurrency}{" "}{value}
                                    {/*{product.wholesale_currency} {value}*/}
                                </div>
                            )}
                        />
                    ) : (
                            <CurrencyFormat
                                value={(((Number(product.price) + Number(product.vat)) / Number(product.rate[product.currency])) * Number(product.rate[userCurrency])).toFixed(2)
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                renderText={(value) => (
                                    <div>
                                        {userCurrency}{" "}{value}
                                        {/*{product.wholesale_currency} {value}*/}
                                    </div>
                                )}
                            />
                        )
                    }

                    {/* <CurrencyFormat
                        value={product.price}
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => (
                            <div>
                                {product.currency} {value}
                            </div>
                        )}
                    /> */}
                    {/* <Currency value={product.price} /> */}
                </div>
            </li>
        </Link>
    )));

    return (
        <div className={rootClasses}>
            {
                products && products.length > 0 ? (
                    <>
                        <ul className="suggestions__list">
                            {list}
                        </ul>
                        <Link
                            onClick={() =>
                                handelquery()}
                            target="_blank"
                            to="/shop/search-products"
                            className="d-flex justify-content-center"
                        >
                            <h6 className="text-body">View More Results</h6>
                        </Link>
                    </>
                ) : (
                        <div className="d-flex justify-content-center">
                            <img src="images/nodata-found.png" alt="" />

                        </div>
                    )
            }

        </div>
    );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Suggestions);
