// react
import React, {useState} from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import Currency from '../shared/Currency';
import { Check100Svg } from '../../svg';

// data stubs
import order from '../../data/accountOrderDetails';
import theme from '../../data/theme';
import { url } from '../../services/utils';
import AsyncAction from "../shared/AsyncAction";
import {EditAddress} from "../../store/shippingAddress";


export default function ShopPayment() {
    const [state, setState] = useState(false);
     const paynowclick=()=>setState(true)

    const successOrder=JSON.parse(localStorage.getItem('success_data'));

    return (
        <div className="block order-success">
            <Helmet>
                <title>{`Order Success — ${theme.name}`}</title>
            </Helmet>
            <br/> <br/>
            <div className="container">
                <div className="order-success__body">
                    <div className="order-success__header">
                        <Check100Svg className="order-success__icon" />
                        <h1 className="order-success__title">Thank you</h1>
                        <div className="order-success__subtitle">Your order has been received Please Make payment of your order</div>
                        {/*<div className="order-success__subtitle">Payment Area </div>*/}
                    </div>
                    <div className="order-success__meta">
                        <ul className="order-success__meta-list">
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Order number:</span>
                                <span className="order-success__meta-value">{successOrder.order_number}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Total amount:</span>
                                <span className="order-success__meta-value">{`TZS ${successOrder.total_amount}`}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Payment number:</span>
                                <span className="order-success__meta-value">{successOrder.order_number}</span>
                            </li>

                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Payment method:</span>
                                <span className="order-success__meta-value">{successOrder.payment_method}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="order-success__meta">
                        <ul className="order-success__meta-list">
                            <li className="order-success__meta-item">
                                <div className="order-success__actions">
                                    <button
                                        onClick={paynowclick}
                                        className="btn btn-sm btn-secondary">
                                        Pay Now
                                    </button>
                                </div>
                            </li>
                            <li className="order-success__meta-item">
                                <div className="order-success__actions">
                                    <button className="btn btn-sm btn-secondary">Pay Later</button>
                                </div>
                            </li>

                        </ul>
                        {state? (

                                <div className="container">
                                    <br/><br/>
                                    <div className="row">
                                        <div className="col-sm">
                                            Phone Number
                                        </div>
                                        <div className="col-sm">

                                            <input
                                                type="number"
                                                className="form-control"
                                                id="phone_number"
                                                placeholder="Enter phone number"
                                                // value={address.address_mobileNumber}
                                                // onChange={formik.handleChange}
                                                // onBlur={formik.handleBlur}
                                            />
                                        </div>
                                        <div className="col-sm">
                                            <AsyncAction
                                                // action={() => EditAddress(formik.values)}
                                                render={({ run, loading }) => (
                                                    <button
                                                        type="button"
                                                        onClick={run}
                                                        className={"btn btn-sm btn-primary"}
                                                        // disabled={!(formik.isValid && formik.dirty)}
                                                    >
                                                        Pay Now
                                                    </button>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>

                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            </div>
         <br/> <br/> <br/>
        </div>
    );
}