import {agenttoken, customertoken} from "../../api/shop";
import Axios from "axios";import {toast} from "react-toastify";
import {AGENT_COMMISSION} from "./CommissionActionTypes";

export function FetchAgentCommission() {
    return async (dispatch) =>
        Axios.post(window.$name+'/api/agent/get/product/orders',{
            agent_token:agenttoken()
        })
            .then(function (response) {
                dispatch({type: AGENT_COMMISSION, payload: response.data.product_commissions});
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

