// react
import React, { useEffect, useState } from "react";
import axios from "axios";
// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import theme from "../../data/theme";
import AsyncAction from "../shared/AsyncAction";
import { connect } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import InputMask from "react-input-mask";
import {
  AddAddress,
  ChangeAddress,
  DeleteAddress,
  EditAddress,
} from "../../store/shippingAddress";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
//creating the validation schema
const validationSchema = yup.object().shape({
  fullname: yup
    .string()
    .required("Please enter your full name")
    .min(4, "Fullname must be at least 4  characters"),
  postcode:yup
        .string()
        .required("This field is required!"),
  mobile: yup
    .string()
    // .test("len", "Phone number too short must be 12 number.", (val) => {
    //   const val_length_without_dashes = val.replace(/-|_/g, "").length;
    //   return val_length_without_dashes === 13;
    // })
    .required("Phone number is required!"),
  // .min(10, "Your mobile number must be at least 10 digits")
  // .max(12, "mobile number must be less than 12 digits"),
  othermobile: yup.number("mobile number container only numeric characters"),
  country: yup.string().required("Please select country"),
  // list: yup.string().required(),
  region: yup.string().required("Please select Region"),
  district: yup.string().required("Please select District"),
  // housenumber: yup
  //     .string()
  //     .required("Please enter your house number"),
  addresstype: yup.string().required("Please select Address Type"),
  // additional: yup
  //     .string(),
});

function AccountPageAddresses(props) {
  const { AddAddress, profile, ChangeAddress, DeleteAddress } = props;

  //using useFormik
  const formik = useFormik({
    initialValues: {
      fullname: profile.fullname,
      mobile: profile.user_phoneNumber,
      othermobile: "",
      postcode: "",
      country: "",
      region: "",
      district: "",
      street: "",
      housenumber: "",
      addresstype: "",
      additional: "",
      list: "",
    },
    validationSchema,
  });

  // console.log("tag", formik.values);

  //  country select
  const [state, setState] = useState({
    fullname: "",
    mobile: "",
    othermobile: "",
    postcode: "",
    country: "",
    region: "",
    district: "",
    street: "",
    housenumber: "",
    addresstype: "",
    additional: "",
  });

  const [countries, setCountry] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const results = await axios
        .get(window.$name + "/api/adminstrator/get-countries")
        .then((response) => response.data);
      setCountry(results);
    };

    fetchData();
  }, []);

  const [regions, setRegions] = useState([]);

  const GetRegions = (e) => {
    const { id, value } = e.target;
    formik.values.country = e.target.value;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    axios
      .get(window.$name + "/api/adminstrator/get-regions", {
        params: {
          country_id: e.target.value,
        },
      })
      .then((res) => {
        setRegions(res.data);
      });
  };

  const [district, setDistrict] = useState([]);

  const GetDistrict = (e) => {
    const { id, value } = e.target;
    formik.values.region = e.target.value;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    axios
      .get(window.$name + "/api/adminstrator/get-districts", {
        params: {
          region_id: e.target.value,
        },
      })
      .then((res) => {
        setDistrict(res.data);
      });
  };

  //  fetch shipping address
  const { shippingAddress } = props;
  // let addnewaddress
  // if (shippingAddress || shippingAddress === null || shippingAddress.length === 0) {
  //
  //         addnewaddress = (<Link data-toggle="modal"
  //                                data-target="#exampleModalLong"
  //                                className="addresses-list__item addresses-list__item--new">
  //                 <div className="addresses-list__plus"/>
  //                 <div className="btn btn-secondary btn-sm">Add Address</div>
  //
  //             </Link>
  //         )
  // }

  const addresses = shippingAddress.user_address.map((address) => (
    <React.Fragment key={address.id}>    
        <div className="addresses-list__item card address-card">
          {address.is_active && (
            <div className="address-card__badge">Active Address</div>
          )}
          <div className="address-card__body">
            <div className="address-card__name text-uppercase">
              {address.address_fullname}
            </div>
            <div className="address-card__row">
              {address.country.country}
              <br />
              {address.region.region},{address.address_district},{address.area}
             
            </div>
            <div className="address-card__row">
              <div className="address-card__row-title">Phone Number</div>
              <div className="address-card__row-content">
                {address.address_mobileNumber}
              </div>
            </div>
            <div className="address-card__row">
              <div className="address-card__row-title">Address Type</div>
              <div className="address-card__row-content">
              {address.address_AddressType}
              </div>
            </div>
            <div className="address-card__footer">
              
              <Link
                className="text-primary"
                to={{
                  pathname: "/account/addresses/5",
                  state: [address]
                }}
              >
                <i className="fa fa-edit"></i> Edit
            </Link>
            &nbsp;&nbsp;
            <AsyncAction
                action={() => DeleteAddress(address.secure_token)}
                render={({ run, loading }) => (
                  <Link className="text-danger" onClick={run}>
                    <i className="fa fa-trash"> </i> Delete
                  </Link>
                )}
              />
            &nbsp;&nbsp;
            <AsyncAction
                action={() => ChangeAddress(address.secure_token)}
                render={({ run, loading }) => (
                  <Link className="text-success" onClick={run}>
                    <i className="fa fa-check-square"> </i> Primary
                  </Link>
                )}
              />
            &nbsp;&nbsp; &nbsp;&nbsp;
            <Link
                className="font-weight-semi-bold"
                to={{ pathname: "/account/address-details", state: [address] }}
              >
                <i className="text-success fa fa-eye"> </i>{" "}
                <a className="text-primary">More Details</a>
              </Link>
            </div>
          </div>
        </div>    
      <div className="addresses-list__divider" />
      {/*}*/}
    </React.Fragment>
  ));
  return (
    <div className="addresses-list">
      <Helmet>
        <title>{`Address List — ${theme.name}`}</title>
      </Helmet>
      <Link
        data-toggle="modal"
        data-target="#exampleModalLong"
        className="addresses-list__item addresses-list__item--new"
      >
        <div className="addresses-list__plus" />
        <div className="btn btn-secondary btn-sm">Add Address</div>
      </Link>
      {/*{addnewaddress}*/}
      <div className="addresses-list__divider" />
      {addresses}
      {/* model add new address  */}
      <div
        className="modal fade"
        id="exampleModalLong"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title container d-flex justify-content-center"
                id="exampleModalLongTitle"
              >
                ADD SHIPPING ADDRESS
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card mb-lg-0 shadow-lg rounded">
                <div className="card-body">
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-first-name">Full Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullname"
                        value={formik.values.fullname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />

                      {formik.touched.fullname && formik.errors.fullname ? (
                        <div className="text-danger">
                          {formik.errors.fullname}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-last-name">Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="mobile"
                        value={formik.values.mobile}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />

                      {/* <InputMask
                        mask="+255-999999999"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.mobile}
                      >
                        {() => (
                          <input
                            id="mobile"
                            type="text"
                            name="mobile"
                            className="form-control"
                          />
                        )}
                      </InputMask> */}
                      {formik.touched.mobile && formik.errors.mobile ? (
                        <div className="text-danger">
                          {formik.errors.mobile}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-country">
                        Alternative phone Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="othermobile"
                        value={formik.values.othermobile}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.othermobile &&
                        formik.errors.othermobile ? (
                          <div className="text-danger">
                            {formik.errors.othermobile}
                          </div>
                        ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-country">Post Code</label>
                      <input
                        type="text"
                        className="form-control"
                        id="postcode"
                        value={formik.values.postcode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.postcode && formik.errors.postcode ? (
                        <div className="text-danger">
                          {formik.errors.postcode}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="checkout-country">Country</label>
                    <select
                      className="form-control"
                      id="country"
                      name="country"
                      onChange={(e) => GetRegions(e)}
                      onBlur={formik.handleBlur}
                      value={formik.values.country}
                    >
                      <option value="">please select country.....</option>
                      {countries.map((country) => (
                        <option key={country.id} value={country.id}>
                          {country.country}
                        </option>
                      ))}
                    </select>
                    {formik.touched.country && formik.errors.country ? (
                      <div className="text-danger">{formik.errors.country}</div>
                    ) : null}
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-country">Region</label>
                      <select
                        onChange={(e) => GetDistrict(e)}
                        id="region"
                        className="form-control"
                        name="region"
                        onBlur={formik.handleBlur}
                        value={formik.values.region}
                      >
                        <option value="">Select a Regions...</option>
                        {regions.map((reg) => (
                          <option key={reg.id} value={reg.id}>
                            {reg.region}
                          </option>
                        ))}
                      </select>
                      {formik.touched.region && formik.errors.region ? (
                        <div className="text-danger">
                          {formik.errors.region}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-country">District</label>
                      <select
                        onChange={formik.handleChange}
                        id="district"
                        className="form-control"
                        name="district"
                        onBlur={formik.handleBlur}
                        value={formik.values.district}
                      >
                        <option value="">Select a district...</option>
                        {district.map((dist) => (
                          <option key={dist.id} value={dist.district}>
                            {dist.district}
                          </option>
                        ))}
                      </select>
                      {formik.touched.district && formik.errors.district ? (
                        <div className="text-danger">
                          {formik.errors.district}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="checkout-street-address">Street</label>
                    <input
                      type="text"
                      className="form-control"
                      id="street"
                      placeholder="Street Address"
                      value={formik.values.street}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.street && formik.errors.street ? (
                      <div className="text-danger">{formik.errors.street}</div>
                    ) : null}
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-email">House Number</label>
                      <input
                        type="email"
                        className="form-control"
                        id="housenumber"
                        value={formik.values.housenumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />

                      {formik.touched.housenumber &&
                        formik.errors.housenumber ? (
                          <div className="text-danger">
                            {formik.errors.housenumber}
                          </div>
                        ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-phone">Address Type</label>
                      <select
                        onChange={formik.handleChange}
                        id="addresstype"
                        name="addresstype"
                        className="form-control"
                        value={formik.values.addresstype}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Select Address Type...</option>
                        <option>Home</option>
                        <option>Office</option>
                      </select>
                      {formik.touched.addresstype &&
                        formik.errors.addresstype ? (
                          <div className="text-danger">
                            {formik.errors.addresstype}
                          </div>
                        ) : null}
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="checkout-street-address">
                      Additional Delivery Information (
                      <small> Not Compulsory </small>)
                    </label>
                    <textarea
                      type="text"
                      className="form-control mt-2"
                      id="additional"
                      placeholder="Additional delivery information"
                      value={formik.values.additional}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />

                    {formik.touched.additional && formik.errors.additional ? (
                      <div className="text-danger">
                        {formik.errors.additional}
                      </div>
                    ) : null}
                  </div>
                  <AsyncAction
                    action={() => AddAddress(formik.values)}
                    render={({ run, loading }) => (
                      <button
                        type="button"
                        onClick={run}
                        className={"btn btn-block btn-primary rounded "}
                        data-dismiss="modal"
                        disabled={!(formik.isValid && formik.dirty)}
                      >
                        Save Address
                      </button>
                    )}
                  />
                </div>
              </div>{" "}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              -
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  shippingAddress: state.shippingAddress,
  profile: state.profile,
});
const mapDispatchToProps = {
  AddAddress,
  ChangeAddress,
  DeleteAddress,
  EditAddress,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountPageAddresses);
