// react
import React from "react";
// third-party
import PropTypes from "prop-types";
import {Link} from "react-router-dom";

// application
import NavPanel from "./NavPanel";
import Search from "./Search";
import Topbar from "./Topbar";
import {WhatsAppSvg, CallSvg} from '../../svg';
import ServiceSearch from "./ServiceSearch";

function Header(props) {
    const {layout} = props;
    let bannerSection;

    if (layout === "default") {
        bannerSection = (
            <div className="site-header__middle container">
                <div className="site-header__logo">
                    <Link to="/">
                        <img width="190px" alt="logo" src="../images/logos/logo2.png"/>
                    </Link>
                </div>
                <div className="site-header__search">
                    {window.location.pathname === '/services-home' ||
                    window.location.pathname === '/all-searched-service' ||
                    window.location.pathname === '/services-product' ?
                        <ServiceSearch context="header"/>
                        :
                        <Search context="header"/>

                    }
                </div>
                <div className="site-header__phone text-muted">
                    {/*<div className="site-header__phone-title">Call for Help</div>*/}
                    {/*<div className="site-header">+255 717 309 346</div>*/}
                    <div className="row"><CallSvg/>  &nbsp;&nbsp; <span className="mt-1">+255 800 750 170</span></div>
                    <div className="row"><WhatsAppSvg/>  &nbsp;&nbsp; <span className="mt-1">+255 743 779 999</span>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="site-header">
            <Topbar/>
            {bannerSection}
            <div className="site-header__nav-panel">
                <NavPanel layout={layout}/>
            </div>
        </div>
    );
}

Header.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(["default", "compact"]),
};

Header.defaultProps = {
    layout: "default",
};

export default Header;
