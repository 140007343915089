import Axios from "axios";
// import { RATING_DATA } from "./ratingActionTypes";
import { toast } from "react-toastify";


export function Rate(rating,product,buyer,seller,rate) {  
  const data = {
    buyer_token:buyer,
    product_token:product,
    title:rating.title,
    description:rating.description,
    rating:rate,
    seller_token:seller,
  };
// console.log(data)
  return async (dispatch) =>
    Axios.post(window.$name + "/api/review/add-review", data)
      .then(function (response) {
        toast.success(`${response.data.message}`);
      })
      .catch(function (error) {
        if (error.response) {
          toast.error(
            `"${error.response.data.message}" Error Status  "${error.response.status}" `
          );
        } else if (error.request) {
          toast.error(`"${error.request}" `);
        } else {
          toast.error(`"${error.message}" `);
        }
      });

    }
