// react
import React from 'react';
// third-party
import {Helmet} from 'react-helmet-async';
// application
// data stubs
import theme from '../../data/theme';

function SitePageIprpp() {
    return (
        <div className="block about-us">
            <Helmet>
                <title>{`Supplemental Services Agreement — ${theme.name}`}</title>
            </Helmet>
            <div className="about-us__image" style={{backgroundImage: 'url("")'}}/>
            <div>
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                        <div className="about-us__body">
                            <h1 className="about-us__title">Supplemental Services Agreement</h1>
                            <h5 className="d-flex justify-content-center">Effective as of January 6, 2020</h5>
                            <p>
                                This services agreement (the “Agreement”) is made between you, as a User (as defined
                                below), and the
                                Ezytrade.africa entity listed below (“Ezytrade.africa” or “we”), which supplements the
                                Ezytrade.africa
                                Transaction Services Agreement regarding your use of the Ezytrade.africa Supplemental
                                Services (as
                                defined below) provided to registered members of the www.ezytrade,africa online site
                                (“Ezytrade.africa
                                Site”). You are contracting with Ezytrade Africa Limited (incorporated in Tanzania with
                                Company Reg.
                                No. 139807197) and its affiliate(s) which provides technical support in connection with
                                the transaction
                                services utilizing its strengths in network technologies, etc.
                            </p>
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left" type="button"
                                                    data-toggle="collapse" data-target="#collapseOne"
                                                    aria-expanded="true" aria-controls="collapseOne">
                                                1. Acceptance of Terms
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                1.1 You acknowledge that you have read this Agreement prior to your use
                                                of Ezytrade.africa
                                                Supplemental Services. Each time you use Ezytrade.africa Supplemental
                                                Services you confirm that you
                                                agree to be bound by the terms and conditions of this Agreement and any
                                                subsequent amendments or
                                                modifications as may be made from time to time.
                                            </p>
                                            <p>
                                                1.1 You acknowledge that you have read this Agreement prior to your use
                                                of Ezytrade.africa
                                                Supplemental Services. Each time you use Ezytrade.africa Supplemental
                                                Services you confirm that you
                                                agree to be bound by the terms and conditions of this Agreement and any
                                                subsequent amendments or
                                                modifications as may be made from time to time.
                                            </p>
                                            <p> 1.2 As some or part of the Ezytrade.africa Supplemental Services may be
                                                supported and provided by
                                                affiliates of Ezytrade.africa, Ezytrade.africa may delegate some of the
                                                Ezytrade.africa Supplemental
                                                Services to its affiliates.
                                            </p>
                                            <p>
                                                1.3 We may amend this Agreement any time by posting an updated version
                                                at www.ezytrade.africa
                                                (the “Ezytrade.africa Site”). The updated version of this Agreement
                                                shall take effect immediately upon
                                                posting. By continuing to use the Ezytrade.africa Supplemental Services,
                                                you agree to that the
                                                amended terms will apply to you.
                                            </p>


                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseTwo"
                                                    aria-expanded="false" aria-controls="collapseTwo">
                                                2.Ezytrade.africa Supplemental Services
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                2.1 The “Ezytrade.africa Supplemental Services” are services provided by
                                                Ezytrade.africa and our
                                                affiliates to facilitate payments in connection with the online
                                                transactions concluded on and through theEzytrade.africa. These
                                                transactions (“Online Transactions”) are subject to the Ezytrade.africa
                                                Transaction Services Agreement . Unless otherwise defined in this
                                                Agreement, the capitalized terms
                                                shall have the same meanings as used in the Ezytrade.africa Transaction
                                                Services Agreement.
                                            </p>
                                            <p>
                                                2.2 The Ezytrade.africa Supplemental Services are only available to the
                                                registered members of the
                                                Ezytrade.africa Sites (“Users”). A User who purchases or acquires any
                                                product or service in an Online
                                                Transaction is referred to also as a “Buyer”, and a User who sells or
                                                provides any product or service in an
                                                Online Transaction is also referred to as a “Seller”. If your
                                                subscription to any membership services of
                                                the Ezytrade.africa Sites expires or is terminated for any reason, you
                                                are not eligible to use the
                                                Ezytrade.africa Supplemental Services to conclude online transactions on
                                                Ezytrade.africa Sites. In
                                                addition, Ezytrade.africa is not obliged to provide the Ezytrade.africa
                                                Supplemental Services where the
                                                bank account designated by a Seller in an Online Transaction to receive
                                                the transaction price has not
                                                been verified and confirmed by Ezytrade.africa and our affiliates.
                                            </p>
                                            <p>
                                                2.3 Ezytrade.africa shall have the right to refuse to provide the
                                                Ezytrade.africa Supplemental Services
                                                for any Online Transaction if (a) the Online Transaction does not
                                                satisfy the terms and conditions in the
                                                Ezytrade.africa Transaction Services Agreement or the other applicable
                                                terms, rules and policies
                                                concerning Ezytrade.africa’s transaction services under the
                                                Ezytrade.africa Transaction Services
                                                Agreement in accordance with Ezytrade.africa’s instructions or (b) if
                                                Ezytrade.africa has reason to
                                                believe that the Online Transaction may violate any laws, rules or
                                                regulations or may otherwise subject
                                                Ezytrade.africa or any of our affiliates for liability.
                                            </p>
                                            <p>
                                                2.4 You agree that Ezytrade.africa shall have the sole and absolute
                                                discretion to remit funds subject to
                                                the terms of this Agreement.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseThree"
                                                    aria-expanded="false" aria-controls="collapseThree">

                                                3. Ezytrade.africa Supplemental Services for Online Transactions
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                3.1 Buyer of an Online Transaction shall pay the full transaction price
                                                listed for the Online Transaction
                                                to the Seller through the Ezytrade.africa Website unless another option
                                                is made available directly by
                                                Ezytrade.africa on the Ezytrade.africa Site. When using Ezytrade.africa
                                                to submit payment for an
                                                Ezytrade.africa Online Transaction, payments are processed through
                                                accounts owned by Ezytrade.africa
                                                or one of its affiliates and/or a registered third party service
                                                provider acting on Ezytrade.africa’s behalf.
                                                The funds are received for the Seller in accordance with the
                                                Ezytrade.africa Transaction Services
                                                Agreement. The Seller agrees that the Buyer’s full payment of the
                                                transaction price listed for the OnlineTransaction to Ezytrade.africa
                                                constitutes final payment to the Seller and Buyer’s payment obligation
                                                for
                                                the Online Transaction is fully satisfied upon receipt of funds by
                                                Ezytrade.africa’s account. The payment
                                                must be made in currencies as supported by Ezytrade.africa from time to
                                                time.

                                            </p>
                                            <p>
                                                3.2 Upon receipt of the Buyer’s payment, Ezytrade.africa shall promptly
                                                notify Ezytrade.africa Sites of
                                                the details of the payment. Ezytrade.africa shall also monitor and keep
                                                safe custody of the received
                                                funds and shall not release the funds unless an event set forth in
                                                clause 3.3 below occurs.
                                            </p>
                                            <p>
                                                3.3 Ezytrade.africa shall retain the funds received in connection with
                                                an Online Transaction unless, as
                                                agreed between Seller and Ezytrade.africa, any of the following events
                                                occurs:
                                            </p>
                                            <p>
                                                (a) in case of the successful completion of the Online Transaction upon
                                                Buyer’s confirmation, all the
                                                funds will be remitted to Seller;
                                            </p>
                                            <p>
                                                (b) in case of absence of the Buyer‘s confirmation of receipt of the
                                                goods within the time limit
                                                prescribed by Seller and as agreed by Ezytrade.africa, all the funds
                                                will be remitted to Seller;
                                            </p>
                                            <p>
                                                (c) in case of the cancellation of the Online Transaction, all the funds
                                                will be refunded to Buyer;
                                            </p>
                                            <p>
                                                (d) in case of any settlement agreement reached by Buyer and Seller, the
                                                funds will be disposed in
                                                accordance with such settlement agreement;
                                            </p>
                                            <p>
                                                (e) in case that a dispute in relation to Transaction Services has been
                                                submitted to Ezytrade.africa Sites
                                                for Ezytrade.africa’s determination and Ezytrade.africa’s determination
                                                has become final and binding
                                                according to clause 10 of the Ezytrade.africa Transaction Services
                                                Agreement , the funds will be disposed
                                                in accordance with Ezytrade.africa’s determination; or
                                            </p>
                                            <p>
                                                (f) if Ezytrade.africa or our affiliates receives any order, ruling,
                                                award or judgment from a competent
                                                court, arbitration tribunal or authority which directs us to release the
                                                funds, the funds will be disposed
                                                in accordance with such order, ruling, award or judgment.
                                            </p>
                                            <p>
                                                Ezytrade.africa is not holding any funds on behalf of Buyer, or in any
                                                escrow or trust relationship. Seller
                                                has requested that the settlement of funds to Seller be delayed as
                                                provided in this clause 3.3.
                                            </p>
                                            <p>
                                                3.4 You acknowledge that Ezytrade.africa may also provide buyer
                                                protection plan for certain Online
                                                Transactions. In case of Seller who has been offered to subscribe the
                                                buyer protection plan, upon
                                                entering into a separate agreement with Ezytrade.africa, Seller may be
                                                required to provide deposits
                                                using the methods as designated by Ezytrade.africa on the
                                                Ezytrade.africa Sites to secure Seller’s due
                                                performance of obligations under the relevant buyer protection plan.
                                                Seller agrees to permit and
                                                hereby authorize Ezytrade.africa, which in turn instructs
                                                Ezytrade.africa to deduct, withhold and dispose
                                                any deposits provided in accordance with the terms under the relevant
                                                buyer protection plan. Buyer
                                                acknowledges and agrees that the protection afforded to you under a
                                                buyer protection plan applies to
                                                those Online Transactions where the Seller subscribed to such plan and
                                                the purchase falls within the
                                                buyer protection plan’s scope. Services described under clause 3.3 of
                                                this Agreement will not be
                                                applicable to you if Seller subscribed to the buyer protection plan and
                                                such plan already covers your
                                                purchase.
                                            </p>
                                            <p>
                                                3.5 When releasing any funds, Ezytrade.africa shall have the right to
                                                deduct any financial charges or
                                                service fees due and payable to Ezytrade.africa (if any) in such amount
                                                as instructed by Ezytrade.africa
                                                pursuant to the agreement between Seller and Ezytrade.africa.
                                            </p>
                                            <p>
                                                3.6 In the event that the Buyer makes payment in a currency that is
                                                different from the currency stated in
                                                the transaction price of the Online Transaction, any refund made by
                                                Ezytrade.africa to the Buyer
                                                pursuant to this Agreement shall be made in the currency used by the
                                                Buyer in the Online Transaction,
                                                at the exchange rate utilized by Ezytrade.africa to convert from the
                                                currency stated in the transaction
                                                price as at the date of refund (or such other applicable date), less any
                                                applicable charges or fees set out
                                                in Clause 3.5 of this Agreement. Due to currency fluctuations, such
                                                amount refunded may be less than
                                                the amount paid by the Buyer in the Online Transaction, and
                                                Ezytrade.africa shall not be liable to any
                                                party for any difference in the amount refunded attributable to currency
                                                conversion costs or exchange
                                                rate movements.
                                            </p>
                                            <p>
                                                3.7 Unclaimed funds will be held by Ezytrade.africa for a period of five
                                                years as instructed by
                                                Ezytrade.africa, at which time, the Seller will be deemed to have waived
                                                any claim in respect of such
                                                funds or, if required by applicable escheat laws, we will remit the
                                                funds (less any fees, if any and to the
                                                extent permitted under applicable laws) to the relevant competent
                                                authority. You hereby agree that
                                                upon expiry of such period or upon remittance of such funds to the
                                                relevant competent authority in
                                                accordance to the applicable escheat laws, Ezytrade.africa will be
                                                relieved of any further obligation to
                                                pay those unclaimed funds to you.
                                            </p>
                                            <p>
                                                3.8 You acknowledge and agree that Ezytrade.africa may receive interest
                                                for the funds held on behalf
                                                of you pursuant to Ezytrade.africa Supplemental Services rendered to you
                                                in accordance with applicable
                                                laws and you will not receive interest or other profits in relation to
                                                the Ezytrade.africa Supplemental
                                                Services.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseFour"
                                                    aria-expanded="false" aria-controls="collapseFour">
                                                4. Users’ Responsibilities
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFour" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                4.1 All Online Transactions are concluded by and between Users only. You
                                                shall complete Online
                                                Transactions in accordance with the Ezytrade.africa Transaction Services
                                                Agreement and Ezytrade.africa
                                                Sites’ terms, rules and policies. You agree that you will not hold
                                                Ezytrade.africa and our affiliates or
                                                agents liable for any Online Transaction or any products or services
                                                supplied under any Online
                                                Transaction.
                                            </p>
                                            <p>4.2 You agree to give all notices, provide all necessary information,
                                                materials and approval, and render
                                                all reasonable assistance and cooperation necessary for
                                                Ezytrade.africa’s provision of the Ezytrade.africa
                                                Supplemental Services. If your failure to do so results in any delay in
                                                the provision of any Ezytrade.africa
                                                Supplemental Services or cancellation of any Online Transaction,
                                                Ezytrade.africa and our affiliates shall
                                                not be liable for any loss or damages arising from such delay.
                                            </p>
                                            <p> 4.3 You represent and warrant that:</p>

                                            <p>
                                                (a) you will use Ezytrade.africa Supplemental Services in good faith and
                                                in compliance with all applicable
                                                laws and regulations;
                                            </p>
                                            <p>
                                                (b) the information and material you provide in connection with the use
                                                of the Ezytrade.africa
                                                Supplemental Services is true, lawful and accurate, and is not false,
                                                misleading or deceptive;
                                            </p>
                                            <p>
                                                (c) you will not use the Ezytrade.africa Supplemental Services to
                                                defraud Ezytrade.africa, our affiliates
                                                or other members or users or engage in other unlawful activities
                                                (including without limitation dealing in
                                                products or services prohibited by law);
                                            </p>

                                            <p>
                                                (d) in case that you are a Seller of products, you have the legitimate
                                                right and authorization to sell,
                                                distribute or export the products using the Transaction Services and
                                                such products do not infringe any
                                                third party’s rights;
                                            </p>
                                            <p>
                                                (e) in case that you are a Seller of products, you have good title to
                                                the products ordered under the
                                                Online Transaction, and the products meet the agreed descriptions and
                                                requirements; and(f) in case that you are a Seller of services, you will
                                                provide the services ordered with reasonable care
                                                and skills.
                                            </p>

                                            <p>
                                                4.4 If in Ezytrade.africa’s opinion, any User is not acting in good
                                                faith, abusing the Ezytrade.africa
                                                Supplemental Services, or in breach of this Agreement, Ezytrade.africa
                                                shall have the right to cancel the
                                                Online Transaction.
                                            </p>
                                            <p>
                                                4.5 You as the User shall be solely responsible for payment of any
                                                taxes, duties or other governmental
                                                levies or any financial charges that may be imposed on any products or
                                                services purchased or supplied
                                                through the Ezytrade.africa Supplemental Services or otherwise arising
                                                from the Online Transaction.
                                            </p>

                                            <p>
                                                4.6 You agree to indemnify Ezytrade.africa and our affiliates and our
                                                employees, directors, officers,
                                                agents and representatives and to hold them harmless, from any and all
                                                losses, damages, actions, claims
                                                and liabilities (including legal costs on a full indemnity basis) which
                                                may arise, directly or indirectly, from
                                                your use of the Ezytrade.africa Supplemental Services or from User’s
                                                breach of this Agreement.
                                                Ezytrade.africa reserves the right, at our own expense, to assume the
                                                exclusive defense and control of
                                                any matter otherwise subject to indemnification by User, in which event
                                                User shall cooperate with
                                                Ezytrade.africa in asserting any available defenses.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseFive"
                                                    aria-expanded="false" aria-controls="collapseFive">
                                                5. Personal Data Privacy
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFive" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                5.1 You agree that we may collect, hold, use and transfer your personal
                                                data in accordance with the
                                                Privacy Policy of the Ezytrade.africa Sites.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseSix"
                                                    aria-expanded="false" aria-controls="collapseSix">
                                                6. Suspension of Systems
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseSix" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                6.1 If you are unable to use the Ezytrade.africa Supplemental Services
                                                directly or indirectly due to any
                                                of the following reasons, you agree you will not hold Ezytrade.africa
                                                its affiliates or agents liable for any
                                                default, delay or failure in performing its obligations under this
                                                Agreement:
                                            </p>
                                            <p>
                                                (a) system suspension which has been announced by Ezytrade.africa in
                                                advance;

                                            </p>
                                            <p>
                                                (b) any Force Majeure Event (as defined under Clause 8).
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseSeven"
                                                    aria-expanded="false" aria-controls="collapseSeven">
                                                7. Disclaimer and Limitation of Liability
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseSeven" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                7.1 Ezytrade.africa will only be liable for the obligations expressly
                                                set forth in this Agreement.
                                            </p>
                                            <p>
                                                7.2 You agree that all risks arising from the Online Transaction will be
                                                borne by you and your
                                                counter party.
                                            </p>
                                            <p>
                                                7.3 Users’ information is provided by Users themselves. Ezytrade.africa
                                                makes no representation or
                                                warranty with respect to the accuracy, truthfulness and completeness of
                                                the Users’ information. You will
                                                be solely responsible for all consequences resulting from your own
                                                judgment and decision to use or
                                                otherwise rely on such information.
                                            </p>
                                            <p>
                                                7.4 Ezytrade.africa makes no warranty regarding the Ezytrade.africa
                                                Supplemental Services or any
                                                products or services supplied by the sellers under any Online
                                                Transaction, including but not limited to:
                                            </p>
                                            <p>
                                                (a) the Ezytrade.africa Supplemental Services will meet your
                                                requirements;
                                            </p>
                                            <p>
                                                (b) the Ezytrade.africa Supplemental Services will be uninterrupted,
                                                timely or error free; OR
                                            </p>
                                            <p>(c) any products, information or material obtained by you in connection
                                                with the Ezytrade.africa
                                                Supplemental Services will meet your requirements.</p>

                                            <p> 7.5 Any proposal or material that you may obtain from Ezytrade.africa or
                                                our staff or through the use
                                                of the Ezytrade.africa Supplemental Services, whether in writing or
                                                oral, shall not constitute
                                                Ezytrade.africa’s warranty regarding the Ezytrade.africa Supplemental
                                                Services.</p>

                                            <p> 7.6 EXCEPT AS EXPRESSLY PROVIDED IN THIS AGREEMENT AND TO THE FULL
                                                EXTENT PERMITTED BY
                                                LAW THE SERVICES ARE PROVIDED ON THE "AS IS", "AS AVAILABLE" AND “WITH
                                                ALL FAULTS” BASES.ANY AND ALL SUCH WARRANTIES, REPRESENTATIONS,
                                                CONDITIONS, UNDERTAKINGS AND TERMS,
                                                WHETHER EXPRESS OR IMPLIED, ARE HEREBY DISCLAIMED AND EXCLUDED.</p>

                                            <p> 7.7 TO THE FULL EXTENT PERMITTED BY LAW, EZYTRADE.AFRICA AND OUR
                                                AFFILIATES SHALL NOT BE
                                                LIABLE FOR ANY INDIRECT, CONSEQUENTIAL, INCIDENTAL, SPECIAL OR PUNITIVE
                                                DAMAGES, INCLUDING
                                                WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS OR REVENUES, BUSINESS
                                                INTERRUPTION, LOSS
                                                OF BUSINESS OPPORTUNITIES, LOSS OF DATA OR LOSS OF OTHER ECONOMIC
                                                INTERESTS, WHETHER IN
                                                CONTRACT, NEGLIGENCE, TORT OR OTHERWISE, ARISING FROM THE USE OF OR
                                                INABILITY TO USE THE
                                                EZYTRADE.AFRICA SUPPLEMENTAL SERVICES.</p>

                                            <p> 7.8 THE AGGREGATE LIABILITY OF EZYTRADE.AFRICA AND OUR AFFILIATES
                                                ARISING FROM THE
                                                EZYTRADE.AFRICA SUPPLEMENTAL SERVICES IN CONNECTION WITH ANY ONLINE
                                                TRANSACTION SHALL
                                                NOT EXCEED THE TOTAL REMUNERATIONS THAT EZYTRADE.AFRICA MAY DERIVE FROM
                                                SUCH
                                                TRANSACTION.</p>

                                            <p> 7.9 By using the Ezytrade.africa Supplemental Services, you acknowledge
                                                and agree that Ezytrade.africa
                                                is not a bank and the Ezytrade.africa Supplemental Services should in no
                                                way be construed as the
                                                provision of banking services. Ezytrade.africa is not acting as a
                                                trustee, fiduciary or escrow with respect
                                                to User’s funds and it does not have control of, nor liability for, the
                                                products or services that are paid for
                                                with the Ezytrade.africa Supplemental Services. Ezytrade.africa does not
                                                guarantee the identity of any
                                                User or ensure that a Buyer or a Seller will complete a transaction on
                                                Ezytrade.africa Sites.
                                            </p>
                                            <p> 7.10 Some states do not allow the exclusion of implied warranties or the
                                                limitation or exclusion of
                                                liability for incidental or consequential damages, so the foregoing
                                                exclusions or limitations may not
                                                apply to you. You may also have other rights that vary from state to
                                                state.</p>

                                            <p>
                                                7.11 If you have a dispute with other parties, you release us (and our
                                                affiliates and officers, directors,
                                                agents, and employees thereof) from claims, demands and damages (actual
                                                and consequential) of every
                                                kind and nature, known and unknown, arising out of or in any way
                                                connected with such disputes. If you
                                                are a California resident, you agree to waive California Civil Code
                                                Section 1542, which states, in part: “A
                                                general release does not extend to claims which a creditor does not know
                                                or suspect to exist in his or
                                                her favor at the time of executing the release, which if not known by
                                                him or her must have materially
                                                affected his or her settlement with the debtor.”
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseEight"
                                                    aria-expanded="false" aria-controls="collapseEight">
                                                8. Force Majeure
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseEight" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                No party, its affiliates or agents shall be held liable for any default, delay or failure in performing its
                                                obligations under this Agreement resulting directly or indirectly from acts of nature, forces or causes
                                                beyond such party’s, its affiliates or agents’ reasonable control, including without limitation, (i) a fire,
                                                flood, elements of nature or other acts of God, (ii) an outbreak or escalation of hostilities, war, riots or
                                                civil disorders, or an act of terrorism; (iii) Internet failures, computer, telecommunications, electrical
                                                power failures or any other equipment failures; (iv) a labor dispute (whether or not employees’
                                                demands are reasonable or within the party’s power to satisfy), (v) acts or omissions of a government
                                                authority prohibiting or impeding the affected party (or its affiliates or agents) from performing its
                                                obligations under this Agreement, including orders of domestic or foreign courts or tribunals,
                                                governmental restrictions, sanctions, restrictions on foreign exchange controls, etc. or (vi) the
                                                nonperformance by a third party for any similar cause beyond the reasonable control of the Party
                                                (collectively, a “Force Majeure Event”). If a Force Majeure Event occurs, the non-performing party will
                                                be excused from any further performance of the obligations affected by the event only for as long as the
                                                Force Majeure Event continues and the party continues to use commercially reasonable efforts to
                                                resume performance.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseNine"
                                                    aria-expanded="false" aria-controls="collapseNine">
                                                9. Jurisdiction
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseNine" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                9.1 This Agreement shall be interpreted, construed and enforced in all respects in accordance with the
                                                laws of the United Republic of Tanzania. Except as otherwise stipulated under applicable law, you
                                                irrevocably consent to the exclusive jurisdiction of the courts of the United Republic of Tanzania, in
                                                connection with any action to enforce the provisions of this Agreement, to recover damages or other
                                                relief for breach or default under this Agreement, or otherwise arising under or by reason of this
                                                Agreement.
                                            </p>
                                            <p>
                                                9.2 If any dispute or claim arises from or in connection with this Agreement, an Online Transaction or
                                                your use of the Ezytrade.africa Supplemental Services (“Dispute”), the relevant parties shall resolve the
                                                Dispute through amicable negotiations. If any Dispute arises between Buyer and Seller in connection
                                                with an Online Transaction, you agree that such Dispute shall be resolved in accordance with the
                                                procedures set forth in clause 10 of the Ezytrade.africa Transaction Services Agreement only. If you
                                                initiate any legal proceedings against Ezytrade.africa or our affiliates in breach of clause 10 of the
                                                Ezytrade.africa Transaction Services Agreement, you shall indemnify and hold Ezytrade.africa and our
                                                affiliates, agents, employees, directors, officers and agents harmless and indemnified against any claim,
                                                losses, damages that may be suffered by us.
                                            </p>

                                                <p>
                                                9.3 In any event, you may not make any claim against Ezytrade.africa or our affiliates under this
                                                Agreement after one year from the date of occurrence of the matter giving rise to the claim.
                                                </p>
                                            <p>
                                                9.4 Notwithstanding the foregoing provisions, either party may seek injunctive or other equitable relief
                                                against the other party in any court of competent jurisdiction prior to or during the arbitration.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseTen"
                                                    aria-expanded="false" aria-controls="collapseTen">
                                                10. General Provisions
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTen" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                10.1 If any provision of this Agreement is held to be invalid or unenforceable, such provision shall be
                                                deleted and the remaining provisions shall be enforced.
                                            </p>
                                            <p>
                                                10.2 Headings are for reference purposes only and in no way define, limit, construe or describe the
                                                scope or extent of such section.
                                            </p>
                                            <p>
                                                10.3 No agency, partnership, joint venture, employee-employer or franchiser-franchisee relationship is
                                                intended or created by this Agreement.
                                            </p>
                                            <p>
                                                10.4 Any failure by Ezytrade.africa to exercise any of our rights under this Agreement shall not
                                                constitute a waiver of such right or a waiver with respect to subsequent or similar breach. A waiver shall
                                                be effective only if made in writing.
                                            </p>
                                            <p>
                                                10.5 Ezytrade.africa shall have the right to assign this Agreement (including all of our rights, titles,
                                                benefits, interests, and obligations and duties in this Agreement) to any of our affiliates and to any
                                                successor in interest. Ezytrade.africa may delegate certain of Ezytrade.africa rights and responsibilities
                                                under this Agreement to independent contractors or other third parties. User may not assign, in whole
                                                or part, this Agreement to any person or entity.
                                            </p>
                                            <p>
                                                10.6 If there is any conflict between the English version and another language version of this Agreement,
                                                the English version shall prevail.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SitePageIprpp;
