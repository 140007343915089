import {customertoken} from "../../api/shop";
import Axios from "axios";
import {EDIT_PROFILE, PROFILE_DATA} from "./profileActionType"
import {toast} from "react-toastify";

export function Fetchprofile() {
    const customer_token = customertoken();
return async (dispatch) =>
        Axios.post(window.$name + '/api/auth/get-user', {
            secure_token: customer_token
        })
            .then(function (response) {
                if (response.data.status === 401) {
                    dispatch({type: PROFILE_DATA, payload: null});
                } else {
                    dispatch({type: PROFILE_DATA, payload: response.data});
                }
            })
            .catch(function (error) {
                if (error.response) {
                    dispatch({type: PROFILE_DATA, payload: null});
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    dispatch({type: PROFILE_DATA, payload: null});
                    toast.error(`"${error.request}" `);
                } else {
                    dispatch({type: PROFILE_DATA, payload: null});
                    toast.error(`"${error.message}" `);
                }
            });
}
export function EditProfile(data) {
    const customer_token = customertoken();
    return async (dispatch) =>
        Axios.post(window.$name + '/api/auth/edit-user-profile',
            data
        )
            .then(function (response) {
                toast.success(
                    `"${response.data.message}" `
                );
                Axios.post(window.$name + '/api/auth/get-user', {
                    secure_token: customer_token
                })
                    .then(function (response) {

                        dispatch({type: EDIT_PROFILE, payload: response.data});
                    })
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

