// react
import React, { Component } from 'react';
// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
// application
import { Link } from 'react-router-dom';
import Menu from './currencyMenu';
import { ArrowRoundedDown7x5Svg } from '../../svg';
class Dropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
        };
    }
    componentDidMount() {
        document.addEventListener('mousedown', this.handleOutsideClick);
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleOutsideClick);
    }
    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };
    handleOutsideClick = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState(() => ({
                open: false,
            }));
        }
    };
    handleButtonClick = () => {
        this.setState((state) => ({
            open: !state.open,
        }));
    };
    render() {
        const { open } = this.state;
        const { title, withIcons, items, } = this.props;
        function handleCurrency(key) {
            localStorage.setItem("userCurrency", key);
            window.location.href = window.location.pathname;
        }
        const classes = classNames('topbar-dropdown', {
            'topbar-dropdown--opened': open,
        });

        return (
            <div className={classes} ref={this.setWrapperRef}>
                <button className="topbar-dropdown__btn" type="button" onClick={this.handleButtonClick}>
                    {title}
                    <ArrowRoundedDown7x5Svg />
                </button>

                <div className="topbar-dropdown__body account-menu__links">
                    <Menu
                        layout="topbar"
                        withIcons={withIcons}
                        items={
                            Object.keys(items).map((key, i) => (
                                <span className="row container"
                                    key={i}
                                    onClick={() => handleCurrency(key)}
                                >  
                                   
                                   <Link className="account-menu__links">  {key}</Link>
                                    
                                    </span>
                            ))}
                    />
                </div>
            </div>
        );
    }
}

Dropdown.propTypes = {
    /** title */
    title: PropTypes.node.isRequired,
    /** array of menu items */
    items: PropTypes.array.isRequired,
    /** default: false */
    withIcons: PropTypes.bool,
    /** callback function that is called when the item is clicked */
    onClick: PropTypes.func,
};

Dropdown.defaultProps = {
    withIcons: false,
    onClick: undefined,
};

export default Dropdown;
