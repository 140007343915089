import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import theme from "../../data/theme";
import CurrencyFormat from "react-currency-format";
import Moment from "react-moment";
import Axios from "axios";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
function MasterViewOrders(props) {
    const MasterNumber = props.location.state;
    const [order, setOrders] = useState([]);
    const [Cartdata, setCart] = useState([]);
    useEffect(() => {
        const orderList = async () => {
            const data = await Axios.post(`${window.$name}/api/agent/get/master-control-order/detail`, {
                control_number: MasterNumber,
            })
                .then((response) => response.data);
            setOrders(data);
        };
        orderList();
    }, []);
    // console.log(order)
    return (
        <div className="block order-success">
            <Helmet>
                <title>{`Order Success — ${theme.name}`}</title>
            </Helmet>
            <div className="container">
                {order.length <= 0 ? (
                    <div>
                        <span className="d-flex justify-content-center">
                            <Spinner size="xl" />
                        </span>
                        <span className="d-flex mt-10 justify-content-center font-weight-bold">
                            Data Loading...
                        </span>
                    </div>
                ) : (
                        <div className="order-success__body">
                            <div className="order-success__meta">
                                <ul className="order-success__meta-list">
                                    <li className="order-success__meta-item">
                                        <span className="order-success__meta-title">
                                            Master Control Number:
                                       </span>
                                        <span className="order-success__meta-value">
                                            {order.master_control_number}
                                        </span>
                                    </li>
                                    <li className="order-success__meta-item">
                                        <span className="order-success__meta-title">Created at:</span>
                                        <span className="order-success__meta-value">
                                            <Moment format="DD/MM/YYYY">{order.created_at}</Moment>
                                        </span>
                                    </li>
                                    <li className="order-success__meta-item">
                                        <span className="order-success__meta-title">Total:</span>
                                        <span className="order-success__meta-value">
                                            <CurrencyFormat
                                                value={order.amount_paid}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                renderText={(value) => (
                                                    <div>
                                                        {" "}
                                                        {order.currency} {"  "} {value}
                                                    </div>
                                                )}
                                            />
                                        </span>
                                    </li>
                                    <li className="order-success__meta-item">
                                        <span className="order-success__meta-title">Status:</span>
                                        <>
                                            {order.payment_status === "Pending" ? (
                                                <span className=" text-danger order-success__meta-value">
                                                    Pending
                                                </span>
                                            ) : order.payment_status === "Paid" ? (
                                                <span className="text-success order-success__meta-value">
                                                    Paid
                                                </span>
                                            ) : order.payment_status === "Shipped" ? (
                                                <span className="text-warning order-success__meta-value">
                                                    Shipped
                                                </span>
                                            ) : (
                                                            <span className="text-primary order-success__meta-value">
                                                                Delivered
                                                            </span>
                                                        )}
                                        </>
                                    </li>
                                </ul>
                            </div>
                            <div className="cardTop card">
                                <div className="card-table">
                                    <div className="table-responsive-sm">
                                        <table>
                                            <thead>
                                                <tr className="text-center">
                                                    <th>Order Number</th>
                                                    <th>Amount</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {order.orders.map((item) => (
                                                    <tr className="text-center">
                                                        <td>  {item.order_number}</td>
                                                        <td>
                                                            <CurrencyFormat
                                                                value={item.total_amount}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                renderText={(value) => (
                                                                    <div>
                                                                        {" "}
                                                                        {item.currency} {"  "} {value}
                                                                    </div>
                                                                )}
                                                            />
                                                        </td>

                                                        <td>
                                                            {item.order_status === "pending" ? (
                                                                <span className=" text-danger order-success__meta-value">
                                                                    Pending
                                                                </span>
                                                            ) : item.order_status === "Paid" ? (
                                                                <span className="text-success order-success__meta-value">
                                                                    Paid
                                                                </span>
                                                            ) : item.order_status === "Shipped" ? (
                                                                <span className="text-warning order-success__meta-value">
                                                                    Shipped
                                                                </span>
                                                            ) : (
                                                                            <span className="text-primary order-success__meta-value">
                                                                                Delivered
                                                                            </span>
                                                                        )}
                                                        </td>
                                                        <td>
                                                            <button
                                                                type="button"
                                                                onClick={() => setCart(JSON.parse(item.cart_details))}
                                                                data-toggle="modal" data-target="#exampleModal"
                                                                className="btn btn-sm btn-outline-warning rounded text-info">
                                                                View items
                                                    </button>

                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- Modal order items--> */}
                            <div class="modal fade bd-example-modal-lg" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 className="modal-title container d-flex justify-content-center" id="exampleModalLabel">ORDER ITEMS</h5>
                                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">

                                            <div className="cardTop card">
                                                <div className="card-table">
                                                    <div className="table-responsive-sm">
                                                        <table>
                                                            <thead>
                                                                <tr className="text-center">
                                                                    <th>Product image</th>
                                                                    <th>Product name</th>
                                                                    <th>Quantity</th>
                                                                    <th>Total Amount</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {Cartdata.map((item) => (
                                                                    <tr className="text-center">
                                                                        <td>
                                                                            <div className="product-image">
                                                                                <div className="product-image__body">
                                                                                    <img
                                                                                        className="product-image__img"
                                                                                        src={item.product_images.productImage_name}
                                                                                        alt=""
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            {item.product.product_name}
                                                                        </td>

                                                                        <td>
                                                                            {item.quantity}
                                                                        </td>
                                                                        <td>
                                                                            <CurrencyFormat
                                                                                value={item.total_amount}
                                                                                displayType={"text"}
                                                                                thousandSeparator={true}
                                                                                renderText={(value) => (
                                                                                    <div>
                                                                                        {" "}
                                                                                        {item.currency} {"  "} {value}
                                                                                    </div>
                                                                                )}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button"
                                             onClick={() => setCart([])}                                            
                                            class="btn btn-secondary" data-dismiss="modal">Close</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
}
export default MasterViewOrders;
