// react
import React from "react";

import CurrencyFormat from "react-currency-format";
// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
// application
// import AsyncAction from "../../../AsyncAction";
import {Quickview16Svg, Wishlist16Svg} from "../../../svg";
import {cartAddItem} from "../../../store/cart";
import {agenttoken} from "../../../api/shop";
import "boxicons";
import AsyncAction from "../../shared/AsyncAction";
import {IoMdStar, IoMdStarHalf} from "react-icons/io";
import ReactHtmlParser from "react-html-parser";
import ShowMoreText from "react-show-more-text";

function ServiceCard(props) {
    const {
        product,
        layout,
    } = props;
    const containerClasses = classNames("product-card", {
        "product-card--layout--grid product-card--size--sm": layout === "grid-sm",
        "product-card--layout--grid product-card--size--nl": layout === "grid-nl",
        "product-card--layout--grid product-card--size--lg": layout === "grid-lg",
        "product-card--layout--list": layout === "list",
        "product-card--layout--horizontal": layout === "horizontal",
    });
    let image;
    // console.log(product)
    // if (product.image && product.image.length > 0) {
    image = (
        <img
            src={product.image}
            className="card__img" height="220" alt="Place"/>
    );
    // }
    const description = ReactHtmlParser(product.description);

    return (
        <div className={containerClasses}>
            <Link target="_blank" to={`/service/product/${product.token}/${product.categoryId}`}>
                <div>
                    <Link to={`/service/product/${product.token}/${product.categoryId}`} className="card-image-wrap">
                        <div className="card-image">
                            {image}
                        </div>
                    </Link>
                    <div className="card-content-wrap">
                        <div className="card-content">
                            <Link target="_blank" to={`/service/product/${product.token}/${product.categoryId}`}>
                                {/*<span className="badge text-black  text-capitalize"> test</span>*/}
                                <h5 className="text-capitalize text-muted" style={{color: 'black'}}>
                                    {product.name.length > 26
                                        ? `${product.name.substring(0, 26)}...`
                                        : product.name}

                                </h5>
                                <span className="card-sub">
                                    <ShowMoreText
                                        lines={2}
                                        more="Read more"
                                        less="Read less"
                                        className="content-css"
                                        anchorClass="my-anchor-css-class"
                                        expanded={false}
                                    >
                                   {description}
                               </ShowMoreText>
                                                        </span>
                            </Link>
                            <a href="" className="author-img">
                                <img src={product.image} alt="author-img"/>
                            </a>

                        </div>
                        <div className="product-card__actions">
                            <div className="product-card__buttons">

                                <Link to={`/service/product/${product.token}`}
                                      type="button"
                                      className={classNames(
                                          "btn btn-primary product-card__addtocart rounded-pill")}
                                >
                                    DISCOVER MORE
                                </Link>


                            </div>

                        </div>

                        <div className="rating-row">
                            <div className="rating-rating">
                                {[
                                    <IoMdStar/>,
                                    <IoMdStar/>,
                                    <IoMdStar/>,
                                    <IoMdStarHalf/>,
                                    <IoMdStar className="last-star"/>].map((rating, index) => {
                                    return (
                                        <span key={index}>{rating}</span>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    );
}

ServiceCard.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf([
        "grid-sm",
        "grid-nl",
        "grid-lg",
        "list",
        "horizontal",
    ]),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,

};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCard);
