import {FETCH_LINK} from "./linkActionTypes";
export default function linkReducer(state =[],action) {
        switch(action.type){
        case FETCH_LINK:
            return action.payload
        default:
           return state;
    }
}


