// react
import React from "react";
// third-party
import PropTypes from "prop-types";
import {Helmet} from "react-helmet-async";
import {Redirect, Route, Switch} from "react-router-dom";
import {ToastContainer} from "react-toastify";
// application
import Footer from "./footer";
import Header from "./header";
import MobileHeader from "./mobile/MobileHeader";
import MobileMenu from "./mobile/MobileMenu";
import Quickview from "./shared/Quickview";
import PrivateRoute from "./PrivateRoute";
// pages
import AccountLayout from "./account/AccountLayout";
import AgentLayout from "./Agent/AgentLayout";
import PageConfirmOrder from "./shop/confirmOrder";
import AccountPageLogin from "./account/AccountPageLogin";
import AccountPageRegister from "./account/register";
import PageCart from "./shop/ShopPageCart";
import PageCompare from "./shop/ShopPageCompare";
import PageWishlist from "./shop/ShopPageWishlist";
import GroupWishlist from "./shop/GroupWishlist";
import ShopPageCategory from "./shop/ShopPageCategory";
import ShopPageOutlet from "./shop/shopPageOutlet";
import ShopPageOrderSuccess from "./shop/ShopPageOrderSuccess";
import ShopPayment from "./shop/paymentarea";
import ShopPageProduct from "./shop/ShopPageProduct";
import ShopPageTrackOrder from "./shop/ShopPageTrackOrder";
import SitePageAboutUs from "./site/SitePageAboutUs";
import SitePageComponents from "./site/SitePageComponents";
import SitePageContactUs from "./site/SitePageContactUs";
import SitePageContactUsAlt from "./site/SitePageContactUsAlt";
import SitePageFaq from "./site/SitePageFaq";
import SitePageNotFound from "./site/SitePageNotFound";
import SitePageTerms from "./footer/terms";
import SitePageTypography from "./site/SitePageTypography";
import SitePagePrivacy from "./footer/PrivacyPolicy";
import siteSuppremental from "./footer/Supplemental-service-agreement";
import siteTransaction from "./footer/Transaction-services";
import siteIprpp from "./footer/IPRPP";
import Checkout from "./shop/checkout";
import QuoteListpage from "./shop/QuoteCheckout";
//digital route
import ShopPageDigital from "./shop/ShopPageDigital";
import ComfirmDigitalOrder from "./shop/digitalSteps";
import DigitalWishlist from "./shop/ShopDigitalWishlist";
import DigitalBuyNowComp from "./shop/digitalBuynowSteps";
// import PageQuote from "./shop/shopPageQuote";
import QouteSteps from "./shop/QouteSteps"
import Buynow from "./shop/BuyNow";
import ShopPageSearch from "./shop/ShopPageSearch";
//Landing page for business
import LandingPage from "./LandingPage/LandingPage";
// import AgentPageRegistration from "./Agent/registration";
import AgentPageRegistration from "./Agent/Agentregistration";
import AgentPageLogin from "./Agent/AgentLogin";
import ForgotPassword from './account/PasswordResetPage';
import NewPassword from './account/NewPasswordPage';
// data stubs
import theme from "../data/theme";
import {connect} from "react-redux";
import {Router} from "@material-ui/icons";
import HomePageDigital from './home/digitalHOme'
import ListingCategory from "./Directory/home";
import ListingAll from "./Directory/AllListings";
import ListingDetails from "./Directory/detailPage";
import CategoryListings from "./Directory/categoryListings"
import ShippingPolicy from './footer/shippingPolicy';
import Listing_Details_Page from "./account/Listings/ListingDetails";
import AddListing from "./account/Listings/add_listing";
import AddListingServices from "./account/Listings/add_service";
import ShopPageServices from "./shop/services/AllServices";
import servicePageHome from "./home/ServivesHome";
import ServiceDetailPage from "./shop/services/serviceDetailPage";
import AllServices from "./shop/services/AllServicepProduct";
import AllSearchServices from "./shop/services/SearchAllService";
import MasterCard from "./shop/nmbMasterCard";
import PromotionPage from "./PromotionPage/promotion"

const categoryLayouts = [
    [
        "/shop/all-products",
        {columns: 3, viewMode: "grid", sidebarPosition: "start"},
    ],
    ["/shop/category-grid-4-columns-full", {columns: 4, viewMode: "grid"}],
    ["/shop/category-grid-5-columns-full", {columns: 5, viewMode: "grid"}],
    [
        "/shop/category-list",
        {columns: 3, viewMode: "list", sidebarPosition: "start"},
    ],
    [
        "/shop/category-right-sidebar",
        {columns: 3, viewMode: "grid", sidebarPosition: "end"},
    ],
].map(([url, options]) => (
    <Route
        key={url}
        exact
        path={url}
        render={(props) => (
            <ShopPageCategory {...props} {...options} categorySlug="all-products"/>
        )}
    />
));
// digital products

const digitalLayouts = [
    [
        "/shop/digital",
        {columns: 3, viewMode: "grid", sidebarPosition: "start"},
    ],
    ["/shop/category-grid-4-columns-full", {columns: 4, viewMode: "grid"}],
    ["/shop/category-grid-5-columns-full", {columns: 5, viewMode: "grid"}],
    [
        "/shop/category-list",
        {columns: 3, viewMode: "list", sidebarPosition: "start"},
    ],
    [
        "/shop/category-right-sidebar",
        {columns: 3, viewMode: "grid", sidebarPosition: "end"},
    ],
].map(([url, options]) => (
    <Route
        key={url}
        exact
        path={url}
        render={(props) => (
            <ShopPageDigital  {...props} {...options} categorySlug="Digital-products"/>
        )}
    />
));
//end digital device

//searched products
const searchLayouts = [
    [
        "/shop/search-products",
        {columns: 3, viewMode: "grid", sidebarPosition: "start"},
    ],
    ["/shop/category-grid-4-columns-full", {columns: 4, viewMode: "grid"}],
    ["/shop/category-grid-5-columns-full", {columns: 5, viewMode: "grid"}],
    [
        "/shop/category-list",
        {columns: 3, viewMode: "list", sidebarPosition: "start"},
    ],
    [
        "/shop/category-right-sidebar",
        {columns: 3, viewMode: "grid", sidebarPosition: "end"},
    ],
].map(([url, options]) => (
    <Route
        key={url}
        exact
        path={url}
        render={(props) => (
            <ShopPageSearch {...props} {...options} categorySlug="search-products"/>
        )}
    />
));
//
const outletLayouts = [
    [
        "/shops/:categorySlug",
        {columns: 3, viewMode: "grid", sidebarPosition: "start"},
    ],
    ["/shop/category-grid-4-columns-full", {columns: 4, viewMode: "grid"}],
    ["/shop/category-grid-5-columns-full", {columns: 5, viewMode: "grid"}],
    [
        "/shop/category-list",
        {columns: 3, viewMode: "list", sidebarPosition: "start"},
    ],
    [
        "/shop/category-right-sidebar",
        {columns: 3, viewMode: "grid", sidebarPosition: "end"},
    ],
].map(([url, options]) => (
    <Route
        key={url}
        exact
        path={url}
        render={(props) => (
            <ShopPageOutlet {...props} {...options} categorySlug="shops"/>
        )}
    />
));
const productLayouts = [
    ["/shop/product-standard", {layout: "standard"}],
    ["/shop/product-columnar", {layout: "columnar"}],
    ["/shop/product-sidebar", {layout: "sidebar"}],
].map(([url, options]) => (
    <Route
        key={url}
        exact
        path={url}
        render={(props) => (
            <ShopPageProduct
                {...props}
                {...options}
                productSlug="brandix-screwdriver-screw1500acc"
            />
        )}
    />
));

const servicesLayouts = [
    ["/shop/product-standard", {layout: "standard"}],
    ["/shop/product-columnar", {layout: "columnar"}],
    ["/shop/product-sidebar", {layout: "sidebar"}],
].map(([url, options]) => (
    <Route
        key={url}
        exact
        path={url}
        render={(props) => (
            <ServiceDetailPage
                {...props}
                {...options}
                productSlug="brandix-screwdriver-screw1500acc"
            />
        )}
    />
));

function Layout(props) {
    const {match, headerLayout, homeComponent} = props;
    return (
        <React.Fragment>
            <Helmet>
                <title>{theme.name}</title>
                <meta
                    name="buy from Tanzania, products of Tanzania, buy from Africa, products of africa, agricultural products, Tanzania, Africa"
                    content={theme.fullName}
                />
            </Helmet>

            <ToastContainer autoClose={5000} hideProgressBar/>

            <Quickview/>

            <MobileMenu/>

            <div className="site">
                <header className="site__header d-lg-none">
                    <MobileHeader/>
                </header>

                <header className="site__header d-lg-block d-none">
                    <Header layout={headerLayout}/>
                </header>
                <div className="site__body">
                    <Switch>
                        {/*
                        // Home
                        */}
                        <Route exact path={`${match.path}`} component={homeComponent}/>
                        {/* /digital home page  */}
                        <Route exact path="/digitalHome" component={HomePageDigital}/>
                        {/*home service pages*/}
                        <Route exact path="/services-home" component={servicePageHome}/>
                        <Route exact path="/services-product" component={AllServices}/>
                        <Route exact path="/all-searched-service" component={AllSearchServices}/>
                        <Route
                            exact
                            path="/service/product/:token/:CategoryId"
                            render={(props) => (
                                <ServiceDetailPage
                                    {...props}
                                    layout="standard"
                                    Token={props.match.params.token}
                                    CategoryId={props.match.params.CategoryId}
                                />
                            )}
                        />
                        {servicesLayouts}
                        {/*
                        // Shop
                        */}
                        <Redirect exact from="/shop" to="/shop/catalog"/>
                        <Route
                            exact
                            path="/shop/catalog"
                            render={(props) => (
                                <ShopPageCategory
                                    {...props}
                                    columns={3}
                                    viewMode="grid"
                                    sidebarPosition="start"
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/shop/catalog/:categorySlug"
                            render={(props) => (
                                <ShopPageCategory
                                    {...props}
                                    columns={3}
                                    viewMode="grid"
                                    sidebarPosition="start"
                                    categorySlug={props.match.params.categorySlug}
                                />
                            )}
                        />

                        {categoryLayouts}
                        {/* Following category layouts only for demonstration. */}
                        {/* digital */}
                        <Redirect exact from="/digital" to="/digital/catalog"/>
                        <Route
                            exact
                            path="/digital/catalog"
                            render={(props) => (
                                <ShopPageDigital
                                    {...props}
                                    columns={3}
                                    viewMode="grid"
                                    sidebarPosition="start"
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/digital/catalog/:categorySlug"
                            render={(props) => (
                                <ShopPageDigital
                                    {...props}
                                    columns={3}
                                    viewMode="grid"
                                    sidebarPosition="start"
                                    categorySlug={props.match.params.categorySlug}
                                />
                            )}
                        />
                        <Router exact path="/digitalOrder/comfirm" component={ComfirmDigitalOrder}/>
                        <Router exact path="/digitalBuynow/comfirm" component={DigitalBuyNowComp}/>
                        <Router exact path="/digital/wishlist" component={DigitalWishlist}/>
                        {/* digital product */}
                        {digitalLayouts}
                        {/*directory links*/}
                        <Router exact path="/Directory" component={ListingCategory}/>
                        <Router exact path="/digital/wishlist" component={DigitalWishlist}/>
                        <Router exact path="/listing_detail_page" component={Listing_Details_Page}/>
                        <Router exact path="/account/add-listing" component={AddListing}/>
                        <Router exact path="/account/add-listing/services" component={AddListingServices}/>
                        <Route exact path="/user/promotion" component={PromotionPage}/>
                        {/*<Route*/}
                        {/*    exact*/}
                        {/*    path="/listing-detail_page/:ListingToken"*/}
                        {/*    render={(props) => (*/}
                        {/*        <Listing_Details*/}
                        {/*            {...props}*/}
                        {/*            columns={3}*/}
                        {/*            viewMode="grid"*/}
                        {/*            sidebarPosition="start"*/}
                        {/*            ListingToken={props.match.params.ListingToken}*/}
                        {/*        />*/}
                        {/*    )}*/}
                        {/*/>*/}
                        {/*<Route*/}
                        {/*    exact*/}
                        {/*    path="/listing-detail_page/:SecureToken"*/}
                        {/*    render={(props) => (*/}
                        {/*        <Listing_Details_Page*/}
                        {/*            {...props}*/}
                        {/*            columns={3}*/}
                        {/*            viewMode="grid"*/}
                        {/*            sidebarPosition="start"*/}
                        {/*            SecureToken={props.match.params.SecureToken}*/}
                        {/*        />*/}
                        {/*    )}*/}
                        {/*/>*/}
                        <Route
                            exact
                            path="/shop/search-products"
                            render={(props) => (
                                <ShopPageSearch
                                    {...props}
                                    columns={3}
                                    viewMode="grid"
                                    sidebarPosition="start"
                                />
                            )}
                        />
                        {searchLayouts}
                        <Route
                            exact
                            path="/listing-details/:Url"
                            render={(props) => (
                                <ListingDetails
                                    {...props}
                                    columns={3}
                                    viewMode="grid"
                                    sidebarPosition="start"
                                    Url={props.match.params.Url}
                                />
                            )}
                        />
                         <Route
                            exact
                            path="/category/listings/:secure_token"
                            render={(props) => (
                                <CategoryListings
                                    {...props}
                                    columns={3}
                                    viewMode="grid"
                                    sidebarPosition="start"
                                    secure_token={props.match.params.secure_token}
                                />
                            )}
                        />

                        <Route
                            exact
                            path="/shop-catalog/:outletToken/:categorySlug"
                            render={(props) => (
                                <ShopPageOutlet
                                    {...props}
                                    columns={3}
                                    viewMode="grid"
                                    sidebarPosition="start"
                                    categorySlug={props.match.params.categorySlug}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/shops/:outletToken"
                            render={(props) => (
                                <ShopPageOutlet
                                    {...props}
                                    columns={3}
                                    viewMode="grid"
                                    sidebarPosition="start"
                                    outletToken={props.match.params.outletToken}
                                />
                            )}
                        />
                        {/* Following category layouts only for demonstration. */}
                        {outletLayouts}
                        <Route
                            exact
                            path="/shop/products/:product_token/:outlet_token/:Category/:ReferralToken"
                            render={(props) => (
                                <ShopPageProduct
                                    {...props}
                                    layout="standard"
                                    product_token={props.match.params.product_token}
                                    outlet_token={props.match.params.outlet_token}
                                    Category={props.match.params.Category}
                                    ReferralToken={props.match.params.ReferralToken}
                                />
                            )}
                        />
                        {/* Following product layouts only for demonstration. */}
                        {productLayouts}
                        <Route
                            exact
                            path="/shop/checkout/success"
                            component={ShopPageOrderSuccess}
                        />
                        <Route

                         exact
                         path="/wait-for-nmb"
                         component={MasterCard}
                        />
                        {/* landing pages */}
                        <Router exact path="/sell-on-Ezytrade" component={LandingPage}/>
                        <Route exact path="/shop/payment" component={ShopPayment}/>
                        <Route exact path="/shop/compare" component={PageCompare}/>
                        <Route
                            exact
                            path="/shop/track-order"
                            component={ShopPageTrackOrder}
                        />
                        <Route
                            exact
                            path="/shop/Confirm-Order"
                            component={PageConfirmOrder}
                        />
                        <Route exact path="/shop/GroupWishlist" component={GroupWishlist}/>
                        <Route exact path="/account/login" component={AccountPageLogin}/>
                        <Route exact path="/account/forgot-password" component={ForgotPassword}/>
                        <Route exact path="/account/new-password" component={NewPassword}/>
                        <Route
                            exact
                            path="/account/Register"
                            component={AccountPageRegister}
                        />
                        <Route
                            exact
                            path="/Agent/Registration"
                            component={AgentPageRegistration}
                        />
                        <Route exact path="/agent/login" component={AgentPageLogin}/>
                        ****************************AUTHENTICATED
                        PAGES****************************
                        <Route exact path="/shop/cart" component={PageCart}/>
                        <PrivateRoute exact path="/shop/wishlist" component={PageWishlist}/>
                        <PrivateRoute exact path="/quote-list" component={QouteSteps}/>
                        <Route
                            exact
                            path="/shop/checkout"
                            component={Checkout}
                            {...props}
                        />
                        <PrivateRoute
                            exact
                            path="/shop/quote-checkout"
                            component={QuoteListpage}
                            {...props}
                        />

                        <PrivateRoute
                            exact={false}
                            path="/shop/checkout-Buynow"
                            component={Buynow}
                            {...props}
                        />
                        <PrivateRoute
                            path="/account"
                            component={AccountLayout}
                            {...props}
                        />
                        <PrivateRoute path="/agent" component={AgentLayout} {...props} />
                        ***************************************AUTHENTICATED
                        END*************************************
                        <Redirect exact from="/site" to="/site/about-us"/>
                        <Route exact path="/site/about-us" component={SitePageAboutUs}/>
                        {/* <Route path="/agent" component={AgentLayout} {...props} /> */}
                        <Route
                            exact
                            path="/site/components"
                            component={SitePageComponents}
                        />
                        {/*<Route*/}
                        {/*    exact*/}
                        {/*    path="/terms/condition"*/}
                        {/*    component={SitePageTermCondition}*/}
                        {/*/>*/}
                        <Route exact path="/privacy-policy" component={SitePagePrivacy}/>
                        <Route exact path="/Intellectual-property" component={siteIprpp}/>
                        <Route
                            exact
                            path="/suppremental-service-agreement"
                            component={siteSuppremental}
                        />
                        <Route
                            exact
                            path="/Transaction-service-agreement"
                            component={siteTransaction}
                        />
                        <Route
                            exact
                            path="/site/contact-us"
                            component={SitePageContactUs}
                        />
                        <Route
                            exact
                            path="/site/contact-us-alt"
                            component={SitePageContactUsAlt}
                        />
                        <Route exact path="/site/not-found" component={SitePageNotFound}/>
                        <Route exact path="/site/faq" component={SitePageFaq}/>
                        <Route exact path="/terms-condition" component={SitePageTerms}/>
                        <Route exact path="/shipping-policy" component={ShippingPolicy}/>
                        <Route
                            exact
                            path="/site/typography"
                            component={SitePageTypography}
                        />
                        <Route component={SitePageNotFound}/>
                    </Switch>
                </div>

                <footer className="site__footer">
                    <Footer/>
                </footer>
            </div>
        </React.Fragment>
    );
}

Layout.propTypes = {
    /**
     * header layout (default: 'classic')
     * one of ['classic', 'compact']
     */
    headerLayout: PropTypes.oneOf(["default", "compact"]),
    /**
     * home component
     */
    homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
    headerLayout: "default",
};
const mapStateToProps = (state) => ({
    authentication: state.auth,
});
export default connect(mapStateToProps)(Layout);
