import React, { useEffect, useMemo, useState } from "react";
import { Pagination, Search } from "../DataTable";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import theme from "../../data/theme";
import { customertoken } from "../../api/shop";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import {FetchPendingOrders} from "../../store/orders/PedingOrder";
import {FetchPaidOrders} from "../../store/orders/paidOrder";
import {FetchShippedOrders} from "../../store/orders/ShippedOrder";
import {FetchDeliveryOrders} from "../../store/orders/DeliveredOrder";
import {FetchPartialOrders} from "../../store/orders/PartialPaidOrder";
import {FetchOrders} from "../../store/orders";
const DataTable = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (customertoken()) {
      dispatch(FetchOrders());
      dispatch(FetchPaidOrders());
      dispatch(FetchShippedOrders());
      dispatch(FetchDeliveryOrders());
      dispatch(FetchPartialOrders())
    }
  }, [dispatch]);
  const DataList = useSelector((state) => state.OrdersList);
  const [orders, setOrders] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const ITEMS_PER_PAGE = 5;

  useEffect(() => {
    setOrders(DataList);
  }, [DataList]);

  const orderData = useMemo(() => {
    let computedOrders = orders;
    if (search) {
      computedOrders = computedOrders.filter(
        (order) =>
          order.order_number.toLowerCase().includes(search.toLowerCase()) ||
          order.order_status.toLowerCase().includes(search.toLowerCase()) ||
          order.total_vat.toLowerCase().includes(search.toLowerCase()) ||
          order.total_amount.toLowerCase().includes(search.toLowerCase())
      );
    }
    setTotalItems(computedOrders.length);
    //Current Page slice
    return computedOrders.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [orders, currentPage, search]);

  return (
    <div className="card">
      <Helmet>
        <title>{`Order History — ${theme.name}`}</title>
      </Helmet>
      <div className="card-header">
        <div className="d-flex align-items-end flex-column">
          <div className="mt-auto p-2">
            <Search
              onSearch={(value) => {
                setSearch(value);
                setCurrentPage(1);
              }}
            />
          </div>
        </div>
      </div>
      <div className="card-divider" />
      <div className="card-table">
        <div className="table-responsive-sm">
          <table>
            <thead>
              <tr className="text-center">
                <th>Order No</th>
                <th>Order Status</th>
                <th>Total Vat</th>
                <th>Total Amount</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {orderData.map((order) => (
                <tr className="text-center">
                  <td>{order.order_number}</td>
                  {order.order_status === "pending" ? (
                    <td className="text-danger font-weight-bold">Pending</td>
                  ) : order.order_status === "Paid" ? (
                    <td className="text-success font-weight-bold">Paid</td>
                  ) : order.order_status === "Shipped" ? (
                    <td className="text-warning font-weight-bold">Shipped</td>
                  ) : order.order_status === "Delivered" ? (
                    <td className="text-primary font-weight-bold">Delivered</td>
                  ) : (
                    <></>
                  )}
                  <td>{order.total_vat}</td>
                  <td>{order.total_amount}</td>
                  <td>
                    <Moment format="DD/MM/YYYY">{order.created_at}</Moment>
                  </td>
                  <td>
                    <Link
                      to={{ pathname: "/account/vieworder", state: [order] }}
                    >
                      View Items
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card-divider" />
      <div className="card-footer">
        <div className="d-flex align-items-center flex-column">
          <Pagination
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
};

export default DataTable;
