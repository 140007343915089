// react
import React from 'react';
// third-party
import { Helmet } from 'react-helmet-async';
// application
import PageHeader from '../shared/PageHeader';
// data stubs
import theme from '../../data/theme';
function LandingPage() {
  const breadcrumb = [
    { title: 'Home', url: '' },
    { title: 'Sell on Ezytrade Africa', url: '' },
  ];
  return (
    <React.Fragment>
      <Helmet>
        <title>{` — ${theme.name}`}</title>
      </Helmet>
      <PageHeader header="Sell on Ezytrade Africa" breadcrumb={breadcrumb} />
      <div className="container">
        <div>
          <p>
            It is simple! <a href="https://businesscentral.ezytrade.africa/register" rel="noopener noreferrer" target="_blank"> Open an account</a> and start displaying your products for your buyers to see and buy online. Our dedicated team will assist you in every step to help you stand out from the competition. Our platform offers a number of feature and services to increase your sell, such as;
        </p>
          <h5>Digital Marketing</h5>
          <p>
            Reach your ideal buyers with content marketing through Ezytrade Africa and social media platforms. Our Digital Marketing experts will help you to create online marketing campaigns so that the right knows your product and service. Our digital marketing services will help you to boost your brand awareness, and drive.
          </p>
          <h5>Business Dashboard</h5>
          <p>
            Operate efficiently with tools built for business. Manage your growth with analytics tools & customer data. Track your real-time sales and marketing campaign.
         </p>
          <h5>Payments</h5>
          <p>
            Ezytrade Africa platform is integrated with local Tanzanian Banks and international payment gateways. Sellers will be able to get paid through banks, mobile wallets and international cards such as Visa and MasterCard’s.
          </p>
          <h5>Shipping and Delivery</h5>
          <p>
            Fast and reliable shipping and delivery services. Ezytrade Africa arrange shipping/delivery on behalf of buyers. Consignments will be dropped at your designated location.
          </p>
          <h5>Seller Support</h5>
          <p>
            Our experts will support you on each step of the selling cycle. From onboarding to reviews management. We are always available to you.
        </p>
        </div>

        {/* Frequently Asked Questions */}
        <div className="mt-4">
          <div className="faq__section-title">
            <h5 className="d-flex justify-content-center">Frequently Asked Questions(FAQ)</h5>
          </div>
          <div className="faq__section-body">
            <div className="row">
              <div className="faq__section-column col-12 col-lg-6">
                <div className="typography">
                  <h6>What buyers can I reach on Ezytrade Africa?</h6>
                  <p>
                    Ezytrade Africa buyers are individuals but mainly small and medium-sized businesses (SMEs) from all over Tanzania and neighboring countries. They source from different categories, including Agriculture, Beauty & Personal Care, Electrical, Health & Medical, Sports & Entertainment, Machinery, Consumer Electronics, Apparel, Home & Garden, to name a few.
                  </p>
                  <h6>Is there a way to advertise on Ezytrade Africa?</h6>
                  <p>
                    Yes. members can increase visibility of their product listings through Advertising. This lets your product listing appear higher in the search results..
                  </p>
                </div>
              </div>
              <div className="faq__section-column col-12 col-lg-6">
                <div className="typography">
                  <h6>Is my business a good fit for Ezytrade Africa?</h6>
                  <p>
                    Businesses that meet the following criteria tend to perform best on Ezytrade Africa.
                    <br />
                    <li>Sell 1+ products</li>
                    <li>Have 2+ employees</li>
                    <li>Some export experience is a plus</li>
                  </p>

                  <h6>Will I have help setting up my business on the platform?</h6>

                  <p>
                    Yes. Ezytrade Africa Experts offer support in setting up your business including content preparations.

                   </p>
                </div>
              </div>
            </div>
            <div className="mt-3">

              <div className="faq__section-title">
                <h5 className="d-flex justify-content-center">Do you have questions?</h5>
                <p className="d-flex justify-content-center">We’ll have an Ezytrade Africa representative reach out to you.</p>
              </div>
              <form>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="form-name">First Name</label>
                    <input type="text"
                      id="form-name"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="form-email">Last Name</label>
                    <input
                      type="text"
                      id="form-name"
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="form-subject">Country</label>
                    <select className="form-control">
                      <option>Select.....</option>
                      <option>Tanzania</option>
                    </select>
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="form-subject">Company Name</label>
                    <input
                      type="text"
                      id="form-subject"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="form-email">Email</label>
                    <input
                      type="email"
                      id="form-email"
                      className="form-control"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="form-email">Phone Number</label>
                    <input
                      type="number"
                      id="form-number"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="form-message">Message</label>
                  <textarea id="form-message" className="form-control" rows="4" />
                </div>
                <button type="submit" className="btn btn-primary">Send Message</button>
              </form>
            </div>
            <br />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LandingPage;
