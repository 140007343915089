import {customertoken} from "../../api/shop";
import Axios from "axios";
import {BOOKING_APPROVE_VALUE} from './ListingBookingActionTypes'
import {toast} from "react-toastify";

export function FetchListingBookingApprove() {
    return async (dispatch) =>
        Axios.get(`${window.$name}/api/directory/consumer/show/listings/booking/${customertoken()}`)
            .then(function (response) {
                dispatch({type: BOOKING_APPROVE_VALUE, payload: response.data.bookings});
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}
