export default [
    {
        title: 'TZS',
        currency: {
            code: 'TZS',
            symbol: 'TZS',
            name: 'TZS',
        },
    },
    // {
    //     title: '£ Pound Sterling',
    //     currency: {
    //         code: 'GBP',
    //         symbol: '£',
    //         name: 'Pound Sterling',
    //     },
    // },
    // {
    //     title: '$ US Dollar',
    //     currency: {
    //         code: 'USD',
    //         symbol: '$',
    //         name: 'US Dollar',
    //     },
    // },
    // {
    //     title: '₽ Russian Ruble',
    //     currency: {
    //         code: 'RUB',
    //         symbol: '₽',
    //         name: 'Russian Ruble',
    //     },
    // },
];
