import React, {Component} from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaDribbble, FaBehance } from 'react-icons/fa'

function WidgetFollow(props) {
    // const state = {
    //     title: 'Follow & Connect',
    //     socials: [
    //         {
    //             icon: <FaFacebookF />,
    //             url: 'https://facebook.com'
    //         },
    //         {
    //             icon: <FaTwitter />,
    //             url: 'https://twitter.com'
    //         },
    //         {
    //             icon: <FaInstagram />,
    //             url: 'https://instagram.com'
    //         },
    //         {
    //             icon: <FaDribbble />,
    //             url: 'https://dribbble.com'
    //         },
    //         {
    //             icon: <FaBehance />,
    //             url: 'https://behance.be'
    //         },
    //     ]
    // }
 const {data}=props
        return (
            <>
                <div className="sidebar-widget social-widget">
                    <h3 className="widget-title">
                      Contact us
                    </h3>
                    <div className="title-shape"></div>
                    <div className="mt-4">
                        <p>
                            Country :<span>{data.listing_locations.country}</span>
                        </p>
                        <p>
                            Region :<span>{data.listing_locations.region}</span>
                        </p>
                        <p>
                            District :<span>{data.listing_locations.district}</span>
                        </p>
                        <p>
                            Email :<span>{data.listing_locations.email}</span>
                        </p>
                        <p>
                            Phone number :<span>{data.listing_locations.phone_number}</span>
                        </p>
                        <p>
                            Physical address :<span>{data.listing_locations.physical_address}</span>
                        </p>

                        <h3 className="widget-title">
                            Follow & Connect
                        </h3>
                        <div className="title-shape"></div>
                        <ul className="social-profile padding-top-30px">
                            <li>
                                <a href={data.listing_locations.facebook}>
                                    <i className="d-inline-block">
                                        <FaFacebookF />
                                    </i>
                                </a>
                            </li>
                            <li>
                                <a href={data.listing_locations.twitter}>
                                    <i className="d-inline-block">
                                        <FaTwitter />
                                    </i>
                                </a>
                            </li>
                            <li>
                                <a href={data.listing_locations.instagram}>
                                    <i className="d-inline-block">
                                        <FaInstagram />
                                    </i>
                                </a>
                            </li>
                            <li>
                                <a href={data.listing_locations.website}>
                                    <i className="d-inline-block">
                                        <FaDribbble />
                                    </i>
                                </a>
                            </li>
                            {/*country: "Tanzania"*/}
                            {/*country_id: 1*/}
                            {/*created_at: "2021-06-01 21:32:42"*/}
                            {/*district: "Arusha Vijijini"*/}
                            {/*district_id: 2*/}
                            {/*email: "yusuphkaondo@gmail.com"*/}
                            {/*facebook: "https://twitter.com/serena_hotels"*/}
                            {/*id: 27*/}
                            {/*instagram: "https://twitter.com/serena_hotels"*/}
                            {/*listing_id: 30*/}
                            {/*phone_number: "0767590820"*/}
                            {/*physical_address: "Arusha"*/}
                            {/*region: "Arusha"*/}
                            {/*region_id: 7*/}
                            {/*secure_token: "h9UIt4oZJ0wDDTwE5jjqi3gbf"*/}
                            {/*twitter: "https://twitter.com/serena_hotels"*/}
                            {/*updated_at: "2021-06-01 21:32:42"*/}
                            {/*website: "https://www.serenahotels.com/en/default.html"*/}
                        </ul>

                    </div>


                </div>
            </>
        );

}

export default WidgetFollow;