import {REVIEW_DIRECTORY_DATA} from "./reviewActionTypes";
export default function reviewReducer(state=[],action) {
    switch(action.type){

        case REVIEW_DIRECTORY_DATA:
            return action.payload
        default:
            return state;
    }
}
