import { customertoken, agenttoken } from "../../api/shop";
import Axios from "axios";
import { MASTER_NUMBER_DATA, } from "./masterNumberActionTypes";
import { toast } from "react-toastify";
import history from '../../@history';
export function FetchMasterNumber() {
    const data = {
        agent_token: agenttoken(),
    };
    return async (dispatch) =>
        Axios.post(window.$name + "/api/agent/get/orders/master-control-number", data)
            .then(function (response) {
                if (response.status === 401) {
                    dispatch({ type: MASTER_NUMBER_DATA, payload: [] });
                } else if (response.status === 201 && response.data.length !== 0) {
                    dispatch({ type: MASTER_NUMBER_DATA, payload: response.data.master_control_cart_detail });
                    // dispatch(FetchRate(JSON.parse(response.data.rates.rate)));
                    // console.log(response.data)
                }
                else {
                    dispatch({ type: MASTER_NUMBER_DATA, payload: response.data });
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

export function AddMasterNumber(data) {
    const params = {
        order_number: data,
        agent_token: agenttoken()
    }
    return async (dispatch) =>
        Axios.post(window.$name + "/api/agent/add/order-number", params)
            .then(function (response) {
                toast.success(`${response.data.message}`);
                dispatch(FetchMasterNumber());
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}
export function PayViaMasterControlNumber(PayMasterNumber) {
    // console.log(data)     
    return async (dispatch) =>
        Axios.post(window.$name + "/api/agent/create/master-control-number", PayMasterNumber)
            .then(function (response) {
                toast.success(`${response.data.message}`);
                window.location.href = "/agent"
                // history.push({
                //     pathname: '/agent'
                // });

            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
};
export function RemoveOrderNumber(order_number) {
    const data = {
        agent_token: agenttoken(),
        order_number: order_number,
    };
    // console.log(data)
    return async (dispatch) =>
        Axios.post(window.$name + "/api/agent/remove/order-number", data)
            .then(function (response) {
                toast.success(`${response.data.message}`);
                dispatch(FetchMasterNumber());
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

// export function AddMasterNumber(data) {
//     const params = {
//         order_number:data,
//         agent_token:agenttoken()
//     }    
//     return async (dispatch) =>
//         axios.post(window.$name + "/api/agent/add/order-number", params)
//             .then(function (response) {
//                 toast.success(`${response.data.message}`);
//                 dispatch(FetchMasterNumber());
//             })
//             .catch(function (error) {
//                 if (error.response) {
//                     toast.error(
//                         `"${error.response.data.message}" Error Status  "${error.response.status}" `
//                     );
//                 } else if (error.request) {
//                     toast.error(`"${error.request}" `);
//                 } else {
//                     toast.error(`"${error.message}" `);
//                 }
//             });
// }