// react
import React, { useState } from "react";
// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
// import Tab from "react-bootstrap/Tab";
// data stubs
import theme from "../../data/theme";
import "react-tabs/style/react-tabs.css";
import PhoneInput from "react-phone-input-2";

import "react-phone-input-2/lib/material.css";

export default function AccountPageViewOrder() {
  const [value,] = useState();
  return (
    <React.Fragment>
      <Helmet>
        <title>{`Order Details — ${theme.name}`}</title>
      </Helmet>
      <div className="card">
        <div className="order-header">
          <div className="order-header__subtitle">
            Group will <mark className="order-header__date">Expire</mark> after
            order to be delivered.
          </div>
          <div className="order-header__actions">
            <Link
              to="/account/orders"
              data-toggle="modal"
              data-target="#exampleModalLong"
              className="btn btn-xs btn-secondary"
            >
              Add Member
            </Link>
            <div
              className="modal fade"
              id="exampleModalLong"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title container text-center"
                      id="exampleModalLongTitle"
                    >
                      INVITE MEMBER
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>                 
                  <div class="modal-body">
                    <form>
                      <div class="d-flex justify-content-center">
                        <PhoneInput
                          className="mr-1"
                          country={"tz"}
                          value={value}
                        />
                        <Link className="ml-2 form-control btn btn-primary text-center rounded">
                          Invite Member
                        </Link>
                      </div>
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="order-header__title">Group Number #3857</div>
          <div className="order-header__subtitle">Group Name: TYD Group</div>
          <div className="order-header__subtitle">Total Member: 87</div>
          <div className="order-header__subtitle">Group Type : Flash</div>
        </div>
        <h6>Group Members</h6>
        <div className="card-divider" />
        <div className="card-table">
          <div className="table-responsive-sm">
            <table>
              <thead>
                <tr>
                  <th>Member No</th>
                  <th>Member Name</th>
                  <th>Mobile No</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="card-table__body card-table__body--merge-rows">
                <tr>
                  <td>Member No</td>
                  <td>Member Name</td>
                  <td>Mobile No</td>
                  <td>Action</td>
                </tr>
              </tbody>
              
              {/* <tfoot>
                                <tr>
                                    <th>Total</th>
                                    <td>$5,882.00</td>
                                </tr>
                            </tfoot> */}
            </table>
          </div>
        </div>
        <div className="card-divider" />
        <br></br>
        <h6>Group Order</h6>
        <div className="card-divider" />
        <div className="card-table">
          <div className="table-responsive-sm">
            <table>
              <thead>
                <tr>
                  <th>Group No</th>
                  <th>Group Name</th>
                  <th>Total Member</th>
                  <th>Date</th>
                  <th>Action </th>
                </tr>
              </thead>

              <tbody>
                <td>G-65342</td>
                <td>TYD Group</td>
                <td>45</td>
                <td>12/3/2020</td>
                <td>Open</td>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
