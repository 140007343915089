// react
import React, {useState} from "react";
// third-party
import classnames from "classnames";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Scrollbars} from "react-custom-scrollbars";
// application
import AsyncAction from "../../shared/AsyncAction";
import InputNumber from "../../shared/InputNumber";
// import ProductGallery from "../..";
import Rating from "../../shared/Rating";
import {cartAddItem} from "../../../store/cart";
import {compareAddItem} from "../../../store/compare";
import {Compare16Svg, Wishlist16Svg, DollarSvg} from "../../../svg";
import {wishlistAddItem} from "../../../store/wishlist";
import CurrencyFormat from "react-currency-format";
import Moment from "react-moment";
import axios from "axios";
import {ProductDetails} from "../../../store/buynow";
import {QuoteAddItem} from "../../../store/quote";
import {digitalAddBuynow} from '../../../store/digitalbuynow'
// import Shop from "../OthershopModal";
import ReactHtmlParser from "react-html-parser";
import ShowMoreText from "react-show-more-text";
import "react-datepicker/dist/react-datepicker.css";

import ServiceGallery from "../../shared/ServiceProductGallery";
import ServiceChoiceCard from "./serviceChoiceCard";
import {useFormik} from "formik";
import * as yup from "yup";
import PaymentOptioncomponent from "../paymentOption";
import {agentcustomerToken, agenttoken, customertoken, PaymentToken, userCurrency} from "../../../api/shop";
import Axios from "axios";
import {toast} from "react-toastify";
import {handleSubmits} from "../checkout";
import {useHistory} from "react-router-dom";
import {url} from "../../../services/utils";

const validationSchema = yup.object().shape({
    full_name: yup
        .string()
        .required("Full Name is required"),
    phone_number: yup
        .string()
        .required("Phone Number is required"),
    location: yup
        .string()
        .required("Location is required"),
    schedule_start: yup
        .string()
        .required("Start Time is required"),
    schedule_end: yup
        .string()
        .required("End Time is a required field"),
});

function Product(props) {
    const history = useHistory()
    const {
        product,
        services,
        layout,
        // ServicesData,
        wishlistAddItem,
        compareAddItem,
        DefaultPrice,
        // isLogin
        // defaultChoice
    } = props;
    const initialValues = {
        full_name: "",
        phone_number: "",
        location: "",
        guest_email: "",
        schedule_end: "",
        schedule_start: "",
    };

    // console.log("isLogin", isLogin)
    //using useFormik
    const formik = useFormik({
        initialValues,
        validationSchema,
    });
    // console.log(product)
    const description = ReactHtmlParser(product.product_description);
// console.log(DefaultPrice)
    const [NewPrice, setNewPrice] = useState(product.price.product_price);
    const [paymentOption, setPaymentOption] = useState(false)
    const [arrayData, setArray] = useState([]);
    const [serviceToken, setServiceToken] = useState(null);
    const [ServiceId, setServiceId] = useState(null);

    function TotalPrice(NewValue) {
        if (NewValue) {
            if (!arrayData.includes(NewValue)) {
                setArray(oldArray => [...oldArray, NewValue]);
                setNewPrice(Number(NewPrice) + Number(NewValue.choice_price.service_price))
                toast.success("Service choice added ,Successfully");
                arrayData.forEach((data) => {
                    if (data.id === NewValue.id) {
                        setServiceId(data.id);
                        // console.log(data.id)
                    }
                });
            } else {
                toast.error("service choice already added")
            }
            return NewPrice
        } else {
            return NewPrice
        }
    }

    function handleRemove(NewValue) {
        const newArray = arrayData.filter((item) => item.id !== NewValue.id);
        setArray(newArray)
        setNewPrice(Number(NewPrice) - Number(NewValue.choice_price.service_price))
        toast.success("Service choice removed ,Successfully");
    }

    function GetPaymentOption(value) {
        setPaymentOption(value);
    }

    // create order function
    const agentcustomer_Token = agentcustomerToken();
    const agentToken = agenttoken();

    const DataToBeSubmit = {
        id: product.id,
        product_name: product.product_name,
        category_name: product.listing_categories_id,
        choices: arrayData,
        price: product.price,
        service_product_image: product.service_product_image
    }

    // console.log(DataToBeSubmit)

    function handleFullName(name) {
        localStorage.setItem("service_name", name);
    }

    function handlePhoneNumber(phone) {
        localStorage.setItem("service_phone", phone);
    }

    function handleEmail(email) {
        localStorage.setItem("service_email", email);
    }

    function handleLocation(location) {
        localStorage.setItem("service_location", location);
    }

    function ConfirmOrder() {

        if (customertoken() || agentcustomerToken()) {
            if (agentcustomer_Token !== null && agentToken !== null) {
                let data = {
                    choice: DataToBeSubmit,
                    payment_token: PaymentToken(),
                    schedule_end: formik.values.schedule_end,
                    schedule_start: formik.values.schedule_start,
                    service_product_token: serviceToken,
                    user_token: agentcustomer_Token,
                    total_amount: NewPrice,
                    agent_token: agentToken,
                    currency: product.price.price_currency,
                    push_number: localStorage.getItem("phone"),
                };
                Axios.post(window.$name + "/api/service-booking/book/service-product", data)
                    .then((response) => {
                        toast.success(` "${response.data.message}"`);
                        localStorage.setItem("success_data", JSON.stringify(response.data.payload));
                        localStorage.removeItem("paymentOption_token");
                        history.push('/shop/checkout/success')
                        // handleSubmits(activeStep)
                    })
                    .catch(function (error) {
                        if (error.response) {
                            toast.error(
                                `"${error.response.data.message}" Error Status  "${error.response.status}" `
                            );
                        } else if (error.request) {
                            toast.error(`"${error.request}" `);
                        } else {
                            toast.error(`"${error.message}" `);
                        }
                    });
            } else {
                let data = {
                    user_token: customertoken(),
                    choice: DataToBeSubmit,
                    payment_token: PaymentToken(),
                    schedule_end: formik.values.schedule_end,
                    schedule_start: formik.values.schedule_start,
                    service_product_token: serviceToken,
                    total_amount: NewPrice,
                    agent_token: "",
                    currency: product.price.price_currency,
                    push_number: localStorage.getItem("phone"),
                };
                Axios.post(window.$name + "/api/service-booking/book/service-product", data)
                    .then((response) => {
                        toast.success(` "${response.data.message}"`);
                        localStorage.setItem(
                            "success_data",
                            JSON.stringify(response.data.payload)
                        );
                        localStorage.removeItem("paymentOption_token");
                        history.push('/shop/checkout/success')
                    })
                    .catch(function (error) {
                        if (error.response) {
                            toast.error(
                                `"${error.response.data.message}" Error Status  "${error.response.status}" `
                            );
                        } else if (error.request) {
                            toast.error(`"${error.request}" `);
                        } else {
                            toast.error(`"${error.message}" `);
                        }
                    });
            }
        } else {

            let data = {
                full_name: localStorage.getItem("service_name"),
                phone_number: localStorage.getItem("service_phone"),
                email: localStorage.getItem("service_email"),
                location: localStorage.getItem("service_location"),
                choice: DataToBeSubmit,
                payment_token: PaymentToken(),
                schedule_end: formik.values.schedule_end,
                schedule_start: formik.values.schedule_start,
                service_product_token: serviceToken,
                total_amount: NewPrice,
                agent_token: "",
                currency: product.price.price_currency,
                push_number: localStorage.getItem("phone"),
            };

            // console.log("data",data)
            Axios.post(window.$name + "/api/service-booking/guest/book/service-product", data)
                .then((response) => {
                    toast.success(` "${response.data.message}"`);
                    localStorage.setItem(
                        "success_data",
                        JSON.stringify(response.data.payload)
                    );
                    localStorage.removeItem("paymentOption_token");
                    localStorage.removeItem("service_name");
                    localStorage.removeItem("service_phone");
                    localStorage.removeItem("service_email");
                    localStorage.removeItem("service_location");
                    history.push('/shop/checkout/success')
                })
                .catch(function (error) {
                    if (error.response) {
                        toast.error(
                            `"${error.response.data.message}" Error Status  "${error.response.status}" `
                        );
                    } else if (error.request) {
                        toast.error(`"${error.request}" `);
                    } else {
                        toast.error(`"${error.message}" `);
                    }
                });
        }

    }

    return (
        <div className={`product product--layout--${layout}`}>
            <div className="product__content">
                <ServiceGallery layout={layout} images={product.service_product_image}/>
                <div className="product__info">
                    <div className="product__wishlist-compare">
                        <AsyncAction
                            action={() => wishlistAddItem(product.Product)}
                            render={({run, loading}) => (
                                <button
                                    type="button"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="Wishlist"
                                    onClick={run}
                                    className={classnames("btn btn-sm btn-light btn-svg-icon", {
                                        "btn-loading": loading,
                                    })}
                                >
                                    <Wishlist16Svg/>
                                </button>
                            )}
                        />
                        <AsyncAction
                            action={() => compareAddItem(product.Product)}
                            render={({run, loading}) => (
                                <button
                                    type="button"
                                    data-toggle="tooltip"
                                    data-placement="right"
                                    title="Compare"
                                    onClick={run}
                                    className={classnames("btn btn-sm btn-light btn-svg-icon", {
                                        "btn-loading": loading,
                                    })}
                                >
                                    <Compare16Svg/>
                                </button>
                            )}
                        />
                    </div>
                    <h2 className="product__name text-capitalize">
                        {product.product_name}
                    </h2>
                    <div className="product__rating">
                        <div className="product__rating-stars">
                            <Rating value="5"/>
                        </div>
                        <div className="product__rating-legend">
                            <Link>Reviews </Link>
                        </div>
                    </div>
                    <div className="product__description">
                        {/* {he.decode(
                  product.Product.product_description.replace(/<[^>]+>/g, "")
                )}
                          */}
                        <ShowMoreText
                            lines={5}
                            more="Read more"
                            less="Read less"
                            className="content-css"
                            anchorClass="my-anchor-css-class"
                            expanded={false}
                        >
                            {description}
                        </ShowMoreText>
                    </div>
                    <p className="mt-4 row container">

                        <span className="text-uppercase font-weight-bold">Price</span>:
                        <span className="text-danger font-weight-bold ml-4">
                            <CurrencyFormat
                                value={TotalPrice()}
                                displayType={"text"}
                                thousandSeparator={true}
                                renderText={(value) => (
                                    <div>
                                        {product.price.price_currency}{" "}{value}
                                    </div>
                                )}
                            />
                        </span>

                    </p>
                    <div className="mt-3">
                        <b> NB:</b>This Price only for {" "}{" "}
                        <b>{product.product_name}</b>,
                        <> {arrayData.map((index) => (
                            <b>{index.choice_name}{" "},{" "}</b>
                        ))}
                        </>

                        if you want to others service please add below
                    </div>

                    <p className="font-weight-bold">
                        Service Provider :
                        {product.business_name ? (
                        <span
                        className="text-capitalize text-primary ">
                        {product.business_name.business_name}
                        </span>
                            ):(
                        <span
                        className="text-capitalize text-primary ">
                        {product.listing.listing_title}
                        </span>
                            )}
                    </p>
                    {customertoken() || agentcustomerToken() ?
                        <div className="mt-3">
                            <h5>
                                Book Now
                            </h5>
                            {/*<div className="card-divider"/>*/}
                            {product.schedule_time === "both" ?
                                <div>
                                    <div className="form-row mt-4">
                                        <div className="form-group col ">
                                            <label>From </label>
                                            <input
                                                type="datetime-local"
                                                className="form-control"
                                                id="schedule_start"
                                                value={formik.values.schedule_start}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.schedule_start && formik.errors.schedule_start ? (
                                                <div className="text-danger">{formik.errors.schedule_start}</div>
                                            ) : null}
                                        </div>
                                        <div className="form-group col ">
                                            <label>To</label>
                                            <input
                                                type="datetime-local"
                                                className="form-control"
                                                id="schedule_end"
                                                value={formik.values.schedule_end}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.schedule_end && formik.errors.schedule_end ? (
                                                <div className="text-danger">{formik.errors.schedule_end}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    {formik.values.schedule_start !== "" && formik.values.schedule_end !== "" ?
                                        <div className="row float-right mt-1">
                                            <div className="product__actions-item product__actions-item--wishlist">
                                                <button
                                                    data-toggle="modal"
                                                    data-target=".bd-example-modal-lg"
                                                    type="button"
                                                    onClick={() => {
                                                        setServiceToken(product.token);
                                                    }}
                                                    className={classnames("btn btn-primary")}
                                                >
                                                    Continue
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                : product.schedule_time === "starting" ?
                                    <div>
                                        <div className="form-row mt-4">
                                            <div className="form-group col ">
                                                <label>From</label>
                                                <input
                                                    type="datetime-local"
                                                    className="form-control"
                                                    id="schedule_start"
                                                    value={formik.values.schedule_start}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.schedule_start && formik.errors.schedule_start ? (
                                                    <div className="text-danger">{formik.errors.schedule_start}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {formik.values.schedule_start !== "" ?
                                            <div className="row float-right mt-1">
                                                <div className="product__actions-item product__actions-item--wishlist">
                                                    <button
                                                        data-toggle="modal"
                                                        data-target=".bd-example-modal-lg"
                                                        type="button"
                                                        onClick={() => setServiceToken(product.token)
                                                        }
                                                        className={classnames("btn btn-primary")}
                                                    >
                                                        Continue
                                                    </button>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    <div className="row float-right mt-1">
                                        <div className="product__actions-item product__actions-item--wishlist">
                                            <button
                                                data-toggle="modal"
                                                data-target=".bd-example-modal-lg"
                                                type="button"
                                                onClick={() => setServiceToken(product.token)}
                                                className={classnames("btn btn-primary")}
                                            >
                                                Continue
                                            </button>

                                        </div>
                                    </div>
                            }

                            {/*modal for booking confirm and payment */}
                            <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog"
                                 aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content container">
                                        <div className="modal-header text-center">
                                            {/*<h5 className="modal-title" id="exampleModalLabel">New message</h5>*/}
                                            <h5 className="modal-title text-center">
                                                SELECT PAYMENT OPTION AND CONFIRM YOUR ORDER ITEMS
                                            </h5>
                                        </div>
                                        <br/>
                                        <PaymentOptioncomponent onClick={GetPaymentOption.bind(this)}/>
                                        <br/>

                                        <table className="cart__table cart-table">
                                            <thead className="cart-table__head">
                                            <tr className="cart-table__row  text-center">
                                                <th>
                                                    Choice Image
                                                </th>
                                                <th>
                                                    Choice name
                                                </th>

                                                <th className="cart-table__column cart-table__column--price">
                                                    Choice Price
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="cart-table__body">
                                            {arrayData.map((item, index) => (
                                                <tr key={item.id} className="cart-table__row">
                                                    <td className="cart-table__column cart-table__column--image">
                                                        <div className="product-image">
                                                            <Link
                                                                className="product-image__body"
                                                            >
                                                                <img
                                                                    className="product-image__img"
                                                                    src={item.choice_image[0].image_name}
                                                                    alt=""
                                                                />
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td className="cart-table__column text-center">
                                                        <Link
                                                            className="cart-table__product-name"
                                                        >
                                                            {item.choice_name}
                                                        </Link>
                                                    </td>
                                                    <td
                                                        className="cart-table__column cart-table__column--price"
                                                        data-title="Price"
                                                    >
                                                        <CurrencyFormat
                                                            value={item.choice_price.service_price}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            renderText={(value) => (
                                                                <div>
                                                                    {" "}
                                                                    {item.choice_price.currency} {value}
                                                                </div>
                                                            )}
                                                        />
                                                    </td>
                                                </tr>

                                            ))}

                                            </tbody>
                                        </table>
                                        <div className="cart__actions">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <h6>
                                                            <label>{arrayData.length} ITEMS</label>
                                                        </h6>
                                                    </div>
                                                    <div className="col-3">
                                                        <h6>
                                                            <label>TOTAL PRICE</label>
                                                        </h6>
                                                    </div>
                                                    <div className="col-3 ">
                                                        <h6>
                                                            <CurrencyFormat
                                                                value={NewPrice}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                renderText={(value) => (
                                                                    <div>
                                                                        {" "}
                                                                        {DefaultPrice.currency} {"  "} {value}
                                                                    </div>
                                                                )}
                                                            />
                                                        </h6>
                                                    </div>
                                                    <div className="col-3">
                                                        {localStorage.getItem("pushValue") === true ? (
                                                            <button
                                                                disabled={
                                                                    paymentOption ||
                                                                    localStorage.getItem("pushValue") === false
                                                                }
                                                                onClick={() => ConfirmOrder()}
                                                                type="submit"
                                                                data-dismiss="modal"
                                                                className="btn-sm btn btn-primary"
                                                            >
                                                                CONFIRM
                                                            </button>
                                                        ) : (
                                                            <button
                                                                disabled={
                                                                    !paymentOption
                                                                }
                                                                onClick={() => ConfirmOrder()}
                                                                type="submit"
                                                                data-dismiss="modal"
                                                                className="btn-sm btn btn-primary "
                                                            >
                                                                CONFIRM
                                                            </button>
                                                        )}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :

                        <div className="mt-4 text-capitalize">
                            {/*Please  <Link to="/account/login">Login</Link> or <Link TO="/account/register">create account</Link> to Book this service*/}

                            <h5>
                                Book Now
                            </h5>
                            {/*<div className="card-divider"/>*/}
                            {product.schedule_time === "both" ?
                                <div>
                                    <div className="form-row mt-4">
                                        <div className="form-group col ">
                                            <label>Full Name </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="full_name"
                                                value={formik.values.full_name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.full_name && formik.errors.full_name ? (
                                                <div className="text-danger">{formik.errors.full_name}</div>
                                            ) : null}
                                        </div>
                                        <div className="form-group col ">
                                            <label>Phone Number</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="phone_number"
                                                value={formik.values.phone_number}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.phone_number && formik.errors.phone_number ? (
                                                <div className="text-danger">{formik.errors.phone_number}</div>
                                            ) : null}
                                        </div>

                                        <div className="form-group col-md-12 ">
                                            <label>Email Address</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="guest_email"
                                                value={formik.values.guest_email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.guest_email && formik.errors.guest_email ? (
                                                <div className="text-danger">{formik.errors.guest_email}</div>
                                            ) : null}
                                        </div>

                                        <div className="form-group col-md-6 ">
                                            <label>From </label>
                                            <input
                                                type="datetime-local"
                                                className="form-control"
                                                id="schedule_start"
                                                value={formik.values.schedule_start}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.schedule_start && formik.errors.schedule_start ? (
                                                <div className="text-danger">{formik.errors.schedule_start}</div>
                                            ) : null}
                                        </div>
                                        <div className="form-group col-md-6 ">
                                            <label>To</label>
                                            <input
                                                type="datetime-local"
                                                className="form-control"
                                                id="schedule_end"
                                                value={formik.values.schedule_end}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.schedule_end && formik.errors.schedule_end ? (
                                                <div className="text-danger">{formik.errors.schedule_end}</div>
                                            ) : null}
                                        </div>

                                        <div className="form-group col-md-12">
                                            <label htmlFor="checkout-street-address">
                                                Location
                                            </label>
                                            <textarea
                                                type="text"
                                                className="form-control mt-2"
                                                id="location"
                                                placeholder="Additional delivery information"
                                                value={formik.values.location}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />

                                            {formik.touched.location && formik.errors.location ? (
                                                <div className="text-danger">
                                                    {formik.errors.location}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    {formik.values.schedule_start !== "" && formik.values.schedule_end !== "" && formik.values.full_name !== "" && formik.values.phone_number !== "" && formik.values.location !== "" ?
                                        <div className="row float-right mt-1">
                                            <div className="product__actions-item product__actions-item--wishlist">
                                                <button
                                                    data-toggle="modal"
                                                    data-target=".bd-example-modal-lg"
                                                    type="button"
                                                    onClick={() => {
                                                        handleFullName(formik.values.full_name);
                                                        handlePhoneNumber(formik.values.phone_number);
                                                        handleEmail(formik.values.guest_email);
                                                        handleLocation(formik.values.location);
                                                        setServiceToken(product.token)
                                                    }}
                                                    className={classnames("btn btn-primary")}
                                                >
                                                    Continue
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                                : product.schedule_time === "starting" ?
                                    <div>
                                        <div className="form-row mt-4">
                                            <div className="form-group col ">
                                                <label>Full Name </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="full_name"
                                                    value={formik.values.full_name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.full_name && formik.errors.full_name ? (
                                                    <div className="text-danger">{formik.errors.full_name}</div>
                                                ) : null}
                                            </div>
                                            <div className="form-group col ">
                                                <label>Phone Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="phone_number"
                                                    value={formik.values.phone_number}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.phone_number && formik.errors.phone_number ? (
                                                    <div className="text-danger">{formik.errors.phone_number}</div>
                                                ) : null}
                                            </div>

                                            <div className="form-group col-md-12 ">
                                                <label>Email Address</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="guest_email"
                                                    value={formik.values.guest_email}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.guest_email && formik.errors.guest_email ? (
                                                    <div className="text-danger">{formik.errors.guest_email}</div>
                                                ) : null}
                                            </div>
                                            <div className="form-group col-md-12 ">
                                                <label>From</label>
                                                <input
                                                    type="datetime-local"
                                                    className="form-control"
                                                    id="schedule_start"
                                                    value={formik.values.schedule_start}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.schedule_start && formik.errors.schedule_start ? (
                                                    <div className="text-danger">{formik.errors.schedule_start}</div>
                                                ) : null}
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label htmlFor="checkout-street-address">
                                                    Location
                                                </label>
                                                <textarea
                                                    type="text"
                                                    className="form-control mt-2"
                                                    id="location"
                                                    placeholder="Additional delivery information"
                                                    value={formik.values.location}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />

                                                {formik.touched.location && formik.errors.location ? (
                                                    <div className="text-danger">
                                                        {formik.errors.location}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        {formik.values.schedule_start !== "" && formik.values.full_name !== "" && formik.values.phone_number !== "" && formik.values.location ?
                                            <div className="row float-right mt-1">
                                                <div className="product__actions-item product__actions-item--wishlist">
                                                    <button
                                                        data-toggle="modal"
                                                        data-target=".bd-example-modal-lg"
                                                        type="button"
                                                        onClick={() => {
                                                            handleFullName(formik.values.full_name);
                                                            handlePhoneNumber(formik.values.phone_number);
                                                            handleEmail(formik.values.guest_email);
                                                            handleLocation(formik.values.location);
                                                            setServiceToken(product.token)
                                                        }}
                                                        className={classnames("btn btn-primary")}
                                                    >
                                                        Continue
                                                    </button>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                    :
                                    <div>
                                        <div className="form-row mt-4">
                                            <div className="form-group col ">
                                                <label>Full Name </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="full_name"
                                                    value={formik.values.full_name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.full_name && formik.errors.full_name ? (
                                                    <div className="text-danger">{formik.errors.full_name}</div>
                                                ) : null}
                                            </div>
                                            <div className="form-group col ">
                                                <label>Phone Number</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="phone_number"
                                                    value={formik.values.phone_number}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.phone_number && formik.errors.phone_number ? (
                                                    <div className="text-danger">{formik.errors.phone_number}</div>
                                                ) : null}
                                            </div>

                                            <div className="form-group col-md-12 ">
                                                <label>Email Address</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="guest_email"
                                                    value={formik.values.guest_email}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.guest_email && formik.errors.guest_email ? (
                                                    <div className="text-danger">{formik.errors.guest_email}</div>
                                                ) : null}
                                            </div>
                                            <div className="form-group col-md-12">
                                                <label htmlFor="checkout-street-address">
                                                    Location
                                                </label>
                                                <textarea
                                                    type="text"
                                                    className="form-control mt-2"
                                                    id="location"
                                                    placeholder="Additional delivery information"
                                                    value={formik.values.location}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />

                                                {formik.touched.location && formik.errors.location ? (
                                                    <div className="text-danger">
                                                        {formik.errors.location}
                                                    </div>
                                                ) : null}
                                            </div>
                                            <div className="row float-right mt-1">
                                                <div className="product__actions-item product__actions-item--wishlist">
                                                    <button
                                                        data-toggle="modal"
                                                        data-target=".bd-example-modal-lg"
                                                        type="button"
                                                        onClick={() => {
                                                            handleFullName(formik.values.full_name);
                                                            handlePhoneNumber(formik.values.phone_number);
                                                            handleEmail(formik.values.guest_email);
                                                            handleLocation(formik.values.location);
                                                            setServiceToken(product.token);
                                                        }}
                                                        className={classnames("btn btn-primary")}
                                                    >
                                                        Continue
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                            }

                            {/*modal for booking confirm and payment */}
                            <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog"
                                 aria-labelledby="myLargeModalLabel" aria-hidden="true">
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content container">
                                        <div className="modal-header text-center">
                                            {/*<h5 className="modal-title" id="exampleModalLabel">New message</h5>*/}
                                            <h5 className="modal-title text-center">
                                                SELECT PAYMENT OPTION AND CONFIRM YOUR ORDER ITEMS
                                            </h5>
                                        </div>
                                        <br/>
                                        <PaymentOptioncomponent onClick={GetPaymentOption.bind(this)}/>
                                        <br/>

                                        <table className="cart__table cart-table">
                                            <thead className="cart-table__head">
                                            <tr className="cart-table__row  text-center">
                                                <th>
                                                    Choice Image
                                                </th>
                                                <th>
                                                    Choice name
                                                </th>

                                                <th className="cart-table__column cart-table__column--price">
                                                    Choice Price
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody className="cart-table__body">
                                            {arrayData.map((item, index) => (
                                                <tr key={item.id} className="cart-table__row">
                                                    <td className="cart-table__column cart-table__column--image">
                                                        <div className="product-image">
                                                            <Link
                                                                className="product-image__body"
                                                            >
                                                                <img
                                                                    className="product-image__img"
                                                                    src={item.choice_image[0].image_name}
                                                                    alt=""
                                                                />
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td className="cart-table__column text-center">
                                                        <Link
                                                            className="cart-table__product-name"
                                                        >
                                                            {item.choice_name}
                                                        </Link>
                                                    </td>
                                                    <td
                                                        className="cart-table__column cart-table__column--price"
                                                        data-title="Price"
                                                    >
                                                        <CurrencyFormat
                                                            value={item.choice_price.service_price}
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            renderText={(value) => (
                                                                <div>
                                                                    {" "}
                                                                    {item.choice_price.currency} {value}
                                                                </div>
                                                            )}
                                                        />
                                                    </td>
                                                </tr>

                                            ))}

                                            </tbody>
                                        </table>
                                        <div className="cart__actions">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <h6>
                                                            <label>{arrayData.length} ITEMS</label>
                                                        </h6>
                                                    </div>
                                                    <div className="col-3">
                                                        <h6>
                                                            <label>TOTAL PRICE</label>
                                                        </h6>
                                                    </div>
                                                    <div className="col-3 ">
                                                        <h6>
                                                            <CurrencyFormat
                                                                value={NewPrice}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                renderText={(value) => (
                                                                    <div>
                                                                        {" "}
                                                                        {DefaultPrice.currency} {"  "} {value}
                                                                    </div>
                                                                )}
                                                            />
                                                        </h6>
                                                    </div>
                                                    <div className="col-3">
                                                        {localStorage.getItem("pushValue") === true ? (
                                                            <button
                                                                disabled={
                                                                    paymentOption ||
                                                                    localStorage.getItem("pushValue") === false
                                                                }
                                                                onClick={() => ConfirmOrder()}
                                                                type="submit"
                                                                data-dismiss="modal"
                                                                className="btn-sm btn btn-primary"
                                                            >
                                                                CONFIRM
                                                            </button>
                                                        ) : (
                                                            <button
                                                                disabled={
                                                                    !paymentOption
                                                                }
                                                                onClick={() => ConfirmOrder()}
                                                                type="submit"
                                                                data-dismiss="modal"
                                                                className="btn-sm btn btn-primary "
                                                            >
                                                                CONFIRM
                                                            </button>
                                                        )}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <br/>
                                        <br/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    }
                </div>
                {/*digital products*/}
            </div>
            <div>
                <ServiceChoiceCard
                    title="Additional choices"
                    layout="grid-4"
                    services={services}
                    serviceId={arrayData}
                    handleRemove={handleRemove.bind(this)}
                    onClick={TotalPrice.bind(this)}
                />
            </div>
        </div>
    );

}

Product.propTypes = {
    /** product object */
    product: PropTypes.object.isRequired,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
};

Product.defaultProps = {
    layout: "standard",
};

const mapDispatchToProps = {
    cartAddItem,
    wishlistAddItem,
    compareAddItem,
    QuoteAddItem,
    digitalAddBuynow,
    ProductDetails,
};

export default connect(() => ({}), mapDispatchToProps)(Product);