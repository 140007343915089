import { FETCH_CUSTOMER } from "./customerActionTypes";
import Axios from "axios";
import { agenttoken } from "../../api/shop";
import { toast } from "react-toastify";

export function FetchCustomers() {
  const token = agenttoken();
  const data = {
      agent_token: token,
  };
  return async (dispatch) =>
    Axios.post(window.$name + "/api/agent/get-customer-registered", data)
      .then(function (response) {
        if (response.data.status === 401) {
          dispatch({ type: FETCH_CUSTOMER, payload: [] });
        } else {
          dispatch({ type: FETCH_CUSTOMER, payload: response.data });
        }
      })
      .catch(function (error) {
        if (error.response) {
          dispatch({ type: FETCH_CUSTOMER, payload: [] });
          toast.error(
            `"${error.response.data.message}" Error Status  "${error.response.status}" `
          );
        } else if (error.request) {
          dispatch({ type: FETCH_CUSTOMER, payload: [] });
          toast.error(`"${error.request}" `);
        } else {
          dispatch({ type: FETCH_CUSTOMER, payload: [] });
          toast.error(`"${error.message}" `);
        }
      });
}

export function AddCustomers(customer) {
  const token = agenttoken();
  const data= {
      agent_secure_token: token,
      fullname: customer.fullname,
      user_phoneNumber: customer.user_phoneNumber,
      email: customer.email,
      username:customer.username
  }
  return async (dispatch) =>
    Axios.post(window.$name + "/api/auth/agent-shop-user",data)
      .then(function (response) {
        if(response.status===201){
          toast.success(`${response.data.message}`);
          dispatch(FetchCustomers());
        }
        else
        {
            toast.error("Something is wrong");
        }
        
      })
      .catch(function (error) {
        if (error.response) {
          toast.error(
            `"${error.response.data.message}" Error Status  "${error.response.status}" `
          );
        } else if (error.request) {
          toast.error(`"${error.request}" `);
        } else {
          toast.error(`"${error.message}" `);
        }
      });
}


