// react
import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import theme from "../../data/theme";
import { connect, useDispatch } from "react-redux";
import { agenttoken } from '../../api/shop';
// import { EditProfile ,Fetchprofile} from "../../store/profile";
import Moment from "react-moment";
import { Pagination, Search } from "../DataTable";
import CurrencyFormat from "react-currency-format";
function GeneratedNumber(props) {
    const dispatch = useDispatch();
    const [orders, setOrders] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const ITEMS_PER_PAGE = 5;
    useEffect(() => {
        const getData = () => {
            axios
                .post(window.$name + "/api/agent/get/master-control-orders", {
                    agent_token: agenttoken(),
                })
                .then((response) => response.data)
                .then((json) => {
                    setOrders(json);
                });
        };
        getData();
    }, []);   
    const orderData = useMemo(() => {
        let computedOrders = orders;
        if (search) {
            computedOrders = computedOrders.filter(
                (order) =>
                    order.master_control_number.toLowerCase().includes(search.toLowerCase()) ||
                    order.payment_status.toLowerCase().includes(search.toLowerCase()) ||
                    order.amount_paid.toLowerCase().includes(search.toLowerCase())
            );
        }
        setTotalItems(computedOrders.length);
        //Current Page slice
        return computedOrders.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [orders, currentPage, search]);
    return (
        <div className="dashboard">
            <Helmet>
                <title>{`Ezytrade Agent — ${theme.name}`}</title>
            </Helmet>

            <div className="cardTop card">
                <div className="card-header">
                    {/* <div className="">RECENT ORDERS</div> */}
                    <div className="d-flex align-items-end flex-column">
                        <div className="mt-auto p-2">
                            <Search
                                onSearch={(value) => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                                <tr className="text-center">
                                    <th>Master Number</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Created Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderData.slice(0, 10).map((order) => (
                                    <tr className="text-center">
                                        <td>{order.master_control_number}</td>
                                        <td>
                                            <CurrencyFormat
                                                value={order.amount_paid}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                renderText={(value) => (
                                                    <div>
                                                        {" "}
                                                        {order.currency} {"  "} {value}
                                                    </div>
                                                )}
                                            />
                                        </td>
                                        {order.payment_status === "Pending" ? (
                                            <td className="text-danger font-weight-bold">Pending</td>
                                        ) : order.payment_status === "Paid" ? (
                                            <td className="text-success font-weight-bold">Paid</td>
                                        ) : order.payment_status === "Shipped" ? (
                                            <td className="text-warning font-weight-bold">Shipped</td>
                                        ) : order.payment_status === "Delivered" ? (
                                            <td className="text-primary font-weight-bold">
                                                Delivered
                                            </td>
                                        ) : (
                                                            <></>
                                                        )}
                                        <td>
                                            <Moment format="DD/MM/YYYY">{order.created_at}</Moment>
                                        </td>
                                        <td>
                                            <Link
                                                to={{ pathname: "/agent/master/number/vieworders", state: order.master_control_number }}
                                            >
                                                View More
                      </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-divider" />
                <div className="card-footer">
                    {/* <div className="d-flex align-items-center flex-column">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div> */}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = {
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GeneratedNumber);
