// react
import React from 'react';
import CurrencyFormat from "react-currency-format";
// third-party
import classNames from 'classnames';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import {Cart16Svg} from '../../svg';
import {cartAddItem} from '../../store/cart';
import {url} from '../../services/utils';


function ServiceSuggestions(props) {
    const {
        context,
        className,
        products,
        cartAddItem,
        query,
        category
    } = props;
    const userCurrency = localStorage.getItem("userCurrency");

    function handelquery() {
        localStorage.setItem("dataquery", query)
        // localStorage.setItem("datacategory", category);
    }

    const rootClasses = classNames(`suggestions suggestions--location--${context}`, className);
    const list = (products && products.slice(0, 5).map((product) => (
        <Link
            to={`/services-product/${product.token}`}
            target="_blank"
        >
            <li key={product.id} className="suggestions__item">
                {/* {product.images && product.images.length > 0 && ( */}
                <div className="suggestions__item-image product-image">
                    <div className="product-image__body">
                        <img className="product-image__img" src={product.images.image_name} alt=""/>
                    </div>
                </div>
                {/* )} */}
                <div className="suggestions__item-info">
                    <Link className="suggestions__item-name text-dark">
                        <h6>{product.name}</h6>
                    </Link>
                    <div className="suggestions__item-meta">
                        {product.description}
                    </div>
                </div>
                <div className="suggestions__item-price">
                    <Link
                        target="_blank"
                        to={`/services-product/${product.token}`}
                    >
                    More Details
                    </Link>
                </div>
            </li>
        </Link>
    )));

    return (
        <div className={rootClasses}>
            {
                products && products.length > 0 ? (
                    <>
                        <ul className="suggestions__list">
                            {list}
                        </ul>
                        {products.length > 2 ? (
                                <Link
                                    onClick={() => handelquery()}
                                    target="_blank"
                                    to="/all-searched-service"
                                    className="d-flex justify-content-center"
                                >
                                    <h6 className="text-body">View All Results</h6>
                                </Link>

                            )
                            : null
                        }

                    </>
                ) : (
                    <div className="d-flex justify-content-center">
                        <img src="images/nodata-found.png" alt=""/>

                    </div>
                )
            }

        </div>
    );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ServiceSuggestions);
