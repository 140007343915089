// react
import React from "react";
// third-party
import { Helmet } from "react-helmet-async";
// application
import PageHeader from "../shared/PageHeader";
// data stubs
import theme from "../../data/theme";
import { connect } from "react-redux";
import { EmailVerification} from "../../store/auth/userActions";
import AsyncAction from "../shared/AsyncAction";
import classNames from "classnames";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
const initialValues = {
  email: "",
};
//creating the validation schema
const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter your valid email")
    .required("Email is a required field"),
});

function Forgotpassword(props) {
  const {EmailVerification } = props;
  //using useFormik
  const formik = useFormik({
    initialValues,
    validationSchema,
  });

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Login — ${theme.name}`}</title>
      </Helmet>
      <PageHeader />
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <label htmlFor="login-email">Email address</label>
          <input
            id="email"
            type="email"
            name="email"
            className="form-control"
            placeholder="Enter Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-danger">{formik.errors.email}</div>
          ) : null}
        </div>
        <div className="form-group">
          <div className="text-right">
          <AsyncAction
          action={() => EmailVerification(formik.values)}
          render={({ run, loading }) => (
            <button
              type="submit"
              data-toggle="tooltip"
              data-placement="right"
              title="login"
              onClick={run}
              className={classNames("btn btn-primary rounded", {
                "btn-loading": loading,
              })}
              disabled={!(formik.isValid && formik.dirty)}
            >
             Send Now
            </button>
          )}
            />
          </div>
        </div>
        <div className="row mt-4 container">
          <Link to="/account/login" className="text-muted">
            Go back to ? <span className="text-primary">Login now</span>
          </Link>
        </div>
      </form>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  authentication: state.auth,
});
const mapDispatchToProps = {
  EmailVerification
};
export default connect(mapStateToProps, mapDispatchToProps)(Forgotpassword);
