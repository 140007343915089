// react
import React from "react";

// data stubs
import theme from "../../data/theme";

export default function FooterContacts() {
  return (
    <div className="site-footer__widget footer-contacts">
      <h5 className="footer-contacts__title">Contact Us</h5>
      <ul className="footer-contacts__contacts">
        <li>
          <i className="footer-contacts__icon fas fa-globe-americas" />
          3rd Floor UMATI Building,
          <br />
          Samora Av/Zanaki St - Dar es Salaam
          <br />
          P.O.Box 5382,Dar es salaam
        </li>
        <li>
          <i className="footer-contacts__icon far fa-envelope" />
          support@ezytrade.africa
        </li>
        <li >
          <i className="footer-contacts__icon fas fa-mobile-alt" />
          Call Us: +255 800 750 170

        </li>
        <li>
          <i className="footer-contacts__icon fab fa-whatsapp text-success" />
          WhatsApp: +255 743 779 999
        </li>

        <li>
          <i className="footer-contacts__icon far fa-clock" />
          Mon-Sun 08:00am - 08:00pm
        </li>
      </ul>
    </div>
  );
}
