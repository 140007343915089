import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import theme from "../../../data/theme";
import {connect, useDispatch} from "react-redux";
import {Pagination, Search} from "../../DataTable";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import Axios from "axios";
import {FetchListing} from "../../../store/Listings";
import {FetchDeleteListing} from "../../../store/DeleteLitsting";

function DeletedListing(props) {
    const dispatch = useDispatch();
    const {ListingDeleted} = props
    const [ListingId, setListingId] = useState("")
    // console.log(ListingDeleted)
    function RestoreListing() {
        Axios.post(`${window.$name}/api/directory/consumer/restore/deleted/listings/${ListingId}`)
            .then((response) => response.data);
        dispatch(FetchDeleteListing())
        dispatch(FetchListing())
    };
    const handleData = (id) => {
        setListingId(id);
    }
    const Listings = (
        <>
            {ListingDeleted.map((index) => (
                <tr>
                    <td>{index.listing_title}</td>
                    <td>{index.slug}</td>
                    <td>
                        <Moment format="DD/MM/YYYY">{index.created_at}</Moment>
                    </td>
                    <td>
                        <div className="dropdown">
                            <button
                                className="btn btn-white"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <b>...</b>
                            </button>
                            <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                            >
                                <button
                                    className="dropdown-item text-danger"
                                    onClick={() => handleData(index.id)}
                                    data-toggle="modal"
                                    data-target="#cancelModal2"
                                >
                                  Restore
                                </button>

                            </div>
                        </div>
                    </td>
                </tr>
            ))
            }

        </>

    );
    return (
        <React.Fragment>
            <Helmet>
                <title>{`add listing — ${theme.name}`}</title>
            </Helmet>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex align-items-end flex-column">
                            <div className="mt-auto p-2">
                                <Search
                                    // onSearch={(value) => {
                                    //     setSearch(value);
                                    //     setCurrentPage(1);
                                    // }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-divider"/>
                    <div className="card-table">
                        <div className="table-responsive-sm">
                            <table>
                                <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Slug</th>
                                    <th>Data</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>{Listings}</tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-divider"/>
                    <div className="card-footer">
                        <div className="d-flex align-items-center flex-column">
                            <Pagination
                                // total={totalItems}
                                // itemsPerPage={ITEMS_PER_PAGE}
                                // currentPage={currentPage}
                                // onPageChange={(page) => setCurrentPage(page)}
                            />
                        </div>
                    </div>
                </div>
                {/*delete listing*/}
                <div className="modal fade" id="cancelModal2" tabIndex="-1"
                     role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close"
                                        data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body"> Are you sure want to Restore this Listing</div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close
                                </button>
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    onClick={RestoreListing}
                                    className="btn btn-danger">
                                    Accept
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeletedListing)
