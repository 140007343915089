// react
import React from 'react';
// third-party
import {Helmet} from 'react-helmet-async';
// application
// data stubs
import theme from '../../data/theme';
function SitePageIprpp() {
    return (
        <div className="block about-us">
            <Helmet>
                <title>{`Term of Use — ${theme.name}`}</title>
            </Helmet>
            <div className="about-us__image" style={{backgroundImage: 'url("")'}}/>
            <div>
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                        <div className="about-us__body">
                            <h1 className="about-us__title">Intellectual Property Rights Protection Policy</h1>
                            <h5 className="d-flex justify-content-center">Effective from January 6, 2020</h5>
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left" type="button"
                                                    data-toggle="collapse" data-target="#collapseOne"
                                                    aria-expanded="true" aria-controls="collapseOne">
                                                1.Overview
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            EZYTRADE AFRICA (“ezytrade.africa”) respects intellectual property rights of
                                            others and we expect our users to honor the same.
                                            Ezytrade.africa takes a serious stance in dealing with intellectual property
                                            infringement claims on our websites at www.ezytrade.africa (Referred to as
                                            "Site").
                                            We also rely on the cooperation of intellectual property rights owners.
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseTwo"
                                                    aria-expanded="false" aria-controls="collapseTwo">
                                                2.Intellectual Property Right (“IPR”) Protection
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                Listings of counterfeits, replicas, or other unauthorized items are
                                                prohibited on the Site strictly.
                                                <br/>
                                                Listings of offers to sell or purchase counterfeits, replicas,
                                                or other unauthorized items shall be subject to removal by
                                                Ezytrade.africa
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseThree"
                                                    aria-expanded="false" aria-controls="collapseThree">

                                                3．Repeat violations of our IPR policy may result in a range of actions,
                                                including without limitation to:

                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                Removal of listings
                                            </p>
                                            <p>
                                                Posting restrictions
                                            </p>
                                            <p>
                                                Account suspension
                                            </p>
                                            <p> Termination of membership service agreement
                                                <br/>
                                                    Ezytrade.africa has the full discretion to take any enforcement action anytime
                                                    for certain extreme / conspicuous situations, such as but
                                                    not limited to where litigation has already been initiated by intellectual property rights holder.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseFour"
                                                    aria-expanded="false" aria-controls="collapseFour">
                                                4.Infringement Claims
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFour" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                         <p>All intellectual property infringement claims shall be made under penalty of perjury.
                                             Intellectual property right holders further agree to indemnify and hold
                                             Ezytrade.africa harmless from all claims, causes of action,
                                             damages and judgments arising out of any removal of product
                                             listings pursuant to intellectual property infringement claims.

                                          </p>
                                            <p>
                                                As a neutral e-commerce platform Ezytrade.africa does not adjudge conflicting intellectual
                                                property infringement claims. Any action taken by Ezytrade.africa shall not be construed as
                                                any endorsement of any intellectual property infringement claim. All conflicting intellectual
                                                property infringement claims shall be resolved by the relevant conflicting parties separately
                                                from Ezytrade and the Site.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseFive"
                                                    aria-expanded="false" aria-controls="collapseFive">
                                                5．IP Protection Platform (“IPP Platform”), the online reporting
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFive" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                Intellectual property right holders shall file intellectual property infringement claims via emai
                                                at support@ezytrade.africa
                                            </p>
                                            <p>
                                                Three types of materials must be submitted to facilitate processing of intellectual property
                                                infringement claims, namely:
                                            </p>
                                            <p>
                                                1. Proof of identity of the complaining party and relevant authorization if the
                                                complaining party is not the intellectual property right holder;
                                                <br/>
                                                2. Proof of intellectual property ownership;
                                                <br/>
                                                3. Exact clickable hyperlinks to the relevant allegedly infringing listings on the Site
                                            </p>
                                            <p>
                                                Ezytrade.africa will evaluate intellectual property infringement claims filed promptly.
                                                Members subject to intellectual property infringement claims will be notified of the claims
                                                and the contact information of the intellectual property right holders will be provided to the
                                                members to facilitate direct conflict resolution and claim handling.
                                            </p>
                                            <p>
                                                Members subject to intellectual property infringement claims may submit counter notifications to dispute the corresponding claims. Intellectual property right holders will be
                                                notified if and when any counter-notifications are filed and may accept or reject such
                                                counter-notifications. Ezytrade.africa reserves the right to reinstate any listing subject to
                                                intellectual property infringement claims at our discretion.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}
export default SitePageIprpp;
