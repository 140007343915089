// react
import React ,{useEffect} from 'react';

// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';
import ToTop from './ToTop';
import WhatsApp from './whatsAppWidget';
import classNames from "classnames";
import theme from "../../data/theme";

export default function Footer(props) {
 //    useEffect(() => {
	// 	var Tawk_API = Tawk_API || {};
	// 	const Tawk_LoadStart = new Date();
	// 	(function () {
	// 		const s1 = document.createElement('script');
	// 		const s0 = document.getElementsByTagName('script')[0];
	// 		s1.async = true;
	// 		s1.src = 'https://embed.tawk.to/5f911fabfd4ff5477ea7f108/default';
	// 		s1.charset = 'UTF-8';
	// 		s1.setAttribute('crossorigin', '*');
	// 		s0.parentNode.insertBefore(s1, s0);
	// 	})();
		
	// }, []);
    const { shape, className } = props;
    const classes = classNames(className, 'social-links', {
        'social-links--shape--circle': shape === 'circle',
        'social-links--shape--rounded': shape === 'rounded',
    });
    const items = [
        { type: 'facebook', url:'https://web.facebook.com/ezytradeafrica', icon: 'fab fa-facebook-f' },
        { type: 'twitter', url: theme.author.profile_url, icon: 'fab fa-twitter' },
        { type: 'youtube', url: theme.author.profile_url, icon: 'fab fa-youtube' },
        { type: 'instagram', url: 'https://www.instagram.com/ezytrade.africa/', icon: 'fab fa-instagram' },
    ]
    const informationLinks = [
        { title: 'Terms of Use', url: '/terms-condition'},
        { title: 'Privacy Policy', url: '/privacy-policy' },
        { title: 'Shipping Policy', url: '/shipping-policy' },
        { title: 'Transaction Service Agreement', url: '/Transaction-service-agreement' },
        { title: 'Supplemental Services Agreement', url: '/suppremental-service-agreement' },
        { title: 'Intellectual Property Rights Protection Policy', url: '/Intellectual-property' },
    ];
    const JoinUsLinks = [
        { title: 'Sell On Ezytrade', url: '/sell-on-Ezytrade' },
        { title: 'Become Our Agent', url: '/Agent/Registration' },
        { title: 'Pay With Ezypay', url: '/' },
        { title: 'Kago', url: 'https://kago.ezytrade.africa/' },
    ];
    const accountLinks = [
        { title: 'Dashboard', url: '/account/dashboard' },
        { title: 'Wishlist', url: '/shop/wishlist' },
        { title: 'Order History', url: '/account/orders/5' },
        { title: 'Create  Account', url: '/account/register' },
        { title: 'Login now', url: '/account/login' },
        // { title: 'Help & Support', url: '' },
    ];
    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <FooterContacts />
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <FooterLinks title="Terms" items={informationLinks} />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="My Account" items={accountLinks}  />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="Join Us" items={JoinUsLinks}   />
                        </div>
                    </div>
                </div>
                <div className="site-footer__bottom">
                    <div className="site-footer__copyright text-center">
                         <a href="https://ezytrade.africa/" rel="noopener noreferrer" target="_blank">
                            © 2019 - {new Date().getFullYear()} Ezytrade Africa All rights reserved
                        </a>
                    </div>
                    <div className="site-footer__payments">
                        <ul className="social-links__list social-links--shape--circle">
                        {items.map((item) => (
                        <li key={item.type} className="social-links__item">
                            <a
                                className={`social-links__link social-links__link--type--${item.type}`}
                                href={item.url}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <i className={item.icon} />
                            </a>
                        </li>
                        ))}
                        </ul>
                    </div>
                </div>
            </div>
            <WhatsApp />
            <ToTop/>
        </div>
    );
}
