// react
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { url } from "../../services/utils";
import { handleSubmits, handleBacks } from "./checkout";
import theme from "../../data/theme";
import CurrencyFormat from "react-currency-format";
import Axios from "axios";
import { toast } from "react-toastify";
import { customertoken, agenttoken, PaymentToken,agentcustomerToken, userCurrency } from "../../api/shop";
import { cartRemove } from "../../store/cart";
import { Row } from "reactstrap";
import PaymentOptioncomponent from "../shop/paymentOption";
class ShopPageCart extends Component { 
  constructor(props) {
    super(props);
    this.state = {
        paymentOption_token:false
    };
}   
toggle(value) {   
    this.setState({    
        paymentOption_token: value,
    });
}
  renderCart() {    
    // console.log(this.state.paymentOption_token);
    const { activeStep, shipping, quoteItem, cartRemove, Rates, MyownShipping, Myownaddress } = this.props;
       function AddressData() {
      return {
        address: quoteItem.quotes.shipping_address,
      }
    }
    function DeliverOption() {
      return {
        Deliver: quoteItem.quotes.customer_delivery
      }
    }
    const agentcustomer_Token = agentcustomerToken();
    const agentToken = agenttoken();
    function TotalAmount() {
      let amount;
      if (userCurrency() === quoteItem.quotes.requestCurrency) {
        amount = Math.round((Number(quoteItem.quotes.totalAmountPaid) / Number(Rates[quoteItem.quotes.requestCurrency])) * Number(Rates[userCurrency()]))
        return amount;
      }
      else {
        amount = ((Number(quoteItem.quotes.totalAmountPaid) / Number(Rates[quoteItem.quotes.requestCurrency])) * Number(Rates[userCurrency()])).toFixed(2)
        return amount;
      }

    }
    function TotalVat() {
      let amount;
      if (userCurrency() === quoteItem.quotes.requestCurrency) {
        amount = Math.round((Number(quoteItem.quotes.totalVatPaid) / Number(Rates[quoteItem.quotes.requestCurrency])) * Number(Rates[userCurrency()]))
        return amount;
      }
      else {
        amount = ((Number(quoteItem.quotes.totalVatPaid) / Number(Rates[quoteItem.quotes.requestCurrency])) * Number(Rates[userCurrency()])).toFixed(2)
        return amount;
      }
    }
    function ConfirmOrder(quoteItem) {
      if (agentcustomer_Token !== null && agentToken !== null) {
        let data = {
          user_token: agentcustomer_Token,
          shippingAddress_token: AddressData().address.secure_token,
          payment_option:PaymentToken(),
          total_amount: TotalAmount(),
          agent_token: agentToken,
          currency: userCurrency(),
          total_vat: TotalVat(),
          shipping_options: DeliverOption().Deliver.secure_token,
          cart_details: JSON.stringify(quoteItem.QuotesProduct),
        };
        Axios.post(window.$name + "/api/order/create-order", data)
          .then((response) => {
            toast.success(` "${response.data.message}"`);
            localStorage.setItem(
              "success_data",
              JSON.stringify(response.data.payload)
            );
            cartRemove();
            localStorage.removeItem("paymentOption_token");
            handleSubmits(activeStep);
          })
          .catch(function (error) {
            if (error.response) {
              toast.error(
                `"${error.response.data.message}" Error Status  "${error.response.status}" `
              );
            } else if (error.request) {
              toast.error(`"${error.request}" `);
            } else {
              toast.error(`"${error.message}" `);
            }
          });
      } else {
        let data = {
          user_token: customertoken(),
          shippingAddress_token: AddressData().address.secure_token,
          payment_option:PaymentToken(),
          total_amount: TotalAmount(),
          agent_token: "",
          currency: userCurrency(),
          total_vat: TotalVat(),
          shipping_options: DeliverOption().Deliver.secure_token,
          cart_details: JSON.stringify(quoteItem.QuotesProduct),
        };
        Axios.post(window.$name + "/api/order/create-order", data)
          .then((response) => {
            toast.success(` "${response.data.message}"`);
            localStorage.setItem(
              "success_data",
              JSON.stringify(response.data.payload)
            );
            cartRemove();
            localStorage.removeItem("paymentOption_token");
            handleSubmits(activeStep);
          })
          .catch(function (error) {
            if (error.response) {
              toast.error(
                `"${error.response.data.message}" Error Status  "${error.response.status}" `
              );
            } else if (error.request) {
              toast.error(`"${error.request}" `);
            } else {
              toast.error(`"${error.message}" `);
            }
          });
      }
    }
    return (
      <div className="cart d-flex justify-content-center">
        <div className="card">
          <div className="card-title">
            <br />
            <h5 className="text-center">
              SELECT PAYMENT OPTION AND CONFIRM YOUR ORDER ITEMS
            </h5>
          </div>
          <div className="card-body">
            {
              DeliverOption().Deliver.customer_shipping === "I will arrange Delivery" ? (
                <div>
                  <h3> Pickup information</h3>
                  <div>
                    <div>
                      <p>
                        Full Name :   &nbsp;&nbsp;{AddressData().address.address_fullname}
                      </p>
                      <p>
                        Mobile :  &nbsp;&nbsp;{AddressData().address.address_mobileNumber}
                      </p>
                      <p>Id Type :  &nbsp;&nbsp;{AddressData().address.id_type}
                      </p>
                      <p>
                        Id Number :  &nbsp;&nbsp;{AddressData().address.id_number}
                      </p>
                    </div>
                  </div>
                </div>

              ) : (
                  <div>
                    <p className="font-weight-bold">Shipping Address to:</p>
                    <div>
                      <p >
                        <span className="text-uppercase">
                          {AddressData().address.address_fullname}
                        </span>
                      </p>
                      <p>
                        <span>{AddressData().address.address_mobileNumber}
                        or {AddressData().address.address_mobileNumber2}</span>

                      </p>
                      <p className="text-capitalize">
                        {AddressData().address.country.country}, {AddressData().address.region.region},{" "}
                        {AddressData().address.address_district}
                      </p>
                      <p>Post Code : {AddressData().address.address_postcode} </p>
                      <p>House Number : {AddressData().address.address_house_number}</p>
                      <p>Area Name : {AddressData().address.area}</p>
                      <p>Address Type : {AddressData().address.address_AddressType}</p>
                    </div>
                  </div>

                )
            }
            <br />
            <PaymentOptioncomponent onClick={this.toggle.bind(this)}/>
            <div className="container">
              <div className="card">
                <div className="card-divider" />
                <div className="card-table">
                  <div className="table-responsive-sm">
                    <table>
                      <thead>
                        <tr className="text-center">
                          <th>Product image</th>
                          <th>Product name</th>
                          <th >
                            Preference
                         </th>
                          <th>Quantity</th>
                          <th>Amount</th>
                          <th>VAT</th>
                          <th>Total Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {quoteItem.QuotesProduct.map((quote) => (
                          <tr className="text-center">
                            <td>
                              <div className="product-image">
                                <Link className="product-image__body">
                                  <img
                                    className="product-image__img"
                                    src={quote.product_images.productImage_name}
                                    alt=""
                                  />
                                </Link>
                              </div>
                            </td>
                            <td>
                              <Link className="cart-table__product-name">
                                {quote.product.product_name}
                              </Link>
                            </td>
                            <td className="order-list__column-product">
                              {quote.preference_color !== null && quote.preference_size !== null ? (
                                <>
                                  <Row>
                                    Color :&nbsp;{quote.preference_color}
                                  </Row>
                                  <Row>
                                    Size :&nbsp;{quote.preference_size}
                                  </Row>
                                </>
                              ) : quote.preference_color !== null && quote.preference_size === null ? (
                                <Row>
                                  Color :&nbsp;{quote.preference_color}
                                </Row>
                              ) : quote.preference_color === null && quote.preference_size !== null ? (
                                <Row>
                                  Size :&nbsp;{quote.preference_size}
                                </Row>
                              ) :
                                    <span>Empty</span>
                              }
                            </td>
                            <td>{quote.quantity}</td>
                            <td className="font-weight-bold">
                              {/* <CurrencyFormat
                                  value={quote.total_amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  renderText={(value) => (
                                    <div>
                                      {" "}
                                      {quote.currency}{value}
                                    </div>
                                  )}
                                /> */}
                              {userCurrency() === quote.currency ? (
                                <CurrencyFormat
                                  value={Math.round((Number(quote.total_amount) / Number(Rates[quote.currency])) * Number(Rates[userCurrency()]))
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  renderText={(value) => (
                                    <div>
                                      {userCurrency()}{" "}{value}
                                      {/*{product.wholesale_currency} {value}*/}
                                    </div>
                                  )}
                                />
                              ) : (
                                  <CurrencyFormat
                                    value={((Number(quote.total_amount) / Number(Rates[quote.currency])) * Number(Rates[userCurrency()])).toFixed(2)
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    renderText={(value) => (
                                      <div>
                                        {userCurrency()}{" "}{value}
                                        {/*{product.wholesale_currency} {value}*/}
                                      </div>
                                    )}
                                  />
                                )
                              }

                            </td>
                            <td>
                              {/* <CurrencyFormat
                                  value={quoteItem.quotes.totalVatPaid}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  renderText={(value) => (
                                    <div>
                                      {" "}
                                      {quoteItem.quotes.requestCurrency} {"  "} {value}
                                    </div>
                                  )}
                                /> */}
                              {userCurrency() === quoteItem.quotes.requestCurrency ? (
                                <CurrencyFormat
                                  value={Math.round((Number(quoteItem.quotes.totalVatPaid) / Number(Rates[quoteItem.quotes.requestCurrency])) * Number(Rates[userCurrency()]))
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  renderText={(value) => (
                                    <div>
                                      {userCurrency()}{" "}{value}
                                      {/*{product.wholesale_currency} {value}*/}
                                    </div>
                                  )}
                                />
                              ) : (
                                  <CurrencyFormat
                                    value={((Number(quoteItem.quotes.totalVatPaid) / Number(Rates[quoteItem.quotes.requestCurrency])) * Number(Rates[userCurrency()])).toFixed(2)
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    renderText={(value) => (
                                      <div>
                                        {userCurrency()}{" "}{value}
                                        {/*{product.wholesale_currency} {value}*/}
                                      </div>
                                    )}
                                  />
                                )
                              }


                            </td>
                            <td>
                              {/* <CurrencyFormat
                                  value={quoteItem.quotes.totalAmountPaid}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  renderText={(value) => (
                                    <div>
                                      {" "}
                                      {quoteItem.quotes.requestCurrency} {"  "} {value}
                                    </div>
                                  )}
                                /> */}

                              {userCurrency() === quoteItem.quotes.requestCurrency ? (
                                <CurrencyFormat
                                  value={Math.round((Number(quoteItem.quotes.totalAmountPaid) / Number(Rates[quoteItem.quotes.requestCurrency])) * Number(Rates[userCurrency()]))
                                  }
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  renderText={(value) => (
                                    <div>
                                      {userCurrency()}{" "}{value}
                                      {/*{product.wholesale_currency} {value}*/}
                                    </div>
                                  )}
                                />
                              ) : (
                                  <CurrencyFormat
                                    value={((Number(quoteItem.quotes.totalAmountPaid) / Number(Rates[quoteItem.quotes.requestCurrency])) * Number(Rates[userCurrency()])).toFixed(2)
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    renderText={(value) => (
                                      <div>
                                        {userCurrency()}{" "}{value}
                                        {/*{product.wholesale_currency} {value}*/}
                                      </div>
                                    )}
                                  />
                                )
                              }
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="cart__actions">
              <div class="container">
                <div className="row">
                  <div className="col-3">
                    <h6>
                      <label>1 ITEMS</label>
                    </h6>
                  </div>
                  <div className="col-3">
                    <h6>
                      <label>TOTAL PRICE</label>
                    </h6>
                  </div>

                  <div className="col-3 ">
                    <h6>
                      {/* <CurrencyFormat
                          value={quoteItem.quotes.totalAmountPaid}
                          displayType={"text"}
                          thousandSeparator={true}
                          renderText={(value) => (
                            <div>
                              {" "}
                              {quoteItem.quotes.requestCurrency} {"  "} {value}
                            </div>
                          )}
                        /> */}
                      {userCurrency() === quoteItem.quotes.requestCurrency ? (
                        <CurrencyFormat
                          value={Math.round((Number(quoteItem.quotes.totalAmountPaid) / Number(Rates[quoteItem.quotes.requestCurrency])) * Number(Rates[userCurrency()]))
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          renderText={(value) => (
                            <div>
                              {userCurrency()}{" "}{value}
                              {/*{product.wholesale_currency} {value}*/}
                            </div>
                          )}
                        />
                      ) : (
                          <CurrencyFormat
                            value={((Number(quoteItem.quotes.totalAmountPaid) / Number(Rates[quoteItem.quotes.requestCurrency])) * Number(Rates[userCurrency()])).toFixed(2)
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                              <div>
                                {userCurrency()}{" "}{value}
                                {/*{product.wholesale_currency} {value}*/}
                              </div>
                            )}
                          />
                        )
                      }
                    </h6>
                  </div>
                  <div className="col-3">
                    {
                      DeliverOption().Deliver.customer_shipping === "I will arrange Delivery" ? (
                        <button
                          disabled={!this.state.paymentOption_token}
                          onClick={() =>
                            ConfirmOrder(quoteItem)
                          }
                          type="submit"
                          className="btn-sm btn btn-primary rounded"
                        >
                          CONFIRM
                        </button>
                      ) : (
                          <button
                            disabled={!this.state.paymentOption_token}
                            onClick={() =>
                              ConfirmOrder(quoteItem)
                            }
                            type="submit"
                            className="btn-sm btn btn-primary rounded"
                          >
                            CONFIRM
                          </button>
                        )
                    }

                    {/* ) : (
                              <div />
                            )}
                        </div> */}
                    {/* ))} */}
                  </div>
                </div>
              </div>
            </div>

            <br />
            <br />
            <button
              className="btn btn-secondary"
              onClick={() => handleBacks(activeStep)}
            >
              {" "}
              <i className="fa fa-backward text-black"> </i> Previous
            </button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let content;
    content = this.renderCart();
    return (
      <React.Fragment>
        <Helmet>
          <title>{`Confirm Order — ${theme.name}`}</title>
        </Helmet>
        {content}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  quoteItem: state.quoteItem,
  shipping: state.shippingAddress,
  Myownaddress: state.Myownaddresss,
  MyownShipping: state.MyownShipping,
  Rates: state.QuoteRateList,
});

const mapDispatchToProps = {
  cartRemove,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCart);
