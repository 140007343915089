import React, {Component,useCallback,useEffect,useRef,useState,} from 'react';
import  { FiSearch } from 'react-icons/fi'
import SelectCountry from "./SelectContry";
import Select from "react-select";
import {useFormik} from "formik";
import * as yup from "yup";
import '../../../assets/css/style.css'
import shopApi from '../../../api/serviceApi';
import Suggestions from './Suggestions';
import classNames from 'classnames';
import { Cross20Svg, Search20Svg } from '../../../svg';

const validationSchema = yup.object().shape({
    string: yup
        .string()
        .required("Search Input is required"),
});

const BannerOneSearchInput = (props) => {

    const {
        context,
        className,
        inputRef,
        onClose,
        location,
    } = props;

    const [cancelFn, setCancelFn] = useState(() => () => {});
    const [suggestionsOpen, setSuggestionsOpen] = useState(false);
    const [hasSuggestions, setHasSuggestions] = useState(false);
    const [suggestedProducts, setSuggestedProducts] = useState([]);
    console.log("suggestionsOpen",suggestionsOpen);
    console.log("hasSuggestions",hasSuggestions);
    const [query, setQuery] = useState('');
    const wrapper = useRef(null);
    const close = useCallback(() => {
        if (onClose) {
            onClose();
        }

        setSuggestionsOpen(false);
    }, [onClose]);

    const initialValues = {
        string: "",
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
    });


    const closeButton = context !== 'mobile-header' ? '' : (
        <button className="search__button search__button--type--close" type="button" onClick={close}>
            <Cross20Svg />
        </button>
    );

    const rootClasses = classNames(`search search--location--${context}`, className, {
        'search--suggestions-open': suggestionsOpen,
        'search--has-suggestions': hasSuggestions,
    });

    const handleBlur = () => {
        setTimeout(() => {
            if (!document.activeElement || document.activeElement === document.body) {
                return;
            }

            // Close suggestions if the focus received an external element.
            if (wrapper.current && !wrapper.current.contains(document.activeElement)) {
                close();
            }
        }, 10);
    };

    const handleFocus = () => {
        setSuggestionsOpen(true);
    };

      // Close suggestions when the Escape key has been pressed.
      const handleKeyDown = (event) => {
        // Escape.
        if (event.which === 27) {
            close();
        }
    };

    const handleChangeQuery = (event) => {
        //  console.log("event",event.target.value);
        let canceled = false;
        let timer;

        const newCancelFn = () => {
            canceled = true;
            clearTimeout(timer);
        };

        const query = event.target.value;

        setQuery(query);
        // loadSearchedProduct(optionsdata={},filtersdata={},query,category)
        if (query === '') {
            setHasSuggestions(false);
        } else {
            timer = setTimeout(() => {
                const options = { limit: 5 };

                shopApi.getSuggestedListings(query, options).then((products) => {
                    if (canceled) {
                        return;
                    }
                    //  console.log("products",products)
                    setSuggestedProducts(products);
                    setHasSuggestions(products);
                    setSuggestionsOpen(true);
                });
            }, 100);
        }

        setCancelFn(() => newCancelFn);
    };
    
        return (
            <>
          <div className={rootClasses} ref={wrapper} onBlur={handleBlur}>
                <div className="main-search-input">
                    <div className="main-search-input-item">
                        <div className="contact-form-action">
                            <form action="">
                                <div className="form-group mb-0">
                                <span className="form-icon">
                                    <FiSearch/>
                                </span>
                                    <input
                                     ref={inputRef}
                                     className="form-control"
                                      type="text"
                                       name="string"
                                       id="string"
                                       value={query}
                                       onChange={handleChangeQuery}
                                       onFocus={handleFocus}
                                       onKeyDown={handleKeyDown}
                                       placeholder="What are you looking for?"
                                           />
                                </div>
                            </form>
                            <Suggestions className="search__suggestions" context={context} products={suggestedProducts} query={query} />
                        </div>
                    </div>
                </div>
                </div>
            </>
        )
}

export default BannerOneSearchInput;
