import {ADD_ADDRESS, FETCH_ADDRESS,EDIT_ADDRESS} from "./shippingActionTypes";

export default function shippingReducer(state = null, action) {
    switch (action.type) {
        case FETCH_ADDRESS:
            return action.payload
        case ADD_ADDRESS:
            return action.payload
        case EDIT_ADDRESS:
            return action.payload
        default:
            return state;
    }
}


