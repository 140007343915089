import React, { useEffect, useMemo, useState } from "react";
import { Pagination, Search } from "../DataTable";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import theme from "../../data/theme";
import {agenttoken, PaymentToken, userCurrency} from "../../api/shop";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { AddMasterNumber} from '../../store/masterNumber';
import { connect, useDispatch, useSelector } from "react-redux";
import AsyncAction from "../shared/AsyncAction";
import {useFormik} from "formik";
import * as yup from "yup";
import Axios from "axios";
import {toast} from "react-toastify";
import {handleSubmits} from "../shop/checkout";

const initialValues = {
    agent_token: localStorage.getItem("agent_token"),
    full_name: "",
    phone_number: "",
    amount: "",
    currency: "",
    vat: "",
    secure_token: "",
};
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
//creating the validation schema
const validationSchema = yup.object().shape({
    amount: yup
        .string()
        .required("Please enter amount and")
        .min(3, "Character must be at least 3"),

    full_name: yup
        .string()
        .required("A FullName is required")
        .min(4, "Full name must be at least 4  characters"),
    email: yup.string().email(),
    phone_number: yup
        .string()
        .required("Phone number is required!")
        .matches(phoneRegExp, "Phone Number is not valid")
        .min(10, "Must be at least 10 characters")
        .max(13, "Phone number is too long, Not Great than 13 characters"),
    // .string()
    // .test('len', 'Must be exactly 10 characters', val => !val || (val.toString().length === 10))
    // .typeError( "Please enter correct phone number" )
    // .required( ''),
    currency: yup
        .string()
        .required("Please select currency"),
    payment_option: yup
        .string()
        .required("Please select payment option"),
});

const Orders = (props) => {
    const [orders, setOrders] = useState([]);
    const [payments, setPaymentOption] = useState([]);
    const [currencies, setCurrency] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const {AddMasterNumber}=props
    const ITEMS_PER_PAGE = 7;

    console.log(orders);

    //using useFormik
    const formik = useFormik({
        initialValues,
        validationSchema,
    });

    useEffect(() => {
        const getData = () => {
            axios.post(window.$name + "/api/manual/order/agent/list", {
                agent_token: agenttoken(),
            })
                .then((response) => response.data)
                .then((json) => {
                    setOrders(json);
                });
        };
        const getPaymentOption = async () => {
            const results = await axios
                .get(`${window.$gateway}/payment-provider/get-payment-option-icons`)
                .then(response => response.data);
            setPaymentOption(results);
        };
        const getCurrency = async () => {
            const currency = await axios
                .get(`${window.$name}/api/adminstrator/get-currency`)
                .then(response => response.data);
            setCurrency(currency);
        };
        getData();
        getPaymentOption();
        getCurrency();

    }, []);

    const orderData = useMemo(() => {
        let computedOrders = orders;
        if (search) {
            computedOrders = computedOrders.filter(
                (order) =>
                    order.order_number.toLowerCase().includes(search.toLowerCase()) ||
                    order.order_status.toLowerCase().includes(search.toLowerCase()) ||
                    order.total_vat.toLowerCase().includes(search.toLowerCase()) ||
                    order.total_amount.toLowerCase().includes(search.toLowerCase())
            );
        }
        setTotalItems(computedOrders.length);
        //Current Page slice
        return computedOrders.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [orders, currentPage, search]);

    function createManualOrder(values) {
    // let deliveryOption = localStorage.getItem("deliveryOptions");
    // let cart_details = localStorage.getItem("cartData");
    console.log(values);

    Axios.post(window.$name + "/api/manual/order/create/agent", values)
        .then((response) => {
            toast.success(` "${response.data.message}"`);
        }).catch(function (error) {
            if (error.response) {
                toast.error(
                    `"${error.response.data.message}" Error Status  "${error.response.status}" `
                );
            } else if (error.request) {
                toast.error(`"${error.request}" `);
            } else {
                toast.error(`"${error.message}" `);
            }
        });
}

    return (
        <div className="cardTop card">
            <Helmet>
                <title>{`Order History — ${theme.name}`}</title>
            </Helmet>
            <div className="d-flex card-header">
                <div className="p-2 font-weight-bold"></div>
                <div className="ml-auto p-2">
                    <button
                        data-toggle="modal"
                        data-target="#exampleModalCenter"
                        className="btn btn-outline-warning btn-sm rounded text-dark"
                    >
                        <i className="fa fa-edit"></i>
                        Create Manual Order
                    </button>
                </div>
            </div>
            <div
                className="modal fade"
                id="exampleModalCenter"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5
                                className="container text-center modal-title"
                                id="exampleModalLongTitle"
                            >
                                MANUAL ORDER FORM
                            </h5>

                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form>
                                <input
                                    type="hidden"
                                    id="agent_token"
                                    value={formik.values.agent_token}
                                    onChange={formik.handleChange}
                                />
                                <div className="form-row">
                                    <div className="form-group col ">
                                        <label>Full Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="full_name"
                                            name="full_name"
                                            placeholder="Full Name"
                                            value={formik.values.full_name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.full_name && formik.errors.full_name ? (
                                            <div className="text-danger">
                                                {formik.errors.full_name}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="checkout-phone">Phone</label>
                                        <input
                                            type="tel"
                                            className="form-control"
                                            id="phone_number"
                                            name="phone_number"
                                            placeholder="Phone Number"
                                            value={formik.values.phone_number}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />

                                        {formik.touched.phone_number &&
                                        formik.errors.phone_number ? (
                                            <div className="text-danger">
                                                {formik.errors.phone_number}
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="checkout-email">Amount</label>
                                        <input
                                            id="amount"
                                            name="amount"
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Amount"
                                            value={formik.values.amount}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.amount && formik.errors.amount ? (
                                            <div className="text-danger">{formik.errors.amount}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-group col ">
                                        <label>Currency</label>
                                        <select
                                            className="form-control"
                                            id="currency"
                                            name="currency"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.currency}
                                        >
                                            <option value="">please select currency.....</option>
                                            {currencies.map((currency) => (
                                                <option key={currency.code} value={currency.code}>
                                                    {currency.code}({currency.country})
                                                </option>
                                            ))}
                                        </select>

                                        {/*{formik.touched.currency && formik.errors.currency ? (*/}
                                        {/*    <div className="text-danger">*/}
                                        {/*        {formik.errors.currency}*/}
                                        {/*    </div>*/}
                                        {/*) : null}*/}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="checkout-email">VAT</label>
                                        <input
                                            id="vat"
                                            name="vat"
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter VAT"
                                            value={formik.values.vat}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                        {formik.touched.vat && formik.errors.vat ? (
                                            <div className="text-danger">{formik.errors.vat}</div>
                                        ) : null}
                                    </div>
                                    <div className="form-group col ">
                                        <label>Select Payment Option</label>
                                        <select
                                            className="form-control"
                                            id="payment_option"
                                            name="payment_option"
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            value={formik.values.payment_option}
                                        >
                                            <option value="">please select currency.....</option>
                                            {payments.map((payment) => (
                                                <option key={payment.id} value={payment.secure_token}>
                                                    {payment.payment_option_name}
                                                </option>
                                            ))}
                                        </select>

                                        {/*{formik.touched.payment_option && formik.errors.payment_option ? (*/}
                                        {/*    <div className="text-danger">*/}
                                        {/*        {formik.errors.payment_option}*/}
                                        {/*    </div>*/}
                                        {/*) : null}*/}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <AsyncAction
                                        // action={() => AddCustomers(formik.values)}
                                        render={({run, loading}) => (
                                            <button
                                                type="button"
                                                onClick={() => createManualOrder(formik.values)}
                                                className={"btn btn-block btn-primary rounded "}
                                                // disabled={!(formik.isValid && formik.dirty)}
                                                data-dismiss="modal"
                                            >
                                               Create
                                            </button>
                                        )}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-primary"
                                data-dismiss="modal"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div >
                <div className="card-header">
                    <div className="">MANUAL ORDERS</div>
                    <div className="d-flex align-items-end flex-column">
                        <div className="mt-auto p-2">
                            <Search
                                onSearch={(value) => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-divider" />
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                            <tr className="text-center">
                                <th>Order No</th>
                                <th>Customer</th>
                                <th>Phone</th>
                                <th>Total Vat</th>
                                <th>Total Amount</th>
                                <th>Status</th>
                                <th>Date</th>
                                {/*<th>Action</th>*/}
                            </tr>
                            </thead>
                            <tbody>
                            {orderData.map((order) => (
                                <tr className="text-center">
                                    <td>{order.order_number}</td>
                                    <td>{order.full_name}</td>
                                    <td>{order.phone_number}</td>
                                    <td>{order.total_vat}</td>
                                    <td>{order.total_amount}</td>
                                    {order.order_status === "pending" ? (
                                        <td className="text-danger font-weight-bold">
                                            Pending
                                        </td>
                                    ) : order.order_status === "Paid" ? (
                                        <td className="text-success font-weight-bold">
                                            Paid
                                        </td>
                                    ) : order.order_status === "Shipped" ? (
                                        <td className="text-warning font-weight-bold">
                                            Shipped
                                        </td>
                                    ) : order.order_status === "Delivered" ? (
                                        <td className="text-primary font-weight-bold">
                                            Delivered
                                        </td>
                                    ) : (
                                        <></>
                                    )}
                                    <td>
                                        <Moment format="DD/MM/YYYY">
                                            {order.created_at}
                                        </Moment>
                                    </td>
                                    {/* <td>
                          <Link
                            to={{
                              pathname: "/agent/vieworder",
                              state: order.order_number,
                            }}
                          >
                            View Items
                          </Link>
                        </td> */}
                                    {/*<td>*/}
                                    {/*    <div className="dropdown">*/}
                                    {/*        <button*/}
                                    {/*            class="btn btn-white"*/}
                                    {/*            type="button"*/}
                                    {/*            id="dropdownMenuButton"*/}
                                    {/*            data-toggle="dropdown"*/}
                                    {/*            aria-haspopup="true"*/}
                                    {/*            aria-expanded="false"*/}
                                    {/*        >*/}
                                    {/*            <b>...</b>*/}
                                    {/*        </button>*/}
                                    {/*        <div*/}
                                    {/*            className="dropdown-menu"*/}
                                    {/*            aria-labelledby="dropdownMenuButton"*/}
                                    {/*        >*/}
                                    {/*            <Link*/}
                                    {/*                className="dropdown-item"*/}
                                    {/*                to={{*/}
                                    {/*                    pathname: "/agent/vieworder",*/}
                                    {/*                    state: order.order_number,*/}
                                    {/*                }}*/}
                                    {/*            >*/}
                                    {/*                View Items*/}
                                    {/*            </Link>*/}
                                    {/*            {order.order_status === "pending" ? (*/}
                                    {/*                <button*/}
                                    {/*                    type="button"*/}
                                    {/*                    onClick={()=>AddMasterNumber(order.order_number)}*/}
                                    {/*                    className={*/}
                                    {/*                        "btn btn-block btn-primary rounded "*/}
                                    {/*                    }*/}
                                    {/*                >*/}
                                    {/*                    Master Number*/}
                                    {/*                </button>*/}
                                    {/*            ) : (*/}
                                    {/*                <></>*/}
                                    {/*            )}*/}
                                    {/*        </div>*/}

                                    {/*    </div>*/}
                                    {/*</td>*/}

                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-divider" />
                <div className="card-footer">
                    {/* <div className="d-flex align-items-center flex-column">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div> */}
                </div>
            </div>

            <div className="d-flex align-items-center flex-column">
                <Pagination
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                />
            </div>
        </div>
        // </div>
    );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {
    AddMasterNumber
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Orders);
