// react
import React, {useEffect} from "react";
// third-party
import {Helmet} from "react-helmet-async";
import {Link} from "react-router-dom";
// application
// data stubs
import theme from "../../data/theme";
import CurrencyFormat from "react-currency-format";
import Moment from "react-moment";
import AsyncAction from "../shared/AsyncAction";
import {makeStyles} from "@material-ui/core/styles";
import Rating from "@material-ui/lab/Rating";
import {connect} from "react-redux";
import Box from "@material-ui/core/Box";
import {useFormik} from "formik";
import {Spinner} from "reactstrap";
import * as yup from "yup";
import {Rate} from "../../store/Rating";
import {Row} from "reactstrap";
import Axios from "axios";
import {userCurrency} from '../../api/shop';

const validationSchema = yup.object({
    title: yup.string().required("is required"),
    rate: yup.string().required("is required"),
    description: yup.string().required("Please confirm your mobile number"),
});
const labels = {
    1: "Bad",
    2: "Poor",
    3: "Good",
    4: "Great",
    5: "Excellent",
};

const useStyles = makeStyles({
    root: {
        width: 200,
        display: "flex",
        alignItems: "center",
    },
});

function ShopPageOrderSuccess(props) {
    const [value, setValue] = React.useState(3);
    const [hover, setHover] = React.useState(-1);
    const [productToken, setProducttoken] = React.useState();
    const [sellerToken, setSellertoken] = React.useState();
    const [buyerToken, setBuyertoken] = React.useState();
    const classes = useStyles();
    const orderItem = props.location.state;
    const OrderRates = orderItem[1].Rates;
    // console.log("value",orderItem);
    const [order, setOrders] = React.useState([]);
    useEffect(() => {
        const orderList = async () => {
            const results = {
                order_number: orderItem[0].order.order_number
            };
            const data = await Axios.post(`${window.$name}/api/order/get-order-info`, results)
                .then((response) => response.data);
            setOrders(data);
        };
        orderList();
    }, []);
// console.log(orderDetail)
    const {Rate} = props;
    const formik = useFormik({
        initialValues: {
            title: "",
            rate: "",
            description: "",
        },
        validationSchema,
    });

    // console.log("formik", formik.values);
    return (
        <div className="block order-success">
            <Helmet>
                <title>{`Order items — ${theme.name}`}</title>
            </Helmet>
            <div className="container">
                {order.length <= 0 ? (
                    <div>
            <span className="d-flex justify-content-center">
              <Spinner size="xl"/>
            </span>
                        <span className="d-flex mt-10 justify-content-center font-weight-bold">
              Data Loading...
            </span>
                    </div>
                ) : (
                    <div className="order-success__body">
                        <div className="order-success__meta">
                            <ul className="order-success__meta-list">
                                <li className="order-success__meta-item">
                  <span className="order-success__meta-title">
                    Order number:
                  </span>
                                    <span className="order-success__meta-value">
                    {order.order_number}
                  </span>
                                </li>
                                <li className="order-success__meta-item">
                                    <span className="order-success__meta-title">Created at:</span>
                                    <span className="order-success__meta-value">
                    <Moment format="DD/MM/YYYY">{order.created_at}</Moment>
                  </span>
                                </li>
                                <li className="order-success__meta-item">
                                    <span className="order-success__meta-title">Total:</span>
                                    <span className="order-success__meta-value">
                    {/* <CurrencyFormat
                      value={order.total_amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      renderText={(value) => (
                        <div>
                          {" "}
                          {userCurrency()} {"  "} {value}
                        </div>
                      )}
                    /> */}

                                        {userCurrency() === order.currency ? (
                                            <CurrencyFormat
                                                value={Math.round((Number(order.total_amount)
                                                    / Number(OrderRates[order.currency])) * Number(OrderRates[userCurrency()]))
                                                }
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                renderText={(value) => (
                                                    <div>
                                                        {userCurrency()}{" "}{value}
                                                        {/*{product.wholesale_currency} {value}*/}
                                                    </div>
                                                )}
                                            />
                                        ) : (
                                            <CurrencyFormat
                                                value={((Number(order.total_amount) / Number(OrderRates[order.currency])) * Number(OrderRates[userCurrency()])).toFixed(2)
                                                }
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                renderText={(value) => (
                                                    <div>
                                                        {userCurrency()}{" "}{value}
                                                        {/*{product.wholesale_currency} {value}*/}
                                                    </div>
                                                )}
                                            />
                                        )
                                        }




                  </span>
                                </li>
                                {order.is_service_price === false ?
                                    <li className="order-success__meta-item">
                                        <span className="order-success__meta-title">Status:</span>
                                        <>
                                            {order.order_status === "pending" ? (
                                                <span className=" text-danger order-success__meta-value">
                        Pending
                      </span>
                                            ) : order.order_status === "Paid" ? (
                                                <span className="text-success order-success__meta-value">
                        Paid
                      </span>
                                            ) : order.order_status === "Shipped" ? (
                                                <span className="text-warning order-success__meta-value">
                        Shipped
                      </span>
                                            ) : (
                                                <span className="text-primary order-success__meta-value">
                              Delivered
                            </span>
                                            )}
                                        </>
                                    </li>
                                    :
                                    <li className="order-success__meta-item">
                                        <span className="order-success__meta-title">Status:</span>
                                        {order.is_paid ?
                                            <span className=" text-success order-success__meta-value">Paid</span>
                                            :
                                            <span className="text-danger order-success__meta-value">Not Paid</span>
                                        }
                                    </li>
                                }
                            </ul>
                        </div>
                        {order.is_service_price ?
                        <div className="container">
                           <p>
                               <span>Main service</span>{" "}{" "}

                               <span className="font-weight-bold">{order.cart_details.product_name}</span>
                           </p>
                        </div>
                            :null
                        }
                        <div className="card">

                            <div className="order-list">
                                {order.is_service_price ?
                                    <table>
                                        <thead className="order-list__header">
                                        <tr>
                                            <td className="order-list__column-label" colSpan="2">
                                                Choice Image
                                            </td>
                                            <td className="order-list__column-label">Choice name</td>
                                            <td className="order-list__column-total">Sub Total</td>
                                        </tr>
                                        </thead>
                                        <tbody className="h-10 overflow-auto w-auto order-list__products">
                                        {order.cart_details.choices.map((item) => (
                                            <tr>
                                                <td colSpan="2" className="order-list__column-image">
                                                    <div className="product-image">
                                                        <div className="product-image__body">
                                                            <img
                                                                className="product-image__img"
                                                                src={item.choice_image[0].image_name}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="order-list__column-product">
                                                    <div className="order-list__options">
                                                        <ul className="order-list__options-list ">
                                                            {item.choice_name}
                                                        </ul>
                                                    </div>
                                                </td>
                                                <td className="order-list__column-total">
                                                    {userCurrency() === item.choice_price.currency ? (
                                                        <CurrencyFormat
                                                            value={Math.round(((Number(item.choice_price.service_price))
                                                                / Number(OrderRates[item.choice_price.currency])) * Number(OrderRates[userCurrency()]))
                                                            }
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            renderText={(value) => (
                                                                <div>
                                                                    {userCurrency()}{" "}{value}
                                                                    {/*{product.wholesale_currency} {value}*/}
                                                                </div>
                                                            )}
                                                        />
                                                    ) : (
                                                        <CurrencyFormat
                                                            value={(((Number(item.choice_price.service_price)) / Number(OrderRates[item.choice_price.currency])) * Number(OrderRates[userCurrency()])).toFixed(2)
                                                            }
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            renderText={(value) => (
                                                                <div>
                                                                    {userCurrency()}{" "}{value}
                                                                    {/*{product.wholesale_currency} {value}*/}
                                                                </div>
                                                            )}
                                                        />
                                                    )
                                                    }


                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                        <tfoot className="order-list__footer">
                                        <tr>
                                            <th className="order-list__column-label" colSpan="4">
                                                Total
                                            </th>
                                            <td></td>
                                            <td></td>
                                            <td className="order-list__column-total">
                                                {/* <CurrencyFormat
                          value={order.total_amount}
                          displayType={"text"}
                          thousandSeparator={true}
                          renderText={(value) => (
                            <div>
                              {" "}
                              {userCurrency()} {""} {value}
                            </div>
                          )}
                        /> */}
                                                {userCurrency() === order.currency ? (
                                                    <CurrencyFormat
                                                        value={Math.round((Number(order.total_amount) / Number(OrderRates[order.currency])) * Number(OrderRates[userCurrency()]))
                                                        }
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        renderText={(value) => (
                                                            <div>
                                                                {userCurrency()}{" "}{value}
                                                                {/*{product.wholesale_currency} {value}*/}
                                                            </div>
                                                        )}
                                                    />
                                                ) : (
                                                    <CurrencyFormat
                                                        value={((Number(order.total_amount) / Number(OrderRates[order.currency])) * Number(OrderRates[userCurrency()])).toFixed(2)
                                                        }
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        renderText={(value) => (
                                                            <div>
                                                                {userCurrency()}{" "}{value}
                                                                {/*{product.wholesale_currency} {value}*/}
                                                            </div>
                                                        )}
                                                    />
                                                )
                                                }
                                            </td>
                                        </tr>
                                        </tfoot>
                                    </table>
                                    :
                                    <table>
                                        <thead className="order-list__header">
                                        <tr>
                                            <td className="order-list__column-label" colSpan="2">
                                                Product Image
                                            </td>
                                            <td className="order-list__column-label">Product name</td>
                                            <td className="order-list__column-label">Preference</td>
                                            <td className="order-list__column-quantity">Qty</td>
                                            <td className="order-list__column-total">Sub Total</td>
                                            <td className="order-list__column-total">Action</td>
                                        </tr>
                                        </thead>
                                        <tbody className="h-10 overflow-auto w-auto order-list__products">
                                        {JSON.parse(order.cart_details).map((item) => (
                                            <tr>
                                                <td colSpan="2" className="order-list__column-image">
                                                    <div className="product-image">
                                                        <div className="product-image__body">
                                                            <img
                                                                className="product-image__img"
                                                                src={item.product_images.productImage_name}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="order-list__column-product">
                                                    <div className="order-list__options">
                                                        <ul className="order-list__options-list ">
                                                            {item.product.product_name}
                                                        </ul>
                                                    </div>
                                                </td>
                                                <td className="order-list__column-product">
                                                    <div className="order-list__options">
                                                        <ul className="order-list__options-list ">
                                                            {item.preference_color !== null && item.preference_size !== null ? (
                                                                <>
                                                                    <Row>
                                                                        Color :&nbsp;{item.preference_color}
                                                                    </Row>
                                                                    <Row>
                                                                        Size :&nbsp;{item.preference_size}
                                                                    </Row>
                                                                </>
                                                            ) : item.preference_color !== null && item.preference_size === null ? (
                                                                <Row>
                                                                    Color :&nbsp;{item.preference_color}
                                                                </Row>
                                                            ) : item.preference_color === null && item.preference_size !== null ? (
                                                                    <Row>
                                                                        Size :&nbsp;{item.preference_size}
                                                                    </Row>
                                                                ) :
                                                                <span>Empty</span>
                                                            }
                                                        </ul>
                                                    </div>
                                                </td>
                                                <td
                                                    className="order-list__column-quantity"
                                                    data-title="Qty:"
                                                >
                                                    {item.quantity}
                                                </td>
                                                <td className="order-list__column-total">
                                                    {/* <CurrencyFormat
                            value={Number(item.total_amount)}
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                              <div>
                                {" "}
                                {userCurrency()} {"  "} {value}
                              </div>
                            )}
                          /> */}
                                                    {userCurrency() === item.currency ? (
                                                        <CurrencyFormat
                                                            value={Math.round(((Number(item.total_amount))
                                                                / Number(OrderRates[item.currency])) * Number(OrderRates[userCurrency()]))
                                                            }
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            renderText={(value) => (
                                                                <div>
                                                                    {userCurrency()}{" "}{value}
                                                                    {/*{product.wholesale_currency} {value}*/}
                                                                </div>
                                                            )}
                                                        />
                                                    ) : (
                                                        <CurrencyFormat
                                                            value={(((Number(item.total_amount)) / Number(OrderRates[item.currency])) * Number(OrderRates[userCurrency()])).toFixed(2)
                                                            }
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            renderText={(value) => (
                                                                <div>
                                                                    {userCurrency()}{" "}{value}
                                                                    {/*{product.wholesale_currency} {value}*/}
                                                                </div>
                                                            )}
                                                        />
                                                    )
                                                    }


                                                </td>
                                                <td className="order-list__column-total">
                                                    <button
                                                        data-toggle="modal"
                                                        data-target="#exampleModalCenter"
                                                        className="text-primary"
                                                        onClick={() => {
                                                            setProducttoken(item.product_token);
                                                            setBuyertoken(item.buyer_token);
                                                            setSellertoken(item.seller_token);
                                                        }}
                                                        className="btn btn-sm text-primary"
                                                    >
                                                        Add Review
                                                    </button>

                                                    <div
                                                        className="modal fade"
                                                        id="exampleModalCenter"
                                                        tabIndex="-1"
                                                        role="dialog"
                                                        aria-labelledby="exampleModalCenterTitle"
                                                        aria-hidden="true"
                                                    >
                                                        <div
                                                            className="modal-dialog modal-dialog-centered"
                                                            role="document"
                                                        >
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    <h5
                                                                        className="container text-center modal-title"
                                                                        id="exampleModalLongTitle"
                                                                    >
                                                                        ADD PRODUCT REVIEW
                                                                    </h5>
                                                                    <button
                                                                        type="button"
                                                                        className="close"
                                                                        data-dismiss="modal"
                                                                        aria-label="Close"
                                                                    >
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    <div className="row no-gutters">
                                                                        <div className="col-12">
                                                                            <form>
                                                                                <div className="form-row">
                                                                                    <div
                                                                                        className="form-group col-md-6">
                                                                                        <input
                                                                                            value={formik.values.title}
                                                                                            onChange={formik.handleChange}
                                                                                            onBlur={formik.handleBlur}
                                                                                            type="title"
                                                                                            name="title"
                                                                                            className="form-control"
                                                                                            id="title"
                                                                                            placeholder="Review title"
                                                                                        />
                                                                                        {formik.touched.title &&
                                                                                        formik.errors.title ? (
                                                                                            <div
                                                                                                className="text-danger">
                                                                                                {formik.errors.title}
                                                                                            </div>
                                                                                        ) : null}
                                                                                    </div>
                                                                                    {" "}
                                                                                    <div
                                                                                        className="form-group col-md-6">
                                                                                        <div className="row container">
                                                                                            <Rating
                                                                                                // className="form-control"
                                                                                                name="simple-controlled"
                                                                                                value={value}
                                                                                                precision={1}
                                                                                                onChange={(event, newValue) => {
                                                                                                    setValue(newValue);
                                                                                                }}
                                                                                                onChangeActive={(
                                                                                                    event,
                                                                                                    newHover
                                                                                                ) => {
                                                                                                    setHover(newHover);
                                                                                                }}
                                                                                            />
                                                                                            {value !== null && (
                                                                                                <Box ml={2}>
                                                                                                    {
                                                                                                        labels[
                                                                                                            hover !== -1
                                                                                                                ? hover
                                                                                                                : value
                                                                                                            ]
                                                                                                    }
                                                                                                </Box>
                                                                                            )}
                                                                                        </div>

                                                                                        {/* {value !== null && (
                                              <Box ml={1}>
                                               {labels[hover !== -1 ? hover : value]}
                                              </Box>
                                            )} */}
                                                                                        {/* </div> */}
                                                                                        {/* {formik.touched.rate &&
                                            formik.errors.rate ? (
                                              <div className="text-danger">
                                                {formik.errors.rate}
                                              </div>
                                            ) : null} */}
                                                                                    </div>
                                                                                </div>
                                                                                <div className="form-group">
                                          <textarea
                                              type="text"
                                              name="description"
                                              className="form-control"
                                              id="description"
                                              value={formik.values.description}
                                              onChange={formik.handleChange}
                                              onBlur={formik.handleBlur}
                                              placeholder="Enter Review Descrption"
                                          />
                                                                                    {formik.touched.description &&
                                                                                    formik.errors.description ? (
                                                                                        <div className="text-danger">
                                                                                            {formik.errors.description}
                                                                                        </div>
                                                                                    ) : null}
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <AsyncAction
                                                                        action={() =>
                                                                            Rate(
                                                                                formik.values,
                                                                                productToken,
                                                                                buyerToken,
                                                                                sellerToken,
                                                                                value
                                                                            )
                                                                        }
                                                                        render={({run}) => (
                                                                            <button
                                                                                type="button"
                                                                                onClick={run}
                                                                                className={
                                                                                    "btn btn-sm btn-primary rounded "
                                                                                }
                                                                            >
                                                                                Save Review
                                                                            </button>
                                                                        )}
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        className="btn  btn-sm btn-secondary"
                                                                        data-dismiss="modal"
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <hr/>
                                            </tr>
                                        ))}
                                        </tbody>
                                        <tfoot className="order-list__footer">
                                        <tr>
                                            <th className="order-list__column-label" colSpan="4">
                                                Total
                                            </th>
                                            <td></td>
                                            <td></td>
                                            <td className="order-list__column-total">
                                                {/* <CurrencyFormat
                          value={order.total_amount}
                          displayType={"text"}
                          thousandSeparator={true}
                          renderText={(value) => (
                            <div>
                              {" "}
                              {userCurrency()} {""} {value}
                            </div>
                          )}
                        /> */}
                                                {userCurrency() === order.currency ? (
                                                    <CurrencyFormat
                                                        value={Math.round((Number(order.total_amount) / Number(OrderRates[order.currency])) * Number(OrderRates[userCurrency()]))
                                                        }
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        renderText={(value) => (
                                                            <div>
                                                                {userCurrency()}{" "}{value}
                                                                {/*{product.wholesale_currency} {value}*/}
                                                            </div>
                                                        )}
                                                    />
                                                ) : (
                                                    <CurrencyFormat
                                                        value={((Number(order.total_amount) / Number(OrderRates[order.currency])) * Number(OrderRates[userCurrency()])).toFixed(2)
                                                        }
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        renderText={(value) => (
                                                            <div>
                                                                {userCurrency()}{" "}{value}
                                                                {/*{product.wholesale_currency} {value}*/}
                                                            </div>
                                                        )}
                                                    />
                                                )
                                                }
                                            </td>
                                        </tr>
                                        </tfoot>
                                    </table>
                                }
                            </div>
                        </div>

                        {/*<div className="row mt-3 no-gutters mx-n2">*/}
                        {/*    <div className="col-sm-6 col-12 px-2">*/}
                        {/*        <div className="card address-card">*/}
                        {/*            <div className="address-card__body">*/}
                        {/*                <div className="address-card__badge address-card__badge--muted">*/}
                        {/*                    Shipping Address*/}
                        {/*                </div>*/}
                        {/*                <div className="address-card__name">*/}
                        {/*                    {`${order.shippingAddress.firstName} ${order.shippingAddress.lastName}`}*/}
                        {/*                </div>*/}
                        {/*                <div className="address-card__row">*/}
                        {/*                    {order.shippingAddress.country}*/}
                        {/*                    <br/>*/}
                        {/*                    {`${order.shippingAddress.postcode}, ${order.shippingAddress.city}`}*/}
                        {/*                    <br/>*/}
                        {/*                    {order.shippingAddress.address}*/}
                        {/*                </div>*/}
                        {/*                <div className="address-card__row">*/}
                        {/*                    <div className="address-card__row-title">Phone Number</div>*/}
                        {/*                    <div*/}
                        {/*                        className="address-card__row-content">{order.shippingAddress.phone}</div>*/}
                        {/*                </div>*/}
                        {/*                <div className="address-card__row">*/}
                        {/*                    <div className="address-card__row-title">Email Address</div>*/}
                        {/*                    <div*/}
                        {/*                        className="address-card__row-content">{order.shippingAddress.email}</div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*    <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">*/}
                        {/*        <div className="card address-card">*/}
                        {/*            <div className="address-card__body">*/}
                        {/*                <div className="address-card__badge address-card__badge--muted">*/}
                        {/*                    Billing Address*/}
                        {/*                </div>*/}
                        {/*                <div className="address-card__name">*/}
                        {/*                    {`${order.billingAddress.firstName} ${order.billingAddress.lastName}`}*/}
                        {/*                </div>*/}
                        {/*                <div className="address-card__row">*/}
                        {/*                    {order.billingAddress.country}*/}
                        {/*                    <br/>*/}
                        {/*                    {`${order.billingAddress.postcode}, ${order.billingAddress.city}`}*/}
                        {/*                    <br/>*/}
                        {/*                    {order.billingAddress.address}*/}
                        {/*                </div>*/}
                        {/*                <div className="address-card__row">*/}
                        {/*                    <div className="address-card__row-title">Phone Number</div>*/}
                        {/*                    <div*/}
                        {/*                        className="address-card__row-content">{order.billingAddress.phone}</div>*/}
                        {/*                </div>*/}
                        {/*                <div className="address-card__row">*/}
                        {/*                    <div className="address-card__row-title">Email Address</div>*/}
                        {/*                    <div*/}
                        {/*                        className="address-card__row-content">{order.billingAddress.email}</div>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                )}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    // shippingAddress: state.shippingAddress,
    // profile: state.profile,
});

const mapDispatchToProps = {
    Rate,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShopPageOrderSuccess);
