// react
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import { handleNexts } from "./QuoteCheckout";
import theme from "../../data/theme";
import { Helmet } from "react-helmet-async";
import { Row } from "reactstrap";
import { userCurrency } from "../../api/shop";
class ShopPageQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantities: [],
      showing: true,
    };
  }

  renderCart() {
    const { quoteItem, Rates, activeStep } = this.props;
    function DeliverOption() {
      return {
        Deliver: quoteItem.quotes.customer_delivery
      }
    }
    return (
      <div className="cart block">
        {/* {quoteItem.map((quote) => ( */}
        <div className="container">
          <div className="card">
            <div className="card-divider" />
            <div className="card-table">
              <div className="table-responsive-sm">
                <table>
                  <thead>
                    <tr className="text-center">
                      <th>Product image</th>
                      <th>Product name</th>
                      <th > Preference</th>
                      <th>Quantity</th>
                      <th>Amount</th>
                      <th>Total Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {quoteItem.QuotesProduct.map((quote) => (
                      <tr className="text-center">
                        <td>
                          <div className="product-image">
                            <Link className="product-image__body">
                              <img
                                className="product-image__img"
                                src={quote.product_images.productImage_name}
                                alt=""
                              />
                            </Link>
                          </div>
                        </td>
                        <td>
                          <Link className="cart-table__product-name">
                            {quote.product.product_name}
                          </Link>
                        </td>
                        {/* preference_color: "Holes"
                        preference_size: null */}
                        <td className="order-list__column-product">
                          {quote.preference_color !== null && quote.preference_size !== null ? (
                            <>
                              <Row>
                                Color :&nbsp;{quote.preference_color}
                              </Row>
                              <Row>
                                Size :&nbsp;{quote.preference_size}
                              </Row>
                            </>
                          ) : quote.preference_color !== null && quote.preference_size === null ? (
                            <Row>
                              Color :&nbsp;{quote.preference_color}
                            </Row>
                          ) : quote.preference_color === null && quote.preference_size !== null ? (
                            <Row>
                              Size :&nbsp;{quote.preference_size}
                            </Row>
                          ) :
                                <span>Empty</span>
                          }
                        </td>
                        <td>{quote.quantity}</td>
                        <td className="font-weight-bold">
                          {/* <CurrencyFormat
                            value={quote.total_amount}
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                              <div>
                                {" "}
                                {quote.currency}{value}
                              </div>
                            )}
                          /> */}
                              {userCurrency() === quote.currency ? (
                            <CurrencyFormat
                              value={Math.round((Number(quote.total_amount) / Number(Rates[quote.currency])) * Number(Rates[userCurrency()]))
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              renderText={(value) => (
                                <div>
                                  {userCurrency()}{" "}{value}
                                  {/*{product.wholesale_currency} {value}*/}
                                </div>
                              )}
                            />
                          ) : (
                              <CurrencyFormat
                                value={((Number(quote.total_amount) / Number(Rates[quote.currency])) * Number(Rates[userCurrency()])).toFixed(2)
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                renderText={(value) => (
                                  <div>
                                    {userCurrency()}{" "}{value}
                                    {/*{product.wholesale_currency} {value}*/}
                                  </div>
                                )}
                              />
                            )
                          }

                        </td>
                        <td>
                          {/* <CurrencyFormat
                          value={quoteItem.quotes.totalAmountPaid - quoteItem.quotes.totalVatPaid}
                          displayType={"text"}
                          thousandSeparator={true}
                          renderText={(value) => (
                            <div>
                              {" "}
                              {quoteItem.quotes.requestCurrency} {"  "} {value}
                            </div>
                          )}
                        /> */}

                          {userCurrency() === quoteItem.quotes.requestCurrency ? (
                            <CurrencyFormat
                              value={Math.round((Number(quoteItem.quotes.totalAmountPaid - quoteItem.quotes.totalVatPaid) / Number(Rates[quoteItem.quotes.requestCurrency])) * Number(Rates[userCurrency()]))
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              renderText={(value) => (
                                <div>
                                  {userCurrency()}{" "}{value}
                                  {/*{product.wholesale_currency} {value}*/}
                                </div>
                              )}
                            />
                          ) : (
                              <CurrencyFormat
                                value={((Number(quoteItem.quotes.totalAmountPaid - quoteItem.quotes.totalVatPaid) / Number(Rates[quoteItem.quotes.requestCurrency])) * Number(Rates[userCurrency()])).toFixed(2)
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                renderText={(value) => (
                                  <div>
                                    {userCurrency()}{" "}{value}
                                    {/*{product.wholesale_currency} {value}*/}
                                  </div>
                                )}
                              />
                            )
                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row justify-content-end pt-md-5 pt-4">
            <div className="col-12 col-md-7 col-lg-6 col-xl-5">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title text-center">SUMMARY</h3>
                  <table className="cart__totals">
                    <thead className="cart__totals-footer">
                      <tr>
                        <th>Sub Total</th>
                        <td>
                          {/* <CurrencyFormat
                            value={quoteItem.quotes.totalAmountPaid - quoteItem.quotes.totalVatPaid}
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                              <div>
                                {" "}
                                {quoteItem.quotes.requestCurrency} {"  "} {value}
                              </div>
                            )}
                          /> */}


                          {userCurrency() === quoteItem.quotes.requestCurrency ? (
                            <CurrencyFormat
                              value={Math.round((Number(quoteItem.quotes.totalAmountPaid - quoteItem.quotes.totalVatPaid) / Number(Rates[quoteItem.quotes.requestCurrency])) * Number(Rates[userCurrency()]))
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              renderText={(value) => (
                                <div>
                                  {userCurrency()}{" "}{value}
                                  {/*{product.wholesale_currency} {value}*/}
                                </div>
                              )}
                            />
                          ) : (
                              <CurrencyFormat
                                value={((Number(quoteItem.quotes.totalAmountPaid - quoteItem.quotes.totalVatPaid) / Number(Rates[quoteItem.quotes.requestCurrency])) * Number(Rates[userCurrency()])).toFixed(2)
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                renderText={(value) => (
                                  <div>
                                    {userCurrency()}{" "}{value}
                                    {/*{product.wholesale_currency} {value}*/}
                                  </div>
                                )}
                              />
                            )
                          }

                        </td>
                      </tr>
                    </thead>
                    <tbody className="cart__totals-body">
                      {/* {extraLines} */}
                    </tbody>
                    <tfoot className="cart__totals-footer">
                      <tr>
                        <th>Total Vat</th>
                        <td>
                          {/* <CurrencyFormat
                            value={quoteItem.quotes.totalVatPaid}
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                              <div>
                                {" "}
                                {quoteItem.quotes.requestCurrency} {"  "} {value}
                              </div>
                            )}
                          /> */}
                          {userCurrency() === quoteItem.quotes.requestCurrency ? (
                            <CurrencyFormat
                              value={Math.round((Number(quoteItem.quotes.totalVatPaid) / Number(Rates[quoteItem.quotes.requestCurrency])) * Number(Rates[userCurrency()]))
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              renderText={(value) => (
                                <div>
                                  {userCurrency()}{" "}{value}
                                  {/*{product.wholesale_currency} {value}*/}
                                </div>
                              )}
                            />
                          ) : (
                              <CurrencyFormat
                                value={((Number(quoteItem.quotes.totalVatPaid) / Number(Rates[quoteItem.quotes.requestCurrency])) * Number(Rates[userCurrency()])).toFixed(2)
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                renderText={(value) => (
                                  <div>
                                    {userCurrency()}{" "}{value}
                                    {/*{product.wholesale_currency} {value}*/}
                                  </div>
                                )}
                              />
                            )
                          }
                        </td>
                      </tr>
                      <tr>
                        <th>Total Amount</th>
                        <td>
                          {/* <CurrencyFormat
                            value={quoteItem.quotes.totalAmountPaid}
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                              <div>
                                {" "}
                                {quoteItem.quotes.requestCurrency} {"  "} {value}
                              </div>
                            )}
                          /> */}
                           {userCurrency() === quoteItem.quotes.requestCurrency ? (
                            <CurrencyFormat
                              value={Math.round((Number(quoteItem.quotes.totalAmountPaid) / Number(Rates[quoteItem.quotes.requestCurrency])) * Number(Rates[userCurrency()]))
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              renderText={(value) => (
                                <div>
                                  {userCurrency()}{" "}{value}
                                  {/*{product.wholesale_currency} {value}*/}
                                </div>
                              )}
                            />
                          ) : (
                              <CurrencyFormat
                                value={((Number(quoteItem.quotes.totalAmountPaid) / Number(Rates[quoteItem.quotes.requestCurrency])) * Number(Rates[userCurrency()])).toFixed(2)
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                renderText={(value) => (
                                  <div>
                                    {userCurrency()}{" "}{value}
                                    {/*{product.wholesale_currency} {value}*/}
                                  </div>
                                )}
                              />
                            )
                          }
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <hr />
                  <h5 >Delivery Option</h5>
                  <div className="mt-3">
                    <div >
                      {DeliverOption().Deliver.customer_shipping}
                    </div>
                    <button
                      type="submit"
                      // disabled={!this.state.deliveryoption}
                      onClick={() => handleNexts(activeStep)}
                      className="btn  btn-primary  btn-block cart__checkout-button mt-4"
                    >
                      Continue
                      </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  render() {
    let content;
    content = this.renderCart();

    return (
      <React.Fragment>
        <Helmet>
          <title>{`Checkout — ${theme.name}`}</title>
        </Helmet>
        {content}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  quoteItem: state.quoteItem,
  Rates: state.QuoteRateList,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageQuote);
