// react
import React from "react";
// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
// application
import PageHeader from "../shared/PageHeader";

// data stubs
import theme from "../../data/theme";
import { connect } from "react-redux";
import { LoginUSer } from "../../store/auth/userLoginActions";

import useForm from "./useForm";
import "./validationcss.css";
import validate from "./validateForm";
import ForgotPassword from "./forgotPassword";
function PasswordReset(props) {
  return (
    <React.Fragment>
      <Helmet>
        <title>{`Reset password — ${theme.name}`}</title>
      </Helmet>
      <PageHeader />
      <div className="block">
        <div className="container">
          <div className="row d-flex justify-content-center ">
            <div className="col-md-6">
              <div className="card shadow-lg rounded flex-grow-1  mb-md-0">
                <div className="card-body">
                  <h5 className="card-title text-muted">Reset Password</h5>
                  <ForgotPassword />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
// }
const mapDispatchToProps = {
};

export default connect(() => ({}), mapDispatchToProps)(PasswordReset);
