// react
import React, {useState} from "react";
// third-party
import { Helmet } from "react-helmet-async";
// application
import PageHeader from "../shared/PageHeader";
import { Check9x7Svg } from "../../svg";
// data stubs
import theme from "../../data/theme";
import { connect } from "react-redux";
import { ActivateLoginUSer } from "../../store/auth/userLoginActions";
import AsyncAction from "../shared/AsyncAction";
import classNames from "classnames";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import axios from "axios";
import {toast} from "react-toastify";
const initialValues = {
    phone_email: "",
    password: "",
    remember_me: "",
};
//creating the validation schema
const validationSchema = yup.object().shape({
    phone_email: yup
        .string()
        .email("Please enter your valid email")
        .required("Email is a required field"),
    password: yup
        .string()
        .required("Please enter your  password")
        .matches(/^.*(?=.{6,}).*$/, "Password must contain at least 6 characters"),

});

function PageLogin(props) {
    const { ActivateLoginUSer } = props;
    const [waitForPassword, SetWaitForPassword] = useState(false)
    const [Token,SetToken]=useState("")
    const formik = useFormik({
        initialValues,
        validationSchema,
    });
    function CheckValidation() {
        axios.post(`${window.$name}/api/auth/check-credentials`,
            {
                phone_email: formik.values.phone_email
            })
            .then((response) => {
                SetWaitForPassword(true)
                SetToken(response.data.token)
                toast.success(` ${response.data.message}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(` ${error.response.data.message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                } else if (error.request) {
                    toast.error(` ${error.request}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                } else {
                    toast.error(` ${error.message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                }
            });
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
                <label htmlFor="login-email">Username</label>
                <input
                    id="phone_email"
                    name="phone_email"
                    className="form-control"
                    placeholder="Email or phone number"
                    value={formik.values.phone_email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {formik.touched.phone_email && formik.errors.phone_email ? (
                    <div className="text-danger">{formik.errors.phone_email}</div>
                ) : null}
            </div>
            {waitForPassword ?
                <>
                    <div className="form-group">
                        <label htmlFor="login-password">Password</label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div className="text-danger">{formik.errors.password}</div>
                        ) : null}
                    </div>

                    <div className="row">
                        <div className="col container flex form-group">
                            <div className="form-check">
              <span className="form-check-input input-check">
                <span className="input-check__body">
                  <input
                      id="login-remember"
                      type="checkbox"
                      value={formik.values.remember_me}
                      className="input-check__input"
                  />
                  <span className="input-check__box"/>
                  <Check9x7Svg className="input-check__icon"/>
                </span>
              </span>
                                <label className="form-check-label" htmlFor="login-remember">
                                    Remember Me
                                </label>
                            </div>
                        </div>
                        <div className="text-center">
                            <Link to="/account/forgot-password" className="text-muted">
                                <i className="fa fa-lock"></i> Forgot your password?
                            </Link>
                        </div>
                    </div>

                    <AsyncAction
                        action={() => ActivateLoginUSer(formik.values.password, Token)}
                        render={({run, loading}) => (
                            <button
                                type="submit"
                                data-toggle="tooltip"
                                data-placement="right"
                                title="login"
                                onClick={run}
                                className={classNames("btn btn-primary rounded btn-block mt-4", {
                                    "btn-loading": loading,
                                })}
                                disabled={!(formik.isValid && formik.dirty)}
                            >
                                Login
                            </button>
                        )}
                    />
                </>
                :
                <div className="d-flex justify-content-end">
                    <button
                        type="submit"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="login"
                        onClick={()=>CheckValidation()}
                        className="btn btn-primary rounded  mt-4"
                        disabled={formik.values.phone_email===""}
                    >
                        Next
                    </button>
                </div>
            }
                <div className="row mt-4 container">
                    <Link to="/account/register" target="_blank"  className="text-muted" >
                        Don't have an account ?{" "}
                        <span className="text-primary" >Signup now</span>
                    </Link>
                </div>
            </form>

    );
}
const mapDispatchToProps = {
    ActivateLoginUSer
};

export default connect(() => ({}), mapDispatchToProps)(PageLogin);
