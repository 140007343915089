import {CURRENT_CUSTOMER} from "./CurrentUserActionTypes";
let initialState={
    CustomerName:"",
}

export default function CurrentCustomerReducer(state =initialState,action) {
        switch(action.type){
        case CURRENT_CUSTOMER:
            return action.payload
        default:
           return state;
    }
}


