// react
import React from "react";

import CurrencyFormat from "react-currency-format";
// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// application
import AsyncAction from "./AsyncAction";
import { Quickview16Svg, Wishlist16Svg } from "../../svg";
import { quickviewOpen } from "../../store/quickview";
import { wishlistAddItem } from "../../store/wishlist";
import { digitalwishlistAddItem } from "../../store/digitalWishlist";
import { cartAddItem } from "../../store/cart";
import { QuoteAddItem } from "../../store/quote";
import {digitalcartAddItem} from '../../store/digitalCart';
import {digitalAddBuynow} from '../../store/digitalbuynow';
import { FetchProductDetail } from "../../store/product";
import { agenttoken } from "../../api/shop";
import "boxicons";
import history from '../../@history'

function ProductCard(props) {
    const {
        product,
        layout,
        quickviewOpen,
        cartAddItem,
        digitalcartAddItem,
        QuoteAddItem,
        wishlistAddItem,
        digitalwishlistAddItem,
        digitalAddBuynow
    } = props;
    // console.log("products",product.currency)
    const currency = JSON.parse(product.rate);
    const userCurrency = localStorage.getItem("userCurrency");
    const quantity = 1;
    //total amount
    function pacentageDiscount() {
        if (product.discount) {
            const discountAmount = ((Number((product.discount.discount))
                / Number(currency[product.currency])) * Number(currency[userCurrency])).toFixed(2)
            const pricetobeConverted = (((Number(product.price) + Number(product.vat)) / Number(currency[product.currency])) * Number(currency[userCurrency])).toFixed(2)
            const priceDiscount = Math.round(((Number(discountAmount) * 100) / Number(pricetobeConverted)))
            // const = (Number(USD));
            return priceDiscount
        }
    }
    function PriceDisplay() {
        if (product.discount) {
            const USD = (Number(product.price) + Number(product.vat)
                - Number(product.discount.discount))
                / Number(currency[product.currency]);
            if (userCurrency === product.currency) {
                const pricediscount = Math.round(Number(USD) * Number(currency[userCurrency]))
                return pricediscount
            }
            else {
                const pricediscount = (Number(USD) * Number(currency[userCurrency])).toFixed(2);
                return pricediscount
            }
        } else {
            const USD = Math.round(Number(product.price) + Number(product.vat))
                / Number(currency[product.currency]);
            if (userCurrency === product.currency) {
                const priceNodiscount = Math.round(Number(USD) * Number(currency[userCurrency]))
                return priceNodiscount
            } else {
                const priceNodiscount = (Number(USD) * Number(currency[userCurrency])).toFixed(2);
                return priceNodiscount
            }
        }
    }
    // function handleClick() {
    //     history.push("/");
    //   }
    function total_amount() {
        if (product.discount) {
            // const USD = Math.round((Number(product.price) + Number(product.vat))
            //     - Number(product.discount.discount))
            //     / Number(currency[product.currency]);
            // const pricediscount = Number(USD) * Number(currency[userCurrency]);
            let sum;
            sum =
                quantity * PriceDisplay()
            // Math.round((Number(product.price) + Number(product.vat)) - Number(product.discount.discount));
            // Math.round(((Number(product.price) + Number(product.vat))/userAmount) - (Number(product.discount.discount)/userAmount));
            return sum;
        } else {
            // const USD = Math.round(Number(product.price) + Number(product.vat))
            //     / Number(currency[product.currency]);
            // const priceNodiscount = Number(USD) * Number(currency[userCurrency]);
            let sum;
            sum =
                quantity * PriceDisplay()
            // Math.round(
            //     Number(product.price) + Number(product.vat)
            //     // /userAmount
            // );
            return sum;

        }
    }
    // console.log(currency[product.currency])
    function total_vat() {
        let USD;
        let sum;
        USD = Number(product.vat)
            / Number(currency[product.currency]);
        if (userCurrency === product.currency) {
            sum = Math.round(Number(USD) * Number(currency[userCurrency]));
            return sum;
        }
        else {
            sum = (Number(USD) * Number(currency[userCurrency])).toFixed(2);
            return sum;
        }

    }
    const productData = {
        id: product.id,
        name: product.name,
        slug: product.name,
        price: total_amount(),
        rate: currency,
        vat: parseInt(total_vat()),
        wholesale_price: Number(product.wholesale_price),
        // currency: product.currency,
        currency: userCurrency,
        // currency_wholesale: product.currency_wholesale,
        currency_wholesale: userCurrency,
        discount: product.discount,
        unit: 1,
        preference_color: "",
        preference_size: "",
        unitMeasure: product.unitMeasure,
        compareAtPrice: null,
        status: product.status,
        images: product.images,
        shop_id: product.shop_id,
        seller_token: product.seller_token,
        secure_token: product.secure_token,
        product_token: product.product_token,
        outlets_token: product.outlets_token,
        Category: product.Category,
        digital: product.digital,
        badges: [],
        rating: 5,
        reviews: 3,
        brand: product.brand,
        categories: [],
        attributes: [],
        customFields: {},
    };
    // console.log("data",product)
    const containerClasses = classNames("product-card", {
        "product-card--layout--grid product-card--size--sm": layout === "grid-sm",
        "product-card--layout--grid product-card--size--nl": layout === "grid-nl",
        "product-card--layout--grid product-card--size--lg": layout === "grid-lg",
        "product-card--layout--list": layout === "list",
        "product-card--layout--horizontal": layout === "horizontal",
    });
    let image;
    let tobedisplayed;
    let price;
    let discount;
    const tokenNull="ezytrade"
    // console.log(product);
    if (agenttoken() && product.agent_commission !== null)
    {
        // const product_price = Math.round(
        //     Number(product.price) + Number(product.vat)
        // );
        // const ezytrade_amount =
        //     (product_price * product.ezytrade_commission.ezy_percentage) / 100;
        const agentamount = Math.round(
            (((Number(product.price)-Number(product.acquistion_cost)) * product.agent_commission.agent_percentage) /
                100)
        );
        const USD = agentamount
            / Number(currency[product.currency]);
        if (userCurrency === product.currency) {
            tobedisplayed = (
                <div
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Agent Commission Amount"
                >
                    <CurrencyFormat
                        value={Math.round(Number(USD) * Number(currency[userCurrency]))}
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => (
                            <div>
                                {userCurrency} {value}
                            </div>
                        )}
                    />
                </div>
            );
        }
        else {
            tobedisplayed = (
                <div
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Agent Commission Amount"
                >
                    <CurrencyFormat
                        value={(Number(USD) * Number(currency[userCurrency])).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => (
                            <div>
                                {userCurrency} {value}
                            </div>
                        )}
                    />
                </div>
            )
        }

    } else if (agenttoken()) {
        tobedisplayed = (
            <div
                data-toggle="tooltip"
                data-placement="right"
                title="Agent Commission Amount"
            >
                <CurrencyFormat
                    value={0}
                    displayType={"text"}
                    thousandSeparator={true}
                    renderText={(value) => (
                        <div>
                            {userCurrency} {value}
                            {/*{product.currency} {value}*/}
                        </div>
                    )}
                />
            </div>
        );
    }
    if (product.images && product.images.length > 0) {
        image = (
            <div className="product-card__image product-image">
                <Link
                    to={`/shop/products/${product.product_token}/${product.outlets_token}/${product.Category}/${tokenNull}`}
                    className="product-image__body"
                >
                    {/*<Zoom>*/}
                    <img
                        className="product-image__img"
                        alt="alt"
                        src={product.images}
                    />
                    {/*</Zoom>*/}
                    {/*<img*/}
                    {/*    className="product-image__img"*/}
                    {/*    src={product.images}*/}
                    {/*    alt="alt"*/}
                    {/*/>*/}
                </Link>
            </div>
        );
    }
    if (product.discount === null) {
        price = (
            <div className="product-card__prices row">
                <div className="col-8 float-left">
                    <CurrencyFormat
                        value={PriceDisplay()}
                        // value={Math.round(Number(product.price) + Number(product.vat))}
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => (
                            <div>
                                {/*{product.currency} {value}*/}
                                {userCurrency} {value}
                            </div>
                        )}
                    />
                </div>
                <div className="col-4 float-right">{tobedisplayed}</div>
            </div>
        );
    } else {
        discount = (
            <div className="product-card__badge product-card__badge--sale">
                {/*{Math.round(*/}
                {/*    Number((product.discount.discount * 100) / (Number(product.price) + Number(product.vat)))*/}
                {/*)}{" "}*/}
                {pacentageDiscount()}
                % OFF
            </div>
        );
        price = (
            <div className="product-card__prices row">
                <div className="col-8 float-left">
                    <span className="product-card__new-price">
                        <CurrencyFormat
                            // value={Math.round(
                            //     ((Number(product.price) +
                            //         Number(product.vat)) -
                            //         Number(product.discount.discount))
                            // )}
                            value={PriceDisplay()}
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                                <div>
                                    {/*{product.currency} {value}*/}
                                    {userCurrency} {value}
                                </div>
                            )}
                        />
                    </span>{" "}
                    <span className="product-card__old-price">
                        {userCurrency === product.currency ? (
                            <CurrencyFormat
                                // value={Math.round(Number(product.price) + Number(product.vat))}
                                value={Math.round(((Number(product.price) + Number(product.vat)) / Number(currency[product.currency])) * Number(currency[userCurrency]))
                                }
                                // value={Math.round((Number(product.price) + Number(product.vat))/userAmount)}
                                displayType={"text"}
                                thousandSeparator={true}
                                renderText={(value) => (
                                    <div>
                                        {userCurrency} {value}
                                        {/*{product.currency} {value}*/}
                                    </div>
                                )}
                            />
                        ) : (
                            <CurrencyFormat
                                // value={Math.round(Number(product.price) + Number(product.vat))}
                                value={(((Number(product.price) + Number(product.vat)) / Number(currency[product.currency])) * Number(currency[userCurrency])).toFixed(2)
                                }
                                // value={Math.round((Number(product.price) + Number(product.vat))/userAmount)}
                                displayType={"text"}
                                thousandSeparator={true}
                                renderText={(value) => (
                                    <div>
                                        {userCurrency} {value}
                                        {/*{product.currency} {value}*/}
                                    </div>
                                )}
                            />
                        )

                        }
                    </span>
                </div>
                <div className="col-4 float-right">{tobedisplayed}</div>
            </div>
        );
    }
    return (
        <div className={containerClasses}>
            <AsyncAction
                action={() => quickviewOpen(productData)}
                render={({ run, loading }) => (
                    <button
                        type="button"
                        onClick={run}
                        className={classNames("product-card__quickview", {
                            "product-card__quickview--preload": loading,
                        })}
                    >
                        <Quickview16Svg />
                    </button>
                )}
            />
            <div className="product-card__badges-list"> {discount}</div>
            {image}
            {/*</Link>*/}
            <div className="product-card__info">
                <div className="product-card__name text-capitalize">
                    {/*<span className="text-dark">*/}
                    <Link
                        to={`/shop/products/${product.product_token}/${product.outlets_token}/${product.Category}/${tokenNull}`}
                    >
                        {" "}
                        {product.name.length > 28
                            ? `${product.name.substring(0, 28)}...`
                            : product.name}
                    </Link>
                    {/*</span>*/}
                </div>
                <div className="product-card__rating">
                    {/*<Rating className="product-card__rating-stars" value={product.rating} />*/}
                    {/*<div className="product-card__rating-legend">{`${product.reviews} Reviews`}</div>*/}
                </div>
                {/*{features}*/}
            </div>
            <div className="product-card__actions">
                <div className="product-card__availability">
                    Availability:
                    <span className="text-success">In Stock</span>
                </div>
                {/*referral code for agent*/}
                {agenttoken()?
                    <span>
                    <Link
                        to={`/shop/products/${product.product_token}/${product.outlets_token}/${product.Category}/${agenttoken()}`}
                    >My Referral Link
                </Link>
                </span>
                    :null
                }

                {/*end here*/}
                {!product.price_tag ? (
                    <> {price}</>
                ) : (
                    <div className="product-card__prices">
                        <span className="product-card__new-price"></span>
                    </div>
                )}
                {product.digital ? (

                    <div className="product-card__buttons">
                        {
                            window.location.pathname === '/digitalHome' ? (
                                <>
                                    <AsyncAction
                                        action={() => digitalcartAddItem(productData)}
                                        render={({ run, loading }) => (
                                            <React.Fragment>
                                                <button
                                                    type="button"
                                                    onClick={run}
                                                    className={classNames(
                                                        "btn btn-primary product-card__addtocart",
                                                        {
                                                            "btn-loading": loading,
                                                        }
                                                    )}
                                                >
                                                    Add To Cart
                                                </button>
                                            </React.Fragment>
                                        )}
                                    />
                                    <AsyncAction
                                        action={() => digitalwishlistAddItem(productData)}
                                        render={({ run, loading }) => (
                                            <button
                                                type="button"
                                                onClick={run}
                                                className={classNames(
                                                    "btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist",
                                                    {
                                                        "btn-loading": loading,
                                                    }
                                                )}
                                            >
                                                <Wishlist16Svg />
                                            </button>
                                        )}
                                    />
                                </>
                            ):(
                                <AsyncAction
                                        action={() => digitalAddBuynow(productData,1)}
                                        render={({ run, loading }) => (
                                            <React.Fragment>
                                                <button
                                                    type="button"
                                                    onClick={run}
                                                    className={classNames(
                                                        "btn btn-success product-card__addtocart",
                                                        {
                                                            "btn-loading": loading,
                                                        }
                                                    )}
                                                >
                                                    Buy Now
                                                </button>
                                            </React.Fragment>
                                        )}
                                    />
                            )

                        }


                    </div>

                ) :
                    (
                        <div className="product-card__buttons">
                            {!product.price_tag ? (
                                <>
                                    {!product.customUoM ? (
                                        <>
                                            <AsyncAction
                                                action={() => cartAddItem(productData)}
                                                render={({ run, loading }) => (
                                                    <React.Fragment>
                                                        <button
                                                            type="button"
                                                            onClick={run}
                                                            className={classNames(
                                                                "btn btn-primary product-card__addtocart",
                                                                {
                                                                    "btn-loading": loading,
                                                                }
                                                            )}
                                                        >
                                                            Add To Cart
                                                </button>
                                                    </React.Fragment>
                                                )}
                                            />
                                            <AsyncAction
                                                action={() => wishlistAddItem(productData)}
                                                render={({ run, loading }) => (
                                                    <button
                                                        type="button"
                                                        onClick={run}
                                                        className={classNames(
                                                            "btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist",
                                                            {
                                                                "btn-loading": loading,
                                                            }
                                                        )}
                                                    >
                                                        <Wishlist16Svg />
                                                    </button>
                                                )}
                                            />
                                            <AsyncAction
                                                action={() => quickviewOpen(productData)}
                                                render={({ run, loading }) => (
                                                    <button
                                                        type="button"
                                                        onClick={run}
                                                        className={classNames(
                                                            "btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist",
                                                            {
                                                                "btn-loading": loading,
                                                            }
                                                        )}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <path
                                                                fill="none"
                                                                d="M12,4c-4.411,0-8,3.589-8,8s3.589,8,8,8s8-3.589,8-8S16.411,4,12,4z M13,16.915V18h-2v-1.08 C8.661,16.553,8,14.918,8,14h2c0.011,0.143,0.159,1,2,1c1.38,0,2-0.585,2-1c0-0.324,0-1-2-1c-3.48,0-4-1.88-4-3 c0-1.288,1.029-2.584,3-2.915V6.012h2v1.109c1.734,0.41,2.4,1.853,2.4,2.879h-1l-1,0.018C13.386,9.638,13.185,9,12,9 c-1.299,0-2,0.516-2,1c0,0.374,0,1,2,1c3.48,0,4,1.88,4,3C16,15.288,14.971,16.584,13,16.915z"
                                                            ></path>
                                                            <path
                                                                d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path>
                                                            <path
                                                                d="M12,11c-2,0-2-0.626-2-1c0-0.484,0.701-1,2-1c1.185,0,1.386,0.638,1.4,1.018l1-0.018h1c0-1.026-0.666-2.469-2.4-2.879 V6.012h-2v1.073C9.029,7.416,8,8.712,8,10c0,1.12,0.52,3,4,3c2,0,2,0.676,2,1c0,0.415-0.62,1-2,1c-1.841,0-1.989-0.857-2-1H8 c0,0.918,0.661,2.553,3,2.92V18h2v-1.085c1.971-0.331,3-1.627,3-2.915C16,12.88,15.48,11,12,11z"></path>
                                                        </svg>
                                                    </button>
                                                )}
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <AsyncAction
                                                action={() => quickviewOpen(productData)}
                                                render={({ run, loading }) => (
                                                    <button
                                                        type="button"
                                                        onClick={run}
                                                        className={classNames(
                                                            "btn btn-primary product-card__addtocart",
                                                            {
                                                                "btn-loading": loading,
                                                            }
                                                        )}
                                                    >
                                                        Custom
                                                    </button>
                                                )}
                                            />
                                            <AsyncAction
                                                action={() => quickviewOpen(productData)}
                                                render={({ run, loading }) => (
                                                    <button
                                                        type="button"
                                                        onClick={run}
                                                        className={classNames(
                                                            "btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__wishlist",
                                                            {
                                                                "btn-loading": loading,
                                                            }
                                                        )}
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                        >
                                                            <path
                                                                fill="none"
                                                                d="M12,4c-4.411,0-8,3.589-8,8s3.589,8,8,8s8-3.589,8-8S16.411,4,12,4z M13,16.915V18h-2v-1.08 C8.661,16.553,8,14.918,8,14h2c0.011,0.143,0.159,1,2,1c1.38,0,2-0.585,2-1c0-0.324,0-1-2-1c-3.48,0-4-1.88-4-3 c0-1.288,1.029-2.584,3-2.915V6.012h2v1.109c1.734,0.41,2.4,1.853,2.4,2.879h-1l-1,0.018C13.386,9.638,13.185,9,12,9 c-1.299,0-2,0.516-2,1c0,0.374,0,1,2,1c3.48,0,4,1.88,4,3C16,15.288,14.971,16.584,13,16.915z"
                                                            ></path>
                                                            <path
                                                                d="M12,2C6.486,2,2,6.486,2,12s4.486,10,10,10s10-4.486,10-10S17.514,2,12,2z M12,20c-4.411,0-8-3.589-8-8s3.589-8,8-8 s8,3.589,8,8S16.411,20,12,20z"></path>
                                                            <path
                                                                d="M12,11c-2,0-2-0.626-2-1c0-0.484,0.701-1,2-1c1.185,0,1.386,0.638,1.4,1.018l1-0.018h1c0-1.026-0.666-2.469-2.4-2.879 V6.012h-2v1.073C9.029,7.416,8,8.712,8,10c0,1.12,0.52,3,4,3c2,0,2,0.676,2,1c0,0.415-0.62,1-2,1c-1.841,0-1.989-0.857-2-1H8 c0,0.918,0.661,2.553,3,2.92V18h2v-1.085c1.971-0.331,3-1.627,3-2.915C16,12.88,15.48,11,12,11z"></path>
                                                        </svg>
                                                    </button>
                                                )}
                                            />
                                        </>
                                    )}
                                </>
                            ) : (
                                <AsyncAction
                                    action={() => quickviewOpen(productData)}
                                    render={({ run, loading }) => (
                                        <button
                                            type="button"
                                            onClick={run}
                                            className={classNames(
                                                "btn btn-primary product-card__addtocart",
                                                {
                                                    "btn-loading": loading,
                                                }
                                            )}
                                        >
                                            Quote
                                        </button>
                                    )}
                                />
                            )}
                        </div>

                    )
                }
            </div>
        </div>
    );
}

ProductCard.propTypes = {
    /**
     * product object
     */
    product: PropTypes.object.isRequired,
    /**
     * product card layout
     * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
     */
    layout: PropTypes.oneOf([
        "grid-sm",
        "grid-nl",
        "grid-lg",
        "list",
        "horizontal",
    ]),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItem,
    digitalcartAddItem,
    digitalwishlistAddItem,
    digitalAddBuynow,
    wishlistAddItem,
    FetchProductDetail,
    quickviewOpen,
    QuoteAddItem,

};

export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
