import { FETCH_PAYMENT } from "./paymentActionTypes";
import Axios from "axios";
import { agenttoken} from "../../api/shop";
import { toast } from "react-toastify";

export function FetchPaymentSettings() {
  const token = agenttoken();
  const data = {
    secure_token: token,
  }; 
  return async (dispatch) =>
    Axios.post(window.$name + "/api/agent/get-agent-payment", data)
      .then(function (response) {
         dispatch({ type: FETCH_PAYMENT, payload: response.data });
        
      })
      // .catch(function (error) {
      //   if (error.response) {
      //     dispatch({ type: FETCH_PAYMENT, payload: [] });
      //     toast.error(
      //       `"${error.response.data.message}" Error Status  "${error.response.status}" `
      //     );
      //   } else if (error.request) {
      //     dispatch({ type: FETCH_PAYMENT, payload: [] });
      //     toast.error(`${error.request} `);
      //   } else {
      //     dispatch({ type: FETCH_PAYMENT, payload: [] });
      //     toast.error(`${error.message}`);
      //   }
      // });
}

export function Addpaymentsettings(setting) {
  const token = agenttoken();
  return async (dispatch) =>
    Axios.post(window.$name + "/api/agent/agent-payment", {
      secure_token: token,
      Account_name: setting.account_name,
      Account_number: setting.account_number,
      bank_name: setting.bank_name,
      branch_name: setting.branch_name,
    })
      .then(function (response) {
        toast.success(`${response.data.message}`);
        dispatch(FetchPaymentSettings());
        window.location.href = "/agent/payment";
      })
      .catch(function (error) {
        if (error.response) {
          toast.error(
            `"${error.response.data.message}" Error Status  "${error.response.status}" `
          );
        } else if (error.request) {
          toast.error(`"${error.request}" `);
        } else {
          toast.error(`"${error.message}" `);
        }
      });
}

export function Editpaymentsettings(data) {
    const token = agenttoken();
  const payment_edit ={
    secure_token: token,
    payment_token:data.secure_token,
    Account_name:data.account_name,
    Account_number:data.account_number,
    bank_name:data.bank_name,
    branch_name:data.branch_name
  }  
  return async (dispatch) =>
    Axios.post(window.$name + "/api/agent/edit-agent-payment", payment_edit)
      .then(function (response) {
          if(response.status===201){
            dispatch(FetchPaymentSettings());
            toast.success(`"${response.data.message}"`);
          }
          
      })
      .catch(function (error) {
        if (error.response) {
          toast.error(
            `"${error.response.data.message}" Error Status  "${error.response.status}" `
          );
        } else if (error.request) {
          toast.error(`"${error.request}" `);
        } else {
          toast.error(`"${error.message}" `);
        }
      });
}
