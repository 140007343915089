import {FETCH_QUOTE} from "./quotelistActionTypes";
export default function quotelistReducer(state =[],action) {
        switch(action.type){
        case FETCH_QUOTE:
            return action.payload
        default:
           return state;
    }
}


