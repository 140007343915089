import React from "react";
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function SimpleMap(props){
  const {data} = props
  const defaultProps = {
    center: {
      lat: 6.82085,
      lng: 39.28697
    },
    zoom: 11
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '50vh', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyA7eSvVggqKFPjdKcFrnGK66oX2khsvSoE" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
          lat={`${data.listing_locations.latitude}`}
          lng={`${data.listing_locations.logitude}`}
          text={data.listing.listing_title}
        />
      </GoogleMapReact>
    </div>
  );
}
