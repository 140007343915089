import shopApi from '../../api/shop';
import { QUICKVIEW_CLOSE, QUICKVIEW_OPEN } from './quickviewActionTypes';
import Axios from "axios";


let cancelPreviousRequest = () => {};


export function quickviewOpenSuccess(product,rate,review_rates) {
    return {
        type: QUICKVIEW_OPEN,
        product,
        rate,
        review_rates
    };
}

export function quickviewClose() {
    return {
        type: QUICKVIEW_CLOSE,
    };
}

export function quickviewOpen(product) {
    return (dispatch) => {
        cancelPreviousRequest();
        return new Promise((resolve) => {
            let canceled = false;
            const timer = setTimeout(() => {
                Axios.post(window.$name+'/api/business/get-product-price', {
                    product_token: product.product_token,
                    outlet_token: product.outlets_token
                })
                    .then(function (response) {
                        if (canceled) {
                            return;
                        }
                        if (response) {
                            dispatch(quickviewOpenSuccess(response.data.products,response.data.rates,response.data));
                        }
                        resolve();
                    })
                    .catch(function (error) {
                        console.log(error);
                    })

            }, 350);
            cancelPreviousRequest = () => {
                canceled = true;
                clearTimeout(timer);
                resolve();
            };
        });
    };
}
