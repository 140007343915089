import React from 'react';
// import '../../assets/css/style.css'
import {IoIosLink, IoMdStar, IoMdStarHalf} from "react-icons/io";
import {FiHeart, FiPhone} from "react-icons/fi";
import {FaRegCalendarCheck} from "react-icons/fa";
import {AiOutlineEye} from "react-icons/ai";
import {Link} from "react-router-dom";
import Moment from 'react-moment';
function Listings(props) {
    const {data} = props
 return (
        <>
            {data.data.map((item, index) => (
                <div className="col-lg-3 mt-3 justify-content-start">
                    {/*<Link to={`/listing-details/${item.secure_token}/${item.url_head}`}>*/}
                    <div className="card-item">
                        <Link to={`/listing-details/${item.url_head}`} className="card-image-wrap">
                            <div className="card-image">
                                <img src={item.image.image_url}className="card__img" height="220" alt="Place"/>
                            </div>
                        </Link>
                        <div className="card-content-wrap">
                            <div className="card-content">
                                <Link to={`/listing-details/${item.url_head}`}>
                                    <span className="badge text-black  text-capitalize"> {item.category_name}</span>
                                    {/*<h7 className="text-muted badge">*/}
                                    {/*   */}
                                    {/*</h7>*/}
                                    <h5 className=" text-capitalize text-muted" style={{color:'black'}}>
                                        {item.listing_title.length > 26
                                            ? `${item.listing_title.substring(0, 26)}...`
                                            : item.listing_title}
                                    </h5>
                                    <p className="card-sub">
                                        {item.district}, {item.region}, {item.country}
                                    </p>
                                </Link>
                                <ul className="info-list padding-top-20px">
                                    <li><span className="d-inline-block"><FiPhone/></span>  &nbsp; &nbsp;
                                        {item.location.phone_number}
                                    </li>
                                    <li><span className="d-inline-block"><IoIosLink/></span>
                                        &nbsp; &nbsp;
                                     <a href={item.location.website}>
                                       website
                                     </a>
                                    </li>
                                    <li>
                                        <span className="d-inline-block"><FaRegCalendarCheck/></span>
                                        &nbsp; &nbsp;
                                        <Moment fromNow>{item.created_at}</Moment>

                                    </li>
                                </ul>
                            </div>
                            <div className="rating-row">
                                <div className="rating-rating">
                                    {[
                                        <IoMdStar/>,
                                        <IoMdStar/>,
                                        <IoMdStar/>,
                                        <IoMdStarHalf/>,
                                        <IoMdStar className="last-star"/>].map((rating, index) => {
                                        return (
                                            <span key={index}>{rating}</span>
                                        )
                                    })}
                                    <span className="rating-count">{item.ratingNum}</span>
                                </div>
                                <div className="listing-info">
                                    <ul>
                                        <li><span className="info__count"><AiOutlineEye/></span> {item.view}</li>
                                        <li>
                                                    <span className="info__save" data-toggle="tooltip"
                                                          data-placement="top" title="Bookmark">
                                                        <FiHeart/>
                                                    </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*</Link>*/}
                </div>

            ))}
        </>
    );
}

export default Listings;
