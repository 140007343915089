import {customertoken,customerId} from "../../api/shop";
import Axios from "axios";
import {BOOKMARK_DATA} from "./bookmarkActionTypes";

import {toast} from "react-toastify";
export function FetchBookmark() {
    return async (dispatch) =>
        Axios.get(`${window.$name}/api/directory/consumer/show/listings/bookmark/${customertoken()}`)
            .then(function (response) {
                // console.log("agent", response.data);
                // if (response.data.status === 401) {
                //   dispatch({ type: BOOKING_DATA, payload: [] });
                // } else {
                dispatch({type: BOOKMARK_DATA, payload: response.data.bookmarks});
                // }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}
export function DeleteBookmark(bookmark_token) {
    return async (dispatch) =>
        Axios.get(`${window.$name}/api/directory/consumer/remove/listings/from/bookmark/${bookmark_token}`)
            .then(function (response) {
                toast.success(`${response.data.message}`);
                dispatch(FetchBookmark());
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}


export function AddBookmark(id) {
    const data = {
        // shop_user_id:customerId(),
        // listing id
        listing_id:id,
        }
        Axios.post(`${window.$name}/api/directory/consumer/save/listing/bookmark/${customertoken()}`,data)
        .then(function (response) {
                toast.success(`${response.data.message}`);
        })
        .catch(function (error) {
            if (error.response) {
                toast.error("something is wrong");
            }
        });

}
