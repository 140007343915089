import React from 'react';
import { MdStar, MdStarHalf } from 'react-icons/md'
import {Link} from "react-router-dom";

function  WidgetPromotedListing(props) {

const {data}=props
        return (
            <>
                <div className="sidebar-widget similar-widget">
                    <h3 className="widget-title">
                        Promoted Listings
                    </h3>
                    <div className="title-shape"/>
                    <div className="similar-list padding-top-30px">

                        {data.map((item, i) => {
                            return (
                                <div className="recent-item" key={i}>
                                 <div className="recent-img">
                                     <Link to={`/listing-details/${item.listing.secure_token}/${item.listing.url_head}`}>

                                     <img src={item.listing.image[0].image_url} alt="blog" />
                                        </Link>
                                    </div>
                                    <div className="recentpost-body">
                                        <h4 className="recent__link">
                                            <Link className="text-capitalize" to={`/listing-details/${item.listing.secure_token}/${item.listing.url_head}`}>
                                            {item.listing.listing_title.length > 26
                                                ? `${item.listing.listing_title.substring(0, 26)}...`
                                                : item.listing.listing_title}
                                            </Link>
                                        </h4>
                                        <div className="rating-rating">
                                            {[
                                                <MdStar />,
                                                <MdStar />,
                                                <MdStar />,
                                                <MdStar />,
                                                <MdStarHalf />,
                                            ].map((icon, i) => {
                                                return <span key={i} className="la la-star">{icon}</span>
                                            })}
                                        </div>
                                        <p className="recent__meta">
                                            {/*<span className="color-text font-weight-bold">{item.price}</span>*/}
                                            <Link>
                                                {item.listing.listing_description.length > 55
                                                ? `${item.listing.listing_description.substring(0, 55)}...`
                                                : item.listing.listing_description}
                                            </Link>
                                        </p>
                                    </div>
                                    {/*</Link>*/}
                                </div>
                            )
                        })}

                    </div>
                    <div className="btn-box text-center padding-top-30px">
                        {/*<Button text="see all listings" url="/listing-grid" className="d-block">*/}
                        {/*    <BsEye />*/}
                        {/*</Button>*/}
                    </div>
                </div>
            </>
        );
}

export default WidgetPromotedListing;