import {REGISTER_USER} from "./userActionTypes";
import {LOGIN_USER } from "./userActionTypes";
import {CHANGE_PASSWORD} from "./userActionTypes";
let initialstate={
    isLogin:false,
    // CustomerName:"",
}

export default function userReducer(state = initialstate,action) {
    switch(action.type){
        case REGISTER_USER :
            return action.payload
        case LOGIN_USER :
            return action.payload
        case CHANGE_PASSWORD :
            return action.payload
        default:
            return state;
    }
}


