// react
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useFormik } from "formik";
import * as yup from "yup";
import theme from "../../data/theme";
import AsyncAction from "../shared/AsyncAction";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  FetchPaymentSettings,Addpaymentsettings, Editpaymentsettings} from "../../store/paymentAgent";
//creating the validation schema
const validationSchema = yup.object({
  account_name: yup
    .string()
    .required("This field is required")
    .min(3, "must be at least 3 characters"),
  account_number: yup.string().required("This field is required"),
  bank_name: yup
    .string()
    .required("This field is required")
    .min(3, "must be at least 3 characters"),
  branch_name: yup.string().required("This field is required"),
});

function AccountPagePayment(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(FetchPaymentSettings());
  }, [dispatch]);  
  const [payment, setAgentpayment] = useState([]);
  const PaymentDataList = useSelector((state) => state.Agentpayment);
  // if(!PaymentDataList){
  //   window.location.reload();
  // }
 
  // console.log(PaymentDataList);

  // useEffect(() => {
  //   setAgentpayment(PaymentDataList[0]);
  // }, [PaymentDataList]);
  
  //using useFormik
  const formik = useFormik({
    initialValues: {
      // secure_token:PaymentDataList[0].secure_token,
      // account_name: PaymentDataList[0].Account_name,
      // account_number: PaymentDataList[0].Account_number,
      // bank_name: PaymentDataList[0].bank_name,
      // branch_name: PaymentDataList[0].branch_name,
      secure_token:"",
      account_name: "",
      account_number: "",
      bank_name: "",
      branch_name: "",
    },
    validationSchema,
  });
  const { Addpaymentsettings, Editpaymentsettings } = props;
  return (
    <div className="card shadow-lg border-none ">
      <Helmet>
        <title>{`Change Password — ${theme.name}`}</title>
      </Helmet>
      <div>
        {PaymentDataList.length > 0 ? (
          <div>
            <div className="d-flex card-header">
              <div className="p-2 font-weight-bold">
                BANK ACCOUNT INFORMATION
              </div>
              <div className="ml-auto p-2">
                <button
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                  className="btn btn-outline-warning btn-sm rounded text-dark"
                >
                  <i className="fa fa-edit text-primary"></i>
                  Edit Information
                </button>
              </div>
            </div>
            <div className="card-divider" />
            <div className="card-body ">
              <div className="row no-gutters">
                <div className="col-12">
                  <form>
                    <div className="form-row">
                      <div className="form-group col-md-4">
                        <label htmlFor="password-confirm font-weight-bold">
                          Account Name
                        </label>
                        <div className="text-secondary text-uppercase">
                          {PaymentDataList[0].Account_name}
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="password-confirm font-weight-bold">
                          Account Number
                        </label>
                        <div className="text-secondary text-uppercase">
                          {PaymentDataList[0].Account_number}
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="password-confirm font-weight-bold">
                          Brank Name
                        </label>
                        <div className="text-secondary text-uppercase">
                          {PaymentDataList[0].bank_name}
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="password-confirm font-weight-bold">
                          Branch Name
                        </label>
                        <div className="text-secondary text-uppercase">
                          {PaymentDataList[0].branch_name}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div
              class="modal fade"
              id="exampleModalCenter"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-body">
                    <div>
                      <div className="card-header">
                        <div className="container text-center font-weight-bold">
                          EDIT BANK ACCOUNT INFORMATION
                        </div>
                      </div>
                      <div className="card-divider" />
                      <div className="card-body ">
                        <div className="row no-gutters">
                          <div className="col-12">
                            <form>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label htmlFor="password-new">
                                    Account Name
                                  </label>
                                  <input
                                    value={formik.values.account_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    name="account_name"
                                    className="form-control"
                                    id="account_name"
                                    placeholder="account name"
                                  />
                                  {formik.touched.account_name &&
                                  formik.errors.account_name ? (
                                    <div className="text-danger">
                                      {formik.errors.account_name}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="form-group col-md-6">
                                  <label htmlFor="account_number">
                                    Account Number
                                  </label>
                                  <input
                                    value={formik.values.account_number}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    name="account_number"
                                    className="form-control"
                                    id="account_number"
                                    placeholder="account number"
                                  />
                                  {formik.touched.account_number &&
                                  formik.errors.account_number ? (
                                    <div className="text-danger">
                                      {formik.errors.account_number}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label htmlFor="branch_name">Bank Name</label>
                                  <input
                                    value={formik.values.bank_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    name="bank_name"
                                    className="form-control"
                                    id="bank_name"
                                    placeholder="bank name"
                                  />
                                  {formik.touched.bank_name &&
                                  formik.errors.bank_name ? (
                                    <div className="text-danger">
                                      {formik.errors.bank_name}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="form-group col-md-6">
                                  <label htmlFor="password-confirm">
                                    Branch Name
                                  </label>
                                  <input
                                    value={formik.values.branch_name}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    name="branch_name"
                                    className="form-control"
                                    id="branch_name"
                                    placeholder="branch name"
                                  />
                                  {formik.touched.branch_name &&
                                  formik.errors.branch_name ? (
                                    <div className="text-danger">
                                      {formik.errors.branch_name}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="form-group">
                                <AsyncAction
                                  action={() =>
                                    Editpaymentsettings(formik.values)
                                  }
                                  render={({ run, loading }) => (
                                    <button
                                      type="button"
                                      onClick={run}
                                      className={
                                        "btn btn-block btn-primary rounded "
                                      }
                                      disabled={
                                        !(formik.isValid && formik.dirty)
                                      }
                                    >
                                      Save Now
                                    </button>
                                  )}
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="d-flex card-header">
              <div className="p-2 font-weight-bold">PAYMENT SETTINGS</div>
            </div>
            <div className="card-divider" />
            <div className="card-body ">
              <div className="row no-gutters">
                <div className="col-12">
                  <form>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="password-new">Account Name</label>
                        <input
                          value={formik.values.account_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="account_name"
                          className="form-control"
                          id="password-new"
                          placeholder="account name"
                        />
                        {formik.touched.account_name &&
                        formik.errors.account_name ? (
                          <div className="text-danger">
                            {formik.errors.account_name}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="account_number">Account Number</label>
                        <input
                          value={formik.values.account_number}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="account_number"
                          className="form-control"
                          id="account_number"
                          placeholder="account number"
                        />
                        {formik.touched.account_number &&
                        formik.errors.account_number ? (
                          <div className="text-danger">
                            {formik.errors.account_number}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="branch_name">Bank Name</label>
                        <input
                          value={formik.values.bank_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="bank_name"
                          className="form-control"
                          id="bank_name"
                          placeholder="bank name"
                        />
                        {formik.touched.bank_name && formik.errors.bank_name ? (
                          <div className="text-danger">
                            {formik.errors.bank_name}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="password-confirm">Branch Name</label>
                        <input
                          value={formik.values.branch_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          name="branch_name"
                          className="form-control"
                          id="branch_name"
                          placeholder="branch name"
                        />
                        {formik.touched.branch_name &&
                        formik.errors.branch_name ? (
                          <div className="text-danger">
                            {formik.errors.branch_name}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group">
                      <AsyncAction
                        action={() => Addpaymentsettings(formik.values)}
                        render={({ run, loading }) => (
                          <button
                            type="button"
                            onClick={run}
                            className={"btn btn-block btn-primary rounded "}
                            disabled={!(formik.isValid && formik.dirty)}
                          >
                            Save Now
                          </button>
                        )}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = {
  Addpaymentsettings,Editpaymentsettings,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountPagePayment);
