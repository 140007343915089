import React, { useState, useEffect } from "react";
import axios from "axios";
import InputMask from "react-input-mask";

import {
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Label,
  Input,
  Form,
  FormGroup,
  Progress,
  UncontrolledAlert,
  Container,
} from "reactstrap";

import classnames from "classnames";
import { Link } from "react-router-dom";
import { Formik, useFormik } from "formik";
import { connect } from "react-redux";
import { AgentRegistration } from "../../store/Agent";
import * as yup from "yup";

const initialValues = {
  bussinessname: "",
  tin: "",
  licensenumber: "",
  email1: "",
  AlreadyRegistered: "",
  email2: "",
  email: "",
  idtype1: "",
  idtype2: "",
  idtype: "",
  idNumber: "",
  idNumber1: "",
  idNumber2: "",
  phone: "",
  phone1: "",
  phone2: "",
  firstname: "",
  surname: "",
  lastname: "",
  fullname1: "",
  fullname2: "",
  country: "",
  region: "",
  district: "",
  street:"",
  username: "",
  password: "",
  confirmPassword: "",
  veoletter: "",
  emailtrue: "",
  passwordtrue: "",
  confirmPasswordtrue: "",
  idtypetrue: "",
  idNumbertrue: ""
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = yup.object().shape({
  bussinessname: yup.string().required("Bussiness name is a required field"),
  tin: yup
    .string()
    .required("TIN is required!"),
  AlreadyRegistered: yup
    .string()
    .required("This is required field"),
  licensenumber: yup.string().required("Company Registration number is a required field"),
  fullname1: yup.string().required("Fullname is a required field"),
  fullname2: yup.string().required("Fullname is a required field"),
  idtype1: yup.string().required("ID type is a required field"),
  idtype2: yup.string().required("ID type is a required field"),
  idtypetrue: yup.string().required("ID type is a required field"),
  idNumber1: yup
    .string()
    .required("ID number is required!"),
  idNumbertrue: yup
    .string()
    .required("ID number is required!"),

  idNumber2: yup
    .string()
    .required("ID number is required!"),
  emailtrue: yup
    .string()
    .email("Please enter your valid email")
    .required("Email is a required field"),
  email1: yup
    .string()
    .email("Please enter your valid email")
    .required("Email is a required field"),
  email2: yup
    .string()
    .email("Please enter your valid email")
    .required("Email is a required field"),
  email: yup
    .string()
    .email("Please enter your valid email")
    .required("Email is a required field"),
  phone1: yup
    .string()
    .required("Phone number is required!"),

  phone2: yup
    .string()
    .required("Phone number is required!"),

  phone: yup
    .string()
    .required("Please enter your mobile number"),

  firstname: yup.string().required("Firstname is a required field"),
  surname: yup.string().required("SurName is a required field"),
  lastname: yup.string().required("Lastname is a required field"),
  idtype: yup.string().required("ID type is a required field"),
  idNumber: yup
    .string()
    .required("ID number is required!"),

  country: yup.string().required("Please select country"),
  region: yup.string().required("Please Select Region"),
  district: yup.string().required("Please Select district"),
  street: yup.string().required("This Field is Required"),
  username: yup.string().required("This Field is Required"),
  password: yup
    .string()
    .required("Please enter your  password")
    .matches(/^.*(?=.{6,}).*$/, "Password must contain at least 6 characters"),
  confirmPassword: yup
    .string()
    .required("Please confirm your password")
    .when("password", {
      is: (password) => !!(password && password.length > 0),
      then: yup
        .string()
        .oneOf([yup.ref(" password")], "Password doesn't match"),
    }),
  passwordtrue: yup
    .string()
    .required("Please enter your  password")
    .matches(/^.*(?=.{6,}).*$/, "Password must contain at least 6 characters"),
  confirmPasswordtrue: yup
    .string()
    .required("Please confirm your password")
    .when("password", {
      is: (passwordtrue) => !!(passwordtrue && passwordtrue.length > 0),
      then: yup
        .string()
        .oneOf([yup.ref("password")], "Password doesn't match"),
    }),
});
const Registration = (props) => {
  const [activeTab, setactiveTab] = useState(1);
  const [activeTabProgress, setactiveTabProgress] = useState(1);
  const [progressValue, setprogressValue] = useState(25);
  const [messageError, setMessageError] = useState(false);
  const [messageError1, setMessageError1] = useState("");
  const [messageError2, setMessageError2] = useState("");
  const [countries, setCountry] = useState([]);
  const [state, setState] = useState();
  const [regions, setRegions] = useState([]);
  const [district, setDistrict] = useState([]);
  const [Letter, setLetter] = useState("");
  const [Errorletter, setErrorletter] = useState(false);
  const [AddGuarantor, setAddGuarantor] = useState(false);
  const [isAlreadyRegistered, setIsAlreadyRegistered] = useState("");
  // console.log(isAlreadyRegistered);
  const uploadletter = async (e) => {
    const file = e.target.files[0];
    if (file.type === "application/pdf") {
      setErrorletter(false);
      const base64 = await convertBase64(file);
      setLetter(base64);
    }
    else {
      setErrorletter(true);
    }
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      const results = await axios
        .get(window.$name + "/api/adminstrator/get-countries")
        .then((response) => response.data);
      setCountry(results);
    };

    fetchData();
  }, []);

  const GetRegions = (e) => {
    const { id, value } = e.target;
    formik.values.country = e.target.value;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    axios
      .get(window.$name + "/api/adminstrator/get-regions", {
        params: {
          country_id: e.target.value,
        },
      })
      .then((res) => {
        setRegions(res.data);
      });
  };

  const GetDistrict = (e) => {
    const { id, value } = e.target;
    formik.values.region = e.target.value;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    axios
      .get(window.$name + "/api/adminstrator/get-districts", {
        params: {
          region_id: e.target.value,
        },
      })
      .then((res) => {
        setDistrict(res.data);
      });
  };

  function toggleTabProgress(tab) {
    if (activeTabProgress !== tab) {
      if (tab >= 1 && tab <= 4) {
        setactiveTabProgress(tab);
        if (tab === 1) {
          setprogressValue(25);
        }
        if (tab === 2) {
          setprogressValue(50);
        }
        if (tab === 3) {
          setprogressValue(75);
        }
        if (tab === 4) {
          setprogressValue(100);
        }
      }
    }
  }
  //using useFormik
  const formik = useFormik({
    initialValues,
    validationSchema,
  });
  // console.log(formik.values)
  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <div id="progrss-wizard" className="twitter-bs-wizard">
            <ul className="nav-justified nav">
              <NavItem>
                {activeTabProgress === 2 ||
                  activeTabProgress === 3 ||
                  activeTabProgress === 4 ? (
                    <NavLink
                      className={classnames({
                        active: activeTabProgress === 1,
                      })}
                    >
                      <span className="step-number mr-2 p-3 mb-2  rounded-circle bg-primary text-white">
                        <i
                          className="fa fa-check text-white"
                          aria-hidden="true"
                        ></i>
                      </span>
                    WHO
                    </NavLink>
                  ) : (
                    <NavLink
                      className={classnames({
                        active: activeTabProgress === 1,
                      })}
                    >
                      <span className="step-number mr-2 p-3 mb-2 rounded-circle  bg-secondary text-white">
                        01
                    </span>
                   WHO
                    </NavLink>
                  )}
              </NavItem>
              <NavItem>
                {activeTabProgress === 3 || activeTabProgress === 4 ? (
                  <NavLink
                    className={classnames({
                      active: activeTabProgress === 2,
                    })}
                  >
                    <span className="step-number mr-2 p-3 mb-2  rounded-circle bg-primary text-white">
                      <i
                        className="fa fa-check text-white"
                        aria-hidden="true"
                      ></i>
                    </span>
                    <span>
                       PERSONAL DETAILS</span>
                  </NavLink>
                ) : (
                    <NavLink
                      className={classnames({
                        active: activeTabProgress === 2,
                      })}
                    >
                      <span className="step-number mr-2 p-3 mb-2  rounded-circle bg-secondary text-white">
                        02
                    </span>
                      <span> PERSONAL DETAILS</span>
                    </NavLink>
                  )}
              </NavItem>
              {activeTabProgress === 4 ? (
                <NavLink
                  className={classnames({
                    active: activeTabProgress === 3,
                  })}
                >
                  <span className="step-number mr-2 p-3 mb-2  rounded-circle bg-primary text-white">
                    <i
                      className="fa fa-check text-white"
                      aria-hidden="true"
                    ></i>
                  </span>
                  GUARANTOR DETAILS

                </NavLink>
              ) : (
                  <NavLink
                    className={classnames({
                      active: activeTabProgress === 3,
                    })}
                  >
                    <span className="step-number mr-2 p-3 mb-2  rounded-circle bg-secondary text-white">
                      03
                  </span>
                    <span> GUARANTOR DETAILS</span>
                  </NavLink>
                )}
              <NavItem>
                {activeTabProgress === 4 ? (
                  <NavLink
                    className={classnames({
                      active: activeTabProgress === 4,
                    })}
                  >
                    <span className="step-number mr-2 p-3 mb-2 rounded-circle bg-primary text-white">
                      04
                    </span>
                    OTHER DETAILS
                  </NavLink>
                ) : (
                    <NavLink
                      className={classnames({
                        active: activeTabProgress === 4,
                      })}
                    >
                      <span className="step-number mr-2 p-3 mb-2 rounded-circle bg-secondary text-white">
                        04
                    </span>
                    OTHER DETAILS
                    </NavLink>
                  )}
              </NavItem>
            </ul>
            <TabContent
              activeTab={activeTabProgress}
              // activeTab={4}
              className="twitter-bs-wizard-tab-content mt-4 container"
            >
              <TabPane tabId={1}>
                {messageError ? (
                  <UncontrolledAlert
                    color="danger"
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    <i className="fa fa-warning mr-2"></i>
                   All field are required!
                  </UncontrolledAlert>
                ) : (
                    <></>
                  )}
                <Form>
                  <Row className="mt-5">
                    <p className="container">
                      Did you have an account rather than an agent account for this platform ?
                      &nbsp; &nbsp;
                      Yes &nbsp;
                      <input
                        type="radio"
                        name="choose"
                        value="choose"
                        style={{ height: '25px', width: '25px', verticalAlign: 'middle' }}
                        onChange={() => setIsAlreadyRegistered(true)}
                      /> &nbsp;   &nbsp;
                         OR
                         &nbsp;
                      No &nbsp; &nbsp;
                      <input
                        type="radio"
                        name="choose"
                        value="choose"
                        style={{ height: '25px', width: '25px', verticalAlign: 'middle' }}
                        onChange={() => setIsAlreadyRegistered(false)}
                      />
                    </p>
                  </Row>
                </Form>
              </TabPane>
              <TabPane tabId={2}>
                {messageError2 ? (
                    <UncontrolledAlert
                        color="danger"
                        className="alert-dismissible fade show"
                        role="alert"
                    >
                      <i className="fa fa-warning mr-2"></i>
                      {messageError2}!
                    </UncontrolledAlert>
                ) : (
                    <></>
                )}
                <div>
                  <Form>
                    {isAlreadyRegistered === false ? (
                        <>
                          <Row>
                            <Col lg="4">
                              <FormGroup>
                                <Label for="basicpill-pancard-input18">
                                  Firstname
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-pancard-input18"
                                    name="firstname"
                                    value={formik.values.firstname}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.firstname &&
                                formik.errors.firstname ? (
                                    <div className="text-danger">
                                      {formik.errors.firstname}
                                    </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <Label for="basicpill-cstno-input20">SurName</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-cstno-input20"
                                    name="surname"
                                    value={formik.values.surname}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.surname && formik.errors.surname ? (
                                    <div className="text-danger">
                                      {formik.errors.surname}
                                    </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <Label for="basicpill-vatno-input19">LastName</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-vatno-input19"
                                    name="lastname"
                                    variant="outlined"
                                    value={formik.values.lastname}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.lastname && formik.errors.lastname ? (
                                    <div className="text-danger">
                                      {formik.errors.lastname}
                                    </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="4">
                              <FormGroup>
                                <Label for="basicpill-vatno-input19">Email</Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-vatno-input19"
                                    name="email"
                                    variant="outlined"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.email && formik.errors.email ? (
                                    <div className="text-danger">
                                      {formik.errors.email}
                                    </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <Label for="basicpill-cstno-input20">
                                  Phone Number
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-cstno-input20"
                                    name="phone"
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.phone && formik.errors.phone ? (
                                    <div className="text-danger">
                                      {formik.errors.phone}
                                    </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <Label for="basicpill-servicetax-input21">
                                  ID TYPE
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-servicetax-input21"
                                    name="idtype"
                                    value={formik.values.idtype}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.idtype && formik.errors.idtype ? (
                                    <div className="text-danger">
                                      {formik.errors.idtype}
                                    </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label for="basicpill-servicetax-input21">
                                  ID Number
                                </Label>
                                <input
                                    id="idNumber"
                                    type="text"
                                    className="form-control"
                                    placeholder="idNumber"
                                    name="idNumber"
                                    value={formik.values.idNumber}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.idNumber && formik.errors.idNumber ? (
                                    <div className="text-danger">
                                      {formik.errors.idNumber}
                                    </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label for="basicpill-servicetax-input21">
                                  Username
                                </Label>
                                <input
                                    id="username"
                                    type="text"
                                    className="form-control"
                                    placeholder="idNumber"
                                    name="username"
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.username && formik.errors.username ? (
                                    <div className="text-danger">
                                      {formik.errors.username}
                                    </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <FormGroup>
                                <Label for="basicpill-servicetax-input21">
                                  Password
                                </Label>
                                <input
                                    id="password"
                                    type="password"
                                    className="form-control"
                                    placeholder="Password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />

                                {formik.touched.password && formik.errors.password ? (
                                    <div className="text-danger">
                                      {formik.errors.password}
                                    </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label for="basicpill-servicetax-input21">
                                  Confirm Password
                                </Label>
                                <input
                                    id="confirmPassword"
                                    type="password"
                                    className="form-control"
                                    name="confirmPassword"
                                    placeholder="confirm Password"
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />

                                {formik.touched.confirmPassword &&
                                formik.errors.confirmPassword ? (
                                    <div className="text-danger">
                                      {formik.errors.confirmPassword}
                                    </div>
                                ) : null}
                              </FormGroup>
                            </Col>

                          </Row>
                        </>) : (
                        <>
                          <br />
                          <Row>
                            <p className="container">NB : Please use your email and password  already you have</p>
                          </Row>
                          <Row className="mt-2">
                            <Col>
                              <FormGroup>
                                <Label for="basicpill-vatno-input19">Email</Label>
                                <Input
                                    type="email"
                                    className="form-control"
                                    id="basicpill-vatno-input19"
                                    name="emailtrue"
                                    variant="outlined"
                                    value={formik.values.emailtrue}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.emailtrue && formik.errors.emailtrue ? (
                                    <div className="text-danger">
                                      {formik.errors.emailtrue}
                                    </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup>
                                <Label for="basicpill-servicetax-input21">
                                  ID TYPE
                                </Label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    id="basicpill-servicetax-input21"
                                    name="idtypetrue"
                                    value={formik.values.idtypetrue}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.idtypetrue && formik.errors.idtypetrue ? (
                                    <div className="text-danger">
                                      {formik.errors.idtypetrue}
                                    </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg="4">
                              <FormGroup>
                                <Label for="basicpill-servicetax-input21">
                                  ID Number
                                </Label>
                                <input
                                    id="idNumbertrue"
                                    type="text"
                                    className="form-control"
                                    name="idNumbertrue"
                                    value={formik.values.idNumbertrue}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.idNumbertrue && formik.errors.idNumbertrue ? (
                                    <div className="text-danger">
                                      {formik.errors.idNumbertrue}
                                    </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <Label for="basicpill-servicetax-input21">
                                  Password
                                </Label>
                                <input
                                    id="passwordtrue"
                                    type="password"
                                    className="form-control"
                                    placeholder="Password"
                                    value={formik.values.passwordtrue}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />

                                {formik.touched.passwordtrue && formik.errors.passwordtrue ? (
                                    <div className="text-danger">
                                      {formik.errors.passwordtrue}
                                    </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                            <Col lg="4">
                              <FormGroup>
                                <Label for="basicpill-servicetax-input21">
                                  Confirm Password
                                </Label>
                                <input
                                    id="confirmPasswordtrue"
                                    type="password"
                                    className="form-control"
                                    name="confirmPasswordtrue"
                                    placeholder="confirm Password"
                                    value={formik.values.confirmPasswordtrue}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />

                                {formik.touched.confirmPasswordtrue &&
                                formik.errors.confirmPasswordtrue ? (
                                    <div className="text-danger">
                                      {formik.errors.confirmPasswordtrue}
                                    </div>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                    )
                    }
                  </Form>
                </div>
              </TabPane>
              <TabPane tabId={3}>
                {messageError1 ? (
                  <UncontrolledAlert
                    color="danger"
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    <i className="fa fa-warning mr-2"></i>
                    {messageError1}!
                  </UncontrolledAlert>
                ) : (
                    <></>
                  )}
                <div>
                  <h6 className="text-primary">GUARANTOR ONE</h6>
                  <Row>
                    <Col lg="4">
                      <FormGroup>
                        <Label for="basicpill-pancard-input18">
                          Fullname
                          </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-pancard-input18"
                          name="fullname1"
                          value={formik.values.fullnam1}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.fullname1 &&
                          formik.errors.fullname1 ? (
                            <div className="text-danger">
                              {formik.errors.fullname1}
                            </div>
                          ) : null}
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <Label for="basicpill-vatno-input19">Email</Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-vatno-input19"
                          name="email1"
                          variant="outlined"
                          value={formik.values.email1}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.email1 && formik.errors.email1 ? (
                          <div className="text-danger">
                            {formik.errors.email1}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <Label for="basicpill-cstno-input20">
                          Phone Number
                          </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-cstno-input20"
                          name="phone1"
                          value={formik.values.phone1}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />

                        {/* <InputMask
                            mask="+255-999999999"
                            value={formik.values.phone1}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            {() => (
                              <input
                                type="text"
                                name="phone1"
                                className="form-control"
                              />
                            )}
                          </InputMask> */}
                        {formik.touched.phone1 && formik.errors.phone1 ? (
                          <div className="text-danger">
                            {formik.errors.phone1}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <Label for="basicpill-servicetax-input21">
                          ID TYPE
                          </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-servicetax-input21"
                          name="idtype1"
                          value={formik.values.idtype1}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.idtype1 && formik.errors.idtype1 ? (
                          <div className="text-danger">
                            {formik.errors.idtype1}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <Label for="basicpill-servicetax-input21">
                          ID Number
                          </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="basicpill-servicetax-input21"
                          name="idNumber1"
                          value={formik.values.idNumber1}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {/* <InputMask
                            mask="99999999-99999-99999-99"
                            value={formik.values.idNumber1}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            {() => (
                              <input
                                type="text"
                                name="idNumber1"
                                className="form-control"
                              />
                            )}
                          </InputMask> */}
                        {formik.touched.idNumber1 &&
                          formik.errors.idNumber1 ? (
                            <div className="text-danger">
                              {formik.errors.idNumber1}
                            </div>
                          ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-start">
                      {!AddGuarantor ? (
                        <button
                          className="btn btn-outline-info rounded-pill"
                          onClick={() => setAddGuarantor(true)}
                        >
                          <i class="fa fa-plus" aria-hidden="true"></i>
                        &nbsp;
                          Add Guarantor
                        </button>
                      ) : (
                          <button
                            className="btn btn-outline-info rounded-pill"
                            onClick={() => setAddGuarantor(false)}
                          >
                            <i class="fa fa-times" aria-hidden="true"></i> &nbsp;
                              Remove
                          </button>
                        )
                      }

                    </Col>
                  </Row>
                  <br />
                  {AddGuarantor ? (
                    <>
                      <h6 className="text-primary">GUARANTOR TWO</h6>
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <Label for="basicpill-pancard-input18">
                              Fullname
                          </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-pancard-input18"
                              name="fullname2"
                              value={formik.values.fullnam2}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.fullname2 &&
                              formik.errors.fullname2 ? (
                                <div className="text-danger">
                                  {formik.errors.fullname2}
                                </div>
                              ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <Label for="basicpill-vatno-input19">Email</Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-vatno-input19"
                              name="email2"
                              variant="outlined"
                              value={formik.values.email2}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.email2 && formik.errors.email2 ? (
                              <div className="text-danger">
                                {formik.errors.email2}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="4">
                          <FormGroup>
                            <Label for="basicpill-cstno-input20">
                              Phone Number
                          </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-cstno-input20"
                              name="phone2"
                              value={formik.values.phone2}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.phone2 && formik.errors.phone2 ? (
                              <div className="text-danger">
                                {formik.errors.phone2}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <Label for="basicpill-servicetax-input21">
                              ID TYPE
                          </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-servicetax-input21"
                              name="idtype2"
                              value={formik.values.idtype2}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.idtype2 && formik.errors.idtype2 ? (
                              <div className="text-danger">
                                {formik.errors.idtype2}
                              </div>
                            ) : null}
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <Label for="basicpill-servicetax-input21">
                              ID Number
                          </Label>
                            <Input
                              type="text"
                              className="form-control"
                              id="basicpill-servicetax-input21"
                              name="idNumber2"
                              value={formik.values.idNumber2}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />

                            {formik.touched.idNumber2 &&
                              formik.errors.idNumber2 ? (
                                <div className="text-danger">
                                  {formik.errors.idNumber2}
                                </div>
                              ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  ) : null
                  }
                </div>
              </TabPane>
            <TabPane tabId={4}>

                {messageError ? (
                  <UncontrolledAlert
                    color="danger"
                    className="alert-dismissible fade show"
                    role="alert"
                  >
                    <i className="fa fa-warning mr-2"></i>
                    {messageError}!
                  </UncontrolledAlert>
                ) : (
                    <></>
                  )}
                <Row>
                  <Col >
                    <FormGroup>
                      <Label for="basicpill-pancard-input18">Veo Letter</Label>
                      <Input
                        type="file"
                        className="form-control"
                        id="basicpill-pancard-input18"
                        name="veoletter"
                        defaultValue={Letter}
                        onChange={(e) => {
                          uploadletter(e);
                        }}
                      />
                      {Errorletter ? (
                        <div className="text-danger">
                          "File must be pdf formart"
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                {/* <h6 className="text-primary">RESIDENT DETAILS</h6> */}
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <Label for="basicpill-servicetax-input21">Country</Label>
                      <select
                        className="form-control"
                        id="country"
                        name="country"
                        onChange={(e) => GetRegions(e)}
                        onBlur={formik.handleBlur}
                        value={formik.values.country}
                      >
                        <option value="">please select country.....</option>
                        {countries.map((country) => (
                          <option key={country.id} value={country.id}>
                            {country.country}
                          </option>
                        ))}
                      </select>
                      {formik.touched.country && formik.errors.country ? (
                        <div className="text-danger">
                          {formik.errors.country}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <Label for="basicpill-servicetax-input21">Region</Label>
                      <select
                        onChange={(e) => GetDistrict(e)}
                        id="region"
                        className="form-control"
                        name="region"
                        onBlur={formik.handleBlur}
                        value={formik.values.region}
                      >
                        <option value="">Select a Regions...</option>
                        {regions.map((reg) => (
                          <option key={reg.id} value={reg.id}>
                            {reg.region}
                          </option>
                        ))}
                      </select>
                      {formik.touched.region && formik.errors.region ? (
                        <div className="text-danger">
                          {formik.errors.region}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <Label for="basicpill-servicetax-input21">District</Label>
                      <select
                        onChange={formik.handleChange}
                        id="district"
                        className="form-control"
                        name="district"
                        onBlur={formik.handleBlur}
                        value={formik.values.district}
                      >
                        <option value="">Select a district...</option>
                        {district.map((dist) => (
                          <option key={dist.id} value={dist.district}>
                            {dist.district}
                          </option>
                        ))}
                      </select>
                      {formik.touched.district && formik.errors.district ? (
                        <div className="text-danger">
                          {formik.errors.district}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <Label for="basicpill-servicetax-input21">Street</Label>
                      <input
                        type="text"
                        className="form-control"
                        id="street"
                        placeholder="Street Address"
                        value={formik.values.street}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.street && formik.errors.street ? (
                        <div className="text-danger">
                          {formik.errors.street}
                        </div>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
            <Row>
              <Col className="container float-left">
                {activeTabProgress !== 1 ? (
                  <button
                    className="float-left btn btn-outline-info"
                    type="button"
                    className="btn-info btn-sm text-white"
                    onClick={() => {
                      toggleTabProgress(activeTabProgress - 1);
                    }}
                  >
                    Previous
                  </button>
                ) : (
                    <></>
                  )}
              </Col>
              <Col className="container float-right ">
                {activeTabProgress === 1 ? (
                        <button
                          className="float-right btn btn-outline-info"
                          disabled={isAlreadyRegistered ===""}
                          onClick={() => {
                            toggleTabProgress(activeTabProgress + 1);
                          }}
                        >
                          Continue
                        </button>
                ) : activeTabProgress === 2 ? (
                        <>
                          {activeTabProgress === 2 && isAlreadyRegistered === false
                              ? (
                                  <button
                                      className="float-right btn btn-outline-info"
                                      disabled={
                                        formik.errors.firstname ||
                                        formik.errors.surname ||
                                        formik.errors.lastname ||
                                        formik.errors.phone ||
                                        formik.errors.username ||
                                        formik.errors.email ||
                                        formik.errors.password ||
                                        formik.errors.confirmPassword ||
                                        formik.errors.idtype ||
                                        formik.errors.idNumber

                                      }
                                      onClick={() => {
                                        toggleTabProgress(activeTabProgress + 1);
                                      }}
                                      to="#"
                                  >
                                    Continue
                                  </button>

                              ) : (
                                  <button
                                      className="float-right btn btn-outline-info"
                                      disabled={
                                        !formik.values.emailtrue ||
                                        !formik.values.passwordtrue ||
                                        !formik.values.confirmPasswordtrue ||
                                        !formik.values.idtypetrue ||
                                        !formik.values.idNumbertrue
                                      }
                                      onClick={() => {
                                        toggleTabProgress(activeTabProgress + 1);
                                      }}
                                      to="#"
                                  >
                                    Continue
                                  </button>
                              )}
                        </>

               ) : activeTabProgress === 3 ? (
                  <>
                {activeTabProgress === 3 &&
                  formik.values.fullname1 === "" &&
                  formik.values.email1 === "" &&
                  formik.values.phone1 === "" &&
                  formik.values.idtype1 === "" &&
                  formik.values.idNumber1 === "" ? (
                  <button
                  className="float-right btn btn-outline-info"
                  disabled={
                  formik.errors.fullname1 &&
                  !formik.touched.fullname1 &&
                  formik.errors.email1 &&
                  !formik.touched.email &&
                  formik.errors.phone1 &&
                  !formik.touched.phone1 &&
                  formik.errors.idNumber1 &&
                  !formik.touched.idNumber1 &&
                  formik.errors.idtype1 &&
                  !formik.touched.idtype1
                }
                  onClick={() =>
                  setMessageError1("Please fill all information")
                }
                  to="#"
                  >
                  Continue
                  </button>
                  ) : (
                  <button
                  className="float-right btn btn-outline-info"
                  disabled={
                  formik.errors.fullname1 ||
                  // !formik.touched.fullname1 ||
                  // !formik.touched.fullname2 ||
                  formik.errors.email1 ||
                  // !formik.touched.email1 ||
                  // !formik.touched.email2 ||
                  formik.errors.phone1 ||
                  // !formik.touched.phone1 ||
                  // !formik.touched.phone2 ||
                  formik.errors.idNumber1 ||
                  // !formik.touched.idNumber1 ||
                  // !formik.touched.idNumber2 ||
                  formik.errors.idtype1 ||
                  AddGuarantor !== false &&
                  (
                  AddGuarantor === true &&
                  (!formik.values.idtype2 ||
                  !formik.values.idNumber2 ||
                  !formik.values.phone2 ||
                  !formik.values.email2 ||
                  !formik.values.fullname2)
                  )

                  // !formik.touched.idtype1 ||
                  // !formik.touched.idtype2
                }
                  onClick={() => {
                  toggleTabProgress(activeTabProgress + 1);
                }}
                  to="#"
                  >
                  Continue
                  </button>
                  )}
                  </>
                  ) : (
                        <>
                          {activeTabProgress === 4 &&
                            formik.values.country === "" &&
                            formik.values.region === "" &&
                            formik.values.district === "" &&
                            formik.values.street === "" ? (
                              <button
                                className="float-right btn btn-outline-info"
                                disabled={
                                  formik.errors.country &&
                                  !formik.touched.country &&
                                  formik.errors.region &&
                                  !formik.touched.region &&
                                  formik.errors.district &&
                                  !formik.touched.district &&
                                  formik.errors.street &&
                                  !formik.touched.street
                                }
                                onClick={() =>
                                  setMessageError("Please fill all information")
                                }
                              >
                                Submit Now
                              </button>
                            ) : (
                              <button
                                className="float-right btn btn-outline-info"
                                disabled={
                                  formik.errors.country ||
                                  // !formik.touched.country ||
                                  formik.errors.region ||
                                  // !formik.touched.region ||
                                  formik.errors.district ||
                                  // !formik.touched.district ||
                                  formik.errors.street ||
                                  Letter === ""
                                  // !formik.touched.street
                                }
                                onClick={() => {
                                  AgentRegistration(
                                    formik.values,
                                    Letter, isAlreadyRegistered
                                  );
                                }}
                              >
                                Submit Now
                              </button>
                            )}
                        </>
                      )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </React.Fragment >
  );
};
const mapStateToProps = (state) => ({
  // shippingAddress: state.shippingAddress,
  // profile: state.profile,
});

const mapDispatchToProps = {
  AgentRegistration,
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
