export default function validate(state, registrationState) {
    let errors = {};
    if (!state.phone_email) {
        errors.phone_email = "Username is required";
    }
    if (!state.password) {
        errors.password = "Password is required";
    } else if (state.password.length < 6) {
        errors.password = "Password needs to be more than 5 characters";
    }
    // error message for registration

    if (!registrationState.email) {
        errors.email = "Email is required";
    }
    if (!/\S+@\S+\.\S+/.test(registrationState.email)) {
        errors.email = "This is Invalid Email";
    } else if (!registrationState.passwords) {
        errors.passwords = "Password is required";
    }
    if (!registrationState.confirmPassword) {
        errors.confirmPassword = "Password is required";
    }
    // else if (registrationState.password.length<6){
    //     errors.password="Password needs to be more than 5 characters";
    // }
    if (!registrationState.fullname) {
        errors.fullname = "Fullname is required";
    }
    if (!registrationState.user_phoneNumber) {
        errors.user_phoneNumber = "Phone number is required";
    } else if (registrationState.user_phoneNumber.length < 10 || registrationState.user_phoneNumber.length > 12) {
        errors.user_phoneNumber = "Phone Number needes to be 10 digits also not greate than 12";
    }

    return errors;
}
