// react
import React, {useEffect, useMemo, useState} from "react";
// third-party
import {toast} from "react-toastify";
import {Helmet} from "react-helmet-async";
import {Link} from "react-router-dom";
import theme from "../../data/theme";
import {connect} from "react-redux";
import Moment from "react-moment";
import {makeStyles} from "@material-ui/core/styles";
import {agenttoken} from "../../api/shop";
import Axios from "axios";
import {Spinner} from "reactstrap";
import {green} from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    root: {
        color: green[500],
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
}));

function AccountPageDashboard() {

    //download VEO id
    function downVeo(pdf) {
        const linkSource = `${pdf}`;
        const downloadLink = document.createElement("a");
        const fileName = "VEO.pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }


    const classes = useStyles();
    const [agent, setAgent] = useState([]);
    useEffect(() => {
        const AgentData = async () => {
            const results = {secure_token: agenttoken()};
            const data = await Axios.post(`${window.$name}/api/auth/get-agent-details`,
                results
            ).then((response) => response.data);
            setAgent(data);
        };
        AgentData();
    }, []);
    // console.log(agent);
    return (
        <div className="dashboard">
            {agent.length === 0 ? (
                <>
                    <div className={classes.root}>
            <span className="d-flex justify-content-center">
              <Spinner size="xl"/>
            </span>
                        <span className="d-flex mt-10 justify-content-center font-weight-bold">
              Data Loading...
          </span>
                    </div>
                </>
            ) : (
                <>
                    <Helmet>
                        <title>{`Ezytrade Agent — ${theme.name}`}</title>
                    </Helmet>
                    <div className="dashboard__address card address-card address-card--featured">
                        <div className="address-card__badge text-dark">PERSONAL DETAILS</div>
                        <div className="address-card__body">
                            <div className="address-card__row">
                                <div className="address-card__row-title">Fullname</div>
                                <div className="address-card__row-content">{agent.fullname}</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Email Address</div>
                                <div className="address-card__row-content">{agent.email}</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Phone Number</div>
                                {agent.user_phoneNumber}
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">ID Type</div>
                                <div className="address-card__row-content">{agent.agent.PersonId_type}</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">ID Number</div>
                                <div className="address-card__row-content">{agent.agent.PersonId_No}</div>
                            </div>
                        </div>
                    </div>
                    {agent.agent.business_name === null ?
                        <div className="dashboard__address card address-card address-card--featured">
                            <div className="address-card__badge text-dark">BUSINESS DETAILS</div>
                            <div className="address-card__body">
                                <div className="address-card__row">
                                    <div className="address-card__row-title">Business Name</div>
                                    <div className="address-card__row-content">{agent.agent.business_name}</div>
                                </div>
                                <div className="address-card__row">
                                    <div className="address-card__row-title">TIN</div>
                                    <div className="address-card__row-content">{agent.agent.tin_no}</div>
                                </div>
                                <div className="address-card__row">
                                    <div className="address-card__row-title">Compant registration Number</div>
                                    {agent.agent.company_registration_no}
                                </div>
                            </div>
                        </div>
                        : null
                    }
                    <div className="dashboard__address card address-card address-card--featured">
                        <div className="address-card__badge text-dark">GUARANTOR ONE</div>

                        <div className="address-card__body">
                            <div className="address-card__row">
                                <div className="address-card__row-title">Fullname</div>
                                <div className="address-card__row-content">{agent.agent.guarantorOneName}</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Email Address</div>
                                <div className="address-card__row-content">{agent.agent.guarantorOneEmail}</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Phone Number</div>
                                {agent.agent.guarantorOnePhone}
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">ID Type</div>
                                <div className="address-card__row-content">{agent.agent.guarantorOneId_type}</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">ID Number</div>
                                <div className="address-card__row-content">{agent.agent.guarantorOneId_No}</div>
                            </div>
                        </div>
                    </div>
                    {agent.agent.guarantorTwoName ?
                        <div className="dashboard__address card address-card address-card--featured">
                            <div className="address-card__badge text-dark">GUARANTOR TWO</div>

                            <div className="address-card__body">
                                <div className="address-card__row">
                                    <div className="address-card__row-title">Fullname</div>
                                    <div className="address-card__row-content">{agent.agent.guarantorTwoName}</div>
                                </div>
                                <div className="address-card__row">
                                    <div className="address-card__row-title">Email Address</div>
                                    <div className="address-card__row-content">{agent.agent.guarantorTwoEmail}</div>
                                </div>
                                <div className="address-card__row">
                                    <div className="address-card__row-title">Phone Number</div>
                                    {agent.agent.guarantorOnePhone}
                                </div>
                                <div className="address-card__row">
                                    <div className="address-card__row-title">ID Type</div>
                                    <div className="address-card__row-content">{agent.agent.guarantorTwoId_type}</div>
                                </div>
                                <div className="address-card__row">
                                    <div className="address-card__row-title">ID Number</div>
                                    <div className="address-card__row-content">{agent.agent.guarantorTwoId_No}</div>
                                </div>
                            </div>
                        </div>
                        : null
                    }
                    <div className="dashboard__address card address-card address-card--featured">
                        <div className="address-card__badge text-dark">RESIDENT DETAILS</div>
                        <div className="address-card__body">
                            <div className="address-card__row">
                                <div className="address-card__row-title">Country</div>
                                <div className="address-card__row-content">{agent.agent.country.country}</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Region</div>
                                <div className="address-card__row-content">{agent.agent.region.region}</div>
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">District</div>
                                {agent.agent.district}
                            </div>
                            <div className="address-card__row">
                                <div className="address-card__row-title">Street</div>
                                <div className="address-card__row-content">{agent.agent.street}</div>
                            </div>
                        </div>
                    </div>
                    {agent.agent.veo_image ?
                        <div className="dashboard__address card address-card address-card--featured">
                            <div className="address-card__badge text-dark">AGENT ATTACHMENTS</div>
                            <div className="address-card__body">
                                <div className="address-card__row">
                                    {/* <div className="address-card__row-title">VEO/WARD LETTER</div> */}
                                    <div className="address-card__row-content">
                                        <button
                                            className="btn btn-sm border-none btn-outline-primary"
                                            onClick={() => downVeo(agent.agent.veo_image)} download="VEO.pdf">
                                            Download Veo letter pdf
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                    }
                </>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPageDashboard);
