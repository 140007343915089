import React, {Component} from 'react'
import '../../../assets/css/style.css'
export default class BannerOneHeroHeading extends Component {
    state  = {
        title: 'What do you need to find? ',
        titleHighlight: [
            {
                active: false,
                text: 'Hotels'
            },
            {
                active: false,
                text: 'Restaurants'
            },
            {
                active: false,
                text: 'Shops'
            },
            {
                active: true,
                text: 'Salons'
            },
            {
                active: false,
                text: 'Apartments'
            },
            {
                active: false,
                text: 'Travels'
            },
            {
                active: false,
                text: 'Business'
            },
            {
                active: false,
                text: 'Fitness'
            }
        ],
        desc: 'Discover the best places to stay, eat, shop & visit the city nearest to you.'
    }
    render() {
        return (
                <div className="hero-heading">
                    <div className="section-heading">
                        <h2 className="sec__title cd-headline zoom">
                            {this.state.title}
                            <span className="cd-words-wrapper">
                               {this.state.titleHighlight.slice(0,1).map((item, index) => {
                                   return (
                                       <b className={item.active ? 'is-visible' : ' '} key={index}> {item.text}</b>
                                   )
                                })}
                            </span>
                        </h2>
                    </div>
                </div>
        )
    }
}
