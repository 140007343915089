import {PAID_ORDER_DATA, DELETE_PAID_ORDER_DATA} from "./orderPaidActionTypes";

export default function orderPaidReducer(state=[],action) {
    switch(action.type){
        case PAID_ORDER_DATA:
            return action.payload
        case DELETE_PAID_ORDER_DATA:
            return action.payload
        default:
            return state;
    }
}
