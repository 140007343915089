// react
import React, {Component, useEffect} from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import Indicator from '../header/Indicator';
import CartIndicator from '../header/IndicatorCart';
import QuoteIndicator from '../header/indicatorQuote';
import {
    Menu18x14Svg,
    // LogoSmallSvg,
    Search20Svg,
    Heart20Svg,
    Cart20Svg,
} from '../../svg';
import { mobileMenuOpen } from '../../store/mobile-menu';
import Search from '../header/Search';
import IndicatorAccount from '../header/IndicatorAccount';
import axios from "axios";

class MobileHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchOpen: false,
        };
        this.searchInput = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        const { searchOpen } = this.state;

        if (searchOpen && searchOpen !== prevState.searchOpen && this.searchInput.current) {
            this.searchInput.current.focus();
        }
    }

    handleOpenSearch = () => {
        this.setState(() => ({ searchOpen: true }));
    };

    handleCloseSearch = () => {
        this.setState(() => ({ searchOpen: false }));
    };
//     useEffect(() => {
//     const getuserdata = async () => {
//         const results = await axios.get("http://ip-api.com/json/?fields=status,currency")
//             .then((response) => response.data.currency);
//         // setCurrency(results);
//         localStorage.setItem("userCurrency",results);
//     };
//     getuserdata();
// }, []);
    render() {
        const { openMobileMenu, wishlist, cart } = this.props;
        const { searchOpen } = this.state;
        const searchClasses = classNames('mobile-header__search', {
            'mobile-header__search--open': searchOpen,
        });

        return (
            <div className="mobile-header">
                <div className="mobile-header__panel">
                    <div className="container">
                        <div className="mobile-header__body">
                            <button type="button" className="mobile-header__menu-button" onClick={openMobileMenu}>
                                <Menu18x14Svg />
                            </button>
                            <Link to="/" className="mobile-header__logo">
                            <img width="100PX" height="40px" alt="logo" src="../images/logos/Ezyafrica.png"/>

                                </Link>
                            <Search
                                context="mobile-header"
                                className={searchClasses}
                                inputRef={this.searchInput}
                                onClose={this.handleCloseSearch}
                            />
                            <div className="mobile-header__indicators">
                                <Indicator
                                    className="indicator--mobile indicator--mobile-search d-md-none"
                                    onClick={this.handleOpenSearch}
                                    icon={<Search20Svg />}
                                />
                                <Indicator
                                    className="indicator--mobile"
                                    url="/shop/wishlist"
                                    value={wishlist.length}
                                    icon={<Heart20Svg />}
                                />
                                {/*<Indicator*/}
                                {/*    className="indicator--mobile"*/}
                                {/*    value={cart.quantity}*/}
                                {/*    icon={<Cart20Svg />}*/}
                                {/*/>*/}
                                <QuoteIndicator/>
                                <CartIndicator/>
                                {/* <IndicatorAccount /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    wishlist: state.wishlist,
});

const mapDispatchToProps = {
    openMobileMenu: mobileMenuOpen,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MobileHeader);
