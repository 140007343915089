import React, { useEffect, useState } from "react";
//  third-party
import { useDispatch, connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import theme from "../../data/theme";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import { handleNexts, handleBacks } from "./checkout";
import { Link } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import AsyncAction from "../shared/AsyncAction";
import InputMask from "react-input-mask";
import * as yup from "yup";
import { useFormik } from "formik";
// import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import subDays from "date-fns/subDays";
import {
  AddMyShipping,
  FetchMyShipping,
  ChangeAddress,
  DeleteAddress,
  EditAddress,
} from "../../store/MyownDelivery";
import { customertoken , agentcustomerToken} from "../../api/shop";
import moment from 'moment';
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
//creating the validation schema
const validationSchema = yup.object().shape({
  fullname: yup
    .string()
    .required("Please enter your full name")
    .min(4, "Fullname must be at least 4  characters"),
  idtype: yup.string().required("This field is required"),
  date: yup.string().required("This field is required"),
  idnumber: yup.string().required("This field is required"),
  additional: yup.string().min(12, "Must be at least 12  characters"),
  mobile: yup
    .string()
    .test("len", "Phone number too short must be 12 number.", (val) => {
      const val_length_without_dashes = val.replace(/-|_/g, "").length;
      return val_length_without_dashes === 13;
    })
    .required("Phone number is required!"),

});
function MyownDelivery(props) {
  const dispatch = useDispatch();
  const { activeStep, stepNumber, AddMyShipping,  Myownaddresss, ChangeAddress, DeleteAddress } = props;
 
  useEffect(() => {
    if (customertoken() ||  agentcustomerToken()) {
      dispatch(FetchMyShipping());
    }
  }, [dispatch]);
  const formik = useFormik({
    initialValues: {
      fullname: "",
      mobile: "",
      idnumber: "",
      idtype: "",
    },
    validationSchema,
  });
  // console.log(Myownaddresss)
  const [state, setState] = useState({
    fullname: "",
    mobile: "",
    idnumber: "",
    idtype: "",
  });
  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  
  if (Myownaddresss.length === 0) {
    return (
      <div className="checkout">
        <Helmet>
          <title>{`Address — ${theme.name}`}</title>
        </Helmet>
        <div className="block">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-12 col-lg-6 col-xl-7">
                <div className="card mb-lg-0 rounded">
                  <div className="card-body">
                    <h6 className="card-title text-center">
                      ADD PICK UP DETAILS
                    </h6>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="checkout-first-name">Full Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="fullname"
                          value={formik.values.fullname}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.fullname && formik.errors.fullname ? (
                          <div className="text-danger">
                            {formik.errors.fullname}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="checkout-last-name">Phone Number</label>
                        <input
                          type="text"
                          className="form-control"
                          id="mobile"
                          value={formik.values.mobile}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.mobile && formik.errors.mobile ? (
                          <div className="text-danger">
                            {formik.errors.mobile}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="checkout-country">ID Type</label>
                        <input
                          type="text"
                          className="form-control"
                          id="idtype"
                          value={formik.values.idtype}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.pidtype && formik.errors.idtype ? (
                          <div className="text-danger">
                            {formik.errors.idtype}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="checkout-country">
                          ID Number
                          </label>
                        <input
                          type="text"
                          className="form-control"
                          id="idnumber"
                          value={formik.values.idnumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.idnumber &&
                          formik.errors.idnumber ? (
                            <div className="text-danger">
                              {formik.errors.idnumber}
                            </div>
                          ) : null}
                      </div>
                    </div>                 
                
                    <AsyncAction
                      action={() => AddMyShipping(formik.values)}
                      render={({ run, loading }) => (
                        <button
                          type="button"
                          onClick={run}
                          className={"btn btn-block btn-primary rounded "}
                          disabled={                           
                            !formik.values.idnumber ||
                            !formik.values.idtype ||
                            !formik.values.mobile ||
                            !formik.values.fullname}
                        >
                          Save Address
                        </button>
                      )}
                    />
                  </div>
                </div>
                <br />
                {
                  stepNumber > 0 ? (
                    <button
                      className="btn btn-secondary"
                      onClick={() => handleBacks(activeStep)}
                    >
                      {" "}
                      <i className="fa fa-backward text-black"> </i> Previous
                    </button>
                  ) : null
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else {
    return (
      <div className="checkout">
        <Helmet>
          <title>{`Address — ${theme.name}`}</title>
        </Helmet>
        <div className="checkout block">
          {/* model add new address  */}
          <div
            class="modal fade"
            id="exampleModalLong"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLongTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title container d-flex justify-content-center"
                    id="exampleModalLongTitle"
                  >
                    ADD NEW PICK UP DETAILS
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-first-name">Full Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="fullname"
                        value={formik.values.fullname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />

                      {formik.touched.fullname && formik.errors.fullname ? (
                        <div className="text-danger">
                          {formik.errors.fullname}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-last-name">Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        id="mobile"
                        value={formik.values.mobile}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.mobile && formik.errors.mobile ? (
                        <div className="text-danger">
                          {formik.errors.mobile}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-country">ID Type</label>
                      <input
                        type="text"
                        className="form-control"
                        id="idtype"
                        value={formik.values.idtype}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.pidtype && formik.errors.idtype ? (
                        <div className="text-danger">
                          {formik.errors.idtype}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-country">
                        ID Number
                          </label>
                      <input
                        type="text"
                        className="form-control"
                        id="idnumber"
                        value={formik.values.idnumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.idnumber &&
                        formik.errors.idnumber ? (
                          <div className="text-danger">
                            {formik.errors.idnumber}
                          </div>
                        ) : null}
                    </div>
                  </div>                 
                  
                  <AsyncAction
                    action={() => AddMyShipping(formik.values)}
                    render={({ run, loading }) => (
                      <button
                        type="button"
                        onClick={run}
                        className={"btn btn-block btn-primary rounded "}
                        disabled={
                          !formik.values.idnumber ||
                          !formik.values.idtype ||
                          !formik.values.mobile ||
                          !formik.values.fullname}
                        data-dismiss="modal"
                      >
                        Save Address
                      </button>
                    )}
                  />
                </div>{" "}
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                  </button>
              </div>
            </div>
          </div>
          <div className="container mt-4">
            <div className="row">
              <div className="col-12 col-lg-6 col-xl-7">
                <div className="card mb-lg-0">
                  <div className="card-body">
                    <div className="row card-title">
                      <div className="col-8">
                        <h6>CURRENT PICK UP DETAILS</h6>
                      </div>
                      <div className="col-4">
                        <Link
                          data-toggle="modal"
                          data-target="#exampleModalLong"
                        >
                          <label
                            htmlFor="checkout-country"
                            className="font-weight-bold address-card__badge rounded btn btn-primary btn-sm"
                          >
                            {" "}
                            <i className="fa fa-plus-square"> </i>
                            Add New
                          </label>{" "}
                        </Link>
                      </div>
                    </div>
                    <div />
                    <div>
                      <p>
                        Please choose pick up  option for this order.
                        When finished,
                        click "<b>I will pick up to this option</b>"
                      </p>
                      <hr />
                      {Myownaddresss.map((address) => (
                        <div>
                          {address.is_active ? (
                            <div>
                              <div className=" mb-lg-0 border-white">
                                <div className=" card-body">
                                  <p>
                                    Full Name :   &nbsp;&nbsp;{address.address_fullname}
                                  </p>
                                  <p>
                                    Mobile :  &nbsp;&nbsp;{address.address_mobileNumber}
                                  </p>                                 
                                  <p>Id Type :  &nbsp;&nbsp;{address.id_type}
                                  </p>
                                  <p>
                                    Id Number :  &nbsp;&nbsp;{address.id_number}
                                  </p>                                  
                                </div>
                                <button
                                  type="submit"
                                  onClick={() =>
                                    handleNexts(activeStep)
                                  }
                                  className="btn-block btn btn-primary"                                 
                                >
                                  {" "}
                                  <i className="fa fa-truck text-black mr-2">
                                    {" "}
                                  </i>
                                I WILL PICK UP TO THIS OPTION
                                </button>
                              </div>
                            </div>
                          ) : (
                              <div></div>
                            )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-5 ">
                <div className="card mb-0">
                  <div className="card-body">
                    <h6 className="card-title text-center">OTHER PICK UP OPTION</h6>
                  </div>
                  <hr />
                  <Scrollbars style={{ height: 555 }}>
                    {Myownaddresss.map((address) => (
                      <div>
                        {!address.is_active ? (
                          <div>
                            <div>
                              <AsyncAction
                                action={() =>
                                  ChangeAddress(address.secure_token)
                                }
                                render={({ run, loading }) => (
                                  <button
                                    type="button"
                                    onClick={run}
                                    className={
                                      "btn btn-primary btn-sm rounded "
                                    }
                                  >
                                    <i className="fa fa-check-square text-primary mr-1">
                                      {" "}
                                    </i>
                                    Choose This Option{" "}
                                  </button>
                                )}
                              />
                            </div>
                            <div className=" card-body">
                              <p>
                                Full Name :   &nbsp;&nbsp;{address.address_fullname}
                              </p>
                              <p>
                                Mobile :  &nbsp;&nbsp;{address.address_mobileNumber}
                              </p>                            
                              <p>Id Type :  &nbsp;&nbsp;{address.id_type}
                              </p>
                              <p>
                                Id Number :  &nbsp;&nbsp;{address.id_number}
                              </p>
                            </div>
                            <hr />
                          </div>
                        ) : (
                            <div></div>
                          )}
                      </div>
                    ))}
                  </Scrollbars>
                </div>
              </div>
            </div>
            {stepNumber > 0 ? (
              <button
                className="btn btn-secondary  mt-2"
                onClick={() => handleBacks(activeStep)}
              >
                {" "}
                <i className="fa fa-backward text-black"> </i> Back
              </button>
            ) : null
            }
          </div>
          <br></br>
        </div>
      </div>
    );

  }
}
const mapStateToProps = (state) => ({
  Myownaddresss: state.Myownaddresss,
});

const mapDispatchToProps = {
  AddMyShipping,
  ChangeAddress,
  DeleteAddress,
  EditAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(MyownDelivery);
