// react
import React, {useEffect, useMemo, useState} from "react";
import axios from "axios";
// third-party
import {Helmet} from "react-helmet-async";
import theme from "../../data/theme";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {customertoken, userCurrency} from "../../api/shop";
import {Link} from "react-router-dom";
import {Pagination, Search} from "../DataTable";
import Moment from "react-moment";
import {FetchOrders, DeleteOrders} from "../../store/orders";
import {connect, useDispatch, useSelector} from "react-redux";
import CurrencyFormat from "react-currency-format";
import AsyncAction from "../shared/AsyncAction";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {FetchPendingOrders} from "../../store/orders/PedingOrder";
import {FetchPaidOrders} from "../../store/orders/paidOrder";
import {FetchShippedOrders} from "../../store/orders/ShippedOrder";
import {FetchDeliveryOrders} from "../../store/orders/DeliveredOrder";
import {FetchPartialOrders} from "../../store/orders/PartialPaidOrder";

function DeliveryOrder(props) {
    const [orders, setOrders] = useState([]);
    const [Rates, setRates] = useState([]);
    const [Cancelnumber, setNumber] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const dispatch = useDispatch();
    useEffect(() => {
        if (customertoken()) {
            dispatch(FetchPendingOrders());
            dispatch(FetchPaidOrders());
            dispatch(FetchShippedOrders());
            dispatch(FetchDeliveryOrders());
            dispatch(FetchPartialOrders())
        }
    }, [dispatch]);
    const DataList = useSelector((state) => state.DeliveryOrder);
    const RateList = useSelector((state) => state.RateList);
    useEffect(() => {
        setOrders(DataList);
    }, [DataList]);
    useEffect(() => {
        setRates(RateList)
    }, [RateList]);
    // const Rates = JSON.parse(Rates.rate);
    const {DeleteOrders} = props;
    //order area

    const ITEMS_PER_PAGE = 10;
    const orderData = useMemo(() => {
        let computedOrders = orders;
        if (search) {
            computedOrders = computedOrders.filter(
                (order) =>
                    order.order_number.toLowerCase().includes(search.toLowerCase()) ||
                    order.order_status.toLowerCase().includes(search.toLowerCase()) ||
                    order.total_vat.toLowerCase().includes(search.toLowerCase()) ||
                    order.total_amount.toLowerCase().includes(search.toLowerCase())
            );
        }
        setTotalItems(computedOrders.length);
        //Current Page slice
        return computedOrders.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [orders, currentPage, search]);
    ;
    //delivered order start here
    const ordersListDelivered = orderData.map((order) => (
        <tr key={order.id}>
            <td>{order.order_number}</td>
            <td className="text-primary">Delivered</td>
            <td><CurrencyFormat
                value={order.total_vat}
                displayType={"text"}
                thousandSeparator={true}
                renderText={(value) => (
                    <div>
                        {order.currency}{" "}{value}
                        {/*{product.wholesale_currency} {value}*/}
                    </div>
                )}
            />
            </td>
            <td><CurrencyFormat
                value={order.total_amount}
                displayType={"text"}
                thousandSeparator={true}
                renderText={(value) => (
                    <div>
                        {order.currency}{" "}{value}
                        {/*{product.wholesale_currency} {value}*/}
                    </div>
                )}
            />
            </td>
            <td>
                {" "}
                <Moment format="DD/MM/YYYY">{order.created_at}</Moment>
            </td>
            <td>
                <div className="dropdown">
                    <button
                        class="btn btn-white"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <b>...</b>
                    </button>
                    <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                    >
                        <Link
                            className="dropdown-item"
                            to={{
                                pathname: "/account/vieworder",
                                state: [{order}, {Rates}],
                            }}
                        >
                            View items
                        </Link>
                        {/*<a*/}
                        {/*  href={*/}
                        {/*    window.$name +*/}
                        {/*    `/api/order/Order/details/pdf/${order.order_number}`*/}
                        {/*  }*/}
                        {/*  className="footer-links__link dropdown-item"*/}
                        {/*  target="_blank"*/}
                        {/*  rel="noopener noreferrer"*/}
                        {/*>*/}
                        {/*  Generate PDF*/}
                        {/*</a>*/}
                    </div>
                </div>
            </td>
        </tr>
    ));

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Order Details — ${theme.name}`}</title>
            </Helmet>
            <div className="card">

                <div className="card-header">
                    <div className="d-flex align-items-end flex-column">
                        <div className="mt-auto p-2">
                            <Search
                                onSearch={(value) => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-divider"/>
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                            <tr>
                                <th>Order No</th>
                                <th>Order Status</th>
                                <th>Total Vat</th>
                                <th>Total Amount</th>
                                <th>Date</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>{ordersListDelivered}</tbody>
                        </table>
                    </div>
                </div>
                <div className="card-divider"/>
                <div className="card-footer">
                    <div className="d-flex align-items-center flex-column">
                        <Pagination
                            total={totalItems}
                            itemsPerPage={ITEMS_PER_PAGE}
                            currentPage={currentPage}
                            onPageChange={(page) => setCurrentPage(page)}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    DeleteOrders,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeliveryOrder);
