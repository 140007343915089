// react
import React, { useEffect, useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// data stubs
// import addresses from "../../data/accountAddresses";
// import allOrders from "../../data/accountOrders";
import theme from "../../data/theme";
import axios from "axios";
import {customertoken} from "../../api/shop";

export default function AccountPageDashboard() {
  // query customer orders here
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const results = await axios
        .post(window.$name+'/api/order/get-order-info', {
          user_token: customertoken(),
        })
        .then((response) => response.data);
      setData(results);
      // console.log("tag", results);
    };

    fetchData();
  }, []);
  //query customer address
  const [addressdata, setAddress] = useState([]);
  useEffect(() => {
    const fetchaddress = async () => {
      const results = await axios
        .post(window.$name+'/api/order/get-address', {
          user_token: customertoken(),
        })
        .then((response) => response.data);
      setAddress(results);
      // console.log("tag", results);
    };
    fetchaddress();
  }, []);
  let group;
  if (1  > 0) {
    group = (
      <div className="dashboard__orders card">
        {/* <div className="card-divider" /> */}
        <div className="card-table">
          <div className="table-responsive-sm">
            <table>
              <thead>
                <tr>
                  <th>Group No</th>
                  <th>Group Name</th>
                  <th>Total Member</th>
                  <th>Date</th>
                  <th>Action </th>
                </tr>
              </thead>

              <tbody>
                {data.slice(0, 3).map((order) => (
                  <tr key={order.id}>
                    <td>G-65342</td>
                    <td>TYD Group</td>
                    <td>45</td>
                    <td>12/3/2020</td>
                    <td>Open</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  } else {
    group = (
      <div className="products-view__empty border-white d-flex justify-content-center">
        <div className="products-view__empty-title">
          Your Not Exist To Any Group
        </div>
        <div className="products-view__empty-subtitle">
          Please Create Your Group Now by Click "create group now" Bellow
        </div>
        <Link to="/account/CreateGroup" className="btn btn-primary btn-sm">
          Create Group Now
        </Link>
      </div>
    );
  }

  return (
    <div className="dashboard">
      <Helmet>
        <title>{`My Account — ${theme.name}`}</title>
      </Helmet>
      {group}
    </div>
  );
}
