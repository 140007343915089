// react
import React, {useEffect, useState} from "react";
// third-party
import {Helmet} from "react-helmet-async";
import {Link} from "react-router-dom";
import theme from "../../data/theme";
import {customertoken} from "../../api/shop";
import {connect, useDispatch, useSelector} from "react-redux";
import Moment from "react-moment";
import {makeStyles} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import {Spinner} from "reactstrap";
import {FetchBookmark,DeleteBookmark} from "../../store/bookMark";

const useStyles = makeStyles((theme) => ({
    root: {
        color: green[500],
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
}));

function Booking(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        if (customertoken()) {
            dispatch(FetchBookmark());
        }
    }, [dispatch]);
const {DeleteBookmark}=props
    const DataList = useSelector((state) => state.bookmark);
    // console.log(DataList)
    const [bookmarkdata, setBookmarkdata] = useState([]);
    const [Token ,setToken]=useState(null)
    useEffect(() => {
        setBookmarkdata(DataList);
    }, [DataList]);
    // const [totalItems, setTotalItems] = useState(0);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [search, setSearch] = useState("");
    // const ITEMS_PER_PAGE = 5;
    // console.log(orders)

    // const bookingData = useMemo(() => {
    //     let computedBook = bookingdata;
    //     if (search) {
    //         computedBook = computedBook.filter(
    //             (booking) =>
    //                 booking.listing.listing_title.toLowerCase().includes(search.toLowerCase()) ||
    //                 booking.listing.slug.toLowerCase().includes(search.toLowerCase()) ||
    //                 booking.listing.allow_booking.toLowerCase().includes(search.toLowerCase()) ||
    //                 booking.listing.allow_postpaid.toLowerCase().includes(search.toLowerCase())
    //         );
    //     }
    //     setTotalItems(computedBook.length);
    //     //Current Page slice
    //     return computedBook.slice(
    //         (currentPage - 1) * ITEMS_PER_PAGE,
    //         (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    //     );
    // }, [bookingdata, currentPage, search]);

    return (
        <div className="dashboard">
            {bookmarkdata.length <= 0 ? (
                <>
                    <div className={classes.root}>

                        <span className="d-flex justify-content-center">
                            <Spinner size="xl"/>
                        </span>
                        <span className="d-flex mt-10 justify-content-center font-weight-bold">
                            Data Loading...
            </span></div>

                </>
            ) : (
                <>
                    <Helmet>
                        <title>{`My Account — ${theme.name}`}</title>
                    </Helmet>
                    <div className="dashboard__orders card">
                        <div className="card-header">
                            <div className="">RECENT ORDERS</div>
                            {/*<div className="d-flex align-items-end flex-column">*/}
                            {/*    <div className="mt-auto p-2">*/}
                            {/*        <Search*/}
                            {/*            onSearch={(value) => {*/}
                            {/*                setSearch(value);*/}
                            {/*                setCurrentPage(1);*/}
                            {/*            }}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>

                        {/*{Rates.length === 0 ?*/}
                        {/*    (*/}
                        {/*        <div className={classes.root}>*/}
                        {/*            <span className="d-flex justify-content-center">*/}
                        {/*                <Spinner size="xl" />*/}
                        {/*            </span>*/}
                        {/*            <span className="d-flex mt-10 justify-content-center font-weight-bold">*/}
                        {/*                Data Loading...*/}
                        {/*            </span>*/}
                        {/*        </div>*/}

                        {/*    ) : (*/}
                        {/*        <>*/}
                        <div className="card-divider"/>
                        <div className="card-table">
                            <div className="table-responsive-sm">
                                <table>
                                    <thead>
                                    <tr className="text-center">
                                        <th>Name</th>
                                        <th>Slug</th>
                                        <th>Booking</th>
                                        <th>Post paid</th>
                                        <th>Pre paid</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {bookmarkdata.map((index) => (
                                        <tr className="text-center">
                                            <td>{index.listing.listing_title}</td>
                                            <td>{index.listing.slug}</td>
                                            <td>
                                                {
                                                    index.listing.allow_booking ?
                                                        <>Allowed</>:
                                                        <>Not Allowed</>
                                                }

                                            </td>
                                            <td>
                                                {
                                                    index.listing.allow_postpaid ?
                                                        <>Allowed</>:
                                                        <>Not Allowed</>
                                                }

                                            </td>
                                            <td>
                                                {
                                                    index.listing.allow_prepaid ?
                                                        <>Allowed</>:
                                                        <>Not Allowed</>
                                                }
                                            </td>
                                            <td>
                                                <Moment format="DD/MM/YYYY">
                                                    {index.created_at}
                                                </Moment>
                                            </td>
                                            <td>
                                                <div className="dropdown">
                                                    <button
                                                        class="btn btn-white"
                                                        type="button"
                                                        id="dropdownMenuButton"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <b>...</b>
                                                    </button>
                                                    <div
                                                        className="dropdown-menu"
                                                        aria-labelledby="dropdownMenuButton"
                                                    >
                                                        <Link
                                                            className="dropdown-item"
                                                            to={`/listing-details/${index.listing.url_head}`}>
                                                           Book Now
                                                        </Link>
                                                        <button
                                                            onClick={() =>
                                                                setToken(index.secure_token)
                                                            }
                                                            type="button"
                                                            className="btn btn-outline-danger text-center btn-block" data-toggle="modal"
                                                            data-target="#cancelModal">
                                                           Delete
                                                        </button>
                                                    </div>
                                                    {/* model for confirmation cancel order */}
                                                    <div className="modal fade" id="cancelModal" tabIndex="-1"
                                                         role="dialog"
                                                         aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                        <div className="modal-dialog" role="document">
                                                            <div className="modal-content">
                                                                <div className="modal-header">
                                                                    {/*<h5 className="modal-title" id="exampleModalLabel">Modal title</h5>*/}
                                                                    <button type="button" className="close"
                                                                            data-dismiss="modal" aria-label="Close">
                                                                        <span aria-hidden="true">&times;</span>
                                                                    </button>
                                                                </div>
                                                                <div className="modal-body">
                                                                    Are you sure want to cancel this booking ?
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-secondary"
                                                                            data-dismiss="modal">Close
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        data-dismiss="modal"
                                                                        onClick={() => DeleteBookmark(Token)}
                                                                        className="btn btn-danger">
                                                                        delete
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/*    </>*/}
                        {/*)}*/}
                        <div className="card-footer">
                            {/* <div className="d-flex align-items-center flex-column">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div> */}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    // Booking: state.booking

});

const mapDispatchToProps = {
    FetchBookmark,
    DeleteBookmark
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Booking);
