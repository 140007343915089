// react
import React, {useEffect, useRef, useState} from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import Currency from '../shared/Currency';
import { Check100Svg } from '../../svg';

// data stubs
import order from '../../data/accountOrderDetails';
import theme from '../../data/theme';
import { url } from '../../services/utils';
import { userCurrency } from "../../api/shop";
import CurrencyFormat from "react-currency-format";

export default function MasterCard() {
    const successOrder=JSON.parse(localStorage.getItem('success_data'));
    const [session, setSession] = useState(successOrder.session_id);
    const ref = useRef(null);
    // console.log(ref)


// .session_id

    const configureCheckout = (session) => {

        window.Checkout.configure({
            session: {
                id: session,
            },
            interaction: {
                merchant: {
                    name: "Ezytrade Africa Online Market",
                    address: {
                        line1: "+255 743 779 999",
                        line2: "support@ezytrade.africa",
                    },
                },
            },
        });

        localStorage.removeItem("masterCard");
    };

    configureCheckout(session)
    // click here for request nmb ui
    useEffect(()=>{
        if(ref.current !=null){
            ref.current.click();
        }
    })

    return (
        <div className="block order-success">
            <Helmet>
                <title>{`Order Success — ${theme.name}`}</title>
            </Helmet>
            <div className="container">
                <div className="order-success__body">
                    <div className="order-success__header">
                        <Check100Svg className="order-success__icon text-success" />
                        <h1 className="order-success__title text-success">Wait for Payment</h1>
                        <div className="order-success__subtitle">
                            Please wait while we are connecting to the payment gateway
                        </div>
                        {/*<div className="order-success__actions">*/}
                        {/*    <Link to="/" className="btn btn-xs btn-secondary">Go To Homepage</Link>*/}
                        {/*</div>*/}
                    </div>
                    <div className="order-success__meta">
                        <ul className="order-success__meta-list">
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Order number:</span>
                                <span className="order-success__meta-value">{successOrder.order_number}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Total amount:</span>
                                <span className="order-success__meta-value">
                          <CurrencyFormat
                              value={successOrder.total_amount
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              renderText={(value) => (
                                  <div>
                                      {userCurrency()}{" "}{value}
                                  </div>
                              )}
                          />

                                    </span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Payment method:</span>
                                <span className="order-success__meta-value">{successOrder.payment_method}</span>
                            </li>
                        </ul>
                    </div>

                    <Link
                        style={{
                            color: "white",
                            backgroundColor: "white",
                        }}
                        ref={ref}
                        onClick={window.Checkout.showPaymentPage}
                    />
                </div>
            </div>
        </div>
    );
}