import {QUOTEAPPLIED} from "./appliedQouteActionTypes";

export default function appliedQouteReducer(state=[],action) {
    switch(action.type){
        case QUOTEAPPLIED:
            return action.payload      
        default:
            return state;
    }
}
