import React from 'react';
import {GiPositionMarker, GiChickenOven} from 'react-icons/gi'
import {MdStar} from 'react-icons/md'
import {BsPencil} from 'react-icons/bs'
import {AiOutlineFlag, AiOutlineMail} from 'react-icons/ai'
import {
    FaRegMoneyBillAlt,
    FaFacebookF,
    FaTwitter,
    FaInstagram,
    FaTumblr,
    FaSnapchatGhost,
    FaGooglePlusG,
    FaPinterest,
    FaVk,
    FaLinkedinIn,
    FaYoutube
} from 'react-icons/fa'
import {RiExternalLinkLine} from 'react-icons/ri'
import {useFormik} from "formik";
import * as yup from "yup";
import {connect} from "react-redux";
import AsyncAction from "../../components/shared/AsyncAction";
import {Dropdown} from "react-bootstrap";
import {Link} from "react-router-dom";
import Button from './bookmarkButton'
import {AddReport} from "../../store/Report";
import { agentcustomerToken, customertoken } from '../../api/shop';
import {BiPhoneCall} from "react-icons/all";

const validationSchema = yup.object().shape({
    message: yup
        .string()
        .required("This field is required")
});

function ListingDetailsBreadcrumb(props) {
    const {title, image, location} = props
    console.log(location)
    //using useFormik
    const formik = useFormik({
        initialValues: {
            listing_id: title.id,
            message: "",

        },
        validationSchema,

    });
console.log(title)
    const state = {
        title: 'Tasty Hand-Pulled Noodles',
        stitle: '101 East Parkview Road, New York',
        shareLinks: [
            {
                icon: <FaFacebookF/>,
                title: 'facebook',
                url: 'https://facebook.com'
            },
            {
                icon: <FaTwitter/>,
                title: 'twitter',
                url: 'https://twitter.com'
            },
            {
                icon: <FaInstagram/>,
                title: 'twitter',
                url: 'https://instagram.com'
            },
            {
                icon: <FaTumblr/>,
                title: 'tumblr',
                url: 'https://tumblr.com'
            },
            {
                icon: <FaSnapchatGhost/>,
                title: 'snapchat',
                url: 'https://snapchat.com'
            },
            {
                icon: <FaGooglePlusG/>,
                title: 'google plus',
                url: 'https://plus.google.com'
            },
            {
                icon: <FaPinterest/>,
                title: 'pinterest',
                url: 'https://pinterest.com'
            },
            {
                icon: <FaVk/>,
                title: 'vkontakte',
                url: 'https://vkontakte.com'
            },
            {
                icon: <FaLinkedinIn/>,
                title: 'linkedin',
                url: 'https://linkedin.com'
            },
            {
                icon: <FaYoutube/>,
                title: 'youtube',
                url: 'https://youtube.com'
            }
        ]
    }
    return (
        <>
            <section style={{
                paddingTop: '50px',
                paddingBottom: '150px',
                position: 'relative',
                zIndex: '1',
                color: '#fff',
                backgroundImage: `url(${image[0].image_url})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                overflow: 'hidden',
            }} className="breadcrumb-area listing-detail-breadcrumb">
                <div className="breadcrumb-wrap">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 position-relative">
                                <div className="breadcrumb-content">
                                    <h2 className="breadcrumb__title text-uppercase">
                                        {title.listing_title}
                                    </h2>
                                    <p className="breadcrumb__desc">
                                        <span
                                            className="la d-inline-block text-capitalize">
                                            <GiPositionMarker/> {" "}
                                        </span>
                                        {location.country}, {location.region}, {location.district}
                                    </p>

                                    <p className="breadcrumb__desc">
                                        <span
                                            className="la d-inline-block text-capitalize">
                                            <AiOutlineMail/>

                                        </span>
                                        {" "}{location.email}
                                    </p>
                                    <p className="breadcrumb__desc">
                                        <span
                                            className="la d-inline-block text-capitalize">
                                            <BiPhoneCall/>
                                        </span>
                                        {" "} {location.phone_number}
                                    </p>
                                    <ul className="listing-info mt-3 mb-3">
                                        <li>
                                            <div className="average-ratings">
                                                    <span className="theme-btn button-success mr-1">
                                                        4.2 <i className="d-inline-block"><MdStar/></i>
                                                    </span>
                                                <span><strong>36</strong> Reviews</span>
                                            </div>
                                        </li>
                                        <li>
                                                <span className="theme-btn listing-tag">
                                                    <i className="d-inline-block"><GiChickenOven/></i> {title.slug}
                                                </span>
                                        </li>
                                    </ul>
                                    <ul className="listing-info">
                                        <li>
                                            <Button id={title.id}/>
                                            {/*<button type="button" className="theme-btn border-0">*/}
                                            {/*    <i className="d-inline-block"><RiBookmarkLine /></i> Bookmark*/}
                                            {/*</button>*/}
                                        </li>
                                        <li>
                                            <Dropdown className="dropdown share-dropmenu">
                                                <Dropdown.Toggle
                                                    className="theme-btn dropdown-toggle border-0 after-none"
                                                    id="dropdown-basic">
                                                    <i className="d-inline-block"><RiExternalLinkLine/></i> share
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="dropdown-menu">
                                                    {state.shareLinks.map((item, index) => {
                                                        return (
                                                            <Dropdown.Item href={item.url}
                                                                           className={'dropdown-item ' + item.title}
                                                                           key={index}>
                                                                <i className="d-inline-block">{item.icon}</i> {item.title}
                                                            </Dropdown.Item>
                                                        )
                                                    })}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </li>
                                        <li>
                                            <Link to="#"
                                             disabled={!customertoken() || !agentcustomerToken()}
                                            className="theme-btn report-modal-btn" data-toggle="modal"
                                                  data-target=".bd-example-modal-lg">
                                                <i className="d-inline-block"><AiOutlineFlag/></i> report
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bread-svg">
                    <svg viewBox="0 0 500 150" preserveAspectRatio="none">
                        <path d="M-4.22,89.30 C280.19,26.14 324.21,125.81 511.00,41.94 L500.00,150.00 L0.00,150.00 Z"/>
                    </svg>
                </div>
            </section>
            <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog"
                 aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title text-center"><i className="fa fa-flag"></i> Report this Listing
                            </h4>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="contact-form-action">
                                <div className="msg-box">
                                    <label className="label-text">Write Message</label>
                                    <div className="form-group">
                                        <i className="form-icon"><BsPencil/></i>
                                        <textarea
                                            className="message-control form-control"
                                            name="message"
                                            onChange={formik.handleChange}
                                            type="text"
                                            id="message"
                                            onBlur={formik.handleBlur}
                                            value={formik.values.message}
                                            placeholder="What's wrong with this listing?">

                                            </textarea>
                                    </div>
                                </div>
                                <div className="btn-box text-right">
                                    <AsyncAction
                                        action={() => AddReport(formik.values)}
                                        render={({run, loading}) => (
                                            <button
                                                type="button"
                                                onClick={run}
                                                disabled={!(formik.isValid && formik.dirty)}
                                                data-dismiss="modal"
                                                className="btn button-success border-0">
                                                Send message
                                            </button>
                                        )}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    );

}

const mapStateToProps = (state) => ({
    // shippingAddress: state.shippingAddress,
});
const mapDispatchToProps = {
    AddReport
};
export default connect(mapStateToProps, mapDispatchToProps)(ListingDetailsBreadcrumb);

