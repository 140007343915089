import { combineReducers } from "redux";
// reducers
import cartReducer from "./cart";
import compareReducer from "./compare";
import currencyReducer from "./currency";
import localeReducer from "./locale";
import mobileMenuReducer from "./mobile-menu";
import quickviewReducer from "./quickview";
import sidebarReducer from "./sidebar";
import version from "./version";
import wishlistReducer from "./wishlist";
import shippingReducer from "./shippingAddress";
import userReducer from "./auth";
import profileReducer from "./profile";
import buyReducer from "./buynow";
import QuoteReducer from "./quote";
import quotelistReducer from "./QuoteList";
import agentReducer from "./Agent";
import paymentReducer from "./paymentAgent";
import customerReducer from "./agentCustomer";
import orderReducer from './orders';
import reducerRate from './RateData';
import linkReducer from "./Links";
import reducerAppliedRate from "./QuoteRate";
import appliedQouteReducer from "./AppliedQoute";
import shippingOptReducer from "./ShippingOpt";
import myshippingReducer from "./MyownDelivery";
import masterNumberReducer from "./masterNumber";
import digitalcartReducer from "./digitalCart";
import digitalwishlistReducer from './digitalWishlist';
import digitalbuyReducer from "./digitalbuynow";
import bookmarkReducer from "./bookMark";
import DirectoryReducer from "./booking";
import reportReducer from "./Report";
import reviewReducer from "./ReviewDirectory";
import orderPaidReducer from "./orders/paidOrder";
import orderDeliveryReducer from "./orders/DeliveredOrder";
import orderPendingReducer from "./orders/PedingOrder";
import orderPartialReducer from "./orders/PartialPaidOrder";
import orderShippedReducer from "./orders/ShippedOrder";
import ListingReducer from "./Listings";
import ListingDetailReducer from "./ListingDetails";
import DeleteListingReducer from "./DeleteLitsting";
import ListingBookingReducer from "./ListingApprove";
import UserListingReducer from "./UserListing";
import CommissionReducer from "./commissionAgent";
import CurrentCustomerReducer from "./currentCustomer";
import LeadsReducer from "./ProductLeads";
export default combineReducers({
  version: (state = version) => state,
  cart: cartReducer,
  digitalcart:digitalcartReducer,
  compare: compareReducer,
  currency: currencyReducer,
  locale: localeReducer,
  mobileMenu: mobileMenuReducer,
  quickview: quickviewReducer,
  sidebar: sidebarReducer,
  wishlist: wishlistReducer,
  digitalwishlist: digitalwishlistReducer,
  auth: userReducer,
  shippingAddress: shippingReducer,
  MyownShipping:shippingOptReducer,
  Myownaddresss:myshippingReducer,
  profile: profileReducer,
  buynow: buyReducer,
  digitalbuynow:digitalbuyReducer,
  quotation: QuoteReducer,
  AgentCustomer: customerReducer,
  AgentProfile: agentReducer,
  Agentpayment: paymentReducer,
  OrdersList:orderReducer,
  PaidOrder:orderPaidReducer,
  PartialOrder:orderPartialReducer,
  ShippedOrder:orderShippedReducer,
  DeliveryOrder:orderDeliveryReducer,
  PendingOrder:orderPendingReducer,
  MasterNumber:masterNumberReducer,
  RateList:reducerRate,
  QuoteRateList:reducerAppliedRate,
  AppliedQuote:appliedQouteReducer,
  quoteItem:quotelistReducer,
  linkdata:linkReducer,
  bookmark:bookmarkReducer,
  booking:DirectoryReducer,
  report:reportReducer,
  DirectoryReview:reviewReducer,
  ListingData:ListingReducer,
  ListingDataDetail:ListingDetailReducer,
  DeleteListing:DeleteListingReducer,
  BookingApprove:ListingBookingReducer,
  UserListingData:UserListingReducer,
  AgentCommission:CommissionReducer,
  CurrentCustomer:CurrentCustomerReducer,
  LeadsProduct:LeadsReducer
});
