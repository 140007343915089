import {RATE_DATA} from "./ActionTypes";

export function FetchRate(Rate) {
    return {
        type:RATE_DATA,
        Rate
    };
  }


