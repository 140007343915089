// react
import React from 'react';
// third-party
import {Helmet} from 'react-helmet-async';
// application
// data stubs
import theme from '../../data/theme';

function SitePageIprpp() {
    return (
        <div className="block about-us">
            <Helmet>
                <title>{`Transaction Services Agreement — ${theme.name}`}</title>
            </Helmet>
            <div className="about-us__image" style={{backgroundImage: 'url("")'}}/>
            <div>
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                        <div className="about-us__body">
                            <h1 className="about-us__title">Transaction Services Agreement</h1>
                            <h5 className="d-flex justify-content-center">Effective as of January 6, 2020</h5>
                            <p>
                                PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY! PLEASE PAY
                                ATTENTION TO PROVISIONS THAT EXCLUDE OR LIMIT LIABILITY AND
                                TERMS OF DISPUTE RESOLUTION, GOVERNING LAW AND JURISDICTION,
                                WHICH MAY APPEAR IN CAPITAL LETTERS.
                            </p>
                            <p>
                                Thank you for trading on Ezytrade.africa’s web-based transaction platforms. This
                                Transaction Services Agreement (this “Agreement”) describes the terms and conditions
                                on which you conclude online transactions for products and services by using the
                                websites, mobile sites, mobile applications and other online transaction portals owned,
                                operated, branded or made available by Ezytrade.africa (defined below) from time to time
                                which relate to (a) the Ezytrade.africa e-commerce platform including but not limited to
                                the web and mobile-optimized versions of the websites identified by the uniform resource
                                locator “www.ezytrade.africa” and the mobile applications of the Ezytrade.africa ecommerce platform (the “Ezytrade Africa Sites”); but not limited to the web and mobileoptimized versions of the websites identified by the uniform resource locators
                                “www.ezytrade.africa.” and “www.ezytradeafrica.com” (collectively the “Ezytrade
                                Africa Sites”), (with Ezytrade Africa Sites collectively the “Sites”). This
                                Agreement contains various limitations on Ezytrade.africa’s transaction services
                                offered through the Sites as well as gives various powers and authority to
                                Ezytrade.africa with respect to online transactions effected using
                                Ezytrade.africa’s transaction services offered through the Sites. This includes
                                without limitation the power and authority to reject or cancel an online
                                transaction, to refund the funds to a buyer or to release the funds to a seller. You
                                should read this Agreement and, to the extent as applicable, the relevant
                                Transactional Terms (defined in clause 1.2 below), and other rules and policies
                                of Ezytrade.africa (including those specified in clause 1.3).
                            </p>
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left" type="button"
                                                    data-toggle="collapse" data-target="#collapseOne"
                                                    aria-expanded="true" aria-controls="collapseOne">
                                                1. Application and Acceptance of Terms
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                1.1 Contracting Party. This Agreement is entered into between you (also referred to as
                                                “Member” hereinafter) and the Ezytrade.africa contracting entity determined in
                                                accordance with this clause 1.1 (“Ezytrade.africa” or “we”) for use of Ezytrade.africa’s
                                                certain transaction services offered through the Sites as described hereunder
                                                You are contracting with Ezytrade Africa Limited (incorporated in Tanzania with
                                                Company Reg. No. 139807197).
                                                Ezytrade Africa Ltd provides technical support in connection with the transaction
                                                services utilizing its strengths in network technologies, etc
                                            </p>
                                            <p>
                                                1.2 Transactional Terms. Ezytrade.africa provides an online transaction platform and
                                                ancillary services on the Sites (such platform and services, the “Transaction Services”)
                                                which allow registered members of the Sites to conclude online transactions for products
                                                or services within the Sites subject to the terms of this Agreement. Ezytrade.africa may
                                                publish transaction rules, dispute rules and other rules and policies for any type of online
                                                transactions and any subsequent amendments or modifications (“Transactional Terms”)
                                                as may be made from time to time. Such Transactional Terms are expressly incorporated
                                                into this Agreement by reference and you agree to be bound by such rules and policies.
                                            </p>
                                            <p>
                                                1.3 General Terms. You agree that you shall also comply with relevant rules and policies
                                                published on the Sites which are also incorporated into this Agreement by reference
                                                (“General Terms”). The General Terms include without limitation:
                                                .Terms of Use
                                                .Free Membership Agreement
                                                .Product Listing Policy
                                                .Privacy Policy
                                                .(for users who access or use the Sites relating to the Ezytrade.africa e-commerce
                                                platform) (collectively, the “Privacy Policy”); and
                                                .Intellectual Property Rights Protection Policy
                                            </p>
                                              <p>
                                                  1.4 Binding Agreement. This Agreement, including the Transactional Terms and the
                                                  General Terms, and, to the extent as applicable form a legally binding agreement between
                                                  you and Ezytrade.africa in relation to your use of the Transaction Services. By accessing
                                                  and using the Transaction Services, you agree to accept and be bound by this Agreement.
                                                  Please do not use the Transaction Services if you do not accept all of the terms of this
                                                  Agreement.
                                              </p>
                                            <p>
                                                1.5 Amendments. Ezytrade.africa may amend any terms of this Agreement including the
                                                Transactional Terms, the General Terms and other related agreements at any time by
                                                posting the relevant amended and restated version on the Sites. The amended terms shall
                                                be effective immediately upon posting. We will endeavor to give you advance notice of
                                                material changes to this Agreement. By continuing to use the Transaction Services, you
                                                agree that the amended terms will apply to you. This Agreement may not otherwise be
                                                amended except in writing by an authorized officer of Ezytrade.africa.
                                            </p>
                                            <p>
                                                1.6 Language Version. If Ezytrade.africa has posted or provided a translation of the
                                                English version of any terms of this Agreement, including the Transactional Terms, the
                                                General Terms and other related agreements, you agree that the translation is provided for
                                                convenience only and that the English language version will govern your use of the
                                                Transaction Services.
                                            </p>
                                            <p>
                                                1.7 Ezytrade.africa Affiliates. Some of the Transaction Services may be supported by our
                                                affiliates.
                                            </p>
                                            <p>
                                                1.8 Additional Terms. In some cases, you may be required to additionally enter into a
                                                separate agreement with Ezytrade.africa or our affiliates in connection with the
                                                Transaction Services (“Additional Terms”). If there is any contradiction between the
                                                provisions of this Agreement and the provisions of the Additional Terms, the Additional
                                                Terms shall govern the relevant types of Transaction Services or Online Transactions
                                                (defined below in clause 2.1), as appropriate.
                                            </p>
                                            <p>
                                                1.9 Membership Services. This Agreement does not affect your agreement with us or any
                                                of our affiliates concerning your subscription and use of the membership services of the
                                                Sites, unless otherwise stipulated in this Agreement or the relevant service agreement.
                                            </p>


                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseTwo"
                                                    aria-expanded="false" aria-controls="collapseTwo">
                                                2. Transaction Services
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                2.1 Transaction Services: Ezytrade.africa’s Transaction Services are designed to facilitate
                                                registered members of the Sites to place, accept, conclude, manage and fulfill orders for
                                                the provision of products and services online within the Sites (“Online Transactions”),
                                                which may include certain services which will be either supported by Ezytrade.africa or
                                                its affiliates. Ezytrade.africa reserves the right to change, upgrade, modify, limit or
                                                suspend the Transaction Services or any of its related functionalities or applications at
                                                any time temporarily or permanently without prior notice without any liability for any
                                                losses or damages arising out of or in connection with such suspension or termination.
                                                Ezytrade.africa further reserves the right but shall not be obliged to introduce new
                                                features, functionalities or applications to the Transaction Services or to future versions
                                                of the Transaction Services. All new features, functionalities, applications, modifications,
                                                upgrades and alterations shall be governed by this Agreement, unless otherwise expressly
                                                stated by Ezytrade.africa.
                                            </p>
                                             <p>
                                                2.2 Members Only. Ezytrade.africa’s Transaction Services are only available to
                                                registered members of the Sites. If your subscription to the paid or free membership of
                                                the Sites expires or is terminated early for any reason, you are not eligible to use the
                                                Transaction Services. In the event that you have a valid Online Transaction under this
                                                Agreement at the point at which your paid or free membership registration on the Sites is
                                                terminated, Ezytrade.africa shall have the full discretion and authority to refund to Buyer
                                                and/or release to Seller (both Buyer and Seller as defined below) all or part of the funds
                                                under the Online Transactions as Ezytrade.africa considers appropriate in its sole
                                                discretion. If you are a Seller, you are required to provide a valid bank account subject
                                                to verification and confirmation by Ezytrade.africa and our affiliates.
                                             </p>
                                            <p>
                                                2.3 Types of Transactions. Ezytrade.africa’s Transaction Services are available to those
                                                types of Online Transactions permitted by Ezytrade.africa only. For any type of Online
                                                Transactions, Ezytrade.africa may limit any or all of the Transaction Services to a
                                                specified group of members in accordance with the relevant Transactional Terms. The
                                                types of Online Transactions and other benefits, features and functions of the Transaction
                                                Services available to a registered member may vary for different countries and regions.
                                                No warranty or representation is given that the same type and extent of transactions,
                                                benefits, features and functions will be available to all members.
                                            </p>
                                            <p>
                                                2.4 Lawful Items. The products or services of an Online Transaction using the
                                                Transaction Services must be lawful items and must not be otherwise prohibited or
                                                restricted by this clause 2.4. You shall not use the Transaction Services in connection
                                                with any Online Transaction that:
                                            </p>
                                            <p>

                                                (a) may infringe Ezytrade.africa’s or any third party’s legitimate or proprietary rights
                                                including but not limited to copyright, trademark right, patent or other intellectual
                                                property rights;
                                            </p>
                                            <p>
                                                (b) may be in breach of the Product Listing Policy or the Intellectual Property Rights
                                                Protection Policy; or
                                            </p>
                                            <p>
                                                (c) may be in breach of other terms of this Agreement including, but not limited to, the
                                                Transactional Terms, the General Terms and the Supplemental Services Agreement.
                                                Ezytrade.africa shall have the right to refuse or cancel any Online Transaction which it
                                                determines in its sole discretion to be in breach of this clause 2.4.
                                            </p>
                                            <p>
                                                2.5 Refuse or Cancel Transactions. Apart from clause 2.4, Ezytrade.africa reserves the
                                                right, at our sole discretion, to refuse or cancel any Online Transaction for any reason
                                                without any liability for any losses or damages arising out of or in connection with such
                                                refusal or cancellation. Some situations that may result in an Online Transaction being
                                                rejected or canceled include where problems are identified by our credit and fraud control
                                                department, where Ezytrade.africa has reason to believe the Online Transaction is
                                                unauthorized, violates any law, rule or regulations or may otherwise subject
                                                Ezytrade.africa or any of our affiliates to liability. Ezytrade.africa may also require
                                                additional verifications or information for any Online Transaction, and you agree to
                                                provide such verifications and information to Ezytrade.africa upon request.
                                            </p>
                                            <p>
                                                2.6 Ezytrade.africa Supplemental Services.
                                            </p>
                                            <p>
                                                (a)(i) Ezytrade.africa may, through affiliates, provide certain services for certain Online
                                                Transactions (“Payment Services”). Affiliate Services are provided by third party
                                                payment gateways (collectively, “Payment Services”) to receive payment of funds in
                                                support of Sites for the Online Transactions. The Payment Services are provided in
                                                accordance with the terms and conditions set out in the Payment Services.
                                            </p>
                                            <p>
                                                (a)(ii) Ezytrade.africa may, through its affiliates provide certain services for certain
                                                Online Transactions (“Supplemental Services”). Ezytrade.africa Supplemental Services
                                                are provided by Ezytrade.africa to receive payment of funds in support of Sites for the
                                                Online Transactions. The Ezytrade.africa Supplemental Services are provided in
                                                accordance with the terms and conditions set out in the Ezytrade.africa Supplemental
                                                Services Agreement.
                                            </p>
                                            <p>
                                                (b) Buyer Protection Plan. Ezytrade.africa may also provide buyer protection plan for
                                                certain Online Transactions. In case of Seller who has been offered to subscribe to the
                                                buyer protection plan, upon entering into a separate agreement with Ezytrade.africa (as
                                                the case may be), Seller may be required to provide deposits using the methods as
                                                designated by Ezytrade.africa on the Sites to secure Seller’s due performance of
                                                obligations under the relevant buyer protection plan. Seller agrees to permit and hereby
                                                authorizes Ezytrade.africa to deduct, withhold and dispose any deposits provided in
                                                accordance with the terms under the relevant buyer protection plan. Buyer acknowledges
                                                and agrees that the protection afforded to you under a buyer protection plan applies to
                                                those Online Transactions where the Seller subscribed to such plan and the purchase falls
                                                within the buyer protection plan’s scope and (i) Payment Service under clause 3.4 of the
                                                Payment Service Agreement and (ii) Ezytrade.com Supplemental Services under clause
                                                3.4 of the Ezytrade.africa Supplemental Service Agreement will not be applicable to you
                                                for such Online Transactions if Seller subscribed to buyer protection plan and such plan
                                                already covers your purchase. Buyer acknowledges and agrees Ezytrade.africa and Seller
                                                may add guarantees for the Seller on such Online Transactions within the scope of buyer
                                                protection plan without further notifying Buyer. The guarantee service will be performed,
                                                revised, suspended and/or terminated according to the agreement reached between the
                                                guarantee service provider and the Seller.
                                            </p>
                                            <p>
                                                2.7 Transactional Terms. For any type of Online Transactions, Ezytrade.africa may
                                                impose additional restrictions, limitations and prohibitions as well as penalties for any
                                                violations in the relevant Transactional Terms.
                                            </p>
                                            <p>
                                                2.8 Disputes between Buyers and Sellers. You agree that any Dispute arising between
                                                you and the other party to an Online Transaction will be handled in accordance with
                                                clause 10, and that Ezytrade.africa shall have the full right and power to make a
                                                determination for such Dispute or to delegate or sub-contract such power to another
                                                party. Upon receipt of a Dispute, Ezytrade.africa shall have the right to request either or
                                                both of Buyer and Seller to provide supporting documents. You agree that
                                                Ezytrade.africa shall have the absolute discretion to reject or receive any supporting
                                                document. You also acknowledge that Ezytrade.africa is not a judicial or arbitration
                                                institution and will make the determinations only as an ordinary non-professional
                                                person. Further, we do not warrant that the supporting documents that the parties to the
                                                dispute submit will be true, complete or accurate. You agree not to hold Ezytrade.africa
                                                and our affiliates liable for any material which is untrue or misleading. You agree to
                                                release and indemnify Ezytrade.africa (and our agents, affiliates, directors, officers
                                                and employees) from all claims, demands, actions, proceedings, costs, expenses and
                                                damages (including without limitation any actual, special, incidental or
                                                consequential damages) arising out of or in connection with such dispute.
                                            </p>
                                            <p>
                                                2.9 Powers of Ezytrade.africa. you expressly acknowledge and agree that Ezytrade.africa
                                                shall have the full power, authority and discretion to reject or cancel an Online
                                                Transaction without any liability for any losses or damages arising out of or in connection
                                                with such refusal or cancellation and to make a determination on any dispute between
                                                buyer and seller including the remittance of the funds under an online transaction that are
                                                held by Payment Services as instructed by Ezytrade.africa in accordance with this
                                                Agreement, the Payment Services Agreement, and the relevant Transactional Terms.
                                                You also acknowledge that this Agreement, the Payment Services Agreement , the
                                                Ezytrade.africa Supplemental Services Agreement and the relevant Transactional Terms
                                                may not cover all issues that may arise in connection with an Online Transaction. You
                                                agree and accept that Ezytrade.africa shall have the right to modify or supplement the
                                                Transactional Terms at any time. You further agree and accept that Ezytrade.africa shall
                                                have the right to make determinations wherever Ezytrade.africa considers appropriate
                                                having regard to the evidence received by us, commonly accepted principles and
                                                practices in the relevant industries and interests of both Buyer and Seller regardless
                                                whether the issue in question has been expressly addressed in the Transactional Terms or
                                                this Agreement.
                                            </p>
                                            <p>
                                                2.10 Ezytrade.africa’s Records. In case of any dispute in connection with any Online
                                                Transaction, the records of Ezytrade.africa shall take precedence and be conclusive.
                                            </p>
                                            <p>
                                                2.11 Transactions involving a third party finance provider.
                                                You agree that:
                                            </p>
                                            <p>
                                                (a) Ezytrade.africa does not guarantee any third party finance provider (the “Lender”)
                                                will provide financing to Buyer (defined in clause 3 below) in connection with the Online
                                                Transaction and shall not be held liable to either Buyer or Seller (defined in clause 3
                                                below) in connection with any third party financing in connection with the Online
                                                Transaction;
                                            </p>
                                            <p>
                                                (b) each of Buyer and Seller hereby authorizes Ezytrade.africa to disclose information
                                                related to Buyer, Seller and/or the Online Transaction to the Lender in connection with
                                                the Lender’s provision of financing for the Online Transaction; and
                                            </p>
                                            <p>
                                                (c) any dispute with the Lender in connection with the Online Transaction shall be
                                                resolved between the Lender and the Buyer. Notwithstanding the power given to
                                                Ezytrade.africa under this Agreement, it is not Ezytrade.africa’s obligation to resolve or
                                                assist in any way in the resolution of such dispute.
                                            </p>
                                            <p>

                                            </p>
                                            <p>

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseThree"
                                                    aria-expanded="false" aria-controls="collapseThree">

                                                3.Transactions between Sellers and Buyers
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                3.1 Seller and Buyer. For the purpose of this Agreement, the term “Seller” means the
                                                registered member who supplies the product(s) or service(s) under an Online Transaction,
                                                and the term “Buyer” means the registered member who purchases or acquires the
                                                product(s) or service(s) under an Online Transaction.
                                            </p>
                                            <p>
                                                3.2 Online Order. Seller and Buyer shall enter into an Online Transaction for products or
                                                services by completing, submitting and accepting an order online using the applicable
                                                standard order form on the Sites. Seller and Buyer yourselves shall be responsible for
                                                ensuring that you have agreed to, and specified, all the relevant terms and conditions for
                                                the products or services in the relevant online order form, including but not limited to the
                                                pricing, quantity, specifications, quality standards, inspection, shipping etc., save that:
                                            </p>
                                                a) for all transactions concluded on www.ezytrade.africa or www.ezytradeafrica.com
                                                website, mobile site, applications or mobile-optimized applications, the Buyer shall be
                                                the importer on record for customs and taxation purposes, unless otherwise agreed upon
                                                between the Buyer and Seller,
                                             <p>b) for all transactions concluded on www.ezytrade.africa or www.ezytradeafrica.com or
                                                 the mobile applications relating thereto, all risks of damage and loss of products
                                                 purchased by a Buyer under a transaction (will be borne solely by the Buyer upon
                                                 physical delivery to the specified address, and
                                             </p>

                                                <p>
                                                c) without prejudice to b) above, title to the products purchased by Buyers from the
                                                Russian Federation (or the mobile applications relating thereto), will transfer from a
                                                Seller to a Buyer upon the Buyer’s confirmation as provided upon occurrence of the
                                                event in clause 3.3(a) of the Ezytrade.africa Supplemental Services Agreement or clause
                                                3.3(a) of the Payment Services Agreement (as the case may be), or expiration of the
                                                prescribed time limit as provided in clause 3.3(b) of the Ezytrade.africa Supplemental
                                                Services Agreement or clause 3.3(b) of the Payment Services Agreement (as the case
                                                may be).
                                               </p>
                                               <p>  Ezytrade.africa may refuse to process or cancel, without liability for any losses or
                                                   damages arising out of or in connection with such refusal or cancellation, any Online
                                                   Transaction which in Ezytrade.africa’s reasonable opinion, has insufficient information to
                                                   constitute a binding contract.
                                               </p>

                                             <p> 3.3 Online Transactions Subject to This Agreement. An Online Transaction is
                                                 additionally subject to the applicable terms and conditions set forth in this Agreement and
                                                 the Transactional Terms. Seller and Buyer shall complete the Online Transaction
                                                 according to the terms of the online order, the relevant Transactional Terms and this
                                                 Agreement. Seller or Buyer may only cancel any Online Transaction in accordance with
                                                 the relevant Transactional Terms.
                                             </p>

                                            <p>
                                                3.4 Transaction between Seller and Buyer Only. Each Online Transaction is made by and
                                                between a Seller and a Buyer only. Despite that Ezytrade.africa makes available the
                                                Transaction Services and, if applicable, may conduct formality review(s) of an Online
                                                Transaction, Ezytrade.africa shall not be considered as a party to the Online Transaction.
                                                Ezytrade.africa does not represent the Seller nor the Buyer in any Online Transaction.
                                                Ezytrade.africa will not be responsible for the quality, safety, lawfulness or availability of
                                                the products or services offered under any Online Transaction or the ability of either
                                                Seller or Buyer to complete any Online Transaction. You agree that you will not hold
                                                Ezytrade.africa and our affiliates and agents liable for any losses, damages, claims,
                                                liabilities, costs or expenses arising from any Online Transactions, including any breach,
                                                partial performance or non-performance of the Online Transaction by the other party to
                                                the transaction.
                                            </p>
                                            <p>
                                                3.5 Payment of Contract Price. For any Online Transaction, Buyer agrees to pay the full
                                                transaction price listed for Online Transaction to the Seller through the Ezytrade.africa
                                                website unless another option is made available directly by Ezytrade.africa on the Sites.
                                                When using Ezytrade.africa to submit payment for an Ezytrade.africa Online Transaction,
                                                payments are (in the case of Online Transaction through Payment Services) processed
                                                through accounts owned by Ezytrade.africa or one of its affiliates and/or a registered third
                                                party service provider acting on Ezytrade.africa’s behalf, and (in the case of Online
                                            </p>
                                              <p>
                                                Transaction through Ezytrade.africa) processed through accounts owned by
                                                Ezytrade.africa or one of its affiliates and/or a registered third party service provider
                                                acting on Ezytrade.africa’s behalf. The funds are received for the Seller in accordance
                                                with the Transaction Services Agreement. Seller agrees that the Buyer’s full payment of
                                                the transaction price listed for the Online Transaction to Ezytrade.africa (as the case may
                                                be) constitutes final payment to Seller and Buyer’s payment obligation for the Online
                                            </p>
                                               <p>  Transaction is fully satisfied upon receipt of funds by Ezytrade.africa’s account.
                                                   In the case the Online Transaction adopts Payment Services, the payment in connection
                                                   with the Online Transactions concluded will be facilitated by Ezytrade.africa. Payment
                                                   Services shall not dispose of any such fund except in accordance with Ezytrade.africa’s
                                                   terms as agreed by Seller and Buyer which are set out in the terms and conditions of this
                                                   Agreement and the Payment Services Agreement. Seller has requested and agreed that
                                                   the settlement of funds to Seller be delayed as provided in the Payment Services
                                                   Agreement.
                                               </p>
                                                <p>
                                                By using the Payment Services, you acknowledge and agree that Ezytrade.africa is not a
                                                bank and the Ezytrade.africa Services should in no way be construed as the provision of
                                                banking services. Ezytrade.africa is not acting as a trustee, fiduciary or escrow with
                                                respect to User’s funds and it does not have control of, nor liability for, the products or
                                                services that are paid for with the Payment Services. Ezytrade.africa does not guarantee
                                                the identity of any User or ensure that a Buyer or a Seller will complete a transaction on
                                                Sites. You further agree that neither Buyer nor Seller will receive interest or other profits
                                                in relation to the Payment Services.
                                                </p>
                                                <p>
                                                In the case the Online Transaction adopts Ezytrade.africa Supplemental Services, the
                                                payment in connection with the Online Transactions concluded will be facilitated by
                                                Ezytrade.africa. Ezytrade.africa shall not dispose of any such fund except in accordance
                                                with Ezytrade.africa’s terms as agreed by Seller and Buyer which are set out in the terms
                                                and conditions of this Agreement and the Ezytrade.africa Supplemental Services
                                                Agreement. Seller has requested and agreed that the settlement of funds to Seller be
                                                delayed as provided in the Ezytrade.africa Supplemental Services Agreement.
                                            </p>
                                            <p>
                                                By using the Ezytrade.africa Supplemental Services, you acknowledge and agree that
                                                Ezytrade.africa is not a bank and the Ezytrade.africa Supplemental Services should in no
                                                way be construed as the provision of banking services. Ezytrade.africa is not acting as a
                                                trustee, fiduciary or escrow with respect to User’s funds and it does not have control of,
                                                nor liability for, the products or services that are paid for with the Ezytrade.africa
                                                Supplemental Services. Ezytrade.africa does not guarantee the identity of any User or
                                                ensure that a Buyer or a Seller will complete a transaction on Sites. You further agree that
                                                neither Buyer nor Seller will receive interest or other profits in relation to the
                                                Ezytrade.africa Supplemental Services.
                                            </p>
                                            <p>
                                                3.6 Payment Methods. Please note that the payment methods available on the Sites may
                                                be provided by Ezytrade.africa’s partners. If there is any chargeback or reversal of any
                                                payment requested by a payment service partner, Seller agrees that Ezytrade.africa has
                                                the right to refund the money so requested by the payment service partner without
                                                liability to Seller. Ezytrade.africa will use reasonable efforts to assist you in participating
                                                in the dispute resolution process of the relevant payment service partners. However, if
                                                the participation in the dispute resolution process is subject to additional fees, this will be
                                                at your own cost only.
                                            </p>
                                            <p>
                                                3.7 Third Party Vendors. You may engage one or more third party vendors for the
                                                purpose of completing and fulfilling an Online Transaction such as the
                                                warehousing and logistic service companies, shipping agents, inspection
                                                agents, insurance companies, etc. Some of such third party vendors may
                                                be partners of Ezytrade.africa and thus designated by Ezytrade.africa to
                                                you. Among such designated partners, you may be required to agree and
                                                accept the terms and conditions of their services online within the Sites.
                                                Notwithstanding the foregoing, for all third party vendors, you
                                                acknowledge and agree that such third party vendors are engaged at your
                                                own discretion and cost and that you will not hold Ezytrade.africa and our
                                                affiliates and agents liable for any losses, damages, claims, liabilities, costs
                                                or expenses arising from the services of such third party vendors. You shall
                                                ensure that the third parties comply with Terms of Use and Privacy Policy.
                                            </p>
                                            <p>
                                                3.8 Your Agent. If you are required to conclude and complete an Online Transaction
                                                through an agent e.g. a Seller may be required to engage a qualified import and export
                                                agent as its export agent, such agent is merely an agent of you. If any obligations are
                                                required to be performed by the agent, you shall remain solely liable to the other party of
                                                the Online Transaction for the non-performance or default by your agent.

                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseFour"
                                                    aria-expanded="false" aria-controls="collapseFour">
                                                4. Ezytrade.africa Service Fees
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFour" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                4.1 Service Fees. Ezytrade.africa charges service fees for Online Transactions according
                                                to the fee schedules announced by Ezytrade.africa on the Sites. Ezytrade.africa reserves
                                                the right to charge any service fees for other types of Online Transactions upon prior
                                                notification published on the Sites. In a case where the Online Transaction adopts
                                                Payment Services, you hereby authorize Ezytrade.africa to instruct Payment Services or,
                                                with respect to payments originating in a Relevant Jurisdiction to deduct any service fees
                                                that are due and payable to Ezytrade.africa under an Online Transaction and to pay the
                                                same to Ezytrade.africa when Payment Services releases any amount held by it under the
                                                Online Transaction. Neither Ezytrade.africa nor Payment Services has any control over,
                                                and are not responsible or liable for, the products or services that are paid for with our
                                                service. We cannot ensure that a buyer or a seller you are dealing with will actually
                                                complete the transaction.
                                            </p>
                                          <p> 4.2 Third Party Fees Not Included. The service fees charged by Ezytrade.africa do not
                                              include any fees (including all taxes) for any service or product that you may acquire or
                                              purchase in connection with the Online Transaction. It shall be your responsibility to
                                              settle the fees with such third party vendors.</p>

                                             <p>
                                                4.3 Taxes, Financial Charges Not Included. All fees charged by Ezytrade.africa are
                                                exclusive of any taxes (such as VAT), duties or other governmental levies or any
                                                financial charges. You agree to pay and be responsible for any such taxes, duties, levies
                                                or charges for the use of the Transaction Services in addition to our service fees. In the
                                                event Ezytrade.africa is required by any applicable law to collect or withhold any taxes or
                                                duties, you agree to pay such taxes or duties to Ezytrade.africa. You will also be liable
                                                for any financial charges for remission of funds to you, and Ezytrade.africa shall have the
                                                right to pay such charges from such funds. Ezytrade.africa and Payment Services shall
                                                have the right to deduct any financial charges incurred as a result of providing the
                                                Transaction Services and the party receiving the funds will bear the costs of such bank
                                                charges.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseFive"
                                                    aria-expanded="false" aria-controls="collapseFive">
                                                5.Member’s Responsibilities
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFive" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                5.1 Provision of Information and Assistance. You agree to give all notices, provide all
                                                necessary information, materials and approval, and render all reasonable assistance and
                                                cooperation necessary for the completion of the Online Transactions and
                                                Ezytrade.africa’s provision of the Transaction Services. If your failure to do so results in
                                                delay in the provision of any Transaction Service, cancellation of any Online Transaction,
                                                or disposal of any funds, Ezytrade.africa shall not be liable for any losses or damages
                                                arising from such default, delay, cancellation or disposal.
                                            </p>
                                            <p>
                                                5.2 Representations and Warranties. You represent and warrant that:
                                            </p>
                                            <p>
                                                (a) you will use the Transaction Services in good faith and in compliance with all
                                                applicable laws and regulations including laws related to anti-money laundering and
                                                counter-terrorism financing;
                                            </p>
                                            <p>
                                                (b) all information and material you provide in connection with the use of the
                                                Transaction Services is true, lawful and accurate, and is not false, misleading or
                                                deceptive;
                                            </p>
                                            <p>
                                                (c) you will not use the Transaction Services to defraud Ezytrade.africa, our affiliates, or
                                                other members or users of the Sites or engage in other unlawful activities (including
                                                without limitation dealing in products prohibited by law); and
                                            </p>
                                            <p>
                                                (d) in case that you are a Seller of products, you have the legitimate right and
                                                authorization to sell, distribute or export the products using the Transaction Services and
                                                such products do not infringe any third party’s rights; and
                                            </p>
                                            <p>
                                                (e) in case that you are a Seller of products, you have good title to the products ordered
                                                under the Online Transaction, and the products meet the agreed descriptions and
                                                requirements; and
                                            </p>
                                            <p>
                                                (f) in case that you are a Seller of services, you will provide the services ordered with
                                                reasonable care and skills.
                                            </p>
                                            <p>
                                                5.3 Breaches. If you are, in Ezytrade.africa’s opinion, not acting in good faith, abusing
                                                the Transaction Services, or otherwise acting in breach of this Agreement, Ezytrade.africa
                                                shall have the right to cancel the relevant Online Transaction(s) at any time without any
                                                liability for any losses or damages arising out of or in connection with such cancellation.
                                                Ezytrade.africa also reserves the right to impose any penalty, or to temporarily or
                                                permanently suspend or terminate your use of the Transaction Services, temporarily or
                                                permanently suspend or terminate or procure the suspension or termination of your paid
                                                or free membership on the Sites. Ezytrade.africa also reserves the right to (i) temporarily
                                                suspend the transaction functionalities of your account with Ezytrade.africa for a
                                                prescribed period determined by Ezytrade.africa, or permanently terminate the use of
                                                your Ezytrade.africa account and/or (ii) authorize Payment Services to temporarily
                                                suspend the transaction functionalities of your Ezytrade.africa account for a prescribed
                                                period determined by Ezytrade.africa, or permanently terminate the use of your
                                                Ezytrade.africa account without any liability for any losses or damages arising out of or
                                                in connection with such suspension or termination. Ezytrade.africa may also publish the
                                                findings, penalties and other records regarding the breaches on the Sites.
                                            </p>
                                            <p>
                                                5.4 Obligations to Pay Taxes. You shall be solely responsible for payment of any taxes
                                                (such as VAT), duties or other governmental levies or any charges or fees that may be
                                                imposed on any products or services purchased or supplied under or in connection with
                                                the Online Transactions.
                                            </p>
                                            <p>
                                                5.5 Feedback System. You shall not take any action which may undermine the integrity
                                                of Ezytrade.africa’s feedback system, such as providing positive feedback on oneself on
                                                the Sites using secondary Member IDs or through third parties or by providing
                                                unsubstantiated negative feedback on another member on the Sites.
                                           </p>
                                            <p>
                                                5.6 Indemnification by Member. You agree to indemnify Ezytrade.africa and our
                                                affiliates, employees, directors, officers, agents and representatives and to hold them
                                                harmless, from any and all losses, damages, actions, claims and liabilities (including legal
                                                costs on a full indemnity basis) which may arise, directly or indirectly, from your use of
                                                the Transaction Services or from your breach of this Agreement. Ezytrade.africa reserves
                                                the right, at our own discretion, to assume the exclusive defense and control of any matter
                                                otherwise subject to indemnification by you, in which event you shall cooperate with
                                                Ezytrade.africa in asserting any available defenses.
                                            </p>
                                            <p>
                                                5.7 Collection and Use of Information for Transaction Services. If you have applied for
                                                and/or used the Transaction Services of Ezytrade.africa, you acknowledge and agree that
                                                Ezytrade.africa shall have the right to use the information collected about you and your
                                                Online Transactions, including but not limited to your credit information, business
                                                information, personal information (such as applicant name and home address) and
                                                financial information (the “Collected Information”) to facilitate the administration,
                                                processing, and operation of your use of the services. In connection with your use of the
                                                Transaction Services, Ezytrade.africa may use the Collected Information in the manner
                                                set out in the Privacy Policy and/or personal information collection statement relevant to
                                                the Transaction Services that you have agreed to prior to or during your application for
                                                and use of the Transaction Services.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseSix"
                                                    aria-expanded="false" aria-controls="collapseSix">
                                                6. Confidentiality
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseSix" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                6.1 Confidential Obligations. You shall keep confidential all confidential information
                                                provided by other members of the Sites or by Ezytrade.africa or any of its affiliates in
                                                connection with any Online Transaction or the Transaction Services.
                                            </p>
                                            <p>
                                                6.2 Confidential Information. All information and material provided by another member
                                                of the Sites or by Ezytrade.africa or any of its affiliates will be deemed to be confidential
                                                information unless such information or material is already in the public domain or has
                                                subsequently becomes public other than due to your breach of the confidential
                                                obligations.

                                            </p>
                                            <p>
                                                6.3 Data Protection and Privacy. You must read the privacy policies of Sites which
                                                govern the protection and use of personal information of Buyers and Sellers in the
                                                possession of Ezytrade.africa and its affiliates and explains how your personal
                                                information may be processed in connection with products and services offered by
                                                Ezytrade.africa.
                                            </p>
                                            <p>
                                                6.4 Data Protection and Privacy Role of Sellers. Sellers acknowledge that they have their
                                                own data protection and privacy compliance responsibilities with respect to personal
                                                information of Buyers or other individuals that the Seller processes. Sellers represent and
                                                warrant, and undertake to Ezytrade.africa to ensure, that in relation to such personal
                                                information they are and will be fully compliant with all applicable data protection and
                                                privacy laws, including without limitation maintaining their own records of such personal
                                                information independently of the platform (to the extent required under applicable laws),
                                                complying with direct marketing laws, and responding to requests by individuals to
                                                exercise their rights under such laws. Neither Ezytrade.africa nor any of its affiliates is
                                                responsible or liable for a Seller's fulfilment of their obligations under applicable data
                                                protection and privacy laws. For the avoidance of doubt, in relation to such personal
                                                information the liability and obligations of Ezytrade.africa and its affiliates under
                                                applicable data protection and privacy laws are entirely independent of and separate from
                                                the liability and obligations of Sellers under such laws. Ezytrade.africa shall have no
                                                liability to any Sellers for the compliance, by Ezytrade.africa or its affiliates, with its or
                                                their own obligations under applicable data protection or privacy laws, including without
                                                limitation in relation to the exercise by Buyers or other individuals of their rights
                                                regarding their personal information processed in connection with any services and
                                                products provided by Ezytrade.africa.
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseSeven"
                                                    aria-expanded="false" aria-controls="collapseSeven">
                                                7. Disclaimer and Limitation of Liability
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseSeven" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                7.1 No Warranty. You expressly agrees that your use of the Transaction Services is at
                                                your sole risk. TO THE FULL EXTENT PERMITTED BY LAW THE
                                                TRANSACTION SERVICES ARE PROVIDED ON THE "AS IS", "AS AVAILABLE"
                                                AND “WITH ALL FAULTS” BASES, AND EZYTRADE.AFRICA MAKES NO
                                                REPRESENTATION OR WARRANTY THAT THE TRANSACTION SERVICES
                                                WILL BE UNINTERRUPTED, TIMELY OR ERROR FREE. EZYTRADE.AFRICA
                                                MAKES NO REPRESENTATION OR WARRANTY WITH RESPECT TO THE
                                                ACCURACY, TRUTHFULNESS AND COMPLETENESS OF THE INFORMATION
                                                PROVIDED BY ANY MEMBER OF THE EZYTRADE.AFRICA SITES. YOU WILL
                                                BE SOLELY RESPONSIBLE FOR ALL CONSEQUENCES RESULTING FROM
                                                YOUR OWN JUDGEMENT AND DECISION TO USE OR OTHERWISE RELY ON
                                                SUCH INFORMATION. EZYTRADE.AFRICA AND OUR AFFILIATES FURTHER
                                                EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR
                                                IMPLIED, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
                                                CONDITION, QUALITY, DURABILITY, PERFORMANCE, ACCURACY,
                                                RELIABILITY, MERCHANTABILITY OR FITNESS FOR A PARTICULAR
                                                PURPOSE OR NON-INFRINGEMENT EVEN IF ADVISED OF THE POSSIBILITY
                                                OF SUCH DAMAGES OR IF SUCH POSSIBILITY WAS REASONABLY
                                                FORESEEABLE. ALL SUCH WARRANTIES, REPRESENTATIONS,
                                                CONDITIONS, UNDERTAKINGS AND TERMS ARE HEREBY DISCLAIMED AND
                                                EXCLUDED.
                                            </p>
                                            <p>
                                                7.2  Exclusion and Limitation of Liabilities. TO THE FULL EXTENT PERMITTED BY
                                                LAW, EZYTRADE.AFRICA SHALL NOT BE LIABLE FOR ANY INDIRECT,
                                                CONSEQUENTIAL, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES,
                                                INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS OR
                                                REVENUES, BUSINESS INTERRUPTION, LOSS OF BUSINESS OPPORTUNITIES
                                                OR LOSS OF DATA, WHETHER IN CONTRACT, NEGLIGENCE, TORT, EQUITY
                                                OR OTHERWISE, ARISING FROM THE USE OF OR INABILITY TO USE THE
                                                TRANSACTION SERVICES. THE AGGREGATE LIABILITY OF
                                                EZYTRADE.AFRICA AND OUR AFFILIATES AND AGENTS ARISING FROM
                                                THE TRANSACTION SERVICES IN CONNECTION WITH ANY ONLINE
                                                TRANSACTION SHALL NOT EXCEED THE HIGHER OF THE SERVICE FEES
                                                CHARGED BY EZYTRADE.AFRICA OR US$1,000.
                                            </p>
                                            <p>
                                                7.3 SOME OR ALL OF THESE LIMITATIONS OR EXCLUSIONS MAY NOT
                                                APPLY TO YOU IF YOUR STATE, PROVINCE OR COUNTRY DOES NOT ALLOW
                                                THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR
                                                CONSEQUENTIAL DAMAGES, SO THE FOREGOING EXCLUSIONS OR
                                                LIMITATIONS MAY NOT APPLY TO YOU. YOU MAY ALSO HAVE OTHER
                                                RIGHTS UNDER YOUR LOCAL LAW IN YOUR STATE, PROVINCE OR
                                                COUNTRY THAT VARY FROM STATE TO STATE. NOTHING IN THIS
                                                AGREEMENT IS INTENDED TO AFFECT THOSE RIGHTS IF THEY ARE
                                                APPLICABLE TO YOU.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseEight"
                                                    aria-expanded="false" aria-controls="collapseEight">
                                                8. Force Majeure
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseEight" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                Force Majeure. Under no circumstances shall Ezytrade.africa and our affiliates and
                                                agents be held liable for any delay or failure or disruption of the Transaction Services
                                                resulting directly or indirectly from acts of nature, forces or causes beyond our reasonable
                                                control, including without limitation, acts of God, Internet failures, computer,
                                                telecommunications or any other equipment failures, electrical power failures, strikes,
                                                labour disputes, riots, insurrections, civil disturbances, shortages of labor or materials,
                                                terrorism, war, governmental actions, orders of domestic or foreign courts or tribunals.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseNine"
                                                    aria-expanded="false" aria-controls="collapseNine">
                                                9. Notices
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseNine" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                9.1 Notices. Except as explicitly stated otherwise, legal notices shall be served on you by
                                                sending notices to the email address in your latest membership profile on the Sites.
                                                Notice shall be deemed given 24 hours after email is sent, unless we are notified that the
                                                email address is invalid. Alternatively, we may give you legal notices by mail to the
                                                address in your latest membership profile in which case the notice shall be deemed given
                                                five days after the date of mailing. Except as explicitly stated otherwise, legal notices
                                                shall be served on Ezytrade.africa by sending the notices to Ezytrade.africa personally, by
                                                courier or certified mail at 263 Chato Street, Regent Estate. P.O.Box 5382, Dar es
                                                Salaam, Tanzania., Attn: Legal Department.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseTen"
                                                    aria-expanded="false" aria-controls="collapseTen">
                                                10. Governing Law; Jurisdiction
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTen" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                10.1 GOVERNING LAW. THIS AGREEMENT SHALL BE GOVERNED BY THE
                                                LAWS OF THE UNITED STATES OF TANZANIA
                                                  </p>
                                            <p>
                                                10.2 Amicable Negotiations. If any dispute or claim arises from or in connection with this
                                                Agreement, an Online Transaction or your access to or use of the Transaction Services
                                                (“Dispute”), the relevant parties shall resolve the Dispute through amicable negotiations.

                                            </p>
                                            <p>
                                                10.3 DISPUTE BETWEEN BUYER AND SELLER. IN CASE A DISPUTE ARISES
                                                BETWEEN BUYER AND SELLER , IF THE DISPUTE IS NOT RESOLVED
                                                THROUGH AMICABLE NEGOTIATION WITHIN THE PRESCRIBED TIME
                                                PERIOD ACCORDING TO THE RELEVANT TRANSACTIONAL TERMS, YOU
                                                AGREE TO SUBMIT THE DISPUTE TO EZYTRADE.AFRICA FOR
                                                DETERMINATION. IF YOU ARE DISSATISFIED WITH EZYTRADE.AFRICA’S
                                                DETERMINATION AND EXCEPT AS OTHERWISE STIPULATED UNDER
                                                APPLICABLE LAW, YOU MUST APPLY TO THE HONG KONG ARBITRATION
                                                CENTRE (“HKIAC”) FOR ARBITRATION AND NOTIFY EZYTRADE.AFRICA OF
                                                SUCH APPLICATION WITHIN 20 CALENDAR DAYS AFTER THE DATE OF
                                                RECEIPT OF EZYTRADE.AFRICA’S DETERMINATION. IF EACH OF BUYER
                                                AND SELLER IN THE DISPUTE DOES NOT APPLY FOR ARBITRATION WITHIN
                                                THE ABOVE 20 CALENDAR DAYS, EACH OF THE BUYER AND THE SELLER
                                                SHALL BE DEEMED TO HAVE AGREED THAT EZYTRADE.AFRICA’S
                                                DETERMINATION SHALL BE FINAL AND BINDING ON YOU. WITH A FINAL
                                                DETERMINATION, IN THE CASE THE ONLINE TRANSACTION ADOPTS THE
                                                PAYMENT SERVICES SERVICES, EZYTRADE.AFRICA MAY INSTRUCT
                                                PAYMENT SERVICES TO DISPOSE THE FUNDS HELD BY PAYMENT
                                                SERVICES ACCORDING TO SUCH DETERMINATION, AND IN THE CASE THE
                                                ONLINE TRANSACTION ADOPTS EZYTRADE.AFRICA SUPPLEMENTAL
                                                SERVICES, EZYTRADE.AFRICA MAY DISPOSE OF THE FUNDS HELD BY
                                                EZYTRADE.AFRICA ACCORDING TO SUCH DETERMINATION. FURTHER,
                                                EACH OF BUYER AND SELLER SHALL BE DEEMED TO HAVE WAIVED ANY
                                                CLAIM AGAINST EZYTRADE.AFRICA, OUR AFFILIATES AND AGENTS.
                                            </p>
                                            <p>
                                                10.4 Disputes between you and Ezytrade.africa. In case a Dispute arises between you and
                                                Ezytrade.africa, if the Dispute is not resolved between you and Ezytrade.africa by
                                                amicable negotiations and except as otherwise stipulated under applicable law, you and
                                                Ezytrade.africa agree that the Dispute shall be finally resolved by arbitration with the
                                                ABOVE MENTIONED ARBITRATOR.
                                            </p>
                                            <p>
                                                10.5 HKIAC ARBITRATION. IF ANY DISPUTE IS SUBMITTED TO THE HKIAC
                                                FOR ARBITRATION, THE ARBITRATION SHALL BE CONDUCTED IN
                                                ACCORDANCE WITH THE RULES OF THE HKIAC IN FORCE AT THE TIME OF
                                                APPLYING FOR ARBITRATION AS AMENDED BY THIS CLAUSE. THE
                                                ARBITRATION PANEL SHALL CONSIST OF ONE SINGLE ARBITRATOR.
                                                UNLESS THE PARTIES AGREE OTHERWISE, THE ARBITRATION SHALL BE
                                                CONDUCTED IN ENGLISH AND IN HONG KONG. THE ARBITRATION SHALL
                                                BE CONDUCTED BY TELEPHONE, ONLINE AND/OR SOLELY BASED ON
                                                WRITTEN SUBMISSIONS AS SPECIFIED BY THE PARTY INITIATING THE
                                                ARBITRATION, PROVIDED THAT THE ARBITRATION SHALL NOT INVOLVE
                                                ANY PERSONAL APPEARANCE BY THE PARTIES OR WITNESSES UNLESS
                                                OTHERWISE AGREED BY THE PARTIES. THE ARBITRATION AWARD
                                                RENDERED BY THE HKIAC SHALL BE FINAL AND BINDING ON ALL THE
                                                RELEVANT PARTIES. THE ARBITRATION EXPENSES SHALL BE BORNE BY
                                                THE LOSING PARTY UNLESS OTHERWISE DETERMINED IN THE AWARD.
                                            </p>
                                            <p>
                                                10.6 Indemnification. If you initiate any legal proceedings against Ezytrade.africa or our
                                                affiliates in breach of this clause 10, including any legal proceedings disputing
                                                Ezytrade.africa’s determination which has become binding on you according to this
                                                clause 10, you shall hold Ezytrade.africa and our affiliates, agents, employees, directors,
                                                officers harmless and indemnified against any claim, losses, damages that may be
                                                suffered by us.
                                            </p>
                                            <p>
                                                10.7 Limitation Period. In any event, you may not make any claim against
                                                Ezytrade.africa or our affiliates under this Agreement after one year from the occurrence
                                                of the matter giving rise to the claim.

                                            </p>
                                            <p>
                                                10.8 Injunctive Relief. Notwithstanding the foregoing provisions, either party may seek
                                                injunctive or other equitable relief against the other party in any court of competent
                                                jurisdiction prior to or during the arbitration.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseEleven"
                                                    aria-expanded="false" aria-controls="collapseEleven">

                                                11. General Provisions
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseEleven" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                11.1 Entire Agreement. This Agreement constitutes the entire agreement between you
                                                and Ezytrade.africa with respect to and governs the use of the Transaction Services,
                                                superseding any prior written or oral agreements in relation to the same subject matter
                                                herein.
                                            </p>
                                            <p>
                                                11.2 Severance. If any provision of this Agreement is held to be invalid or unenforceable,
                                                such provision shall be deleted and the remaining provisions shall remain valid and be
                                                enforced.
                                            </p>

                                            <p>
                                                11.3 Headings. Headings are for reference purposes only and in no way define, limit,
                                                construe or describe the scope or extent of such section.
                                            </p>

                                            <p>
                                                11.4 Independent Contractor. No agency, partnership, joint venture, employee-employer
                                                or franchiser-franchisee relationship is intended or created by this Agreement.
                                            </p>

                                            <p>
                                                11.5 No Waiver. Any failure by Ezytrade.africa and our affiliates to exercise any of our
                                                rights under this Agreement shall not constitute a waiver of such right or a waiver with
                                                respect to subsequent or similar breach. A waiver shall be effective only if made in
                                                writing.
                                            </p>

                                            <p>
                                                11.6 Assignment. Ezytrade.africa shall have the right to assign this Agreement (including
                                                all of our rights, titles, benefits, interests, and obligations and duties in this Agreement) to
                                                any of our affiliates and to any successor in interest. Ezytrade.africa may delegate certain
                                                of Ezytrade.africa rights and responsibilities under this Agreement to independent
                                                contractors or other third parties. You may not assign, in whole or part, this Agreement
                                                to any person or entity.
                                            </p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SitePageIprpp;
