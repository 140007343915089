// react
import React from "react";
// third-party
import { Helmet } from "react-helmet-async";
// application
import PageHeader from "../shared/PageHeader";
// data stubs
import theme from "../../data/theme";
import AccountNavPageLogin from "./AccountNavPageLogin";
function AccountPageLogin() {  
  return (
    <React.Fragment>
      <Helmet>
        <title>{`Login — ${theme.name}`}</title>
      </Helmet>
      <PageHeader />
      <div className="block">
        <div className="container">
          <div className="d-flex justify-content-center ">
            <div className="col-md-6">
              <div className="card flex-grow-1  mb-md-0">
                <div className="card-body">
                  <span className="d-flex justify-content-center font-size-27">Ezytrade Africa</span>
                    <span className="d-flex justify-content-center font-size-25">Sign in</span>
                  <AccountNavPageLogin />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}


export default AccountPageLogin;
