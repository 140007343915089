import React, { useEffect, useMemo, useState } from "react";
import { Pagination, Search } from "../DataTable";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import theme from "../../data/theme";
import { customertoken, userCurrency } from "../../api/shop";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { Spinner } from "reactstrap";
import { FetchAppliedQuote } from "../../store/AppliedQoute";
import CurrencyFormat from "react-currency-format";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    color: green[500],
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function AccountPageQuote() {
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    if (customertoken()) {
      dispatch(FetchAppliedQuote());
    }
  }, [dispatch]);
  const DataList = useSelector((state) => state.AppliedQuote);
  const RateList = useSelector((state) => state.QuoteRateList);
  const [quoteData, setQuotes] = useState([]);
  const [Rates, setRates] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const ITEMS_PER_PAGE = 5;
  // const user_token = customertoken(); 
  useEffect(() => {
    setRates(RateList);
  }, [RateList]);
  useEffect(() => {
    setQuotes(DataList);
  }, [DataList]);
  // const OrderRates = JSON.parse(Rates.rate);
  // const quoteData = useMemo(() => {
  // let computedQuote = quote.quotes;
  //   if (search) {
  //     computedQuote = computedQuote.filter(
  //       (quote) =>
  //         quote.totalAmountPaid.toLowerCase().includes(search.toLowerCase()) ||
  //         quote.totalVatPaid.toLowerCase().includes(search.toLowerCase()) ||
  //         quote.created_at.toLowerCase().includes(search.toLowerCase())
  //     );
  //   }
  //   setTotalItems(computedQuote.length);
  //   //Current Page slice
  //   return computedQuote.slice(
  //     (currentPage - 1) * ITEMS_PER_PAGE,
  //     (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
  //   );
  // }, [quote.quotes, currentPage, search]);

  return (
    <div className="card">
      <Helmet>
        <title>{`Order History — ${theme.name}`}</title>
      </Helmet>
      <div className="card-header">
        <div className="d-flex align-items-end flex-column">
          <div className="mt-auto p-2">
            {/* <Search
              onSearch={(value) => {
                setSearch(value);
                setCurrentPage(1);
              }}
            /> */}
          </div>
        </div>
      </div>
      <div className="card-divider" />
      <div className="card-table">
        <div className="table-responsive-sm">
        {quoteData.length === 0 ? (
              <div className="d-flex justify-content-center">
                <div className={classes.root}>
                  <span className="d-flex justify-content-center">
                    <Spinner size="xl" />
                  </span>
                  <span className="d-flex mt-10 justify-content-center font-weight-bold">
                    Data Loading...
            </span>
                </div>
              </div>) : (
          <table>
            <thead>
              <tr className="text-center">
                <th>Amount</th>
                <th>Total Vat</th>
                <th>Total Amount</th>
                <th>Date</th>
                <th>status</th>
                <th>Action</th>
              </tr>
            </thead>          
                <tbody>
                  {quoteData.quotes.map((quote) => (
                    <tr className="text-center">
                      <td>
                        {/* {quote.totalAmountPaid - quote.totalVatPaid} */}
                        {userCurrency() === "TZS" ? (
                          <CurrencyFormat
                            value={Math.round((Number(quote.totalAmountPaid - quote.totalVatPaid) / Number(Rates["TZS"])) * Number(Rates[userCurrency()]))
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                              <div>
                                {userCurrency()}{" "}{value}
                                {/*{product.wholesale_currency} {value}*/}
                              </div>
                            )}
                          />
                        ) : (
                            <CurrencyFormat
                              value={((Number(quote.totalAmountPaid - quote.totalVatPaid) / Number(Rates["TZS"])) * Number(Rates[userCurrency()])).toFixed(2)
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              renderText={(value) => (
                                <div>
                                  {userCurrency()}{" "}{value}
                                  {/*{product.wholesale_currency} {value}*/}
                                </div>
                              )}
                            />
                          )
                        }
                      </td>
                      <td>

                        {/* {quote.totalVatPaid} */}
                        {userCurrency() === "TZS" ? (
                          <CurrencyFormat
                            value={Math.round((Number(quote.totalVatPaid) / Number(Rates["TZS"])) * Number(Rates[userCurrency()]))
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                              <div>
                                {userCurrency()}{" "}{value}
                                {/*{product.wholesale_currency} {value}*/}
                              </div>
                            )}
                          />
                        ) : (
                            <CurrencyFormat
                              value={((Number(quote.totalVatPaid) / Number(Rates["TZS"])) * Number(Rates[userCurrency()])).toFixed(2)
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              renderText={(value) => (
                                <div>
                                  {userCurrency()}{" "}{value}
                                  {/*{product.wholesale_currency} {value}*/}
                                </div>
                              )}
                            />
                          )
                        }

                      </td>
                      <td>
                        {/* {quote.totalAmountPaid} */}
                        {userCurrency() === "TZS" ? (
                          <CurrencyFormat
                            value={Math.round((Number(quote.totalAmountPaid) / Number(Rates["TZS"])) * Number(Rates[userCurrency()]))
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                              <div>
                                {userCurrency()}{" "}{value}
                                {/*{product.wholesale_currency} {value}*/}
                              </div>
                            )}
                          />
                        ) : (
                            <CurrencyFormat
                              value={((Number(quote.totalAmountPaid) / Number(Rates["TZS"])) * Number(Rates[userCurrency()])).toFixed(2)
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              renderText={(value) => (
                                <div>
                                  {userCurrency()}{" "}{value}
                                  {/*{product.wholesale_currency} {value}*/}
                                </div>
                              )}
                            />
                          )
                        }
                        {/* {JSON.parse(Rates.rate)} */}
                      </td>
                      <td>
                        <Moment format="DD/MM/YYYY">{quote.created_at}</Moment>
                      </td>
                      {quote.status !== "Pending" ? (
                        <td className="text-success font-weight-bold">Accepted</td>
                      ) : (
                          <td className="text-danger font-weight-bold">Pending</td>
                        )}
                      <td>
                        <Link
                          to={{ pathname: "/account/viewquote", state: [{quote},{Rates}] }}
                        >
                          View Items
                    </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
             
          </table>
           )
          }

        </div>
      </div>
      <div className="card-divider" />
      <div className="card-footer">
        <div className="d-flex align-items-center flex-column">
          {/* <Pagination
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          /> */}
        </div>
      </div>
    </div>
  );
};



export default AccountPageQuote;
