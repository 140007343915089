// react
import React, { Component } from "react";
// third-party
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
// application
import AsyncAction from "./AsyncAction";
// import Currency from "./Currency";
import InputNumber from "./InputNumber";
import { cartAddItem } from "../../store/cart";
import { compareAddItem } from "../../store/compare";
import { Compare16Svg, Wishlist16Svg, DollarSvg } from "../../svg";
import { wishlistAddItem } from "../../store/wishlist";
import CurrencyFormat from "react-currency-format";
import { ProductDetails } from "../../store/buynow";
import { QuoteAddItem } from "../../store/quote";
import Moment from "react-moment";
import subDays from "date-fns/subDays";
import Login from "../account/activateLogin";
import {customertoken,agentcustomerToken} from "../../api/shop";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
class Othershop extends Component {
  constructor(props) {
    super(props);
    const { product,rate } = props;
    // console.log(product);
    this.buyingHandle = this.buyingHandle.bind(this);
    this.priceHandle = this.priceHandle.bind(this);
    this.handleDiscription = this.handleDiscription.bind(this);
    this.startDateChange.bind(this);
    this.endDateChange.bind(this);
    this.state = {
      quantitywholesale:product.minimum_quantity,
      quantity:product.retail_minimum_quantity,
      buying_option: "retail",
      price_option: "retail",
      product: product,
      unit: 1,
      preferenceValidation: false,
      preferenceValidationsize: false,
      quotequantity:product.minimum_quantity,
      Quotedescription: "",
      startDate: new Date(),
      endDate: new Date(),
    };
  }
  handleChangeunit = (unit) => {
    this.setState({ unit });
  };

  handleDiscription = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  
  startDateChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  endDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };
  togglePreference(id) {
    this.setState({
      preferenceValidation: true,
    });
  }
  togglePreferencesize(id) {
    this.setState({
      preferenceValidationsize: true,
    });
  }
  handleChangeQuantity = (quantity) => {
    this.setState({ quantity });
  };
  handleChangeWholesaleQuantity = (quantitywholesale) => {
    this.setState({ quantitywholesale });
  };

  buyingHandle(e) {
    this.setState({
      buying_option: e.target.value,
    });
  }

  priceHandle(e) {
    this.setState({
      price_option: e.target.value,
    });
  }

  render() {
    const {
      product,
      layout,
      wishlistAddItem,
      compareAddItem,
      cartAddItem,
      QuoteAddItem,
      ProductDetails,
      rate,
    } = this.props;
    // console.log(product);
    const { quantity } = this.state;
    const { unit } = this.state;
    const { quantitywholesale } = this.state;
    const { buying_option } = this.state;
    const { price_option } = this.state;
    // quotedata
    const { quotequantity } = this.state;
    const { Quotedescription } = this.state;
    const { startDate } = this.state;
    const { endDate } = this.state;
    const currency =rate;
    const userCurrency = localStorage.getItem("userCurrency");
    let Price_data;
    let discounted;
    let preferences;
    let quotemodal;
    let quoteForm;   
    let Loginmodal;
      Loginmodal=(
          <>
            <div className="product__actions-item product__actions-item--addtocart">
              <button
                  data-toggle="modal"
                  data-target="#Login"
                  type="button"
                  title="Add to quote"
                  data-dismiss="modal"
                  className={classnames("btn   btn-success btn-md")}
              >
              Buy Now
              </button>
            </div>
            <div
                className="modal fade"
                id="Login"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLongTitle"
                aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content container">
                  <div className="modal-header">
                    <h5
                        className="modal-title"
                        id="exampleModalLongTitle"
                    >
                    Please login into your Account
                    </h5>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                <Login />
                </div>
              </div>
            </div>
          </>
      )
  // console.log(currency[product.shop_price.retail_currency]);
  function unitQuantity(){
      if (product.product.customUoM) {
        let quantitymeasure;
        quantitymeasure=unit
        return  quantitymeasure;
      }
      else{
        let quantitymeasure;
        quantitymeasure=1
        return  quantitymeasure;
      }
    }

 // console.log(Products());
    //total vat
   function total_vat() {
    if (buying_option === "wholesale") {
      let USD;
      let sum;
      USD =Number(product.shop_price.total_wholesale_vat)
          / Number(currency[ product.shop_price.wholesale_currency]);
      if(userCurrency === product.shop_price.wholesale_currency) {
        sum = Math.round(Number(USD) * Number(currency[userCurrency]));
        // sum = Number(product.total_wholesale_vat);
        return sum;
      }
      else{
        sum = (Number(USD) * Number(currency[userCurrency])).toFixed(2);
        // sum = Number(product.total_wholesale_vat);
        return sum;
      }
    // sum = Number(product.shop_price.total_wholesale_vat);
    //   return sum;
    } else {
      let sum;
      let USD;
      USD =Number(product.shop_price.total_retail_vat)
          / Number(currency[product.shop_price.retail_currency]);
      if(userCurrency === product.shop_price.retail_currency) {
        sum = Math.round(Number(USD) * Number(currency[userCurrency]));
        // sum = Number(product.total_retail_vat);
        return sum;
      }else{
        sum = (Number(USD) * Number(currency[userCurrency])).toFixed(2);
        // sum = Number(product.total_retail_vat);
        return sum;
      }
     // sum = Number(product.shop_price.total_retail_vat);
     //  return sum;
    }
  }
      // console.log(product);
 //total amount
 function total_amount() {
  if (buying_option === "wholesale") {
    if (product.product.customUoM) {
      let sum;
      let USD;
      USD = Math.round(
          Number(product.shop_price.wholesaleprice) +
          Number(product.shop_price.total_wholesale_vat)
          )
          / Number(currency[product.shop_price.wholesale_currency]);
      if(userCurrency === product.shop_price.wholesale_currency) {
        sum =
            unit *
            quantitywholesale *
            Math.round(Number(USD) * Number(currency[userCurrency]))
        // Math.round(
        //   Number(product.wholesaleprice) +
        //     Number(product.total_wholesale_vat)
        // );
        return sum;
      }
      else{
        sum =
            unit *
            quantitywholesale *
            (Number(USD) * Number(currency[userCurrency])).toFixed(2)
        // Math.round(
        //   Number(product.wholesaleprice) +
        //     Number(product.total_wholesale_vat)
        // );
        return sum;
      }
      // sum =
      //   unit *
      //   quantitywholesale *
      //   Math.round(
      //     Number(product.shop_price.wholesaleprice) +
      //       Number(product.shop_price.total_wholesale_vat)
      //   );
      // return sum;
    } else {
      let sum;
      let USD;
      USD = Math.round(
          Number(product.shop_price.wholesaleprice) +
          Number(product.shop_price.total_wholesale_vat)
          )
          / Number(currency[product.shop_price.wholesale_currency]);
      if(userCurrency === product.shop_price.wholesale_currency) {
        sum =
            quantitywholesale *
            Math.round(Number(USD) * Number(currency[userCurrency]))
        return sum;
      }
      else{
        sum =
            quantitywholesale *
            (Number(USD) * Number(currency[userCurrency])).toFixed(2)
        return sum
      }
      // sum =
      //   quantitywholesale *
      //   Math.round(
      //     Number(product.shop_price.wholesaleprice) +
      //       Number(product.shop_price.total_wholesale_vat)
      //   );
      // return sum;
    }
  } else {
    if (product.product.customUoM) {
      if (Products().discount) {
        let sum;
        let USD;
        USD =((Number(product.shop_price.retailprice) +  Number(product.shop_price.total_retail_vat)) -
            Number(Number(Products().discount.discount)))
            / Number(currency[product.shop_price.retail_currency]);
        if(userCurrency === product.shop_price.retail_currency) {
          sum =
              unit *
              quantity *
              Math.round(Number(USD) * Number(currency[userCurrency]))
          return sum;
        }
        else{
          sum =
              unit *
              quantity *
              (Number(USD) * Number(currency[userCurrency])).toFixed(2)
          return sum;
        }
        // sum =
        //   unit *
        //   quantity *
        //   Math.round(
        //     Number(product.shop_price.retailprice) +
        //       Number(product.shop_price.total_retail_vat) -
        //       Number(Products().discount.discount)
        //   );
        // return sum;
      } else {
        let sum;
        let USD;
        USD =(Number(product.shop_price.retailprice) + Number(product.shop_price.total_retail_vat))
            / Number(currency[product.shop_price.retail_currency]);
        if(userCurrency === product.shop_price.retail_currency) {
          sum =
              unit *
              quantity *
              Math.round(Number(USD) * Number(currency[userCurrency]))
          return sum;
        }
        else{
          sum =
              unit *
              quantity *
              (Number(USD) * Number(currency[userCurrency])).toFixed(2)
          return sum;
        }
        // sum =
        //   unit *
        //   quantity *
        //   Math.round(
        //     Number(product.shop_price.retailprice) + Number(product.shop_price.total_retail_vat)
        //   );
        // return sum;
      }
    }
    else{
    if (Products().discount) {
      let sum;
      let USD;
      USD =((Number(product.shop_price.retailprice) + Number(product.shop_price.total_retail_vat)) -
          Number(Products().discount.discount))
          / Number(currency[product.shop_price.retail_currency]);
      if(userCurrency === product.shop_price.retail_currency) {
        sum =
            quantity *
            Math.round(Number(USD) * Number(currency[userCurrency]))
        return sum;
      }
      else{
        sum =
            quantity *
            (Number(USD) * Number(currency[userCurrency])).toFixed(2)
        return sum;
      }
   //   sum =
    //     quantity *
    //     Math.round(
    //       Number(product.shop_price.retailprice) +
    //         Number(product.shop_price.total_retail_vat) -
    //         Number(Products().discount.discount)
    //     );
    //   return sum;
    }
      let sum;
      let USD;
      USD =(Number(product.shop_price.retailprice) + Number(product.shop_price.total_retail_vat))
          / Number(currency[product.shop_price.retail_currency]);
      if(userCurrency === product.shop_price.retail_currency) {
        sum =
            quantity *
            Math.round(Number(USD) * Number(currency[userCurrency]))
        return sum;
      }
      else{
        sum =
            quantity *
            (Number(USD) * Number(currency[userCurrency])).toFixed(2)
        return sum;
      }
    // sum =
    //   quantity *
    //   Math.round(
    //     Number(product.shop_price.retailprice) + Number(product.shop_price.total_retail_vat)
    //   );
    // return sum;
  }
}
}
//amount to be taken
// console.log(product);
function amount() {
  if (buying_option === "wholesale") {
    if (product.product.customUoM) {
      let sum;
      let USD;
      USD =Math.round(
          Number(product.shop_price.wholesaleprice) +
          Number(product.shop_price.total_wholesale_vat)
          )
          / Number(currency[product.shop_price.wholesale_currency]);
      if(userCurrency === product.shop_price.wholesale_currency) {
        sum =
            unit *
            Math.round(Number(USD) * Number(currency[userCurrency]))
        return sum;
      }
      else{
        sum =
            unit *
            (Number(USD) * Number(currency[userCurrency])).toFixed(2)
        return sum;
      }
     // sum =
      //   unit *
      //   Math.round(
      //     Number(product.shop_price.wholesaleprice) +
      //       Number(product.shop_price.total_wholesale_vat)
      //   );
      // return sum;
    } else {
      let sum;
      let USD;
      USD =Math.round(
          Number(product.shop_price.wholesaleprice) + Number(product.shop_price.total_wholesale_vat)
          )
          / Number(currency[product.shop_price.wholesale_currency]);
      if(userCurrency === product.shop_price.wholesale_currency) {
        sum =
            Math.round(Number(USD) * Number(currency[userCurrency]))
        return sum;
      }
      else{
        sum =
            (Number(USD) * Number(currency[userCurrency])).toFixed(2)
        return sum;
      }
    // sum = Math.round(
      //   Number(product.shop_price.wholesaleprice) + Number(product.shop_price.total_wholesale_vat)
      // );
      // return sum;
    }
  } else {
    if (product.product.customUoM) {
      if (Products().discount) {
        let sum;
        let USD;
        USD =((Number(product.shop_price.retailprice)  +Number(product.shop_price.total_retail_vat)) -
            Number(Products().discount.discount))
            / Number(currency[product.shop_price.retail_currency]);
        if(userCurrency === product.shop_price.retail_currency) {
          sum =
              unit *
              Math.round(Number(USD) * Number(currency[userCurrency]))
          return sum;
        }
        else{
          sum =
              unit *
              (Number(USD) * Number(currency[userCurrency])).toFixed(2)
          return sum;
        }
        // sum =
        //   unit *
        //   Math.round(
        //     Number(product.shop_price.retailprice) +
        //       Number(product.shop_price.total_retail_vat) -
        //       Number(Products().discount.discount)
        //   );
        // return sum;
      }
      else {
        let sum;
        let USD;
        USD =(Number(product.shop_price.retailprice) + Number(product.shop_price.total_retail_vat))
            / Number(currency[product.shop_price.retail_currency]);
        if(userCurrency === product.shop_price.retail_currency) {
          sum =
              unit *
              Math.round(Number(USD) * Number(currency[userCurrency]))
          return sum;
        }
        else{
          sum =
              unit *
              (Number(USD) * Number(currency[userCurrency])).toFixed(2)
          return sum;
        }
        // sum =
        //   unit *
        //   Math.round(
        //     Number(product.shop_price.retailprice) + Number(product.shop_price.total_retail_vat)
        //   );
        // return sum;
      }
    } else {
      if (Products().discount) {
        let sum;
        let USD;
        USD =((Number(product.shop_price.retailprice) + Number(product.shop_price.total_retail_vat)) -
            Number(Products().discount.discount))
            / Number(currency[product.shop_price.retail_currency]);
        if(userCurrency === product.shop_price.retail_currency) {
          sum = Math.round(Number(USD) * Number(currency[userCurrency]))
          return sum;
        }
        else{
          sum = (Number(USD) * Number(currency[userCurrency])).toFixed(2)
          return sum;
        }
        // sum = Math.round(
        //   Number(product.shop_price.retailprice) +
        //     Number(product.shop_price.total_retail_vat) -
        //     Number(Products().discount.discount)
        // );
        // return sum;
      }
      let sum;
      let USD;
      USD =(Number(product.shop_price.retailprice) + Number(product.shop_price.total_retail_vat))
          / Number(currency[product.shop_price.retail_currency]);
      if(userCurrency === product.shop_price.retail_currency) {
        sum = Math.round(Number(USD) * Number(currency[userCurrency]))
        return sum;
      }
      else{
        sum = (Number(USD) * Number(currency[userCurrency])).toFixed(2)
        return sum;
      }
      // sum = Math.round(
      //   Number(product.shop_price.retailprice) + Number(product.shop_price.total_retail_vat)
      // );
      // return sum;
    }
  }
}
// console.log("gfgjfgjfk amount",amount());
    function product_details() {
      return {
        id: product.id,
        shop_id: product.shop_id,
        seller_token: product.product.business.secure_token,
        vat: parseInt(total_vat()),
        name: product.product.product_name,
        slug: product.product.product_name,
        price: amount(),
        wholesale_price: parseInt(product.shop_price.wholesaleprice),
        currency: userCurrency,
        currency_wholesale:userCurrency,
        // currency: product.shop_price.retail_currency,
        // currency_wholesale: product.shop_price.wholesale_currency,
        discount: product.product.discount,
        unitMeasure:product.product.unitMeasure,
        unit:unitQuantity(),
        compareAtPrice: null,
        status: product.product.is_featured,
        images: Products().images.productImage_name,
        secure_token: product.secure_token,
        product_token: product.product_token,
        outlets_token: product.outlet_token,
        Category: product.product.categories.category,
        badges: [],
        rating: 5,
        reviews: 3,
        brand: product.product.brand,
        categories: [],
        attributes: [],
        customFields: [],
      };
    }

    function quote_details() {
      return {
        id: Products().id,
        shop_id:Products().shop_id,
        seller_token: Products().seller_token,
        name: Products().name,
        slug: Products().name,
        images: Products().images.productImage_name,
        product_token: Products().product_token,
        outlets_token: Products().outlet_token,
        quotes_description:Quotedescription,
        start_at: startDate,
        end_at: endDate,
      };
    }
    function Products(){
      return {
        id: product.id,
        name: product.product.product_name,
        slug: product.product.product_name,
        price: parseInt(product.shop_price.retailprice),
        price_tag: product.shop_price.price_tag,
        vat_check: product.shop_price.vat_check,
        vat: parseInt(product.shop_price.total_retail_vat),
        ezytrade_commission: product.product.ezy_commmission,
        agent_commission: product.product.agent_commmission,
        wholesale_price: parseInt(product.shop_price.wholesaleprice),
        wholesale_vat: parseInt(product.shop_price.total_wholesale_vat),
        currency: userCurrency,
        currency_wholesale:userCurrency,
        // currency: product.shop_price.retail_currency,
        // currency_wholesale: product.shop_price.wholesale_currency,
        discount: product.product.discount,
        customUoM: product.product.customUoM,
        images: product.product.images,
        compareAtPrice: null,
        status: product.product.is_featured,
        shop_id: product.shop_id,
        seller_token: product.product.business.secure_token,
        secure_token: product.secure_token,
        product_token: product.product_token,
        outlets_token: product.outlet_token,
        Category: product.product.categories.category,
        badges: [],
        rating: 5,
        reviews: 3,
        brand: product.product.brand,
        categories: [],
        attributes: [],
        customFields: {},
      }
    }

    //product Preferences
    function handleChange(data) {
      localStorage.setItem("preferenceOptions", data.preference_token);
      return data;
    }
    // if (product.Preference.length >= 1) {
    //   let color = (
    //     <>
    //       <div className="product__option-label">Color</div>
    //       <div className="row ">
    //         {product.Preference.map((preferences) => (
    //           <div>
    //             {preferences.preference_token === "LFmpYSIUh9Ddr7pU" ? (
    //               <>
    //                 <div className="order-list__column-image">
    //                   <div className="product-image">
    //                     <div className="container product-image__body ">
    //                       <img
    //                         className=" border border-warning product-image__img img-thumbnail product-gallery__carousel-image "
    //                         alt="ghf"
    //                         src={preferences.Preference_image.productImage_name}
    //                       />
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="container">
    //                   <input
    //                     type="radio"
    //                     name="color"
    //                     onChange={() => handleChange(preferences)}
    //                     value="Radiobox"
    //                     onClick={() => this.togglePreference(preferences.id)}
    //                   />
    //                   <small className="text-lowercase">
    //                     {" "}
    //                     {preferences.preference_choice}
    //                   </small>
    //                 </div>
    //               </>
    //             ) : (
    //               <div />
    //             )}
    //           </div>
    //         ))}
    //       </div>
    //     </>
    //   );
    //
    //   let size = (
    //     <div>
    //       <div className="product__option-label">Size</div>
    //       <div className="row">
    //         {product.Preference.map((preferences) => (
    //           <div>
    //             {preferences.preference_token === "PjSRaBpAwtbzqMRd" ? (
    //               <div>
    //                 <div className="form-group product__option">
    //                   <div className="input-radio-label">
    //                     <div className="input-radio-label__list">
    //                       <label>
    //                         <input
    //                           type="radio"
    //                           name="material"
    //                           onChange={() => handleChange(preferences)}
    //                           value="Radiobox"
    //                           onClick={() =>
    //                             this.togglePreferencesize(preferences.id)
    //                           }
    //                         />
    //                         {/*<small className="text-capitalize"> {preferences.preference_choice}</small>*/}
    //                         <span className="ext-capitalize">
    //                           {preferences.preference_choice}
    //                         </span>
    //                       </label>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             ) : (
    //               <div />
    //             )}
    //           </div>
    //         ))}
    //       </div>
    //     </div>
    //   );
    //   preferences = (
    //     <div>
    //       <div>{color}</div>
    //       <div>{size}</div>
    //       {!this.state.preferenceValidation &&
    //       !this.state.preferenceValidationsize ? (
    //         <div className="text-center text-danger d-flex justify-content-center">
    //           Preferences is required
    //         </div>
    //       ) : (
    //         <></>
    //       )}
    //     </div>
    //   );
    // } else {
    //   preferences = <div />;
    // }

    if (buying_option === "wholesale") {
      Price_data = (
        <CurrencyFormat
          value={total_amount()}
          displayType={"text"}
          thousandSeparator={true}
          renderText={(value) => (
            <div>
              {userCurrency}{" "}{value}
              {/*{product.shop_price.wholesale_currency} {value}*/}
            </div>
          )}
        />
      );
    } else {
      Price_data = (
        <CurrencyFormat
          value={total_amount()}
          displayType={"text"}
          thousandSeparator={true}
          renderText={(value) => (
            <div>
              {userCurrency}{" "}{value}
              {/*{product.shop_price.retail_currency} {value}*/}
            </div>
          )}
        />
      );
    }

    if (Products().discount) {
      if (buying_option === "wholesale") {
        discounted = (
          <div className="product-card__prices">
            <small className="product-card__new-price">
              {userCurrency === product.shop_price.wholesale_currency ?(
                  <CurrencyFormat
                      value={Math.round(((Number(product.shop_price.wholesaleprice)  + Number(product.shop_price.total_wholesale_vat)) / Number(currency [product.shop_price.wholesale_currency])) * Number(currency [userCurrency]))
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      renderText={(value) => (
                          <div>
                            {userCurrency}{" "}{value}
                            {/*{product.wholesale_currency} {value}*/}
                          </div>
                      )}
                  />
              ):(
                  <CurrencyFormat
                      value={(((Number(product.shop_price.wholesaleprice)  + Number(product.shop_price.total_wholesale_vat)) / Number(currency [product.shop_price.wholesale_currency])) * Number(currency [userCurrency])).toFixed(2)
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      renderText={(value) => (
                          <div>
                            {userCurrency}{" "}{value}
                            {/*{product.wholesale_currency} {value}*/}
                          </div>
                      )}
                  />
              )
              }
            </small>
          </div>
        );
      } else {
        discounted = (
          <div className="product-card__prices">
            <span className="product-card__new-price">
                {userCurrency === product.shop_price.retail_currency ? (
                    <CurrencyFormat
                        value={
                          Math.round((Number(product.shop_price.retailprice) +
                              Number(product.shop_price.total_retail_vat) -
                              Number(Products().discount.discount))/
                              Number(currency [product.shop_price.retail_currency]) * Number(currency [userCurrency]))}
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => (
                            <div>
                              {userCurrency}{" "}{value}
                              {/*{product.retail_currency} {value}*/}
                            </div>
                        )}
                    />
                ) : (
                    <CurrencyFormat
                        value={
                          ((Number(product.shop_price.retailprice) +
                              Number(product.shop_price.total_retail_vat) -
                              Number(Products().discount.discount))/
                              Number(currency [product.shop_price.retail_currency]) * Number(currency [userCurrency])).toFixed(2)}
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => (
                            <div>
                              {userCurrency}{" "}{value}
                              {/*{product.retail_currency} {value}*/}
                            </div>
                        )}
                    />
                )
                }

           {/*<CurrencyFormat*/}
           {/*     value={Math.round(*/}
           {/*       Number(product.shop_price.retailprice) +*/}
           {/*         Number(product.shop_price.total_retail_vat) -*/}
           {/*         Number(Products().discount.discount)*/}
           {/*     )}*/}
           {/*     displayType={"text"}*/}
           {/*     thousandSeparator={true}*/}
           {/*     renderText={(value) => (*/}
           {/*       <div>*/}
           {/*         {userCurrency}{" "}{value}*/}
           {/*         /!*{product.shop_price.retail_currency} {value}*!/*/}
           {/*       </div>*/}
           {/*     )}*/}
           {/*   />*/}

            </span>{" "}
            <span className="product-card__old-price">

               {userCurrency === product.retail_currency ? (
                   <CurrencyFormat
                       // value={Math.round(
                       //   Number(product.retailprice) + Number(product.total_retail_vat)
                       // )}
                       value={Math.round(((Number(product.shop_price.retailprice) +  Number(product.shop_price.total_retail_vat)) / Number(currency [product.shop_price.retail_currency])) * Number(currency [userCurrency]))
                       }
                       displayType={"text"}
                       thousandSeparator={true}
                       renderText={(value) => (
                           <div>
                             {userCurrency}{" "}{value}
                             {/*{product.retail_currency} {value}*/}
                           </div>
                       )}
                   />
               ):(
                   <CurrencyFormat
                       // value={Math.round(
                       //   Number(product.retailprice) + Number(product.total_retail_vat)
                       // )}
                       value={(((Number(product.shop_price.retailprice) +  Number(product.shop_price.total_retail_vat)) / Number(currency [product.shop_price.retail_currency])) * Number(currency [userCurrency])).toFixed(2)
                       }
                       displayType={"text"}
                       thousandSeparator={true}
                       renderText={(value) => (
                           <div>
                             {userCurrency}{" "}{value}
                             {/*{product.retail_currency} {value}*/}
                           </div>
                       )}
                   />
               )
               }
            {/*<CurrencyFormat*/}
              {/*  value={Math.round(*/}
              {/*    Number(product.shop_price.retailprice) +*/}
              {/*      Number(product.shop_price.total_retail_vat)*/}
              {/*  )}*/}
              {/*  displayType={"text"}*/}
              {/*  thousandSeparator={true}*/}
              {/*  renderText={(value) => (*/}
              {/*    <div>*/}
              {/*      {userCurrency}{" "}{value}*/}
              {/*      /!*{product.shop_price.retail_currency} {value}*!/*/}
              {/*    </div>*/}
              {/*  )}*/}
              {/*/>*/}
            </span>
            <div className="small">
              Discount Ends{" "}
              <Moment fromNow>{Products().discount.end_times}</Moment>
            </div>
          </div>
        );
      }
    } else {
      if (buying_option === "wholesale") {
        discounted = (
          <div className="product-card__prices">
            <span className="product-card__new-price">
                {userCurrency === product.shop_price.wholesale_currency ? (
                    <CurrencyFormat
                        // value={Math.round(
                        //   Number(product.wholesaleprice) +
                        //     Number(product.total_wholesale_vat)
                        // )}
                        value={Math.round(Math.round(Number(product.shop_price.wholesaleprice)  + Number(product.shop_price.total_wholesale_vat)) / Number(currency [product.shop_price.wholesale_currency]) * Number(currency [userCurrency]))
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => (
                            <div>
                              {userCurrency}{" "}{value}
                              {/*{product.wholesale_currency} {value}*/}
                            </div>
                        )}
                    />
                ) : (
                    <CurrencyFormat
                        // value={Math.round(
                        //   Number(product.wholesaleprice) +
                        //     Number(product.total_wholesale_vat)
                        // )}
                        value={(Math.round( Number(product.shop_price.wholesaleprice) + Number(product.shop_price.total_wholesale_vat)) / Number(currency [product.shop_price.wholesale_currency]) * Number(currency [userCurrency])).toFixed(2)
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => (
                            <div>
                              {userCurrency}{" "}{value}
                              {/*{product.wholesale_currency} {value}*/}
                            </div>
                        )}
                    />
                )
                }


              {/*<CurrencyFormat*/}
              {/*  value={Math.round(*/}
              {/*    Number(product.shop_price.wholesaleprice) +*/}
              {/*      Number(product.shop_price.total_wholesale_vat)*/}
              {/*  )}*/}
              {/*  displayType={"text"}*/}
              {/*  thousandSeparator={true}*/}
              {/*  renderText={(value) => (*/}
              {/*    <div>*/}
              {/*      {userCurrency}{" "}{value}*/}
              {/*      /!*{product.shop_price.wholesale_currency} {value}*!/*/}
              {/*    </div>*/}
              {/*  )}*/}
              {/*/>*/}
            </span>
          </div>
        );
      } else {
        discounted = (
          <div className="product-card__prices">
            <span className="product-card__new-price">
                    {userCurrency === product.shop_price.retail_currency ? (
                        <CurrencyFormat
                            // value={Math.round(
                            //   Number(product.retailprice) + Number(product.total_retail_vat)
                            // )}
                            value={Math.round((Math.round(Number(product.shop_price.retailprice) +
                                Number(product.shop_price.total_retail_vat)) / Number(currency [product.shop_price.retail_currency])) * Number(currency [userCurrency]))
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                                <div>
                                  {userCurrency}{" "}{value}
                                  {/*{product.retail_currency} {value}*/}
                                </div>
                            )}
                        />
                    ) : (
                        <CurrencyFormat
                            // value={Math.round(
                            //   Number(product.retailprice) + Number(product.total_retail_vat)
                            // )}
                            value={((Math.round(Number(product.shop_price.retailprice) +
                                Number(product.shop_price.total_retail_vat)) / Number(currency [product.shop_price.retail_currency])) * Number(currency [userCurrency])).toFixed(2)
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                                <div>
                                  {userCurrency}{" "}{value}
                                  {/*{product.retail_currency} {value}*/}
                                </div>
                            )}
                        />
                    )
                    }
              {/*<CurrencyFormat*/}
              {/*  value={Math.round(*/}
              {/*    Number(product.shop_price.retailprice) +*/}
              {/*      Number(product.shop_price.total_retail_vat)*/}
              {/*  )}*/}
              {/*  displayType={"text"}*/}
              {/*  thousandSeparator={true}*/}
              {/*  renderText={(value) => (*/}
              {/*    <div>*/}
              {/*      {userCurrency}{" "}{value}*/}
              {/*      /!*{product.shop_price.retail_currency} {value}*!/*/}
              {/*    </div>*/}
              {/*  )}*/}
              {/*/>*/}
            </span>
          </div>
        );
      }
    }
    quoteForm=(
      <Form className="mb-4">
      <div className="row product__name container">
        Price range &nbsp;&nbsp;
        <CurrencyFormat
          value={product.shop_price.price_start}
          displayType={"text"}
          thousandSeparator={true}
          renderText={(value) => (
            <div>
                  {userCurrency}{" "}{value}
              {/*{product.shop_price.price_start_currency} {value}*/}
            </div>
          )}
        />
        &nbsp;&nbsp; <h5>-</h5> &nbsp;&nbsp;
        <CurrencyFormat
          value={product.shop_price.price_end}
          displayType={"text"}
          thousandSeparator={true}
          renderText={(value) => (
            <div>
                  {userCurrency}{" "}{value}
              {/*{product.shop_price.price_end_currency} {value}*/}
            </div>
          )}
        />
      </div>
      <FormGroup className="mb-4" row>
        <Label
          htmlFor="projectname"
          className="col-form-label col-lg-2"
        >
          Quantity
        </Label>
        <Col lg="10">
          <InputNumber
            id="product-quantity"
            aria-label="Quantity"
            className="product__quantity"
            size="sm"
            min={1}
            value={quotequantity}
            onChange={this.handleChangequotequantinty}
          />
        </Col>
      </FormGroup>
      <FormGroup className="mb-4" row>
        <Label className="col-form-label col-lg-2">Date</Label>
        <Col lg="10">
          <Row>
            <Col md={6} className="pr-0">
              <DatePicker
                className="form-control"
                selected={this.state.startDate}
                onChange={this.startDateChange}
                minDate={subDays(new Date(), 0)}
              />
            </Col>
            <Col md={6} className="pl-0">
              {" "}
              <DatePicker
                className="form-control"
                selected={this.state.endDate}
                onChange={this.endDateChange}
                minDate={subDays(new Date(), 0)}
              />
            </Col>
          </Row>
        </Col>
      </FormGroup>
      <FormGroup className="mb-4" row>
        <Label
          htmlFor="projectdesc"
          className="col-form-label col-lg-2"
        >
          Description
        </Label>
        <Col lg="10">
          <textarea
            className="form-control"
            id="Quotedescription"
            rows="3"
            name="Quotedescription"
            value={this.state.Quotedescription}
            onChange={this.handleDiscription}
            placeholder="Enter Description"
          />
        </Col>
      </FormGroup>
      <FormGroup>
        <div className="float-right">
          <AsyncAction
            action={() =>
              QuoteAddItem(quote_details(), [], quotequantity)
            }
            render={({ run, loading }) => (
              <button
                type="button"
                data-toggle="tooltip"
                title="Request A Quote"
                onClick={run}
                className={classnames("btn btn-primary btn-md", {
                  "btn-loading": loading,
                })}
              >
                <span className="row">
                  <DollarSvg /> &nbsp;Request Quote
                </span>
              </button>
            )}
          />
        </div>
      </FormGroup>
    </Form>
  
    );
    {
      /* model for quotationstart here*/
    }
    quotemodal =(
      <>
        <div className="product__actions-item product__actions-item--compare">
          <button type="button"  
          data-toggle="modal" 
          title="Add to quote"
          className="btn btn-secondary"
          data-target="#othermodal">
          <DollarSvg />
        </button>
        </div>
        <div class="modal fade" 
        id="othermodal" 
        tabindex="-1" 
        role="dialog" 
        aria-labelledby="exampleModalLabel" 
        aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content container">
              <div className="modal-header">
                <h5
                  className="modal-title container "
                  id="exampleModalLongTitle"
                >
                 Tell us your requirement
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <br />
             {quoteForm}
            </div>
          </div>
        </div>
      </>
    );
    {
      /*model for quotation retails*/
    }
    return (
      <div>
        <div className="flex modal-body justify-center">
          <div className="text-center">
            {product.shop.outlet_name} , {product.shop.district}
          </div>
        </div>
        {
        //   product.Preference.length >= 1 && product.product.customUoM ? (
        //   <>
        //   {product.price_tag ? (
        //       <div>
        //         {quoteForm}
        //      </div>
        //     ) : (
        //   <form className="product__options">
        //     <div className="row">
        //       <div className="col">
        //         <div className="product__prices">
        //           {!product.price_tag ? (
        //             <>
        //               <small>{discounted}</small>
        //             </>
        //           ) : (
        //             <small></small>
        //           )}
        //         </div>
        //         {/*preferences data*/}
        //         {preferences}
        //         {/*end of preferences data*/}
        //       </div>
        //       <div className="col mt-3">
        //         <div className="card border-white">
        //           <ul className="list-group list-group-flush">
        //             <li className="list-group-item  border-white">
        //               <label>PRICE OPTIONS</label>
        //             </li>
        //             <li className="list-group-item  border-white">
        //               <label>
        //                 <span className="filter-list__input input-radio">
        //                   <span className="input-radio__body">
        //                     <input
        //                       className="input-radio__input"
        //                       type="radio"
        //                       name="options"
        //                       onClick={this.buyingHandle}
        //                       checked={buying_option === "retail"}
        //                       value="retail"
        //                     />
        //                     <span className="input-radio__circle" />
        //                   </span>
        //                 </span>
        //                 Retail
        //               </label>
        //             </li>
        //             <li className="list-group-item">
        //               <label>
        //                 <span className="filter-list__input input-radio">
        //                   <span className="input-radio__body">
        //                     <input
        //                       className="input-radio__input"
        //                       type="radio"
        //                       name="options"
        //                       value="wholesale"
        //                       checked={buying_option === "wholesale"}
        //                       onClick={this.buyingHandle}
        //                     />
        //                     <span className="input-radio__circle" />
        //                   </span>
        //                 </span>
        //                 Wholesale
        //               </label>
        //             </li>
        //           </ul>
        //         </div>
        //       </div>
        //     </div>
        //     <div className="form-group product__option">
        //       <div className="row">
        //         <div className="col product__actions">
        //           <div className="product__actions-item">
        //             <div className="row product__actions-item">
        //               <span>Custom Unit Measure</span>
        //               <InputNumber
        //                 id="product-quantity"
        //                 aria-label="Quantity"
        //                 className="product__quantity"
        //                 size="sm"
        //                 min={1}
        //                 value={unit}
        //                 onChange={this.handleChangeunit}
        //               />
        //               <span className="col">{product.product.unitMeasure}</span>
        //             </div>
        //           </div>
        //         </div>
        //         <div className="col product__actions">
        //           <div className="product__actions-item">
        //             <span>Quantity</span>
        //             {buying_option === "retail" ? (
        //               <InputNumber
        //                 id="product-quantity"
        //                 aria-label="Quantity"
        //                 className="product__quantity"
        //                 size="sm"
        //                 min={1}
        //                 value={quantity}
        //                 onChange={this.handleChangeQuantity}
        //               />
        //             ) : (
        //               <InputNumber
        //                 id="product-quantity"
        //                 aria-label="Quantity"
        //                 className="product__quantity"
        //                 size="sm"
        //                 min={quantitywholesale}
        //                 value={quantitywholesale}
        //                 onChange={this.handleChangeWholesaleQuantity}
        //               />
        //             )}
        //             {!product.price_tag ? (
        //               <>
        //                 <span>{Price_data}</span>
        //               </>
        //             ) : (
        //               <span></span>
        //             )}
        //           </div>
        //         </div>
        //         <span>
        //           {product.vat_check && !product.price_tag ? (
        //             <>vat inclusive</>
        //           ) : (
        //             <> vat exclusive</>
        //           )}
        //         </span>
        //       </div>
        //     </div>
        //     <div className="form-group product__option">
        //       <div className="product__actions mt-4">
        //         {buying_option === "retail" ? (
        //           <>
        //                 <div className="product__actions-item product__actions-item--addtocart">
        //                   <AsyncAction
        //                     action={() =>
        //                       ProductDetails(product_details(), quantity)
        //                     }
        //                     render={({ run, loading }) => (
        //                       <button
        //                         type="button"
        //                         onClick={run}
        //                         disabled={
        //                           !quantity ||
        //                           !this.state.preferenceValidation ||
        //                           !this.state.preferenceValidationsize
        //                         }
        //                         className={classnames(
        //                           "btn   btn-success btn-md",
        //                           {
        //                             "btn-loading": loading,
        //                           }
        //                         )}
        //                       >
        //                         Buy Now
        //                       </button>
        //                     )}
        //                   />
        //                 </div>
        //                 <div className="product__actions-item product__actions-item--addtocart">
        //                   <AsyncAction
        //                     action={() =>
        //                       cartAddItem(product_details(), [], quantity)
        //                     }
        //                     render={({ run, loading }) => (
        //                       <button
        //                         type="button"
        //                         onClick={run}
        //                         disabled={
        //                           !quantity ||
        //                           !this.state.preferenceValidation ||
        //                           !this.state.preferenceValidationsize
        //                         }
        //                         className={classnames(
        //                           "btn   btn-primary btn-md",
        //                           {
        //                             "btn-loading": loading,
        //                           }
        //                         )}
        //                       >
        //                         Add to cart
        //                       </button>
        //                     )}
        //                   />
        //                 </div>
        //                 <div className="product__actions-item product__actions-item--wishlist">
        //                   <AsyncAction
        //                     action={() => wishlistAddItem(product_details())}
        //                     render={({ run, loading }) => (
        //                       <button
        //                         type="button"
        //                         data-toggle="tooltip"
        //                         title="Wishlist"
        //                         onClick={run}
        //                         disabled={
        //                           !this.state.preferenceValidation ||
        //                           !this.state.preferenceValidationsize
        //                         }
        //                         className={classnames("btn btn-secondary", {
        //                           "btn-loading": loading,
        //                         })}
        //                       >
        //                         <i
        //                           className="fa fa-heart text-danger"
        //                           aria-hidden="true"
        //                         ></i>
        //                       </button>
        //                     )}
        //                   />
        //                 </div>
        //                {quotemodal}
        //           </>
        //         ) : (
        //           <>
        //                 <div className="product__actions-item product__actions-item--addtocart">
        //                   <AsyncAction
        //                     action={() =>
        //                       ProductDetails(
        //                         product_details(),
        //                         quantitywholesale
        //                       )
        //                     }
        //                     render={({ run, loading }) => (
        //                       <button
        //                         type="button"
        //                         onClick={run}
        //                         disabled={
        //                           !quantitywholesale ||
        //                           !this.state.preferenceValidation ||
        //                           !this.state.preferenceValidationsize
        //                         }
        //                         className={classnames(
        //                           "btn   btn-success btn-md",
        //                           {
        //                             "btn-loading": loading,
        //                           }
        //                         )}
        //                       >
        //                         Buy Now
        //                       </button>
        //                     )}
        //                   />
        //                 </div>
        //                 <div className="product__actions-item product__actions-item--addtocart">
        //                   <AsyncAction
        //                     action={() =>
        //                       cartAddItem(
        //                         product_details(),
        //                         [],
        //                         quantitywholesale
        //                       )
        //                     }
        //                     render={({ run, loading }) => (
        //                       <button
        //                         type="button"
        //                         onClick={run}
        //                         disabled={
        //                           !quantitywholesale ||
        //                           !this.state.preferenceValidation ||
        //                           !this.state.preferenceValidationsize
        //                         }
        //                         className={classnames(
        //                           "btn   btn-primary btn-md",
        //                           {
        //                             "btn-loading": loading,
        //                           }
        //                         )}
        //                       >
        //                         Add to cart
        //                       </button>
        //                     )}
        //                   />
        //                 </div>
        //                 <div className="product__actions-item product__actions-item--wishlist">
        //                   <AsyncAction
        //                     action={() => wishlistAddItem(product_details())}
        //                     render={({ run, loading }) => (
        //                       <button
        //                         type="button"
        //                         data-toggle="tooltip"
        //                         title="Wishlist"
        //                         onClick={run}
        //                         disabled={
        //                           !this.state.preferenceValidation ||
        //                           !this.state.preferenceValidationsize
        //                         }
        //                         className={classnames("btn btn-secondary", {
        //                           "btn-loading": loading,
        //                         })}
        //                       >
        //                         <i
        //                           className="fa fa-heart text-danger"
        //                           aria-hidden="true"
        //                         ></i>
        //                       </button>
        //                     )}
        //                   />
        //                 </div>
        //               {quotemodal}
        //           </>
        //         )}
        //       </div>
        //     </div>
        //   </form>
        //     )}
        //     </>
        // ) : product.Preference.length >= 1 && !product.product.customUoM ? (
        //   <>
        //   {product.price_tag ? (
        //       <div>
        //         {quoteForm}
        //       </div>
        //     ) : (
        //   <form className="product__options">
        //     <div className="row">
        //       <div className="col">
        //         <div className="product__prices">
        //           {!product.price_tag ? (
        //             <>
        //               <h5> {discounted}</h5>
        //             </>
        //           ) : (
        //             <h5></h5>
        //           )}
        //         </div>
        //         {/*preferences data*/}
        //         {preferences}
        //         {/*end of preferences data*/}
        //       </div>
        //       <div className="col mt-3">
        //         <div className="card border-white">
        //           <ul className="list-group list-group-flush">
        //             <li className="list-group-item  border-white">
        //               <h6>
        //                 <small>PRICE OPTIONS </small>
        //               </h6>
        //             </li>
        //             <li className="list-group-item  border-white">
        //               <label>
        //                 <span className="filter-list__input input-radio">
        //                   <span className="input-radio__body">
        //                     <input
        //                       className="input-radio__input"
        //                       type="radio"
        //                       name="options"
        //                       onClick={this.buyingHandle}
        //                       checked={buying_option === "retail"}
        //                       value="retail"
        //                     />
        //                     <span className="input-radio__circle" />
        //                   </span>
        //                 </span>
        //                 Retail
        //               </label>
        //             </li>
        //             <li className="list-group-item">
        //               <label>
        //                 <span className="filter-list__input input-radio">
        //                   <span className="input-radio__body">
        //                     <input
        //                       className="input-radio__input"
        //                       type="radio"
        //                       name="options"
        //                       value="wholesale"
        //                       checked={buying_option === "wholesale"}
        //                       onClick={this.buyingHandle}
        //                     />
        //                     <span className="input-radio__circle" />
        //                   </span>
        //                 </span>
        //                 Wholesale
        //               </label>
        //             </li>
        //           </ul>
        //         </div>
        //       </div>
        //     </div>
        //     <div className="row mt-2 container form-group product__option">
        //       <div className="product__actions">
        //         <span className="text-uppercase">Quantity</span>
        //         <div className="product__actions-item">
        //           {buying_option === "retail" ? (
        //             <InputNumber
        //               id="product-quantity"
        //               aria-label="Quantity"
        //               className="product__quantity"
        //               size="sm"
        //               min={1}
        //               value={quantity}
        //               onChange={this.handleChangeQuantity}
        //             />
        //           ) : (
        //             <InputNumber
        //               id="product-quantity"
        //               aria-label="Quantity"
        //               className="product__quantity"
        //               size="sm"
        //               min={quantitywholesale}
        //               value={quantitywholesale}
        //               onChange={this.handleChangeWholesaleQuantity}
        //             />
        //           )}
        //         </div>
        //         {!product.price_tag ? (
        //           <>
        //             <span className="text-uppercase">{Price_data}</span>
        //           </>
        //         ) : (
        //           <span className="text-uppercase"></span>
        //         )}
        //       </div>
        //       <span>
        //         {product.vat_check && !product.price_tag ? (
        //           <>vat inclusive</>
        //         ) : (
        //           <> vat exclusive</>
        //         )}
        //       </span>
        //     </div>
        //     <div className="form-group product__option">
        //       <div className="product__actions mt-4">
        //         {buying_option === "retail" ? (
        //           <>
        //
        //                 <div className="product__actions-item product__actions-item--addtocart">
        //                   <AsyncAction
        //                     action={() =>
        //                       ProductDetails(product_details(), quantity)
        //                     }
        //                     render={({ run, loading }) => (
        //                       <button
        //                         type="button"
        //                         onClick={run}
        //                         disabled={
        //                           !quantity || !this.state.preferenceValidation
        //                         }
        //                         className={classnames(
        //                           "btn   btn-success btn-md",
        //                           {
        //                             "btn-loading": loading,
        //                           }
        //                         )}
        //                       >
        //                         Buy Now
        //                       </button>
        //                     )}
        //                   />
        //                 </div>
        //                 <div className="product__actions-item product__actions-item--addtocart">
        //                   <AsyncAction
        //                     action={() =>
        //                       cartAddItem(product_details(), [], quantity)
        //                     }
        //                     render={({ run, loading }) => (
        //                       <button
        //                         type="button"
        //                         onClick={run}
        //                         disabled={
        //                           !quantity || !this.state.preferenceValidation
        //                         }
        //                         className={classnames(
        //                           "btn   btn-primary btn-md",
        //                           {
        //                             "btn-loading": loading,
        //                           }
        //                         )}
        //                       >
        //                         Add to cart
        //                       </button>
        //                     )}
        //                   />
        //                 </div>
        //                 <div className="product__actions-item product__actions-item--wishlist">
        //                   <AsyncAction
        //                     action={() => wishlistAddItem(product_details())}
        //                     render={({ run, loading }) => (
        //                       <button
        //                         type="button"
        //                         data-toggle="tooltip"
        //                         title="Wishlist"
        //                         onClick={run}
        //                         disabled={
        //                           !this.state.preferenceValidation ||
        //                           !this.state.preferenceValidationsize
        //                         }
        //                         className={classnames("btn btn-secondary", {
        //                           "btn-loading": loading,
        //                         })}
        //                       >
        //                         <i
        //                           className="fa fa-heart text-danger"
        //                           aria-hidden="true"
        //                         ></i>
        //                       </button>
        //                     )}
        //                   />
        //                 </div>
        //              {quotemodal}
        //           </>
        //         ) : (
        //           <>
        //                 <div className="product__actions-item product__actions-item--addtocart">
        //                   <AsyncAction
        //                     action={() =>
        //                       ProductDetails(
        //                         product_details(),
        //                         quantitywholesale
        //                       )
        //                     }
        //                     render={({ run, loading }) => (
        //                       <button
        //                         type="button"
        //                         onClick={run}
        //                         disabled={
        //                           !quantitywholesale ||
        //                           !this.state.preferenceValidation
        //                         }
        //                         className={classnames(
        //                           "btn   btn-success btn-md",
        //                           {
        //                             "btn-loading": loading,
        //                           }
        //                         )}
        //                       >
        //                         Buy Now
        //                       </button>
        //                     )}
        //                   />
        //                 </div>
        //                 <div className="product__actions-item product__actions-item--addtocart">
        //                   <AsyncAction
        //                     action={() =>
        //                       cartAddItem(
        //                         product_details(),
        //                         [],
        //                         quantitywholesale
        //                       )
        //                     }
        //                     render={({ run, loading }) => (
        //                       <button
        //                         type="button"
        //                         onClick={run}
        //                         disabled={
        //                           !quantitywholesale ||
        //                           !this.state.preferenceValidation
        //                         }
        //                         className={classnames(
        //                           "btn   btn-primary btn-md",
        //                           {
        //                             "btn-loading": loading,
        //                           }
        //                         )}
        //                       >
        //                         Add to cart
        //                       </button>
        //                     )}
        //                   />
        //                 </div>
        //                 <div className="product__actions-item product__actions-item--wishlist">
        //                   <AsyncAction
        //                     action={() => wishlistAddItem(product_details())}
        //                     render={({ run, loading }) => (
        //                       <button
        //                         type="button"
        //                         data-toggle="tooltip"
        //                         title="Wishlist"
        //                         onClick={run}
        //                         className={classnames("btn btn-secondary", {
        //                           "btn-loading": loading,
        //                         })}
        //                       >
        //                         <i
        //                           className="fa fa-heart text-danger"
        //                           aria-hidden="true"
        //                         ></i>
        //                       </button>
        //                     )}
        //                   />
        //                 </div>
        //                {quotemodal}
        //           </>
        //         )}
        //       </div>
        //     </div>
        //   </form>
        //     )}
        //     </>
        // ) :
              product.product.customUoM ? (
          <>
            {Products().price_tag ? (
                <div>
                  {quoteForm}
                </div>
              ) : (
          <form className="product__options">
            <div className="row">
              <div className="col">
                <div className="product__prices">
                  {!product.price_tag ? (
                    <>
                      <small>{discounted}</small>
                    </>
                  ) : (
                    <small></small>
                  )}
                </div>
                <div className="product__actions">
                  <div className="product__actions-item">
                    <div className="row product__actions-item">
                      <span>Custom unit of measure</span>
                      <InputNumber
                        id="product-quantity"
                        aria-label="Quantity"
                        className="product__quantity"
                        size="sm"
                        min={1}
                        value={unit}
                        onChange={this.handleChangeunit}
                      />
                      <span className="col">{product.product.unitMeasure}</span>
                    </div>
                  </div>
                </div>
                <div className="product__actions">
                  <div className="product__actions-item">
                    <span>Quantity</span>
                    {buying_option === "retail" ? (
                      <InputNumber
                        id="product-quantity"
                        aria-label="Quantity"
                        className="product__quantity"
                        size="sm"
                        min={1}
                        value={quantity}
                        onChange={this.handleChangeQuantity}
                      />
                    ) : (
                      <InputNumber
                        id="product-quantity"
                        aria-label="Quantity"
                        className="product__quantity"
                        size="sm"
                        min={quantitywholesale}
                        value={quantitywholesale}
                        onChange={this.handleChangeWholesaleQuantity}
                      />
                    )}
                    {!Products().price_tag ? (
                      <>
                        <span>{Price_data}</span>
                      </>
                    ) : (
                      <span></span>
                    )}
                  </div>
                </div>
                <span>
                  {Products().vat_check && !Products().price_tag ? (
                    <>vat inclusive</>
                  ) : (
                    <> vat exclusive</>
                  )}
                </span>
              </div>
              <div className="col mt-3">
                <div className="card border-white">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item  border-white">
                      <label>PRICE OPTIONS</label>
                    </li>
                    <li className="list-group-item  border-white">
                      <label>
                        <span className="filter-list__input input-radio">
                          <span className="input-radio__body">
                            <input
                              className="input-radio__input"
                              type="radio"
                              name="options"
                              onClick={this.buyingHandle}
                              checked={buying_option === "retail"}
                              value="retail"
                            />
                            <span className="input-radio__circle" />
                          </span>
                        </span>
                        Retail
                      </label>
                    </li>
                    <li className="list-group-item">
                      <label>
                        <span className="filter-list__input input-radio">
                          <span className="input-radio__body">
                            <input
                              className="input-radio__input"
                              type="radio"
                              name="options"
                              value="wholesale"
                              checked={buying_option === "wholesale"}
                              onClick={this.buyingHandle}
                            />
                            <span className="input-radio__circle" />
                          </span>
                        </span>
                        Wholesale
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
           
            <div className="form-group product__option">
              <div className="product__actions mt-4">
                {buying_option === "retail" ? (
                  <> {customertoken() || agentcustomerToken()?(
                    <div className="product__actions-item product__actions-item--addtocart">
                    <AsyncAction
                      action={() =>
                        ProductDetails(product_details(), quantity)
                      }
                      render={({ run, loading }) => (
                        <button
                          type="button"
                          onClick={run}
                          disabled={!quantity}
                          className={classnames(
                            "btn   btn-success btn-md",
                            {
                              "btn-loading": loading,
                            }
                          )}
                        >
                          Buy Now
                        </button>
                      )}
                    />
                  </div>
                 
                 

                     ):(
                         <>
                           {Loginmodal}
                         </>
                     )

                     }
                       <div className="product__actions-item product__actions-item--addtocart">
                          <AsyncAction
                            action={() =>
                              cartAddItem(product_details(), [], quantity)
                            }
                            render={({ run, loading }) => (
                              <button
                                type="button"
                                onClick={run}
                                disabled={!quantity}
                                className={classnames(
                                  "btn   btn-primary btn-md",
                                  {
                                    "btn-loading": loading,
                                  }
                                )}
                              >
                                Add to cart
                              </button>
                            )}
                          />
                        </div>
                        <div className="product__actions-item product__actions-item--wishlist">
                          <AsyncAction
                            action={() => wishlistAddItem(product_details())}
                            render={({ run, loading }) => (
                              <button
                                type="button"
                                data-toggle="tooltip"
                                title="Wishlist"
                                onClick={run}
                                className={classnames("btn btn-secondary", {
                                  "btn-loading": loading,
                                })}
                              >
                                <i
                                  className="fa fa-heart text-danger"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            )}
                          />
                        </div>
                       {quotemodal}
                  </>
                ) : (
                  <>
                  {customertoken() || agentcustomerToken()?(
                         <div className="product__actions-item product__actions-item--addtocart">
                         <AsyncAction
                           action={() =>
                             ProductDetails(
                               product_details(),
                               [],
                               quantitywholesale
                             )
                           }
                           render={({ run, loading }) => (
                             <button
                               type="button"
                               onClick={run}
                               disabled={!quantitywholesale}
                               className={classnames(
                                 "btn   btn-success btn-md",
                                 {
                                   "btn-loading": loading,
                                 }
                               )}
                             >
                               Buy Now
                             </button>
                           )}
                         />
                       </div>
                      
 
                          ):(
                              <>
                                {Loginmodal}
                              </>
                          )

                          }
                       <div className="product__actions-item product__actions-item--addtocart">
                          <AsyncAction
                            action={() =>
                              cartAddItem(
                                product_details(),
                                [],
                                quantitywholesale
                              )
                            }
                            render={({ run, loading }) => (
                              <button
                                type="button"
                                onClick={run}
                                disabled={!quantitywholesale}
                                className={classnames(
                                  "btn   btn-primary btn-md",
                                  {
                                    "btn-loading": loading,
                                  }
                                )}
                              >
                                Add to cart
                              </button>
                            )}
                          />
                        </div>
                        <div className="product__actions-item product__actions-item--wishlist">
                          <AsyncAction
                            action={() => wishlistAddItem(product_details())}
                            render={({ run, loading }) => (
                              <button
                                type="button"
                                data-toggle="tooltip"
                                title="Wishlist"
                                onClick={run}
                                className={classnames("btn btn-secondary", {
                                  "btn-loading": loading,
                                })}
                              >
                                <i
                                  className="fa fa-heart text-danger"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            )}
                          />
                        </div>
                      {quotemodal}
                  </>
                )}
                <div
                  className="modal fade"
                  id="Modalgroupbuy"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLongTitle"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5
                          className="modal-title container text-center"
                          id="exampleModalLongTitle"
                        >
                          SELECT GROUP
                        </h5>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <Scrollbars style={{ height: 300 }}>
                          <ul class="list-group">
                            <li class="list-group-item list-group-item-action">
                              <span className="row">
                                <span className="col">Vijana Group</span>
                                <span className="col">
                                  <Link
                                    target="_blank"
                                    to="/shop/GroupWishlist"
                                    className="btn-sm rounded-lg"
                                  >
                                    Add To this Group
                                  </Link>
                                </span>
                              </span>
                            </li>
                            <li class="list-group-item list-group-item-action">
                              <span className="row">
                                <span className="col">Kupatana Group</span>
                                <span className="col">
                                  <Link
                                    target="_blank"
                                    to="/shop/GroupWishlist"
                                    className="btn-sm rounded-lg"
                                  >
                                    Add To this Group
                                  </Link>
                                </span>
                              </span>
                            </li>
                            <li class="list-group-item list-group-item-action">
                              <span className="row">
                                <span className="col">TYD Group</span>
                                <span className="col">
                                  <Link
                                    target="_blank"
                                    to="/shop/GroupWishlist"
                                    className="btn-sm rounded-lg"
                                  >
                                    Add To this Group
                                  </Link>
                                </span>
                              </span>
                            </li>
                            <li class="list-group-item list-group-item-action">
                              <span className="row">
                                <span className="col">Wajanja Group</span>
                                <span className="col">
                                  <Link
                                    target="_blank"
                                    to="/shop/GroupWishlist"
                                    className="btn-sm rounded-lg"
                                  >
                                    Add To this Group
                                  </Link>
                                </span>
                              </span>
                            </li>
                            <li class="list-group-item list-group-item-action">
                              <span className="row">
                                <span className="col">Care Group</span>
                                <span className="col">
                                  <Link
                                    target="_blank"
                                    to="/shop/GroupWishlist"
                                    className="btn-sm rounded-lg"
                                  >
                                    Add To this Group
                                  </Link>
                                </span>
                              </span>
                            </li>
                            <li class="list-group-item list-group-item-action">
                              <span className="row">
                                <span className="col">Cras justo odio</span>
                                <span className="col">
                                  <Link
                                    target="_blank"
                                    to="/shop/GroupWishlist"
                                    className="btn-sm rounded-lg"
                                  >
                                    Add To this Group
                                  </Link>
                                </span>
                              </span>
                            </li>
                            <li class="list-group-item list-group-item-action">
                              <span className="row">
                                <span className="col">Care Group</span>
                                <span className="col">
                                  <Link
                                    target="_blank"
                                    to="/shop/GroupWishlist"
                                    className="btn-sm rounded-lg"
                                  >
                                    Add To this Group
                                  </Link>
                                </span>
                              </span>
                            </li>
                            <li class="list-group-item list-group-item-action">
                              <span className="row">
                                <span className="col">Care Group</span>
                                <span className="col">
                                  <Link
                                    target="_blank"
                                    to="/shop/GroupWishlist"
                                    className="btn-sm rounded-lg"
                                  >
                                    Add To this Group
                                  </Link>
                                </span>
                              </span>
                            </li>
                          </ul>
                        </Scrollbars>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-secondary"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                        {/* <button type="button" class="btn btn-primary">
        Save changes
      </button> */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* popup model for group buy option start here  */}
              </div>
            </div>
          </form>
              )}
              </>
       ) : (
          <>
            {Products().price_tag ? (
                <div>
                  {quoteForm}
                </div>
              ) : (
          <form className="product__options container">
            <div className="row container">
              <div className="col container">
                <div className="product__prices">
                  {!Products().price_tag ? <>{discounted}</> : <></>}
                </div>
                <span>Quantity</span>
                <div className="row mt-2  form-group product__option">
                  <div className="product__actions">
                    <div className="product__actions-item">
                      {buying_option === "retail" ? (
                        <InputNumber
                          id="product-quantity"
                          aria-label="Quantity"
                          className="product__quantity"
                          size="sm"
                          min={1}
                          value={quantity}
                          onChange={this.handleChangeQuantity}
                        />
                      ) : (
                        <InputNumber
                          id="product-quantity"
                          aria-label="Quantity"
                          className="product__quantity"
                          size="sm"
                          min={quantitywholesale}
                          value={quantitywholesale}
                          onChange={this.handleChangeWholesaleQuantity}
                        />
                      )}
                    </div>
                    {!Products().price_tag ? (
                      <>
                        <span className="text-uppercase">{Price_data}</span>
                      </>
                    ) : (
                      <span className="text-uppercase"></span>
                    )}
                  </div>
                  <span className="mt-3">
                    {Products().vat_check && !Products().price_tag ? (
                      <>vat inclusive</>
                    ) : (
                      <> vat exclusive</>
                    )}
                  </span>
                </div>
              </div>
              <div className="col mt-3">
                <div className="card border-white">
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item  border-white">
                      <label>PRICE OPTIONS</label>
                    </li>
                    <li className="list-group-item  border-white">
                      <label>
                        <span className="filter-list__input input-radio">
                          <span className="input-radio__body">
                            <input
                              className="input-radio__input"
                              type="radio"
                              name="options"
                              onClick={this.buyingHandle}
                              checked={buying_option === "retail"}
                              value="retail"
                            />
                            <span className="input-radio__circle" />
                          </span>
                        </span>
                        Retail
                      </label>
                    </li>
                    <li className="list-group-item">
                      <label>
                        <span className="filter-list__input input-radio">
                          <span className="input-radio__body">
                            <input
                              className="input-radio__input"
                              type="radio"
                              name="options"
                              value="wholesale"
                              checked={buying_option === "wholesale"}
                              onClick={this.buyingHandle}
                            />
                            <span className="input-radio__circle" />
                          </span>
                        </span>
                        Wholesale
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center form-group product__option">
              <div className="product__actions mt-4">
                {buying_option === "retail" ? (
                  <>                    
                    {customertoken() || agentcustomerToken()?(
                         <div className="product__actions-item product__actions-item--addtocart">
                         <AsyncAction
                           action={() =>
                             ProductDetails(product_details(), quantity)
                           }
                           render={({ run, loading }) => (
                             <button
                               type="button"
                               onClick={run}
                               disabled={!quantity}
                               className={classnames(
                                 "btn   btn-success btn-md",
                                 {
                                   "btn-loading": loading,
                                 }
                               )}
                             >
                               Buy Now
                             </button>
                           )}
                         />
                       </div>
                      
                          ):(
                              <>
                                {Loginmodal}
                              </>
                          ) }
                       <div className="product__actions-item product__actions-item--addtocart">
                          <AsyncAction
                            action={() =>
                              cartAddItem(product_details(), [], quantity)
                            }
                            render={({ run, loading }) => (
                              <button
                                type="button"
                                onClick={run}
                                disabled={!quantity}
                                className={classnames(
                                  "btn   btn-primary btn-md",
                                  {
                                    "btn-loading": loading,
                                  }
                                )}
                              >
                                Add to cart
                              </button>
                            )}
                          />
                        </div>
                        <div className="product__actions-item product__actions-item--wishlist">
                          <AsyncAction
                            action={() => wishlistAddItem(product_details())}
                            render={({ run, loading }) => (
                              <button
                                type="button"
                                data-toggle="tooltip"
                                title="Wishlist"
                                onClick={run}
                                className={classnames("btn btn-secondary", {
                                  "btn-loading": loading,
                                })}
                              >
                                <i
                                  className="fa fa-heart text-danger"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            )}
                          />
                        </div>
                         {quotemodal}
                      </>
                   
                ) : (
                  <>
                    {customertoken() || agentcustomerToken()?(
                             <div className="product__actions-item product__actions-item--addtocart">
                             <AsyncAction
                               action={() =>
                                 ProductDetails(
                                   product_details(),
                                   [],
                                   quantitywholesale
                                 )
                               }
                               render={({ run, loading }) => (
                                 <button
                                   type="button"
                                   onClick={run}
                                   disabled={!quantitywholesale}
                                   className={classnames(
                                     "btn   btn-success btn-md",
                                     {
                                       "btn-loading": loading,
                                     }
                                   )}
                                 >
                                   Buy Now
                                 </button>
                               )}
                             />
                           </div>
                         
                          ):(
                              <>
                                {Loginmodal}
                              </>
                          )}
                       <div className="product__actions-item product__actions-item--addtocart">
                          <AsyncAction
                            action={() =>
                              cartAddItem(
                                product_details(),
                                [],
                                quantitywholesale
                              )
                            }
                            render={({ run, loading }) => (
                              <button
                                type="button"
                                onClick={run}
                                disabled={!quantitywholesale}
                                className={classnames(
                                  "btn   btn-primary btn-md",
                                  {
                                    "btn-loading": loading,
                                  }
                                )}
                              >
                                Add to cart
                              </button>
                            )}
                          />
                        </div>
                        <div className="product__actions-item product__actions-item--wishlist">
                          <AsyncAction
                            action={() => wishlistAddItem(product_details())}
                            render={({ run, loading }) => (
                              <button
                                type="button"
                                data-toggle="tooltip"
                                title="Wishlist"
                                onClick={run}
                                className={classnames("btn btn-secondary", {
                                  "btn-loading": loading,
                                })}
                              >
                                <i
                                  className="fa fa-heart text-danger"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            )}
                          />
                        </div>
                         {quotemodal}
                  </>
                )}
              </div>
            </div>
          </form>
              )}
         </>
        )}
        <div className=" form-group product__option">
          <div className="mb-1 d-flex justify-content-center product__actions"></div>
        </div>

        <hr />
      </div>
    );
  }
}
const mapDispatchToProps = {
  cartAddItem,
  wishlistAddItem,
  compareAddItem,
  QuoteAddItem,
  ProductDetails,
};

export default connect(() => ({}), mapDispatchToProps)(Othershop);
