import {DELETE_LISTING} from "./DeleteListingActionTypes";

export default function  DeleteListingReducer(state=[],action) {
    switch(action.type){
        case DELETE_LISTING:
            return action.payload
        default:
            return state;
    }
}
