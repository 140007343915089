// react
import React, { useState, useEffect } from "react";
import axios from "axios";
// third-party
import PropTypes from "prop-types";
import { connect } from "react-redux";

// application
import languages from "../../i18n";
import MegamenuLinks from "./MegamenuLinks";
// import { megaData} from "../../api/shop";
function Megamenu(props) {
  const [Category, setData] = useState([]);
  // useEffect(() => {
  //    const result= fetch(window.$name + )
  //     .then((response) => response.data());

  //   }, []);

  useEffect(() => {
    const getData = () => {
      axios
        .get(window.$name + "/api/adminstrator/get-all-categories")
        .then((response) => response.data)
        .then((json) => {
          setData(json);
        });
    };
    getData();
  }, []);
  const { menu, location, locale } = props;
  // const { direction } = languages[locale];
  if (!menu) {
    return [];
  }

  console.log("kaondo", Category);
  const columns = Category.map((column, index) => (
    <div className="col-4">
    <MegamenuLinks links={Category} />
  </div>
  ));
  return (
    <div className={`megamenu megamenu--${location}`}>
      <div className="row">
       {columns}
      </div>
    </div>
  );
}

Megamenu.propTypes = {
  /** menu object (required) */
  menu: PropTypes.object,
  /** one of ['nav-links', 'department'] (default: 'nav-links') */
  location: PropTypes.oneOf(["nav-links", "department"]),
  /** current locale */
  locale: PropTypes.string,
};

Megamenu.defaultProps = {
  location: "nav-links",
};

const mapStateToProps = (state) => ({
  locale: state.locale,
});

export default connect(mapStateToProps)(Megamenu);
