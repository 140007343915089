import React, {useEffect} from 'react'
import '../../assets/css/style.css'
import BannerOne from './banner1/BannerOne'
import SectionsHeading from "./SectionHeading";
import Listings from "./Listings";
import Axios from "axios";
import {Spinner} from "reactstrap";
import PopularCategories from "./popularCategory";
import {Category} from "@material-ui/icons";

const Home = () => {
    const [ListingsData, setListings] = React.useState([]);
    useEffect(() => {
        const dataAvailable = async () => {
            const data = await Axios.get(`${window.$name}/api/directory/home`)
                .then((response) => response.data);
            setListings(data);
        };
        dataAvailable();
    }, []);
    // console.log(ListingsData)
    return (
        <main className="home-1">
            {ListingsData.length <= 0 ?
                <div className="text-center" style={{
                    marginTop: '10%',
                    marginLeft: '50%',
                    marginRight: '50%'
                }}>
                    <Spinner style={{width: '3rem', height: '3rem'}}/>
                </div> :
                <>
                    {/* Hero Banner */}
                    <BannerOne Category={ListingsData.listing_category} promoted={ListingsData.promoted_listings}/>

                    <section className="cat-area">
                        <div className="container">
                            <br />
                            <div className="row section-title-width">
                                <SectionsHeading title="Available Listings" desc=""/>
                            </div>
                            <div className="row">
                                <Listings data={ListingsData.listings}/>
                            </div>
                        </div>
                    </section>
                    {/* Popular Categories */}
                    <section className="cat-area">
                        <div className="container">
                            <div className="row section-title-width text-center">
                                <SectionsHeading title="Most Popular Categories" desc="" />
                            </div>
                            <div className="row mt-4">
                                <PopularCategories Category={ListingsData.listing_category}/>
                            </div>
                        </div>
                    </section>
                </>
            }
            </main>
    )
}

export default Home;
