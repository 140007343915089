// react
import React, {useEffect, useState} from 'react';
// third-party
import PropTypes from 'prop-types';
import {connect, useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
// application
import CartIndicator from './IndicatorCart';
import DigitalCartIndicator from './IndicatorDigitalCart';
import QuoteIndicator from './indicatorQuote';
import Departments from './Departments';
import Indicator from './Indicator';
import IndicatorAccount from './IndicatorAccount';
import IndicatorSearch from './IndicatorSearch';
import NavLinks from './NavLinks';
import {Heart20Svg, LogoSmallSvg} from '../../svg';
import {customertoken, agentcustomerToken} from "../../api/shop";
import {WishlistDataFromDb} from "../../store/wishlist";
import {FetchShippingAddress} from "../../store/shippingAddress";

import IndicatorWishlist from "./IndicatorWishlist";
import IndicatorDigitalWishlist from "./IndicatorDigitalWishlist";
import {HiOutlineBookmark} from "react-icons/all";
import {FetchBookmark} from "../../store/bookMark";


function NavPanel(props) {
    const {layout, wishlist, bookmark} = props;
    const dispatch = useDispatch();
    useEffect(() => {
        if (customertoken() || agentcustomerToken()) {
            dispatch(WishlistDataFromDb());
            // dispatch(FetchBookmark())
            dispatch(FetchShippingAddress());
        }

    }, []);

    let logo = null;
    let departments = null;
    let searchIndicator;
    if (layout === 'compact') {
        logo = (
            <div className="nav-panel__logo">
                <Link to="/"><LogoSmallSvg/></Link>
            </div>
        );
        searchIndicator = <IndicatorSearch/>;
    }
    if (layout === 'default') {
        departments = (
            <div className="nav-panel__departments">
                <Departments/>
            </div>
        );
    }
    return (
        <div className="nav-panel">
            <div className="nav-panel__container container">
                <div className="nav-panel__row ">
                    {logo}
                    {departments}
                    <div className="nav-panel__nav-links nav-links">
                        <NavLinks/>
                    </div>
                    {window.location.pathname === '/digitalHome'
                    || window.location.pathname === '/digitalOrder/comfirm'
                    || window.location.pathname === '/digital/wishlist'
                        ?
                        <div className="nav-panel__indicators">
                            {searchIndicator}
                            <IndicatorDigitalWishlist/>
                            <DigitalCartIndicator/>
                            <IndicatorAccount/>
                        </div>
                        :
                        <div className="nav-panel__indicators">
                            {searchIndicator}
                            <IndicatorWishlist/>
                                    {/*<Indicator url="/account/my_bookmark" value={bookmark.length}*/}
                                    {/*           icon={<HiOutlineBookmark/>}/>*/}
                            <CartIndicator/>
                            <QuoteIndicator/>
                            <IndicatorAccount/>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

NavPanel.propTypes = {
    layout: PropTypes.oneOf(['default', 'compact']),
};

NavPanel.defaultProps = {
    layout: 'default',
};

const mapStateToProps = (state) => ({
    wishlist: state.wishlist,
    // bookmark: state.bookmark,
});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(NavPanel);
