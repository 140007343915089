// react
import React from "react";
import {Helmet} from "react-helmet-async";
import {useFormik} from "formik";
import * as yup from "yup";
import theme from "../../data/theme";
import AsyncAction from "../shared/AsyncAction";
import {connect} from "react-redux";
import {ChangePassword} from "../../store/auth";
import {useHistory} from "react-router";

//creating the validation schema
const validationSchema = yup.object({
    currentPassword: yup
        .string()
        .required("A Current Password is required")
        .min(6, "Current Password must be at least 6 characters"),
    NewPassword: yup
        .string()
        .required("Please enter your New password")
        .matches(
            /^.*(?=.{6,}).*$/,
            "Password must contain at least 6 characters"
        ),
    confirmPassword: yup
        .string()
        .required("Please confirm your New password")
        .when("NewPassword", {
            is:  NewPassword => ( NewPassword &&  NewPassword.length > 0 ? true : false),
            then: yup.string().oneOf([yup.ref(" NewPassword")], "Password doesn't match")
        })
});
function AccountPagePassword(props) {
const history=useHistory()
//using useFormik
    const formik = useFormik({
        initialValues:{
            currentPassword:"",
            NewPassword:"",
            confirmPassword:"",
        },
        validationSchema,

    });
    const {ChangePassword}=props
    return (

        <div className="card shadow-lg border-none ">
            <Helmet>
                <title>{`Change Password — ${theme.name}`}</title>
            </Helmet>
            <div className="card-header">
                <h5>Change Password </h5>
            </div>
            <div className="card-divider"/>
            <div className="card-body ">
                <div className="row no-gutters">
                    <div className="col-12">
                        <form >
                            <div className="form-group">
                                <label htmlFor="password-current">Current Password</label>
                                <input


                                    type="password"
                                    onChange={formik.handleChange}
                                    name="currentPassword"
                                    className="form-control"
                                    id="currentPassword"
                                    value={formik.values.currentPassword}
                                    onBlur={formik.handleBlur}
                                    placeholder="Current Password"
                                />
                                {formik.touched.currentPassword && formik.errors.currentPassword ? (
                                    <div className="text-danger">{formik.errors.currentPassword}</div>
                                ) : null}
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="password-new">New Password</label>
                                    <input
                                        value={formik.values.NewPassword}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type="password"
                                        name="NewPassword"
                                        className="form-control"
                                        id="password-new"
                                        placeholder="New Password"
                                    />
                                    {formik.touched.NewPassword && formik.errors.NewPassword ? (
                                        <div className="text-danger">{formik.errors.NewPassword}</div>
                                    ) : null}
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="password-confirm">Reenter New Password</label>
                                    <input
                                        value={formik.values.confirmPassword}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        type="password"
                                        name="confirmPassword"
                                        className="form-control"
                                        id="password-confirm"
                                        placeholder="Reenter New Password"

                                    />
                                    {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                                        <div className="text-danger">{formik.errors.confirmPassword}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="form-group">
                                <AsyncAction
                                    action={() =>ChangePassword(formik.values,history) }
                                    render={({run, loading}) => (
                                        <button
                                            type="button"
                                            onClick={run}
                                            className={"btn btn-block btn-primary rounded "}
                                            disabled={!(formik.isValid && formik.dirty)}
                                        >
                                            Update Password
                                        </button>
                                    )}
                                />

                            </div>
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );
}
const mapStateToProps = (state) => ({

});
const mapDispatchToProps = {
ChangePassword,
};
export default connect(mapStateToProps,mapDispatchToProps)(AccountPagePassword);
