// react
import React, { useEffect, useReducer, useState } from "react";
// third-party
import PropTypes from "prop-types";
import queryString from "query-string";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
// application
import BlockLoader from "../blocks/BlockLoader";
import CategorySidebar from "./CategorySidebar";
import CategorySidebarItem from "./CategorySidebarItem";
import PageHeader from "../shared/PageHeader";
import ProductsView from "./ProductsView";
import WidgetFilters from "../widgets/OutletWidgetFilters";
// import WidgetProducts from "../widgets/WidgetProducts";
import { sidebarClose } from "../../store/sidebar";
// data stubs
import theme from "../../data/theme";
import { UncontrolledCarousel } from 'reactstrap';
import { url, getCategoryParents } from "../../services/utils";
import BlockCategories from "../blocks/BlockCategories";
import outletApi from "../../api/outletApi";
import { useParams } from 'react-router-dom';
import BlockOutletBanner from "../blocks/BlockOutletBanner";
function parseQueryOptions(location) {
    const query = queryString.parse(location);
    const optionValues = {};
    if (typeof query.page === "string") {
        optionValues.page = parseFloat(query.page);
    }
    if (typeof query.limit === "string") {
        optionValues.limit = parseFloat(query.limit);
    }
    if (typeof query.sort === "string") {
        optionValues.sort = query.sort;
    }

    return optionValues;
}

function parseQueryFilters(location) {
    const query = queryString.parse(location);
    const filterValues = {};

    Object.keys(query).forEach((param) => {
        const mr = param.match(/^filter_([-_A-Za-z0-9]+)$/);

        if (!mr) {
            return;
        }

        const filterSlug = mr[1];

        filterValues[filterSlug] = query[param];
    });

    return filterValues;
}

function parseQuery(location) {
    return [parseQueryOptions(location), parseQueryFilters(location)];
}

function buildQuery(options, filters) {
    const params = {};

    if (options.page !== 1) {
        params.page = options.page;
    }

    if (options.limit !== 12) {
        params.limit = options.limit;
    }

    if (options.sort !== "default") {
        params.sort = options.sort;
    }

    Object.keys(filters)
        .filter((x) => x !== "category" && !!filters[x])
        .forEach((filterSlug) => {
            params[`filter_${filterSlug}`] = filters[filterSlug];
        });

    return queryString.stringify(params, { encode: false });
}

const initialState = {
    init: false,
    /**
     * Indicates that the category is loading.
     */
    categoryIsLoading: true,
    /**
     * Category object.
     */
    category: null,
    /**
     * Indicates that the products list is loading.
     */
    productsListIsLoading: true,
    /**
     * Products list.
     */
    productsList: null,
    /**
     * Products list options.
     *
     * options.page:  number - Current page.
     * options.limit: number - Items per page.
     * options.sort:  string - Sort algorithm.
     */
    options: {},
    /**
     * Products list filters.
     *
     * filters[FILTER_SLUG]: string - filter value.
     */
    filters: {},
};

function reducer(state, action) {
    switch (action.type) {
        case "FETCH_CATEGORY_SUCCESS":
            return {
                ...state,
                init: true,
                categoryIsLoading: false,
                category: action.category,
            };
        case "FETCH_PRODUCTS_LIST":
            return { ...state, productsListIsLoading: true };
        case "FETCH_PRODUCTS_LIST_SUCCESS":
            return {
                ...state,
                productsListIsLoading: false,
                productsList: action.productsList,
            };
        case "SET_OPTION_VALUE":
            return {
                ...state,
                options: { ...state.options, page: 1, [action.option]: action.value },
            };
        case "SET_FILTER_VALUE":
            return {
                ...state,
                options: { ...state.options, page: 1 },
                filters: { ...state.filters, [action.filter]: action.value },
            };
        case "RESET_FILTERS":
            return { ...state, options: { ...state.options, page: 1 }, filters: {} };
        case "RESET":
            return state.init ? initialState : state;
        default:
            throw new Error();
    }
}

function init(state) {
    const [options, filters] = parseQuery(window.location.search);

    return { ...state, options, filters };
}

function ShopPageOutlet(props) {
    const items = [
        {
            src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa1d%20text%20%7B%20fill%3A%23555%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa1d%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23777%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22285.921875%22%20y%3D%22218.3%22%3EFirst%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
            altText: 'Slide 1',
            caption: 'Slide 1',
            header: 'Slide 1 Header',
            key: '1'
        },
        {
            src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa20%20text%20%7B%20fill%3A%23444%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa20%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23666%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22247.3203125%22%20y%3D%22218.3%22%3ESecond%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
            altText: 'Slide 2',
            caption: 'Slide 2',
            header: 'Slide 2 Header',
            key: '2'
        },
        {
            src: 'data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%22800%22%20height%3D%22400%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20800%20400%22%20preserveAspectRatio%3D%22none%22%3E%3Cdefs%3E%3Cstyle%20type%3D%22text%2Fcss%22%3E%23holder_15ba800aa21%20text%20%7B%20fill%3A%23333%3Bfont-weight%3Anormal%3Bfont-family%3AHelvetica%2C%20monospace%3Bfont-size%3A40pt%20%7D%20%3C%2Fstyle%3E%3C%2Fdefs%3E%3Cg%20id%3D%22holder_15ba800aa21%22%3E%3Crect%20width%3D%22800%22%20height%3D%22400%22%20fill%3D%22%23555%22%3E%3C%2Frect%3E%3Cg%3E%3Ctext%20x%3D%22277%22%20y%3D%22218.3%22%3EThird%20slide%3C%2Ftext%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E',
            altText: 'Slide 3',
            caption: 'Slide 3',
            header: 'Slide 3 Header',
            key: '3'
        }
    ];
    const routeParams = useParams();
    const { outletToken } = routeParams;
    // console.log("outletTokenoutletTokenoutletToken",outletToken);
    //console.log("outletTokenoutletTokenoutletToken",outletToken);

    localStorage.setItem('outlet_token', outletToken);
    const { categorySlug, columns, viewMode, sidebarPosition } = props;
    const offcanvas = columns === 3 ? "mobile" : "always";
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const [latestProducts, setLatestProducts] = useState([]);
    // Replace current url.
    useEffect(() => {
        const query = buildQuery(state.options, state.filters);
        const location = `${window.location.pathname}${query ? "?" : ""}${query}`;
        window.history.replaceState(null, "", location);
    }, [state.options, state.filters]);
    // Load category.
    useEffect(() => {
        let request;
        let canceled = false;
        dispatch({ type: "RESET", categorySlug });
        if (categorySlug) {
            request = outletApi.getCategoryBySlug(categorySlug);
        } else {
            request = Promise.resolve(null);
        }

        request.then((category) => {
            if (canceled) {
                return;
            }

            dispatch({ type: "FETCH_CATEGORY_SUCCESS", category });
        });

        return () => {
            canceled = true;
        };
    }, [dispatch, categorySlug]);

    // Load products.
    useEffect(() => {
        let canceled = false;

        dispatch({ type: "FETCH_PRODUCTS_LIST" });

        outletApi.getProductsList(state.options, {
                ...state.filters,
                category: categorySlug,
            })
            .then((productsList) => {
                if (canceled) {
                    return;
                }
                dispatch({ type: "FETCH_PRODUCTS_LIST_SUCCESS", productsList });
            });
        return () => {
            canceled = true;
        };
    }, [dispatch, categorySlug, state.options, state.filters]);

    // Load latest products.
    useEffect(() => {
        let canceled = false;

        if (offcanvas === "always") {
            setLatestProducts([]);
        } else {
            outletApi.NewArrivalsProducts({ limit: 5 }).then((result) => {
                if (canceled) {
                    return;
                }
                setLatestProducts(result);
            });
        }
        return () => {
            canceled = true;
        };
    }, [offcanvas]);

    if (
        state.categoryIsLoading ||
        (state.productsListIsLoading && !state.productsList)
    ) {
        return <BlockLoader />;
    }
    const breadcrumb = [
        { title: "Home", url: url.outlet(outletToken) },
        { title: "Shop", url: url.outlet(outletToken) },
    ];
    let content;
    if (state.category) {
        getCategoryParents(state.category).forEach((parent) => {
            breadcrumb.push({ title: parent.name, url: url.category(parent) });
        });
        breadcrumb.push({
            title: state.category.name,
            url: url.category(state.category),
        });
    }
    const productsView = (
        <ProductsView
            isLoading={state.productsListIsLoading}
            productsList={state.productsList}
            options={state.options}
            filters={state.filters}
            dispatch={dispatch}
            layout={viewMode}
            grid={`grid-${columns}-${columns > 3 ? "full" : "sidebar"}`}
            offcanvas={offcanvas}
        />
    );
    const sidebarComponent = (
        <CategorySidebar offcanvas={offcanvas}>
            <CategorySidebarItem>
                <WidgetFilters
                    title="Filters"
                    offcanvas={offcanvas}
                    filters={state.productsList.filters}
                    values={state.filters}
                    dispatch={dispatch}
                />
            </CategorySidebarItem>
        </CategorySidebar>
    );

    if (columns > 4) {
        content = (
            <div className="container">
                <div className="block">{productsView}</div>
                {sidebarComponent}
            </div>
        );
    } else {
        const sidebar = (
            <div className="shop-layout__sidebar">{sidebarComponent}</div>
        );
        content = (
            <div className="container">
                <BlockOutletBanner withDepartments Banner={state.productsList.Banners[0]}/>
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === "start" && sidebar}
                    <div className="shop-layout__content">
                        <div className="block">{productsView}</div>
                    </div>
                    {sidebarPosition === "end" && sidebar}
                </div>
            </div>
        );
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Shop All Products — ${theme.name}`}</title>
            </Helmet>
            {content}
        </React.Fragment>
    );
}
ShopPageOutlet.propTypes = {
    /**
     * Category slug.
     */
    categorySlug: PropTypes.string,
    /**
     * number of product columns (default: 3)
     */
    columns: PropTypes.number,
    /**
     * mode of viewing the list of products (default: 'grid')
     * one of ['grid', 'grid-with-features', 'list']
     */
    viewMode: PropTypes.oneOf(["grid", "grid-with-features", "list"]),
    /**
     * sidebar position (default: 'start')
     * one of ['start', 'end']
     * for LTR scripts "start" is "left" and "end" is "right"
     */
    sidebarPosition: PropTypes.oneOf(["start", "end"]),
};

ShopPageOutlet.defaultProps = {
    columns: 4,
    viewMode: "grid",
    sidebarPosition: "start",
};

const mapStateToProps = (state) => ({
    sidebarState: state.sidebar,
    page: state.category,
});

const mapDispatchToProps = () => ({
    sidebarClose,
});

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageOutlet);
