// react
import React from 'react';
// third-party
import {Helmet} from 'react-helmet-async';
// application
import PageHeader from '../shared/PageHeader';
// data stubs
import theme from '../../data/theme';

function ShippingPolicy() {
    const breadcrumb = [
        {title: 'Home', url: ''},
        {title: 'Shipping Policy', url: ''},
    ];
    return (
        <React.Fragment>
            <Helmet>
                <title>{` — ${theme.name}`}</title>
            </Helmet>
            <PageHeader header="Shipping Policy" breadcrumb={breadcrumb}/>
            <div className="container">
                <div>
                    <p>
                        Thank you for visiting and shopping at <a href="www.ezytrade.africa" rel="noopener noreferrer"
                                                                  target="_blank"> www.ezytrade.africa</a>. Following
                        are the terms and conditions that constitute our Shipping Policy.

                    </p>
                    <p>The transportation sector is facing many challenges in many parts of Africa. This is due to poor
                        infrastructure, lack of residential addresses, and lack of effective transportation systems. The
                        situation makes freight forwarding and courier services a bit expensive compared to developed
                        countries.
                    </p>
                    <p> However, Ezytrade Africa Limited is making every effort to ensure that it serves its customers
                        reliably and efficiently. We have been able to overcome most of the challenges by setting up a
                        shipping and delivery network through a shared economy using multiple independent shippers and
                        delivery companies to both national and international locations.
                    </p>
                    <p><h5>Ezytrade Africa website does not calculate shipping charges during checkout. </h5>
                        This is because shipping costs depend and fluctuate due to several factors such as the original
                        location, the delivery location, the size and nature of the cargo being transported, the type of
                        transport used, the season etc. Therefore, to get realistic and affordable charges we establish
                        delivery charges immediately after the order is placed.
                    </p>
                    <p>Our marketplace offers two options with regards to delivery charges; </p>
                    <div className="container">
                        <li>
                            <span style={{fontSize: '18px', fontWeight: 'bold'}}>The first option is to arrange delivery of your order on your own.</span> When
                            this option is
                            selected, the buyer is expected to arrange the delivery of the goods on his/her own.
                            Normally the buyer contacts the third-party delivery service providers and agrees on the
                            delivery charges. The buyer then informs Ezytrade, who will be picking up and deliver the
                            products. The products are collected from their location address shown on the product detail
                            page.

                        </li>

                        <li><span style={{fontSize: '18px', fontWeight: 'bold'}}>The second option is to let Ezytrade Africa arrange the delivery for you.</span>When
                            this option
                            is selected, Ezytrade Africa will establish delivery charges for you and let you know how
                            much it will cost to deliver your order as well as the estimated delivery schedule and the
                            preferred courier. Depending on the complexity of the route, the order delivery charges can
                            be established within 3- 8 hrs. You will be required to pay an additional delivery charges
                            once the delivery costs have been established.
                        </li>
                    </div>
                    <br/>
                    <h5>Domestic Shipping Policy</h5>
                    <br/>
                    <p style={{
                        fontWeight: 'bold'
                    }}>Shipment Processing Time</p>

                    <p> All orders are processed within 2-3 business days. Orders are not shipped or delivered on
                        weekends
                        or holidays.</p>
                    <p>
                        If we are experiencing a high volume of orders, shipments may be delayed by a few days. Please
                        allow
                        additional days in transit for delivery. If there will be a significant delay in the shipment of
                        your order, we will contact you via email or telephone.
                    </p>
                    <br/>
                    <h5>Shipping and Delivery</h5>
                    <br/>
                    <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                        <tr>
                            <th scope="col">Shipping method</th>
                            <th scope="col">Estimated Delivery Time</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>

                            <td> Trucks and Marine</td>
                            <td> 3-5 business days</td>
                        </tr>
                        <tr>
                            <td> Buses
                            </td>
                            <td> Two business days</td>
                        </tr>
                        <tr>
                            <td>
                                Aircraft

                            </td>
                            <td> One business days</td>
                        </tr>
                        </tbody>
                    </table>
                        </div>
                    <h6>
                        Shipment Confirmation & Order Tracking
                    </h6>

                    <p>You will receive a Shipment Confirmation email or SMS once your order has shipped containing your
                        tracking number(s). The tracking number will be active within 2 hours.
                    </p>
                    <h6>
                        Customs, Duties, and Taxes
                    </h6>

                    <p>
                        <a href="www.ezytrade.africa" rel="noopener noreferrer"
                           target="_blank"> www.ezytrade.africa</a> is not responsible for any customs and taxes applied
                        to your order. You will be required to pay all fees imposed during or after shipping (tariffs,
                        taxes, etc.).
                    </p>
                    <h6>Damages</h6>

                    <p><a href="www.ezytrade.africa" rel="noopener noreferrer"
                          target="_blank"> www.ezytrade.africa</a> is not liable for any products damaged or lost during
                        shipping. However, if you received your order damaged, we could help to follow up and open a
                        claim with the seller and the shipper. Please save all packaging materials and damaged goods
                        before filing a claim.
                    </p>
                    <p>
                        Kindly contact <a >sales@ezytrade.africa</a> to arrange delivery for you before you pay for your order
                    </p>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ShippingPolicy;
