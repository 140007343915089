import { useState, useEffect } from "react";

const useForm = (callback, validate) => {
    // state for login
    const [state, setState] = useState({
        phone_email: "",
        password: "",
        remember_me: "",
    });
    // state for checking submition results
    const [isSubmitting, setIsSubmitting] = useState(false);

    //  state for registration
    const [registrationState, setRegistrationstate] = useState({
        email: "",
        // passwords: "",
        fullname: "",
        user_phoneNumber: "",
        // confirmPassword: "",
    });

    // state for error
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { id, value } = e.target;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));

        setRegistrationstate((prevState) => ({
            ...prevState,
            [id]: value,
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        //    handling errors
        setErrors(validate(state,registrationState));
        setIsSubmitting(true);
        //    callback();
    };
    useEffect(() => {
        if (Object.keys(errors.length === 0 && isSubmitting)) {
            callback();
        }
    }, [errors]);
    return {
        handleChange,
        handleSubmit,
        state,
        errors,
        registrationState,
    };
};
export default useForm;
