// react
import React, {Component} from "react";
import {Link} from "react-router-dom";
// third-party
import classNames from "classnames";
// application
import PropTypes from 'prop-types';
import departmentsAria from "../../services/departmentsArea";
import languages from "../../i18n";
import StroykaSlick from "../shared/StroykaSlick";
import {connect}from "react-redux";

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
};

class BlockSlideShow extends Component {
    departmentsAreaRef = null;
    media = window.matchMedia("(min-width: 992px)");

    constructor(props) {
        super(props);
        const { Banner} = props;
        this.state = {
            slides: [],
        };
    }

    componentDidMount() {
        fetch(window.$name + '/api/adminstrator/show-banners')
            .then((response) => response.json())
            .then((datalist) => this.setState({slides: datalist}));

        if (this.media.addEventListener) {
            this.media.addEventListener("change", this.onChangeMedia);
        } else {
            this.media.addListener(this.onChangeMedia);
        }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener("change", this.onChangeMedia);
        } else {
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    render() {
const{Banner}=this.props;
        const {slides} = this.state;
        const {locale, withDepartments} = this.props;
        const {direction} = languages[locale];
        const blockClasses = classNames("block-slideshow block", {
            "block-slideshow--layout--full": !withDepartments,
            "block-slideshow--layout--with-departments": withDepartments,
        });
        const layoutClasses = classNames("col-12", {
            "col-lg-12": !withDepartments,
            "col-lg-9": withDepartments,
        });
        // console.log(Banner.images);
        const  banner =Banner.images.map((slide, index) => {
            if (!withDepartments || Banner.images.length>0)  {
                return (
                    <div key={index} className="block-slideshow__slide">
                        <div
                            className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                            style={{
                                backgroundImage: `url(${slide.secure_url})`,
                            }}
                        />
                        <div className="block-slideshow__slide-content">
                            <div className="block-slideshow__slide-button">
                                <Link
                                    className="btn btn-primary  border-0 text-uppercase rounded-pill"
                                >
                                    {Banner.business_name}&nbsp; store
                                </Link>
                            </div>
                        </div>
                    </div>
                );
            }
        });
        const  bannermobile =Banner.images.map((slide, index) => {
            if( withDepartments ) {
                return (
                    <div key={index} className="block-slideshow__slide">
                        <div
                            className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                            style={{
                                backgroundImage: `url(${slide.secure_url})`,
                            }}
                        />
                        <div className="block-slideshow__slide-content">
                            <div className="block-slideshow__slide-button">
                                <Link
                                    className="btn btn-primary  border-0 text-uppercase rounded-pill"
                                >
                                    {Banner.business_name}  &nbsp; store
                                </Link>
                            </div>
                        </div>
                    </div>
                )
            }
        });
        return (
            <div className={blockClasses}>
                <div className="container">
                    <div className="row">
                        {withDepartments && (
                            <div
                                className="col-3 d-lg-block d-none"
                                ref={this.setDepartmentsAreaRef}
                            />
                        )}
                        <div className={layoutClasses}>
                            <div className="d-none d-md-block d-xl-block d-lg-block block-slideshow__body">
                                <StroykaSlick {...slickSettings}>
                                    {banner}
                                </StroykaSlick>
                            </div>
                            <div className=" d-sm-none .d-md-block block-slideshow__body">
                                <StroykaSlick {...slickSettings}>
                                    {bannermobile}
                                </StroykaSlick>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockSlideShow.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
};

BlockSlideShow.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});
export default connect(mapStateToProps)(BlockSlideShow);
