import React from 'react';
import {connect} from "react-redux";
import MyownDelivery from './MyownDelivery';
import AddressCheckout from './ShopPageCheckout';
import AddressGuestCheckout from './ShopGuestCheckout';

function AddressStage(props) {
    const {stepNumber, activeStep, MyownShipping, isLogin} = props;
    // console.log(MyownShipping.data);
    if (MyownShipping.data === "I will arrange Delivery") {
        return (
            <MyownDelivery stepNumber={stepNumber} activeStep={activeStep}/>
        );
    } else {
        return (
            <div>
                {isLogin ?(
                     <AddressCheckout stepNumber={stepNumber} activeStep={activeStep}/>
                    ):
                  (
                     <AddressGuestCheckout stepNumber={stepNumber} activeStep={activeStep}/>
                    )    
                }

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    shipping: state.shippingAddress,
    MyownShipping: state.MyownShipping,
    isLogin: state.auth.isLogin,
});
const mapDispatchToProps = {};
export default connect(mapStateToProps, mapDispatchToProps)(AddressStage);
