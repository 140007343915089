import {CURRENT_CUSTOMER} from "./CurrentUserActionTypes";

export function SetCurrentCustomer(fullname) {
    return async (dispatch) => (
        dispatch({type: CURRENT_CUSTOMER, payload: fullname})

    );
}
export function CurrentCustomer(){
    localStorage.setItem("agentcustomer_token", "");
    window.location.href = ("/agent/customers");
}
export function ChangeCurrentCustomer(fullname) {
    return async (dispatch) => (
        dispatch({type: CURRENT_CUSTOMER, payload: fullname},CurrentCustomer())



)


}
