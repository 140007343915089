export const url = {
    home: () => '/',
    catalog: () => '/shop/catalog',
    outlet:(outletToken) =>`/shops/${outletToken}`,
    outletcategory:(outletToken,category) =>`/shop-catalog/${outletToken}/${category.slug}`,
    category: (category) => `/shop/catalog/${category.slug}`,
    product: (product) => `/shop/products/${product.slug}`,
    //digital 
    digital: () => '/digital/catalog',
    digitalcategory:(category) =>`/digital/catalog/${category.slug}`,
};


export function getCategoryParents(category) {
    return category.parent ? [...getCategoryParents(category.parent), category.parent] : [];
}
