// react
import React, {useEffect, useState} from "react";
// third-party
import {Helmet} from "react-helmet-async";
import theme from "../../data/theme";
import {connect, useDispatch, useSelector} from "react-redux";
import {useFormik} from "formik";
import * as yup from "yup";
import {FetchProductLeads} from "../../store/ProductLeads";
import {FetchCustomers} from "../../store/agentCustomer";
import {DropzoneArea} from "material-ui-dropzone";
import axios from "axios";
import {toast} from "react-toastify";
import {FetchListingDetail} from "../../store/ListingDetails";
import {Link} from "react-router-dom";

const initialValues = {
    product_name: "",
    product_description: "",
    quantity: "",
};
//creating the validation schema
const validationSchema = yup.object().shape({
    product_name: yup
        .string()
        .required("Product Name is required"),
    product_description: yup
        .string()
        .required("Product Description is required"),
    quantity: yup
        .string()
        .required("Product Quantity is required"),
});

function AccountPageDashboard(props) {
    const dispatch=useDispatch();
    const [imageFile, setImage] = useState([]);
    const [leadToken, setleadToken] = useState([]);
    useEffect(() => {
        dispatch(FetchProductLeads());
    }, [dispatch]);
    const {leads}=props
    console.log("leads here:",leads)

    //using useFormik
    const formik = useFormik({
        initialValues,
        validationSchema,
    });
    function handleImages(files) {
        setImage(files)
    }
    function uploadLeadImage() {
        const formData = new FormData();
        imageFile.forEach(image => {
            formData.append('leadsProductImage[]', image);
        });
        formData.append('lead_token',leadToken)

        axios.post(`${window.$name}/api/leads/insert/leads-image`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                toast.success(` ${response.data.message}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                dispatch(FetchProductLeads())
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(` ${error.response.data.message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                } else if (error.request) {
                    toast.error(` ${error.request}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                } else {
                    toast.error(` ${error.message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                }
            });
    }

    return (
        <div className="dashboard">
            <Helmet>
                <title>{`Ezytrade Agent — ${theme.name}`}</title>
            </Helmet>
            <div className="cardTop card ">
                <div className="d-flex card-header">
                    {/*<div className="p-2 font-weight-bold"></div>*/}
                    {/*<div className="ml-auto p-2">*/}
                    {/*    <button*/}
                    {/*        data-toggle="modal"*/}
                    {/*        data-target="#exampleModalCenter"*/}
                    {/*        className="btn btn-outline-warning btn-sm rounded text-dark"*/}
                    {/*    >*/}
                    {/*        <i className="fa fa-edit"></i>*/}
                    {/*        Add Product*/}
                    {/*    </button>*/}
                    {/*</div>*/}
                </div>
                <div
                    class="modal fade"
                    id="exampleModalCenter"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5
                                    className="container text-center modal-title"
                                    id="exampleModalLongTitle"
                                >
                                    ADD PRODUCT IMAGE
                                </h5>

                                <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div className="form-row">
                                        <div className="form-group col ">
                                            <label>Product Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="product_name"
                                                placeholder="product_name"
                                                value={formik.values.product_name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />

                                            {formik.touched.product_name && formik.errors.product_name ? (
                                                <div className="text-danger">
                                                    {formik.errors.product_name}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group col ">
                                            <label>Quantity</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="quantity"
                                                placeholder="quantity"
                                                value={formik.values.quantity}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />

                                            {formik.touched.quantity && formik.errors.quantity ? (
                                                <div className="text-danger">
                                                    {formik.errors.quantity}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label htmlFor="checkout-email">Product Description </label>
                                            <textarea
                                                id="product_description"
                                                type="text"
                                                className="form-control"
                                                placeholder="Product description"
                                                value={formik.values.product_description}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.product_description && formik.errors.product_description ? (
                                                <div className="text-danger">{formik.errors.product_description}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="form-row">
                                                <button
                                                    type="button"
                                                    // onClick={() => AddLeadsArray(formik.values)}
                                                    className={"btn btn-block btn-primary rounded "}
                                                    disabled={!(formik.isValid && formik.dirty)}
                                                    data-dismiss="modal"
                                                >
                                                    Collect Product
                                                </button>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    data-dismiss="modal"
                                >
                                    Close Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*add leads image*/}
                <div className="modal fade" id="exampleModalCenter2" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">UPLOAD LEAD PRODUCT IMAGES</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <DropzoneArea
                                    dropzoneText="Drag and Drop  Listing Images"
                                    acceptedFiles={['image/*']}
                                    filesLimit={6}
                                    showAlerts={false}
                                    onChange={files => handleImages(files)}
                                />
                            </div>
                            <div className="modal-footer">
                                <button onClick={uploadLeadImage} data-dismiss="modal" type="button"
                                        className="btn btn-primary">Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex card-header">
                    {/* <div className="p-2 font-weight-bold">LIST OF CUSTOMERS</div> */}
                    <div className="site-header__search">
                        {/*<SearchUser context="header"/>*/}
                        <br/>
                        <div className="card-table mt-2">
                            <div className="table-responsive-sm">
                                <table>
                                    <thead>
                                    <tr className="text-center mb-3">
                                        <th>Product Image</th>
                                        <th>Product Name</th>
                                        <th>Quantity</th>
                                        <th>Product Description</th>
                                    </tr>
                                    </thead>
                                    <tbody >
                                    {leads.map((index) => (
                                        <>
                                        {index.lead_product.map((value)=>(
                                            <tr className="text-center">
                                                <td>
                                                    {value.lead_product_image.length<=0?
                                                        <button
                                                            type="button"
                                                            onClick={() => setleadToken(value.token)}
                                                            data-toggle="modal"
                                                            data-target="#exampleModalCenter2"
                                                            className={"btn btn-outline-danger"}
                                                            // disabled={!(formik.isValid && formik.dirty)}
                                                            data-dismiss="modal"
                                                        >
                                                            Add image
                                                        </button>
                                                        :
                                                        <img
                                                            src={value.lead_product_image[0].leads_product_name}
                                                            alt="elt"
                                                            height="100" width="150"
                                                            className="img-thumbnail"
                                                        />
                                                    }
                                                </td>
                                                <td>{value.product_name}</td>
                                                <td>{value.quantity}</td>
                                                <td>

                                                    {value.product_description.length > 30
                                                        ? `${value.product_description.substring(0, 30)}...`
                                                        : value.product_description}
                                                </td>
                                            </tr>
                                            ))
                                        }
                                       </>
                                    ))}
                                    </tbody>
                                </table>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    leads:state.LeadsProduct
});

const mapDispatchToProps = {
   FetchProductLeads
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPageDashboard);
