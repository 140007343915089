// react
import React from 'react';

// third-party
import classNames from 'classnames';
import {
    Link,
    matchPath,
    Redirect,
    Switch,
    Route,
} from 'react-router-dom';

// application
import PageHeader from '../shared/PageHeader';
// pages
import AccountPageAddresses from './AccountPageAddresses';
import AccountPageDashboard from './AccountPageDashboard';
import AccountPageEditAddress from './AccountPageEditAddress';
import AccountPageOrderDetails from './AccountPageOrderDetails';
import AccountPageOrders from './AccountPageOrders';
import AccountPagePassword from './AccountPagePassword';
import AccountPageViewOrder from './viewOrder';
import AddressDetails from './AccountPageAddressDetails'
import AccountPageGroup from './Group';
import AccountPagemangeGroup from './manageGroup';
import GroupOrderSummary from './GroupOrderSummary';
import CreateGroup from './CreateGroup';
import AccountPageQuotation from './quote';
import AccountPageQuoteitems from './viewQuotes';
import AccountMyownDelivery from './AccountMyownDelivery';
import Listing_home from "./Listings/Listing_home";
import Booking from './booking';
import Bookmark from './bookmark'
import ListingServices from './Listings/add_service'
import {Router} from "@material-ui/icons";
// import AddListing from "./Listings/add_listing";

export default function AccountLayout(props) {
    const { match, location } = props;
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'My Account', url: '' },
    ];

    const links = [
        { title: 'Dashboard', url: 'dashboard' },
        // { title: 'My Group', url: 'Group' },
        { title: 'My Orders', url: 'orders/5' },
        // { title: 'Order By Status', url: 'orders/5' },
        { title: 'Addresses', url: 'addresses' },
        {title:'My Shipping ',url:'MyShipping'},
        { title: 'My Quote', url: 'quotation-list' },
        { title: 'Directory', url: 'directory'},
        { title: 'Booking', url: 'my_booking' },
        { title: 'Bookmark', url: 'my_bookmark' },
        { title: 'Change Password', url: 'password' },
    ].map((link) => {
        const url = `${match.url}/${link.url}`;
        const isActive = matchPath(location.pathname, { path: url, exact: true });
        const classes = classNames('account-nav__item', {
            'account-nav__item--active': isActive,
        });

        return (
            <li key={link.url} className={classes}>
                <Link to={url}>{link.title}</Link>
            </li>
        );
    });
    return (
        <React.Fragment>
            <PageHeader header="My Account" breadcrumb={breadcrumb} />

            <div className="block">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-3 d-flex">
                            <div className="account-nav flex-grow-1">
                                <h4 className="account-nav__title">Navigation</h4>
                                <ul>{links}</ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                            <Switch>
                                <Redirect exact from={match.path} to={`${match.path}/dashboard`} />
                                <Route exact path={`${match.path}/dashboard`} component={AccountPageDashboard} />
                                <Route exact path={`${match.path}/orders`} component={AccountPageOrders} />
                                <Route exact path={`${match.path}/orders/:order`} component={AccountPageOrderDetails} />
                                <Route exact path={`${match.path}/quotation-list`} component={AccountPageQuotation} />
                                <Route exact path={`${match.path}/addresses`} component={AccountPageAddresses} />
                                <Route exact path={`${match.path}/MyShipping`} component={AccountMyownDelivery}/>
                                <Route exact path={`${match.path}/addresses/:addressId`} component={AccountPageEditAddress} />
                                <Route exact path={`${match.path}/password`} component={AccountPagePassword} />
                                <Route exact path={`${match.path}/vieworder`} component={AccountPageViewOrder} />
                                <Route exact path={`${match.path}/viewquote`} component={ AccountPageQuoteitems } />
                                <Route exact path={`${match.path}/address-details`} component={AddressDetails}/>
                                <Route exact path={`${match.path}/Group`} component={AccountPageGroup} />
                                <Route exact path={`${match.path}/manageGroup`}  component={AccountPagemangeGroup}/>
                                <Route exact path={`${match.path}/GroupOrderSummary`} component={GroupOrderSummary}/>
                                <Route exact path={`${match.path}/my_booking`} component={Booking}/>
                                <Route exact path={`${match.path}/my_bookmark`} component={Bookmark}/>
                                <Route exact path={`${match.path}/directory`} component={Listing_home}/>
                                <Route exact path={`${match.path}/CreateGroup`} component={CreateGroup}/>
                                <Route exact path={`${match.path}/add_listing_service/:id`} component={ListingServices}/>
                                {/*<Router  exact path={`${match.path}/add-listing`} component={AddListing}/>*/}
                          </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
