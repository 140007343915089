import { BUY_PRODUCT_DIGITAL,REMOVE_PRODUCT_DIGITAL,DELETE_PRODUCT_DIGITAL } from './digitalbuyActionTypes';
import Axios from "axios";
import {customertoken} from "../../api/shop";
import {toast} from "react-toastify";

/**
 * @param {array} items
 * @param {object} product
 * @param {array} options
 * @return {number}
 */
function findItemIndex(items, product, options) {
    return items.findIndex((item) => {
        if (item.product.id !== product.id || item.options.length !== options.length) {
            return false;
        }
        for (let i = 0; i < options.length; i += 1) {
            const option = options[i];
            const itemOption = item.options.find((itemOption) => (
                itemOption.optionId === option.optionId && itemOption.valueId === option.valueId
            ));
            if (!itemOption) {
                return false;
            }
        }

        return true;
    });
}


function calcSubtotal(items) {
    return items.reduce((subtotal, item) => subtotal + item.total, 0);
}


function calcQuantity(items) {
    return items.reduce((quantity, item) => quantity + item.quantity, 0);
}

function calcTotal(subtotal, extraLines) {
    return subtotal + extraLines.reduce((total, extraLine) => total, 0);
}

function digitaladdItem(state, product, options, quantity) {
    const itemIndex = findItemIndex(state.items, product, options);
    let newItems;
    let {lastItemId} = state;

    if (itemIndex === -1) {
        lastItemId += 1;
        newItems = [...state.items, {
            id: lastItemId,
            product: JSON.parse(JSON.stringify(product)),
            options: JSON.parse(JSON.stringify(options)),
            price: product.price,
            total: product.price * quantity,
            quantity,
        }];
    } else {
        const item = state.items[itemIndex];
        newItems = [
            ...state.items.slice(0, itemIndex),
            {
                ...item,
                quantity: item.quantity + quantity,
                total: (item.quantity + quantity) * item.price,
            },
            ...state.items.slice(itemIndex + 1),
        ];
    }

    const subtotal = calcSubtotal(newItems);
    const total = calcTotal(subtotal, state.extraLines);
    return {
        ...state,
        lastItemId,
        subtotal,
        total,
        items: newItems,
        quantity: calcQuantity(newItems),
    };
}

function removeItem(state, itemId) {
    const {items} = state;
    const newItems = items.filter((item) => item.id !== itemId);
    const subtotal = calcSubtotal(newItems);
    const total = calcTotal(subtotal, state.extraLines);

    return {
        ...state,
        items: newItems,
        quantity: calcQuantity(newItems),
        subtotal,
        total,
    };
}

function deleteAll(state) {
    const {items} = state;
    const newItems = [];
    const subtotal = 0;
    const total = 0;
    return {
        ...state,
        items: newItems,
        quantity: 0,
        subtotal,
        total,
    };
}

const initialState = {
    lastItemId: 0,
    quantity: 0,
    items: [],
    subtotal: 0,
    extraLines: [ // shipping, taxes, fees, .etc
        {
            type: 'shipping',
            title: 'Shipping',
            price: 28708,
        },
        {
            type: 'tax',
            title: 'Tax',
            price: 0,
        },
    ],
    total: 0,
};

function updateQuantities(state, quantities) {
    let needUpdate = false;
    const newItems = state.items.map((item) => {
        const quantity = quantities.find((x) => x.itemId === item.id && x.value !== item.quantity);
        if (!quantity) {
            return item;
        }
        needUpdate = true;
        return {
            ...item,
            quantity: quantity.value,
            total: quantity.value * item.price,
        };
    });

   newItems.forEach((data)=>{
       let dataPosted={
           secure_token:customertoken(),
           product_token:data.product.product_token,
           quantity:data.quantity,
       };
       Axios.post(window.$name+'/api/cart/update-quantity-in-the-cart',dataPosted).then((response)=>{
           toast.success(`"${response.data.message}"`);
       })
   })
    if (needUpdate) {
        const subtotal = calcSubtotal(newItems);
        const total = calcTotal(subtotal, state.extraLines);
        return {
            ...state,
            items: newItems,
            quantity: calcQuantity(newItems),
            subtotal,
            total,
        };
    }
    return state;
}

export default function digitalbuyReducer(state = initialState, action) {
    switch (action.type) {
        case BUY_PRODUCT_DIGITAL:
            return digitaladdItem(state, action.product, action.options, action.quantity);

        // case DELETE_PRODUCT:
        //     return deleteAll(state);


        default:
            return state;
    }
}
