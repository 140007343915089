import {USER_LISTING_VALUE} from "./UserListingActionTypes";

export default function  UserListingReducer(state=[],action) {
    switch(action.type){

        case USER_LISTING_VALUE:
            return action.payload
        default:
            return state;
    }
}
