import {BOOKMARK_DATA} from "./bookmarkActionTypes";
export default function bookmarkReducer(state=[],action) {
    switch(action.type){

        case BOOKMARK_DATA:

            return action.payload
        default:
            return state;
    }
}
