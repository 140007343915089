// react
import React, {useEffect, useMemo, useState} from "react";
// third-party
import PropTypes from "prop-types";
import {Helmet} from "react-helmet-async";
// application
import PageHeader from "../shared/PageHeader";
import Product from "../shared/Product";
import ProductTabs from "./ProductTabs";
import shopApi from "../../api/shop";
import {url} from "../../services/utils";
// blocks
import BlockLoader from "../blocks/BlockLoader";
import BlockRelatedProducts from "../blocks/BlockRelatedProducts";
import BlockCategories from "../blocks/BlockCategories";
// widgets
import WidgetCategories from "../widgets/WidgetCategories";
import WidgetProducts from "../widgets/WidgetProducts";
// data stubs
import categories from "../../data/shopWidgetCategories";
import {useProductTabs} from "../../services/hooks";
//import axios
import axios from "axios";
import {connect} from "react-redux";
import {toast} from "react-toastify";
function ShopPageProduct(props) {
   const links=props.location.pathname;
  const relatedProducts = useProductTabs(
    useMemo(() => [], []),
    (tab) => fechProducts()
  );
  // console.log(relatedProducts)
  const {
    outlet_token,
    product_token,
    Category,
      ReferralToken,
    productSlug,
    layout,
    sidebarPosition,
    product_details,
  } = props;
  // console.log(ReferralToken);
  const toProductJson = (list,rate) =>
    list.map((item) => ({
        id: item.id,
        rate:rate.rate,
        name: item.product.product_name,
        slug: item.product.product_name,
        price: parseInt(item.shop_price.retailprice),
        price_tag: item.shop_price.price_tag,
        vat_check:item.shop_price.vat_check,
        vat: parseInt(item.shop_price.total_retail_vat),
        acquistion_cost: parseInt(item.product.acquistion_cost),
        acquistion_currency: item.product.acquistion_currency,
        ezytrade_commission: item.product.ezy_commmission,
        agent_commission: item.product.agent_commmission,
        wholesale_price: parseInt(item.shop_price.wholesaleprice),
        wholesale_vat: parseInt(item.shop_price.total_wholesale_vat),
        currency: item.shop_price.retail_currency,
        currency_wholesale: item.shop_price.wholesale_currency,
        discount: item.product.discount,
        customUoM :item.product.customUoM ,
        unitMeasure:item.product.unitMeasure,
        digital:item.product.is_digital,
        compareAtPrice: null,
        status: item.product.is_featured,
        images: item.product.images.productImage_name,
        shop_id: item.shop_id,
        seller_token: item.product.business.secure_token,
        secure_token: item.secure_token,
        product_token: item.product_token,
        outlets_token: item.outlet_token,
        Category:item.product.categories.category,
        // Category: item.Category.category,
        badges: [],
        rating: 5,
        reviews: 3,
        brand: item.product.brand,
        categories: [],
        attributes: [],
        customFields: {},
    }));
    
  const fechProducts = async (tab) => {
    const response = await axios.get(window.$name+`/api/consumer/all-products?category=${Category}`);
    // console.log("related",response.data)
    return toProductJson(response.data.products.data,response.data.rates);
  };
  const [isLoading, setIsLoading] = useState(true);

  let [product, setProduct] = useState(null);

  const [latestProducts, setLatestProducts] = useState([]);
  //products to be structured
  useEffect(() => {
    let canceled = false;
    setIsLoading(true);
    axios.post(window.$name + "/api/business/get-product-price", {
        product_token: product_token,
        outlet_token: outlet_token,
      })
      .then(function (response) {
        if (canceled) {
          return;
        }
          // console.log(response.data);
        product = response.data;
        setProduct(product);
        setIsLoading(false);
      })
      .catch(function (error) {
        if (error.response) {
          toast.error(
            `"${error.response.data.message}" Error Status  "${error.response.status}" `
          );
        } else if (error.request) {
          toast.error(`"${error.request}" `);
        } else {
          toast.error(`"${error.message}" `);
        }
      });
    return () => {
      canceled = true;
    };
  }, [productSlug, setIsLoading]);
// console.log("fhgfhgf products",relatedProducts.data);
  // Load latest products.
  useEffect(() => {
    let canceled = false;

    if (layout !== "sidebar") {
      setLatestProducts([]);
    } else {
      shopApi.getLatestProducts({ limit: 5 }).then((result) => {
        if (canceled) {
          return;
        }
        setLatestProducts(result);
      });
    }

    return () => {
      canceled = true;
    };
  }, [layout]);

  if (isLoading) {
    return <BlockLoader />;
  }

  const breadcrumb = [
    { title: "Home", url: url.home() },
    { title: "Shop", url: url.catalog() },
    { title: product.name, url: url.product(product.products) },
  ];
  let content;
  if (layout === "sidebar") {
    const sidebar = (
      <div className="shop-layout__sidebar">
        <div className="block block-sidebar">
          <div className="block-sidebar__item">
            <WidgetCategories categories={categories} location="shop" />
          </div>
          <div className="block-sidebar__item d-none d-lg-block">
            <WidgetProducts title="Latest Products" products={latestProducts} />
          </div>
        </div>
      </div>
    );

    content = (
      <div className="container">
        <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
          {sidebarPosition === "start" && sidebar}
          <div className=" shop-layout__content">
            <div className=" block">
              <Product Referral={ReferralToken} product={product.products} links={links} layout={layout} rate={product.rates} review_rates={product.review_rates}/>
              <ProductTabs product={product.products.product_shop} review_rates={product.review_rates} withSidebar />
            </div>

            <BlockRelatedProducts
              title="Related Products"
              layout="grid-5"
              products={relatedProducts.data}
              loading={relatedProducts.isLoading}
              groups={relatedProducts.tabs}
              onGroupClick={relatedProducts.handleTabChange}
            />
          </div>
          {sidebarPosition === "end" && sidebar}
        </div>
      </div>
    );
  } else {
    content = (
      <React.Fragment>
        <div className="block">
          <div className="container">
            <Product Referral={ReferralToken} product={product.products} links={links} layout={layout} rate={product.rates} review_rates={product.review_rates}/>
            <ProductTabs product={product.products.product_shop} review_rates={product.review_rates} />
          </div>
        </div>
        <BlockRelatedProducts
          title="Related Products"
          layout="grid-4-sm"
          products={relatedProducts.data}
          loading={relatedProducts.isLoading}
          groups={relatedProducts.tabs}
          onGroupClick={relatedProducts.handleTabChange}
        />        
        <BlockCategories title="Popular Categories" layout="classic" />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Product details</title>
      </Helmet>
      <PageHeader breadcrumb={breadcrumb} />
      {content}
    </React.Fragment>
  );
}

ShopPageProduct.propTypes = {
  /** product slug. */
  productSlug: PropTypes.string,
  /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
  layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
  sidebarPosition: PropTypes.oneOf(["start", "end"]),
};

ShopPageProduct.defaultProps = {
  layout: "standard",
  sidebarPosition: "start",
};
const mapStateToProps = (state) => ({
  product_details: state.product,
});

export default connect(mapStateToProps, () => ({}))(ShopPageProduct);
