import React, { Fragment, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "axios";
import { toast } from "react-toastify";
import { GamepadTwoTone } from "@material-ui/icons";
// Destructure props
const SecondStep = ({
  handleNext,
  handleBack,
  handleChange,
  values: {
    FullName,
    email,
    phone,
    Category,
    Country
  },
  filedError,
  isError,
}) => {
  // Check if all values are not empty
  // const isEmpty = District.length > 0 && Street.length > 0;
  const [country, setCountry] = useState([]);
  const [personalid, setId] = useState("");
  const [Guarantorone,setOneImage]= useState("");
  const [Guarantortwo,setTwoImage]= useState("");
  const [Letter ,setLetter] = useState("");
  const [License,setLicense] =useState("");
  const [formValues, setFormValues] = useState([{name: "",email : ""}])


let handleValue = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
 }

let addFormFields = () => {
    setFormValues([...formValues, {name: "", email: "" }])
 }

let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues)
}

  const uploadImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setId(base64);
  };
  const uploadlicense= async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setLicense(base64);
  };
  const GuarantoroneImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setOneImage(base64);
  }
  const GuarantortwoImage = async (e) => {
    const file = e.target.files[0];
    const base64 = await convertBase64(file);
    setTwoImage(base64);
  }
  const uploadletter = async (e) => {
  const file = e.target.files[0];
  const base64 = await convertBase64(file);
  setLetter(base64);
}
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  useEffect(() => {
    const serviceOption = async () => {
      // eslint-disable-next-line camelcase
      const country_data = await Axios.get(
        `${window.$name}/api/adminstrator/get-all-categories`
      ).then((response) => response.data);
      setCountry(country_data);
    };
    serviceOption();
  }, []);
  function handleSubmit() {
    const data = {
      fullname: FullName,
      user_phoneNumber: phone,
      email: email,
      category:Category,
      invitations:formValues,
      country: Country
    };
    // console.log(data);
    toast.info(`Processing...........!!`);
    Axios.post(window.$name + "/api/auth/register-user-promoted", data)
      .then(function (response) {
        if (response.status === 201) {
          toast.success(`${response.data.message}`);
          window.location.href = "/account/login";
        }
      })
      .catch(function (error) {
          // console.log(error.response)
          // // if (error.response) {
          if (error.response.status === 401 && error.response.data.message[0].email !== undefined && error.response.data.message[0].email !== "") {
            toast.error("The email has already been taken.");
          }
          else if (error.response.status === 401 && error.response.data.message[0].user_phoneNumber !== undefined && error.response.data.message[0].user_phoneNumber !== "") {
            toast.error("The user phone number has already been taken.");
          }
        });
  }
  return (
    <Fragment>
      <h6 className="card-title text-center">Invite up to 10 friends to join Ezytrade Africa and receive a total of 10 credits equivalent to TZS 10,000 for your next order.</h6>
       <form  onSubmit={handleSubmit}>
       <FormControl
       size="small"
       variant="outlined"
       required
       margin="normal"
     >
         <Button variant="contained" color="default" className="dd" type="button" onClick={() => addFormFields()}>Add</Button>
     </FormControl>
          {formValues.map((element, index) => (
            <div className="form-inline" key={index}>
               <FormControl
            size="small"
            fullWidth
            variant="outlined"
            required
            margin="normal"
          >
               <TextField
              fullWidth
              size="small"
              label="Email"
              value={element.email||""}
              variant="outlined"
              name="email"
              placeholder="Your Email Address"
              onChange={e => handleValue(index, e)}
              margin="normal"
              required
            />

            </FormControl>

              {
                index ?
          <FormControl
            size="small"
            variant="outlined"
            required
            margin="normal"
          >
          <Button  variant="contained" color="default" className="remove" onClick={() => removeFormFields(index)}>Remove</Button>
          </FormControl>
                : null
              }
            </div>
          ))}
      </form>
      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button
          variant="contained"
          color="default"
          onClick={handleBack}
          style={{ marginRight: 20 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          // disabled={!isEmpty || isError}
          color="primary"
          onClick={handleSubmit}
        >
          Submit Now
        </Button>
      </div>
    </Fragment>
  );
};

export default SecondStep;
