import React, { useState, Fragment } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import ResidentStep from "./ResidentStep";
import Success from "./Success";
import Axios from "axios";

const emailRegex = RegExp(/^[^@]+@[^@]+\.[^@]+$/);
const phoneRegex = RegExp(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4,6})$/);
// Step titles
const labels = [
  "BUSINESS DETAILS",
  "GUARANTOR DETAILS",
  "PERSONAL DETAILS",
  "OTHER DETAILS",
];

const StepForm = () => {
  const [steps, setSteps] = useState(0);
  const [region, setRegion] = useState([]);
  const [district, setDistrict] = useState([]);
  const [baseimage, setBaseImage]=useState("");
  console.log("base image 64",baseimage);
  const [fields, setFields] = useState({
    BusinessName: "",
    TIN: "",
    License: "",
    FullName: "",
    Licensefile: "",
    email1: "",
    phone1: "",
    Idtype1:"",
    Idtype: "",
    idNumber: "",
    idNumber1: "",
    IdNumberp: "",
    FullName2: "",
    FirstName: "",
    SurName: "",
    LastName: "",
    email: "",
    email1: "",
    email2: "",
    IdcopyG1: "",
    IdcopyG2: "",
    phone: "",
    phone2: "",
    idNumber2: "",
    Idtype2: "",
    IdType: "",
    Idcopyp: "",
    IdNumber: "",
    password: "",
    ConfrimPassword: "",
    Country: "",
    Region: "",
    District: "",
    Street: "",
  });

  // Copy fields as they all have the same name
  const [filedError, setFieldError] = useState({
    ...fields,
  });

  const [isError, setIsError] = useState(false);

  // Proceed to next step
  const handleNext = () => setSteps(steps + 1);
  // Go back to prev step
  const handleBack = () => setSteps(steps - 1);

  // Handle fields change
  const handleChange = (input) => ({ target: { value } }) => {
    // Set values to the fields
    // console.log("tag", value);
    // console.log("tag", input);
    if (input === "Country") {
      regionChange(value);
    } else if (input === "Region") {
      districtChange(value);
    }
    // else if (input === "IdcopyG2") {
    //   imagedata(value);
    // }
    

  
    setFields({
      ...fields,
      [input]: value,
    });

    // Handle errors
    const formErrors = { ...filedError };
    const lengthValidate = value.length > 0 && value.length < 3;
    const lengthTin = value.length > 0 && value.length < 8;
    const lengthbusiness = value.length > 0 && value.length < 10;
    const lengthFullname = value.length > 0 && value.length < 6;
    const lengthIdtype = value.length > 0 && value.length < 4;
    switch (input) {
      case "BusinessName":
        formErrors.BusinessName = lengthbusiness
          ? "Minimum 10 characaters required"
          : "";
        break;
      case "BusinessName":
        formErrors.BusinessName = lengthValidate
          ? "Minimum 3 characaters required"
          : "";
        break;
      case "TIN":
        formErrors.TIN = lengthTin ? "Minimum 8 Characters required" : "";
        break;
      case "License":
        formErrors.License = lengthValidate
          ? "Minimum 8 Characters required"
          : "";
        break;
      case "FullName":
        formErrors.FullName = lengthFullname
          ? "Minimum 6 characaters required"
          : "";
        break;
      case "FullName2":
        formErrors.FullName2 = lengthFullname
          ? "Minimum 6 characaters required"
          : "";
        break;
      case "email1":
        formErrors.email1 = emailRegex.test(value)
          ? ""
          : "Invalid email address";
        break;
      case "email2":
        formErrors.email2 = emailRegex.test(value)
          ? ""
          : "Invalid email address";
        break;
      case "email":
        formErrors.email = emailRegex.test(value)
          ? ""
          : "Invalid email address";
        break;
      case "phone":
        formErrors.phone = phoneRegex.test(value)
          ? ""
          : "Please enter a valid phone number. i.e: xxx-xxx-xxxx";
        break;
      case "phone1":
        formErrors.phone1 = phoneRegex.test(value)
          ? ""
          : "Please enter a valid phone number. i.e: xxx-xxx-xxxx";
        break;
      case "phone2":
        formErrors.phone2 = phoneRegex.test(value)
          ? ""
          : "Please enter a valid phone number. i.e: xxx-xxx-xxxx";
        break;
      case "IdType":
        formErrors.IdType = lengthIdtype
          ? "Minimum 4 characaters required"
          : "";
        break;
        case "Idtype1":
        formErrors.Idtype1 =lengthIdtype
          ? "Minimum 3 characaters required"
          : "";
        break; 
      case "Idtype2":
        formErrors.Idtype2 = lengthIdtype
          ? "Minimum 4 characaters required"
          : "";
        break;
      case "IdNumberp":
        formErrors.IdNumberp = phoneRegex.test(value)
          ? ""
          : "Please enter a valid Id number. i.e: xxx-xxx-xxxx";
        break;
        case "idNumber1":
          formErrors.idNumber1 = phoneRegex.test(value)
            ? ""
            : "Please enter a valid Id number. i.e: xxx-xxx-xxxx";
          break;
      case "idNumber2":
        formErrors.idNumber2 = phoneRegex.test(value)
          ? ""
          : "Please enter a valid Id number. i.e: xxx-xxx-xxxx";
        break;
      case "FirstName":
        formErrors.FirstName = lengthValidate
          ? "Minimum 3 characaters required"
          : "";
        break;
      case "SurName":
        formErrors.SurName = lengthValidate
          ? "Minimum 3 characaters required"
          : "";
        break;
      case "LastName":
        formErrors.LastName = lengthValidate
          ? "Minimum 3 characaters required"
          : "";
        break;
      case "email":
        formErrors.email = emailRegex.test(value)
          ? ""
          : "Invalid email address";
        break;
      case "phone":
        formErrors.phone = phoneRegex.test(value)
          ? ""
          : "Please enter a valid Id number. i.e: xxx-xxx-xxxx";
        break;
      case "IdType":
        formErrors.IdType = lengthValidate
          ? "Minimum 3 characaters required"
          : "";
        break;
      case "IdNumber":
        formErrors.IdNumber = phoneRegex.test(value)
          ? ""
          : "Please enter a valid Id number. i.e: xxx-xxx-xxxx";
        break;
      // case "password":
      //   formErrors.password = !lengt
      //     ? "characaters required"
      //     : "";
      //   break;
      // case "ConfrimPassword":
      //   formErrors.ConfrimPassword = lengthValidate
      //     ? "Minimum 6 characaters required"
      //     : "";
      //   break;
      case "Street":
        formErrors.Street = lengthValidate
          ? "Minimum 3 characaters required"
          : "";
        break;
      default:
        break;
    }

    // set error hook
    Object.values(formErrors).forEach((error) =>
      error.length > 0 ? setIsError(true) : setIsError(false)
    );
    // set errors hook
    setFieldError({
      ...formErrors,
    });
  };

  function regionChange(e) {
    // console.log("id country",e.target.value)
    Axios.get(`${window.$name}/api/adminstrator/get-regions`, {
      params: {
        country_id: e,
      },
    }).then((res) => {
      setRegion(res.data);
    });
  }
  function districtChange(e) {
    console.log("id region", e);
    Axios.get(`${window.$name}/api/adminstrator/get-districts`, {
      params: {
        region_id: e,
      },
    }).then((res) => {
      setDistrict(res.data);
    });
  }

  
  const handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          <FirstStep
            handleNext={handleNext}
            handleChange={handleChange}
            values={fields}
            isError={isError}
            filedError={filedError}
          />
        );
      case 1:
        return (
          <SecondStep
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            values={fields}
            isError={isError}
            filedError={filedError}
          />
        );
      case 2:
        return (
          <ThirdStep
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            values={fields}
            isError={isError}
            filedError={filedError}
          />
        );
      case 3:
        return (
          <ResidentStep
            handleNext={handleNext}
            handleBack={handleBack}
            handleChange={handleChange}
            values={fields}
            region={region}
            district={district}
            isError={isError}
            filedError={filedError}
          />
        );

      default:
        break;
    }
  };

  // Handle components
  return (
    <Fragment>
      {steps === labels.length ? (
        <Success />
      ) : (
        <Fragment>
          <Stepper
            className="justify-content-center"
            activeStep={steps}
            style={{ paddingTop: 30, paddingBottom: 50 }}
            alternativeLabel
          >
            {labels.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {handleSteps(steps)}
        </Fragment>
      )}
    </Fragment>
  );
};

export default StepForm;
