import React, { Fragment, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Axios from "axios";

// Destructure props
const SecondStep = ({
  handleNext,
  handleBack,
  handleChange,
  values: {
    Category,
  },
  filedError,
  isError,
}) => {
  // Check if all values are not empty
  // const isEmpty =
  //   Category.length > 0;

    const [country, setCountry] = useState([]);

    useEffect(() => {
    const serviceOption = async () => {
      // eslint-disable-next-line camelcase
      const country_data = await Axios.get(
        `${window.$name}/api/adminstrator/get-all-categories`
      ).then((response) => response.data);
      setCountry(country_data);
    };
    serviceOption();
  }, []);

  return (
    <Fragment>
        <h6 className="card-title text-center">Tell us your most prefered shoping Category</h6>
      <Grid container spacing={4} className="justify-content-center" noValidate>
       <Grid item xs={8} sm={8}>
          <FormControl
            size="small"
            fullWidth
            variant="outlined"
            required
            margin="normal"
          >
            <Select
             name="Category"
             onChange={handleChange("Category")}
             >
              {country.map((s, idx) => (
                <MenuItem key={idx} value={s.id}>
                  {s.category}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Grid>
      </Grid>
      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button
          variant="contained"
          color="default"
          onClick={handleBack}
          style={{ marginRight: 20 }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          // disabled={!isEmpty || isError}
          color="primary"
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </Fragment>
  );
};

export default SecondStep;
