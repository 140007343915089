import React, { useEffect, useMemo, useState } from "react";
import { Pagination, Search } from "../DataTable";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import theme from "../../data/theme";
import { agenttoken } from "../../api/shop";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import { AddMasterNumber} from '../../store/masterNumber';
import { connect, useDispatch, useSelector } from "react-redux";
const Orders = (props) => {
  const [orders, setOrders] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const {AddMasterNumber}=props
  const ITEMS_PER_PAGE = 7;
  useEffect(() => {
    const getData = () => {
      axios.post(window.$name + "/api/agent/get-agents-orders", {
          agent_token: agenttoken(),
        })
        .then((response) => response.data)
        .then((json) => {
          setOrders(json);
        });
    };
    getData();
  }, []);

  const orderData = useMemo(() => {
    let computedOrders = orders;
    if (search) {
      computedOrders = computedOrders.filter(
        (order) =>
          order.order_number.toLowerCase().includes(search.toLowerCase()) ||
          order.order_status.toLowerCase().includes(search.toLowerCase()) ||
          order.total_vat.toLowerCase().includes(search.toLowerCase()) ||
          order.total_amount.toLowerCase().includes(search.toLowerCase())
      );
    }
    setTotalItems(computedOrders.length);
    //Current Page slice
    return computedOrders.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [orders, currentPage, search]);

  return (
    <div className="cardTop card">   
      <Helmet>
        <title>{`Order History — ${theme.name}`}</title>
      </Helmet>
      <div >
            <div className="card-header">
              <div className="">MY ORDERS</div>
              <div className="d-flex align-items-end flex-column">
                <div className="mt-auto p-2">
                  <Search
                    onSearch={(value) => {
                      setSearch(value);
                      setCurrentPage(1);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="card-divider" />
            <div className="card-table">
              <div className="table-responsive-sm">
                <table>
                  <thead>
                    <tr className="text-center">
                      <th>Order No</th>
                      <th>Buyer Name</th>
                      <th>Total Vat</th>
                      <th>Total Amount</th>
                      <th>Status</th>
                      <th>Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderData.map((order) => (
                      <tr className="text-center">
                        <td>{order.order_number}</td>
                        <td>{order.buyer_info.fullname}</td>
                        <td>{order.total_vat}</td>
                        <td>{order.total_amount}</td>
                          {order.order_status === "pending" ? (
                              <td className="text-danger font-weight-bold">
                                  Pending
                              </td>
                          ) : order.order_status === "Paid" ? (
                              <td className="text-success font-weight-bold">
                                  Paid
                              </td>
                          ) : order.order_status === "Shipped" ? (
                              <td className="text-warning font-weight-bold">
                                  Shipped
                              </td>
                          ) : order.order_status === "Delivered" ? (
                              <td className="text-primary font-weight-bold">
                                  Delivered
                              </td>
                          ) : (
                              <></>
                          )}
                        <td>
                          <Moment format="DD/MM/YYYY">
                            {order.created_at}
                          </Moment>
                        </td>
                        {/* <td>
                          <Link
                            to={{
                              pathname: "/agent/vieworder",
                              state: order.order_number,
                            }}
                          >
                            View Items
                          </Link>
                        </td> */}
                         <td>
                                <div className="dropdown">
                                  <button
                                    class="btn btn-white"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <b>...</b>
                                  </button>
                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                  <Link
                                      className="dropdown-item"
                                      to={{
                                        pathname: "/agent/vieworder",
                                        state: order.order_number,
                                      }}
                                    >
                                      View Items
                                  </Link>
                                    {order.order_status === "pending" ? (                                      
                                          <button
                                            type="button"
                                            onClick={()=>AddMasterNumber(order.order_number)}
                                            className={
                                              "btn btn-block btn-primary rounded "
                                            }
                                          >
                                            Master Number
                                          </button>
                                    ) : (
                                        <></>
                                      )}
                                  </div>

                                </div>
                              </td>

                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-divider" />
            <div className="card-footer">
              {/* <div className="d-flex align-items-center flex-column">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div> */}
            </div>
          </div>
       
        <div className="d-flex align-items-center flex-column">
          <Pagination
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    // </div>
  );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {
  AddMasterNumber
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Orders);
