import {FETCH_DATA} from './productActionTypes'
import Axios from "axios";
import {toast} from "react-toastify";
export function FetchProductDetail(product, options = []){
    return async (dispatch) => (
        Axios.post(window.$name+'/api/business/get-product-price', {
                product_token: product.product_token,
                outlet_token: product.outlets_token
            })
                .then(function (response) {
                    dispatch({type:FETCH_DATA,payload:response})
                })
                .catch(function (error) {
                    if (error.response) {
                        toast.error(`"${error.response.data.message}" Error Status  "${error.response.status}" `);
                    } else if (error.request) {
                        toast.error(`"${error.request}" `);
                    } else {
   
                        toast.error(`"${error.message}" `);
                    }
                })
    );
}
