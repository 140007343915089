import {
  CART_ADD_ITEM,
  CART_LOGIN_ITEM,
  CART_REMOVE_ALL,
  CART_REMOVE_ITEM,
  CART_UPDATE_QUANTITIES,
} from "./cartActionTypes";
import Axios from "axios";
import { customertoken, agentcustomerToken, userCurrency } from "../../api/shop";
import { toast } from "react-toastify";
import Product from "../../components/shared/Product";

/**
 * @param {array} items
 * @param {object} product
 * @param {array} options
 * @return {number}
 */
function findItemIndex(items, product, options) {
  return items.findIndex((item) => {
    if (
      item.product.id !== product.id ||
      item.options.length !== options.length
    ) {
      return false;
    }
    for (let i = 0; i < options.length; i += 1) {
      const option = options[i];
      const itemOption = item.options.find(
        (itemOption) =>
          itemOption.optionId === option.optionId &&
          itemOption.valueId === option.valueId
      );
      if (!itemOption) {
        return false;
      }
    }

    return true;
  });
}

function calcSubtotal(items) {
  return items.reduce((subtotal, item) => subtotal + item.total, 0);
}
// function totalAmount(vattotal,subtotal) {
//     let totalamount = vattotal + (subtotal - vattotal);
//     return totalamount;
// }

function calcVattotal(items) {
  // console.log(items)
  return items.reduce((vattotal, item) => vattotal + item.vattotal, 0);
}

function calcQuantity(items) {
  return items.reduce((quantity, item) => quantity + item.quantity, 0);
}

function calcTotal(subtotal, extraLines) {
  return subtotal + extraLines.reduce((total, extraLine) => total, 0);
}

function addItem(state, product, options, quantity) {
  // console.log(product)
  const Rates=product.rate;
  function convertedPrice() {
    let amount;
    if (userCurrency() === product.currency) {
      amount = Math.round((Number(product.price) / Number(Rates[product.currency])) * Number(Rates[userCurrency()]))
      return amount;
    }
    else {
      amount = ((Number(product.price) / Number(Rates[product.currency])) * Number(Rates[userCurrency()])).toFixed(2)
      return amount;
    }
  }
  function convertedVat() {
    let amount;
    if (userCurrency() === product.currency) {
      amount = Math.round((Number(product.vat) / Number(Rates[product.currency])) * Number(Rates[userCurrency()]))
      return amount;
    }
    else {
      amount = ((Number(product.vat) / Number(Rates[product.currency])) * Number(Rates[userCurrency()])).toFixed(2)
      return amount;
    }
  }
  const itemIndex = findItemIndex(state.items, product, options);
  let newItems;
  let { lastItemId } = state;
  if (itemIndex === -1) {
    lastItemId += 1;
    newItems = [
      ...state.items,
      {
        id: lastItemId,
        product: JSON.parse(JSON.stringify(product)),
        options: JSON.parse(JSON.stringify(options)),
        price:convertedPrice() ,
        total: convertedPrice() * quantity,
        currency: userCurrency(),
        vattotal: convertedVat() * quantity,
        quantity,
      },
    ];
    // console.log("newItems" ,newItems);
  } else {
    const item = state.items[itemIndex];
    newItems = [
      ...state.items.slice(0, itemIndex),
      {
        ...item,
        quantity: item.quantity + quantity,
        total: (item.quantity + quantity) * item.price,
      },
      ...state.items.slice(itemIndex + 1),
    ];
  }

  const subtotal = calcSubtotal(newItems);
  const vattotal = calcVattotal(newItems);
  // const totalamount = totalAmount(vattotal,subtotal);
  const total = calcTotal(subtotal, state.extraLines);
  return {
    ...state,
    lastItemId,
    subtotal,
    vattotal,
    // totalamount,
    total,
    items: newItems,
    quantity: calcQuantity(newItems),
  };
}

function addlOGINItem(state, product, options, quantity) {
  const Rates=product.rate; 
  const itemIndex = findItemIndex(state.items, product, options);
  function Priceconverted() {
    let amount;
    if (userCurrency() === product.currency) {
      amount = Math.round((Number(product.price) / Number(Rates[product.currency])) * Number(Rates[userCurrency()]))
      return amount;
    }
    else {
      amount = ((Number(product.price) / Number(Rates[product.currency])) * Number(Rates[userCurrency()])).toFixed(2)
      return amount;
    }
  }
  function Vatconverted() {
    let amount;
    if (userCurrency() === product.currency) {
      amount = Math.round((Number(product.vat) / Number(Rates[product.currency])) * Number(Rates[userCurrency()]))
      return amount;
    }
    else {
      amount = ((Number(product.vat) / Number(Rates[product.currency])) * Number(Rates[userCurrency()])).toFixed(2)
      return amount;
    }
  }
  let newItems;
  let { lastItemId } = state;
  if (itemIndex === -1) {
    lastItemId += 1;
    newItems = [
      ...state.items,
      {
        id: lastItemId,
        product: JSON.parse(JSON.stringify(product)),
        options: JSON.parse(JSON.stringify(options)),
        // price: product.price,
        price: Priceconverted(),
        vattotal:Vatconverted() * quantity,
        // vattotal: product.vat * quantity,
        total:Priceconverted() * quantity,
        // total:product.price * quantity,
        currency: userCurrency(),
        quantity,
      },
    ];
  } else {
    const item = state.items[itemIndex];
    newItems = [
      ...state.items.slice(0, itemIndex),
      {
        ...item,
        quantity: item.quantity + quantity,
        total: (item.quantity + quantity) *  item.price
      },
      ...state.items.slice(itemIndex + 1),
    ];
  }

  const subtotal = calcSubtotal(newItems);
  const vattotal = calcVattotal(newItems);
  // const totalamount = totalAmount(vattotal,subtotal);
  const total = calcTotal(subtotal, state.extraLines);
  return {
    ...state,
    lastItemId,
    subtotal,
    vattotal,
    // totalamount,
    total,
    items: newItems,
    quantity: calcQuantity(newItems),
  };
}

function removeItem(state, itemId) {
  const { items } = state;
  const newItems = items.filter((item) => item.id !== itemId);
  const subtotal = calcSubtotal(newItems);
  const vattotal = calcVattotal(newItems);
  // const totalamount = totalAmount(vattotal,subtotal);
  const total = calcTotal(subtotal, state.extraLines);

  return {
    ...state,
    items: newItems,
    quantity: calcQuantity(newItems),
    subtotal,
    vattotal,
    // totalamount,
    total,
  };
}

function deleteAll(state) {
  const { items } = state;
  const newItems = [];
  const subtotal = 0;
  const total = 0;
  const vattotal = 0;
  return {
    ...state,
    items: newItems,
    quantity: 0,
    subtotal,
    vattotal,
    // totalamount,
    total,
  };
}
const initialState = {
  lastItemId: 0,
  quantity: 0,
  items: [],
  subtotal: 0,
  vattotal: 0,
  // totalamount:0,
  extraLines: [
    // shipping, taxes, fees, .etc
    {
      type: "shipping",
      title: "Shipping",
      price: 0,
    },
    {
      type: "tax",
      title: "Tax",
      price: 0,
    },
  ],
  total: 0,
};

function updateQuantities(state, quantities) {
  let needUpdate = false;
  const newItems = state.items.map((item) => {
    const quantity = quantities.find(
      (x) => x.itemId === item.id && x.value !== item.quantity
    );
    if (!quantity) {
      return item;
    }
    needUpdate = true;
    return {
      ...item,
      quantity: quantity.value,
      total: quantity.value * item.price,
    };
  });
  if (customertoken()) {
    newItems.forEach((data) => {
      let dataPosted = {
        secure_token: customertoken(),
        product_token: data.product.product_token,
        quantity: data.quantity,
      };
      Axios.post(
        window.$name + "/api/cart/update-quantity-in-the-cart",
        dataPosted
      ).then((response) => { });
    });
    toast.success(`Successfully Updated Quantities`);
  } else {
    newItems.forEach((data) => {
      let dataPosted = {
        secure_token: agentcustomerToken(),
        product_token: data.product.product_token,
        quantity: data.quantity,
      };
      Axios.post(
        window.$name + "/api/cart/update-quantity-in-the-cart",
        dataPosted
      ).then((response) => { });
    });
    toast.success(`Successfully Updated Quantities`);
  }

  if (needUpdate) {
    const subtotal = calcSubtotal(newItems);
    const vattotal = calcVattotal(newItems);
    // const totalamount = totalAmount(vattotal,subtotal);
    const total = calcTotal(subtotal, state.extraLines);
    return {
      ...state,
      items: newItems,
      quantity: calcQuantity(newItems),
      subtotal,
      vattotal,
      // totalamount,
      total,
    };
  }
  return state;
}

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case CART_ADD_ITEM:
      return addItem(state, action.product, action.options, action.quantity);

    case CART_LOGIN_ITEM:
      return addlOGINItem(
        state,
        action.product,
        action.options,
        action.quantity
      );
    case CART_REMOVE_ITEM:
      return removeItem(state, action.itemId);

    case CART_REMOVE_ALL:
      return deleteAll(state);

    case CART_UPDATE_QUANTITIES:
      return updateQuantities(state, action.quantities);

    default:
      return state;
  }
}
