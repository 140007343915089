import React, {Component} from 'react';
import Moment from "react-moment";
import moment from "moment";
function WidgetOpenHours(props){
    const {open,holidays,schedule}=props
   const state = {
        title: 'Opening Hours',
        stitle: 'now open',
        items: [
            {
                day: 'Monday',
                time: '9am - 5pm',
                close: false,
            },
            {
                day: 'Tuesday',
                time: '9am - 5pm',
                close: false,
            },
            {
                day: 'Wednesday',
                time: '9am - 5pm',
                close: false,
            },
            {
                day: 'Thursday',
                time: '9am - 5pm',
                close: false,
            },
            {
                day: 'Friday',
                time: '9am - 5pm',
                close: false,
            },
            {
                day: 'Sat-Sun',
                time: 'Closed',
                close: true,
            }
        ]
    }
        return (
            <>
                <div className="sidebar-widget">
                    <div className="opening-hours">
                        <div className="listing-badge d-flex justify-content-between align-items-center">
                            <div>
                                <h3 className="widget-title">
                                   Opening Hours
                                </h3>
                                <div className="title-shape"></div>
                            </div>
                            {/*<p><span className="theme-btn button-success">*/}
                            {/*    Now Open*/}
                            {/*</span></p>*/}
                        </div>
                        <ul className="list-items padding-top-30px">
                            {schedule === null || schedule.schedule === "selected" && open !== null ? 
                            open.map((item, i) => {
                                return (
                                    <li key={i} className="d-flex justify-content-between">
                                        {item.day}
                                        <strong className='text-black font-weight-medium'>
                                            {/*<Moment  format="LT">{item.opening_time} </Moment>*/}
                                            {item.opening_time}
                                        {" "} - {" "}
                                            {item.closing_time}

                                        </strong>
                                    </li>
                                )
                            }) : 
                                schedule.schedule === "always" ?
                                (
                                    <li className="d-flex justify-content-between">
                                       <h6 className="text-success">Open 24 hours</h6>
                                    </li>
                                ):(
                                 
                                        <li className="d-flex justify-content-between">
                                         <h6  className="text-success">Open by appointment only</h6>
                                        </li>
                                    )
                             }
                        </ul>
                    </div>
                    <div className="opening-hours">
                        <div className="listing-badge d-flex justify-content-between align-items-center">
                            <div>
                                <h3 className="widget-title">
                                  Holidays
                                </h3>
                                <div className="title-shape"></div>
                            </div>
                        </div>
                        <ul className="list-items padding-top-30px">
                            {holidays.map((item, i) => {
                                return (
                                    <li key={i} className="d-flex justify-content-between">
                                        {item.holiday_name}
                                        <strong className='font-weight-medium'>
                                            {item.is_serving ?
                                            <h6 className="text-success">Open</h6>:
                                                <h6 className="text-danger">Closed</h6>
                                            }
                                    </strong>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </>
        );
}

export default WidgetOpenHours;
