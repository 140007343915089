import { QUICKVIEW_CLOSE, QUICKVIEW_OPEN } from './quickviewActionTypes';


const initialState = {
    open: false,
    product: null,
    rate: null,
    review_rates:null
};

export default function quickviewReducer(state = initialState, action) {
    // console.log(JSON.stringify(action.rate));
    let newState = state;
    if (action.type === QUICKVIEW_OPEN) {
        newState = {
            ...state,
            open: true,
            product: JSON.parse(JSON.stringify(action.product)),
            rate:action.rate,
            review_rates:action.review_rates
        };
    } else if (action.type === QUICKVIEW_CLOSE) {
        newState = {
            ...state,
            open: false,
        };
    }

    return newState;
}
