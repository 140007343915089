// react
import React, {useEffect, useState} from "react";
// third-party
import { Link } from "react-router-dom";
// application
import Indicator from "./Indicator";
import { Person20Svg } from "../../svg";
import { connect, useDispatch, useSelector } from "react-redux";
import AsyncAction from "../shared/AsyncAction";
import { LogoutUser } from "../../store/auth/userLoginActions";
import { FetchAgentprofile } from "../../store/Agent";
import {agenttoken, customertoken} from "../../api/shop";
import {Fetchprofile} from "../../store/profile";
import {useHistory} from "react-router";

function IndicatorAccount(props) {
    const history=useHistory()
    const dispatch = useDispatch();
    useEffect(() => {
        if (customertoken()) {
            dispatch(Fetchprofile());
        }
    }, [dispatch]);

    useEffect(() => {
        if (agenttoken()) {
            dispatch(FetchAgentprofile());
        }
    }, []);

    const ProfileDataList = useSelector((state) => state.profile);
    const AgentDataList = useSelector((state) => state.AgentProfile);
    const [profiles, setProfile] = useState([]);
    const [images, setImages] = useState("");
    const {authentication, LogoutUser } = props;
    useEffect(() => {
        setProfile(ProfileDataList);
        if(customertoken()){
            setImages(ProfileDataList.Profileimages);
        }
        else if(agenttoken()){
            setImages(AgentDataList.Profileimages);
        }

    }, [ProfileDataList]);

// console.log("profile here",profiles)

    let profile;
    if (agenttoken()) {
        profile = (
            <Link to="/agent/dashboard" className="account-menu__user">
                {profiles === null || images === undefined ?(
                    <div className="account-menu__user-avatar">
                        <img src="images/avatars/profile.webp" alt="" />
                    </div>
                ):(
                    <div className="account-menu__user-avatar">
                        {images === "" ? (
                            <div>Loading......</div>
                        ) : (
                            <img
                                // src={images.image_name}
                                alt=""
                            />
                        )}
                    </div>
                )


                }
                <div className="account-menu__user-info">
                    <div className="account-menu__user-name">
                        {AgentDataList.fullname}
                    </div>
                    <div className="account-menu__user-email">{AgentDataList.email}</div>
                </div>
            </Link>
        );
    } else {
        profile = (
            <Link to="/account/dashboard" className="account-menu__user">
                {profiles.Profileimages === null || images === undefined ?(
                    <div className="account-menu__user-avatar">
                        <img src="images/avatars/profile.webp" alt="" />
                    </div>
                ):(
                    <div className="account-menu__user-avatar">
                        {images === "" ? (
                            <div>Loading......</div>
                        ) : (
                            <img
                                src={images.image_name}
                                alt=""
                            />
                        )}
                    </div>
                )


                }
                <div className="account-menu__user-info">
                    <div className="account-menu__user-name">{profiles.fullname}</div>
                    <div className="account-menu__user-email">{profiles.email}</div>
                </div>

            </Link>
        );
    }
    let login;
    login = (
        <div className="account-menu">
            <div className="account-menu__divider" />
            <div className="account-menu__divider" />
            <ul className="account-menu__links">
                <li>
                    <Link to="/account/register">
                        Create Account
                    </Link>
                </li>
                <li>
                    <Link to="/account/login">
                        Login now
                    </Link>
                </li>
            </ul>
        </div>
    );
    if (authentication.isLogin) {
        if (agenttoken()) {
            const dropdown = (
                <div className="account-menu">
                    <div className="account-menu__divider" />
                    <div>{profile}</div>
                    <div className="account-menu__divider" />
                    <ul className="account-menu__links">
                        <li>
                            <Link to="/agent/dashboard">My Profile</Link>
                        </li>
                        <li>
                            <Link to="/agent/customers">Customers</Link>
                        </li>
                        <li>
                            <Link to="/agent/orders">My Orders </Link>
                        </li>
                        <li>
                            <Link to="/agent/password">Change Password</Link>
                        </li>
                    </ul>
                    <div className="account-menu__divider" />
                    <ul className="account-menu__links">
                        <li>
                            <AsyncAction
                                action={() => LogoutUser()}
                                render={({ run }) => (
                                    <button
                                        type="button"
                                        onClick={run}
                                        className={"btn btn-block btn-primary rounded "}
                                    >
                                        {" "}
                                        <i className="fa fa-power-off text-danger mr-2"> </i>
                                        Logout
                                    </button>
                                )}
                            />
                        </li>
                    </ul>
                </div>
            );
            return <Indicator dropdown={dropdown} icon={<Person20Svg />} />;
        } else {
            const dropdown = (
                <div className="account-menu">
                    <div className="account-menu__divider" />
                    <div>{profile}</div>
                    <div className="account-menu__divider" />
                    <ul className="account-menu__links">
                        <li>
                            <Link to="/account/dashboard">My Profile</Link>
                        </li>
                        <li>
                            <Link to="/account/addresses">Address Book</Link>
                        </li>
                        <li>
                            <Link to="/account/quotation-list">My Quote</Link>
                        </li>
                        <li>
                            <Link to="/account/orders/5">My Order</Link>
                        </li>
                    </ul>
                    <div className="account-menu__divider" />
                    <ul className="account-menu__links">
                        <li>
                            <AsyncAction
                                action={() => LogoutUser(history)}
                                render={({ run }) => (
                                    <button
                                        type="button"
                                        onClick={run}
                                        className={"btn btn-block btn-primary rounded "}
                                    >
                                        {" "}
                                        <i className="fa fa-power-off text-danger mr-2"> </i>
                                        Logout
                                    </button>
                                )}
                            />
                        </li>
                    </ul>
                </div>
            );
            return <Indicator dropdown={dropdown} icon={<Person20Svg />} />;
        }
    } else {
        return <Indicator dropdown={login} icon={<Person20Svg />} />;
    }
}

const mapStateToProps = (state) => ({
    profiledata: state.profile,
    authentication: state.auth,
});

const mapDispatchToProps = {
    LogoutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorAccount);
