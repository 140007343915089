import React from 'react'
import FloatingWhatsApp from 'react-floating-whatsapp'
import Logo from './Boxes.png'

export default function WhatsAppWidget() {

  return (
    <div className="App">
      <FloatingWhatsApp
        phoneNumber="255743779999"
        accountName="Ezytrade Africa Limited"
        notification
        notificationDelay={30000} // 1 minute
        notificationSound={false}
        chatMessage="Hello there how can we help you"
        allowClickAway={false}
        avatar={Logo}
        statusMessage="Available to provide service 24 hours"
      />
    </div>
  )

}