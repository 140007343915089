import {APPLIED_RATE_DATA} from "./AppliedQuoteActionTypes";
export default function reducerAppliedRate(state=[],action) {
    // console.log(action.Rate);
    switch(action.type){
        case APPLIED_RATE_DATA:
            return action.Rate        
        default:
            return state;
    }
}
