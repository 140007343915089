import {DATA_SHIPPING_OPT} from "./shippingOptType";

export default function shippingOptReducer(state = {data:"",True:false}, action) {
    switch (action.type) {
        case DATA_SHIPPING_OPT:
            return action.payload
     
        default:
            return state;
    }
}
