import { toast } from "react-toastify";
import {
  DIGITAL_CART_ADD_ITEM,
  DIGITAL_CART_LOGIN_ITEM,
  DIGITAL_CART_REMOVE_ITEM,
  DIGITAL_CART_UPDATE_QUANTITIES,
  DIGITAL_CART_REMOVE_ALL,
} from "./digitalcartActionTypes";
import Axios from "axios";
import { agenttoken, customertoken, agentcustomerToken } from "../../api/shop";

export function digitalcartAddItemSuccess(product, options = [], quantity = 1) {
  toast.success(`Product "${product.name}" added to cart!`);
  return {
    type: DIGITAL_CART_ADD_ITEM,
    product,
    options,
    quantity,
  };
}

export function digitalcartAddLoginItemSuccess(product, options = [], quantity) {
  // console.log("product cart data",product);

  return {
    type: DIGITAL_CART_LOGIN_ITEM,
    product,
    options,
    quantity,
  };
}

export function digitalcartRemoveItemSuccess(itemId) {
  return {
    type: DIGITAL_CART_REMOVE_ITEM,
    itemId,
  };
}

export function digitalcartRemove() {
  return {
    type: DIGITAL_CART_REMOVE_ALL,
  };
}

export function digitalcartUpdateQuantitiesSuccess(quantities) {
  return {
    type: DIGITAL_CART_UPDATE_QUANTITIES,
    quantities,
  };
}
export function digitalAddcartData() {
  const carts = JSON.parse(localStorage.getItem("state"));
  let cartArray = carts.cart.items;
  cartArray.forEach((data) => {
    console.log(data);
    let detail_data = {
      
      secure_token: customertoken(),
      product_token: data.product.product_token,
      outlet_token: data.product.outlets_token,
      preference_color: data.product.preference_color,
      preference_size: data.product.preference_size,
      shop_id: data.product.shop_id,
      quantity: data.quantity,
      custom_quantity: data.product.unit,
      custom_unit_measure: data.unitMeasure,
      seller_token: data.product.seller_token,
      product_detail: "",
      total_amount: data.total,
      currency: data.product.currency,
      vat: data.product.vat,

      // secure_token: customertoken(),
      // product_token: product.product_token,
      // outlet_token: product.outlets_token,
      // preference_color: product.preference_color,
      // preference_size: product.preference_size,
      // shop_id: product.shop_id,
      // quantity: quantity,
      // custom_quantity: product.unit,
      // custom_unit_measure: product.unitMeasure,
      // seller_token: product.seller_token,
      // product_detail: "",
      // total_amount: product.price,/
      // currency: product.currency,
      // vat: product.vat,
    };
    Axios.post(window.$name + "/api/cart/add-to-cart", detail_data)
      .then(function (response) {
        toast.success(`Product Added to Cart Successfully `);
      })
      .catch(function (error) {
        if (error.response) {
          toast.error(
            `"${error.response.data.message}" Error Status  "${error.response.status}" `
          );
        } else if (error.request) {
          toast.error(`"${error.request}" `);
        } else {
          toast.error(`"${error.message}" `);
        }
      });
  });
}

export function digitalCartDataFromDb(options = []) {
  const agentcustomer_Token = agentcustomerToken();
  const agentToken = agenttoken();
  return (dispatch) =>
    new Promise((resolve) => {
      setTimeout(() => {
        if (agentcustomer_Token !== null && agentToken !== null) {
          Axios.post(window.$name + "/api/cart/get-digital-cart", {
            user_token: agentcustomer_Token,
          })
            .then(function (response) {
              let cart_list = response.data;
              // console.log(" cart data",response.data);
              dispatch(digitalcartRemove());
              cart_list.forEach((item) => {
                let product = {
                  id: item.id,
                  name: item.product.product_name,
                  slug: item.product.product_name,
                  price: Number(item.total_amount),
                  vat: item.vat,
                  rate:JSON.parse(item.rates.rate),
                  wholesale_price: 0,
                  currency: item.currency,
                  currency_wholesale: item.currency,
                  discount: 0,
                  compareAtPrice: null,
                  status: item.product.is_featured,
                  images: [item.product_images.productImage_name],
                  shop_id: item.outlet_info.id,
                  seller_token: item.seller_token,
                  secure_token: item.secure_token,
                  product_token: item.product_token,
                  outlets_token: item.outlet_token,
                  Category: "",
                  badges: [],
                  rating: 5,
                  reviews: 3,
                  brand: item.product.brand,
                  categories: [],
                  attributes: [],
                  customFields: {},
                };
                const quantity = item.quantity;
                // console.log(" cart data",product.vat);
                dispatch(digitalcartAddLoginItemSuccess(product, options, quantity));
              });
              localStorage.setItem("digitalcartData", JSON.stringify(response.data));
            })
            .catch(function (error) {
              console.log(error);
            });
        } 
        else {
          Axios.post(window.$name + "/api/cart/get-digital-cart", {
            user_token: customertoken(),
          })
            .then(function (response) {
              let cart_list = response.data;
              // console.log("1232345566", response.data);
              dispatch(digitalcartRemove());
              cart_list.forEach((item) => {
                let product = {
                  id: item.id,
                  name: item.product.product_name,
                  slug: item.product.product_name,
                  price: Number(item.total_amount),
                  vat: item.vat,
                  rate:JSON.parse(item.rates.rate),
                  wholesale_price: 0,
                  currency: item.currency,
                  currency_wholesale: item.currency,
                  discount: 0,
                  compareAtPrice: null,
                  status: item.product.is_featured,
                  images: [item.product_images.productImage_name],
                  shop_id: item.outlet_info.id,
                  seller_token: item.seller_token,
                  secure_token: item.secure_token,
                  product_token: item.product_token,
                  outlets_token: item.outlet_token,
                  Category: "",
                  badges: [],
                  rating: 5,
                  reviews: 3,
                  brand: item.product.brand,
                  categories: [],
                  attributes: [],
                  customFields: {},
                };
                const quantity = item.quantity;
                dispatch(digitalcartAddLoginItemSuccess(product, options, quantity));
              });
              // console.log(response.data);
              localStorage.setItem("digitalcartData", JSON.stringify(response.data));
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    });
}

export function digitalcartAddItem(product, options = [], quantity = 1) {
  // console.log("cart data",product);
  const agentcustomer_Token = agentcustomerToken();
  const agentToken = agenttoken();
  return (dispatch) =>
    new Promise((resolve) => {
      setTimeout(() => {
        if (agentcustomer_Token !== null && agentToken !== null) {
          let detail_data = {            
            secure_token: agentcustomer_Token,
            product_token: product.product_token,
            outlet_token: product.outlets_token,
            quantity: quantity,
            seller_token: product.seller_token,
            total_amount: product.price,
            currency: product.currency,
            vat: product.vat,
          };
          Axios.post(window.$name + "/api/cart/add-digital-cart", detail_data)
            .then(function (response) {
              if (response.status === 201) {
                toast.success(`Product ${product.name} added to cart!`);
                dispatch(digitalCartDataFromDb());
                dispatch(digitalcartAddLoginItemSuccess(product, options, quantity));
              } else {
                toast.info(`${response.data.message}`);
              }
            })
            .catch(function (error) {
              if (error.response) {
                toast.error(
                  `${error.response.data.message} Error Status  ${error.response.status}`
                );
              } else if (error.request) {
                toast.error(`${error.request} `);
              } else {
                toast.error(`${error.message} `);
              }
            });
        }
        else if (customertoken()) {
          // console.log(product)
          let detail_data = {
            secure_token: customertoken(),
            product_token: product.product_token,
            outlet_token: product.outlets_token,
            quantity: quantity,
            seller_token: product.seller_token,
            total_amount: product.price,
            currency: product.currency,
            vat: product.vat,
          };
          Axios.post(window.$name + "/api/cart/add-digital-cart", detail_data)
            .then(function (response) {
              if (response.status === 201) {
                toast.success(`Product ${product.name} added to cart!`);
                dispatch(digitalCartDataFromDb());
                dispatch(digitalcartAddLoginItemSuccess(product, options, quantity));                

              } else {
                toast.info(`${response.data.message}`);
              }
            })
            .catch(function (error) {             
              if (error.response) {              
                toast.error(
                  `${error.response.data.message} Error Status  ${error.response.status}`
                );
              }               
            });
        }
        else {
          // console.log(product)
          dispatch(digitalcartAddItemSuccess(product, options, quantity));
        }
        resolve();
      }, 500);
    });
}

export function digitalcartRemoveItem(itemId, product_token) {
  // console.log(itemId);
  const agentcustomer_Token = agentcustomerToken();
  const agentToken = agenttoken();
  return (dispatch) =>
    new Promise((resolve) => {
      setTimeout(() => {
        if (agentcustomer_Token !== null && agentToken !== null) {
          let detail_data = {
            secure_token: agentcustomer_Token,
            product_token: product_token,
          };
          Axios.post(window.$name + "/api/cart/remove-from-cart", detail_data)
            .then(function (response) {
              toast.success(`"${response.data.message}" `);
              dispatch(digitalcartRemoveItemSuccess(itemId));
              dispatch(digitalCartDataFromDb());
            })
            .catch(function (error) {
              if (error.response) {
                toast.error(
                  `${error.response.data.message} Error Status  ${error.response.status} `
                );
              } else if (error.request) {
                toast.error(`${error.request} `);
              } else {
                toast.error(`${error.message} `);
              }
            });
        } else if (customertoken()) {
          let detail_data = {
            secure_token: customertoken(),
            product_token: product_token,
          };
          Axios.post(window.$name + "/api/cart/remove-from-cart", detail_data)
            .then(function (response) {
              toast.success(`"${response.data.message}" `);
              dispatch(digitalcartRemoveItemSuccess(itemId));
              dispatch(digitalCartDataFromDb());
            })
            .catch(function (error) {
              if (error.response) {
                toast.error(
                  `${error.response.data.message} Error Status  ${error.response.status} `
                );
              } else if (error.request) {
                toast.error(`${error.request} `);
              } else {
                toast.error(`${error.message} `);
              }
            });
        } else {
          dispatch(digitalcartRemoveItemSuccess(itemId));
        }
        resolve();
      }, 500);
    });
}

export function digitalcartUpdateQuantities(quantities) {
  // sending request to server, timeout is used as a stub
  return (dispatch) =>
    new Promise((resolve) => {
      setTimeout(() => {
        dispatch(digitalcartUpdateQuantitiesSuccess(quantities));
        dispatch(digitalCartDataFromDb());
        resolve();
      }, 500);
    });
}
