// react
import React, {useEffect, useMemo, useState} from "react";
import axios from "axios";
// third-party
import {DropzoneArea} from "material-ui-dropzone";
import {toast} from "react-toastify";
import {Helmet} from "react-helmet-async";
import {Link} from "react-router-dom";
import theme from "../../data/theme";
import {agenttoken, customertoken} from "../../api/shop";
import {FetchShippingAddress} from "../../store/shippingAddress";
import {AddAddress, ChangeAddress} from "../../store/shippingAddress";
import {connect, useDispatch} from "react-redux";
// import {  } from "../../store/profile";
import {EditProfile, Fetchprofile} from "../../store/profile";
import AsyncAction from "../shared/AsyncAction";
import {useFormik} from "formik";
import * as yup from "yup";
import Moment from "react-moment";
import {Pagination, Search} from "../DataTable";
import {CartDataFromDb} from "../../store/cart";
import store from '../../store/store';

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = yup.object({
    fullname: yup.string().required("Please enter your fullname"),
    email: yup.string().email().required("Email is a required field"),
    user_phoneNumber: yup
        .string()
        .required("Please confirm your mobile number")
        .matches(phoneRegExp, "Phone Number is not valid")
        .min(10, "Must be at least 10 characters")
        .max(13, "Phone number is too long, Not Great than 13 characters"),
});

function AccountPageDashboard(props) {
    const dispatch = useDispatch();
    //order area
    const [orders, setOrders] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const ITEMS_PER_PAGE = 5;
    const user_token = customertoken();
    // console.log(orders)
    useEffect(() => {
        const getData = () => {
            axios.post(window.$name + "/api/agent/get-agents-orders", {
                agent_token: agenttoken(),
            })
                .then((response) => response.data)
                .then((json) => {
                    setOrders(json);
                });
        };
        getData();
    }, []);
    const orderData = useMemo(() => {
        let computedOrders = orders;
        if (search) {
            computedOrders = computedOrders.filter(
                (order) =>
                    order.order_number.toLowerCase().includes(search.toLowerCase()) ||
                    order.order_status.toLowerCase().includes(search.toLowerCase()) ||
                    order.total_vat.toLowerCase().includes(search.toLowerCase()) ||
                    order.total_amount.toLowerCase().includes(search.toLowerCase())
            );
        }
        setTotalItems(computedOrders.length);
        //Current Page slice
        return computedOrders.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [orders, currentPage, search]);
    return (
        <div className="dashboard">
            <Helmet>
                <title>{`Ezytrade Agent — ${theme.name}`}</title>
            </Helmet>

            <div className="cardTop card">
                <div className="card-header">
                    {/* <div className="">RECENT ORDERS</div> */}
                    <div className="d-flex align-items-end flex-column">
                        <div className="mt-auto p-2">
                            <Search
                                onSearch={(value) => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-divider"/>
                <div className="card-table">
                    <div className="table-responsive-sm">
                        <table>
                            <thead>
                            <tr className="text-center">
                                {/* <th>Sales No</th> */}
                                <th>Buyer</th>
                                <th>Payment Method</th>
                                <th>Payment Reference</th>
                                {/*<th>account number</th>*/}
                                <th>vat</th>
                                <th>total amount</th>
                                <th>Date</th>
                                {/*<th>action</th>*/}
                            </tr>
                            </thead>
                            <tbody>

                            { orderData.slice(0, 5).filter(index => index.order_status === "Paid").map((order) => (
                                // orderData.slice(0, 5).map((order) => (
                                <tr className="text-center">
                                    <td>{order.buyer_info.fullname}</td>
                                    <td>{order.paid_method}</td>
                                    {/*<td className="text-success font-weight-bold">{order.order_status}</td>*/}
                                    <td>{order.order_number}</td>
                                    <td>{order.total_vat}</td>
                                    <td>{order.total_amount}</td>
                                    <td>
                                        <Moment format="DD/MM/YYYY">{order.created_at}</Moment>
                                    </td>
                                    {/*<td>*/}
                                    {/*    <Link*/}
                                    {/*        to={{pathname: "/account/vieworder", state: [order]}}*/}
                                    {/*    >*/}
                                    {/*        View More*/}
                                    {/*    </Link>*/}
                                    {/*</td>*/}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="card-divider"/>
                <div className="card-footer">
                    {/* <div className="d-flex align-items-center flex-column">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div> */}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    shippingAddress: state.shippingAddress,
    profile: state.profile,
});

const mapDispatchToProps = {
    FetchShippingAddress,
    AddAddress,
    ChangeAddress,
    EditProfile,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPageDashboard);
