import {ADD_MY_ADDRESS, FETCH_MY_ADDRESS,EDIT_MY_ADDRESS} from "./myshippingActionTypes";

export default function myshippingReducer(state =[], action) {
    switch (action.type) {
        case FETCH_MY_ADDRESS:
            return action.payload
        case ADD_MY_ADDRESS:
            return action.payload
        case EDIT_MY_ADDRESS:
            return action.payload
        default:
            return state;
    }
}


