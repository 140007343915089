// react
import React, {useState} from "react";
// third-party
import {toast} from "react-toastify";
import {Helmet} from "react-helmet-async";
import theme from "../../data/theme";
import {connect} from "react-redux";
import AsyncAction from "../shared/AsyncAction";
import {useFormik} from "formik";
import * as yup from "yup";
import {CreateProductLeads} from "../../store/ProductLeads";
import {useHistory} from "react-router";
import { ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

//creating the validation schema
const validationSchema = yup.object().shape({
    product_name: yup
        .string()
        .required("Product Name is required"),
    // product_description: yup
    //     .string()
    //     .required("Product Description is required"),
    quantity: yup
        .string()
        .required("Product Quantity is required"),
});

function AccountPageDashboard(props) {
    const [arrayData, setArray] = useState([]);
    const {CreateProductLeads, agent_id, customer_id} = props
    let _contentState = ContentState.createFromText('');
    const raw = convertToRaw(_contentState)
    const [contentState, setContentState] = useState(raw) // ContentState JSON

    const description=contentState.blocks[0].text
    const initialValues = {
        product_name: "",
        // product_description: description,
        quantity: "",
    };
    // console.log(description)
    const history = useHistory()

    //using useFormik
    const formik = useFormik({
        initialValues,
        validationSchema,
    });
    // console.log(formik.values)

    // append data into array
    function AddLeadsArray(NewValue) {
        if (NewValue) {
            NewValue.product_description=description
            if (!arrayData.includes(NewValue)) {
                setArray(oldArray => [...oldArray, NewValue]);
                toast.success("Product  added ,Successfully");
            } else {
                toast.error("Product already added")
            }
            return arrayData
        } else {
            return arrayData
        }
    }

    function handleRemoveLeadsArray(NewValue) {
        const newArray = arrayData.filter((item) => item.product_name !== NewValue.product_name);
        setArray(newArray)
        toast.success("Product removed ,Successfully");
    }

    return (
        <div className="dashboard">
            <Helmet>
                <title>{`Ezytrade Agent — ${theme.name}`}</title>
            </Helmet>
            <div className="cardTop card ">
                <div className="d-flex card-header">
                    <div className="p-2 font-weight-bold"></div>
                    <div className="ml-auto p-2">
                        <button
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            className="btn btn-outline-warning btn-sm rounded text-dark"
                        >
                            <i className="fa fa-edit"></i>
                            Add Product
                        </button>
                    </div>
                </div>
                <div
                    class="modal fade"
                    id="exampleModalCenter"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5
                                    className="container text-center modal-title"
                                    id="exampleModalLongTitle"
                                >
                                    PRODUCT DETAILS
                                </h5>

                                <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div className="form-row">
                                        <div className="form-group col ">
                                            <label>Product Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="product_name"
                                                placeholder="product_name"
                                                value={formik.values.product_name}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />

                                            {formik.touched.product_name && formik.errors.product_name ? (
                                                <div className="text-danger">
                                                    {formik.errors.product_name}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group col ">
                                            <label>Quantity</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="quantity"
                                                placeholder="quantity"
                                                value={formik.values.quantity}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />

                                            {formik.touched.quantity && formik.errors.quantity ? (
                                                <div className="text-danger">
                                                    {formik.errors.quantity}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-12">
                                            <label htmlFor="checkout-email">Product Description </label>
                                            {/*<textarea*/}
                                            {/*    id="product_description"*/}
                                            {/*    type="text"*/}
                                            {/*    className="form-control"*/}
                                            {/*    placeholder="Product description"*/}
                                            {/*    value={formik.values.product_description}*/}
                                            {/*    onChange={formik.handleChange}*/}
                                            {/*    onBlur={formik.handleBlur}*/}
                                            {/*/>*/}
                                            <Editor
                                                defaultContentState={contentState}
                                                onContentStateChange={setContentState}
                                                wrapperClassName="wrapper-class"
                                                editorClassName="editor-class"
                                                toolbarClassName="toolbar-class"
                                            />
                                            {/*{formik.touched.product_description && formik.errors.product_description ? (*/}
                                            {/*    <div className="text-danger">{formik.errors.product_description}</div>*/}
                                            {/*) : null}*/}
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <button
                                            type="button"
                                            onClick={() => AddLeadsArray(formik.values)}
                                            className={"btn btn-block btn-primary rounded "}
                                            disabled={!(formik.isValid && formik.dirty)}
                                            data-dismiss="modal"
                                        >
                                            Collect Product
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    data-dismiss="modal"
                                >
                                    Close Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex card-header">
                    {/* <div className="p-2 font-weight-bold">LIST OF CUSTOMERS</div> */}
                    <div className="site-header__search">
                        {/*<SearchUser context="header"/>*/}
                        <br/>
                        {arrayData.length > 0 ?
                            <div className="card-table mt-2">
                                <div className="table-responsive-sm">
                                    <table>
                                        <thead>
                                        <tr className="text-center">
                                            <th>Product Name</th>
                                            <th>Quantity</th>
                                            <th>Product Description</th>
                                            <th>Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {arrayData.map((index) => (
                                            <tr className="text-center">
                                                <td>{index.product_name}</td>
                                                <td>{index.quantity}</td>
                                                <td>{index.product_description}</td>
                                                <td>
                                                    <button
                                                        type="button"
                                                        onClick={() => handleRemoveLeadsArray(index)}
                                                        className={"btn btn-outline-danger"}
                                                        disabled={!(formik.isValid && formik.dirty)}
                                                        data-dismiss="modal"
                                                    >
                                                        Remove
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </table>
                                    <div className="float-right justify-content-end">
                                        <AsyncAction
                                            action={() => CreateProductLeads(agent_id, customer_id, arrayData, history)}
                                            render={({run, loading}) => (
                                                <button
                                                    type="button"
                                                    onClick={run}
                                                    className={"btn btn-block btn-primary rounded "}
                                                    data-dismiss="modal"
                                                >
                                                    Create Leads
                                                </button>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            : null
                        }

                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
    CreateProductLeads
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPageDashboard);
