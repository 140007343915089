import React from 'react';
import {connect} from "react-redux";
import {AddBookmark} from "../../store/bookMark/bookmarkActions";
import AsyncAction from "../shared/AsyncAction";
import {RiBookmarkLine} from "react-icons/ri";
import { agentcustomerToken, customertoken } from '../../api/shop';

function BookmarkButton(props) {
    const {AddBookmark, id,isLogin} = props
    console.log(isLogin)
    return (
        <AsyncAction
            action={() => AddBookmark(id)}
            render={({run}) => (
                <button
                    type="button"
                    onClick={run}
                    disabled={!isLogin===true}
                    className={"btn btn-block btn-primary rounded "}
                >
                    <i className="d-inline-block"><RiBookmarkLine /></i>  Bookmark
                </button>
            )}
        />
    )
}

const mapStateToProps = (state) => ({
    isLogin:state.auth.isLogin
});
const mapDispatchToProps = {
    AddBookmark
};
export default connect(mapStateToProps, mapDispatchToProps)(BookmarkButton);
