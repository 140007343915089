import {SHIPPED_DELETE_ORDER_DATA,SHIPPED_ORDER_DATA} from "./orderShippedActionTypes";

export default function orderShippedReducer(state=[],action) {
    switch(action.type){
        case SHIPPED_ORDER_DATA:
            return action.payload
        case SHIPPED_DELETE_ORDER_DATA:
            return action.payload
        default:
            return state;
    }
}
