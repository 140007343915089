import {LISTING_VALUE} from "./ListingActionTypes";

export default function  ListingReducer(state=[],action) {
    switch(action.type){

        case LISTING_VALUE:
            return action.payload
        default:
            return state;
    }
}
