// react
import React, { useEffect,useState,useMemo } from "react";
// third-party
// import axios from 'axios';
import { Helmet } from "react-helmet-async";
import { agenttoken } from "../../api/shop";
import { useProductTabs } from "../../services/hooks";
import BlockCategories from "../blocks/BlockCategories";
import BlockFeatures from "../blocks/BlockFeatures";
import BlockProductsCarousel from "../blocks/BlockProductsCarousel";
import BlockSlideShow from "../blocks/BlockSlideShow";
import BlockFeaturesm from "../blocks/BlockFeaturesm";
import {  useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import digitalshopApi from "../../api/digitalApi";
import ServiceCarousel from "../shop/services/serviceCarousel";
import serviceShopApi from "../../api/serviceApi";
// import BlockCategoryCarousel from "../blocks/BlockCategoriesCarousel";
function HomePageService(props) {
    // console.log(window.location.pathname)
    const LoginAuth = useSelector(state => state.auth.isLogin);
    const AgentcustomerToken = props.location.state;
    //  if(LoginAuth && agenttoken() !== null && AgentcustomerToken !== undefined){
    //   localStorage.setItem("agentcustomer_token",AgentcustomerToken);
    //  }


    const latestServices = useProductTabs(
        useMemo(() => [], []),
        (tab) => serviceShopApi.getLatestService({ limit: 12 })
    );
    const NewArrivalsProducts = useProductTabs(
        useMemo(() => [], []),
        (tab) => digitalshopApi.NewArrivalsProducts({ limit: 12 })
    );

    /**
     * Featured products.
     */
    const featuredService = useProductTabs(
        useMemo(() => [], []),
        (tab) => serviceShopApi.getFeaturedService({ limit: 12 })
    );
    // console.log(featuredService.data)
    return (
        <React.Fragment>
            <Helmet>
                <title>Ezytrade Africa: Manufactures,Suppliers,E..</title>
            </Helmet>
            {useMemo(
                () => (
                    <BlockSlideShow  withDepartments />
                ),
                []
            )}
            {useMemo(
                () => (
                    <BlockFeatures />
                ),
                []
            )}
            {useMemo(
                () => (
                    <ServiceCarousel
                        title="Featured Services"
                        layout="grid-4"
                        rows={1}
                        products={featuredService.data}
                        loading={featuredService.isLoading}
                        groups={featuredService.tabs}
                        onGroupClick={featuredService.handleTabChange}
                    />
                ),
                [featuredService]
            )}
            {/*{popularProducts.data.length >=7 ?*/}
            {/*    (*/}
                    <ServiceCarousel
                        title="Latest Services"
                        layout="grid-4"
                        rows={1}
                        products={latestServices.data}
                        loading={latestServices.isLoading}
                        groups={latestServices.tabs}
                        onGroupClick={latestServices.handleTabChange}
                    />
            {/*    ):*/}
            {/*    <div/>*/}
            {/*}*/}

            {/*{useMemo(() => (*/}
            {/*    <ServiceCarousel*/}
            {/*        title="New Arrivals"*/}
            {/*        layout="grid-4"*/}
            {/*        rows={2}*/}
            {/*        products={NewArrivalsProducts.data}*/}
            {/*        loading={NewArrivalsProducts.isLoading}*/}
            {/*        groups={NewArrivalsProducts.tabs}*/}
            {/*        onGroupClick={NewArrivalsProducts.handleTabChange}*/}
            {/*    />*/}
            {/*), [NewArrivalsProducts])}*/}

            <BlockCategories title="Popular Categories" layout="classic" />
            {useMemo(
                () => (
                    <BlockFeaturesm   />
                ),
                []
            )}
        </React.Fragment>
    );
}

export default withRouter(HomePageService);