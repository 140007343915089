// react
import React, {useEffect, useMemo, useState} from "react";
import axios from "axios";
// third-party
import {DropzoneArea} from "material-ui-dropzone";
import {toast} from "react-toastify";
import {Helmet} from "react-helmet-async";
import {Link} from "react-router-dom";
import theme from "../../data/theme";
import {agentcustomerToken, agenttoken, customertoken} from "../../api/shop";
import {connect, useDispatch, useSelector} from "react-redux";
// import {  } from "../../store/profile";
import {FetchCustomers, AddCustomers} from "../../store/agentCustomer";
import AsyncAction from "../shared/AsyncAction";
import {useFormik} from "formik";
import * as yup from "yup";
import Moment from "react-moment";
import {Pagination, Search} from "../DataTable";
import {CartDataFromDb} from "../../store/cart";
import store from "../../store/store";
import classNames from "classnames";
import SearchUser from "./search";
import {CURRENT_CUSTOMER, LOGIN_USER} from "../../store/auth/userActionTypes";
import {useHistory} from "react-router";
import {SetCurrentCustomer} from "../../store/currentCustomer";

const initialValues = {
    username: "",
    fullname: "",
    email: "",
    user_phoneNumber: "",
    password: "",
    confirmPassword: "",
};
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
//creating the validation schema
const validationSchema = yup.object().shape({
    username: yup
        .string()
        .min(3, "Username must be at least 3 characters"),

    fullname: yup
        .string()
        .required("A FullName is required")
        .min(4, "Fullname must be at least 4  characters"),
    email: yup.string().email(),
    user_phoneNumber: yup
        .string()
        .required("Phone number is required!")
        .matches(phoneRegExp, "Phone Number is not valid")
        .min(10, "Must be at least 10 characters")
        .max(13, "Phone number is too long, Not Great than 13 characters"),
    // .string()
    // .test('len', 'Must be exactly 10 characters', val => !val || (val.toString().length === 10))
    // .typeError( "Please enter correct phone number" )
    // .required( ''),
    password: yup
        .string()
        .required("Please enter your New password")
        .matches(/^.*(?=.{6,}).*$/, "Password must contain at least 6 characters"),
    confirmPassword: yup
        .string()
        .required("Please confirm your password")
        .when("password", {
            is: (password) => !!(password && password.length > 0),
            then: yup
                .string()
                .oneOf([yup.ref(" password")], "Password doesn't match"),
        }),
});

function AccountPageDashboard(props) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(FetchCustomers());
    }, [dispatch]);
    const [customer, setCustomer] = useState([]);
    const AgentDataList = useSelector((state) => state.AgentCustomer);

    //using useFormik
    const formik = useFormik({
        initialValues,
        validationSchema,
    });
// console.log(customer)
    const {AddCustomers} = props;
    const history=useHistory()
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const ITEMS_PER_PAGE = 5;
    const user_token = customertoken();
    useEffect(() => {
        setCustomer(AgentDataList);
    }, [AgentDataList]);
    const CustomerData = useMemo(() => {
        let computedcustomer = customer;
        if (search) {
            computedcustomer = computedcustomer.filter(
                (customer) =>
                    customer.fullname.toLowerCase().includes(search.toLowerCase()) ||
                    customer.email.toLowerCase().includes(search.toLowerCase()) ||
                    customer.user_phoneNumber
                        .toLowerCase()
                        .includes(search.toLowerCase()) ||
                    customer.created_at.toLowerCase().includes(search.toLowerCase())
            );
        }
        setTotalItems(computedcustomer.length);
        //Current Page slice
        return computedcustomer.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [customer, currentPage, search]);

    function handleCustomer(data,fullname) {
        localStorage.setItem("agentcustomer_token", data);

        if (agenttoken() !== null && agentcustomerToken() !== null) {
            dispatch(SetCurrentCustomer(fullname));
            // history.push('/')
            window.location.href = ("/");
        }
    }

    return (
        <div className="dashboard">
            <Helmet>
                <title>{`Ezytrade Agent — ${theme.name}`}</title>
            </Helmet>
            <div className="cardTop card ">
                <div className="d-flex card-header">
                    <div className="p-2 font-weight-bold"></div>
                    <div className="ml-auto p-2">
                        <button
                            data-toggle="modal"
                            data-target="#exampleModalCenter"
                            className="btn btn-outline-warning btn-sm rounded text-dark"
                        >
                            <i className="fa fa-edit"></i>
                            Add Customers
                        </button>
                    </div>
                </div>
                <div
                    class="modal fade"
                    id="exampleModalCenter"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                >
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5
                                    className="container text-center modal-title"
                                    id="exampleModalLongTitle"
                                >
                                    REGISTER CUSTOMER
                                </h5>

                                <button
                                    type="button"
                                    class="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div className="form-row">
                                        <div className="form-group col ">
                                            <label>UserName</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="username"
                                                placeholder="username"
                                                value={formik.values.username}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />

                                            {formik.touched.username && formik.errors.username ? (
                                                <div className="text-danger">
                                                    {formik.errors.username}
                                                </div>
                                            ) : null}
                                        </div>
                                        <div className="form-group col ">
                                            <label>Full Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="fullname"
                                                placeholder="Full Name"
                                                value={formik.values.fullname}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />

                                            {formik.touched.fullname && formik.errors.fullname ? (
                                                <div className="text-danger">
                                                    {formik.errors.fullname}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="checkout-email">Email address</label>
                                            <input
                                                id="email"
                                                type="email"
                                                className="form-control"
                                                placeholder="Enter Email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.email && formik.errors.email ? (
                                                <div className="text-danger">{formik.errors.email}</div>
                                            ) : null}
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="checkout-phone">Phone</label>
                                            <input
                                                type="tel"
                                                className="form-control"
                                                id="user_phoneNumber"
                                                placeholder="Phone"
                                                value={formik.values.user_phoneNumber}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />

                                            {formik.touched.user_phoneNumber &&
                                            formik.errors.user_phoneNumber ? (
                                                <div className="text-danger">
                                                    {formik.errors.user_phoneNumber}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <AsyncAction
                                            action={() => AddCustomers(formik.values)}
                                            render={({run, loading}) => (
                                                <button
                                                    type="button"
                                                    onClick={run}
                                                    className={"btn btn-block btn-primary rounded "}
                                                    // disabled={!(formik.isValid && formik.dirty)}
                                                    data-dismiss="modal"
                                                >
                                                    Save Now
                                                </button>
                                            )}
                                        />
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button
                                    type="button"
                                    class="btn btn-primary"
                                    data-dismiss="modal"
                                >
                                    Close Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex card-header">
                    {/* <div className="p-2 font-weight-bold">LIST OF CUSTOMERS</div> */}
                    <div className="site-header__search">
                        <SearchUser context="header"/>
                        <br/>
                        <div className="card-table mt-2">
                            <div className="table-responsive-sm">
                                <table>
                                    <thead>
                                    <tr className="text-center">
                                        <th>full name</th>
                                        <th>email</th>
                                        <th>phone number</th>
                                        <th>created</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {customer.slice(0, 5).map((index) => (
                                        <tr className="text-center">
                                            <td>{index.fullname}</td>
                                            <td>{index.email}</td>
                                            <td>{index.user_phoneNumber}</td>
                                            <td>
                                                <Moment format="DD/MM/YYYY">{index.created_at}</Moment>
                                            </td>
                                            <td>
                                                <Link to={`leads/${localStorage.getItem("agentId")}/${index.id}`}>
                                                    create Leads
                                                </Link>
                                            </td>
                                            <td>
                                                <Link
                                                    onClick={() => handleCustomer(index.secure_token,index.fullname)}
                                                >
                                                    <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                                                    &nbsp;&nbsp; Buy on behalf
                                                </Link>
                                            </td>

                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {
    FetchCustomers,
    AddCustomers,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPageDashboard);
