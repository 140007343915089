// react
import React, {useEffect, useMemo, useState} from "react";
// third-party
import PropTypes from "prop-types";
import {Helmet} from "react-helmet-async";
// application
import PageHeader from "../../shared/PageHeader";
import Product from "./serviceProduct";
import ProductTabs from "../ProductTabs";
import shopApi from "../../../api/shop";
import {url} from "../../../services/utils";
// blocks
import BlockLoader from "../../blocks/BlockLoader";
import BlockRelatedProducts from "../../blocks/BlockRelatedProducts";
import BlockCategories from "../../blocks/BlockCategories";
// widgets
import WidgetCategories from "../../widgets/WidgetCategories";
import WidgetProducts from "../../widgets/WidgetProducts";
// data stubs
import categories from "../../../data/shopWidgetCategories";
import {useProductTabs} from "../../../services/hooks";
//import axios
import axios from "axios";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import ServiceCarousel from "./serviceCarousel";
import RelatedServiceCarousel from "./RelatedServiceCarousel";
import Listingbreadcrumb from "../../Directory/Listingbreadcrumb";

function ServiceDetailPage(props) {

    const links = props.location.pathname;

    const {
        productSlug,
        layout,
        sidebarPosition,
        Token,
        CategoryId
    } = props;
    const [isLoading, setIsLoading] = useState(true);

    let [product, setProduct] = useState(null);
    let [ServiceChoiceData, setServiceChoice] = useState([])
    const [DefaultPrice,setPrice] = useState(0);
console.log("product",product);

    const dataService = useProductTabs(
        useMemo(() => [], []),
        (tab) => fechRelated()
    );

    // console.log(rate);
    const toServiceJson = (list) =>
        // console.log(list)
        list.map((item) => ({
            id: item.id,
            token: item.token,
            name: item.product_name,
            slug: item.product_name,
            description: item.product_description,
            image: item.service_image.image_name,
            categoryId:item.listing_categories_id
        }));

// end here


    const [defaultChoice, setDefaultChoice] = useState(null);
    //products to be structured

    useEffect(() => {
        let canceled = false;
        setIsLoading(true);
        axios.post(window.$name + "/api/consumer/service-product-details", {
            service_token: Token,
        })
            .then(function (response) {
                    if (canceled) {
                        return;
                    }
                    product = response.data;
                    setProduct(product);
                // setRelated(related.data)
                    // console.log("related",response.data)
                    setIsLoading(false);

                }
            )
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
        return () => {
            canceled = true;
        };
    }, [productSlug, setIsLoading]);
    //
    //
    // console.log("related data here",Related)
    const fechRelated = async (tab) => {
            const response = await axios.post(window.$name+"/api/consumer/related-service-product",
                {
                    category_id:CategoryId
                }
            );
            // console.log("related",response.data)
            return toServiceJson(response.data.data);
    };
    // console.log(product)
    // Load latest products.
    if (isLoading) {
        return <BlockLoader/>;
    }

    const breadcrumb = [
        {title: "Home", url: url.home()},
        {title: "Shop", url: url.catalog()},
        // { title: products.product_name, url: url.product(product) },
    ];
    let content;
    if (layout === "sidebar") {
        const sidebar = (
            <div className="shop-layout__sidebar">
                <div className="block block-sidebar">
                    <div className="block-sidebar__item">
                        <WidgetCategories categories={categories} location="shop"/>
                    </div>
                    <div className="block-sidebar__item d-none d-lg-block">
                        {/*<WidgetProducts title="Latest Products" products={latestProducts} />*/}
                    </div>
                </div>
            </div>
        );
        content = (
            <div className="container">
                <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
                    {sidebarPosition === "start" && sidebar}
                    <div className=" shop-layout__content">
                        <div className=" block">
                            <Product
                                     services={product.choice_service}
                                     DefaultPrice={DefaultPrice}
                                     product={product}
                                     links={links}
                                     layout={layout}
                                     rate="44" review_rates="5"/>
                        </div>

                        <br/>
                        <RelatedServiceCarousel
                            title="Related Services"
                            layout="grid-4"
                            products={dataService.data}
                            loading={dataService.isLoading}
                            groups={dataService.tabs}
                            onGroupClick={dataService.handleTabChange}
                        />
                    </div>
                    {sidebarPosition === "end" && sidebar}
                </div>
            </div>
        );
    } else {
        content = (
            <React.Fragment>
                <div className="block">
                    <div className="container">
                        <Product
                                 services={product.choice_service}
                                 DefaultPrice={DefaultPrice}
                                 product={product}
                                 links={links}
                                 layout={layout}
                                 rate="44"
                                 review_rates="5"
                        />
                    </div>
                </div>
                {/*<ServiceChoiceCard*/}
                {/*    title="Services Choices"*/}
                {/*    layout="grid-4"*/}
                {/*    services={ServiceChoiceData}*/}
                {/*/>*/}
                <br/>
                <RelatedServiceCarousel
                    title="Related Services"
                    layout="grid-4"
                    products={dataService.data}
                    loading={dataService.isLoading}
                    groups={dataService.tabs}
                    onGroupClick={dataService.handleTabChange}
                />
                <BlockCategories title="Popular Categories" layout="classic"/>
            </React.Fragment>
        )
    }
    return (
        <React.Fragment>
            <Helmet>
                <title>service details</title>
            </Helmet>
            <PageHeader breadcrumb={breadcrumb}/>
            {content}
        </React.Fragment>
    );
}

ServiceDetailPage.propTypes = {
    /** product slug. */
    productSlug: PropTypes.string,
    /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
    layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
    sidebarPosition: PropTypes.oneOf(["start", "end"]),
};

ServiceDetailPage.defaultProps = {
    layout: "standard",
    sidebarPosition: "start",
};
const mapStateToProps = (state) => ({
    product_details: state.product,
});

export default connect(mapStateToProps, () => ({}))(ServiceDetailPage);
