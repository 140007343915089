import React, { Component } from 'react'
import { RiBuilding4Line, RiHotelBedLine, RiPlaneLine } from 'react-icons/ri';
import { GiChickenOven } from 'react-icons/gi';
import { BsMusicNoteBeamed } from 'react-icons/bs';
import { MdFitnessCenter } from 'react-icons/md';
import '../../../assets/css/style.css';
import {Link} from "react-router-dom";
function BannerOneCategories (props){
    const {Category}=props
    const state = {
        connector: 'or',
        title: 'browse featured categories:',
        items: [
            {
                path: "#",
                title: "Apartments",
                icon: <RiBuilding4Line />
            },
            {
                path: "#",
                title: "Restaurants",
                icon: <GiChickenOven />
            },
            {
                path: "#",
                title: "Travel",
                icon: <RiPlaneLine />
            },
            {
                path: "#",
                title: "Events",
                icon: <BsMusicNoteBeamed />
            },
            {
                path: "#",
                title: "Fitness",
                icon: <MdFitnessCenter />
            },
            {
                path: "#",
                title: "Hotels",
                icon: <RiHotelBedLine />
            }
        ]
    };
        return (
            <>
                <div className="highlighted-categories">
                    <h4 className="highlighted-element">{state.connector}</h4>
                    <h5 className="highlighted__title">
                        {state.title}
                    </h5>
                    <div className="highlight-lists d-flex justify-content-center mt-4">
                        {Category.slice(0,8).map((item, index) => {
                            return (
                                    <Link to={`/category/listings/${item.secure_token}`}>
                                        <div className="category-item col-lg-12 col-sm-12 column-td-6" key={index}>
                                            <a  className="d-block">
                                                {item.category_name}
                                            </a>
                                        </div>
                                    </Link>
                            )
                        })}
                    </div>
                </div>
            </>
        )

}
export default BannerOneCategories
