import React, {useEffect, useState} from "react";
import {
    Row,
    Col,
    Label,
    Input,
    Form,
    FormGroup, TabPane,
} from "reactstrap";
import {useFormik} from "formik";
import {connect} from "react-redux";
import {AgentIsCompanyRegistration, AgentRegistration} from "../../store/Agent";
import * as yup from "yup";
import {useHistory} from "react-router";
import axios from "axios";

const initialValues = {
    bussinessname: "",
    tin: "",
    licensenumber: "",
    AlreadyRegistered: "",
    email: "",
    idtype: "",
    idNumber: "",
    phone: "",
    firstname: "",
    surname: "",
    lastname: "",
    username: "",
    password: "",
    country: "",
    region: "",
    district: "",
    street:"",
    confirmPassword: "",
    emailtrue: "",
    passwordtrue: "",
    confirmPasswordtrue: "",
    idtypetrue: "",
    idNumbertrue: ""
};

const validationSchema = yup.object().shape({
    bussinessname: yup.string().required("Bussiness name is a required field"),
    tin: yup
        .string()
        .required("TIN is required!"),
    AlreadyRegistered: yup
        .string()
        .required("This is required field"),
    licensenumber: yup.string().required("Company Registration number is a required field"),
    idtypetrue: yup.string().required("ID type is a required field"),
    idNumbertrue: yup
        .string()
        .required("ID number is required!"),
    emailtrue: yup
        .string()
        .email("Please enter your valid email")
        .required("Email is a required field"),
    email: yup
        .string()
        .email("Please enter your valid email")
        .required("Email is a required field"),

    phone: yup
        .string()
        .required("Please enter your mobile number"),

    firstname: yup.string().required("Firstname is a required field"),
    surname: yup.string().required("SurName is a required field"),
    lastname: yup.string().required("Lastname is a required field"),
    idtype: yup.string().required("ID type is a required field"),
    idNumber: yup
        .string()
        .required("ID number is required!"),
    username: yup.string().required("This Field is Required"),
    country: yup.string().required("Please select country"),
    region: yup.string().required("Please Select Region"),
    district: yup.string().required("Please Select district"),
    street: yup.string().required("This Field is Required"),
    password: yup
        .string()
        .required("Please enter your  password")
        .matches(/^.*(?=.{6,}).*$/, "Password must contain at least 6 characters"),
    confirmPassword: yup
        .string()
        .required("Please confirm your password")
        .when("password", {
            is: (password) => !!(password && password.length > 0),
            then: yup
                .string()
                .oneOf([yup.ref(" password")], "Password doesn't match"),
        }),
    passwordtrue: yup
        .string()
        .required("Please enter your  password")
        .matches(/^.*(?=.{6,}).*$/, "Password must contain at least 6 characters"),
    confirmPasswordtrue: yup
        .string()
        .required("Please confirm your password")
        .when("password", {
            is: (passwordtrue) => !!(passwordtrue && passwordtrue.length > 0),
            then: yup
                .string()
                .oneOf([yup.ref("password")], "Password doesn't match"),
        }),
});
const IsCompany = (props) => {
    const [isAlreadyRegistered, setIsAlreadyRegistered] = useState(null);
    const [countries, setCountry] = useState([]);
    const [state, setState] = useState();
    const [regions, setRegions] = useState([]);
    const [district, setDistrict] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const results = await axios
                .get(window.$name + "/api/adminstrator/get-countries")
                .then((response) => response.data);
            setCountry(results);
        };

        fetchData();
    }, []);

    const GetRegions = (e) => {
        const {id, value} = e.target;
        formik.values.country = e.target.value;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
        axios
            .get(window.$name + "/api/adminstrator/get-regions", {
                params: {
                    country_id: e.target.value,
                },
            })
            .then((res) => {
                setRegions(res.data);
            });
    };

    const GetDistrict = (e) => {
        const {id, value} = e.target;
        formik.values.region = e.target.value;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
        axios
            .get(window.$name + "/api/adminstrator/get-districts", {
                params: {
                    region_id: e.target.value,
                },
            })
            .then((res) => {
                setDistrict(res.data);
            });
    };
    const history = useHistory();
    //using useFormik
    const formik = useFormik({
        initialValues,
        validationSchema,
    });

    return (
        <React.Fragment>
            <Row>
                <Col lg="12">
                    <div>
                        <Form>
                            {/*{isAlreadyRegistered === null ?*/}
                            <Row>
                                <p className="container">
                                    Did you have an account rather than an agent account for this platform ?
                                    &nbsp; &nbsp;
                                    Yes &nbsp;
                                    <input
                                        type="radio"
                                        name="choose"
                                        value="choose"
                                        style={{height: '25px', width: '25px', verticalAlign: 'middle'}}
                                        onChange={() => setIsAlreadyRegistered(true)}
                                    /> &nbsp;   &nbsp;
                                    OR
                                    &nbsp;
                                    No &nbsp; &nbsp;
                                    <input
                                        type="radio"
                                        name="choose"
                                        value="choose"
                                        style={{height: '25px', width: '25px', verticalAlign: 'middle'}}
                                        onChange={() => setIsAlreadyRegistered(false)}
                                    />
                                </p>
                            </Row>
                            <br/>
                            {isAlreadyRegistered === true ?
                                <div>
                                    <Row className="mb-2">
                                        <Col lg="4">
                                            <FormGroup>
                                                <Label for="basicpill-firstname-input1">
                                                    Business name
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="basicpill-firstname-input1"
                                                    name="bussinessname"
                                                    variant="outlined"
                                                    value={formik.values.bussinessname}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.bussinessname &&
                                                formik.errors.bussinessname ? (
                                                    <div className="text-danger">
                                                        {formik.errors.bussinessname}
                                                    </div>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col lg="4">
                                            <FormGroup>
                                                <Label for="basicpill-firstname-input1">TIN</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="basicpill-firstname-input1"
                                                    name="tin"
                                                    variant="outlined"
                                                    value={formik.values.tin}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.tin && formik.errors.tin ? (
                                                    <div className="text-danger">{formik.errors.tin}</div>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col lg="4">
                                            <FormGroup>
                                                <Label for="basicpill-lastname-input2">
                                                    Company Registration Number
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="licensenumber"
                                                    name="licensenumber"
                                                    value={formik.values.licensenumber}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.licensenumber &&
                                                formik.errors.licensenumber ? (
                                                    <div className="text-danger">
                                                        {formik.errors.licensenumber}
                                                    </div>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <p className="container">NB : Please use your email and password already you
                                            have</p>
                                    </Row>
                                    <Row className="mt-1">
                                        <Col>
                                            <FormGroup>
                                                <Label for="basicpill-vatno-input19">Email</Label>
                                                <Input
                                                    type="email"
                                                    className="form-control"
                                                    id="basicpill-vatno-input19"
                                                    name="email"
                                                    variant="outlined"
                                                    value={formik.values.email}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.email && formik.errors.email ? (
                                                    <div className="text-danger">
                                                        {formik.errors.email}
                                                    </div>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            <FormGroup>
                                                <Label for="basicpill-servicetax-input21">
                                                    ID TYPE
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="basicpill-servicetax-input21"
                                                    name="idtype"
                                                    value={formik.values.idtype}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.idtype && formik.errors.idtype ? (
                                                    <div className="text-danger">
                                                        {formik.errors.idtype}
                                                    </div>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col lg="4">
                                            <FormGroup>
                                                <Label for="basicpill-servicetax-input21">
                                                    ID Number
                                                </Label>
                                                <input
                                                    id="idNumber"
                                                    type="text"
                                                    className="form-control"
                                                    name="idNumber"
                                                    value={formik.values.idNumber}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.idNumber && formik.errors.idNumber ? (
                                                    <div className="text-danger">
                                                        {formik.errors.idNumber}
                                                    </div>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <Label for="basicpill-servicetax-input21">Country</Label>
                                                <select
                                                    className="form-control"
                                                    id="country"
                                                    name="country"
                                                    onChange={(e) => GetRegions(e)}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.country}
                                                >
                                                    <option value="">please select country.....</option>
                                                    {countries.map((country) => (
                                                        <option key={country.id} value={country.id}>
                                                            {country.country}
                                                        </option>
                                                    ))}
                                                </select>
                                                {formik.touched.country && formik.errors.country ? (
                                                    <div className="text-danger">
                                                        {formik.errors.country}
                                                    </div>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <Label for="basicpill-servicetax-input21">Region</Label>
                                                <select
                                                    onChange={(e) => GetDistrict(e)}
                                                    id="region"
                                                    className="form-control"
                                                    name="region"
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.region}
                                                >
                                                    <option value="">Select a Regions...</option>
                                                    {regions.map((reg) => (
                                                        <option key={reg.id} value={reg.id}>
                                                            {reg.region}
                                                        </option>
                                                    ))}
                                                </select>
                                                {formik.touched.region && formik.errors.region ? (
                                                    <div className="text-danger">
                                                        {formik.errors.region}
                                                    </div>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <Label for="basicpill-servicetax-input21">District</Label>
                                                <select
                                                    onChange={formik.handleChange}
                                                    id="district"
                                                    className="form-control"
                                                    name="district"
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.district}
                                                >
                                                    <option value="">Select a district...</option>
                                                    {district.map((dist) => (
                                                        <option key={dist.id} value={dist.district}>
                                                            {dist.district}
                                                        </option>
                                                    ))}
                                                </select>
                                                {formik.touched.district && formik.errors.district ? (
                                                    <div className="text-danger">
                                                        {formik.errors.district}
                                                    </div>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <Label for="basicpill-servicetax-input21">Street</Label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="street"
                                                    placeholder="Street Address"
                                                    value={formik.values.street}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.street && formik.errors.street ? (
                                                    <div className="text-danger">
                                                        {formik.errors.street}
                                                    </div>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>

                                        <Col lg="6">
                                            <FormGroup>
                                                <Label for="basicpill-servicetax-input21">
                                                    Password
                                                </Label>
                                                <input
                                                    id="password"
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Password"
                                                    value={formik.values.password}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />

                                                {formik.touched.password && formik.errors.password ? (
                                                    <div className="text-danger">
                                                        {formik.errors.password}
                                                    </div>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <Label for="basicpill-servicetax-input21">
                                                    Confirm Password
                                                </Label>
                                                <input
                                                    id="confirmPassword"
                                                    type="password"
                                                    className="form-control"
                                                    name="confirmPassword"
                                                    placeholder="confirm Password"
                                                    value={formik.values.confirmPassword}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />

                                                {formik.touched.confirmPassword &&
                                                formik.errors.confirmPassword ? (
                                                    <div className="text-danger">
                                                        {formik.errors.confirmPassword}
                                                    </div>
                                                ) : null}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <div className="col d-flex justify-content-end">
                                            <button
                                                type="button"
                                                onClick={() => AgentIsCompanyRegistration(formik.values, isAlreadyRegistered, history)}
                                                className="btn-warning btn-lg rounded-sm">
                                                Create account
                                            </button>
                                        </div>

                                    </Row>
                                </div>
                                : isAlreadyRegistered === false ?
                                    <div>
                                        <Row className="mb-2">
                                            <Col lg="4">
                                                <FormGroup>
                                                    <Label for="basicpill-firstname-input1">
                                                        Business name
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-firstname-input1"
                                                        name="bussinessname"
                                                        variant="outlined"
                                                        value={formik.values.bussinessname}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.bussinessname &&
                                                    formik.errors.bussinessname ? (
                                                        <div className="text-danger">
                                                            {formik.errors.bussinessname}
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <Label for="basicpill-firstname-input1">TIN</Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-firstname-input1"
                                                        name="tin"
                                                        variant="outlined"
                                                        value={formik.values.tin}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.tin && formik.errors.tin ? (
                                                        <div className="text-danger">{formik.errors.tin}</div>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <Label for="basicpill-lastname-input2">
                                                        Company Registration Number
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="licensenumber"
                                                        name="licensenumber"
                                                        value={formik.values.licensenumber}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.licensenumber &&
                                                    formik.errors.licensenumber ? (
                                                        <div className="text-danger">
                                                            {formik.errors.licensenumber}
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <Label for="basicpill-pancard-input18">
                                                        Firstname
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-pancard-input18"
                                                        name="firstname"
                                                        value={formik.values.firstname}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.firstname &&
                                                    formik.errors.firstname ? (
                                                        <div className="text-danger">
                                                            {formik.errors.firstname}
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <Label for="basicpill-cstno-input20">SurName</Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-cstno-input20"
                                                        name="surname"
                                                        value={formik.values.surname}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.surname && formik.errors.surname ? (
                                                        <div className="text-danger">
                                                            {formik.errors.surname}
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <Label for="basicpill-vatno-input19">LastName</Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-vatno-input19"
                                                        name="lastname"
                                                        variant="outlined"
                                                        value={formik.values.lastname}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.lastname && formik.errors.lastname ? (
                                                        <div className="text-danger">
                                                            {formik.errors.lastname}
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <Label for="basicpill-vatno-input19">Email</Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-vatno-input19"
                                                        name="emailtrue"
                                                        variant="outlined"
                                                        value={formik.values.emailtrue}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.emailtrue && formik.errors.emailtrue ? (
                                                        <div className="text-danger">
                                                            {formik.errors.emailtrue}
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <Label for="basicpill-cstno-input20">
                                                        Phone Number
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-cstno-input20"
                                                        name="phone"
                                                        value={formik.values.phone}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.phone && formik.errors.phone ? (
                                                        <div className="text-danger">
                                                            {formik.errors.phone}
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="4">
                                                <FormGroup>
                                                    <Label for="basicpill-servicetax-input21">
                                                        Username
                                                    </Label>
                                                    <input
                                                        id="username"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="idNumber"
                                                        name="username"
                                                        value={formik.values.username}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.username && formik.errors.username ? (
                                                        <div className="text-danger">
                                                            {formik.errors.username}
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="basicpill-servicetax-input21">
                                                        ID TYPE
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="basicpill-servicetax-input21"
                                                        name="idtypetrue"
                                                        value={formik.values.idtypetrue}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.idtypetrue && formik.errors.idtypetrue ? (
                                                        <div className="text-danger">
                                                            {formik.errors.idtypetrue}
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="basicpill-servicetax-input21">
                                                        ID Number
                                                    </Label>
                                                    <input
                                                        id="idNumber"
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="idNumber"
                                                        name="idNumbertrue"
                                                        value={formik.values.idNumbertrue}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.idNumbertrue && formik.errors.idNumbertrue ? (
                                                        <div className="text-danger">
                                                            {formik.errors.idNumbertrue}
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label for="basicpill-servicetax-input21">Country</Label>
                                                    <select
                                                        className="form-control"
                                                        id="country"
                                                        name="country"
                                                        onChange={(e) => GetRegions(e)}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.country}
                                                    >
                                                        <option value="">please select country.....</option>
                                                        {countries.map((country) => (
                                                            <option key={country.id} value={country.id}>
                                                                {country.country}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {formik.touched.country && formik.errors.country ? (
                                                        <div className="text-danger">
                                                            {formik.errors.country}
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label for="basicpill-servicetax-input21">Region</Label>
                                                    <select
                                                        onChange={(e) => GetDistrict(e)}
                                                        id="region"
                                                        className="form-control"
                                                        name="region"
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.region}
                                                    >
                                                        <option value="">Select a Regions...</option>
                                                        {regions.map((reg) => (
                                                            <option key={reg.id} value={reg.id}>
                                                                {reg.region}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {formik.touched.region && formik.errors.region ? (
                                                        <div className="text-danger">
                                                            {formik.errors.region}
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label for="basicpill-servicetax-input21">District</Label>
                                                    <select
                                                        onChange={formik.handleChange}
                                                        id="district"
                                                        className="form-control"
                                                        name="district"
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.district}
                                                    >
                                                        <option value="">Select a district...</option>
                                                        {district.map((dist) => (
                                                            <option key={dist.id} value={dist.district}>
                                                                {dist.district}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    {formik.touched.district && formik.errors.district ? (
                                                        <div className="text-danger">
                                                            {formik.errors.district}
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <Label for="basicpill-servicetax-input21">Street</Label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="street"
                                                        placeholder="Street Address"
                                                        value={formik.values.street}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />
                                                    {formik.touched.street && formik.errors.street ? (
                                                        <div className="text-danger">
                                                            {formik.errors.street}
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="basicpill-servicetax-input21">
                                                        Password
                                                    </Label>
                                                    <input
                                                        id="passwordtrue"
                                                        type="password"
                                                        className="form-control"
                                                        placeholder="Password"
                                                        value={formik.values.passwordtrue}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />

                                                    {formik.touched.passwordtrue && formik.errors.passwordtrue ? (
                                                        <div className="text-danger">
                                                            {formik.errors.passwordtrue}
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>
                                            <Col>
                                                <FormGroup>
                                                    <Label for="basicpill-servicetax-input21">
                                                        Confirm Password
                                                    </Label>
                                                    <input
                                                        id="confirmPasswordtrue"
                                                        type="password"
                                                        className="form-control"
                                                        name="confirmPasswordtrue"
                                                        placeholder="confirm Password"
                                                        value={formik.values.confirmPasswordtrue}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                    />

                                                    {formik.touched.confirmPasswordtrue &&
                                                    formik.errors.confirmPasswordtrue ? (
                                                        <div className="text-danger">
                                                            {formik.errors.confirmPasswordtrue}
                                                        </div>
                                                    ) : null}
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <div className="col d-flex justify-content-end">
                                                <button
                                                    type="button"
                                                    onClick={() => AgentIsCompanyRegistration(formik.values, isAlreadyRegistered, history)}
                                                    className="btn-warning btn-lg rounded-sm">
                                                    Create account
                                                </button>
                                            </div>

                                        </Row>
                                    </div>
                                    : null
                            }

                        </Form>

                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};
const mapStateToProps = (state) => ({
    // shippingAddress: state.shippingAddress,
    // profile: state.profile,
});

const mapDispatchToProps = {
    AgentRegistration,
};

export default connect(mapStateToProps, mapDispatchToProps)(IsCompany);
