// react
import React, { useState, useEffect } from "react";
import axios from "axios";

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function MegamenuLinks(props) {  
    const { links, depth } = props;   
    console.log("dfdgkljfgrfg",links) 
       console.log("dfdgkljfgrfg",links) 
    const linksList = links.map((link, index) => {
        let title = null;
        let subLinks = null;
        if (link.category) {
            title = <Link >{link.category}</Link>;
        }

        if (link.category_model && link.category_model.length) {
           
            subLinks =  link.category_model.map((item, index) => (
            <MegamenuLinks links={item.subcategory} depth={depth + 1} />
            ))
        }

        const classes = classNames('megamenu__item', {
            'megamenu__item--with-submenu': subLinks,
        });

        return (
            <li key={index} className={classes}>
                {title}
                {subLinks}
            </li>
        );
    });

    return (
        <ul className={`megamenu__links megamenu__links--level--${depth}`}>
            {linksList}
        </ul>
    );
}

MegamenuLinks.propTypes = {
    links: PropTypes.array,
    depth: PropTypes.number,
};

MegamenuLinks.defaultProps = {
    depth: 0,
};

export default MegamenuLinks;
