import {ADD_CUSTOMER, FETCH_CUSTOMER,EDIT_CUSTOMER} from "./customerActionTypes";

export default function customersReducer(state = [], action) {
    switch (action.type) {
        case FETCH_CUSTOMER:
            return action.payload
        case ADD_CUSTOMER:
            return action.payload
        case EDIT_CUSTOMER:
            return action.payload
        default:
            return state;
    }
}


