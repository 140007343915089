import {customertoken} from "../../../api/shop";
import Axios from "axios";
import {DELIVERY_ORDER_DATA,RATE_DATA} from "./orderDeliveredActionTypes";
import {toast} from "react-toastify";
import {FetchRate} from '../../RateData/RateAction'

export function FetchDeliveryOrders() {
    const token = customertoken();
    const data = {
        user_token: token,
    };
    return async (dispatch) =>
        Axios.post(window.$name + "/api/order/get-delivered-orders", data)
            .then(function (response) {
                if (response.data.status === 401) {
                    dispatch({type: DELIVERY_ORDER_DATA, payload: []});
                } else {
                    dispatch({type: DELIVERY_ORDER_DATA, payload: response.data.order});
                    dispatch(FetchRate(JSON.parse(response.data.rates.rate)));
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}


export function DeleteDeliveryOrders(order_number) {
    // console.log(order_number);
    const token = customertoken();
    const data = {
        user_token: token,
        order_number: order_number,
    };
// console.log(data)
    return async (dispatch) =>
        Axios.post(window.$name + "/api/order/cancel-order", data)
            .then(function (response) {
                toast.success(`${response.data.message}`);
                dispatch(FetchDeliveryOrders());
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

