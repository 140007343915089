import React, { useEffect, useState } from "react";
//  third-party
import { useDispatch, connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import theme from "../../data/theme";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import { handleNexts, handleBacks } from "./checkout";
import { Link } from "@material-ui/core";
import { Countries } from "../../api/ezytradeApi";
import { FetchShippingAddress } from "../../store/shippingAddress/shippingActions";
import AsyncAction from "../shared/AsyncAction";
import InputMask from "react-input-mask";
import {
  AddAddress,
  ChangeAddress,
  DeleteAddress,
} from "../../store/shippingAddress";
import * as yup from "yup";
import { useFormik } from "formik";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
//creating the validation schema
const validationSchema = yup.object().shape({
  fullname: yup
    .string()
    .required("Please enter your full name")
    .min(4, "Fullname must be at least 4  characters"),
  postcode:yup
    .string()
    .required("This field is required!"),
  mobile: yup
    .string()
    // .test("len", "Phone number too short must be 12 number.", (val) => {
    //   const val_length_without_dashes = val.replace(/-|_/g, "").length;
    //   return val_length_without_dashes === 13;
    // })
    .required("Phone number is required!"),
  // .min(10, "Your mobile number must be at least 10 digits")
  // .max(12, "mobile number must be less than 12 digits"),
  othermobile: yup.number("mobile number container only numeric characters"),
  country: yup.string().required("Please select country"),
  region: yup.string().required("Please Select Region"),
  district: yup.string().required("Please Select district"),
  // street: yup
  //     .string()
  //     .required("Please enter your Street name"),
  // housenumber: yup
  //     .string(),
  addresstype: yup.string().required("Please select Address Type"),
  // additional: yup
  //     .string(),
});
function ShopPageCheckout(props) {
  const { AddAddress, profile, ChangeAddress, DeleteAddress ,stepNumber,activeStep, shippingAddress  } = props;
  const [countries, setCountry] = useState([]);
  const [regions, setRegions] = useState([]);
  const formik = useFormik({
    initialValues: {
      fullname: "",
      mobile: "",
      othermobile: "",
      postcode: "",
      country: "",
      region: "",
      district: "",
      street: "",
      housenumber: "",
      addresstype: "",
      additional: "",
      // fullname: "",
      // mobile: "",
      // othermobile: "",
      // postcode: "",
      // country: "",
      // region: "",
      // district: "",
      // street: "",
      // housenumber: "",
      // addresstype: "",
      // additional: "",
    },
    validationSchema,
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(FetchShippingAddress());
  }, []);
  //  country select
  const [state, setState] = useState({
    fullname: "",
    mobile: "",
    othermobile: "",
    postcode: "",
    country: "",
    region: "",
    district: "",
    street: "",
    housenumber: "",
    addresstype: "",
    additional: "",
  });
  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
 
  useEffect(() => {
    const fetchData = async () => {
      const results = await axios
        .get(window.$name + "/api/adminstrator/get-countries")
        .then((response) => response.data);
      setCountry(results);
    };
    fetchData();
  }, []); 
  const GetRegions = (e) => {
    const { id, value } = e.target;
    formik.values.country = e.target.value;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    axios
      .get(window.$name + "/api/adminstrator/get-regions", {
        params: {
          country_id: e.target.value,
        },
      })
      .then((res) => {
        setRegions(res.data);
      });
  };
  const [district, setDistrict] = useState([]);
  const GetDistrict = (e) => {
    const { id, value } = e.target;
    formik.values.region = e.target.value;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    axios
      .get(window.$name + "/api/adminstrator/get-districts", {
        params: {
          region_id: e.target.value,
        },
      })
      .then((res) => {
        setDistrict(res.data);
      });
  };
  //  fetch shipping address
  if (shippingAddress === null || shippingAddress.length === 0) {
    return (
      <div className="checkout">
        <Helmet>
          <title>{`Address — ${theme.name}`}</title>
        </Helmet>
        <div className="block">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-12 col-lg-6 col-xl-7">
                <div className="card mb-lg-0 shadow-lg rounded">
                  <div className="card-body">
                    <h3 className="card-title text-center">
                      ADD SHIPPING ADDRESS
                    </h3>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="checkout-first-name">Full Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="fullname"
                          value={formik.values.fullname}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.fullname && formik.errors.fullname ? (
                          <div className="text-danger">
                            {formik.errors.fullname}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="checkout-last-name">Phone Number</label>
                        <input
                          type="text"
                          className="form-control"
                          id="mobile"
                          value={formik.values.mobile}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {/* <InputMask
                          mask="+255-999999999"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.mobile}
                        >
                          {() => (
                            <input
                              type="text"
                              name="mobile"
                              className="form-control"
                            />
                          )}
                        </InputMask> */}
                        {formik.touched.mobile && formik.errors.mobile ? (
                          <div className="text-danger">
                            {formik.errors.mobile}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="checkout-country">
                          Alternative phone Number
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="othermobile"
                          value={formik.values.othermobile}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.othermobile &&
                        formik.errors.othermobile ? (
                          <div className="text-danger">
                            {formik.errors.othermobile}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="checkout-country">Post Code</label>
                        <input
                          type="text"
                          className="form-control"
                          id="postcode"
                          value={formik.values.postcode}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.postcode && formik.errors.postcode ? (
                          <div className="text-danger">
                            {formik.errors.postcode}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="checkout-country">Country</label>

                      {/* <div className="form-group">
                      <label>Select List</label> */}
                      <select
                        className="form-control"
                        id="country"
                        name="country"
                        onChange={(e) => GetRegions(e)}
                        onBlur={formik.handleBlur}
                        value={formik.values.country}
                      >
                        <option value="">please select country.....</option>
                        {countries.map((country) => (
                          <option key={country.id} value={country.id}>
                            {country.country}
                          </option>
                        ))}
                      </select>
                      {formik.touched.country && formik.errors.country ? (
                        <div className="text-danger">
                          {formik.errors.country}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="checkout-country">Region</label>
                        <select
                          onChange={(e) => GetDistrict(e)}
                          id="region"
                          className="form-control"
                          name="region"
                          onBlur={formik.handleBlur}
                          value={formik.values.region}
                        >
                          <option value="">Select a Regions...</option>
                          {regions.map((reg) => (
                            <option key={reg.id} value={reg.id}>
                              {reg.region}
                            </option>
                          ))}
                        </select>
                        {formik.touched.region && formik.errors.region ? (
                          <div className="text-danger">
                            {formik.errors.region}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="checkout-country">District</label>
                        <select
                          onChange={formik.handleChange}
                          id="district"
                          className="form-control"
                          name="district"
                          onBlur={formik.handleBlur}
                          value={formik.values.district}
                        >
                          <option value="">Select a district...</option>
                          {district.map((dist) => (
                            <option key={dist.id} value={dist.district}>
                              {dist.district}
                            </option>
                          ))}
                        </select>
                        {formik.touched.district && formik.errors.district ? (
                          <div className="text-danger">
                            {formik.errors.district}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="checkout-street-address">Street</label>
                      <input
                        type="text"
                        className="form-control"
                        id="street"
                        placeholder="Street Address"
                        value={formik.values.street}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.street && formik.errors.street ? (
                        <div className="text-danger">
                          {formik.errors.street}
                        </div>
                      ) : null}
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="checkout-email">House Number</label>
                        <input
                          type="email"
                          className="form-control"
                          id="housenumber"
                          value={formik.values.housenumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />

                        {formik.touched.housenumber &&
                        formik.errors.housenumber ? (
                          <div className="text-danger">
                            {formik.errors.housenumber}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="checkout-phone">Address Type</label>
                        <select
                          onChange={formik.handleChange}
                          id="addresstype"
                          className="form-control"
                          value={formik.values.addresstype}
                          onBlur={formik.handleBlur}
                        >
                          <option>Select Address Type...</option>
                          <option>Home</option>
                          <option>Office</option>
                        </select>
                        {formik.touched.addresstype &&
                        formik.errors.addresstype ? (
                          <div className="text-danger">
                            {formik.errors.addresstype}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="checkout-street-address">
                        Additional Delivery Information (
                        <small> Not Compulsory </small>)
                      </label>
                      <textarea
                        type="text"
                        className="form-control mt-2"
                        id="additional"
                        placeholder="Additional delivery information"
                        value={formik.values.additional}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />

                      {formik.touched.additional && formik.errors.additional ? (
                        <div className="text-danger">
                          {formik.errors.additional}
                        </div>
                      ) : null}
                    </div>
                    <AsyncAction
                      action={() => AddAddress(formik.values)}
                      render={({ run, loading }) => (
                        <button
                          type="button"
                          onClick={run}
                          className={"btn btn-block btn-primary rounded "}
                          disabled={!(formik.isValid && formik.dirty)}
                        >
                          Save Address
                        </button>
                      )}
                    />
                  </div>
                </div>
                <br />
                {
                  stepNumber>0?(
                     <button
                  className="btn btn-secondary"
                  onClick={() => handleBacks(activeStep)}
                >
                  {" "}
                  <i className="fa fa-backward text-black"> </i> Previous
                </button>
                  ):null
                }
               
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="checkout">
        <Helmet>
          <title>{`Address — ${theme.name}`}</title>
        </Helmet>
        <div className="checkout block">
          {/* model add new address  */}
          <div
            class="modal fade"
            id="exampleModalLong"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLongTitle"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    className="modal-title container d-flex justify-content-center"
                    id="exampleModalLongTitle"
                  >
                    ADD NEW SHIPPING ADDRESS
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div className="card mb-lg-0 shadow-lg rounded">
                    <div className="card-body">
                      {/* <h3 className="card-title text-center">

                      </h3> */}
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="checkout-first-name">Full Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="fullname"
                            value={formik.values.fullname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />

                          {formik.touched.fullname && formik.errors.fullname ? (
                            <div className="text-danger">
                              {formik.errors.fullname}
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="checkout-last-name">
                            Phone Number
                          </label>
                          <InputMask
                          mask="+255-999999999"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.mobile}
                        >
                          {() => (
                            <input
                              type="text"
                              name="mobile"
                              className="form-control"
                            />
                          )}
                        </InputMask>
                          {formik.touched.mobile && formik.errors.mobile ? (
                            <div className="text-danger">
                              {formik.errors.mobile}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="checkout-country">
                            Aternative phone Number
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="othermobile"
                            value={formik.values.othermobile}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.othermobile &&
                          formik.errors.othermobile ? (
                            <div className="text-danger">
                              {formik.errors.othermobile}
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="checkout-country">Post Code</label>
                          <input
                            type="text"
                            className="form-control"
                            id="postcode"
                            value={formik.values.postcode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.postcode && formik.errors.postcode ? (
                            <div className="text-danger">
                              {formik.errors.postcode}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="checkout-country">Country</label>

                        {/* <div className="form-group">
                      <label>Select List</label> */}
                        <select
                          className="form-control"
                          id="country"
                          name="country"
                          onChange={(e) => GetRegions(e)}
                          onBlur={formik.handleBlur}
                          value={formik.values.country}
                        >
                          <option value="">please select country.....</option>
                          {countries.map((country) => (
                            <option key={country.id} value={country.id}>
                              {country.country}
                            </option>
                          ))}
                        </select>
                        {formik.touched.country && formik.errors.country ? (
                          <div className="text-danger">
                            {formik.errors.country}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="checkout-country">Region</label>
                          <select
                            onChange={(e) => GetDistrict(e)}
                            id="region"
                            className="form-control"
                            name="region"
                            onBlur={formik.handleBlur}
                            value={formik.values.region}
                          >
                            <option value="">Select a Regions...</option>
                            {regions.map((reg) => (
                              <option key={reg.id} value={reg.id}>
                                {reg.region}
                              </option>
                            ))}
                          </select>
                          {formik.touched.region && formik.errors.region ? (
                            <div className="text-danger">
                              {formik.errors.region}
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="checkout-country">District</label>
                          <select
                            onChange={formik.handleChange}
                            id="district"
                            className="form-control"
                            name="district"
                            onBlur={formik.handleBlur}
                            value={formik.values.district}
                          >
                            <option value="">Select a district...</option>
                            {district.map((dist) => (
                              <option key={dist.id} value={dist.district}>
                                {dist.district}
                              </option>
                            ))}
                          </select>
                          {formik.touched.district && formik.errors.district ? (
                            <div className="text-danger">
                              {formik.errors.district}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="checkout-street-address">Street</label>
                        <input
                          type="text"
                          className="form-control"
                          id="street"
                          placeholder="Street Address"
                          value={formik.values.street}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.street && formik.errors.street ? (
                          <div className="text-danger">
                            {formik.errors.street}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="checkout-email">House Number</label>
                          <input
                            type="email"
                            className="form-control"
                            id="housenumber"
                            value={formik.values.housenumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />

                          {formik.touched.housenumber &&
                          formik.errors.housenumber ? (
                            <div className="text-danger">
                              {formik.errors.housenumber}
                            </div>
                          ) : null}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="checkout-phone">Address Type</label>
                          <select
                            onChange={formik.handleChange}
                            id="addresstype"
                            className="form-control"
                            value={formik.values.addresstype}
                            onBlur={formik.handleBlur}
                          >
                            <option>Select Address Type...</option>
                            <option>Home</option>
                            <option>Office</option>
                          </select>
                          {formik.touched.addresstype &&
                          formik.errors.addresstype ? (
                            <div className="text-danger">
                              {formik.errors.addresstype}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="checkout-street-address">
                          Additional Delivery Information (
                          <small> Not Compulsory </small>)
                        </label>
                        <textarea
                          type="text"
                          className="form-control mt-2"
                          id="additional"
                          placeholder="Additional delivery information"
                          value={formik.values.additional}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />

                        {formik.touched.additional &&
                        formik.errors.additional ? (
                          <div className="text-danger">
                            {formik.errors.additional}
                          </div>
                        ) : null}
                      </div>
                      <AsyncAction
                        action={() => AddAddress(formik.values)}
                        render={({ run, loading }) => (
                          <button
                            type="button"
                            onClick={run}
                            data-dismiss="modal"
                            className={"btn btn-block btn-primary rounded "}
                            disabled={!(formik.isValid && formik.dirty)}
                          >
                            Save Address
                          </button>
                        )}
                      />
                    </div>
                  </div>{" "}
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  -
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-4">
            <div className="row">
              <div className="col-12 col-lg-6 col-xl-7">
                <div className="card mb-lg-0">
                  <div className="card-body">
                    <div className="row card-title">
                      <div className="col">
                        <h6>SHIPPING ADDRESS OPTIONS</h6>
                      </div>
                      <div className="col ">
                        <Link
                          data-toggle="modal"
                          data-target="#exampleModalLong"
                        >
                          <label
                            htmlFor="checkout-country"
                            className="font-weight-bold address-card__badge rounded btn btn-primary btn-sm"
                          >
                            {" "}
                            <i className="fa fa-plus-square"> </i> ADD NEW
                            ADDRESS
                          </label>{" "}
                        </Link>
                      </div>
                    </div>
                    <div />
                    <div>
                      <p>
                        Please choose shipping address for this order. Please
                        also indicate whether your billing address is the same
                        as the shipping address entered. When finished, click
                        the "Deliver To This Address" button.
                      </p>
                      <hr />
                      {shippingAddress.map((address) => (
                        <div>
                          {address.is_active ? (
                            <div>
                              <div className=" mb-lg-0 border-white">
                                <div className=" card-body">
                                  <p className="font-weight-bold">
                                    {" "}
                                    <span className="text-uppercase">
                                      {address.address_fullname}
                                    </span>{" "}
                                    ,{address.address_mobileNumber} or{" "}
                                    {address.address_mobileNumber2}
                                  </p>
                                  <p className="text-capitalize">
                                    {address.country.country},{" "}
                                    {address.region.region},{" "}
                                    {address.address_district}
                                  </p>
                                  <p>Post Code : {address.address_postcode} </p>
                                  <p>
                                    House Number :{" "}
                                    {address.address_house_number}
                                  </p>
                                  <p>Area Name : {address.area}</p>
                                  <p>
                                    Address Type : {address.address_AddressType}
                                  </p>
                                  <span className="font-weight-bold text-uppercase">
                                    Additional Shipping Details
                                  </span>
                                  <p>{address.additional_delivery_notes}</p>
                                </div>
                                <button
                                  type="submit"
                                  onClick={() => handleNexts(activeStep)}
                                  className="btn-block btn btn-primary"
                                >
                                  {" "}
                                  <i className="fa fa-truck text-black mr-2">
                                    {" "}
                                  </i>
                                  DELIVER TO THIS ADDRESS
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-xl-5 ">
                <div className="card mb-0">
                  <div className="card-body">
                    <h6 className="card-title text-center">OTHER ADDRESS</h6>
                  </div>
                  <hr />
                  <Scrollbars style={{ height: 555 }}>
                    {shippingAddress.map((address) => (
                      <div>
                        {address.is_active !== true ? (
                          <div>
                            <div>
                              <AsyncAction
                                action={() =>
                                  ChangeAddress(address.secure_token)
                                }
                                render={({ run, loading }) => (
                                  <button
                                    type="button"
                                    onClick={run}
                                    className={
                                      "btn btn-primary btn-sm rounded "
                                    }
                                  >
                                    <i className="fa fa-check-square text-primary mr-1">
                                      {" "}
                                    </i>
                                    Choose This Address{" "}
                                  </button>
                                )}
                              />
                            </div>
                            <div className=" card-body">
                              <p className="font-weight-bold">
                                {" "}
                                <span className="text-uppercase">
                                  {address.address_fullname}
                                </span>{" "}
                                ,{address.address_mobileNumber} or{" "}
                                {address.address_mobileNumber2}
                              </p>
                              <p className="text-capitalize">
                                {address.country.country},{" "}
                                {address.region.region},{" "}
                                {address.address_district}
                              </p>
                              <p>Post Code : {address.address_postcode} </p>
                              <p>
                                House Number : {address.address_house_number}
                              </p>
                              <p>Area Name : {address.area}</p>
                              <p>
                                Address Type : {address.address_AddressType}
                              </p>
                              <AsyncAction
                                action={() =>
                                  DeleteAddress(address.secure_token)
                                }
                                render={({ run, loading }) => (
                                  <button
                                    type="button"
                                    onClick={run}
                                    className={"btn btn-danger btn-sm rounded "}
                                  >
                                    <i className="fa fa-trash"> </i>
                                    Remove This Address{" "}
                                  </button>
                                )}
                              />
                            </div>

                            <hr />
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    ))}
                  </Scrollbars>
                </div>
              </div>
            </div>
            {
                  stepNumber>0?(
            <button
              className="btn btn-secondary  mt-2"
              onClick={() => handleBacks(activeStep)}
            >
              {" "}
              <i className="fa fa-backward text-black"> </i> Back
            </button>
                  ):null}
          </div>
          <br></br>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  shippingAddress: state.shippingAddress.user_address,
  profile: state.profile,
});

const mapDispatchToProps = {
  AddAddress,
  ChangeAddress,
  DeleteAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckout);
