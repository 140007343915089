export default {
    name: 'Ezytrade Africa',
    fullName: 'Ezytrade Africa',
    url: 'ezytradeafrica.com',
    author: {
        name: 'Kos',
        profile_url: 'ezytradeafrica.com',
    },
    contacts: {
        address: '715 Fake Street, New York 10021 USA',
        email: 'support@ezytrade.africa',
        phone: '+255 717 309 346',
    },
};
