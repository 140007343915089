import {DELIVERY_DELETE_ORDER_DATA,DELIVERY_ORDER_DATA} from './orderDeliveredActionTypes'
export default function orderDeliveryReducer(state=[],action) {
    switch(action.type){
        case DELIVERY_ORDER_DATA:
            return action.payload
        case DELIVERY_DELETE_ORDER_DATA:
            return action.payload
        default:
            return state;
    }
}
