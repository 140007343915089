import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import theme from "../../../data/theme";
import {connect, useDispatch} from "react-redux";
import Axios from "axios";
import {useFormik} from "formik";
import {FetchListingDetail} from "../../../store/ListingDetails";
import {toast} from "react-toastify";
import axios from "axios";
import {customertoken} from "../../../api/shop";
import {FetchListing} from "../../../store/Listings";
import {useHistory} from "react-router-dom";

function ListingAction(props) {
    const history = useHistory()
    const dispatch = useDispatch();
    const {listingData} = props
    const [schedule, setSchedule] = useState("");
    // console.log(ListingId)

    const Time = [
        {
            value: 1,
            label: 'none'
        },
        {
            value: 2,
            label: '1:00am'
        },
        {
            value: 2,
            label: '2:00am'
        },
        {
            value: 3,
            label: '3:00am'
        },
        {
            value: 4,
            label: '4:00am'
        },
        {
            value: 5,
            label: '5:00am'
        },
        {
            value: 6,
            label: '6:00am'
        },
        {
            value: 7,
            label: '7:00am'
        },
        {
            value: 8,
            label: '8:00am'
        },
        {
            value: 9,
            label: '9:00am'
        },
        {
            value: 10,
            label: '10:00am'
        },
        {
            value: 11,
            label: '11:00am'
        },
        {
            value: 12,
            label: '12:00pm'
        },
        {
            value: 13,
            label: '1:00pm'
        },
        {
            value: 14,
            label: '2:00pm'
        },
        {
            value: 15,
            label: '3:00pm'
        },
        {
            value: 16,
            label: '4:00pm'
        },
        {
            value: 17,
            label: '5:00pm'
        },
        {
            value: 18,
            label: '6:00pm'
        },
        {
            value: 19,
            label: '7:00pm'
        },
        {
            value: 20,
            label: '8:00pm'
        },
        {
            value: 21,
            label: '9:00pm'
        },
        {
            value: 22,
            label: '10:00pm'
        },
        {
            value: 23,
            label: '11:00pm'
        },
        {
            value: 24,
            label: '12:00am'
        },
    ]

    const formik = useFormik({
        initialValues: {
            listing_title: listingData.listing.listing_title,
            listing_description: listingData.listing.listing_description,
            email: listingData.listing_locations.email,
            phone: listingData.listing_locations.phone_number,
            twitter: listingData.listing_locations.twitter,
            facebook: listingData.listing_locations.facebook,
            instagram: listingData.listing_locations.instagram,
            website: listingData.listing_locations.website,
            physical_address: listingData.listing_locations.physical_address,
            zip_code: listingData.listing_locations.zip_code,
            url_head: listingData.listing.url_head,
            monday_opening: "",
            monday_closing: "",
            tuesday_opening: "",
            tuesday_closing: "",
            wednesday_opening: "",
            wednesday_closing: "",
            thursday_opening: "",
            thursday_closing: "",
            friday_opening: "",
            friday_closing: "",
            saturday_opening: "",
            saturday_closing: "",
            sunday_opening: "",
            sunday_closing: "",
            video_url: listingData.listing.video_url,
            slug: listingData.listing.slug,
            latitude: listingData.listing_locations.latitude,
            longitude: listingData.listing_locations.logitude
        }
    });

    function updateListing(value) {
        const formData = new FormData();
        formData.append('listing_title', value.listing_title);
        formData.append('listing_description', value.listing_description);
        formData.append('physical_address', value.physical_address);
        formData.append('url_head', value.url_head);
        formData.append('email', value.email);
        formData.append('phone_number', value.phone);
        formData.append('website', value.website);
        formData.append('facebook_account', value.facebook);
        formData.append('instagram_account', value.instagram);
        formData.append('twitter_account', value.twitter);
        formData.append('zip_code', value.zip_code);
        formData.append('monday_opening', value.monday_opening);
        formData.append('monday_closing', value.monday_closing);
        formData.append('tuesday_opening', value.tuesday_opening);
        formData.append('tuesday_closing', value.tuesday_closing);
        formData.append('wednesday_opening', value.wednesday_opening);
        formData.append('wednesday_closing', value.wednesday_closing);
        formData.append('thursday_opening', value.thursday_opening);
        formData.append('thursday_closing', value.thursday_closing);
        formData.append('friday_opening', value.friday_opening);
        formData.append('friday_closing', value.friday_closing);
        formData.append('saturday_opening', value.saturday_opening);
        formData.append('saturday_closing', value.saturday_closing);
        formData.append('sunday_opening', value.sunday_opening);
        formData.append('sunday_closing', value.sunday_closing);
        formData.append('video_url', value.video_url);
        formData.append('slug', value.slug);
        formData.append('latitude', value.latitude);
        formData.append('longitude', value.longitude);
        formData.append('schedule', schedule);
        toast.info(`Please wait, the upload is on process`);

        // console.log("formData",formData)
        axios.post(window.$name + `/api/directory/consumer/update/listings/${listingData.listing.id}`, formData)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(FetchListing())
                    history.push("/account/directory")
                    toast.success(` "${response.data.message}"`)

                } else {
                    toast.error("something is wrong")
                }
            })
    }

    function HandlePublish() {
        // useEffect(() => {
        const listingPublish = async () => {
            const data = await Axios.post(`${window.$name}/api/directory/consumer/publish/listings/${listingData.listing.id}`)
                .then((response) => {
                    toast.success(` ${response.data.message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                    dispatch(FetchListingDetail(listingData.listing.secure_token))
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(` ${error.response.data.message}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000,
                        });
                    } else if (error.request) {
                        toast.error(` ${error.request}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000,
                        });
                    } else {
                        toast.error(` ${error.message}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000,
                        });
                    }
                });
        };
        listingPublish();
        // }, []);
    }

    function HandleUnPublish() {
        const unPublish = async () => {
            const data = await Axios.post(`${window.$name}/api/directory/consumer/unpublish/listings/${listingData.listing.id}`)
                .then((response) => {
                    toast.success(` ${response.data.message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                    dispatch(FetchListingDetail(listingData.listing.secure_token))
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(` ${error.response.data.message}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000,
                        });
                    } else if (error.request) {
                        toast.error(` ${error.request}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000,
                        });
                    } else {
                        toast.error(` ${error.message}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000,
                        });
                    }
                });
        };
        unPublish();
    }

    function HandleDelete() {
        const Delete = async () => {
            const data = await Axios.post(`${window.$name}/api/directory/consumer/delete/listings/${listingData.listing.id}`)
                .then((response) => {
                    toast.success(` ${response.data.message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                    dispatch(FetchListingDetail(listingData.listing.ListingToken));
                    window.location.href="/account/directory"
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(` ${error.response.data.message}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000,
                        });
                    } else if (error.request) {
                        toast.error(` ${error.request}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000,
                        });
                    } else {
                        toast.error(` ${error.message}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000,
                        });
                    }
                });
        };
        Delete();
    }

    const getSchedule = (e) => {
        setSchedule(e.target.value)
    }

    // @ts-ignore
    // @ts-ignore
    // @ts-ignore
    return (
        <React.Fragment>
            <Helmet>
                <title>{`listing action — ${theme.name}`}</title>
            </Helmet>
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            {
                                !listingData.listing.is_published ?
                                    <button type="submit"
                                            onClick={HandlePublish}
                                            className="btn btn-block btn-sm btn-warning border-0 rounded-pill">
                                        Publish Listing
                                    </button> :
                                    <button type="submit"
                                            data-toggle="modal"
                                            data-target="#unpublishModal"
                                            className="btn btn-block btn-sm btn-warning border-0 rounded-pill">
                                        Un publish Listing
                                    </button>
                            }
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <button
                                type="submit"
                                data-toggle="modal"
                                data-target="#editModal"
                                className="btn btn-block btn-sm btn-dark border-0 rounded-pill"
                            >Edit Listing
                            </button>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <button type="submit"
                                    data-toggle="modal"
                                    data-target="#deleteModal"
                                    className="btn btn-block btn-sm btn-danger border-0 rounded-pill">Delete Listing
                            </button>
                        </div>
                    </div>
                </div>
                {/*delete listing*/}
                <div className="modal fade" id="unpublishModal" tabIndex="-1"
                     role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close"
                                        data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body"> Are you sure want to Un publish this Listing</div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close
                                </button>
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    onClick={()=>HandleUnPublish()}
                                    className="btn btn-danger">Accept
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*delete listing*/}
                <div className="modal fade" id="deleteModal" tabIndex="-1"
                     role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close"
                                        data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body"> Are you sure want to delete this Listing</div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close
                                </button>
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    onClick={()=>HandleDelete()}
                                    className="btn btn-danger">Accept
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/*Edit Modal*/}
                <div className="modal fade" id="editModal" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4>Edit Listing</h4>
                                <button type="button" className="close"
                                        data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                               <div className="row">
                                   <div className="col-lg-4">
                                       <div className="form-group">
                                           <label htmlFor="inputCity">Listing Title</label>
                                           <input
                                               type="text"
                                               className="form-control"
                                               id="listing_title"
                                               name="listing_title"
                                               value={formik.values.listing_title}
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                           />
                                       </div>
                                   </div>
                                   <div className="col-lg-4">
                                       <div className="form-group">
                                           <label htmlFor="inputCity">Slug</label>
                                           <input
                                               type="text"
                                               className="form-control"
                                               id="slug"
                                               name="slug"
                                               value={formik.values.slug}
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                           />
                                       </div>
                                   </div>
                                   <div className="col-lg-4">
                                       <div className="form-group">
                                           <label htmlFor="inputCity">Video Link</label>
                                           <input
                                               type="text"
                                               className="form-control"
                                               id="video_url"
                                               name="video_url"
                                               value={formik.values.video_url}
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                           />
                                           {formik.touched.product_cost && formik.errors.product_cost ? (
                                               <div
                                                   className="text-danger">{formik.errors.product_cost}
                                               </div>
                                           ) : null}
                                       </div>
                                   </div>
                                   <div className="col-lg-12">
                                       <div className="form-group">
                                           <label htmlFor="inputCity">Listing Description</label>
                                           <textarea
                                               type="text"
                                               className="form-control"
                                               id="listing_description"
                                               name="listing_description"
                                               value={formik.values.listing_description}
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                           />
                                           {formik.touched.product_cost && formik.errors.product_cost ? (
                                               <div
                                                   className="text-danger">{formik.errors.product_cost}
                                               </div>
                                           ) : null}
                                       </div>
                                   </div>
                                   <div className="col-md-12">
                                       <div className="form-group">
                                           <label htmlFor="inputCity">Business Hours</label>
                                           <select
                                               className="form-control"
                                               id="country"
                                               name="country"
                                               onChange={(e) => getSchedule(e)}
                                               onBlur={formik.handleBlur}
                                               value={formik.values.country}
                                           >
                                               <option value="">Select Working Schedule</option>
                                               <option key="1" value="selected">
                                                   Open for selected hours
                                               </option>
                                               <option key="2" value="always">
                                                   Always Open (Open 24 hours)
                                               </option>
                                               <option key="3" value="appointment">
                                                   By Appointment only
                                               </option>
                                           </select>
                                       </div>
                                       {schedule == "selected" ?
                                           <div className="row">
                                               <div className="col-md-2">
                                                   <div className="form-group">
                                                       <label htmlFor="inputState">Monday</label>
                                                   </div>
                                               </div>
                                               <div className="col-md-5">
                                                   <div className="form-group">
                                                       <label htmlFor="inputState">opening</label>
                                                       <select
                                                           className="form-control"
                                                           id="monday_opening"
                                                           name="monday_opening"
                                                           onBlur={formik.handleBlur}
                                                           onChange={formik.handleChange}
                                                           value={formik.values.monday_opening}
                                                       >
                                                           <option value="">Select time...</option>
                                                           {Time.map((index) => (
                                                               <option key={index.value}
                                                                       value={index.label}>
                                                                   {index.label}
                                                               </option>
                                                           ))}
                                                       </select>

                                                       {formik.touched.monday_opening && formik.errors.monday_opening ? (
                                                           <div className="text-danger">
                                                               {formik.errors.monday_opening}
                                                           </div>
                                                       ) : null}
                                                   </div>
                                               </div>
                                               <div className="col-md-5">
                                                   <div className="form-group">
                                                       <label htmlFor="inputCity">closing</label>
                                                       <select
                                                           className="form-control"
                                                           id="monday_closing"
                                                           name="monday_closing"
                                                           onBlur={formik.handleBlur}
                                                           onChange={formik.handleChange}
                                                           value={formik.values.monday_closing}
                                                       >
                                                           <option value="">Select time...</option>
                                                           {Time.map((index) => (
                                                               <option key={index.value}
                                                                       value={index.label}>
                                                                   {index.label}
                                                               </option>
                                                           ))}
                                                       </select>

                                                       {formik.touched.monday_closing && formik.errors.monday_closing ? (
                                                           <div className="text-danger">
                                                               {formik.errors.monday_closing}
                                                           </div>
                                                       ) : null}
                                                   </div>
                                               </div>
                                               <div className="col-md-2">
                                                   <div className="form-group">
                                                       <label htmlFor="inputState">Tuesday</label>
                                                   </div>
                                               </div>
                                               <div className="col-md-5">
                                                   <div className="form-group">
                                                       <label htmlFor="inputCity">
                                                           opening</label>
                                                       <select
                                                           className="form-control"
                                                           id="tuesday_opening"
                                                           name="tuesday_opening"
                                                           onBlur={formik.handleBlur}
                                                           onChange={formik.handleChange}
                                                           value={formik.values.tuesday_opening}
                                                       >
                                                           <option value="">Select time...</option>
                                                           {Time.map((index) => (
                                                               <option key={index.value}
                                                                       value={index.label}>
                                                                   {index.label}
                                                               </option>
                                                           ))}
                                                       </select>

                                                       {formik.touched.tuesday_opening && formik.errors.tuesday_opening ? (
                                                           <div className="text-danger">
                                                               {formik.errors.tuesday_opening}
                                                           </div>
                                                       ) : null}
                                                   </div>
                                               </div>
                                               <div className="col-md-5">
                                                   <div className="form-group">
                                                       <label htmlFor="inputCity">closing</label>
                                                       <select
                                                           className="form-control"
                                                           id="tuesday_closing"
                                                           name="tuesday_closing"
                                                           onBlur={formik.handleBlur}
                                                           onChange={formik.handleChange}
                                                           value={formik.values.tuesday_closing}
                                                       >
                                                           <option value="">Select time...</option>
                                                           {Time.map((index) => (
                                                               <option key={index.value}
                                                                       value={index.label}>
                                                                   {index.label}
                                                               </option>
                                                           ))}
                                                       </select>


                                                       {formik.touched.tuesday_closing && formik.errors.tuesday_closing ? (
                                                           <div className="text-danger">
                                                               {formik.errors.tuesday_closing}
                                                           </div>
                                                       ) : null}
                                                   </div>
                                               </div>
                                               <div className="col-md-2">
                                                   <div className="form-group">
                                                       <label htmlFor="inputState">Wednesday</label>
                                                   </div>
                                               </div>
                                               <div className="col-md-5">
                                                   <div className="form-group">
                                                       <label htmlFor="inputCity">opening</label>
                                                       <select
                                                           className="form-control"
                                                           id="wednesday_opening"
                                                           name="wednesday_opening"
                                                           onBlur={formik.handleBlur}
                                                           onChange={formik.handleChange}
                                                           value={formik.values.wednesday_opening}
                                                       >
                                                           <option value="">Select time...</option>
                                                           {Time.map((index) => (
                                                               <option key={index.value}
                                                                       value={index.label}>
                                                                   {index.label}
                                                               </option>
                                                           ))}
                                                       </select>

                                                       {formik.touched.wednesday_opening && formik.errors.wednesday_opening ? (
                                                           <div className="text-danger">
                                                               {formik.errors.wednesday_opening}
                                                           </div>
                                                       ) : null}
                                                   </div>
                                               </div>
                                               <div className="col-md-5">
                                                   <div className="form-group">
                                                       <label htmlFor="inputCity">closing</label>
                                                       <select
                                                           className="form-control"
                                                           id="wednesday_closing"
                                                           name="wednesday_closing"
                                                           onBlur={formik.handleBlur}
                                                           onChange={formik.handleChange}
                                                           value={formik.values.wednesday_closing}
                                                       >
                                                           <option value="">Select time...</option>
                                                           {Time.map((index) => (
                                                               <option key={index.value}
                                                                       value={index.label}>
                                                                   {index.label}
                                                               </option>
                                                           ))}
                                                       </select>

                                                       {formik.touched.wednesday_closing && formik.errors.wednesday_closing ? (
                                                           <div className="text-danger">
                                                               {formik.errors.wednesday_closing}
                                                           </div>
                                                       ) : null}
                                                   </div>
                                               </div>
                                               <div className="col-md-2">
                                                   <div className="form-group">
                                                       <label htmlFor="inputState">Thursday</label>
                                                   </div>
                                               </div>
                                               <div className="col-md-5">
                                                   <div className="form-group">
                                                       <label htmlFor="inputCity">opening</label>
                                                       <select
                                                           className="form-control"
                                                           id="thursday_opening"
                                                           name="thursday_opening"
                                                           onBlur={formik.handleBlur}
                                                           onChange={formik.handleChange}
                                                           value={formik.values.thursday_opening}
                                                       >
                                                           <option value="">Select time...</option>
                                                           {Time.map((index) => (
                                                               <option key={index.value}
                                                                       value={index.label}>
                                                                   {index.label}
                                                               </option>
                                                           ))}
                                                       </select>

                                                       {formik.touched.thursday_opening && formik.errors.thursday_opening ? (
                                                           <div className="text-danger">
                                                               {formik.errors.thursday_opening}
                                                           </div>
                                                       ) : null}
                                                   </div>
                                               </div>
                                               <div className="col-md-5">
                                                   <div className="form-group">
                                                       <label htmlFor="inputCity">closing</label>
                                                       <select
                                                           className="form-control"
                                                           id="thursday_closing"
                                                           name="thursday_closing"
                                                           onBlur={formik.handleBlur}
                                                           onChange={formik.handleChange}
                                                           value={formik.values.thursday_closing}
                                                       >
                                                           <option value="">Select time...</option>
                                                           {Time.map((index) => (
                                                               <option key={index.value}
                                                                       value={index.label}>
                                                                   {index.label}
                                                               </option>
                                                           ))}
                                                       </select>

                                                       {formik.touched.thursday_closing && formik.errors.thursday_closing ? (
                                                           <div className="text-danger">
                                                               {formik.errors.thursday_closing}
                                                           </div>
                                                       ) : null}
                                                   </div>
                                               </div>
                                               <div className="col-md-2">
                                                   <div className="form-group">
                                                       <label htmlFor="inputState">Friday</label>
                                                   </div>
                                               </div>
                                               <div className="col-md-5">
                                                   <div className="form-group">
                                                       <label htmlFor="inputCity">opening</label>
                                                       <select
                                                           className="form-control"
                                                           id="friday_opening"
                                                           name="friday_opening"
                                                           onBlur={formik.handleBlur}
                                                           onChange={formik.handleChange}
                                                           value={formik.values.friday_opening}
                                                       >
                                                           <option value="">Select time...</option>
                                                           {Time.map((index) => (
                                                               <option key={index.value}
                                                                       value={index.label}>
                                                                   {index.label}
                                                               </option>
                                                           ))}
                                                       </select>
                                                       {formik.touched.friday_opening && formik.errors.friday_opening ? (
                                                           <div className="text-danger">
                                                               {formik.errors.friday_opening}
                                                           </div>
                                                       ) : null}
                                                   </div>
                                               </div>
                                               <div className="col-md-5">
                                                   <div className="form-group">
                                                       <label htmlFor="inputCity">closing</label>
                                                       <select
                                                           className="form-control"
                                                           id="friday_closing"
                                                           name="friday_closing"
                                                           onBlur={formik.handleBlur}
                                                           onChange={formik.handleChange}
                                                           value={formik.values.friday_closing}
                                                       >
                                                           <option value="">Select time...</option>
                                                           {Time.map((index) => (
                                                               <option key={index.value}
                                                                       value={index.label}>
                                                                   {index.label}
                                                               </option>
                                                           ))}
                                                       </select>
                                                       {formik.touched.friday_closing && formik.errors.friday_closing ? (
                                                           <div className="text-danger">
                                                               {formik.errors.friday_closing}
                                                           </div>
                                                       ) : null}
                                                   </div>
                                               </div>
                                               <div className="col-md-2">
                                                   <div className="form-group">
                                                       <label htmlFor="inputState">Saturday</label>
                                                   </div>
                                               </div>
                                               <div className="col-md-5">
                                                   <div className="form-group">
                                                       <label htmlFor="inputCity">opening</label>
                                                       <select
                                                           className="form-control"
                                                           id="saturday_opening"
                                                           name="saturday_opening"
                                                           onBlur={formik.handleBlur}
                                                           onChange={formik.handleChange}
                                                           value={formik.values.saturday_opening}
                                                       >
                                                           <option value="">Select time...</option>
                                                           {Time.map((index) => (
                                                               <option key={index.value}
                                                                       value={index.label}>
                                                                   {index.label}
                                                               </option>
                                                           ))}
                                                       </select>
                                                       {formik.touched.saturday_opening && formik.errors.saturday_opening ? (
                                                           <div className="text-danger">
                                                               {formik.errors.saturday_opening}
                                                           </div>
                                                       ) : null}
                                                   </div>
                                               </div>
                                               <div className="col-md-5">
                                                   <div className="form-group">
                                                       <label htmlFor="inputCity">closing</label>
                                                       <select
                                                           className="form-control"
                                                           id="saturday_closing"
                                                           name="saturday_closing"
                                                           onBlur={formik.handleBlur}
                                                           onChange={formik.handleChange}
                                                           value={formik.values.saturday_closing}
                                                       >
                                                           <option value="">Select time...</option>
                                                           {Time.map((index) => (
                                                               <option key={index.value}
                                                                       value={index.label}>
                                                                   {index.label}
                                                               </option>
                                                           ))}
                                                       </select>
                                                       {formik.touched.saturday_closing && formik.errors.saturday_closing ? (
                                                           <div className="text-danger">
                                                               {formik.errors.saturday_closing}
                                                           </div>
                                                       ) : null}
                                                   </div>
                                               </div>
                                               <div className="col-md-2">
                                                   <div className="form-group">
                                                       <label htmlFor="inputState">Sunday</label>
                                                   </div>
                                               </div>
                                               <div className="col-md-5">
                                                   <div className="form-group">
                                                       <label htmlFor="inputCity">opening</label>
                                                       <select
                                                           className="form-control"
                                                           id="sunday_opening"
                                                           name="sunday_opening"
                                                           onBlur={formik.handleBlur}
                                                           onChange={formik.handleChange}
                                                           value={formik.values.sunday_opening}
                                                       >
                                                           <option value="">Select time...</option>
                                                           {Time.map((index) => (
                                                               <option key={index.value}
                                                                       value={index.label}>
                                                                   {index.label}
                                                               </option>
                                                           ))}
                                                       </select>
                                                       {formik.touched.sunday_opening && formik.errors.sunday_opening ? (
                                                           <div className="text-danger">
                                                               {formik.errors.sunday_opening}
                                                           </div>
                                                       ) : null}
                                                   </div>
                                               </div>
                                               <div className="col-md-5">
                                                   <div className="form-group">
                                                       <label htmlFor="inputCity">closing</label>
                                                       <select
                                                           className="form-control"
                                                           id="sunday_closing"
                                                           name="sunday_closing"
                                                           onBlur={formik.handleBlur}
                                                           onChange={formik.handleChange}
                                                           value={formik.values.sunday_closing}
                                                       >
                                                           <option value="">Select time...</option>
                                                           {Time.map((index) => (
                                                               <option key={index.value}
                                                                       value={index.label}>
                                                                   {index.label}
                                                               </option>
                                                           ))}
                                                       </select>

                                                       {formik.touched.sunday_closing && formik.errors.sunday_closing ? (
                                                           <div className="text-danger">
                                                               {formik.errors.sunday_closing}
                                                           </div>
                                                       ) : null}
                                                   </div>
                                               </div>

                                           </div>
                                           : schedule === "always" ?
                                               <div className="row">
                                                   <div className="col-md-12">
                                                       Your schedule is set for 24 hours.
                                                   </div>
                                               </div>
                                               : schedule === "appointment" ?
                                                   <div className="row">
                                                       <div className="col-md-12">
                                                           Your schedule is set appointment only
                                                       </div>
                                                   </div>
                                                   :
                                                   <div className="row">
                                                       <div className="col-md-12">
                                                           Select schedule above to set your business hours
                                                       </div>
                                                   </div>
                                       }
                                   </div>

                                   <div className="col-md-4">
                                       <div className="form-group">
                                           <label htmlFor="inputCity">Email</label>
                                           <input
                                               type="text"
                                               className="form-control"
                                               id="email"
                                               value={formik.values.email}
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                           />
                                           {formik.touched.email && formik.errors.email ? (
                                               <div
                                                   className="text-danger">{formik.errors.email}</div>
                                           ) : null}
                                       </div>
                                   </div>
                                   <div className="col-md-4">
                                       <div className="form-group">
                                           <label htmlFor="inputCity">Phone</label>
                                           <input
                                               type="text"
                                               className="form-control"
                                               id="phone"
                                               value={formik.values.phone}
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                           />
                                           {formik.touched.phone && formik.errors.phone ? (
                                               <div
                                                   className="text-danger">{formik.errors.phone}</div>
                                           ) : null}
                                       </div>
                                   </div>
                                   <div className="col-md-4">
                                       <div className="form-group">
                                           <label htmlFor="inputCity">Website
                                               link</label>
                                           <input
                                               type="text"
                                               className="form-control"
                                               id="website"
                                               value={formik.values.website}
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                           />
                                           {formik.touched.website && formik.errors.website ? (
                                               <div
                                                   className="text-danger">{formik.errors.website}</div>
                                           ) : null}
                                       </div>
                                   </div>
                                   <div className="col-md-4">
                                       <div className="form-group">
                                           <label htmlFor="inputCity">Facebook
                                               link</label>
                                           <input
                                               type="text"
                                               className="form-control"
                                               id="facebook"
                                               value={formik.values.facebook}
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                           />
                                           {formik.touched.facebook && formik.errors.facebook ? (
                                               <div
                                                   className="text-danger">{formik.errors.facebook}</div>
                                           ) : null}
                                       </div>
                                   </div>
                                   <div className="col-md-4">
                                       <div className="form-group">
                                           <label htmlFor="inputCity">Instagram
                                               link</label>
                                           <input
                                               type="text"
                                               className="form-control"
                                               id="instagram"
                                               value={formik.values.instagram}
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                           />
                                           {formik.touched.instagram && formik.errors.instagram ? (
                                               <div
                                                   className="text-danger">{formik.instagram}</div>
                                           ) : null}
                                       </div>
                                   </div>
                                   <div className="col-md-4">
                                       <div className="form-group">
                                           <label htmlFor="inputCity">Twitter
                                               link</label>
                                           <input
                                               type="text"
                                               className="form-control"
                                               id="twitter"
                                               value={formik.values.twitter}
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                           />
                                           {formik.touched.twitter && formik.errors.twitter ? (
                                               <div
                                                   className="text-danger">{formik.errors.twitter}</div>
                                           ) : null}
                                       </div>
                                   </div>
                                   <div className="col-md-4">
                                       <div className="form-group">
                                           <label htmlFor="inputCity">Custom Listing Page Name</label>
                                           <input
                                               type="text"
                                               className="form-control"
                                               id="url_head"
                                               value={formik.values.url_head}
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                           />
                                           {formik.touched.url_head && formik.errors.url_head ? (
                                               <div
                                                   className="text-danger">{formik.errors.url_head}</div>
                                           ) : null}
                                       </div>
                                   </div>
                                   <div className="col-md-4">
                                       <div className="form-group">
                                           <label htmlFor="inputCity">Zip code</label>
                                           <input
                                               type="text"
                                               className="form-control"
                                               id="zip_code"
                                               value={formik.values.zip_code}
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                           />
                                           {formik.touched.zip_code && formik.errors.zip_code ? (
                                               <div
                                                   className="text-danger">{formik.errors.zip_code}</div>
                                           ) : null}
                                       </div>
                                   </div>
                                   <div className="col-md-4">
                                       <div className="form-group">
                                           <label htmlFor="inputCity">Physical
                                               Address</label>
                                           <input
                                               type="text"
                                               className="form-control"
                                               id="physical_address"
                                               value={formik.values.physical_address}
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                           />
                                           {formik.touched.physical_address && formik.errors.physical_address ? (
                                               <div
                                                   className="text-danger">{formik.physical_address}</div>
                                           ) : null}
                                       </div>
                                   </div>

                                   <div className="col-md-6">
                                       <div className="form-group">
                                           <label htmlFor="inputCity">Latitude</label>
                                           <input
                                               type="text"
                                               className="form-control"
                                               id="latitude"
                                               value={formik.values.latitude}
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                           />
                                       </div>
                                   </div>
                                   <div className="col-md-6">
                                       <div className="form-group">
                                           <label htmlFor="inputCity">Longitude</label>
                                           <input
                                               type="text"
                                               className="form-control"
                                               id="longitude"
                                               value={formik.values.longitude}
                                               onChange={formik.handleChange}
                                               onBlur={formik.handleBlur}
                                           />
                                       </div>
                                   </div>
                               </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close
                                </button>
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    onClick={()=>updateListing(formik.values)}
                                    className="btn btn-danger">update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListingAction)
