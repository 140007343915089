import {customertoken} from "../../api/shop";
import Axios from "axios";
import {QUOTEAPPLIED} from "./appliedQouteActionTypes";
import {toast} from "react-toastify";
import {FetchAppliedRate} from '../QuoteRate/AppliedRateAction'

export function FetchAppliedQuote() {
    const data = {
        buyer_token: customertoken(),
    };
    return async (dispatch) =>
        Axios.post(window.$name + "/api/quote/get-approved-quotes", data)
            .then(function (response) {
                if (response.data.status === 401) {
                    dispatch({type: QUOTEAPPLIED, payload: []});
                } else {
                    dispatch({type: QUOTEAPPLIED, payload: response.data.ProductQuotes});
                    dispatch(FetchAppliedRate(JSON.parse(response.data.rates.rate)));
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

