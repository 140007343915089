// react
import React, { useEffect,useState,useMemo } from "react";
// third-party
// import axios from 'axios';
import { Helmet } from "react-helmet-async";
import shopApi, { agenttoken } from "../../api/shop";
import { useProductTabs } from "../../services/hooks";
import BlockCategories from "../blocks/BlockCategories";
import BlockFeatures from "../blocks/BlockFeatures";
import BlockProductsCarousel from "../blocks/BlockProductsCarousel";
import BlockSlideShow from "../blocks/BlockSlideShow";
import BlockFeaturesm from "../blocks/BlockFeaturesm";
import {  useSelector } from "react-redux";
import { withRouter } from 'react-router-dom';
import ServiceCarousel from "../shop/services/serviceCarousel";
import serviceShopApi from "../../api/serviceApi";
// import BlockCategoryCarousel from "../blocks/BlockCategoriesCarousel";
function HomePageOne(props) {
  const LoginAuth = useSelector(state => state.auth.isLogin);
  const AgentcustomerToken = props.location.state;
  //  if(LoginAuth && agenttoken() !== null && AgentcustomerToken !== undefined){  
  //   localStorage.setItem("agentcustomer_token",AgentcustomerToken);
  //  } 

  /**
   * Featured products.
   */
  const popularProducts = useProductTabs(
    useMemo(() => [], []),
    (tab) => shopApi.getPopularProducts({ limit: 12 })
  );
    const NewArrivalsProducts = useProductTabs(
        useMemo(() => [], []),
        (tab) => shopApi.NewArrivalsProducts({ limit: 12 })
    );
  const featuredProducts = useProductTabs(
    useMemo(() => [], []),
    (tab) => shopApi.getfeaturedProducts({ limit: 12 })
  );
  const AllService = useProductTabs(
      useMemo(() => [], []),
      (tab) => serviceShopApi.getLatestService({ limit: 12 })
  );
  return (
    <React.Fragment>
      <Helmet>
        <title>Ezytrade Africa: Manufactures,Suppliers,E..</title>
      </Helmet>
      {useMemo(
        () => (
          <BlockSlideShow  withDepartments />
        ),
        []
      )}
        {useMemo(
            () => (
                <BlockFeatures />
            ),
            []
        )}
      {useMemo(
        () => (
          <BlockProductsCarousel
            title="Featured Products"
            layout="grid-4"
            rows={2}
            products={featuredProducts.data}
            loading={featuredProducts.isLoading}
            groups={featuredProducts.tabs}
            onGroupClick={featuredProducts.handleTabChange}
          />
        ),
        [featuredProducts]
      )}
        {popularProducts.data.length >=7 ?
            (
            <BlockProductsCarousel
                title="Popular Products"
                layout="grid-4"
                rows={2}
                products={popularProducts.data}
                loading={popularProducts.isLoading}
                groups={popularProducts.tabs}
                onGroupClick={popularProducts.handleTabChange}
            />
            ):
            <div/>
        }

        {useMemo(() => (
            <BlockProductsCarousel
                title="New Arrivals"
                layout="grid-4"
                rows={2}
                products={NewArrivalsProducts.data}
                loading={NewArrivalsProducts.isLoading}
                groups={NewArrivalsProducts.tabs}
                onGroupClick={NewArrivalsProducts.handleTabChange}
            />
        ), [NewArrivalsProducts])}

      {useMemo(
          () => (
              <ServiceCarousel
                  title="Services Available"
                  layout="grid-4"
                  rows={1}
                  products={AllService.data}
                  loading={AllService.isLoading}
                  groups={AllService.tabs}
                  onGroupClick={AllService.handleTabChange}
              />
          ),
          [AllService]
      )}

      {/*{useMemo(*/}
      {/*    () => (*/}
      {/*        <ServiceCarousel*/}
      {/*            title="Listings Available"*/}
      {/*            layout="grid-4"*/}
      {/*            rows={1}*/}
      {/*            products={AllService.data}*/}
      {/*            loading={AllService.isLoading}*/}
      {/*            groups={AllService.tabs}*/}
      {/*            onGroupClick={AllService.handleTabChange}*/}
      {/*        />*/}
      {/*    ),*/}
      {/*    [AllService]*/}
      {/*)}*/}
        <BlockCategories title="Popular Categories" layout="classic" />
        {useMemo(
            () => (
                <BlockFeaturesm   />
            ),
            []
        )}
    </React.Fragment>
  );
}

export default withRouter(HomePageOne);