import React, {useEffect, useState} from 'react';
import Slider from "react-slick";

import img1 from '../../assets/images/img17.jpg';
import img2 from '../../assets/images/img18.jpg';
import img3 from '../../assets/images/img19.jpg';
import img4 from '../../assets/images/img20.jpg';
import img5 from '../../assets/images/img21.jpg';
import img6 from '../../assets/images/img22.jpg';
import '../../assets/css/style.css'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'


function ListingDetailsGallery(props) {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const [slider1, setSlider1] = useState(null);
    const [slider2, setSlider2] = useState(null);
    const {images} = props
    useEffect(() => {

        setNav1(slider1);
        setNav2(slider2);

    });

    const state = {
        title: 'Product name',
        items: [
            {
                img: img1,
            },
            {
                img: img2,
            },
            {
                img: img3,
            },
            {
                img: img4,
            },
            {
                img: img5,
            },
            {
                img: img6,
            },
        ],
        slideDots: [
            {
                img: images[0].image_url
            },
            {
                img:images[0].image_url
            },
            {
                img:images[0].image_url
            },
            {
                img: images[0].image_url
            },
            {
                img: images[0].image_url
            },
            {
                img: images[0].image_url
            }
        ]
    }
    const settingsMain = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        asNavFor: '.slider-nav',
        className: 'places-carousel gallery-carousel padding-top-35px'
    };
    const settingsThumbs = {
        slidesToShow: 6,
        slidesToScroll: 1,
        asNavFor: '.slider-for',
        dots: false,
        swipeToSlide: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 700,
                settings: {
                    arrows: false,
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 500,
                settings: {
                    arrows: false,
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 400,
                settings: {
                    arrows: false,
                    slidesToShow: 1
                }
            }
        ]
    };
    return (
        <>
            <h2 className="widget-title">
                Gallery
            </h2>
            <div className="title-shape"></div>
            <Slider
                {...settingsMain}
                asNavFor={nav2}
                ref={slider => (setSlider1(slider))}
            >
                {images.map((slide, i) => {
                    return (
                        <div key={i} className="gallery-item">
                            <img src={slide.image_url} alt="Gallery"/>
                        </div>
                    )
                })}
            </Slider>
            <div className="gallery-carousel-dots">
                <Slider
                    {...settingsThumbs}
                    asNavFor={nav1}
                    ref={slider => (setSlider2(slider))}>
                    {images.map((slide, i) => {
                        return (
                            <div key={i}>
                                <img src={slide.image_url} alt=""/>
                            </div>
                        )
                    })}
                </Slider>
            </div>
        </>
    );
}

export default ListingDetailsGallery;
