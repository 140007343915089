// react
import React, {useCallback, useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {Helmet} from "react-helmet-async";
import {Link} from "react-router-dom";
import {url} from "../../services/utils";
import {handleBacks, handleSubmits} from "./checkout";
import theme from "../../data/theme";
import CurrencyFormat from "react-currency-format";
import Axios from "axios";
import {toast} from "react-toastify";
import {
    agentcustomerToken,
    agenttoken,
    PaymentToken,
    customertoken,
    userCurrency,
} from "../../api/shop";
import {cartRemove} from "../../store/cart";
import PaymentOptioncomponent from "../shop/paymentOption";
import "../blocks/files.css";
import {Row} from "reactstrap";
import {useHistory} from "react-router";
import axios from "axios";

function ShopPageCart(props) {
    const history = useHistory();
    const {cartRemove} = props
    const [paymentOption_token, setPaymentToken] = useState(false);
    const [shipping, setAddress] = useState(false);

    function toggle(value) {
        setPaymentToken(value);
    }

    // useEffect(() => {

    function renderItems() {
        const {cart, cartRemove} = props;
        // console.log(cart);

        return cart.items.map((item) => {
            let image;
            let options;
            if (item.product.images.length > 0) {
                image = (
                    <div className="product-image">
                        <Link
                            to={url.product(item.product)}
                            className="product-image__body"
                        >
                            <img
                                className="product-image__img"
                                src={item.product.images}
                                alt=""
                            />
                        </Link>
                    </div>
                );
            }
            if (item.options.length > 0) {
                options = (
                    <ul className="cart-table__options">
                        {item.options.map((option, index) => (
                            <li
                                key={index}
                            >{`${option.optionTitle}: ${option.valueTitle}`}</li>
                        ))}
                    </ul>
                );
            }
            return (
                <tr key={item.id} className="cart-table__row">
                    <td className="cart-table__column cart-table__column--image">
                        {image}
                    </td>
                    <td className="cart-table__column cart-table__column--product">
                        <Link
                            to={url.product(item.product)}
                            className="cart-table__product-name"
                        >
                            {item.product.name}
                        </Link>
                        {options}
                    </td>
                    <td className="cart-table__column cart-table__column--product">
                        {item.product.preference_color !== null &&
                        item.product.preference_size !== null ? (
                            <>
                                <Row>
                                    <Link className="cart-table__product-name">
                                        Color :&nbsp;&nbsp; {item.product.preference_color}
                                    </Link>
                                </Row>
                                <Row>
                                    Size :&nbsp;&nbsp; <Link>{item.product.preference_size}</Link>
                                </Row>
                            </>
                        ) : item.product.preference_color !== null &&
                        item.product.preference_size === null ? (
                            <Row>
                                <Link className="cart-table__product-name">
                                    Color :&nbsp;&nbsp; {item.product.preference_color}
                                </Link>
                            </Row>
                        ) : item.product.preference_color === null &&
                        item.product.preference_size !== null ? (
                            <Row>
                                <Link className="cart-table__product-name">
                                    Size :&nbsp;&nbsp; {item.product.preference_size}
                                </Link>
                            </Row>
                        ) : (
                            <h6>Empty</h6>
                        )}
                    </td>
                    <td
                        className="cart-table__column cart-table__column--price"
                        data-title="Price"
                    >
                        {/*<Currency value={item.price}/>*/}
                        <CurrencyFormat
                            value={item.price}
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                                <div>
                                    {" "}
                                    {userCurrency()} {value}
                                </div>
                            )}
                        />
                    </td>
                    <td
                        className="cart-table__column cart-table__column--quantity"
                        data-title="Quantity"
                    >
                        {item.quantity}
                    </td>
                    <td
                        className="cart-table__column cart-table__column--total"
                        data-title="Total"
                    >
                        {/*<Currency value={item.total}/>*/}
                        <CurrencyFormat
                            value={item.total}
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                                <div>
                                    {" "}
                                    {userCurrency()} {value}
                                </div>
                            )}
                        />
                    </td>
                    <td className="cart-table__column cart-table__column--remove"/>
                </tr>
            );
        });
    }

    function renderCart() {
        const {
            activeStep,
            shipping,
            cart,
            MyownShipping,
            Myownaddress,
            cartRemove,
            isLogin,
        } = props;
        // console.log(session);
        const agentcustomer_Token = agentcustomerToken();
        localStorage.getItem("masterCard");
        const agentToken = agenttoken();

        console.log("cart",cart);

        function GuestConfirmOrder() {
            // let deliveryOption = localStorage.getItem("deliveryOptions");
            // let cart_details = localStorage.getItem("cartData");
            let data = {
                guest_full_name: localStorage.getItem("full_name"),
                guest_phone_number: localStorage.getItem("phone_number"),
                guest_email: localStorage.getItem("email"),
                guest_location: localStorage.getItem("location"),
                payment_option: PaymentToken(),
                total_amount: cart.subtotal,
                agent_token: "",
                currency: userCurrency(),
                push_number: localStorage.getItem("phone"),
                total_vat: cart.vattotal,
                shipping_options: localStorage.getItem("deliveryOptions"),
                cart_details: cart.items,
                shippingAddress_token: "",
            };
            // console.log(data);

            Axios.post(window.$name + "/api/order/create-guest-order", data)
                .then((response) => {
                    toast.success(` "${response.data.message}"`);
                    localStorage.setItem(
                        "success_data",
                        JSON.stringify(response.data.payload)
                    );
                        localStorage.removeItem("deliveryOptions");
                        localStorage.removeItem("paymentOption_token");
                        localStorage.removeItem("full_name");
                        localStorage.removeItem("phone_number");
                        localStorage.removeItem("email");
                        localStorage.removeItem("location");
                        cartRemove();
                    // setSession(response.data.payload.session_id);
                    if (localStorage.getItem("masterCard") === "Master Card") {
                        history.push("/wait-for-nmb")
                    } else {
                        handleSubmits(activeStep);
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        toast.error(
                            `"${error.response.data.message}" Error Status  "${error.response.status}" `
                        );
                    } else if (error.request) {
                        toast.error(`"${error.request}" `);
                    } else {
                        toast.error(`"${error.message}" `);
                    }
                });
        }

        function ConfirmOrder(shipping_token) {
            let deliveryOption = localStorage.getItem("deliveryOptions");
            let cartdetails = localStorage.getItem("cartData");
            if (agentcustomer_Token !== null && agentToken !== null) {
                let data = {
                    user_token: agentcustomer_Token,
                    shippingAddress_token: shipping_token,
                    payment_option: PaymentToken(),
                    total_amount: cart.subtotal,
                    agent_token: agentToken,
                    currency: userCurrency(),
                    push_number: localStorage.getItem("phone"),
                    total_vat: cart.vattotal,
                    shipping_options: deliveryOption,
                    cart_details: cartdetails,
                };

                Axios.post(window.$name + "/api/order/create-order", data)
                    .then((response) => {
                        toast.success(` "${response.data.message}"`);
                        localStorage.setItem(
                            "success_data",
                            JSON.stringify(response.data.payload)
                        );
                        localStorage.removeItem("deliveryOptions");
                        localStorage.removeItem("paymentOption_token");
                        cartRemove("");
                        if (localStorage.getItem("masterCard") === "Master Card") {
                            history.push("/wait-for-nmb")
                        } else {
                            handleSubmits(activeStep);
                        }
                    })
                    .catch(function (error) {
                        if (error.response) {
                            toast.error(
                                `"${error.response.data.message}" Error Status  "${error.response.status}" `
                            );
                        } else if (error.request) {
                            toast.error(`"${error.request}" `);
                        } else {
                            toast.error(`"${error.message}" `);
                        }
                    });
            } else {
                let data = {
                    user_token: customertoken(),
                    shippingAddress_token: shipping_token,
                    payment_option: PaymentToken(),
                    total_amount: cart.subtotal,
                    agent_token: "",
                    currency: userCurrency(),
                    push_number: localStorage.getItem("phone"),
                    total_vat: cart.vattotal,
                    shipping_options: deliveryOption,
                    cart_details: cartdetails,
                };
                Axios.post(window.$name + "/api/order/create-order", data)
                    .then((response) => {
                        toast.success(` "${response.data.message}"`);
                        localStorage.setItem(
                            "success_data",
                            JSON.stringify(response.data.payload)
                        );
                        localStorage.removeItem("deliveryOptions");
                        localStorage.removeItem("paymentOption_token");
                        cartRemove();
                        // setSession(response.data.payload.session_id);
                        if (localStorage.getItem("masterCard") === "Master Card") {
                            history.push("/wait-for-nmb")
                        } else {
                            handleSubmits(activeStep);
                        }
                    })
                    .catch(function (error) {
                        if (error.response) {
                            toast.error(
                                `"${error.response.data.message}" Error Status  "${error.response.status}" `
                            );
                        } else if (error.request) {
                            toast.error(`"${error.request}" `);
                        } else {
                            toast.error(`"${error.message}" `);
                        }
                    });
            }
        }

        return (
            <div className="cart d-flex justify-content-center">
                <div className="card ">
                    <div className="card-title">
                        <br/>
                        <h5 className="text-center">
                            REVIEW,SELECT PAYMENT OPTION AND CONFIRM YOUR ORDER ITEMS
                        </h5>
                    </div>
                    <div className="card-body -mt-2 ">
                        {/* {MyownShipping.data === "I will arrange Delivery" ? (
              <div>
                <h3> Pickup information</h3>
                {Myownaddress.map((address) => (
                  <div>
                    {address.is_active ? (
                      <div>
                        <p>
                          Full Name : &nbsp;&nbsp;{address.address_fullname}
                        </p>
                        <p>
                          Mobile : &nbsp;&nbsp;{address.address_mobileNumber}
                        </p>
                        <p>Id Type : &nbsp;&nbsp;{address.id_type}</p>
                        <p>Id Number : &nbsp;&nbsp;{address.id_number}</p>
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <p className="font-weight-bold">Shipping Address to:</p>
                {shipping.map((address) => (
                  <div>
                    {address.is_active ? (
                      <div>
                        <p>
                          <span className="text-uppercase">
                            {address.address_fullname}
                          </span>
                        </p>
                        <p>
                          {address.address_mobileNumber}
                          or {address.address_mobileNumber2}
                        </p>
                        <p className="text-capitalize">
                          {address.country.country}, {address.region.region},{" "}
                          {address.address_district}
                        </p>
                        <p>Post Code : {address.address_postcode} </p>
                        <p>House Number : {address.address_house_number}</p>
                        <p>Area Name : {address.area}</p>
                        <p>Address Type : {address.address_AddressType}</p>
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                ))}
              </div>
            )}*/}

                        <br/>
                        <PaymentOptioncomponent onClick={toggle.bind(this)}/>
                        <br/>
                        <table className="cart__table cart-table">
                            <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column cart-table__column--image">
                                    Image
                                </th>
                                <th className="cart-table__column cart-table__column--product">
                                    Product
                                </th>
                                <th className="cart-table__column cart-table__column--product">
                                    Preference
                                </th>
                                <th className="cart-table__column cart-table__column--price">
                                    Price
                                </th>
                                <th className="cart-table__column cart-table__column--quantity">
                                    Quantity
                                </th>
                                <th className="cart-table__column cart-table__column--total">
                                    Total
                                </th>
                                <th
                                    className="cart-table__column cart-table__column--remove"
                                    aria-label="Remove"
                                />
                            </tr>
                            </thead>
                            <tbody className="cart-table__body">{renderItems()}</tbody>
                        </table>

                        <div className="cart__actions">
                            <div class="container">
                                <div className="row">
                                    <div className="col-3">
                                        <h6>
                                            <label>{cart.items.length} ITEMS</label>
                                        </h6>
                                    </div>
                                    <div className="col-3">
                                        <h6>
                                            <label>TOTAL PRICE</label>
                                        </h6>
                                    </div>
                                    <div className="col-3 ">
                                        <h6>
                                            <CurrencyFormat
                                                value={cart.subtotal.toFixed(2)}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                renderText={(value) => (
                                                    <div>
                                                        {" "}
                                                        {userCurrency()} {"  "} {value}
                                                    </div>
                                                )}
                                            />
                                        </h6>
                                    </div>
                                    <div className="col-3">
                                        {MyownShipping.data === "I will arrange Delivery" && isLogin === false ? (
                                            <>
                                                {Myownaddress.map((address) => (
                                                    <div>
                                                        {address.is_active &&
                                                        localStorage.getItem("pushValue") === true ? (
                                                            <button
                                                                disabled={
                                                                    !paymentOption_token ||
                                                                    localStorage.getItem("pushValue") === false
                                                                }
                                                                onClick={() =>
                                                                    ConfirmOrder(address.secure_token)
                                                                }
                                                                type="submit"
                                                                className="btn-sm btn btn-primary "
                                                            >
                                                                CONFIRM
                                                            </button>
                                                        ) : (
                                                            <button
                                                                disabled={!paymentOption_token}
                                                                onClick={() =>
                                                                    ConfirmOrder(address.secure_token)
                                                                }
                                                                type="submit"
                                                                className="btn-sm btn btn-primary "
                                                            >
                                                                CONFIRM
                                                            </button>
                                                        )}
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                {isLogin ? (
                                                    shipping.shippingAddress.user_address.map((address) => (
                                                        <div>
                                                            {address.is_active &&
                                                            localStorage.getItem("pushValue") === true ? (
                                                                <button
                                                                    disabled={
                                                                        !paymentOption_token ||
                                                                        localStorage.getItem("pushValue") === false
                                                                    }
                                                                    onClick={() =>
                                                                        ConfirmOrder(address.secure_token)
                                                                    }
                                                                    type="submit"
                                                                    className="btn-sm btn btn-primary"
                                                                >
                                                                    CONFIRM
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    disabled={!paymentOption_token}
                                                                    onClick={() =>
                                                                        ConfirmOrder(address.secure_token)
                                                                    }
                                                                    type="submit"
                                                                    className="btn-sm btn btn-primary"
                                                                >
                                                                    CONFIRM
                                                                </button>
                                                            )}
                                                        </div>
                                                    ))
                                                ) : (
                                                    <button
                                                        disabled={!paymentOption_token}
                                                        onClick={() =>
                                                            GuestConfirmOrder()
                                                        }
                                                        type="submit"
                                                        className="btn-sm btn btn-primary"
                                                    >
                                                        CONFIRM
                                                    </button>
                                                )}
                                            </>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <button
                            className="btn btn-secondary"
                            onClick={() => handleBacks(activeStep)}
                        >
                            {" "}
                            <i className="fa fa-backward text-black"> </i> Previous
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    //   function render() {
    let content;
    content = renderCart();
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Confirm Order — ${theme.name}`}</title>
            </Helmet>
            {content}
        </React.Fragment>
    );
}

// }

const mapStateToProps = (state) => ({
    cart: state.cart,
    shipping: state,
    Myownaddress: state.Myownaddresss,
    MyownShipping: state.MyownShipping,
    isLogin: state.auth.isLogin

});

const mapDispatchToProps = {
    cartRemove,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCart);
