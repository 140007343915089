import {ADD_PAYMENT, FETCH_PAYMENT,EDIT_PAYMENT} from "./paymentActionTypes";

export default function paymentReducer(state = [], action) {
    switch (action.type) {
        case FETCH_PAYMENT:
            return action.payload
        case ADD_PAYMENT:
            return action.payload
        case EDIT_PAYMENT:
            return action.payload
        default:
            return state;
    }
}


