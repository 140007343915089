import React, {Component} from 'react';
import { MdStar, MdStarHalf } from 'react-icons/md'
import { RiReplyLine } from 'react-icons/ri'
import { FiThumbsUp, FiRefreshCw } from 'react-icons/fi'
import { FaRegSmile } from 'react-icons/fa'
import {Link} from "react-router-dom";
import commentimg1 from '../../../assets/images/img1.jpg';

class ListingDetailsComments extends Component {
    states = {
        comments: [
            {
                img: commentimg1,
                name: 'Adam Smith',
                date: 'Reviewed 2 Days ago',
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Curabitur non nulla sit amet nisl tempus',
                stars: [
                    <MdStar />,
                    <MdStar />,
                    <MdStar />,
                    <MdStar />,
                    <MdStarHalf />,
                ],
                replyBtn: 'Reply',
                replyBtnIcon: <RiReplyLine />,
                replyComments: [
                    {
                        img: commentimg1,
                        name: 'Julian Smith',
                        date: 'Reviewed 3 Days ago',
                        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Curabitur non nulla sit amet nisl tempus',
                        stars: [
                            <MdStar />,
                            <MdStar />,
                            <MdStar />,
                            <MdStar />,
                            <MdStarHalf />,
                        ],
                        replyBtn: 'Reply',
                        replyBtnIcon: <RiReplyLine />,
                    }
                ]
            },
            {
                img: commentimg1,
                name: 'Matt Derry',
                date: 'Reviewed 4 Days ago',
                content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Curabitur non nulla sit amet nisl tempus',
                stars: [
                    <MdStar />,
                    <MdStar />,
                    <MdStar />,
                    <MdStar />,
                    <MdStar />,
                ],
                replyBtn: 'Reply',
                replyBtnIcon: <RiReplyLine />,
                replyComments: []
            }
        ]
    }
    render() {
        return (
            <>
                <ul className="comments-list padding-top-40px">
                    <li>

                        {this.states.comments.map((item, i) => {
                            return (
                                <>
                                    <div className="comment" key={i}>
                                        <img className="avatar__img" alt="Comment" src={item.img} />
                                        <div className="comment-body">
                                            <div className="meta-data">
                                                <span className="comment__author">
                                                    {item.name}
                                                </span>
                                                <span className="comment__date">
                                                    {item.date}
                                                </span>
                                                <div className="rating-rating">
                                                    {item.stars.map((star, index) => {
                                                        return <span key={index} className="la la-star">{star}</span>
                                                    })}
                                                </div>
                                            </div>
                                            <p className="comment-content">
                                                {item.content}
                                            </p>
                                        </div>
                                    </div>

                                </>
                            )
                        })}
                    </li>
                </ul>
                <hr/>
            </>
        );
    }
}

export default ListingDetailsComments;