export default [
    {
        type: 'link',
        label: 'Home',
        url: '/',
    },
    {
        type: 'link',
        label: 'Shop',
        url: '/shop/all-products',
    },
    {
        type: 'link',
        label: 'Digital Products',
        url: '/digitalHome',
    },
    {
        type: 'link',
        label: 'Directory',
        url: '/directory',
        children: [
            {type: 'link', label: 'Listings', url: '/listing'},

        ],
    },
    {
        type: 'link',
        label: 'Services',
        url: '/services-home',
        children: [
            {type: 'link', label: 'Home', url: '/services-home'},
            {type: 'link', label: 'All service', url: '/services-product'},

        ],
    },
    {
        type: 'link',
        label: 'Wishlist',
        url: '/shop/wishlist',
    },
    {
      type:'link',
      label:'Agent',
      url:'/agent/login',
    },
    {
        type: 'link',
        label: 'Agent Account',
        url: '/account',
        children: [
            {type: 'link', label: 'Dashboard', url: '/agent/dashboard'},
            {type: 'link', label: 'Customers', url: '/agent/customers'},          
            {type: 'link', label: 'My Orders', url: '/agent/orders'},           
            {type: 'link', label: 'My Sales', url: '/agent/sales'},
            {type: 'link', label: 'My Commission', url: '/agent/commission'},
            {type: 'link', label: 'Payment Setting', url: '/agent/payment'},
            {type: 'link', label: 'Change Password', url: '/agent/password'},  
        ],
    }, 
    {
        type: 'link',
        label: 'Create Account',
        url: '/account/register',
    },
    {
        type: 'link',
        label: 'My Account',
        url: '/account',
        children: [
            {type: 'link', label: 'Dashboard', url: '/account/dashboard'},
            {type: 'link', label: 'My Orders', url: '/account/orders/5'},
            {type: 'link', label: 'My Quotes', url: '/account/quotation-list'},
            {type: 'link', label: 'Address Book', url: '/account/addresses'},
            {type: 'link', label: 'My Shipping', url: '/account/MyShipping'},
            {type: 'link', label: 'Booking', url: '/account/booking'},
            {type: 'link', label: 'Bookmark', url: '/account/bookmark'},
            {type: 'link', label: 'Change Password', url: '/account/password'},
        ],
    }, 
    // {
    //     type: 'link',
    //     label: 'Kago',
    //     url: 'https://kago.ezytrade.africa',
    // },
    // {
    //     type: 'link',
    //     label: 'Sell on Ezytrade',
    //     url: 'https://businesscentral.ezytrade.africa/login',
    // }

];
