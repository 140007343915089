import React from 'react';
import {IoMdStar, IoMdStarHalf} from 'react-icons/io'
import {FiHeart} from 'react-icons/fi'
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from "react-router-dom";

function Related(props) {
    const {data} = props
  const responsive = [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
    return (
        <div className="row mt-5">
            {
                data.length > 3 ?
                    <div className="col-lg-12">
                        <Slider className="places-carousel" dots={true} infinite={true} slidesToScroll={2}
                                arrows={false}
                                slidesToShow={4} centerMode={false} centerPadding="50px" autoplay={true}
                                responsive={responsive}>
                            {data.map((item, index) => {
                                return (
                                    // <div className="col-lg-3 mt-3 justify-content-start">
                                        <div className="card-item  bg-light" key={index}>
                                            <Link target="_blank" to={`/listing-details/${item.url_head}`}>
                                                <a href="" className="card-image-wrap">
                                                    <div className="card-image">
                                                        <img src={item.image.image_url} height="250" className="card__img" alt="Place"/>
                                                        {/*<span className="badge">promoted</span>*/}
                                                    </div>
                                                </a>
                                                <div className="card-content-wrap">
                                                    <div className="card-content">
                                                        <Link target="_blank" to={`/listing-details/${item.url_head}`}>
                                                        {/*<span*/}
                                                        {/*    className="badge text-black  text-capitalize"> {item.slug}</span>*/}
                                                            <h5 className=" text-capitalize text-muted"
                                                                style={{color: 'black'}}>
                                                                {item.listing_title.length > 26
                                                                    ? `${item.listing_title.substring(0, 26)}...`
                                                                    : item.listing_title}
                                                            </h5>
                                                            <span className="card-sub">
                                                            {item.listing_description.length > 90
                                                                ? `${item.listing_description.substring(0, 90)}...`
                                                                : item.listing_description}
                                                        </span>
                                                        </Link>
                                                        <a href="" className="author-img">
                                                            <img src={item.image.image_url} alt="author-img"/>
                                                        </a>
                                                    </div>
                                                    <div className="rating-row">
                                                        <div className="rating-rating">
                                                            {[<IoMdStar/>,
                                                                <IoMdStar/>,
                                                                <IoMdStar/>,
                                                                <IoMdStarHalf/>,
                                                                <IoMdStar className="last-star"/>,
                                                            ].map((rating, index) => {
                                                                return (
                                                                    <span key={index}>{rating}</span>
                                                                )
                                                            })}
                                                            <span className="rating-count">{item.ratingNum}</span>
                                                        </div>
                                                        <div className="listing-info">
                                                            <ul>
                                                                <li><span className="info__count"><FiHeart/></span> 115</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    // </div>

                                )
                            })}
                        </Slider>
                    </div>
                    :
                    <>
                        {data.map((item, index) => {
                            return (
                                <div className="col-lg-3 mt-3 justify-content-start">
                                    <div className="card-item  bg-light" key={index}>
                                        <Link target="_blank" to={`/listing-details/${item.url_head}`}>
                                            <a href="" className="card-image-wrap">
                                                <div className="card-image">
                                                    <img src={item.image.image_url} className="card__img" alt="Place"/>
                                                    {/*<span className="badge">promoted</span>*/}
                                                </div>
                                            </a>
                                            <div className="card-content-wrap">
                                                <div className="card-content">
                                                    <Link target="_blank" to={`/listing-details/${item.url_head}`}>
                                                        <span
                                                            className="badge text-black  text-capitalize"> {item.slug}</span>
                                                        <h5 className=" text-capitalize text-muted"
                                                            style={{color: 'black'}}>
                                                            {item.listing_title.length > 26
                                                                ? `${item.listing_title.substring(0, 26)}...`
                                                                : item.listing_title}
                                                        </h5>
                                                        <span className="card-sub">
                                                            {item.listing_description.length > 90
                                                                ? `${item.listing_description.substring(0, 90)}...`
                                                                : item.listing_description}
                                                        </span>
                                                    </Link>
                                                    <a href="" className="author-img">
                                                        <img src={item.image.image_url} alt="author-img"/>
                                                    </a>
                                                </div>
                                                <div className="rating-row">
                                                    <div className="rating-rating">
                                                        {[<IoMdStar/>,
                                                            <IoMdStar/>,
                                                            <IoMdStar/>,
                                                            <IoMdStarHalf/>,
                                                            <IoMdStar className="last-star"/>,
                                                        ].map((rating, index) => {
                                                            return (
                                                                <span key={index}>{rating}</span>
                                                            )
                                                        })}
                                                        <span className="rating-count">{item.ratingNum}</span>
                                                    </div>
                                                    <div className="listing-info">
                                                        <ul>
                                                            <li><span className="info__count"><FiHeart/></span> 115</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            )
                        })}
                    </>
            }
        </div>
    );
}

export default Related;
