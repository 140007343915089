import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import theme from "../../../data/theme";
import {connect, useDispatch} from "react-redux";
import axios from "axios";
import {useFormik} from "formik";
import * as yup from "yup";
import {AddListingData, FetchListing} from "../../../store/Listings/ListingActions";
import {useHistory} from "react-router-dom";
import {DropzoneArea} from "material-ui-dropzone";
import {toast} from "react-toastify";
import PaymentOptioncomponent from "../../shop/paymentOption";
import {Link} from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import Axios from "axios";
import {customertoken} from "../../../api/shop";
//creating the validation schema
const validationSchema = yup.object().shape({
    listing_title: yup
        .string()
        .required("This field is required"),
    listing_description: yup
        .string()
        .required("This field is required"),
    service_description: yup
        .string()
        .required("This field is required"),
    category_token: yup
        .string()
        .required("This field is required"),
    listing_subcategory_secure_token: yup
        .string()
        .required("This field is required"),
    listing_plan_secure_token: yup
        .string()
        .required("This field is required"),
    listing_plan_price_secure_token: yup
        .string()
        .required("This field is required"),
    address: yup
        .string()
        .required("This field is required"),
    zip_code: yup
        .string()
        .required("This field is required"),
    country_id: yup
        .string()
        .required("This field is required"),
    region_id: yup
        .string()
        .required("This field is required"),
    district_id: yup
        .string()
        .required("This field is required"),
    listing_services: yup
        .string()
        .required("This field is required"),
    phone: yup
        .string()
        .required("This field is required"),
    email: yup
        .string()
        .required("This field is required"),
    facebook: yup
        .string()
        .required("This field is required"),
    twitter: yup
        .string()
        .required("This field is required"),
    instagram: yup
        .string()
        .required("This field is required"),
    website: yup
        .string()
        .required("This field is required"),
    physical_address: yup
        .string()
        .required("This field is required"),
    url_head: yup
        .string()
        .required("This field is required"),
    holidays: "",
    allow_enquiry: "",
    allow_booking: "",
    // allow_prepaid: "",
    // allow_postpaid: "",
    video_url: yup
        .string()
        .required("This field is required"),
    slug: yup
        .string()
        .required("This field is required"),

});

function AddListing() {
    const dispatch = useDispatch()
    // const [checkedValues, setCheckedValues] = useState([]);
    const [arrayHolidayData, setHolidaysData] = useState([]);
    // console.log(checkedHolidays)
    const [enquiry, setEnquiry] = useState(false);
    const [prepaid, setPrepaid] = useState("");
    const [booking, setBooking] = useState(false);
    const [count, setCount] = useState(1)
    const [stepActive1, setActiveStep1] = useState("disabled");
    const [stepActive2, setActiveStep2] = useState("disabled");
    const [stepActive3, setActiveStep3] = useState("disabled");
    const [stepActive4, setActiveStep4] = useState("disabled");
    const [stepActive5, setActiveStep5] = useState("disabled");
    const [imageFiles, setImages] = useState([]);
    const [imageFile, setImage] = useState([]);
    const [imageId, setImageId] = useState([]);
    const [arrayData, setArray] = useState([]);
    const [ServiceId, setServiceId] = useState(null);
    const [schedule, setSchedule] = useState("");
     console.log("schedule", schedule)

    function handleImages(files) {
        setImages(files);
    }

    const history = useHistory()
    useEffect(() => {
        if (count === 2 || count >= 2) {
            setActiveStep2("active")
        } else {
            setActiveStep2("disabled")
        }
    })
    useEffect(() => {
        if (count === 3 || count >= 3) {
            setActiveStep3("active")
        } else {
            setActiveStep3("disabled")
        }
    })
    useEffect(() => {
        if (count === 4 || count >= 4) {
            setActiveStep4("active")
        } else {
            setActiveStep4("disabled")
        }
    })
    useEffect(() => {
        if (count === 5 || count >= 5) {
            setActiveStep5("active")
        } else {
            setActiveStep5("disabled")
        }
    })

    function NextSteps() {
        // console.log("gfdghfgfdgh")
        if (count > 0) {
            setTimeout(() => setCount(count + 1), 1000);
        }
    }

    function BackSteps() {
        if (count > 1 || count >= 4) {
            setTimeout(setCount(count - 1), 1000);
        }

    }

    const handleSelect = (NewValue) => {
        setArray(oldArray => [...oldArray, NewValue]);
        toast.success("Service selected ,Successfully");
        return arrayData
    }

    function handleRemove(id) {
        const newArray = arrayData.filter((item) => item.subcategory_service_id !== id);
        setArray(newArray)
        toast.success("Service removed ,Successfully");
    }

    const handleHolidays = (NewValue) => {
        setHolidaysData(oldArray => [...oldArray, NewValue]);
        toast.success("Holiday selected ,Successfully");
        return arrayHolidayData
    }

    function RemoveHoliday(id) {
        const newArray = arrayHolidayData.filter((item) => item.id !== id);
        setHolidaysData(newArray)
        toast.success("Holiday removed ,Successfully");
    }

    const formik = useFormik({
        initialValues: {
            listing_title: "",
            category_token: "",
            listing_subcategory_secure_token: "",
            listing_plan_secure_token: "",
            listing_plan_price_secure_token: "",
            email: "",
            phone: "",
            twitter: "",
            facebook: "",
            instagram: "",
            website: "",
            physical_address: "",
            zip_code: "",
            country_id: "",
            region_id: "",
            district_id: "",
            // listing_services: arrayData,
            monday_opening: "",
            monday_closing: "",
            tuesday_opening: "",
            tuesday_closing: "",
            wednesday_opening: "",
            wednesday_closing: "",
            thursday_opening: "",
            thursday_closing: "",
            friday_opening: "",
            friday_closing: "",
            saturday_opening: "",
            saturday_closing: "",
            sunday_opening: "",
            sunday_closing: "",
            allow_booking: booking,
            allow_enquiry: enquiry,
            // allow_postpaid: postpaid,
            video_url: "",
            slug: "",
            latitude: "",
            logitude: ""

        },
        validationSchema,
    });
    const [state, setState] = useState({
        country_id: "",
        region_id: "",
        district_id: "",
        category_token: "",
        // allow_booking: false,
        listing_subcategory_secure_token: "",
        listing_plan_secure_token: "",
        listing_plan_price_secure_token: "",
    });
    const [countries, setCountry] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const results = await axios
                .get(window.$name + "/api/adminstrator/get-countries")
                .then((response) => response.data);
            setCountry(results);
        };

        fetchData();
    }, []);

    const [regions, setRegions] = useState([]);
    const GetRegions = (e) => {
        const {id, value} = e.target;
        formik.values.country_id = e.target.value;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
        axios
            .get(window.$name + "/api/adminstrator/get-regions", {
                params: {
                    country_id: e.target.value,
                },
            })
            .then((res) => {
                setRegions(res.data);
            });
    };

    const getSchedule = (e) => {
        setSchedule(e.target.value)
    }
     
    const [district, setDistrict] = useState([]);
    const GetDistrict = (e) => {
        const {id, value} = e.target;
        formik.values.region_id = e.target.value;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
        axios
            .get(window.$name + "/api/adminstrator/get-districts", {
                params: {
                    region_id: e.target.value,
                },
            })
            .then((res) => {
                setDistrict(res.data);
            });
    };

    const [category, setCategory] = useState([]);
    useEffect(() => {
        const fetchCategory = async () => {
            const responseData = await axios
                .get(window.$name + "/api/directory/get/listings/categories")
                .then((response) => response.data.listing_categories);
            setCategory(responseData);
        };
        fetchCategory();
    }, []);

    const [subcategory, setSubCategory] = useState([]);
    const GetSub = (e) => {
        const {id, value} = e.target;
        formik.values.category_token = e.target.value;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
        axios
            .get(window.$name + `/api/directory/get/listings/subcategories/${e.target.value}`)
            .then((res) => {
                setSubCategory(res.data.listing_subcategories);
            });
    };

    const [service, setService] = useState([]);
    const GetService = (e) => {
        const {id, value} = e.target;
        formik.values.listing_subcategory_secure_token = e.target.value;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
        axios
            .get(window.$name + `/api/directory/get/subcategories/services/${e.target.value}`)
            .then((res) => {
                setService(res.data.subcategory_services);
            });
    };
    const Time = [
        {
            value: 1,
            label: 'none'
        },
        {
            value: 2,
            label: '1:00am'
        },
        {
            value: 2,
            label: '2:00am'
        },
        {
            value: 3,
            label: '3:00am'
        },
        {
            value: 4,
            label: '4:00am'
        },
        {
            value: 5,
            label: '5:00am'
        },
        {
            value: 6,
            label: '6:00am'
        },
        {
            value: 7,
            label: '7:00am'
        },
        {
            value: 8,
            label: '8:00am'
        },
        {
            value: 9,
            label: '9:00am'
        },
        {
            value: 10,
            label: '10:00am'
        },
        {
            value: 11,
            label: '11:00am'
        },
        {
            value: 12,
            label: '12:00pm'
        },
        {
            value: 13,
            label: '1:00pm'
        },
        {
            value: 14,
            label: '2:00pm'
        },
        {
            value: 15,
            label: '3:00pm'
        },
        {
            value: 16,
            label: '4:00pm'
        },
        {
            value: 17,
            label: '5:00pm'
        },
        {
            value: 18,
            label: '6:00pm'
        },
        {
            value: 19,
            label: '7:00pm'
        },
        {
            value: 20,
            label: '8:00pm'
        },
        {
            value: 21,
            label: '9:00pm'
        },
        {
            value: 22,
            label: '10:00pm'
        },
        {
            value: 23,
            label: '11:00pm'
        },
        {
            value: 24,
            label: '12:00am'
        },
    ]
    const holidays = [
        {
            id: 1,
            holiday_name: "christmas day",
            is_serving: true

        },
        {
            id: 3,
            holiday_name: 'Boxing Day',
            is_serving: true


        },
        {
            id: 4,
            holiday_name: 'Easter',
            is_serving: true


        },
        {
            id: 5,
            holiday_name: 'Nyerere Day',
            is_serving: true

        }
    ]
    const NewValue =
        {
            service_description: formik.values.service_description,
            subcategory_service_id: ServiceId
        }

    function CreateListing(value, enquiry, service, booking, history, imageFiles, publish, schedule) {
        const formData = new FormData();
        formData.append('listing_title', value.listing_title);
        formData.append('listing_description', value.listing_description);
        formData.append('listing_category_secure_token', value.category_token);
        formData.append('listing_subcategory_secure_token', value.listing_subcategory_secure_token);
        formData.append('listing_plan_secure_token', value.listing_plan_secure_token);
        formData.append('listing_plan_price_secure_token', value.listing_plan_price_secure_token);
        formData.append('physical_address', value.physical_address);
        formData.append('url_head', value.url_head);
        formData.append('email', value.email);
        formData.append('phone_number', value.phone);
        formData.append('website', value.website);
        formData.append('facebook_account', value.facebook);
        formData.append('instagram_account', value.instagram);
        formData.append('twitter_account', value.twitter);
        formData.append('zip_code', value.zip_code);
        formData.append('country_id', value.country_id);
        formData.append('region_id', value.region_id);
        formData.append('district_id', value.district);
        // formData.append('listing_services', JSON.stringify(arrayData));

        // arrayData.forEach(item => {
        //     formData.append('listing_services[]', JSON.stringify(item));
        // });
        formData.append('monday_opening', value.monday_opening);
        formData.append('monday_closing', value.monday_closing);
        formData.append('tuesday_opening', value.tuesday_opening);
        formData.append('tuesday_closing', value.tuesday_closing);
        formData.append('wednesday_opening', value.wednesday_opening);
        formData.append('wednesday_closing', value.wednesday_closing);
        formData.append('thursday_opening', value.thursday_opening);
        formData.append('thursday_closing', value.thursday_closing);
        formData.append('friday_opening', value.friday_opening);
        formData.append('friday_closing', value.friday_closing);
        formData.append('saturday_opening', value.saturday_opening);
        formData.append('saturday_closing', value.saturday_closing);
        formData.append('sunday_opening', value.sunday_opening);
        formData.append('sunday_closing', value.sunday_closing);
        formData.append('holidays', JSON.stringify(arrayHolidayData));
        // arrayHolidayData.forEach(holiday => {
        //     formData.append('holidays[]', JSON.stringify(holiday));
        // });
        formData.append('allow_booking', booking);
        formData.append('allow_enquiry', enquiry);
        formData.append('video_url', value.video_url);
        formData.append('slug', value.slug);
        formData.append('latitude', value.latitude);
        formData.append('longitude', value.longitude);
        formData.append('is_published', publish);
        formData.append('schedule', schedule);
        imageFiles.forEach(image => {
            formData.append('images[]', image);
        });

        toast.info(`Please wait, the upload is on process`);

        axios.post(window.$name + `/api/directory/consumer/post/listings/${customertoken()}`, formData)
            .then((response) => {
                if (response.status === 200) {
                    dispatch(FetchListing())
                    history.push("/account/directory")
                    toast.success(` "${response.data.message}"`)

                } else {
                    toast.error("something is wrong")
                }
            })
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`add listing — ${theme.name}`}</title>
            </Helmet>
            <div>
                <div className="step">
                    <section className="signup-step-container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-8">
                                <div className="wizard">
                                    <div className="wizard-inner">
                                        <div className="connecting-line"></div>
                                        <ul className="nav nav-tabs" role="tablist">
                                            <li role="presentation" className="active">
                                                <a href="#step1" data-toggle="tab" aria-controls="step1" role="tab"
                                                   aria-expanded="true"><span className="round-tab">1 </span> <i>
                                                    <h6>
                                                        Basic Information
                                                    </h6>
                                                </i>
                                                </a>
                                            </li>
                                            <li role="presentation" className={stepActive2}>
                                                <a href="#step2" data-toggle="tab" aria-controls="step2" role="tab"
                                                   aria-expanded="false"><span className="round-tab">2</span> <i>
                                                    <h6>
                                                        Plan and Services
                                                    </h6>
                                                </i>
                                                </a>
                                            </li>
                                            <li role="presentation" className={stepActive3}>
                                                <a href="#step3" data-toggle="tab" aria-controls="step3"
                                                   role="tab"><span
                                                    className="round-tab">3</span> <i><h6>Opening Hours</h6></i></a>
                                            </li>
                                            <li role="presentation" className={stepActive4}>
                                                <a href="#step4" data-toggle="tab" aria-controls="step4"
                                                   role="tab"><span
                                                    className="round-tab">4</span> <i><h6>Contancts</h6></i></a>
                                            </li>
                                            <li role="presentation" className={stepActive5}>
                                                <a href="#step5" data-toggle="tab" aria-controls="step5"
                                                   role="tab"><span
                                                    className="round-tab">5</span> <i><h6>Listing images</h6></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <form role="form" className="login-box">
                                        <div className="tab-content" id="main_form">
                                            {count === 1 ? (
                                                    <div className="tab-pane active" role="tabpanel">
                                                        {/*<h4 className="text-center">Company Details</h4>*/}
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="inputCity">Title</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="listing_title"
                                                                        value={formik.values.listing_title}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                    {formik.touched.listing_title && formik.errors.listing_title ? (
                                                                        <div
                                                                            className="text-danger">{formik.errors.listing_title}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="inputCity">Slug</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="slug"
                                                                        value={formik.values.slug}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                    {formik.touched.slug && formik.errors.slug ? (
                                                                        <div
                                                                            className="text-danger">{formik.errors.slug}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label htmlFor="inputCity">Video Link</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="video_url"
                                                                        value={formik.values.video_url}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                    {formik.touched.video_url && formik.errors.video_url ? (
                                                                        <div
                                                                            className="text-danger">{formik.errors.video_url}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label htmlFor="inputCity">Listing description</label>
                                                                    <textarea
                                                                        id="listing_description"
                                                                        name="listing_description"
                                                                        className="form-control"
                                                                        value={formik.values.listing_description}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                    {formik.touched.listing_description && formik.errors.listing_description ? (
                                                                        <div
                                                                            className="text-danger">{formik.errors.listing_description}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {formik.values.listing_title !== "" && formik.values.slug !== "" && formik.values.video_url !== "" && formik.values.listing_description !== "" ?
                                                            <ul className="list-inline pull-right">
                                                                <li>
                                                                    <button type="button"
                                                                            onClick={() => NextSteps()}
                                                                            className="default-btn next-step cursor-pointer">Continue
                                                                        to
                                                                        next step
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                            :
                                                            <div>

                                                            </div>
                                                        }
                                                    </div>
                                                )
                                                : count === 2 ? (
                                                        <div className="tab-pane active" role="tabpanel">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="inputState">Category</label>
                                                                        <select
                                                                            className="form-control"
                                                                            id="category_token"
                                                                            name="category_token"
                                                                            onChange={(e) => GetSub(e)}
                                                                            onBlur={formik.handleBlur}
                                                                            value={formik.values.category_token}
                                                                        >
                                                                            <option value="">select category.....</option>
                                                                            {category.map((index) => (
                                                                                <option key={index.secure_token}
                                                                                        value={index.secure_token}>
                                                                                    {index.category_name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        {formik.touched.category_token && formik.errors.category_token ? (
                                                                            <div
                                                                                className="text-danger">{formik.errors.category_token}</div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="inputCity">Subcategory</label>
                                                                        <select
                                                                            onChange={(e) => GetService(e)}
                                                                            className="form-control"
                                                                            id="listing_subcategory_secure_token"
                                                                            name="listing_subcategory_secure_token"
                                                                            onBlur={formik.handleBlur}
                                                                            value={formik.values.listing_subcategory_secure_token}
                                                                        >
                                                                            <option value="">Select a subcategory...
                                                                            </option>
                                                                            {subcategory.map((index) => (
                                                                                <option key={index.id}
                                                                                        value={index.secure_token}>
                                                                                    {index.subcategory_name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        {formik.touched.listing_subcategory_secure_token && formik.errors.listing_subcategory_secure_token ? (
                                                                            <div className="text-danger">
                                                                                {formik.errors.listing_subcategory_secure_token}
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                {/*<div className="col-md-12">*/}
                                                                {/*    <label htmlFor="inputCity">Services ( Select service by*/}
                                                                {/*        clicking it)</label>*/}
                                                                {/*    <br/>*/}
                                                                {/*    <div className="row mt-3">*/}
                                                                {/*        {service.map((index) => (*/}
                                                                {/*            <div className="col-md-3">*/}
                                                                {/*                <div className="form-group">*/}
                                                                {/*                    {*/}
                                                                {/*                        arrayData.some(code => code.subcategory_service_id === index.id) ?*/}
                                                                {/*                            <button*/}
                                                                {/*                                type="button"*/}
                                                                {/*                                onClick={() => handleRemove(index.id)}*/}
                                                                {/*                                className="btn btn-success text-center btn-sm rounded">*/}
                                                                {/*                                {index.service_name}*/}
                                                                {/*                            </button>*/}

                                                                {/*                            :*/}
                                                                {/*                            <button*/}
                                                                {/*                                type="button"*/}
                                                                {/*                                data-toggle="modal"*/}
                                                                {/*                                data-target=".bd-example-modal-lg"*/}
                                                                {/*                                onClick={() => setServiceId(index.id)}*/}
                                                                {/*                                className="btn btn-secondary text-center btn-sm rounded">*/}
                                                                {/*                                {index.service_name}*/}
                                                                {/*                            </button>*/}
                                                                {/*                    }*/}
                                                                {/*                </div>*/}
                                                                {/*            </div>*/}
                                                                {/*        ))*/}
                                                                {/*        }*/}
                                                                {/*    </div>*/}
                                                                {/*</div>*/}
                                                                {/* <div className="col-md-4">
                                                                    <div className="form-group">
                                                                        <input type="checkbox" id="allow_booking"
                                                                               name="allow_booking"
                                                                               disabled={enquiry === true}
                                                                               onChange={(e) => setBooking(e.target.checked)}
                                                                               value={formik.values.allow_booking}
                                                                               style={{height: '25px', width: '25px'}}/>
                                                                        {booking ?
                                                                            <h7 className="ml-2 text-success">
                                                                                BOOKING ALLOWED
                                                                            </h7>
                                                                            :
                                                                            <h7 className="ml-2">
                                                                                BOOKING NOT ALLOWED
                                                                            </h7>
                                                                        }
                                                                    </div>
                                                                </div> */}
                                                                {/*<div className="col-md-4">*/}
                                                                {/*    <div className="form-group">*/}
                                                                {/*        <input type="checkbox" id="allow_prepaid"*/}
                                                                {/*               name="allow_prepaid"*/}
                                                                {/*               disabled={booking === true}*/}
                                                                {/*               onChange={(e) => setEnquiry(e.target.checked)}*/}
                                                                {/*               value={formik.values.allow_enquiry}*/}
                                                                {/*               style={{height: '25px', width: '25px'}}/>*/}
                                                                {/*        {enquiry ?*/}
                                                                {/*            <h7 className="ml-2 text-success">*/}
                                                                {/*                ENQUIRY ALLOWED*/}
                                                                {/*            </h7>*/}
                                                                {/*            :*/}
                                                                {/*            <h7 className="ml-2">*/}
                                                                {/*                ENQUIRY NOT ALLOWED*/}
                                                                {/*            </h7>*/}
                                                                {/*        }*/}
                                                                {/*    </div>*/}

                                                                {/*</div>*/}
                                                            </div>
                                                            {/*modal for  */}
                                                            <div className="modal fade bd-example-modal-lg"
                                                                 tabIndex="-1" role="dialog"
                                                                 aria-labelledby="myLargeModalLabel"
                                                                 aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content container">
                                                                        <div className="modal-header text-center">
                                                                            {/*<h5 className="modal-title" id="exampleModalLabel">New message</h5>*/}
                                                                            <h5 className="modal-title text-center text-uppercase">
                                                                                Add Service Description
                                                                            </h5>
                                                                        </div>
                                                                        <div className="col-md-12">
                                                                            <div className="form-group">
                                                                                <label htmlFor="inputCity">Listing description</label>
                                                                                <textarea
                                                                                    id="service_description"
                                                                                    name="service_description"
                                                                                    className="form-control"
                                                                                    value={formik.values.service_description}
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                                {formik.touched.service_description && formik.errors.service_description ? (
                                                                                    <div
                                                                                        className="text-danger">{formik.errors.service_description}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                        <div className="cart__actions">
                                                                            <div className="container">
                                                                                <div className="row">
                                                                                    <button
                                                                                        type="submit"
                                                                                        data-dismiss="modal"
                                                                                        onClick={() => handleSelect(NewValue)}
                                                                                        className="btn-sm btn btn-primary "
                                                                                    >
                                                                                        Add Now
                                                                                    </button>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <br/>
                                                                    <br/>
                                                                </div>
                                                            </div>
                                                            <ul className="list-inline d-flex justify-content-between">
                                                                <li>
                                                                    <button type="button" onClick={() => BackSteps()}
                                                                            className="default-btn prev-step cursor-pointer">Back
                                                                    </button>
                                                                </li>

                                                                {formik.values.category_token !== "" && formik.values.listing_subcategory_secure_token !== "" ?
                                                                <li>
                                                                    <button type="button" onClick={() => NextSteps()}
                                                                            className="default-btn next-step cursor-pointer">Continue
                                                                        to
                                                                        next step
                                                                    </button>
                                                                </li>
                                                                    :
                                                                    <div></div>
                                                                }
                                                            </ul>
                                                        </div>
                                                    )
                                                    : count === 3 ? (
                                                            <div className="tab-pane active" role="tabpanel">
                                                                {/*<h4 className="text-center">Contact Details</h4>*/}
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <div className="form-group">
                                                                        <label htmlFor="inputCity">Business Hours</label>
                                                                                <select
                                                                                    className="form-control"
                                                                                    id="country"
                                                                                    name="country"
                                                                                    onChange={(e) => getSchedule(e)}
                                                                                    onBlur={formik.handleBlur}
                                                                                    value={formik.values.country}
                                                                                >
                                                                                    <option value="">Select Working Schedule</option>
                                                                                        <option key="1"value="selected">
                                                                                            Open for selected hours
                                                                                        </option>
                                                                                        <option key="2" value="always">
                                                                                           Always Open (Open 24 hours)
                                                                                        </option>
                                                                                        <option key="3" value="appointment">
                                                                                           By Appointment only
                                                                                        </option>
                                                                                </select>
                                                                        </div>
                                                                        </div>
                                                                        </div>


                                                                        {schedule == "selected" ? 
                                                                                     <div className="row">
                                                                                          <div className="col-md-2">
                                                                                         <div className="form-group">
                                                                                             <label htmlFor="inputState">Monday</label>
                                                                                         </div>
                                                                                     </div>
                                                                                     <div className="col-md-5">
                                                                                         <div className="form-group">
                                                                                             <label htmlFor="inputState">opening</label>
                                                                                             <select
                                                                                                className="form-control"
                                                                                                id="monday_opening"
                                                                                                 name="monday_opening"
                                                                                                onBlur={formik.handleBlur}
                                                                                                onChange={formik.handleChange}
                                                                                                value={formik.values.monday_opening}
                                                                                             >
                                                                                                <option value="">Select time...</option>
                                                                                                {Time.map((index) => (
                                                                                                    <option key={index.value}
                                                                                                            value={index.label}>
                                                                                                        {index.label}
                                                                                                    </option>
                                                                                                ))}
                                                                                             </select>
                                                                                          
                                                                                             {formik.touched.monday_opening && formik.errors.monday_opening ? (
                                                                                                 <div className="text-danger">
                                                                                                     {formik.errors.monday_opening}
                                                                                                 </div>
                                                                                             ) : null}
                                                                                         </div>
                                                                                     </div>
                                                                                     <div className="col-md-5">
                                                                                         <div className="form-group">
                                                                                             <label htmlFor="inputCity">closing</label>
                                                                                             <select
                                                                                                className="form-control"
                                                                                                id="monday_closing"
                                                                                                name="monday_closing"
                                                                                                onBlur={formik.handleBlur}
                                                                                                onChange={formik.handleChange}
                                                                                                value={formik.values.monday_closing}
                                                                                             >
                                                                                                <option value="">Select time...</option>
                                                                                                {Time.map((index) => (
                                                                                                    <option key={index.value}
                                                                                                            value={index.label}>
                                                                                                        {index.label}
                                                                                                    </option>
                                                                                                ))}
                                                                                             </select>
                                                                                        
                                                                                             {formik.touched.monday_closing && formik.errors.monday_closing ? (
                                                                                                 <div className="text-danger">
                                                                                                     {formik.errors.monday_closing}
                                                                                                 </div>
                                                                                             ) : null}
                                                                                         </div>
                                                                                     </div>
                                                                                     <div className="col-md-2">
                                                                                         <div className="form-group">
                                                                                             <label htmlFor="inputState">Tuesday</label>
                                                                                         </div>
                                                                                     </div>
                                                                                     <div className="col-md-5">
                                                                                         <div className="form-group">
                                                                                             <label htmlFor="inputCity">
                                                                                                 opening</label>
                                                                                             <select
                                                                                                className="form-control"
                                                                                                id="tuesday_opening"
                                                                                                name="tuesday_opening"
                                                                                                onBlur={formik.handleBlur}
                                                                                                onChange={formik.handleChange}
                                                                                                value={formik.values.tuesday_opening}
                                                                                             >
                                                                                                <option value="">Select time...</option>
                                                                                                {Time.map((index) => (
                                                                                                    <option key={index.value}
                                                                                                            value={index.label}>
                                                                                                        {index.label}
                                                                                                    </option>
                                                                                                ))}
                                                                                             </select>
                                                                                           
                                                                                             {formik.touched.tuesday_opening && formik.errors.tuesday_opening ? (
                                                                                                 <div className="text-danger">
                                                                                                     {formik.errors.tuesday_opening}
                                                                                                 </div>
                                                                                             ) : null}
                                                                                         </div>
                                                                                     </div>
                                                                                     <div className="col-md-5">
                                                                                         <div className="form-group">
                                                                                             <label htmlFor="inputCity">closing</label>
                                                                                             <select
                                                                                                className="form-control"
                                                                                                id="tuesday_closing"
                                                                                                name="tuesday_closing"
                                                                                                onBlur={formik.handleBlur}
                                                                                                onChange={formik.handleChange}
                                                                                                value={formik.values.tuesday_closing}
                                                                                             >
                                                                                                <option value="">Select time...</option>
                                                                                                {Time.map((index) => (
                                                                                                    <option key={index.value}
                                                                                                            value={index.label}>
                                                                                                        {index.label}
                                                                                                    </option>
                                                                                                ))}
                                                                                             </select>
                                                                                          
                 
                                                                                             {formik.touched.tuesday_closing && formik.errors.tuesday_closing ? (
                                                                                                 <div className="text-danger">
                                                                                                     {formik.errors.tuesday_closing}
                                                                                                 </div>
                                                                                             ) : null}
                                                                                         </div>
                                                                                     </div>
                                                                                     <div className="col-md-2">
                                                                                         <div className="form-group">
                                                                                             <label htmlFor="inputState">Wednesday</label>
                                                                                         </div>
                                                                                     </div>
                                                                                     <div className="col-md-5">
                                                                                         <div className="form-group">
                                                                                             <label htmlFor="inputCity">opening</label>
                                                                                             <select
                                                                                                className="form-control"
                                                                                                id="wednesday_opening"
                                                                                                name="wednesday_opening"
                                                                                                onBlur={formik.handleBlur}
                                                                                                onChange={formik.handleChange}
                                                                                                value={formik.values.wednesday_opening}
                                                                                             >
                                                                                                <option value="">Select time...</option>
                                                                                                {Time.map((index) => (
                                                                                                    <option key={index.value}
                                                                                                            value={index.label}>
                                                                                                        {index.label}
                                                                                                    </option>
                                                                                                ))}
                                                                                             </select>
                                                                                         
                                                                                             {formik.touched.wednesday_opening && formik.errors.wednesday_opening ? (
                                                                                                 <div className="text-danger">
                                                                                                     {formik.errors.wednesday_opening}
                                                                                                 </div>
                                                                                             ) : null}
                                                                                         </div>
                                                                                     </div>
                                                                                     <div className="col-md-5">
                                                                                         <div className="form-group">
                                                                                             <label htmlFor="inputCity">closing</label>
                                                                                             <select
                                                                                                className="form-control"
                                                                                                id="wednesday_closing"
                                                                                                name="wednesday_closing"
                                                                                                onBlur={formik.handleBlur}
                                                                                                onChange={formik.handleChange}
                                                                                                value={formik.values.wednesday_closing}
                                                                                             >
                                                                                                <option value="">Select time...</option>
                                                                                                {Time.map((index) => (
                                                                                                    <option key={index.value}
                                                                                                            value={index.label}>
                                                                                                        {index.label}
                                                                                                    </option>
                                                                                                ))}
                                                                                             </select>
                
                                                                                             {formik.touched.wednesday_closing && formik.errors.wednesday_closing ? (
                                                                                                 <div className="text-danger">
                                                                                                     {formik.errors.wednesday_closing}
                                                                                                 </div>
                                                                                             ) : null}
                                                                                         </div>
                                                                                     </div>
                                                                                     <div className="col-md-2">
                                                                                         <div className="form-group">
                                                                                             <label htmlFor="inputState">Thursday</label>
                                                                                         </div>
                                                                                     </div>
                                                                                     <div className="col-md-5">
                                                                                         <div className="form-group">
                                                                                             <label htmlFor="inputCity">opening</label>
                                                                                             <select
                                                                                                className="form-control"
                                                                                                id="thursday_opening"
                                                                                                name="thursday_opening"
                                                                                                onBlur={formik.handleBlur}
                                                                                                onChange={formik.handleChange}
                                                                                                value={formik.values.thursday_opening}
                                                                                             >
                                                                                                <option value="">Select time...</option>
                                                                                                {Time.map((index) => (
                                                                                                    <option key={index.value}
                                                                                                            value={index.label}>
                                                                                                        {index.label}
                                                                                                    </option>
                                                                                                ))}
                                                                                             </select>
                                                                                          
                                                                                             {formik.touched.thursday_opening && formik.errors.thursday_opening ? (
                                                                                                 <div className="text-danger">
                                                                                                     {formik.errors.thursday_opening}
                                                                                                 </div>
                                                                                             ) : null}
                                                                                         </div>
                                                                                     </div>
                                                                                     <div className="col-md-5">
                                                                                         <div className="form-group">
                                                                                             <label htmlFor="inputCity">closing</label>
                                                                                             <select
                                                                                                className="form-control"
                                                                                                id="thursday_closing"
                                                                                                name="thursday_closing"
                                                                                                onBlur={formik.handleBlur}
                                                                                                onChange={formik.handleChange}
                                                                                                value={formik.values.thursday_closing}
                                                                                             >
                                                                                                <option value="">Select time...</option>
                                                                                                {Time.map((index) => (
                                                                                                    <option key={index.value}
                                                                                                            value={index.label}>
                                                                                                        {index.label}
                                                                                                    </option>
                                                                                                ))}
                                                                                             </select>
                                                                        
                                                                                             {formik.touched.thursday_closing && formik.errors.thursday_closing ? (
                                                                                                 <div className="text-danger">
                                                                                                     {formik.errors.thursday_closing}
                                                                                                 </div>
                                                                                             ) : null}
                                                                                         </div>
                                                                                     </div>
                                                                                     <div className="col-md-2">
                                                                                         <div className="form-group">
                                                                                             <label htmlFor="inputState">Friday</label>
                                                                                         </div>
                                                                                     </div>
                                                                                     <div className="col-md-5">
                                                                                         <div className="form-group">
                                                                                             <label htmlFor="inputCity">opening</label>
                                                                                             <select
                                                                                                className="form-control"
                                                                                                id="friday_opening"
                                                                                                name="friday_opening"
                                                                                                onBlur={formik.handleBlur}
                                                                                                onChange={formik.handleChange}
                                                                                                value={formik.values.friday_opening}
                                                                                             >
                                                                                                <option value="">Select time...</option>
                                                                                                {Time.map((index) => (
                                                                                                    <option key={index.value}
                                                                                                            value={index.label}>
                                                                                                        {index.label}
                                                                                                    </option>
                                                                                                ))}
                                                                                             </select>
                                                                                             {formik.touched.friday_opening && formik.errors.friday_opening ? (
                                                                                                 <div className="text-danger">
                                                                                                     {formik.errors.friday_opening}
                                                                                                 </div>
                                                                                             ) : null}
                                                                                         </div>
                                                                                     </div>
                                                                                     <div className="col-md-5">
                                                                                         <div className="form-group">
                                                                                             <label htmlFor="inputCity">closing</label>
                                                                                             <select
                                                                                                className="form-control"
                                                                                                id="friday_closing"
                                                                                                name="friday_closing"
                                                                                                onBlur={formik.handleBlur}
                                                                                                onChange={formik.handleChange}
                                                                                                value={formik.values.friday_closing}
                                                                                             >
                                                                                                <option value="">Select time...</option>
                                                                                                {Time.map((index) => (
                                                                                                    <option key={index.value}
                                                                                                            value={index.label}>
                                                                                                        {index.label}
                                                                                                    </option>
                                                                                                ))}
                                                                                             </select>
                                                                                             {formik.touched.friday_closing && formik.errors.friday_closing ? (
                                                                                                 <div className="text-danger">
                                                                                                     {formik.errors.friday_closing}
                                                                                                 </div>
                                                                                             ) : null}
                                                                                         </div>
                                                                                     </div>
                                                                                     <div className="col-md-2">
                                                                                         <div className="form-group">
                                                                                             <label htmlFor="inputState">Saturday</label>
                                                                                         </div>
                                                                                     </div>
                                                                                     <div className="col-md-5">
                                                                                         <div className="form-group">
                                                                                             <label htmlFor="inputCity">opening</label>
                                                                                             <select
                                                                                                className="form-control"
                                                                                                id="saturday_opening"
                                                                                                name="saturday_opening"
                                                                                                onBlur={formik.handleBlur}
                                                                                                onChange={formik.handleChange}
                                                                                                value={formik.values.saturday_opening}
                                                                                             >
                                                                                                <option value="">Select time...</option>
                                                                                                {Time.map((index) => (
                                                                                                    <option key={index.value}
                                                                                                            value={index.label}>
                                                                                                        {index.label}
                                                                                                    </option>
                                                                                                ))}
                                                                                             </select>
                                                                                             {formik.touched.saturday_opening && formik.errors.saturday_opening ? (
                                                                                                 <div className="text-danger">
                                                                                                     {formik.errors.saturday_opening}
                                                                                                 </div>
                                                                                             ) : null}
                                                                                         </div>
                                                                                     </div>
                                                                                     <div className="col-md-5">
                                                                                         <div className="form-group">
                                                                                             <label htmlFor="inputCity">closing</label>
                                                                                             <select
                                                                                                className="form-control"
                                                                                                id="saturday_closing"
                                                                                                name="saturday_closing"
                                                                                                onBlur={formik.handleBlur}
                                                                                                onChange={formik.handleChange}
                                                                                                value={formik.values.saturday_closing}
                                                                                             >
                                                                                                <option value="">Select time...</option>
                                                                                                {Time.map((index) => (
                                                                                                    <option key={index.value}
                                                                                                            value={index.label}>
                                                                                                        {index.label}
                                                                                                    </option>
                                                                                                ))}
                                                                                             </select>
                                                                                             {formik.touched.saturday_closing && formik.errors.saturday_closing ? (
                                                                                                 <div className="text-danger">
                                                                                                     {formik.errors.saturday_closing}
                                                                                                 </div>
                                                                                             ) : null}
                                                                                         </div>
                                                                                     </div>
                                                                                     <div className="col-md-2">
                                                                                         <div className="form-group">
                                                                                             <label htmlFor="inputState">Sunday</label>
                                                                                         </div>
                                                                                     </div>
                                                                                     <div className="col-md-5">
                                                                                         <div className="form-group">
                                                                                             <label htmlFor="inputCity">opening</label>
                                                                                             <select
                                                                                                 className="form-control"
                                                                                                 id="sunday_opening"
                                                                                                 name="sunday_opening"
                                                                                                 onBlur={formik.handleBlur}
                                                                                                 onChange={formik.handleChange}
                                                                                                value={formik.values.sunday_opening}
                                                                                             >
                                                                                                <option value="">Select time...</option>
                                                                                                {Time.map((index) => (
                                                                                                     <option key={index.value}
                                                                                                             value={index.label}>
                                                                                                         {index.label}
                                                                                                  </option>
                                                                                                ))}
                                                                                             </select>
                                                                                             {formik.touched.sunday_opening && formik.errors.sunday_opening ? (
                                                                                                 <div className="text-danger">
                                                                                                     {formik.errors.sunday_opening}
                                                                                                 </div>
                                                                                             ) : null}
                                                                                         </div>
                                                                                     </div>
                                                                                     <div className="col-md-5">
                                                                                         <div className="form-group">
                                                                                             <label htmlFor="inputCity">closing</label>
                                                                                             <select
                                                                                                className="form-control"
                                                                                                id="sunday_closing"
                                                                                                name="sunday_closing"
                                                                                                onBlur={formik.handleBlur}
                                                                                                onChange={formik.handleChange}
                                                                                                value={formik.values.sunday_closing}
                                                                                             >
                                                                                                <option value="">Select time...</option>
                                                                                                {Time.map((index) => (
                                                                                                    <option key={index.value}
                                                                                                            value={index.label}>
                                                                                                        {index.label}
                                                                                                    </option>
                                                                                                ))}
                                                                                             </select>
                                                                                        
                                                                                             {formik.touched.sunday_closing && formik.errors.sunday_closing ? (
                                                                                                 <div className="text-danger">
                                                                                                     {formik.errors.sunday_closing}
                                                                                                 </div>
                                                                                             ) : null}
                                                                                         </div>
                                                                                     </div>
                 
                                                                                 </div>
                                                                                    : schedule == "always" ? 
                                                                                    <div className="row">
                                                                                         <div className="col-md-12">
                                                                                        Your schedule is set for 24 hours.
                                                                                        </div>
                                                                                    </div>
                                                                                    : schedule == "appointment" ? 
                                                                                    <div className="row">
                                                                                        <div className="col-md-12">
                                                                                        Your schedule is set appointment only
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                    <div className="row">
                                                                                    <div className="col-md-12">
                                                                                        Select schedule above to set your business hours
                                                                                    </div>
                                                                                    </div>
                                                                                }

                                                                {/* <p className="text-uppercase font-weight-bold">
                                                                   Are you working on holidays ?,If Yes click the button below.
                                                                </p>
                                                                <div className="row mt-5">
                                                                    {holidays.map((index) => (
                                                                        <div className="col-md-3">
                                                                            {arrayHolidayData.some(code => code.id === index.id) ?
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => RemoveHoliday(index.id)}
                                                                                    className="btn btn-success text-center btn-sm rounded">
                                                                                    {index.holiday_name}
                                                                                </button>
                                                                                :
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => handleHolidays(index)}
                                                                                    className="btn btn-secondary text-center btn-sm rounded">
                                                                                    {index.holiday_name}
                                                                                </button>
                                                                            }
                                                                        </div>
                                                                    ))
                                                                    }
                                                                </div> */}
                                                                <ul className="list-inline d-flex justify-content-between">
                                                                    <li>
                                                                        <button type="button" onClick={() => BackSteps()}
                                                                                className="default-btn prev-step cursor-pointer">Back
                                                                        </button>
                                                                    </li>

                                                                    <li>
                                                                        <button type="button" onClick={() => NextSteps()}
                                                                                className="default-btn next-step cursor-pointer">
                                                                            Continue
                                                                            to
                                                                            next step
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                        )
                                                        : count === 4 ? (
                                                                <div className="tab-pane active" role="tabpanel">
                                                                    <div className="row">
                                                                        <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor="inputCity">Email</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="email"
                                                                                    value={formik.values.email}
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                                {formik.touched.email && formik.errors.email ? (
                                                                                    <div
                                                                                        className="text-danger">{formik.errors.email}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor="inputCity">Phone</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="phone"
                                                                                    value={formik.values.phone}
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                                {formik.touched.phone && formik.errors.phone ? (
                                                                                    <div
                                                                                        className="text-danger">{formik.errors.phone}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor="inputCity">Website
                                                                                    link</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="website"
                                                                                    value={formik.values.website}
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                                {formik.touched.website && formik.errors.website ? (
                                                                                    <div
                                                                                        className="text-danger">{formik.errors.website}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor="inputCity">Facebook
                                                                                    link</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="facebook"
                                                                                    value={formik.values.facebook}
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                                {formik.touched.facebook && formik.errors.facebook ? (
                                                                                    <div
                                                                                        className="text-danger">{formik.errors.facebook}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor="inputCity">Instagram
                                                                                    link</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="instagram"
                                                                                    value={formik.values.instagram}
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                                {formik.touched.instagram && formik.errors.instagram ? (
                                                                                    <div
                                                                                        className="text-danger">{formik.instagram}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor="inputCity">Twitter
                                                                                    link</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="twitter"
                                                                                    value={formik.values.twitter}
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                                {formik.touched.twitter && formik.errors.twitter ? (
                                                                                    <div
                                                                                        className="text-danger">{formik.errors.twitter}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor="inputCity">Custom Listing Page Name</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="url_head"
                                                                                    value={formik.values.url_head}
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                                {formik.touched.url_head && formik.errors.url_head ? (
                                                                                    <div
                                                                                        className="text-danger">{formik.errors.url_head}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor="inputCity">Zip code</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="zip_code"
                                                                                    value={formik.values.zip_code}
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                                {formik.touched.zip_code && formik.errors.zip_code ? (
                                                                                    <div
                                                                                        className="text-danger">{formik.errors.zip_code}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor="inputCity">Physical
                                                                                    Address</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="physical_address"
                                                                                    value={formik.values.physical_address}
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                                {formik.touched.physical_address && formik.errors.physical_address ? (
                                                                                    <div
                                                                                        className="text-danger">{formik.physical_address}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor="inputCity">Country</label>
                                                                                <select
                                                                                    className="form-control"
                                                                                    id="country"
                                                                                    name="country"
                                                                                    onChange={(e) => GetRegions(e)}
                                                                                    onBlur={formik.handleBlur}
                                                                                    value={formik.values.country}
                                                                                >
                                                                                    <option value="">please select
                                                                                        country.....
                                                                                    </option>
                                                                                    {countries.map((country) => (
                                                                                        <option key={country.id}
                                                                                                value={country.id}>
                                                                                            {country.country}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                                {formik.touched.country && formik.errors.country ? (
                                                                                    <div
                                                                                        className="text-danger">{formik.errors.country}</div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor="inputCity">Region</label>
                                                                                <select
                                                                                    onChange={(e) => GetDistrict(e)}
                                                                                    id="region"
                                                                                    className="form-control"
                                                                                    name="region"
                                                                                    onBlur={formik.handleBlur}
                                                                                    value={formik.values.region}
                                                                                >
                                                                                    <option value="">Select a Regions...
                                                                                    </option>
                                                                                    {regions.map((reg) => (
                                                                                        <option key={reg.id} value={reg.id}>
                                                                                            {reg.region}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                                {formik.touched.region && formik.errors.region ? (
                                                                                    <div className="text-danger">
                                                                                        {formik.errors.region}
                                                                                    </div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-4">
                                                                            <div className="form-group">
                                                                                <label htmlFor="inputCity">District</label>
                                                                                <select
                                                                                    onChange={formik.handleChange}
                                                                                    id="district"
                                                                                    className="form-control"
                                                                                    name="district"
                                                                                    onBlur={formik.handleBlur}
                                                                                    value={formik.values.district}
                                                                                >
                                                                                    <option value="">Select a district...
                                                                                    </option>
                                                                                    {district.map((dist) => (
                                                                                        <option key={dist.id}
                                                                                                value={dist.id}>
                                                                                            {dist.district}
                                                                                        </option>
                                                                                    ))}
                                                                                </select>
                                                                                {formik.touched.district && formik.errors.district ? (
                                                                                    <div className="text-danger">
                                                                                        {formik.errors.district}
                                                                                    </div>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor="inputCity">Latitude</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="latitude"
                                                                                    value={formik.values.latitude}
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-6">
                                                                            <div className="form-group">
                                                                                <label htmlFor="inputCity">Longitude</label>
                                                                                <input
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    id="longitude"
                                                                                    value={formik.values.longitude}
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                />
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <ul className="list-inline  d-flex justify-content-between">
                                                                        <li>
                                                                            <button type="button"
                                                                                    onClick={() => BackSteps()}
                                                                                    className="default-btn prev-step cursor-pointer">
                                                                                Back
                                                                            </button>
                                                                        </li>
                                                                        {formik.values.email !== "" && formik.values.phone !== "" && formik.values.url_head && formik.values.zip_code !== "" && formik.values.physical_address !== "" && formik.values.country !== "" && formik.values.region !== "" && formik.values.district !== "" ?
                                                                            <li>
                                                                            <button type="button"
                                                                                    onClick={() => NextSteps()}
                                                                                    className="default-btn next-step cursor-pointer">
                                                                                Continue
                                                                                to
                                                                                next step
                                                                            </button>
                                                                        </li>
                                                                            :
                                                                            <div></div>
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            )
                                                            : count === 5 ? (
                                                                    <div className="tab-pane active" role="tabpanel">
                                                                        {/*<h4 className="text-center">Location Details</h4>*/}
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <DropzoneArea
                                                                                    dropzoneText="Drag and Drop  Listing Images"
                                                                                    acceptedFiles={['image/*']}
                                                                                    filesLimit={6}
                                                                                    showAlerts={false}
                                                                                    onChange={files => handleImages(files)}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <ul className="list-inline  d-flex justify-content-between">
                                                                            <li>
                                                                                <button type="button"
                                                                                        onClick={() => BackSteps()}
                                                                                        className="default-btn prev-step cursor-pointer">
                                                                                    Back
                                                                                </button>
                                                                            </li>
                                                                            <li>
                                                                                <div className="justify-content-between">
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() => CreateListing(formik.values, enquiry, arrayData, booking, history, imageFiles, false,schedule)}
                                                                                        className="default-btn next-step cursor-pointer">
                                                                                        Upload
                                                                                    </button>
                                                                                    {" "} OR{" "}
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() => CreateListing(formik.values, enquiry, arrayData, booking, history, imageFiles, true,schedule)}
                                                                                        className="default-btn next-step cursor-pointer">
                                                                                        Upload and Publish
                                                                                    </button>
                                                                                </div>

                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                )
                                                                :
                                                                <div className="clearfix">Unknown page</div>
                                            }

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddListing)
