// react
import React, {useEffect, useMemo, useState} from "react";
import axios from "axios";
// third-party
import {Helmet} from "react-helmet-async";
import theme from "../../data/theme";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {customertoken} from "../../api/shop";
import {Link} from "react-router-dom";
import {Pagination,Search} from "../DataTable";


export default function AccountPageOrderDetails() {
  
  
    const user_token = customertoken();
    useEffect(() => {
        const getData = () => {
            axios.post(window.$name + "/api/order/get-order-info",
                {
                    user_token: user_token,
                }
            )
                .then(response => response.data)
                .then(json => {
                    setOrders(json);
                });
        };
        getData();
    }, []);

    //order area
    const [orders, setOrders] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const ITEMS_PER_PAGE = 10;
    const orderData = useMemo(() => {
        let computedOrders = orders;
        if (search) {
            computedOrders =computedOrders.filter(
                order =>
                    order.order_number.toLowerCase().includes(search.toLowerCase()) ||
                    order.order_status.toLowerCase().includes(search.toLowerCase())||
                    order.total_vat.toLowerCase().includes(search.toLowerCase())||
                    order.total_amount.toLowerCase().includes(search.toLowerCase())
            );
        }
        setTotalItems(computedOrders.length);
        //Current Page slice
        return computedOrders.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [orders, currentPage, search]);

    //pending order start here
    const ordersListpending = orderData.map((order) => (
        <>
            {order.order_status === "pending" ? (
                <tr key={order.id}>
                    <td>{order.order_number}</td>
                    <td className="text-danger">Pending</td>
                    <td>{order.total_vat}</td>
                    <td>{order.total_amount}</td>
                    <td>{order.created_at}</td>
                    <td>
                        <Link to={{pathname: '/account/vieworder',state: [order]}}>View More</Link>
                    </td>
                </tr>
            ) : (
                <tr classsName="text-center">
                    <b classsName="text-center"></b>
                </tr>
            )}</>
    ));
    //paid order start here
    const ordersListPaid = orderData.map((order) => (
        <>
            {order.order_status === "Paid" ? (
                <tr key={order.id}>
                    <td>{order.order_number}</td>
                    <td className="text-success">Paid</td>
                    <td>{order.total_vat}</td>
                    <td>{order.total_amount}</td>
                    <td>{order.created_at}</td>
                    <td>
                        <Link to={{pathname: '/account/vieworder',state: [order]}}>View More</Link>
                    </td>
                </tr>
            ) : (
                <tr classsName="text-center">
                    <b classsName="text-center"></b>
                </tr>
            )}</>
    ));
    //shipped order start here
    const ordersListShipped = orderData.map((order) => (
        <>
            {order.order_status === "Shipped" ? (
                <tr key={order.id}>
                    <td>{order.order_number}</td>
                    <td className="text-warning">Shipped</td>
                    <td>{order.total_vat}</td>
                    <td>{order.total_amount}</td>
                    <td>{order.created_at}</td>
                    <td>
                        <Link to={{pathname: '/account/vieworder',state: [order]}}>View More</Link>
                    </td>
                </tr>
            ) : (
                <tr classsName="text-center">
                    <b classsName="text-center"></b>
                </tr>
            )}</>
    ));
    //delivered order start here
    const ordersListDelivered = orderData.map((order) => (
        <>
            {order.order_status === "Delivered" ? (
                <tr key={order.id}>
                    <td>{order.order_number}</td>
                    <td className="text-primary">Delivered</td>
                    <td>{order.total_vat}</td>
                    <td>{order.total_amount}</td>
                    <td>{order.created_at}</td>
                    <td>
                        <Link to={{pathname: '/account/vieworder',state: [order]}}>View More</Link>
                    </td>
                </tr>
            ) : (
                <tr classsName="text-center">
                    <b classsName="text-center"></b>
                </tr>
            )}
        </>
    ));
    const ordersListpartial= orderData.map((order) => (
        <>
            {order.order_status === "Partial Paid" ? (
                <tr key={order.id}>
                    <td>{order.order_number}</td>
                    <td className="text-primary">Partial Paid</td>
                    <td>{order.total_vat}</td>
                    <td>{order.total_amount}</td>
                    <td className="text-success">{order.amount_paid}</td>
                    <td className="text-danger">{order.total_amount-order.amount_paid}</td>
                    <td>{order.created_at}</td>
                    <td>
                        <Link to={{pathname: '/account/vieworder',state: [order]}}>View More</Link>
                    </td>
                </tr>
            ) : (
                <tr classsName="text-center">
                    <b classsName="text-center"></b>
                </tr>
            )}
        </>
    ));
    
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Order Details — ${theme.name}`}</title>
            </Helmet>

            <div className="card">
                <Tabs>
                    <TabList>
                        <Tab>
                            <span className="text-danger">PENDIND ORDERS</span>
                        </Tab>
                        <Tab>
                            <span className="text-success">PAID ORDERS</span>
                        </Tab>
                        <Tab>
                            <span className="text-primary">PARTIAL PAID ORDERS</span>
                        </Tab>
                        <Tab>
                            <span className="text-warning">SHIPPED ORDERS</span>
                        </Tab>
                        <Tab>
                            <span className="text-primary">DELIVERED ORDERS</span>
                        </Tab>

                    </TabList>

                    <TabPanel>
                        <div className="card-header">
                            <div className="d-flex align-items-end flex-column">
                                <div className="mt-auto p-2">
                                    <Search
                                        onSearch={value => {
                                            setSearch(value);
                                            setCurrentPage(1);
                                        }}
                                    /></div>
                            </div>
                        </div>
                        <div className="card-divider"/>
                        <div className="card-table">
                            <div className="table-responsive-sm">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Order No</th>
                                        <th>Order Status</th>
                                        <th>Total Vat</th>
                                        <th>Total Amount</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>{ordersListpending}</tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-divider"/>
                        <div className="card-footer">
                            <div className="d-flex align-items-center flex-column">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="card-header">
                            <div className="d-flex align-items-end flex-column">
                                <div className="mt-auto p-2">
                                    <Search
                                        onSearch={value => {
                                            setSearch(value);
                                            setCurrentPage(1);
                                        }}
                                    /></div>
                            </div>
                        </div>
                        <div className="card-divider"/>
                        <div className="card-table">
                            <div className="table-responsive-sm">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Order No</th>
                                        <th>Order Status</th>
                                        <th>Total Vat</th>
                                        <th>Total Amount</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>{ordersListPaid}</tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-divider"/>
                        <div className="card-footer">
                            <div className="d-flex align-items-center flex-column">
                                <Pagination
                                    total={totalItems}
                                    itemsPerPage={ITEMS_PER_PAGE}
                                    currentPage={currentPage}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>

                        <div className="card-header">
                            <div className="d-flex align-items-end flex-column">
                                <div className="mt-auto p-2">
                                    <Search
                                        onSearch={value => {
                                            setSearch(value);
                                            setCurrentPage(1);
                                        }}
                                    /></div>
                            </div>
                        </div>
                        <div className="card-divider"/>
                        <div className="card-table">
                            <div className="table-responsive-sm">
                                <table>
                                <thead>
                                    <tr>
                                        <th>Order No</th>
                                        <th>Order Status</th>
                                        <th>Total Vat</th>
                                        <th>Total Amount</th>
                                        <th>Amount Paid</th>
                                        <th>Not Paid</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>{ordersListpartial}</tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-divider"/>
                        <div className="card-footer">
                            <div className="d-flex align-items-center flex-column">
                                <Pagination
                                    total={totalItems}
                                    itemsPerPage={ITEMS_PER_PAGE}
                                    currentPage={currentPage}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="card-header">
                            <div className="d-flex align-items-end flex-column">
                                <div className="mt-auto p-2">
                                    <Search
                                        onSearch={value => {
                                            setSearch(value);
                                            setCurrentPage(1);
                                        }}
                                    /></div>
                            </div>
                        </div>
                        <div className="card-divider"/>
                        <div className="card-table">
                            <div className="table-responsive-sm">
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Order No</th>
                                        <th>Order Status</th>
                                        <th>Total Vat</th>
                                        <th>Total Amount</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>{ordersListDelivered}</tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-divider"/>
                        <div className="card-footer">
                            <div className="d-flex align-items-center flex-column">
                                <Pagination
                                    total={totalItems}
                                    itemsPerPage={ITEMS_PER_PAGE}
                                    currentPage={currentPage}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="card-header">
                            <div className="d-flex align-items-end flex-column">
                                <div className="mt-auto p-2">
                                    <Search
                                        onSearch={value => {
                                            setSearch(value);
                                            setCurrentPage(1);
                                        }}
                                    /></div>
                            </div>
                        </div>
                        <div className="card-divider"/>
                        <div className="card-table">
                            <div className="table-responsive-sm">
                                <table>                                   
                                    <thead>
                                    <tr>
                                        <th>Order No</th>
                                        <th>Order Status</th>
                                        <th>Total Vat</th>
                                        <th>Total Amount</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>{ordersListShipped}</tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-divider"/>
                        <div className="card-footer">
                            <div className="d-flex align-items-center flex-column">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                            </div>
                        </div>
                    </TabPanel>
                  
                </Tabs>
            </div>
        </React.Fragment>
    );
}
