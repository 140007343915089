// react
import React, { Component } from "react";
// third-party
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import { myownShippingOpt } from "../../store/ShippingOpt";
// data stubs
import theme from "../../data/theme";
import { handleNexts } from "./checkout";
import { Row } from "reactstrap";
import { userCurrency } from "../../api/shop";
class BuynowProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quantities: [],
            shippingOption: [],
            deliveryoption: false,
            showing: true,
        };
    }

    toggle(id) {
        this.setState({
            deliveryoption: true,
        });
    }

    componentDidMount() {
        fetch(window.$name + "/api/adminstrator/get-customer-shipping")
            .then((response) => response.json())
            .then((datalist) => this.setState({ shippingOption: datalist }));
    }

    getItemQuantity(item) {
        const { quantities } = this.state;
        const quantity = quantities.find((x) => x.itemId === item.id);
        return quantity ? quantity.value : item.quantity;
    }

    renderItems() {
        const { Rates } = this.props
        let buynow = JSON.parse(localStorage.getItem("BuynowData"));
        // console.log(buynow)
        let Amount_total = buynow[0].total_amount * buynow[0].quantity;
        return (
            <tr key={buynow[0].id} className="cart-table__row">
                <td className="cart-table__column cart-table__column--image">
                    <div className="product-image">
                        <Link
                            className="product-image__body"
                        >
                            <img
                                className="product-image__img"
                                src={buynow[0].product_images.productImage_name}
                                alt=""
                            />
                        </Link>
                    </div>
                </td>
                <td className="cart-table__column cart-table__column--product">
                    <Link className="cart-table__product-name">
                        {buynow[0].product.product_name}
                    </Link>
                    {/* {options} */}
                </td>
                <td className="cart-table__column cart-table__column--product">
                    {buynow[0].preference_color !== null && buynow[0].preference_size !== null ? (
                        <>
                            <Row>
                                <Link className="cart-table__product-name">
                                    Color :&nbsp;&nbsp; {buynow[0].preference_color}
                                </Link>
                            </Row>
                            <Row>
                                Size :&nbsp;&nbsp;   <Link >{buynow[0].preference_size}</Link>
                            </Row>
                        </>
                    ) : buynow[0].preference_color !== null && buynow[0].preference_size === null ? (
                        <Row>
                            <Link className="cart-table__product-name">
                                Color :&nbsp;&nbsp; {buynow[0].preference_color}
                            </Link>
                        </Row>
                    ) : buynow[0].preference_color === null && buynow[0].preference_size !== null ? (
                        <Row>
                            <Link className="cart-table__product-name">
                                Size :&nbsp;&nbsp; {buynow[0].preference_size}
                            </Link>
                        </Row>
                    ) :
                                <span>Empty</span>
                    }


                </td>
                <td
                    className="cart-table__column cart-table__column--price"
                    data-title="Price"
                >
                    {/*<Currency value={item.price}/>*/}
                    {/* <CurrencyFormat
                        value={buynow[0].total_amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => (
                            <div>
                                {" "}

                                {buynow[0].currency} {value}
                            </div>
                        )}
                    /> */}
                    {userCurrency() === buynow[0].currency ? (
                        <CurrencyFormat
                            value={Math.round((Number(buynow[0].total_amount) / Number(Rates[buynow[0].currency])) * Number(Rates[userCurrency()]))
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                                <div>
                                    {userCurrency()}{" "}{value}
                                </div>
                            )}
                        />
                    ) : (
                            <CurrencyFormat
                                value={((Number(buynow[0].total_amount) / Number(Rates[buynow[0].currency])) * Number(Rates[userCurrency()])).toFixed(2)
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                renderText={(value) => (
                                    <div>
                                        {userCurrency()}{" "}{value}
                                    </div>
                                )}
                            />
                        )
                    }
                </td>
                <td
                    className="cart-table__column cart-table__column--quantity"
                    data-title="Quantity"
                >
                    {buynow[0].quantity}
                </td>
                <td
                    className="cart-table__column cart-table__column--total"
                    data-title="Total"
                >
                    {/* <CurrencyFormat
                        value={}
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => (
                            <div>
                                {" "}
                                {buynow[0].currency} {value}
                            </div>
                        )}
                    /> */}
                    {userCurrency() === buynow[0].currency ? (
                        <CurrencyFormat
                            value={Math.round((Number(Amount_total) / Number(Rates[buynow[0].currency])) * Number(Rates[userCurrency()]))
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                                <div>
                                    {userCurrency()}{" "}{value}
                                </div>
                            )}
                        />
                    ) : (
                            <CurrencyFormat
                                value={((Number(Amount_total) / Number(Rates[buynow[0].currency])) * Number(Rates[userCurrency()])).toFixed(2)
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                renderText={(value) => (
                                    <div>
                                        {userCurrency()}{" "}{value}
                                    </div>
                                )}
                            />
                        )
                    }
                </td>
                <td className="cart-table__column cart-table__column--remove" />
            </tr>
        );
    }

    renderTotals() {
        const { Rates } = this.props
        let buynow = JSON.parse(localStorage.getItem("BuynowData"));
        console.log(buynow)
        return (
            <React.Fragment>
                <thead className="cart__totals-footer">
                    <tr>
                        <th>Amount</th>
                        <td>
                            {/* <CurrencyFormat
                                value={(buynow[0].total_amount - buynow[0].vat).toFixed(2)}
                                displayType={"text"}
                                thousandSeparator={true}
                                renderText={(value) => (
                                    <div>
                                        {" "}
                                        {userCurrency()} {value}
                                    </div>
                                )}
                            /> */}
                            {userCurrency() === buynow[0].currency ? (
                                <CurrencyFormat
                                    value={Math.round((Number(buynow[0].total_amount - buynow[0].vat) / Number(Rates[buynow[0].currency])) * Number(Rates[userCurrency()]))
                                    }
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    renderText={(value) => (
                                        <div>
                                            {userCurrency()}{" "}{value}
                                        </div>
                                    )}
                                />
                            ) : (
                                    <CurrencyFormat
                                        value={((Number(buynow[0].total_amount - buynow[0].vat) / Number(Rates[buynow[0].currency])) * Number(Rates[userCurrency()])).toFixed(2)
                                        }
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        renderText={(value) => (
                                            <div>
                                                {userCurrency()}{" "}{value}
                                            </div>
                                        )}
                                    />
                                )
                            }
                        </td>
                    </tr>
                </thead>
            </React.Fragment>
        );
    }
    renderCart() {
        const { Rates } = this.props
        let buynow = JSON.parse(localStorage.getItem("BuynowData"));
        // console.log(buynow)
        const { shippingOption } = this.state;
        const { myownShippingOpt, activeStep, MyownShipping } = this.props;
        function handleChange(data) {
            localStorage.setItem("deliveryOptions", data.secure_token);
            return data;
        }
        localStorage.getItem("deliveryOptions");
        return (
            <div className="cart block">
                <div className="container">
                    <table className="cart__table cart-table">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column cart-table__column--image">
                                    Product Image
                               </th>
                                <th className="cart-table__column cart-table__column--product">
                                    Product name
                                </th>
                                <th className="cart-table__column cart-table__column--product">
                                    Preference
                                 </th>
                                <th className="cart-table__column cart-table__column--price">
                                    Price
                               </th>
                                <th className="cart-table__column cart-table__column--quantity">
                                    Quantity
                               </th>
                                <th className="cart-table__column cart-table__column--total">
                                    Total
                               </th>
                                <th
                                    className="cart-table__column cart-table__column--remove"
                                    aria-label="Remove"
                                />
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">{this.renderItems()}</tbody>
                    </table>

                    <div className="row justify-content-end pt-md-5 pt-4">
                        <div className="col-12 col-md-7 col-lg-6 col-xl-5">
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title text-center"> SUMMARY</h3>
                                    <table className="cart__totals">
                                        {this.renderTotals()}
                                        <tfoot className="cart__totals-footer">
                                            <tr>
                                                <th>VAT</th>
                                                <td>
                                                    {/* <CurrencyFormat
                                                        value={(buynow[0].vat).toFixed(2)}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        renderText={(value) => (
                                                            <div>
                                                                {" "}
                                                                {userCurrency()} {value}
                                                            </div>
                                                        )}
                                                    /> */}

                                                    {userCurrency() === buynow[0].currency ? (
                                                        <CurrencyFormat
                                                            value={Math.round((Number(buynow[0].vat) / Number(Rates[buynow[0].currency])) * Number(Rates[userCurrency()]))
                                                            }
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            renderText={(value) => (
                                                                <div>
                                                                    {userCurrency()}{" "}{value}
                                                                </div>
                                                            )}
                                                        />
                                                    ) : (
                                                            <CurrencyFormat
                                                                value={((Number(buynow[0].vat) / Number(Rates[buynow[0].currency])) * Number(Rates[userCurrency()])).toFixed(2)
                                                                }
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                renderText={(value) => (
                                                                    <div>
                                                                        {userCurrency()}{" "}{value}
                                                                    </div>
                                                                )}
                                                            />
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Total Amount</th>
                                                <td>
                                                    {/* <CurrencyFormat
                                                        value={Number(buynow[0].total_amount).toFixed(2)}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        renderText={(value) => (
                                                            <div>
                                                                {" "}
                                                                {userCurrency()} {"  "} {value}
                                                            </div>
                                                        )}
                                                    /> */}
                                                    {userCurrency() === buynow[0].currency ? (
                                                        <CurrencyFormat
                                                            value={Math.round((Number(buynow[0].total_amount) / Number(Rates[buynow[0].currency])) * Number(Rates[userCurrency()]))
                                                            }
                                                            displayType={"text"}
                                                            thousandSeparator={true}
                                                            renderText={(value) => (
                                                                <div>
                                                                    {userCurrency()}{" "}{value}
                                                                </div>
                                                            )}
                                                        />
                                                    ) : (
                                                            <CurrencyFormat
                                                                value={((Number(buynow[0].total_amount) / Number(Rates[buynow[0].currency])) * Number(Rates[userCurrency()])).toFixed(2)
                                                                }
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                renderText={(value) => (
                                                                    <div>
                                                                        {userCurrency()}{" "}{value}
                                                                    </div>
                                                                )}
                                                            />
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    <hr />
                                    <h5 className="text-center">Select Delivery Option</h5>
                                    <div>
                                        {shippingOption.map((option) => (
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="Radiobox"
                                                    id="exampleRadios"
                                                    onChange={() => handleChange(option)}
                                                    value="Radiobox"
                                                    onClick={() => myownShippingOpt(option.customer_shipping, true)
                                                    }
                                                />
                                                <label className="form-check-label" for="exampleRadios">
                                                    {option.customer_shipping}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="mt-3">
                                        {localStorage.getItem("deliveryOptions") === null ? (
                                            <div className="text-center text-danger">
                                                Delivery Option is Required
                                            </div>
                                        ) : (
                                                <></>
                                            )}

                                        <button
                                            type="submit"
                                            disabled={localStorage.getItem("deliveryOptions") === null}
                                            onClick={() => handleNexts(activeStep)}
                                            className="btn rounded shadow-lg  btn-primary  btn-block cart__checkout-button"
                                        >
                                            Continue
                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Checkout — ${theme.name}`}</title>
                </Helmet>
                {this.renderCart()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    Rates: state.RateList,
    MyownShipping: state.MyownShipping
});

const mapDispatchToProps = {
    myownShippingOpt
};

export default connect(mapStateToProps, mapDispatchToProps)(BuynowProduct);
