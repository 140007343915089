// react
import React from "react";
// third-party
import { Helmet } from "react-helmet-async";
// application
// data stubs
import theme from "../../data/theme";
import CurrencyFormat from "react-currency-format";
import Moment from "react-moment";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import ShowMoreText from "react-show-more-text";
export default function ShopPageEditAddress(props) {
  const addrerssdata = props.location.state;
  return (
    <div className="block order-success">
      <Helmet>
        <title>{`address details — ${theme.name}`}</title>
      </Helmet>
      {/* <div className="container">
        <div className="order-success__body">
          
        </div>
      </div> */}
      <div className="block">
        <div className="container">
          <div className="card mb-0">
            {addrerssdata.map((address) => (
              <div className="card-body contact-us">
                <h4 className="contact-us__header card-title text-center">
                  ADDRESS DETAILS
                </h4>

                <div className="contact-us__container">
                  <div className="row">
                    <div className="col-12 col-lg-4 pb-4 pb-lg-0">
                      <div className="contact-us__address">
                        <p>
                          <strong>Fullname</strong>
                          <br />
                          {address.address_fullname}
                        </p>
                        <p>
                          <strong>Country</strong>
                          <br />
                          {address.country.country}
                        </p>
                        <p>
                          <strong>Post code</strong>
                          <br />
                          {address.address_postcode}
                        </p>
                        <p>
                          <strong>Address Type</strong>
                          <br />
                          {address.address_AddressType}
                        </p>
                      </div>
                    </div>
                    <div className="col-12 col-lg-4 pb-4 pb-lg-0">
                      <div className="contact-us__address">
                        <p>
                          <strong>Mobile Number</strong>
                          <br />
                          {address.address_mobileNumber}
                        </p>

                        <p>
                          <strong>Region</strong>
                          <br />
                          {address.region.region}
                        </p>
                        <p>
                          <strong>Street</strong>
                          <br />
                          {address.area}
                        </p>
                      </div>
                    </div>

                    <div className="col-12 col-lg-4 pb-4 pb-lg-0">
                      <div className="contact-us__address">
                        <p>
                          <strong>Alternative Phone Number</strong>
                          <br />
                          {address.address_mobileNumber2}
                        </p>

                        <p>
                          <strong>District</strong>
                          <br />
                          {address.address_district}
                        </p>

                        <p>
                          <strong>House Number</strong>
                          <br />
                          {address.address_house_number}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <p>
                  <strong>Additional Information</strong>
                  <br />
                  <ShowMoreText
                    lines={8}
                    more="Read more"
                    less="Read less"
                    className="content-css"
                    anchorClass="my-anchor-css-class"
                    expanded={false}
                  >
                    {ReactHtmlParser(address.additional_delivery_notes)}
                  </ShowMoreText>
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
