// react
import React from 'react';
// third-party
import {Helmet} from 'react-helmet-async';
// application
// data stubs
import theme from '../../data/theme';
function SitePageTerms() {
    return (
        <div className="block about-us">
            <Helmet>
                <title>{`Term of Use — ${theme.name}`}</title>
            </Helmet>
            <div className="about-us__image" style={{backgroundImage: 'url("")'}}/>
            <div>
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                        <div className="about-us__body">
                            <h1 className="about-us__title">Terms of use</h1>
                            <h5 className="d-flex justify-content-center">Part A: Effective as of January 6, 2020</h5>
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left" type="button"
                                                    data-toggle="collapse" data-target="#collapseOne"
                                                    aria-expanded="true" aria-controls="collapseOne">
                                                1.Introduction
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            Welcome to www.ezytrade.africa. These Terms of Use describe the terms and
                                            conditions applicable to your access and use of the websites, mobile sites,
                                            mobile applications and other portals owned, operated, branded or made
                                            available by Ezytrade.africa (defined below) from time to time which relate
                                            to
                                            (a) the EZYTRADE AFRICA e-commerce platform including but not limited to the
                                            web and mobile-optimized versions of the websites identified by the uniform
                                            resource locator “www.ezytrade.africa” and the mobile applications of the
                                            EZYTRADE AFRICA e-commerce platform (the “Ezytrade Sites”); e-commerce
                                            platform
                                            including but not limited to the web and mobile-optimized versions of the
                                            websites identified by the uniform resource locators
                                            “www.ezytradeafrica.com”,
                                            and the mobile applications of the Ezytrade.africa e-commerce
                                            platforms,(with ezytrade.africa Sites and ezytradeafrica.com Sites
                                            collectively the “Sites”).
                                            This document is a legally binding agreement between you as the user(s) of
                                            the Sites (referred to as “you”, “your” or “User” hereinafter)
                                            and the Ezytrade.africa contracting entity determined in accordance with
                                            clause 2.1 below (referred to as “we”, “our” or “Ezytrade.africa”
                                            hereinafter)
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseTwo"
                                                    aria-expanded="false" aria-controls="collapseTwo">
                                                2.Application and Acceptance of the Term
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>2.1 Your access to and use of the Sites and Ezytrade.africa’s services,
                                                software and products through the Sites,
                                                which shall include the platforms described in clause 7.1 of the Terms
                                                (such services, software and products collectively
                                                referred to as the “Services” hereinafter) is subject to the terms and
                                                conditions contained in this document as well as the
                                                Privacy Policy (defined in clause 3.3 below), the Product Listing Policy
                                                and any other rules and policies of the Sites that
                                                Ezytrade.africa may publish from time to time. The Promotion Services
                                                referred to in the Free Membership Agreement shall include
                                                Top Ranking and Sponsored Listing and any such other Services as may be
                                                announced by Ezytrade.africa from time to time.
                                                This document and such other rules and policies of the Sites are
                                                collectively referred to below as the “Terms”. By accessing
                                                and use of the Sites and Services, you agree to accept and be bound by
                                                the Terms.
                                                Please do not access or use the Services or the Sites if you do not
                                                accept all of the Terms.</p>
                                            <p>
                                                2.2 You may not access or use the Services or the Sites and may not
                                                accept the Terms if (a) you are not of legal age to form a
                                                binding contract with Ezytrade.africa, or (b) you are not permitted to
                                                receive any Services under the laws of any country / regions
                                                including the country / region in which you are resident or from which
                                                you access and use the Services and the Sites.
                                            </p>

                                            <p>
                                                2.3 Ezytrade.africa may amend any Terms at any time by posting the
                                                relevant amended and restated Terms on the Sites. By continuing to
                                                access or use the Services or the Sites, you agree that the amended and
                                                restated Terms will apply to you.
                                            </p>

                                            <p>
                                                2.4 If Ezytrade.africa has posted or provided a translation of the
                                                English language version of the Terms, you agree that the translation is
                                                provided for convenience only and that the English language version will
                                                govern your access to and use of the Services or the Sites.
                                            </p>
                                            <p>
                                                2.5 You may be required to enter into separate agreement(s), whether
                                                online or offline, with Ezytrade.africa or our affiliate for any Service
                                                (or features within the Services) (each an “Additional Agreement”). If
                                                there is any conflict or inconsistency between the Terms and an
                                                Additional Agreement, the Additional Agreement shall take precedence
                                                over the Terms only in relation to that Service (or feature within the
                                                Service) concerned.
                                            </p>
                                            <p>
                                                2.6 The Terms may not otherwise be modified except in writing by an
                                                authorized officer of Ezytrade.africa.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseThree"
                                                    aria-expanded="false" aria-controls="collapseThree">
                                                3.Provision of Services
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThree" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                3.1 If you are a registered member of any of the Sites, you are
                                                contracting with Ezytrade Africa Ltd. As some or part of the Services
                                                may be supported and provided by affiliates of ezytrade.africa,
                                                ezytrade.africa may delegate some of the Services to its affiliates
                                            </p>
                                            <p>
                                                3.2 You must register as a member on the Sites in order to access and
                                                use some Services. Further, ezytrade.africa reserves the right, without
                                                prior notice, to restrict access to or use of certain Services (or any
                                                features within the Services) to paying Users, or subject to other
                                                conditions that Ezytrade.africa may impose at our discretion
                                            </p>
                                            <p>
                                                3.3 Services (or any features within the Services) may vary for
                                                different regions and countries. No warranty or representation is given
                                                that a particular Service or feature or function thereof or the same
                                                type and extent of the Service or features and functions thereof will be
                                                available for Users. Ezytrade.africa may in our sole discretion limit,
                                                deny or create different levels of access to and use of any Services (or
                                                any features within the Services) with respect to different User
                                            </p>
                                            <p>
                                                3.4 Ezytrade.africa may launch, change, upgrade, impose conditions to,
                                                suspend, or stop any Services (or any features within the Services)
                                                without prior notice except that in case of a fee-based Service, such
                                                changes will not substantially adversely affect the ability of such
                                                paying Users to enjoy that Service.
                                            </p>
                                            <p>
                                                3.5 Some Services (or part thereof) may be provided by Ezytrade.africa’s
                                                affiliates on behalf of Ezytrade.africa.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseFour"
                                                    aria-expanded="false" aria-controls="collapseFour">
                                                4.User Generally
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFour" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                4.1 As a condition of your access to and use of the Sites or Services,
                                                you agree that you will comply with all applicable laws and regulations
                                                when accessing or using the Sites or Services.
                                            </p>
                                            <p>
                                                4.2 You agree that (a) you will not copy, reproduce, download,
                                                re-publish, sell, distribute or resell any Services or any information,
                                                text, images, graphics, video clips, sound, directories, files,
                                                databases or listings, etc available on or through the Sites (the “Site
                                                Content”), and (b) you will not copy, reproduce, download, compile or
                                                otherwise use any Site Content for the purposes of operating a business
                                                that competes with Ezytrade.africa, or otherwise commercially exploiting
                                                the Site Content. Systematic retrieval of Site Content from the Sites to
                                                create or compile, directly or indirectly, a collection, compilation,
                                                database or directory (whether through robots, spiders, automatic
                                                devices or manual processes) without written permission from
                                                Ezytrade.africa is prohibited. Use of any content or materials on the
                                                Sites for any purpose not expressly permitted in the Terms is
                                                prohibited.
                                            </p>
                                            <p>
                                                4.3 You must read the following documents which govern the protection
                                                and use of personal information about Users in the possession of
                                                Ezytrade.africa and our affiliates:for Users who access or use Sites
                                                relating to the Ezytrade Africa e-commerce platform, and the Privacy
                                                Policy
                                            </p>
                                            <p>
                                                4.4 Ezytrade may allow Users to access to content, products or services
                                                offered by third parties through hyperlinks (in the form of word link,
                                                banners, channels or otherwise), API or otherwise to such third
                                                parties\' web sites. You are cautioned to read such web sites\' terms
                                                and conditions and/or privacy policies before using the Sites. You
                                                acknowledge that ezytrade.africa has no control over such third
                                                parties\' web sites, does not monitor such web sites, and shall not be
                                                responsible or liable to anyone for such web sites, or any content,
                                                products or services made available on or through such web sites.
                                            </p>
                                            <p>
                                                4.5 You agree not to undertake any action to undermine the integrity of
                                                the computer systems or networks of ezytrade.africa and/or any other
                                                User nor to gain unauthorized access to such computer systems or
                                                networks.
                                            </p>
                                            <p>
                                                4.6 You agree not to undertake any action which may undermine the
                                                integrity of ezytrade.africa’s feedback system, such as leaving positive
                                                feedback for yourself using secondary Member IDs or through third
                                                parties or by leaving unsubstantiated negative feedback for another
                                                User.
                                            </p>
                                            <p>

                                                4.7 By posting or displaying any information, content or material (“User
                                                Content”) on the Sites or providing any User Content to Ezytrade.africa
                                                or our representative(s), and to the extent permitted under applicable
                                                laws, you grant an irrevocable, perpetual, worldwide, royalty-free, and
                                                sub-licensable (through multiple tiers) license to Ezytrade.africa to
                                                display, transmit, distribute, reproduce, publish, duplicate, adapt,
                                                modify, translate, create derivative works, and otherwise use any or all
                                                of the User Content in any form, media, or technology now known or not
                                                currently known in any manner and for any purpose which may be
                                                beneficial to Ezytrade.africa, the operation of the Sites, the provision
                                                of any Services and/or the business of the User. You confirm and warrant
                                                to Ezytrade.africa that you have all the rights, power and authority
                                                necessary to grant the above license.Information that is protected under
                                                data protection laws will only be used and kept in compliance with those
                                                laws.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseFive"
                                                    aria-expanded="false" aria-controls="collapseFive">
                                                5.Member Account
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseFive" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                5.1 User must be registered on the Sites to access or use some Services
                                                (a registered User is also referred to as a “Member” below). Except with
                                                Ezytrade.africa’s approval, one User may only register one member
                                                account on the Sites. Ezytrade.africa may cancel or terminate a User’s
                                                member account if Ezytrade.africa has reasons to suspect that the User
                                                has concurrently registered or is in control of two or more member
                                                accounts. Further, Ezytrade.africa may reject User’s application for
                                                registration for any reason.
                                            </p>
                                            <p>
                                                5.2 Upon registration on the Sites, Ezytrade.africa shall assign an
                                                account and issue a member ID and password (the latter shall be chosen
                                                by a registered User during registration) to each registered User. An
                                                account may have a web-based email account with limited storage space
                                                for the Member to send or receive emails.
                                            </p>
                                            <p>
                                                5.3 A set of Member ID and password is unique to a single account. Each
                                                Member shall be solely responsible for maintaining the confidentiality
                                                and security of your Member ID and password and for all use of and
                                                activities that occur under your account (whether such use or activities
                                                are authorized or not). No Member may share, assign, or permit the use
                                                of your Member account, ID or password by another person, even to other
                                                individuals within the Member’s own business entity (where applicable).
                                                Member agrees to notify ezytrde.africa immediately if you become aware
                                                of any unauthorized use of your password or your account or any other
                                                breach of security of your account.
                                            </p>
                                            <p>
                                                5.4 Member agrees that all use of the Sites and Services, and all
                                                activities that occur under your account (including without limitation,
                                                posting any company or product information, clicking to accept any
                                                Additional Agreements or rules, subscribing to or making any payment for
                                                any services, sending emails using the email account or sending SMS)
                                                will be deemed to have been authorized by the Membe
                                            </p>
                                            <p>
                                                5.5 Member acknowledges that sharing of your account with other persons,
                                                or allowing multiple users outside of your business entity to use your
                                                account (collectively, "multiple use"), may cause irreparable harm to
                                                Ezytrade.africa or other Users of the Sites. Member shall indemnify
                                                Ezytrade.africa, our affiliates, directors, employees, agents and
                                                representatives against any loss or damages (including but not limited
                                                to loss of profits) suffered as a result of the multiple use of your
                                                account. Member also agrees that in case of the multiple use of your
                                                account or Member’s failure to maintain the security of your account,
                                                Ezytrade.africa shall not be liable for any loss or damages arising from
                                                such a breach and shall have the right to suspend or terminate Member’s
                                                account without liability to the Member.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapsesix"
                                                    aria-expanded="false" aria-controls="collapsesix">
                                                6.Member’s Responsibilities
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapsesix" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                6.1 Each Member represents, warrants and agrees that (a) you have full
                                                power and authority to accept the Terms, to grant the license and
                                                authorization and to perform the obligations hereunder; (b) your access
                                                and use the Sites and Services will be for business purposes only; and
                                                (c) for Members who are business entities, the address you provide when
                                                registering is the principal place of business of your business entity.
                                                For purposes of this provision, a branch or liaison office will not be
                                                considered a separate entity and your principal place of business will
                                                be deemed to be that of your head office.
                                            </p>
                                            <p>
                                                6.2 Member will be required to provide information or material about
                                                your entity, business or products/services as part of the registration
                                                process on the Sites for your access to and use of any Service or the
                                                member account. Each Member represents, warrants and agrees that (a)
                                                such information and material whether submitted during the registration
                                                process or thereafter throughout the continuation of the use of the
                                                Sites or Service is true, accurate, current and complete, and (b) you
                                                will maintain and promptly amend all information and material to keep it
                                                true, accurate, current and complete.
                                            </p>
                                            <p>
                                                6.3 Upon becoming a Member, you consent to the inclusion of the contact
                                                information about you in our database and authorize Ezytrade.africa and
                                                our affiliates to share the contact information with other Users or
                                                otherwise use your personal information in accordance with the Privacy
                                                Policy
                                            </p>
                                            <p>
                                                6.4 Each Member represents, warrants and agrees that (a) you shall be
                                                solely responsible for obtaining all necessary third party licenses and
                                                permissions regarding any User Content that you submit, post or display;
                                                (b) any User Content that you submit, post or display does not infringe
                                                or violate any of the copyright, patent, trademark, trade name, trade
                                                secrets or any other personal or proprietary rights of any third party
                                                (“Third Party Rights”); (c) you have the right and authority to sell,
                                                trade, distribute or export or offer to sell, trade, distribute or
                                                export the products or services described in the User Content and such
                                                sale, trade, distribution or export or offer does not violate any Third
                                                Party Rights and (d) you and your affiliates are not the subject of any
                                                trade restrictions, sanctions or other legal restrictions enacted by any
                                                country, international organization or jurisdiction
                                            </p>
                                            <p>
                                                6.5 Each Member further represents, warrants and agrees that the User
                                                Content that you submit, post or display shall
                                                <ul>
                                                    <li>a) be true, accurate, complete and lawful;</li>
                                                    <li>b) not be false, misleading or deceptive;</li>
                                                    <li> c) not contain information that is defamatory, libelous,
                                                        threatening or harassing, obscene, objectionable, offensive,
                                                        sexually explicit or harmful to minors;
                                                    </li>
                                                    <li> d) not contain information that is discriminatory or promotes
                                                        discrimination based on race, sex, religion, nationality,
                                                        disability, sexual orientation or age;
                                                    </li>
                                                    <li> e) not violate the Product Listing Policy, other Terms or any
                                                        applicable Additional Agreements
                                                    </li>
                                                    <li>f) not violate any applicable laws and regulations (including
                                                        without limitation those governing export control, consumer
                                                        protection, unfair competition, or false advertising) or promote
                                                        any activities which may violate any applicable laws and
                                                        regulations;
                                                    </li>
                                                    <li>g) not contain any link directly or indirectly to any other web
                                                        Sites which includes any content that may violate the Terms.
                                                    </li>
                                                </ul>
                                            </p>
                                            <p>
                                                6.6 Each Member further represents, warrants and agrees that you
                                                shall/are:
                                                <li> a) carry on your activities on the Sites in compliance with any
                                                    applicable laws and regulations;</li>
                                                <li> b) conduct your business transactions with other users of the Sites
                                                    in good faith;
                                                </li>
                                                <li> c) carry on your activities in accordance with the Terms and any
                                                    applicable Additional Agreements;
                                                </li>
                                                <li>d) not use the Services or Sites to defraud any person or entity
                                                    (including without limitation sale of stolen items, use of stolen
                                                    credit/debit cards);
                                                </li>
                                                <li> e) not impersonate any person or entity, misrepresent yourself or
                                                    your affiliation with any person or entity;
                                                </li>
                                                <li> f) not engage in spamming or phishing;</li>
                                                <li>g) not engage in any other unlawful activities (including without
                                                    limitation those which would constitute a criminal offence, give
                                                    rise to civil liability, etc) or encourage or abet any unlawful
                                                    activities;
                                                </li>
                                                <li> h) not involve attempts to copy, reproduce, exploit or expropriate
                                                    Ezytrade.africa’s various proprietary directories, databases and
                                                    listings;
                                                </li>
                                                <li> i) not involve any computer viruses or other destructive devices
                                                    and codes that have the effect of damaging, interfering with,
                                                    intercepting or expropriating any software or hardware system, data
                                                    or personal information;
                                                </li>
                                                <li> j) not involve any scheme to undermine the integrity of the data,
                                                    systems or networks used by Ezytrade.africa and/or any user of the
                                                    Sites or gain unauthorized access to such data, systems or networks;
                                                </li>
                                                <li> k) not, and your director(s), officer(s), controlling party/ies,
                                                    affiliates and legal jurisdiction in which any of the foregoing
                                                    persons or entities is organized or has operations are not, persons
                                                    or entities that are subject to any economic or fraud sanctions of
                                                    any governmental, international or regulatory entities; and
                                                </li>
                                                <li> l) not engage in any activities that would otherwise create any
                                                    liability for Alibaba.com or our affiliates. ' 6.7 Member may not
                                                    use the Sites, Services or member account to engage in activities
                                                    which are identical or similar to Ezytrade.africa’s e-commerce
                                                    marketplace business.
                                                </li>
                                            </p>
                                            <p>
                                                6.8 If Member provides a business referee, Member represents, warrants
                                                and agrees that you have obtained all necessary consents, approvals and
                                                waivers from such referee to
                                                <ul>
                                                    <li>(a) act as your business referee;</li>
                                                    <li>(b) post
                                                        and publish their contact details and information, reference
                                                        letters and
                                                        comments on their behalf; and
                                                    </li>
                                                    <li>(c) that third parties may contact such
                                                        business referees to support claims or statements made about
                                                        you. You
                                                        further warrant and agree that all reference letters and
                                                        comments are
                                                        true and accurate and third parties may contact the business
                                                        referees
                                                        without the need to obtain your consent.
                                                    </li>
                                                </ul>
                                            </p>
                                            <p>
                                                6.9 Member agrees to provide all necessary information, materials and
                                                approval, and render all reasonable assistance and cooperation necessary
                                                for ezytrade.africa’s provision of the Services, evaluating whether
                                                Member has breached the Terms and/or handling any complaint against the
                                                Member. If Member’s failure to do so results in delay in, or suspension
                                                or termination of, the provision of any ServiceEzytrade.africa shall not
                                                be obliged to extend the relevant service period nor be liable for any
                                                loss or damages arising out of or in connection with such delay,
                                                suspension or termination.

                                            </p>
                                            <p>
                                                6.10 Member acknowledges and agrees that Ezytrade.africa reserves the
                                                right to, but shall not be required to actively monitor or exercise any
                                                editorial control whatsoever over the content of any message or material
                                                or information (including User Content) created, obtained or accessible
                                                through the Services or Sites. Ezytrade.africa does not endorse, verify
                                                or otherwise certify the contents of any comments or other material or
                                                information (including User Content) created, submitted, posted,
                                                displayed or otherwise made by any Member. Each Member is solely
                                                responsible for the contents of their communications and may be held
                                                legally liable or accountable for the content of their comments or other
                                                material or information
                                            </p>
                                            <p>
                                                6.11 Member acknowledges and agrees that the Sites and Services may only
                                                be used by businesses and their representatives for business use and not
                                                for for personal use.
                                            </p>
                                            <p>
                                                6.12Member acknowledges and agrees that each Member is solely
                                                responsible
                                                for observing applicable laws and regulations in its respective
                                                jurisdictions to ensure that all access and use of the Site and Services
                                                are
                                                in compliance with the same.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseSeven"
                                                    aria-expanded="false" aria-controls="collapseSeven">
                                                7.Breaches by Members
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseSeven" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                7.1 Ezytrade.africa reserves the right in our sole discretion to remove,
                                                modify or reject any User Content that you submit to, post or display on
                                                the Sites which we reasonably believe is unlawful, violates the Terms,
                                                could subject Ezytrade.africa or our affiliates to liability, or is
                                                otherwise found inappropriate in ezytrade.africa’s sole discretion.
                                            </p>
                                            <p>
                                                7.2 If any Member breaches any Terms, or if Ezytrade.africa has
                                                reasonable grounds to believe that a Member is in breach of any Terms,
                                                Ezytrade.africa shall have the right to take such disciplinary actions
                                                as it deems appropriate, including without limitation:
                                                <ul>
                                                    <li>(i) suspending or terminating the Member’s account and any and
                                                        all
                                                        accounts determined to be related to such account by
                                                        Ezytrade.africa in
                                                        its sole discretion without liability for any losses or damages
                                                        arising
                                                        out of or in connection with such suspension or termination;
                                                    </li>
                                                    <li>(ii) restricting, downgrading, suspending or terminating the
                                                        subscription of, access to, or current or future use of any
                                                        Service;
                                                    </li>
                                                    <li>(iii) removing any product listings or other User Content that
                                                        the
                                                        Member has submitted, posted or displayed, or imposing
                                                        restrictions on
                                                        the number of product listings or User Content that the Member
                                                        may post
                                                        or display;
                                                    </li>
                                                    <li>(iv) imposing other restrictions on the Member’s use of any
                                                        features or
                                                        functions of any Service as Ezytrade.africa may consider
                                                        appropriate in
                                                        its sole discretion; and
                                                    </li>
                                                    <li>
                                                        (v) any other corrective actions, discipline or penalties as
                                                        Ezytrade.africa may deem necessary or appropriate in its sole
                                                        discretion.
                                                    </li>
                                                </ul>
                                            </p>
                                            <p>
                                                7.3 Without limiting the generality of the provisions of the Terms, a
                                                Member would be considered as being in breach of the Terms in any of the
                                                following circumstances:
                                                <ul>
                                                    <li>a) upon complaint or claim from any third party, Ezytrade.africa
                                                        has
                                                        reasonable grounds to believe that such Member has willfully or
                                                        materially failed to perform your contract with such third party
                                                        including without limitation where a Member who supplies
                                                        products or
                                                        services using the Sites and Services has failed to deliver any
                                                        items
                                                        ordered by such third party after receipt of the purchase price,
                                                        or
                                                        where the items such Member has delivered materially fail to
                                                        meet the
                                                        terms and descriptions outlined in your contract with such third
                                                        party,
                                                    </li>
                                                    <li>b) ezytrade.africa has reasonable grounds to suspect that such
                                                        Member
                                                        has used a stolen credit card or other false or misleading
                                                        information
                                                        in any transaction with a counter party,
                                                    </li>
                                                    <li>c) Ezytrade.africa has reasonable grounds to suspect that any
                                                        information provided by the Member is not current or complete or
                                                        is
                                                        untrue, inaccurate, or misleading, or
                                                    </li>
                                                    <li> d) Ezytrade.africa believes that the Member’s actions may cause
                                                        financial loss or legal liability to Ezytrade.africa or our
                                                        affiliates
                                                        or any other Users.
                                                    </li>
                                                </ul>
                                            </p>
                                            <p>
                                                7.4 Ezytrade.africa reserves the right to cooperate fully with
                                                governmental or regulatory authorities, law enforcement bodies, private
                                                investigators and/or injured third parties in the investigation of any
                                                suspected criminal or civil wrongdoing. Further, to the extent permitted
                                                by applicable laws and policies, Ezytrade.africa may disclose the
                                                Member\'s identity, contact information and/or information regarding the
                                                Member\'s account(s), transactions or activities carried out on or via
                                                the Site, if requested by a government, regulatory or law enforcement
                                                body or an injured third party, or as a result of a subpoena or other
                                                legal action. Ezytrade.africa shall not be liable for damages or results
                                                arising from such disclosure, and Member agrees not to bring any action
                                                or claim against Ezytrade.africa for such disclosure.
                                            </p>
                                            <p>
                                                7.5 Ezytrade.africa may, at any time and in our reasonable discretion,
                                                impose limitations on, suspend or terminate the Member’s use of any
                                                Service or the Sites without being liable to the Member if
                                                Ezytrade.africa has received notice that the Member is in breach of any
                                                agreement or undertaking with any affiliate of Ezytrade.africa including
                                                without limitation ezytradeafrica.com, such breach involves or is
                                                reasonably suspected to involve dishonest or fraudulent activities.
                                                Ezytrade.africa reserves the right to, but shall not be required to
                                                investigate such breach or request confirmation from the Member.
                                            </p>
                                            <p>
                                                7.6 Each Member agrees to indemnify Ezytrade.africa, our affiliates,
                                                directors, employees, agents and representatives and to hold them
                                                harmless, from any and all damages, losses, claims and liabilities
                                                (including legal costs on a full indemnity basis) which may arise from
                                                your submission, posting or display of any User Content, from your
                                                access to or use of the Sites or Services, or from your breach of the
                                                Terms or any Additional Agreement
                                            </p>
                                            <p>
                                                7.7 Each Member further agrees that Ezytrade.africa is not responsible,
                                                and shall have no liability to you or anyone else for any User Content
                                                or other material transmitted through the Sites or Services, including
                                                fraudulent, untrue, misleading, inaccurate, defamatory, offensive or
                                                illicit material and that the risk of damage from such User Content or
                                                other material rests entirely with the Member. Ezytrade.africa reserves
                                                the right, at our own expense, to assume the exclusive defense and
                                                control of any matter otherwise subject to indemnification by the
                                                Member, in which event the Member shall cooperate with Ezytrade.africa
                                                in asserting any available defenses.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseEight"
                                                    aria-expanded="false" aria-controls="collapseEight">
                                                8.Transactions Between Buyers and Sellers
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseEight" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                8.1 Through the Sites, Ezytrade.africa provides electronic web-based
                                                platforms for exchanging information between buyers and sellers of
                                                products and servicesEzytrade.africa additionally provides electronic
                                                web-based transaction platforms for Members to place, accept, conclude,
                                                manage and fulfill orders for the provision of products and services
                                                online within the Sites subject to the terms of the Transaction Service
                                                Agreement However, for any Services, ezytrade.africa does not represent
                                                either the seller or the buyer in specific transactions. Ezytrade.africa
                                                does not control and is not liable or responsible for the quality,
                                                safety, lawfulness or availability of the products or services offered
                                                for sale on the Sites, the ability of the sellers to complete a sale or
                                                the ability of buyers to complete a purchase
                                            </p>
                                            <p>
                                                8.2 Users are hereby made aware that there may be risks of dealing with
                                                people acting under false pretences. Ezytrade.africa uses several
                                                techniques to verify the accuracy of certain information our paying
                                                Users provide us when they register for a paying membership service on
                                                the Sites. However, because user verification on the Internet is
                                                difficult, Ezytrade.africa cannot and does not confirm each User\'s
                                                purported identity (including, without limitation, paying Members). We
                                                encourage you to use various means, as well as common sense, to evaluate
                                                with whom you are dealing.
                                            </p>
                                            <p>
                                                8.3 Buyers and sellers accessing or using the Sites or Services shall
                                                assume the risks of conducting any purchase and sale transactions in
                                                connection with or through the Sites or Services. Buyer and sellers
                                                accessing or using the Site or Services shall also fully assume all
                                                risks of liability or harm of any kind arising out of or in connection
                                                with any subsequent activity relating to the products or services that
                                                are the subject of the transactions on the Sites. Examples of such risks
                                                shall include, but are not limited to, mis-representation of products
                                                and services, fraudulent schemes, unsatisfactory product quality,
                                                failure to meet specifications, defective or dangerous products,
                                                unlawful products, delay or default in delivery or payment, cost
                                                mis-calculations, breach of warranty, breach of contract, transportation
                                                accidents, the risk that the manufacture, importation, export,
                                                distribution, offer, display, purchase, sale and/or use of products or
                                                services offered or displayed on the Sites may violate or may be
                                                asserted to violate Third Party Rights, and the risk that Users may
                                                incur costs of defense or other costs in connection with third parties’
                                                assertion of Third Party Rights, or in connection with any claims by any
                                                party that they are entitled to defense or indemnification in relation
                                                to the assertion of rights, demands or claims by claimants of Third
                                                Party Rights. Examples of such risks also include the risk of claims
                                                from consumers, other purchasers, end-users of products or other third
                                                parties that they have suffered injuries or harm from their use of the
                                                products obtained through the Sites or Services. All of the foregoing
                                                risks are referred to as "Transaction Risks". Ezytrade.africa is not
                                                liable or responsible for any damages, claims, liabilities, costs, harm,
                                                inconveniences, business disruptions or expenditures of any kind that
                                                may arise a result of or in connection with any Transaction Risks.
                                            </p>
                                            <p>
                                                8.4 Buyers and sellers on the Sites are solely responsible for setting
                                                out and performance of the terms and conditions of the transactions
                                                conducted on, through or as a result of use of the Sites or Services,
                                                including, without limitation, terms regarding payment, returns,
                                                warranties, shipping, insurance, fees, taxes, title, licenses, fines,
                                                permits, handling, transportation and storage, subject to any additional
                                                obligations imposed under the Transaction Service Agreement (and in
                                                particular, clause 3.2
                                            </p>
                                            <p>
                                                8.5 User agrees to provide all information and materials as may be
                                                reasonably required by ezytrade.africa in connection with your
                                                transactions conducted on, through or as a result of use of the Sites or
                                                Services. Ezytrade.africa has the right to suspend or terminate any
                                                User’s account if the User fails to provide the required information and
                                                materials without liability for any losses or damages arising out of or
                                                in connection with such suspension or termination.
                                            </p>
                                            <p>
                                                8.6 In the event that any User has a dispute with any party to a
                                                transaction, such User agrees to release and indemnify Ezytrade.africa
                                                (and our agents, affiliates, directors, officers and employees) from all
                                                claims, demands, actions, proceedings, costs, expenses and damages
                                                (including without limitation any actual, special, incidental or
                                                consequential damages) arising out of or in connection with such dispute
                                                or the transaction.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseNine"
                                                    aria-expanded="false" aria-controls="collapseNine">
                                                9.Limitation of Liability
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseNine" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                9.1 TO THE MAXIMUM EXTENT PERMITTED BY LAW, THE SERVICES PROVIDED BY
                                                EZYTRADE.AFRICA ON OR THROUGH THE SITES ARE PROVIDED "AS IS", "AS
                                                AVAILABLE" AND “WITH ALL FAULTS”, AND EZYTRADE.AFRICA HEREBY EXPRESSLY
                                                DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT
                                                LIMITED TO, ANY WARRANTIES OF CONDITION, QUALITY, DURABILITY,
                                                PERFORMANCE, ACCURACY, RELIABILITY, MERCHANTABILITY OR FITNESS FOR A
                                                PARTICULAR PURPOSE. ALL SUCH WARRANTIES, REPRESENTATIONS, CONDITIONS,
                                                AND UNDERTAKINGS ARE HEREBY EXCLUDED.
                                            </p>
                                            <p>
                                                9.2 TO THE MAXIMUM EXTENT PERMITTED BY LAW, EZYTRADE.AFRICA MAKES NO
                                                REPRESENTATIONS OR WARRANTIES ABOUT THE VALIDITY, ACCURACY, CORRECTNESS,
                                                RELIABILITY, QUALITY, STABILITY, COMPLETENESS OR CURRENTNESS OF ANY
                                                INFORMATION PROVIDED ON OR THROUGH THE SITES; EZYTRADE.AFRICA DOES NOT
                                                REPRESENT OR WARRANT THAT THE MANUFACTURE, IMPORTATION, EXPORT,
                                                DISTRIBUTION, OFFER, DISPLAY, PURCHASE, SALE AND/OR USE OF PRODUCTS OR
                                                SERVICES OFFERED OR DISPLAYED ON THE SITES DOES NOT VIOLATE ANY THIRD
                                                PARTY RIGHTS; AND EZYTRADE.AFRICA MAKES NO REPRESENTATIONS OR WARRANTIES
                                                OF ANY KIND CONCERNING ANY PRODUCT OR SERVICE OFFERED OR DISPLAYED ON
                                                THE SITES
                                            </p>
                                            <p>
                                                9.3 Any material downloaded or otherwise obtained through the Sites or
                                                Services is done at each User\'s sole discretion and risk and each User
                                                is solely responsible for any damage to its own or to ezytrade.africa’s
                                                computer system(s) or any loss of data that may result from the download
                                                of any such material. No advice or information, whether oral or written,
                                                obtained by any User from Ezytrade.africa or through or from the Sites
                                                shall create any warranty not expressly stated herein
                                            </p>
                                            <p>
                                                9.4 The Sites may make available to User services or products provided
                                                by independent third parties. No warranty or representation is made with
                                                regard to such services or products. In no event shall Ezytrade.africa
                                                or our affiliates be held liable for any such services or products.
                                            </p>
                                            <p>
                                                9.5 Each User hereby agrees to indemnify and save Ezytrade.africa, our
                                                affiliates, directors, officers and employees harmless, from any and all
                                                losses, claims, liabilities (including legal costs on a full indemnity
                                                basis) which may arise from such User\'s access to or use of the Sites
                                                or Services (including but not limited to the display of such User\'s
                                                information on the Sites) or from your breach of any of the terms and
                                                conditions of the Terms. Each User hereby further agrees to indemnify
                                                and save Ezytrade.africa, our affiliates, directors, officers and
                                                employees harmless, from any and all losses, damages, claims,
                                                liabilities (including legal costs on a full indemnity basis) which may
                                                arise from User\'s breach of any representations and warranties made by
                                                User to Ezytrade.africa, including but not limited to those set forth in
                                                clause 5 hereunder.
                                            </p>
                                            <p>
                                                9.6 Each User hereby further agrees to indemnify and save
                                                Ezytrade.africa, our affiliates, directors, officers and employees
                                                harmless, from any and all losses, damages, claims, liabilities
                                                (including legal costs on a full indemnity basis) which may arise,
                                                directly or indirectly, as a result of any claims asserted by Third
                                                Party Rights claimants or other third parties relating to products
                                                offered or displayed on the Sites. Each User hereby further agrees that
                                                Ezytrade.africa is not responsible and shall have no liability to you,
                                                for any material posted by others, including defamatory, offensive or
                                                illicit material and that the risk of damages from such material rests
                                                entirely with each User. Ezytrade.africa reserves the right, at our own
                                                expense, to assume the exclusive defense and control of any matter
                                                otherwise subject to indemnification by you, in which event you shall
                                                cooperate with Alibaba.com in asserting any available defenses.
                                            </p>
                                            <p>
                                                9.7 Ezytrade.africa shall not be liable for any special, direct,
                                                indirect, punitive, incidental or consequential damages or any damages
                                                whatsoever (including but not limited to damages for loss of profits or
                                                savings, business interruption, loss of information), whether in
                                                contract, negligence, tort, equity or otherwise or any other damages
                                                resulting from any of the following:
                                                <ul>
                                                    <li>a) the use or the inability to use the Sites or Services;</li>
                                                    <li>b) any defect in goods, samples, data, information or services
                                                        purchased or obtained from a User or any other third party
                                                        through the Sites or Services;
                                                    </li>
                                                    <li> c) violation of Third Party Rights or claims or demands that
                                                        User\'s
                                                        manufacture, importation, exportation, distribution, offer,
                                                        display,
                                                        purchase, sale and/or use of products or services offered or
                                                        displayed
                                                        on the Sites or through the Services may violate or may be
                                                        asserted to
                                                        violate Third Party Rights; or claims by any party that they are
                                                        entitled to defense or indemnification in relation to assertions
                                                        of
                                                        rights, demands or claims by Third Party Rights claimants;
                                                    </li>
                                                    <li> d) unauthorized access by third parties to data or private
                                                        information of any User;
                                                    </li>
                                                    <li> e) statements or conduct of any User of the Sites or Services;
                                                        or;
                                                    </li>
                                                    <li> f) any matters relating to the Sites or Services, however
                                                        arising, including negligence
                                                    </li>
                                                </ul>
                                            </p>
                                            <p>
                                                8.8 Notwithstanding any of the foregoing provisions, the aggregate
                                                liability of Ezytrade.africa, our employees, agents, affiliates,
                                                representatives or anyone acting on our behalf with respect to each User
                                                for all claims arising from the access to or use of the Sites or
                                                Services during any calendar year shall be limited to the greater of (a)
                                                the amount of fees the User has paid to Ezytrade.africa in exchange for
                                                the access to or use of the Site or Services during the calendar year
                                                and (b) the maximum amount permitted under the applicable law. The
                                                preceding sentence shall not preclude the requirement by the User to
                                                prove actual damages. All claims arising from the use of the Sites or
                                                Services must be filed within one (1) year from the date the cause of
                                                action arose or such longer period as prescribed under the applicable
                                                law governing the Terms.
                                            </p>
                                            <p>
                                                9.9 The limitations and exclusions of liability to you under the Terms
                                                shall apply to the maximum extent permitted by law and shall apply
                                                whether or not Ezytrade.africa has been advised of or should have been
                                                aware of the possibility of any such losses arising.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseTen"
                                                    aria-expanded="false" aria-controls="collapseTen">
                                                10.Force Majeure
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTen" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                10.1 Under no circumstances shall ezytrade.africa be held liable for any
                                                delay or failure or disruption of the content or the Services accessed
                                                or delivered through the Sites resulting directly or indirectly from
                                                acts of nature, forces or causes beyond our reasonable control,
                                                including without limitation, Internet failures, computer,
                                                telecommunications or any other equipment failures, electrical power
                                                failures, strikes, labor disputes, riots, insurrections, civil
                                                disturbances, shortages of labor or materials, fires, flood, storms,
                                                explosions, acts of God, war, governmental actions, orders of domestic
                                                or foreign courts or tribunals or non-performance of third parties.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseEleven"
                                                    aria-expanded="false" aria-controls="collapseEleven">
                                                11.Intellectual Property Rights
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseEleven" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                11.1 Ezytrade.africa is the sole owner or lawful licensee of all the
                                                rights and interests in the Sites and the Site Content. The Sites and
                                                Site Content embody trade secrets and other intellectual property rights
                                                protected under worldwide copyright and other laws. All title, ownership
                                                and intellectual property rights in the Sites and Site Content shall
                                                remain with Ezytrade.africa, our affiliates or licensors, as the case
                                                may be. All rights not otherwise claimed under the Terms or by
                                                ezytrade.africa are hereby reserved
                                            </p>
                                            <p>
                                                11.2 "EZYTRADE AFRICA", "EZYTRADE.AFRICA", “EZYTRADEAFRICA.COM” and
                                                related icons and logos are registered trademarks or trademarks or
                                                service marks of Ezytrade Africa Limited; in the Relevant Jurisdictions,
                                                are registered trademarks or trademarks or service marks of Ezytrade
                                                Africa Limited and its affiliates, in various jurisdictions and are
                                                protected under applicable copyright, trademark and other proprietary
                                                rights laws. The unauthorized copying, modification, use or publication
                                                of these marks is strictly prohibited.
                                            </p>
                                            <p>
                                                11.3 Ezytrade.africa may have independent third parties involved in the
                                                provision of the Sites or Services (e.g., the authentication and
                                                verification service providers). You may not use any trademark, service
                                                mark or logo of such independent third parties without prior written
                                                approval from such parties.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapsetwelve"
                                                    aria-expanded="false" aria-controls="collapsetwelvee">
                                                12.Notices
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapsetwelve" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                12.1 All legal notices or demands to or upon Ezytrade.africa shall be
                                                made in writing and sent to Ezytrade.africa personally, by courier or
                                                certified mail to the following entity and address: EZYTRADE AFRICA
                                                LIMITED, 263 Chato Street, Mikocheni, Dar es Salaam – Tanzania. Attn:
                                                Legal Department. The notices shall be effective when they are received
                                                by Ezytrade Africa in any of the above-mentioned manne
                                            </p>
                                            <p>
                                                12.2 All legal notices or demands to or upon a User shall be effective
                                                if either delivered personally, sent by courier, certified mail, by
                                                facsimile or email to the last-known correspondence, fax or email
                                                address provided by the User to Ezytrade.africa, or by posting such
                                                notice or demand on an area of the Sites that is publicly accessible
                                                without a charge. Notice to a User shall be deemed to be received by
                                                such User if and when:
                                                <ul>
                                                    <li> a) Ezytrade.africa is able to demonstrate that communication,
                                                        whether in physical or electronic form, has been sent to such
                                                        User, or
                                                    </li>
                                                    <li> b) immediately upon Ezytrade.africa posting such notice on an
                                                        area of the Sites that is publicly accessible without charge
                                                    </li>
                                                </ul>
                                            </p>
                                            <p>
                                                12.3 You agree that all agreements, notices, demands, disclosures and
                                                other communications that Ezytrade.africa sends to you electronically
                                                will satisfy any legal requirement that such communication should be in
                                                writing.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingThree">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseThirteen"
                                                    aria-expanded="false" aria-controls="collapseThirteen">

                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseThirteen" className="collapse" aria-labelledby="headingThree"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>
                                                13.1 Subject to any Additional Agreements, the Terms constitute the
                                                entire agreement between you and Ezytrade.africa with respect to and
                                                govern your use of the Sites and Services, superseding any prior written
                                                or oral agreements in relation to the same subject matter herein.
                                            </p>
                                            <p>
                                                13.2 Ezytrade.africa and you are independent contractors, and no agency,
                                                partnership, joint venture, employee-employer or franchiser-franchisee
                                                relationship is intended or created by the Terms.
                                            </p>
                                            <p>
                                                13.3 If any provision of the Terms is held to be invalid or
                                                unenforceable, such provision shall be deleted and the remaining
                                                provisions shall remain valid and be enforced.
                                            </p>
                                            <p>
                                                13.4 Headings are for reference purposes only and in no way define,
                                                limit, construe or describe the scope or extent of such clause.
                                            </p>
                                            <p>
                                                13.5 Ezytrade.africa’s failure to enforce any right or failure to act
                                                with respect to any breach by you under the Terms will not constitute a
                                                waiver of that right nor a waiver of Ezytrade.africa’s right to act with
                                                respect to subsequent or similar breaches.
                                            </p>
                                            <p>
                                                13.6 Ezytrade.africa shall have the right to assign the Terms (including
                                                all of our rights, titles, benefits, interests, and obligations and
                                                duties in the Terms to any person or entity (including any affiliates of
                                                Ezytrade.africa). You may not assign, in whole or part, the Terms to any
                                                person or entity.
                                            </p>
                                            <p>
                                                13.7 THIS AGREEMENT SHALL BE GOVERNED BY THE LAWS OF THE UNITED REPUBLIC
                                                OF TANZANIA
                                            </p>
                                            <p>
                                                13.8 If you have any comments on the Services we provide to you, you may
                                                contact our customer service support line: +255 754 475 963 or by email:
                                                support@ezytrade.africa
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageTerms;
