import {DETAILS_VALUE} from "./ListingDetailActionTypes";

export default function  ListingDetailReducer(state=[],action) {
    switch(action.type){

        case DETAILS_VALUE:
            return action.payload
        default:
            return state;
    }
}
