import {BOOKING_APPROVE_VALUE} from "./ListingBookingActionTypes";

export default function  ListingBookingReducer(state=[],action) {
    switch(action.type){

        case BOOKING_APPROVE_VALUE:
            return action.payload
        default:
            return state;
    }
}
