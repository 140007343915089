// react
import React from "react";

// third-party
import PropTypes from "prop-types";

// application
import {Fi24Hours48Svg, FiFreeDelivery48Svg, FiPaymentSecurity48Svg, FiTag48Svg} from "../../svg";

export default function BlockFeatures(props) {
    const {layout} = props;
    return (

            <div className={`d-none d-md-block d-xl-block d-lg-block  block block-features block-features--layout--${layout}`}>

                <div className="container">
                    <div className="block-features__list">
                        <div className="block-features__item">
                            <div className="block-features__icon">
                                <Fi24Hours48Svg/>
                            </div>
                            <div className="block-features__content">
                                <div className="block-features__title">Support 24/7</div>
                                <div className="block-features__subtitle">Call us anytime</div>
                            </div>
                        </div>
                        <div className="block-features__divider"/>
                        <div className="block-features__item">
                            <div className="block-features__icon">
                                <FiFreeDelivery48Svg/>
                            </div>
                            <div className="block-features__content">
                                <div className="block-features__title">Convenient delivery</div>
                                <div className="block-features__subtitle">Flexible and Affordable</div>
                            </div>
                        </div>
                        <div className="block-features__divider"/>
                        <div className="block-features__item">
                            <div className="block-features__icon">
                                <FiPaymentSecurity48Svg/>
                            </div>
                            <div className="block-features__content">
                                <div className="block-features__title">100% Safety</div>
                                <div className="block-features__subtitle">Secure payments</div>
                            </div>
                        </div>
                        <div className="block-features__divider"/>
                        <div className="block-features__item">
                            <div className="block-features__icon">
                                <FiTag48Svg/>
                            </div>
                            <div className="block-features__content">
                                <div className="block-features__title">Hot Offers</div>
                                <div className="block-features__subtitle">Competitive prices</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




    );
}

BlockFeatures.propTypes = {
    layout: PropTypes.oneOf(["classic", "boxed"]),
};

BlockFeatures.defaultProps = {
    layout: "classic",
};
