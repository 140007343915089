import { BUY_PRODUCT, DELETE_PRODUCT } from "./buyActionTypes";
import Axios from "axios";
import { toast } from "react-toastify";
import { agenttoken, customertoken, agentcustomerToken } from "../../api/shop";
import { FetchRate } from "../RateData";
export function BuyNow(product, options = [], quantity = 1) {
  // console.log("mjkfgd product buy now",product);
  return {
    type: BUY_PRODUCT,
    product,
    options,
    quantity,
  };
}
export function Delete() {
  return {
    type: DELETE_PRODUCT,
  };
}
export function BuyDataFromDb() {
  const agentcustomer_Token = agentcustomerToken();
  const agentToken = agenttoken();
  return (dispatch) =>
    new Promise((resolve) => {
      setTimeout(() => {
        if (agentcustomer_Token !== null && agentToken !== null) {
          Axios.post(window.$name + "/api/buynow/get-product-buy-now", {
            user_token: agentcustomer_Token,
          })
            .then(function (response) {
              localStorage.setItem("BuynowData", JSON.stringify(response.data));
              window.location='/shop/checkout-Buynow'; 
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          Axios.post(window.$name + "/api/buynow/get-product-buy-now", {
            user_token: customertoken(),
          })
            .then(function (response) {
                // console.log(response.data);
              localStorage.setItem("BuynowData", JSON.stringify(response.data));
              window.location='/shop/checkout-Buynow'; 
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    });
}
export function ProductDetails(product, quantity,ReferralToken) {
  // console.log(product);
  const agentcustomer_Token = agentcustomerToken();
  const agentToken = agenttoken();
  return (dispatch) =>
    new Promise((resolve) => {
      setTimeout(() => {
        if (agentcustomer_Token !== null && agentToken !== null) {
          let data = {
            secure_token: agentcustomer_Token,
            product_token: product.product_token,
            outlet_token: product.outlets_token,
            preference_color: product.preference_color,
            preference_size: product.preference_size,
            shop_id: product.shop_id,
            quantity: quantity,
            seller_token: product.seller_token,
            product_detail: "",
            total_amount: product.price,
            currency: product.currency,
            vat: product.vat,
            referral_token:ReferralToken
          };
          Axios.post(window.$name + "/api/buynow/create-buy-now", data)
            .then(function (response) {
              if (response.status === 201) {               
                dispatch(BuyDataFromDb()); 
                dispatch(FetchRate(product.rate));                              
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          let data = {
            secure_token: customertoken(),
            product_token: product.product_token,
            outlet_token: product.outlets_token,
            preference_color: product.preference_color,
            preference_size: product.preference_size,
            shop_id: product.shop_id,
            quantity: quantity,
            seller_token: product.seller_token,
            product_detail: "",
            total_amount: product.price,
            currency: product.currency,
            vat: product.vat,
            referral_token:ReferralToken
          };
          Axios.post(window.$name + "/api/buynow/create-buy-now", data)
            .then(function (response) {
              if (response.status === 201) {
                // toast.success(`Product ${product.name} buy now!`);
               dispatch(BuyDataFromDb()); 
               dispatch(FetchRate(product.rate));
                
                // window.location='/shop/checkout-Buynow'; 
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    });

  }

