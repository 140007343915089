import {APPLIED_RATE_DATA} from "./AppliedQuoteActionTypes";

export function FetchAppliedRate(Rate) {
    return {
        type:APPLIED_RATE_DATA,
        Rate
    };
  }


