import {customertoken} from "../../api/shop";
import Axios from "axios";
import {DETAILS_VALUE} from "./ListingDetailActionTypes";
import {toast} from "react-toastify";

export function FetchListingDetail(Token) {
    // console.log(Token,"gdjd")
    return async (dispatch) =>
        Axios.get(`${window.$name}/api/directory/consumer/show/listings/details/${Token}`)
            .then(function (response) {
                dispatch({type: DETAILS_VALUE, payload: response.data});
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

export function DeleteBooking(booking_token) {
    return async (dispatch) =>
        Axios.get(`${window.$name}/api/directory/consumer/cancel/listings/booking/${booking_token}`)
            .then(function (response) {
                toast.success(`${response.data.message}`);
                // dispatch(FetchBooking());
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

export function AddListingData(value, postpaid, prepaid, service,booking) {
    const data = {
        listing_title: value.listing_title,
        listing_description: value.listing_description,
        listing_category_secure_token: value.category_token,
        listing_subcategory_secure_token: value.listing_subcategory_secure_token,
        listing_plan_secure_token: value.listing_plan_secure_token,
        listing_plan_price_secure_token: value.listing_plan_price_secure_token,
        address: value.address,
        zip_code: value.zip_code,
        country_id: value.country_id,
        region_id: value.region_id,
        district_id: value.district,
        listing_services: service,
        monday_opening: value.monday_opening,
        monday_closing: value.monday_closing,
        tuesday_opening: value.tuesday_opening,
        tuesday_closing: value.tuesday_closing,
        wednesday_opening: value.wednesday_opening,
        wednesday_closing: value.wednesday_closing,
        thursday_opening: value.thursday_opening,
        thursday_closing: value.thursday_closing,
        friday_opening: value.friday_opening,
        friday_closing: value.friday_closing,
        saturday_opening: value.saturday_opening,
        saturday_closing: value.saturday_closing,
        sunday_opening: value.sunday_opening,
        sunday_closing: value.sunday_closing,
        allow_booking: booking,
        allow_prepaid: prepaid,
        allow_postpaid: postpaid,
        video_url: value.video_url,
        slug: value.slug
    }
    // console.log(data)
    return async (dispatch) =>
    Axios.post(window.$name + `/api/directory/consumer/post/listings/${customertoken()}`, data)
        .then(function (response) {
            toast.success(`${response.data.message}`);
            // dispatch(FetchListing())
        })
        .catch(function (error) {
            if (error.response) {
                toast.error("something is wrong");
            }
        });

}
