// react
import React from 'react';
// third-party
import {Helmet} from 'react-helmet-async';
// application
// data stubs
import theme from '../../data/theme';
function SitePageTerms() {
    return (
        <div className="block about-us">
            <Helmet>
                <title>{`Term of Use — ${theme.name}`}</title>
            </Helmet>
            <div className="about-us__image" style={{backgroundImage: 'url("")'}}/>
            <div >
                <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                        <div className="about-us__body">
                            <h2 className="about-us__title">PRIVACY POLICY</h2>
                            <h5 className="d-flex justify-content-center">Part A: Effective as of January 6, 2020</h5>
                            <div>
                                www.ezytrade.africa (the "Platform") is a B2B platform which connects and facilitates
                                sales and purchases of products between suppliers (or "Sellers") and buyers (or
                                "Buyers"). The Platform is for business to business transactions and therefore is only
                                made available for use by businesses and not for individual or personal use. This
                                Privacy Policy sets out the ways in which ezytrade.africa collect, use and disclose
                                information in connection with its operation of the Platform including personal
                                information about Platform visitors and representatives and employees of Buyers and
                                Sellers ("you"). This Privacy Policy also applies to use of our Platform via a mobile
                                device, either through mobile applications or mobile-optimized websites. If you have any
                                questions or concerns about the use of your personal information, then please contact us
                            </div>
                            <div className="accordion" id="accordionExample">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left" type="button"
                                                    data-toggle="collapse" data-target="#collapseOne"
                                                    aria-expanded="true" aria-controls="collapseOne">
                                                A. COLLECTION OF INFORMATION
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>The personal information that we may collect about you broadly falls into
                                                the following categories:</p>
                                            <p>information you provide to us</p>
                                            <p>If you are a representative or employee of any Buyer or Seller or a sole
                                                trader Buyer or Seller:</p>
                                            <p>
                                                <ul>
                                                    <li>you will be asked to provide certain contact information that
                                                        is
                                                        necessary for the registering for a Platform account on behalf
                                                        of a
                                                        Buyer or Seller, including name, address, phone number, email
                                                        address,
                                                        job title and department;
                                                    </li>
                                                    <li>
                                                         you will be asked to provide certain identity information, tax
                                                        registration and/or related information about your business,
                                                        such as
                                                        your company name, business type and industry, corporate
                                                        registration
                                                        details and information about your business license;
                                                    </li>
                                                    <li>
                                                         if you are a Seller we will also ask you to provide details
                                                        about the
                                                        products that you intend to sell via the Platform and details
                                                        about your
                                                        sales/transactions on ezytradea.africa;
                                                    </li>
                                                    <li>
                                                         if you are a Buyer we will also ask you to provide details or
                                                        preferences about the goods and products that you may be
                                                        interested in
                                                        buying via the Platform and any details or preferences relevant
                                                        to your
                                                        purchases;
                                                    </li>
                                                    <li>
                                                         in connection with the facilitation of transactions, purchases
                                                        and
                                                        payments over the Platform, you will be asked to provide certain
                                                        payment
                                                        information. This might include bank account numbers, billing
                                                        and
                                                        delivery information, credit/debit card numbers, expiration
                                                        dates and
                                                        security code and tracking information from cheques or money
                                                        orders to
                                                        facilitate the sale and purchase as well as the settlement of
                                                        purchase
                                                        price of the products or services transacted on or procured
                                                        through the
                                                        Platform;
                                                    </li>
                                                    <li> we will process any information provided to us in connection
                                                        with
                                                        disputes or to support complaints in connection with the
                                                        Platform or
                                                        goods and/or services purchased via the Platform. Please note,
                                                        Buyers
                                                        and/or Sellers may submit complaints regarding the products and
                                                        services
                                                        on the Platform and/or disputes between the Buyers and Sellers
                                                        to the
                                                        Platform and under such circumstances, we will process data
                                                        provided to
                                                        us by Buyers and/or Sellers that is relevant to the disputes or
                                                        complaints submitted. This may include health data of end-users
                                                        (which
                                                        may include minors as applicable) of products purchased on the
                                                        Platform
                                                        to the extent relevant to the disputes or complaints that are
                                                        being
                                                        reviewed by the Platform and to the extent that the
                                                        individual(s) has
                                                        provided their explicit consent to their data being shared with
                                                        us for
                                                        the purposes of handling the complaints and/or settling the
                                                        disputes.
                                                    </li>
                                                    <li>
                                                         Information you submit to the Platform for publication may be
                                                        made
                                                        publicly available on the Platform and therefore accessible by
                                                        any
                                                        internet user. You should exercise caution when deciding what
                                                        information you submit to us for publication.
                                                    </li>
                                                </ul>
                                            </p>
                                            <p>Information that we collect automatically</p>
                                            <p>
                                                <ul>
                                                    <li>
                                                         If you contact us (such as via our customer services
                                                        department chat
                                                        service, telephone or email), we may record a copy of your
                                                        correspondence and may ask for additional information to verify
                                                        your
                                                        identity.
                                                    </li>
                                                    <li>
                                                         We will collect contact details and other information of users
                                                        from
                                                        publicly available sources for the purposes of verifying the
                                                        users and
                                                        performing due diligence on the users.
                                                    </li>
                                                    <li>
                                                         We will collect details of user activities, transactions and
                                                        interactions on the Platform including information relating to
                                                        the types
                                                        and specifications of the products and services purchased,
                                                        pricing and
                                                        delivery information, dispute and complaint records,
                                                        communications
                                                        between users and any information disclosed in any discussion
                                                        forum.
                                                    </li>
                                                    <li>
                                                         From time to time, we may also collect information about the
                                                        existing
                                                        and prospective users, during or in connection with trade shows,
                                                        industry events and/or other functions. Including representative
                                                        contact
                                                        names, addresses, phone numbers, fax numbers and email
                                                        addresses.
                                                    </li>
                                                    <li>
                                                         If you visit our Platform, we will automatically collect
                                                        certain
                                                        information from your device. In some countries, including
                                                        countries in
                                                        the European Economic Area, this information may be considered
                                                        as
                                                        personal information under applicable data protection laws.
                                                        Specifically
                                                        this information may include IP addresses, device type, unique
                                                        device
                                                        identification numbers, browser type, broad geographic location
                                                        (e.g.
                                                        country or city-level location), browsing patterns and details
                                                        of how
                                                        you have interacted with our Platform and the goods and services
                                                        available on it. In addition, we gather statistical information
                                                        about
                                                        the Platform and visitors to the Platform including, browser
                                                        software,
                                                        operating system, software and hardware attributes, pages
                                                        viewed, number
                                                        of sessions and unique visitors. Collecting this information
                                                        enables us
                                                        to better understand the visitors who come to our Platform,
                                                        where they
                                                        come from, and what content is of interest to them. We use this
                                                        information for our internal analytics purposes and to improve
                                                        the
                                                        quality and relevance of our Platform. Some of this information
                                                        may be
                                                        collected using cookies and similar tracking technology.
                                                    </li>
                                                </ul>
                                            </p>
                                            <p>
                                                Information that we receive from third parties
                                            </p>
                                            <p>
                                                 We may receive personal information about you from social media
                                                platforms if you choose to register for a Platform account via a social
                                                media account. Exactly what information we receive will depend on your
                                                privacy settings with the applicable platform, but it would typically
                                                include basic public profile information such as
                                            </p>
                                            <p>
                                                <ul>
                                                    <li> Your user name or nickname</li>
                                                    <li> Your profile picture</li>
                                                    <li>  Country</li>
                                                    <li> Company name</li>
                                                    <li>  Contact details</li>
                                                </ul>
                                            </p>
                                            <p>We may receive personal information about you from third parties that are
                                                engaged by us to assist with providing verification services and
                                                conducting suitable money laundering and KYC (know-your-customer) checks
                                                on users. This may include your name, email address, company details and
                                                contact information</p>
                                            <p>
                                                <ul>
                                                    <li>• If you choose to participate in reviewing or rating products
                                                        and/or services on ezytrade,africa, we may receive personal
                                                        information about you from third parties that work with us to
                                                        conduct reviews into our services and/or rate us
                                                    </li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>


                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <h2 className="mb-0">
                                            <button className="btn btn-link btn-block text-left collapsed" type="button"
                                                    data-toggle="collapse" data-target="#collapseTwo"
                                                    aria-expanded="false" aria-controls="collapseTwo">
                                                B. USE OF PERSONAL INFORMATION
                                            </button>
                                        </h2>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo"
                                         data-parent="#accordionExample">
                                        <div className="card-body">
                                            <p>We collect and use your personal information for the following
                                                purposes:</p>
                                            <p>
                                                <ul>
                                                    <li>  setting up / administering user accounts, including providing
                                                        log-in IDs and developing a Platform profile; • verifying your
                                                        identity and conducting security, KYC (know-your-customer) and
                                                        anti-money laundering diligence and background checks in order
                                                        to
                                                        set up user accounts
                                                    </li>
                                                    <li> verifying your eligibility to register as a Platform user in
                                                        accordance with our Terms of Use;
                                                    </li>
                                                    <li> providing users with customer service support; responding to
                                                        queries, feedback, and managing claims and/or disputes;
                                                    </li>
                                                    <li> facilitating communication between Buyers and Sellers via the
                                                        Platform, processing transactions and settlement between Buyers
                                                        and
                                                        Sellers made over the Platform, assessing fund withdrawal
                                                        requests
                                                        from Sellers;
                                                    </li>
                                                    <li> providing logistics and warehousing services (including,
                                                        without
                                                        limitation, customs clearance declarations via the Platform);
                                                    </li>
                                                    <li> • facilitating customs clearance declarations / applications
                                                        via the
                                                        Platform;
                                                    </li>
                                                    <li>assessing and monitoring account security and transaction
                                                        risks of
                                                        users of ezytrade.africa, detecting and preventing fraud, money
                                                        laundering and other security incidents;
                                                    </li>
                                                    <li>personalizing our marketing and communications with you based
                                                        on
                                                        your browsing records, and your order history;
                                                    </li>
                                                    <li>performing research or statistical analysis in order to
                                                        improve
                                                        the content and layout of the Platform, and to improve the
                                                        product
                                                        offerings and services on ezytrade.com, including, for example,
                                                        using anonymized data for machine learning purposes;
                                                    </li>
                                                    <li>identifying, developing and marketing and advertising
                                                        products and
                                                        services that we believe you will value, including across
                                                        browsers
                                                        and devices, in accordance with applicable laws. Cookies or
                                                        other
                                                        similar technologies may be used to provide you with advertising
                                                        based upon your browsing activities and interests. Where we are
                                                        required by applicable law, we will seek your consent prior to
                                                        sending you communications for marketing purposes;
                                                        We may also use your personal information for other purposes
                                                        that
                                                        are not incompatible with the purposes we have disclosed to you
                                                        (such as archiving purposes in the public interest, scientific
                                                        or
                                                        historical research purposes, or statistical purposes) if and
                                                        where
                                                        this is permitted by applicable data protection laws.
                                                    </li>
                                                </ul>
                                            </p>

                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link btn-block text-left collapsed"
                                                        type="button"
                                                        data-toggle="collapse" data-target="#collapseThree"
                                                        aria-expanded="false" aria-controls="collapseThree">
                                                    C. DISCLOSURE OR SHARING OF PERSONAL INFORMATION
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseThree" className="collapse" aria-labelledby="headingThree"
                                             data-parent="#accordionExample">
                                            <div className="card-body">
                                                <p>We may disclose (or provide access to) personal information to the
                                                    following categories of recipients:</p>
                                                <p>
                                                    <ul>
                                                        <li> Other Platform Users where necessary in connection with
                                                            the
                                                            facilitation of purchases and communications made between
                                                            you and
                                                            the other users.
                                                        </li>
                                                        <li> Third party business partners, service providers and/or
                                                            affiliates
                                                            of Ezytrade.africa engaged by us or working with us to
                                                            assist us to
                                                            provide services to you or who otherwise process personal
                                                            information for purposes described in this Privacy Policy or
                                                            notified to you when we collect your personal information.
                                                            Categories of these partners or service providers include:
                                                            1.
                                                            Members of EZYTRADE AFRICA and their respective affiliates
                                                            and/or
                                                            their designated service providers that work with us to
                                                            provide
                                                            processing services such as software, tools, systems and
                                                            messaging
                                                            services for purposes described in this Privacy Policy; our
                                                            business
                                                            partners (for example, in order for them to provide you with
                                                            discounts or offers that may interest you); marketing and
                                                            advertising platforms, such as Google, Twitter, Facebook and
                                                            Instagram, and providers of analytics services relating to
                                                            users’
                                                            behavior, in order to tailor the content you see when
                                                            visiting our
                                                            Platform. These platforms may combine information they
                                                            collect on
                                                            our Platform with data on their platforms and data they
                                                            collect from
                                                            other websites or through other sources in order to conduct
                                                            targeted
                                                            advertising. The processing activities of these third party
                                                            marketing platforms are governed by their own privacy
                                                            policies, not
                                                            this Privacy Policy; payment service providers who collect
                                                            and
                                                            process personal information to assist with settling the
                                                            payments
                                                            for transactions or process withdrawal requests for Sellers.
                                                            The
                                                            activities of payment service providers may be governed by
                                                            their own
                                                            privacy policies, not this Privacy Policy; credit risk
                                                            assessment
                                                            providers to conduct risk assessment on Sellers to determine
                                                            whether
                                                            a Seller can be permitted to make a fund withdrawal;
                                                            logistics
                                                            partners for providing delivery services for Buyers,
                                                            including
                                                            return and exchange of products, and warehousing services
                                                            for
                                                            Sellers; custom agents for customs clearances purposes;
                                                            cloud
                                                            computing service providers to provide cloud storage
                                                            services;
                                                            customer service providers to provide after-sale services;
                                                            risk
                                                            control service providers to assess the security of users’
                                                            accounts
                                                            and transaction risks; and third party rating / reviewing
                                                            service
                                                            providers to carry out reviews of our services with
                                                            customers if you
                                                            choose to participate in reviewing or rating EZYTRADE
                                                            products
                                                            and/or services
                                                        </li>
                                                        <li>To our professional advisers, law enforcement agencies,
                                                            insurers,
                                                            government and regulatory and other organizations where we
                                                            believe
                                                            it is necessary to comply with applicable laws or to
                                                            exercise,
                                                            establish or defend our legal rights or protect your vital
                                                            interests
                                                            or those of any other person, we may also disclose and
                                                            transfer your
                                                            personal information or as otherwise required or permitted
                                                            by
                                                            applicable laws.
                                                        </li>
                                                        <li>To an actual or potential buyer (and its agents and
                                                            advisers) in
                                                            connection with any actual or proposed purchase, merger or
                                                            acquisition of any part of our business, provided that we
                                                            inform the
                                                            buyer it must use your personal information only for the
                                                            purposes
                                                            disclosed in this Privacy Policy.
                                                        </li>
                                                        <li>To any other person with your consent to the disclosure.
                                                            We may
                                                            provide aggregated or anonymized data to third parties, but
                                                            when we
                                                            do so, the information we share is in a de-identified format
                                                            that
                                                            does not personally identify you.
                                                        </li>
                                                    </ul>

                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link btn-block text-left collapsed"
                                                        type="button"
                                                        data-toggle="collapse" data-target="#collapseFour"
                                                        aria-expanded="false" aria-controls="collapseFour">
                                                    D. RETENTION
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseFour" className="collapse" aria-labelledby="headingThree"
                                             data-parent="#accordionExample">
                                            <div className="card-body">
                                                <p>We retain your personal information as long as we have an ongoing
                                                    legitimate business need to do so for example to provide services or
                                                    products to you, or as required or permitted by applicable laws,
                                                    such as tax and accounting laws. When we have no ongoing legitimate
                                                    business need to process your personal information, we will either
                                                    delete or anonymize it or, if this is not possible (for example,
                                                    because your personal information has been stored in backup
                                                    archives), then we will securely store your personal information and
                                                    isolate it from any further processing until deletion is
                                                    possible.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link btn-block text-left collapsed"
                                                        type="button"
                                                        data-toggle="collapse" data-target="#collapseFive"
                                                        aria-expanded="false" aria-controls="collapseFive">
                                                    E. COOKIES
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseFive" className="collapse" aria-labelledby="headingThree"
                                             data-parent="#accordionExample">
                                            <div className="card-body">
                                                <p>This section explains how we use cookies and similar technologies
                                                    (we'll refer to them collectively as "cookies") to recognize you
                                                    when you visit our Platform. It explains what these technologies are
                                                    and why we use them, as well as your rights to control them.</p>
                                                <p>What are cookies?</p>
                                                <p>A cookie is a small amount of data that is sent to your browser and
                                                    stored on your computer's or mobile device’s hard drive. If you do
                                                    not de-activate or erase the cookie, each time you use the same
                                                    browser or mobile device to access the Platform, our web servers
                                                    will be notified of your visit to the Platform and in turn we may
                                                    have knowledge of your visit and the pattern of your usage.</p>
                                                <p>Cookies set by the website or app owner (in this case,
                                                    Ezytrade,africa) are called "first party cookies". Cookies set by
                                                    parties other than the website owner are called "third party
                                                    cookies". Third party cookies enable third party features or
                                                    functionality to be provided on or through the website (e.g. like
                                                    advertising, interactive content and analytics). The parties that
                                                    set these third party cookies can recognize your device both when it
                                                    visits the website in question and also when it visits certain other
                                                    websites.
                                                </p>
                                                <p>Cookies can also be categorized as session cookies (which are erased
                                                    once you close the browser or shut down the device) and persistent
                                                    cookies (which remain even after closing the browser or shutting
                                                    down the device/application and which are activated each time you
                                                    visit the Platform). The session cookies use encrypted data to
                                                    authenticate you. The persistent cookies do not store account
                                                    numbers or passwords. We use both session cookies and persistent
                                                    cookies..</p>
                                                <p>Why we use cookies?</p>
                                                <p>We use first and third party cookies for several reasons. Some
                                                    cookies are required for technical and/or security reasons in order
                                                    for our Platform to operate, and we refer to these as "essential" or
                                                    "strictly necessary" cookies. First party cookies are mainly served
                                                    to enable basic functionality on our Platform; including web
                                                    analytics cookies that help us understand how users use our web
                                                    pages and how we can improve their use. For example we use essential
                                                    or strictly necessary cookies to enable us to remember goods added
                                                    to your ezytrade.africa shopping basket and to recognize you when
                                                    you sign into your ezytrade.africa account.

                                                    In addition we also use social media cookies. You may register for
                                                    Platform membership and "share" Platform content through social
                                                    media such as Facebook and Twitter. Sometimes we embed videos from
                                                    websites like YouTube. These websites have their own privacy
                                                    policies which govern their use of information, which you can find
                                                    on the respective websites.</p>
                                                <p>We also use cookies to enable us to track and target the interests of
                                                    Platform visitors to enhance the experience on our Platform by
                                                    researching visiting patterns and conduct interest-based
                                                    advertising; assisting our partners to track user visits to the
                                                    Platforms; and to track progress and participation in promotions. We
                                                    use first and third party cookies for these purposes</p>
                                                <p>Cookies we use</p>
                                                <p>The specific categories of first and third party cookies served
                                                    through our Platform and the purposes they perform are described in
                                                    the table below (please note that the specific cookies served may
                                                    vary):</p>
                                                <p>
                                                    <table className="table table-bordered">
                                                        <thead className="thead-dark">
                                                        <tr>
                                                            <th>Who serves these cookies</th>
                                                            <th>Types of cookie</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <td><a
                                                                href="https://www.ezytrade.africa/">Ezytrade.africa</a>
                                                            </td>
                                                            <td>Essential Cookies These cookies are strictly necessary
                                                                for enabling access and use of our Platform. They
                                                                provide necessary functionality to ensure the proper
                                                                performance, security and functionality of our Platform.
                                                                These cookies cannot be disabled.
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>Statistical Cookies: Statistical cookies including
                                                                analytics cookies collect information in aggregate form
                                                                to help us determine and understand how users interact
                                                                with and use our Platform. We use this information to
                                                                help us to improve and optimize our Platform and its
                                                                security.
                                                            </td>
                                                            <td><a
                                                                href="https://www.ezytrade.africa/">Ezytrade.africa </a> Google
                                                                Analytics (please see below for further information) •
                                                                <a href="https://policies.google.com/privacy ">google
                                                                    privacy</a> •and
                                                                <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">cookie
                                                                    usage</a>
                                                            </td>

                                                        </tr>
                                                        <tr>
                                                            <td><a
                                                                href="https://www.ezytrade.africa/">Ezytrade.africa </a>
                                                            </td>
                                                            <td>Personalisation and Service Cookies: These
                                                                Personalisation and Service Cookies: These cookies are
                                                                used to remember your computer or mobile device when you
                                                                visit a website. They remember your registration and
                                                                login details and your settings and preferences such as
                                                                language and region. They are also used to ensure you
                                                                don’t have to login every time you use your browser to
                                                                comment on articles and to ensure interactive services
                                                                work effectively. Personalization cookies are also used
                                                                to recommend content we think you'll be interested in by
                                                                serving and measuring relevant advertising, based on
                                                                what you've looked at before. Personalization cookies
                                                                may also include social media cookies which are used to
                                                                enable you to log into our Platform using your social
                                                                media accounts and to share pages and content that you
                                                                find interesting on our Platform through third party
                                                                social networking and other websites. These cookies may
                                                                also be used for advertising purposes too.
                                                            </td>


                                                        </tr>
                                                        <tr>
                                                            <td><a href="http://www.facebook.com">Facebook</a>
                                                                <a href="http://www.twitter.com Google http://www.google.com ">Twitter</a>
                                                            </td>
                                                            <td>Marketing Cookies. Marketing cookies help us to ensure
                                                                that provide you with more targeted and relevant
                                                                marketing. We will also use marketing cookies to
                                                                evaluate the effectiveness of our marketing
                                                                communications by monitoring open rate and conversions.
                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </p>
                                                <p>What about other tracking technologies, like web beacons?</p>
                                                <p>Cookies are not the only way to recognise or track visitors to our
                                                    Platform. We may use other, similar technologies from time to time,
                                                    like web beacons (sometimes called "tracking pixels" or "clear
                                                    gifs"). These are tiny graphics files that contain a unique
                                                    identifier that enable us to recognise when someone has visited our
                                                    Platform or opened an e-mail that we have sent them. This allows us,
                                                    for example, to monitor the traffic patterns of users from one page
                                                    within our Platform to another, to deliver or communicate with
                                                    cookies, to understand whether you have come to our Platform from an
                                                    online advertisement displayed on a third-party website, to improve
                                                    site performance, and to measure the success of e-mail marketing
                                                    campaigns. In many instances, these technologies are reliant on
                                                    cookies to function properly, and so declining cookies will impair
                                                    their functioning.</p>
                                                <p>How can I control cookies?</p>
                                                <p>You can determine if and how a cookie will be accepted by configuring
                                                    the privacy setting of the browser you are using to access the
                                                    Platform or the "privacy setting" of your device. As the means by
                                                    which you can refuse cookies through your web browser controls vary
                                                    from browser-to-browser, you should visit your browser's help menu
                                                    for more information. If you adjust the privacy setting in the
                                                    browser, your device will continue collecting data unless you adjust
                                                    the privacy setting of the device, and vice versa.
                                                    You have the right to decide whether to accept or reject cookies. If
                                                    you choose to set your web browser controls to reject cookies, you
                                                    may still use our website though your access to some functionality
                                                    and areas of our website may be restricted.</p>
                                                <p>Certain features of the Platform depend on cookies. Please be aware
                                                    that if you choose to block cookies, you may not be able to sign in
                                                    or use those features, and preferences that are dependent on cookies
                                                    may be lost.</p>
                                                <p>Google Analytics (More information) Our Platform uses Google
                                                    Analytics, an internet analytics service provided by Google, Inc.
                                                    ("Google"). Google's cookies allow us analyze use of the Platform by
                                                    telling us which pages our users are viewing, which ones are most
                                                    popular, what time of day our Platform are visited, if users have
                                                    previously visited our Platform, from which website users are
                                                    redirected to our Platform were and the like. The data generated by
                                                    the cookie about your use of the Platform will be transmitted to
                                                    Google and stored by Google on servers in the United States.</p>
                                                <p>The Platform uses an anonymization tool of IP addresses so that the
                                                    IP addresses are truncated within the territory of the Member States
                                                    of the European Union and the other members of the agreement on the
                                                    European Economic Area. The non-truncated IP address are only
                                                    exceptionally transmitted to Google’s servers in the US and then
                                                    truncated there.</p>
                                                <p>European Economic Area. The non-truncated IP address are only
                                                    exceptionally transmitted to Google’s servers in the US and then
                                                    truncated there.
                                                    Google uses the data on behalf of ezytrade to evaluate your use of
                                                    the Platform, to compile reports on website activity for the website
                                                    operators and for other website activity and internet usage
                                                    services. Google may disclose this information to third parties
                                                    under the terms of the law or for further processing by third
                                                    parties on behalf of Google. For more information about Google
                                                    Analytics cookies, visit:
                                                    o the Google Help pages and Privacy Policy: :
                                                    <a href="https://policies.google.com/privacy">Google Privacy
                                                        Policy</a>;
                                                    <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">and
                                                        or the Google Analytics Help
                                                        Center:</a></p>
                                                <p>The IP address that your browser will pass on to Google Analytics
                                                    will not be associated with any other data stored by Google. Through
                                                    your browser settings, you can block cookies; however, this can lead
                                                    to problems with the use of some functionalities of the website.
                                                    Through the add-on "Google Analytics Opt-out" you can through your
                                                    current web browser opt-out to the use of  <a href="http://tools.google.com/dlpage/gaoptout?hl=en.">Google Analytics</a> from then
                                                    on:  : <a href="https://support.google.com/analytics/answer/6004245">More information can be found here</a> </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link btn-block text-left collapsed"
                                                        type="button"
                                                        data-toggle="collapse" data-target="#collapsesix"
                                                        aria-expanded="false" aria-controls="collapsesix">
                                                    F. CHANGES TO THIS PRIVACY POLICY
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapsesix" className="collapse" aria-labelledby="headingThree"
                                             data-parent="#accordionExample">
                                            <div className="card-body">
                                                <p>We may update this Privacy Policy from time to time in response to
                                                    changing legal, technical or business developments. When we update
                                                    our Privacy Policy, we will take appropriate measures to inform you,
                                                    consistent with the significance of the changes we make. We will
                                                    obtain your consent to any material Privacy Policy changes if and
                                                    where this is required by applicable data protection laws. Any
                                                    changes to this Privacy Policy will be communicated by us posting an
                                                    amended Privacy Policy on the Platform. Once posted on the Platform,
                                                    the new Privacy Policy will be effective immediately. You can see
                                                    when this Privacy Notice was last updated by checking the “last
                                                    updated” date displayed at the top of this Privacy Notice</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link btn-block text-left collapsed"
                                                        type="button"
                                                        data-toggle="collapse" data-target="#collapseSeven"
                                                        aria-expanded="false" aria-controls="collapseSeven">
                                                    G. VISITORS FROM THE EUROPEAN UNION
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseSeven" className="collapse" aria-labelledby="headingThree"
                                             data-parent="#accordionExample">
                                            <div className="card-body">
                                                <p>This section of the Privacy Policy applies only if you use our
                                                    Platform or services covered by this Privacy Policy from a country
                                                    that is a Member State of the European Union and in the event of and
                                                    from the date of the UK's departure from the European Union, the
                                                    United Kingdom (the "EEA"), and supplements the information in this
                                                    Privacy Policy.</p>
                                                <p>Legal basis for data processing</p>
                                                <p>We process personal information for the purposes set out in this
                                                    Privacy Policy, as described above. Our legal basis for processing
                                                    personal information will depend on the personal information
                                                    concerned and the specific context in which we collect it. However,
                                                    we will normally collect and process personal information about you
                                                    only where it is:</p>
                                                <p>
                                                    <ul>
                                                        <li>necessary for providing the services pursuant to the
                                                            agreement
                                                            between you and EZYTRADE AFRICA LTD and/or its affiliates
                                                            (for
                                                            example, to provide you with the services you request and to
                                                            identify and authenticate you so you may use the Platform);
                                                        </li>
                                                        <li>necessary to comply with legal requirements (for example,
                                                            to
                                                            comply with applicable accounting rules and to make
                                                            mandatory
                                                            disclosures to law enforcement);
                                                        </li>
                                                        <li>necessary for our legitimate interests and not overridden
                                                            by
                                                            your rights; and/or
                                                        </li>
                                                        <li>where it is based on your consent.
                                                            If we collect and use your personal information in reliance
                                                            on
                                                            our legitimate interests (or those of any third party), this
                                                            interest will normally be to operate our Platform and
                                                            services,
                                                            manage our relationship with you and communicate with you as
                                                            necessary to provide our services to you and for our
                                                            legitimate
                                                            commercial interest, for instance, when responding to your
                                                            queries, improving our Platform and our services,
                                                            undertaking
                                                            marketing, or for the purposes of ensuring the security of
                                                            our
                                                            Platform and services and detecting or preventing illegal
                                                            activities such as fraud. We may have other legitimate
                                                            interests
                                                            and if appropriate we will make clear to you at the relevant
                                                            time what those legitimate interests are. If we ask you to
                                                            provide personal information to comply with a legal
                                                            requirement
                                                            or to enter into a contract with you, we will make this
                                                            clear at
                                                            the relevant time. We will advise you whether the provision
                                                            of
                                                            your personal information is mandatory or not (as well as of
                                                            the
                                                            possible consequences if you do not provide your personal
                                                            information). In some instances, you may be required to
                                                            provide
                                                            us with personal information for processing as described
                                                            above,
                                                            in order for us to be able to provide you all of our
                                                            services,
                                                            and for you to use all the features of our Platform. If you
                                                            have
                                                            questions about or need further information concerning the
                                                            legal
                                                            basis on which we collect and use your personal information,
                                                            please contact us.
                                                            <p> International transfers of personal information</p>
                                                            In connection with our provision of the Platform and its
                                                            connected services, we may transfer your personal
                                                            information to
                                                            countries outside of the EEA, including to countries that
                                                            may
                                                            not provide the same level of data protection as your home
                                                            country such as the United States and China. We take
                                                            appropriate
                                                            steps to ensure that recipients of your personal information
                                                            are
                                                            bound to duties of confidentiality and we implement
                                                            appropriate
                                                            measures to ensure your personal information will remain
                                                            protected in accordance with this Privacy Policy.
                                                        </li>
                                                    </ul>
                                                </p>
                                                <p>Your rights</p>
                                                <p>If you are a resident of the EEA, you also have the following data
                                                    protection rights below, which you can exercise at any time by
                                                    contacting us:</p>
                                                <p>
                                                    <ul>
                                                        <li>The right to access, correct, update or request deletion
                                                            of your
                                                            personal information.
                                                        </li>
                                                        <li>The right to object to processing of your personal
                                                            information
                                                            when it is based on our legitimate interests, and separately
                                                            the
                                                            right to object to direct marketing.
                                                        </li>
                                                        <li>The right to ask us, in some situations, to restrict
                                                            processing of
                                                            your personal information or request portability of your
                                                            personal
                                                            information.
                                                        </li>
                                                        <li>The right to opt-out of marketing communications we send
                                                            you at
                                                            any time. You can exercise this right by clicking on the
                                                            “unsubscribe” or “opt-out” link in the marketing e-mails we
                                                            send
                                                            you. To opt-out of other forms of marketing (such as postal
                                                            marketing or telemarketing), then please contact us using
                                                            the
                                                            contact details provided.
                                                        </li>
                                                        <li>If we have collected and process your personal information
                                                            with
                                                            your consent, then you have the right to withdraw your
                                                            consent at
                                                            any time. Withdrawing your consent will not affect the
                                                            lawfulness of
                                                            any processing we conducted prior to your withdrawal, nor
                                                            will it
                                                            affect processing of your personal information conducted in
                                                            reliance
                                                            on lawful processing grounds other than consent.
                                                        </li>
                                                        <li>The right to complain to a data protection authority
                                                            about our
                                                            collection and use of your personal information. For more
                                                            information, please contact your local data protection
                                                            authority.
                                                            We respond to all requests we receive from individuals
                                                            wishing to
                                                            exercise their data protection rights in accordance with
                                                            applicable
                                                            data protection laws. If you are aware of changes or
                                                            inaccuracies in
                                                            your information, you should inform us of such changes so
                                                            that our
                                                            records may be updated or corrected.
                                                        </li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link btn-block text-left collapsed"
                                                        type="button"
                                                        data-toggle="collapse" data-target="#collapseEight"
                                                        aria-expanded="false" aria-controls="collapseEight">
                                                    H. HOW TO CONTACT US
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseEight" className="collapse" aria-labelledby="headingThree"
                                             data-parent="#accordionExample">
                                            <div className="card-body">
                                                <p>If you have any requests, questions or concerns about our use of your
                                                    personal information and this Privacy Policy, please contact us at
                                                    support@ezytrade.africa.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-header" id="headingThree">
                                            <h2 className="mb-0">
                                                <button className="btn btn-link btn-block text-left collapsed"
                                                        type="button"
                                                        data-toggle="collapse" data-target="#collapseNine"
                                                        aria-expanded="false" aria-controls="collapseNine">
                                                    I. LANGUAGE
                                                </button>
                                            </h2>
                                        </div>
                                        <div id="collapseNine" className="collapse" aria-labelledby="headingThree"
                                             data-parent="#accordionExample">
                                            <div className="card-body">
                                                <p>If there is any conflict between the English version and another
                                                    language version of this Privacy Policy, the English version shall
                                                    prevail.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SitePageTerms;
