// react
import React, {useState} from "react";

// third-party
import {Helmet} from "react-helmet-async";

// application
import PageHeader from "../shared/PageHeader";

// data stubs
import theme from "../../data/theme";
import StepForm from "./StepForm";
import Form from './registration';
import {Row} from "reactstrap";
import IsCompany from "./isCompanyAgent";

function ShopPageTrackOrder() {
    const breadcrumb = [
        {title: "Home", url: ""},
        {title: "Agent registration", url: ""},
    ];

    const [isCompany, setIsCompany] = useState(null);
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Agent registration — ${theme.name}`}</title>
            </Helmet>

            <PageHeader breadcrumb={breadcrumb}/>

            <div className="block">
                <div className="container">
                    {/* <div className="row justify-content-center"> */}
                    {/* <div className="col-xl-5 col-lg-6 col-md-8"> */}
                    <div className="card">
                        <div className="card-body">
                            <div className="card-title text-center">
                                <h3 className="pt-lg-0 pt-2">AGENT APPLICATION FORM</h3>
                            </div>
                            {isCompany ===null?
                                <Row>
                                    <p className="container">
                                        Did you want to open agent account as a organization or individual ?
                                        &nbsp; &nbsp;
                                        Organization &nbsp;
                                        <input
                                            type="radio"
                                            name="choose"
                                            value="choose"
                                            style={{height: '25px', width: '25px', verticalAlign: 'middle'}}
                                            onChange={() => setIsCompany(true)}
                                        /> &nbsp;   &nbsp;
                                        OR
                                        &nbsp;
                                        Individual &nbsp; &nbsp;
                                        <input
                                            type="radio"
                                            name="choose"
                                            value="choose"
                                            style={{height: '25px', width: '25px', verticalAlign: 'middle'}}
                                            onChange={() => setIsCompany(false)}
                                        />
                                    </p>
                                </Row>
                                :null
                            }
                          {isCompany===true?
                                <div>
                                   <IsCompany/>
                                </div>
                                :isCompany===false?
                                <Form/>
                                :
                                    null
                            }

                        </div>
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                </div>
            </div>
        </React.Fragment>
    );
}

export default ShopPageTrackOrder;
