import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import theme from "../../../data/theme";
import {connect, useDispatch} from "react-redux";
import {Pagination, Search} from "../../DataTable";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import Axios from "axios";
import {FetchListing} from "../../../store/Listings";
import {FetchDeleteListing} from "../../../store/DeleteLitsting";
import * as yup from "yup";
import axios from "axios";
import {useFormik} from "formik";
import {toast} from "react-toastify";
import {DropzoneArea} from "material-ui-dropzone";

const validationSchema = yup.object().shape({
    product_name: yup
        .string()
        .required("This field is required"),
    product_cost: yup
        .string()
        .required("This field is required"),
    cost_currency: yup
        .string()
        .required("This field is required"),
    product_price: yup
        .string()
        .required("This field is required"),
    price_currency: yup
        .string()
        .required("This field is required"),
    service_category: yup
        .string()
        .required("This field is required"),
    service_subcategory: yup
        .string()
        .required("This field is required"),
    schedule_time: yup
        .string()
        .required("This field is required"),
    service_description: yup
        .string()
        .required("This field is required"),
});

function AvailableListing(props) {
    const dispatch = useDispatch();
    const {ListingValue} = props
    const [ListingId, setListingId] = useState("")
    const [ListintToken, setListingToken] = useState("")
    const [state, setState] = useState({
        listing_plan_secure_token: "",
        listing_plan_price_secure_token: "",
    });
    const [plan, setPlan] = useState([]);
    const [imageFiles, setImages] = useState([]);


    function handleImages(files) {
        setImages(files);
    }

    const [category, setCategory] = useState([]);
    const [currencies, setCurrency] = useState([]);
    useEffect(() => {
        const fetchCategory = async () => {
            const responseData = await axios
                .get(window.$name + "/api/directory/get/listings/categories")
                .then((response) => response.data.listing_categories);
            setCategory(responseData);
        };
        const fetchCurrency = async () => {
            const currency = await axios
                .get(window.$name + "/api/adminstrator/get-currency")
                .then((response) => response.data);
            setCurrency(currency);
        };
        fetchCategory();
        fetchCurrency();
    }, []);

    const [subcategory, setSubCategory] = useState([]);
    const GetSub = (e) => {
        const {id, value} = e.target;
        formik.values.category_token = e.target.value;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
        axios
            .get(window.$name + `/api/directory/get/listings/subcategories/${e.target.value}`)
            .then((res) => {
                setSubCategory(res.data.listing_subcategories);
            });
    };

    useEffect(() => {
        const fetchData = async () => {
            const results = await axios
                .get(window.$name + "/api/directory/get/listings/plan")
                .then((response) => response.data.listing_plans);
            setPlan(results);
        };
        fetchData();
    }, []);

    const [planPrice, setPlanPrice] = useState([]);
    const GetPlanPrice = (e) => {
        const {id, value} = e.target;
        formik.values.listing_plan_secure_token = e.target.value;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
        axios
            .get(window.$name + `/api/directory/get/listings/plan/price/${e.target.value}`)
            .then((res) => {
                setPlanPrice(res.data.plan_price);
            });
    };

    function AddListingServices(id) {


        props.history.push(`/add_listing_service/${id}`);
        // setAnchorEl(null);
    }


    function DeleteListing() {
        Axios.post(`${window.$name}/api/directory/consumer/delete/listings/${ListingId}`)
            .then((response) => response.data);
        dispatch(FetchDeleteListing())
        dispatch(FetchListing())
    };

    //using useFormik
    const formik = useFormik({
        initialValues: {
            product_name: "",
            service_description: "",
            cost_currency: "",
            price_currency: "",
            product_cost: "",
            product_price: "",
            service_category: "",
            service_subcategory: "",
            publish: "",

        },
        validationSchema,
    });


    //promote listings
    function PromoteListing() {
        const Data = {
            listing_plan_secure_token: formik.values.listing_plan_secure_token,
            listing_plan_price_secure_token: formik.values.listing_plan_price_secure_token
        }
        Axios.post(`${window.$name}/api/directory/consumer/promote/listings/${ListintToken}`,
            Data
        )
            .then(function (response) {
                toast.success(`${response.data.message}`);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("something is wrong");
                }
            });
    }

    const handleData = (id) => {
        setListingId(id);
    }

    const Listings = (
        <>
            {ListingValue.map((index) => (
                <tr>
                    <td>{index.listing_title}</td>
                    <td>{index.slug}</td>
                    <td className="text-success">
                        {index.is_published ?
                            <h6 className="text-success">
                                Published
                            </h6>
                            :
                            <h6 className="text-danger">
                                Not Published
                            </h6>
                        }
                    </td>
                    <td className="text-danger">
                        {index.is_approved ?
                            <h6 className="text-success">
                                Approved
                            </h6>
                            :
                            <h6 className="text-danger">
                                Not Approved
                            </h6>
                        }
                    </td>
                    <td>
                        <Moment format="DD/MM/YYYY">{index.created_at}</Moment>
                    </td>
                    <td>
                        <div className="dropdown">
                            <button
                                className="btn btn-white"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <b>...</b>
                            </button>
                            <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                            >
                                <Link
                                    className="dropdown-item"
                                    to={{
                                        pathname: "/account/add-listing/services",
                                        state: index.id
                                    }}
                                >
                                    Add Services
                                </Link>

                                <button
                                    className="dropdown-item text-danger"
                                    onClick={() => handleData(index.id, index.secure_token)}
                                    data-toggle="modal"
                                    data-target="#cancelModal2"
                                >
                                    Delete
                                </button>
                                <button
                                    onClick={() => setListingToken(index.secure_token)}
                                    type="button"
                                    className="dropdown-item"
                                    data-toggle="modal"
                                    data-target="#PromoteModal">
                                    Promote
                                </button>
                                <Link
                                    className="dropdown-item"
                                    to={{
                                        pathname: "/listing_detail_page",
                                        state: index.secure_token
                                    }}
                                >
                                    View more
                                </Link>

                            </div>
                        </div>
                    </td>
                </tr>
            ))
            }

        </>

    );
    return (
        <React.Fragment>
            <Helmet>
                <title>{`add listing — ${theme.name}`}</title>
            </Helmet>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex align-items-end flex-column">
                            <div className="mt-auto p-2">
                                <Search
                                    // onSearch={(value) => {
                                    //     setSearch(value);
                                    //     setCurrentPage(1);
                                    // }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-divider"/>
                    <div className="card-table">
                        <div className="table-responsive-sm">
                            <table>
                                <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Slug</th>
                                    <th>Status</th>
                                    <th>Approval</th>
                                    <th>Data</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>{Listings}</tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-divider"/>
                    <div className="card-footer">
                        <div className="d-flex align-items-center flex-column">
                            <Pagination
                                // total={totalItems}
                                // itemsPerPage={ITEMS_PER_PAGE}
                                // currentPage={currentPage}
                                // onPageChange={(page) => setCurrentPage(page)}
                            />
                        </div>
                    </div>
                </div>
                {/*add services*/}
                <div className="modal fade" id="ServiceModal" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-center" id="exampleModalLongTitle">Add Listing
                                    Services</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="inputCity">Service Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="product_name"
                                                    name="product_name"
                                                    value={formik.values.product_name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.product_name && formik.errors.product_name ? (
                                                    <div
                                                        className="text-danger">{formik.errors.product_name}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="inputCity">Cost</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="product_cost"
                                                    name="product_cost"
                                                    value={formik.values.product_cost}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.product_cost && formik.errors.product_cost ? (
                                                    <div
                                                        className="text-danger">{formik.errors.product_cost}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="inputCity">Cost Currency</label>
                                                <select
                                                    className="form-control"
                                                    id="cost_currency"
                                                    name="cost_currency"
                                                    // onChange={(e) => GetPlanPrice(e)}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.cost_currency}
                                                >
                                                    <option value="">select Currency.....</option>
                                                    {currencies.map((index) => (
                                                        <option key={index.code}
                                                                value={index.code}>
                                                            {index.code}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        {formik.touched.listing_plan_secure_token && formik.errors.listing_plan_secure_token ? (
                                            <div className="text-danger">
                                                {formik.errors.listing_plan_secure_token}
                                            </div>
                                        ) : null}

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="inputCity">Selling Price</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="product_price"
                                                    value={formik.values.product_price}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                />
                                                {formik.touched.product_price && formik.errors.product_price ? (
                                                    <div
                                                        className="text-danger">{formik.errors.product_price}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="inputCity">Selling Price Currency</label>
                                                <select
                                                    className="form-control"
                                                    id="price_currency"
                                                    name="price_currency"
                                                    // onChange={(e) => GetPlanPrice(e)}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.price_currency}
                                                >
                                                    <option value="">select Currency.....</option>
                                                    {currencies.map((index) => (
                                                        <option key={index.code}
                                                                value={index.code}>
                                                            {index.code}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        {formik.touched.listing_plan_secure_token && formik.errors.listing_plan_secure_token ? (
                                            <div className="text-danger">
                                                {formik.errors.listing_plan_secure_token}
                                            </div>
                                        ) : null}

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="inputCity">Category</label>
                                                <select
                                                    className="form-control"
                                                    id="service_category"
                                                    name="service_category"
                                                    onChange={(e) => GetSub(e)}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.service_category}
                                                >
                                                    <option value="">select Category.....</option>
                                                    {category.map((index) => (
                                                        <option key={index.id}
                                                                value={index.secure_token}>
                                                            {index.category_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        {formik.touched.service_category && formik.errors.service_category ? (
                                            <div className="text-danger">
                                                {formik.errors.service_category}
                                            </div>
                                        ) : null}

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="inputCity">Subcategory</label>
                                                <select
                                                    className="form-control"
                                                    id="service_subcategory"
                                                    name="service_subcategory"
                                                    // onChange={(e) => GetPlanPrice(e)}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.service_subcategory}
                                                >
                                                    <option value="">select Subcategory.....</option>
                                                    {subcategory.map((index) => (
                                                        <option key={index.id}
                                                                value={index.id}>
                                                            {index.subcategory_name}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        {formik.touched.service_subcategory && formik.errors.service_subcategory ? (
                                            <div className="text-danger">
                                                {formik.errors.service_subcategory}
                                            </div>
                                        ) : null}
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="inputCity">Select Schedule Time</label>
                                                <select
                                                    className="form-control"
                                                    id="schedule_time"
                                                    name="schedule_time"
                                                    // onChange={(e) => GetPlanPrice(e)}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.schedule_time}
                                                >
                                                    <option value="">select Schedule Time.....</option>
                                                    <option value="starting">Require Starting Time</option>
                                                    <option value="both">Require Starting Time and End Time</option>
                                                    <option value="none">Don't Require Schedule Time</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label htmlFor="inputCity">Write Service Description Here</label>
                                                <textarea
                                                    className="form-control"
                                                    id="service_description"
                                                    name="service_description"
                                                    value={formik.values.service_description}
                                                >
                                                </textarea>
                                            </div>
                                        </div>

                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <DropzoneArea
                                                    dropzoneText="Drag and Drop  Service Images"
                                                    acceptedFiles={['image/*']}
                                                    filesLimit={6}
                                                    showAlerts={false}
                                                    onChange={files => handleImages(files)}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                               <label>
                                                   <input type="radio"
                                                          id="publish"
                                                          name="publish"
                                                          onBlur={formik.handleBlur}
                                                          value={formik.values.publish}
                                                   />

                                                   Save And Publish
                                               </label>
                                            </div>
                                        </div>

                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                    // onClick={() => AssignServicesToListing()}
                                    // disabled={!(formik.isValid && formik.dirty)}
                                    type="button"
                                    className="btn btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*promote listing*/}
                <div className="modal fade" id="PromoteModal" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-center" id="exampleModalLongTitle">Promote Listing</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="inputCity">Plan</label>
                                            <select
                                                className="form-control"
                                                id="listing_plan_secure_token"
                                                name="listing_plan_secure_token"
                                                onChange={(e) => GetPlanPrice(e)}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.listing_plan_secure_token}
                                            >
                                                <option value="">select plan.....</option>
                                                {plan.map((index) => (
                                                    <option key={index.secure_token}
                                                            value={index.secure_token}>
                                                        {index.plan_name}
                                                    </option>
                                                ))}
                                            </select>
                                            {formik.touched.listing_plan_secure_token && formik.errors.listing_plan_secure_token ? (
                                                <div className="text-danger">
                                                    {formik.errors.listing_plan_secure_token}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="inputCity">Plan Price</label>
                                            <select
                                                onChange={formik.handleChange}
                                                id="listing_plan_price_secure_token"
                                                className="form-control"
                                                name="listing_plan_price_secure_token"
                                                onBlur={formik.handleBlur}
                                                value={formik.values.listing_plan_price_secure_token}
                                            >
                                                <option value="">Select a plan price...</option>
                                                {planPrice.map((index) => (
                                                    <option key={index.id}
                                                            value={index.secure_token}>
                                                        {index.plan_price}
                                                    </option>
                                                ))}
                                            </select>
                                            {formik.touched.listing_plan_price_secure_token && formik.errors.listing_plan_price_secure_token ? (
                                                <div
                                                    className="text-danger">{formik.errors.listing_plan_price_secure_token}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                    onClick={() => PromoteListing()}
                                    disabled={!(formik.isValid && formik.dirty)}
                                    type="button"
                                    className="btn btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*modal for add description service */}
                <div className="modal fade bd-example-modal-lg"
                     tabIndex="-1" role="dialog"
                     aria-labelledby="myLargeModalLabel"
                     aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content container">
                            <div className="modal-header text-center">
                                {/*<h5 className="modal-title" id="exampleModalLabel">New message</h5>*/}
                                <h5 className="modal-title text-center text-uppercase">
                                    Add Service Description
                                </h5>
                            </div>
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="inputCity">Listing
                                        description</label>
                                    <textarea
                                        id="service_description"
                                        name="service_description"
                                        className="form-control"
                                        value={formik.values.service_description}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.service_description && formik.errors.service_description ? (
                                        <div
                                            className="text-danger">{formik.errors.service_description}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="cart__actions">
                                <div className="container">
                                    <div className="row">
                                        <button
                                            type="submit"
                                            data-dismiss="modal"
                                            // onClick={() => handleSelect(NewValue)}
                                            className="btn-sm btn btn-primary "
                                        >
                                            Add Now
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <br/>
                        <br/>
                    </div>
                </div>

                {/*delete listing*/}
                <div className="modal fade" id="cancelModal2" tabIndex="-1"
                     role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close"
                                        data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body"> Are you sure want to Delete this Listing</div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close
                                </button>
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    onClick={DeleteListing}
                                    className="btn btn-danger">
                                    Accept
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AvailableListing)
