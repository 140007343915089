// react
import React, {useEffect, useMemo, useState} from "react";
import axios from "axios";
// third-party
import {DropzoneArea} from "material-ui-dropzone";
import {toast} from "react-toastify";
import {Helmet} from "react-helmet-async";
import {Link} from "react-router-dom";
import theme from "../../data/theme";
import {agenttoken, customertoken} from "../../api/shop";
import {AddAddress, ChangeAddress, FetchShippingAddress} from "../../store/shippingAddress";
import {connect, useDispatch, useSelector} from "react-redux";
import AsyncAction from "../shared/AsyncAction";
import {useFormik} from "formik";
import * as yup from "yup";
import Moment from "react-moment";
import {Search} from "../DataTable";
import CurrencyFormat from "react-currency-format";
import {AddMasterNumber} from '../../store/masterNumber';
import {EditProfile, FetchAgentprofile} from "../../store/Agent";
import {Spinner} from "reactstrap";
import {makeStyles} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const validationSchema = yup.object({
  fullname: yup.string().required("Please enter your fullname"),
  email: yup.string().email().required("Email is a required field"),
  user_phoneNumber: yup
    .string()
    .required("Please confirm your mobile number")
    .matches(phoneRegExp, "Phone Number is not valid")
    .min(10, "Must be at least 10 characters")
    .max(13, "Phone number is too long, Not Great than 13 characters"),
});

const useStyles = makeStyles((theme) => ({
  root: {
    color: green[500],
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

function AccountPageDashboard(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const AgentDataList = useSelector((state) => state.AgentProfile);
  const [agentprofile, setAgentprofile] = useState([]);
  const [images, setImages] = useState("");
  const {AddMasterNumber}=props;
  useEffect(() => {
    dispatch(FetchAgentprofile());
  }, []);
  useEffect(() => {
    setAgentprofile(AgentDataList);
    localStorage.setItem("agentId",AgentDataList.id)
    setImages(AgentDataList.Profileimages);
  }, [AgentDataList]);
  const [shopUser_image, setImage] = useState([]);
  function handleSubmitImage() {
    const usertoken = agenttoken();
    const formData = new FormData();
    formData.append("shopUser_image", shopUser_image);
    // formData.append("payment_token", model.payment_options_token);
    formData.append("user_token", usertoken);
    axios
      .post(`${window.$name}/api/auth/upload-shop-user`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        toast.success(` ${response.data.message}`, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 5000,
        });
        dispatch(FetchAgentprofile());
      })
      .catch((error) => {
        if (error.response) {
          toast.error(` ${error.response.data.message}`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });
        } else if (error.request) {
          toast.error(` ${error.request}`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });
        } else {
          toast.error(` ${error.message}`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 5000,
          });
        }
      });
  }
 
  function handleChange(files) {
    setImage(files[0]);
  }
  // const { profile, EditProfile } = props;
  const formik = useFormik({
    initialValues: {
      user_token: customertoken(),
      fullname: agentprofile.fullname,
      user_phoneNumber: agentprofile.user_phoneNumber,
      email: agentprofile.email,
    },
    validationSchema,
  });
  //order area
  const [orders, setOrders] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const ITEMS_PER_PAGE = 5;
  useEffect(() => {
    const getData = () => {
      axios
        .post(window.$name + "/api/agent/get-agents-orders", {
          agent_token: agenttoken(),
        })
        .then((response) => response.data)
        .then((json) => {
          // console.log(json)
          setOrders(json);
        });
    };
    getData();
  }, []);
  // console.log(orders);
  const orderData = useMemo(() => {
    let computedOrders = orders;
    if (search) {
      computedOrders = computedOrders.filter(
        (order) =>
          order.order_number.toLowerCase().includes(search.toLowerCase()) ||
          order.order_status.toLowerCase().includes(search.toLowerCase()) ||
          order.total_vat.toLowerCase().includes(search.toLowerCase()) ||
          order.total_amount.toLowerCase().includes(search.toLowerCase())
      );
    }
    setTotalItems(computedOrders.length);
    //Current Page slice
    return computedOrders.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [orders, currentPage, search]);
  return (
    <div className="dashboard">
      {AgentDataList.length === 0 || images === undefined ? (
        <>
          <div className={classes.root}>
            <span className="d-flex justify-content-center">
              <Spinner size="xl" />
            </span>
            <span className="d-flex mt-10 justify-content-center font-weight-bold">
              Data Loading...
          </span>
          </div>
        </>
      ) : (
          <>
            <Helmet>
              <title>{`Ezytrade Agent — ${theme.name}`}</title>
            </Helmet>
            <div
              className="modal fade"
              id="exampleModalCenterimage"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title container text-center"
                      id="exampleModalLongTitle"
                    >
                      UPLOAD PROFILE IMAGE
                  </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div className="row justify-content-center bg-light">
                      <div className="text-center">
                        <DropzoneArea
                          dropzoneText="Press here To choose Your Profile image"
                          acceptedFiles={[
                            "image/jpeg",
                            "image/jpg",
                            "image/png",
                            "image/bmp",
                          ]}
                          filesLimit={1}
                          maxFileSize={5000000}
                          onChange={(files) => handleChange(files)}
                        />

                        <button
                          type="button"
                          onClick={() => handleSubmitImage()}
                          // onClick={run}
                          className={"btn btn-block btn-primary"}
                        >
                          Upload Now
                      </button>

                        {/* <p>
                    You can upload a sample file to see the progress bar with
                    cancel file upload button
                  </p> */}
                      </div>
                    </div>
                  </div>
                  <div class="modal-footer">
                    <button
                      type="button"
                      class="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                  </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="dashboard__profile card profile-card">
              <div className="card-body profile-card__body">
                <div className="profile-card__avatar">
                  {agentprofile.Profileimages === null ? (
                    <img
                      data-toggle="modal"
                      data-target="#exampleModalCenterimage"
                      src="images/avatars/profile.webp"
                      alt=""
                    />
                  ) : (
                      <>
                        {images === "" ? (
                          <img src="images/avatars/profile.webp" />
                        ) : (
                            <img
                              data-toggle="modal"
                              data-target="#exampleModalCenterimage"
                              src={images.image_name}
                              className="rounded-circle"
                              height="100"
                              alt=""
                            />
                          )}
                      </>
                    )}
                </div>
                <div className="profile-card__name">{AgentDataList.fullname}</div>
                <div className="profile-card__email">{AgentDataList.email}</div>
                <div className="profile-card__edit">
                  <Link
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                    className="btn btn-secondary btn-sm"
                  >
                    Edit Profile
                </Link>
                </div>
                {/*MODAL FOR EDIT USER ACCOUNT */}
                <div
                  class="modal fade"
                  id="exampleModalCenter"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalCenterTitle"
                  aria-hidden="true"
                >
                  <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5
                          className="container text-center modal-title"
                          id="exampleModalLongTitle"
                        >
                          UPDATE YOUR PROFILE
                      </h5>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div className="row no-gutters">
                          <div className="col-12">
                            <form>
                              <div className="form-group">
                                <label htmlFor="password-current">
                                  {" "}
                                Fullname
                              </label>
                                <input
                                  type="fullname"
                                  name="fullname"
                                  className="form-control"
                                  id="fullname"
                                  value={formik.values.fullname}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  placeholder="Enter fullname"
                                />
                                {formik.touched.fullname &&
                                  formik.errors.fullname ? (
                                    <div className="text-danger">
                                      {formik.errors.fullname}
                                    </div>
                                  ) : null}
                              </div>
                              <div className="form-row">
                                <div className="form-group col-md-6">
                                  <label htmlFor="password-new">Mobile</label>
                                  <input
                                    value={formik.values.user_phoneNumber}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type="phone"
                                    name="user_phoneNumber"
                                    className="form-control"
                                    id="user_phoneNumber"
                                    placeholder="Mobile number"
                                  />
                                  {formik.touched.user_phoneNumber &&
                                    formik.errors.user_phoneNumber ? (
                                      <div className="text-danger">
                                        {formik.errors.user_phoneNumber}
                                      </div>
                                    ) : null}
                                </div>
                                <div className="form-group col-md-6">
                                  <label htmlFor="password-confirm">Email</label>
                                  <input
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type="email"
                                    name="email"
                                    className="form-control"
                                    id="email"
                                    placeholder="Enter email"
                                  />
                                  {formik.touched.email && formik.errors.email ? (
                                    <div className="text-danger">
                                      {formik.errors.email}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="form-group">
                                <AsyncAction
                                  action={() => EditProfile(formik.values)}
                                  render={({ run }) => (
                                    <button
                                      type="button"
                                      onClick={run}
                                      className={
                                        "btn btn-block btn-primary rounded "
                                      }
                                    >
                                      Save profile
                                    </button>
                                  )}
                                />
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="dashboard__address card address-card address-card--featured">
              <div className="address-card__badge text-dark">
                Agent information
            </div>

              <div className="address-card__body">
                <div className="address-card__name text-uppercase">
                  {agentprofile.fullname}
                </div>
                <div className="address-card__row">
                  <div className="address-card__row-title">Phone Number</div>
                  <div className="address-card__row-content">
                    {AgentDataList.user_phoneNumber}
                  </div>
                </div>
                <div className="address-card__row">
                  <div className="address-card__row-title">Email Address</div>
                  <div className="address-card__row-content">
                    {AgentDataList.email}
                  </div>
                </div>
                <div className="address-card__row">
                  <div className="address-card__row-title">Agent Status</div>
                  {agentprofile.approve_agent ? (
                    <div className="address-card__row-content text-success">
                      Approved
                    </div>
                  ) : (
                      <div className="address-card__row-content text-danger">
                        Not Approved
                      </div>
                    )}
                </div>
                <div className="address-card__row">
                  <div className="address-card__row-title">Created Date</div>
                  <div className="address-card__row-content">
                    <Moment format="DD/MM/YYYY">{agentprofile.created_at}</Moment>
                  </div>
                </div>
                <div className="address-card__footer">
                  {/* <Link
                    to={{
                      pathname: "/account/addresses/5",
                    }}
                  >
                    <i className="fa fa-edit"></i> Edit information
                 </Link>
               &nbsp;&nbsp; */}
                  <Link
                    className="text-success"
                    to="/agent/more-information"

                  >
                    <i className="fa fa-eye"> </i> Other information
               </Link>
                </div>
              </div>
            </div>

            <div className="dashboard__orders card">
              <div className="card-header">
                <div className="">RECENT ORDERS</div>
                <div className="d-flex align-items-end flex-column">
                  <div className="mt-auto p-2">
                    <Search
                      onSearch={(value) => {
                        setSearch(value);
                        setCurrentPage(1);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="card-divider" />
              <div className="card-table">
                <div className="table-responsive-sm">
                  {orders.length === 0 ?
                    (
                      <div className="d-flex justify-content-center">
                        <div className={classes.root}>
                          <span className="d-flex justify-content-center">
                            <Spinner size="xl" />
                          </span>
                          <span className="d-flex mt-10 justify-content-center font-weight-bold">
                            Data Loading...
                          </span>
                        </div>
                      </div>

                    ) : (
                      <table className="table-responsive">
                        <thead>
                          <tr className="text-center">
                            <th>Order No</th>
                            <th>Buyer Name</th>
                            <th>Status</th>
                            <th>Total Vat</th>
                            <th>Total Amount</th>
                            <th>Date</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {orderData.map((order) => (
                            <tr className="text-center">
                              <td>{order.order_number}</td>
                              <td>{order.buyer_info.fullname}</td>
                              {order.order_status === "pending" ? (
                                <td className="text-danger font-weight-bold">
                                  Pending
                                </td>
                              ) : order.order_status === "Paid" ? (
                                <td className="text-success font-weight-bold">
                                  Paid
                                </td>
                              ) : order.order_status === "Shipped" ? (
                                <td className="text-warning font-weight-bold">
                                  Shipped
                                </td>
                              ) : order.order_status === "Delivered" ? (
                                <td className="text-primary font-weight-bold">
                                  Delivered
                                </td>
                              ) : (
                                        <></>
                                      )}
                              <td>
                                <CurrencyFormat
                                  value={order.total_vat}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  renderText={(value) => (
                                    <div>
                                      {/* {userCurrency()}{" "}{value} */}
                                      {order.currency}{" "} {value}
                                    </div>
                                  )}
                                />

                              </td>
                              <td>
                                <CurrencyFormat
                                  value={order.total_amount}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  renderText={(value) => (
                                    <div>
                                      {/* {userCurrency()}{" "}{value} */}
                                      {order.currency}{" "} {value}
                                    </div>
                                  )}
                                />


                              </td>
                              <td>
                                <Moment format="DD/MM/YYYY">
                                  {order.created_at}
                                </Moment>
                              </td>
                              <td>
                                <div className="dropdown">
                                  <button
                                    class="btn btn-white"
                                    type="button"
                                    id="dropdownMenuButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <b>...</b>
                                  </button>
                                  <div
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <Link
                                      className="dropdown-item"
                                      to={{
                                        pathname: "/agent/vieworder",
                                        state: order.order_number,
                                      }}
                                    >
                                      View Items
                                  </Link>
                                    {order.order_status === "pending" ? (                                      
                                          <button
                                            type="button"
                                            onClick={()=>AddMasterNumber(order.order_number)}
                                            className={
                                              "btn btn-block btn-primary rounded "
                                            }
                                          >
                                            Master Number
                                          </button>
                                    ) : (
                                        <></>
                                      )}
                                  </div>

                                </div>
                              </td>

                              <td>

                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                </div>
              </div>
              <div className="card-divider" />
              <div className="card-footer">
                {/* <div className="d-flex align-items-center flex-column">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div> */}
              </div>
            </div>
          </>
        )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  shippingAddress: state.shippingAddress,
  profile: state.profile,
});

const mapDispatchToProps = {
  FetchShippingAddress,
  AddAddress,
  ChangeAddress,
  EditProfile,
  AddMasterNumber
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountPageDashboard);
