import React, {Component, useState} from 'react';
import { MdStar } from 'react-icons/md'
import { AiOutlineUser } from 'react-icons/ai'
import { FaRegEnvelope } from 'react-icons/fa'
import { BsPencil } from 'react-icons/bs'
import {useFormik} from "formik";
import * as yup from "yup";
import {connect} from "react-redux";
import AsyncAction from "../../shared/AsyncAction";
import {AddReview} from "../../../store/ReviewDirectory";
const validationSchema = yup.object().shape({
    rate: yup
        .string()
        .required("This field is required")
});

function ReviewFields (props){
    const [rating,setRating]=useState(0);
    const {id}=props;
    //using useFormik
    const formik = useFormik({
        initialValues: {
            listing_id: id,
            message: "",
            rate:rating
        },
        validationSchema,
    });
console.log(rating)
    const  states = {
        title: 'Add a Review',
    }
        return (
            <>
                <div className="add-review-listing padding-top-50px" id="review">
                    <h2 className="widget-title">
                        {states.title}
                    </h2>
                    <div className="title-shape"></div>
                    <ul className="rating-list padding-top-20px">
                        <li>
                            <span className="la d-inline-block">
                                <MdStar />
                            </span>
                            <label onClick={()=>setRating(1)} className="review-label">
                                <input type="radio" name="review-radio" />
                                <span className="review-mark"></span>
                            </label>
                        </li>
                        <li>
                            <span className="la d-inline-block">
                                <MdStar />
                            </span>
                            <span className="la d-inline-block">
                                <MdStar />
                            </span>
                            <label onClick={()=>setRating(2)} className="review-label">
                                <input type="radio" name="review-radio" />
                                <span className="review-mark"></span>
                            </label>
                        </li>
                        <li>
                            <span className="la d-inline-block">
                                <MdStar />
                            </span>
                            <span className="la d-inline-block">
                                <MdStar />
                            </span>
                            <span className="la d-inline-block">
                                <MdStar />
                            </span>
                            <label className="review-label">
                                <input onClick={()=>setRating(3)} type="radio" name="review-radio" />
                                <span className="review-mark"></span>
                            </label>
                        </li>
                        <li>
                            <span className="la d-inline-block">
                                <MdStar />
                            </span>
                            <span className="la d-inline-block">
                                <MdStar />
                            </span>
                            <span className="la d-inline-block">
                                <MdStar />
                            </span>
                            <span className="la d-inline-block">
                                <MdStar />
                            </span>
                            <label onClick={()=>setRating(4)} className="review-label">
                                <input type="radio" name="review-radio" />
                                <span className="review-mark"></span>
                            </label>
                        </li>
                        <li>
                            <span className="la d-inline-block">
                                <MdStar />
                            </span>
                            <span className="la d-inline-block">
                                <MdStar />
                            </span>
                            <span className="la d-inline-block">
                                <MdStar />
                            </span>
                            <span className="la d-inline-block">
                                <MdStar />
                            </span>
                            <span className="la d-inline-block">
                                <MdStar />
                            </span>
                            <label onClick={()=>setRating(5)} className="review-label">
                                <input type="radio" name="review-radio" />
                                <span className="review-mark"></span>
                            </label>
                        </li>
                    </ul>
                    <div className="contact-form-action mt-5">
                        <form>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="input-box">
                                        <label className="label-text">Review</label>
                                        <div className="form-group">
                                            <span className="la form-icon"><BsPencil /></span>
                                            <textarea className="message-control form-control" name="message" placeholder="Write Message"></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="upload-btn-box">
                                        <AsyncAction
                                            action={() => AddReview(formik.values)}
                                            render={({run, loading}) => (
                                                <button
                                                    type="button"
                                                    onClick={run}
                                                    disabled={(rating===0)}
                                                    data-dismiss="modal"
                                                    className="theme-btn border-0 margin-top-20px">
                                                    Submit review
                                                </button>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </>
        );
}
/* First tell me why you have used this in the responsive device*/
const mapStateToProps = (state) => ({
    // shippingAddress: state.shippingAddress,
});
const mapDispatchToProps = {
    AddReview
};
export default connect(mapStateToProps, mapDispatchToProps)(ReviewFields);