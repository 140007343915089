// react
import React, { useState } from "react";
// third-party
import { Helmet } from "react-helmet-async";
// application
import PageHeader from "../shared/PageHeader";
import { Check9x7Svg } from "../../svg";
// data stubs
import theme from "../../data/theme";
import { connect } from "react-redux";
import { LoginAgent } from "../../store/auth/userLoginActions";
import AsyncAction from "../shared/AsyncAction";
import classNames from "classnames";
import { useFormik } from "formik";
import * as yup from "yup";
import { useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
const initialValues = {
    phone_email: "",
    password: "",
    remember_me: "",
};
//creating the validation schema
const validationSchema = yup.object().shape({
    phone_email: yup
        .string()
        .email("Please enter your valid email")
        .required("Email is a required field"),
    password: yup
        .string()
        .required("Please enter your  password")
        .matches(/^.*(?=.{6,}).*$/, "Password must contain at least 6 characters"),
    // remember_me:yup.bool()
    // .oneOf([true], 'Accept Ts & Cs is required'),
});

function AgentPageLogin(props) {
    const history=useHistory()
    const {LoginAgent}=props;
    //using useFormik
    const formik = useFormik({
        initialValues,
        validationSchema,
    });
    const [recaptchaValue, setRecaptcha] = useState();
    let LoginUi;
    LoginUi = (
        <form onSubmit={formik.handleSubmit}>
            <div className="form-group">
                <label htmlFor="login-email">Email address</label>
                <input
                    id="phone_email"
                    type="email"
                    name="phone_email"
                    className="form-control"
                    placeholder="Enter Email"
                    value={formik.values.phone_email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {formik.touched.phone_email && formik.errors.phone_email ? (
                    <div className="text-danger">{formik.errors.phone_email}</div>
                ) : null}
            </div>
            <div className="form-group">
                <label htmlFor="login-password">Password</label>
                <input
                    id="password"
                    name="password"
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {formik.touched.password && formik.errors.password ? (
                    <div className="text-danger">{formik.errors.password}</div>
                ) : null}
            </div>
            {/* <div className="form-group">
                <ReCAPTCHA
                    className="w-full"
                    sitekey="6Ld50AUaAAAAAPKSmMLwuE0UhVtQ9wi9MuajGboX"
                    onChange={onChange}
                />
            </div> */}
            <div className="row">
                <div className="col container flex form-group">
                    <div className="form-check">
                        <span className="form-check-input input-check">
                            <span className="input-check__body">
                                <input
                                    id="login-remember"
                                    type="checkbox"
                                    value={formik.values.remember_me}
                                    className="input-check__input"
                                />
                                <span className="input-check__box" />
                                <Check9x7Svg className="input-check__icon" />
                            </span>
                        </span>
                        <label className="form-check-label" htmlFor="login-remember">
                            Remember Me
              </label>
                    </div>
                </div>
                <div className="text-center">
                    <Link to="/account/forgot-password" className="text-muted">
                        <i className="fa fa-lock"></i> Forgot your password?
            </Link>
                </div>
            </div>
            <AsyncAction
                action={() => LoginAgent(formik.values,history)}
                render={({ run, loading }) => (
                    <button
                        type="submit"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="login"
                        onClick={run}
                        className={classNames("btn btn-primary rounded btn-block", {
                            "btn-loading": loading,
                        })}
                        disabled={!(formik.isValid && formik.dirty)}
                    >
                        Login
                    </button>
                )}
            />
            <div className="row mt-4 container">
                <Link to="/Agent/Registration" className="text-muted">
                    Don't have an account ? &nbsp;&nbsp;
                    <span className="text-primary">Signup now</span>
                </Link>
            </div>
        </form>
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Login — ${theme.name}`}</title>
            </Helmet>
            <PageHeader />
            <div className="block">
                <div className="container">
                    <div className="d-flex justify-content-center ">
                        <div className="col-md-6">
                            <div className="card flex-grow-1  mb-md-0">
                                <div className="card-body">
                                    <h5 className="d-flex justify-content-center">Agent Sign in</h5>
                                    {LoginUi}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    authentication: state.auth,
});
const mapDispatchToProps = {
    LoginAgent,
};
export default connect(mapStateToProps, mapDispatchToProps)(AgentPageLogin);
