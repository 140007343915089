// react
import React, {useEffect, useMemo, useState} from "react";
import axios from "axios";
// third-party
import {Helmet} from "react-helmet-async";
import theme from "../../data/theme";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {customertoken, userCurrency} from "../../api/shop";
import {Link} from "react-router-dom";
import {Pagination, Search} from "../DataTable";
import Moment from "react-moment";
import {FetchOrders, DeleteOrders} from "../../store/orders";
import {connect, useDispatch, useSelector} from "react-redux";
import CurrencyFormat from "react-currency-format";
import AsyncAction from "../shared/AsyncAction";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import PendingOrder from "./PendingOrder";
import PartialOrder from "./PartialOrder";
import PaidOrder from "./PaidOrder";
import ShippedOrder from "./ShippedOrder";
import DeliveryOrder from "./DeliveredOrder";

function AccountPageOrderDetails(props) {
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Order Details — ${theme.name}`}</title>
            </Helmet>
            <div className="card">
                <Tabs>
                    <TabList>
                        <Tab>
                            <span className="text-danger">Pending orders</span>
                        </Tab>
                        <Tab>
                            <span className="text-success">Paid orders</span>
                        </Tab>
                        <Tab>
                            <span className="text-primary">Partial paid orders</span>
                        </Tab>
                        <Tab>
                            <span className="text-warning">Shipped orders</span>
                        </Tab>
                        <Tab>
                            <span className="text-primary">Delivered orders</span>
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <PendingOrder/>
                    </TabPanel>
                    <TabPanel>
                        <PaidOrder/>
                    </TabPanel>
                    <TabPanel>
                        <PartialOrder/>
                    </TabPanel>
                    <TabPanel>
                        <ShippedOrder/>
                    </TabPanel>
                    <TabPanel>

                        <DeliveryOrder/>
                    </TabPanel>
                </Tabs>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPageOrderDetails);
