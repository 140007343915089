import {customertoken} from "../../api/shop";
import Axios from "axios";
import {USER_LISTING_VALUE} from "./UserListingActionTypes";
import {toast} from "react-toastify";
export function FetchUserListing() {
    return async (dispatch) =>
        Axios.get(`${window.$name}/api/directory/consumer/show/all/promoted/listings/${customertoken()}`)
            .then(function (response) {
                dispatch({type: USER_LISTING_VALUE, payload: response.data.listings});
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}
