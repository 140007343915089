import {PENDING_DELETE_ORDER_DATA,PENDING_ORDER_DATA} from "./orderPendingActionTypes";

export default function orderPendingReducer(state=[],action) {
    switch(action.type){
        case PENDING_ORDER_DATA:
            return action.payload
        case PENDING_DELETE_ORDER_DATA:
            return action.payload
        default:
            return state;
    }
}
