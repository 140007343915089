import {RATE_DATA} from "./ActionTypes";
export default function reducerRate(state=[],action) {
    // console.log(action.Rate);
    switch(action.type){
        case RATE_DATA:
            return action.Rate        
        default:
            return state;
    }
}
