// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
// import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';
// import Rating from '../shared/Rating';
import { digitalcartAddItem, } from '../../store/digitalCart';
import { Cross12Svg } from '../../svg';
// CartDataFromDb
import { url } from '../../services/utils';
import { Row } from "reactstrap";
import { digitalwishlistRemoveItem } from '../../store/digitalWishlist';
// WishlistDataFromDb, 
// data stubs
import theme from '../../data/theme';
import CurrencyFormat from "react-currency-format";
import { customertoken, userCurrency } from "../../api/shop";

function ShopDigitalWishlist(props) {
    const { wishlist, digitalcartAddItem, Rates, digitalwishlistRemoveItem } = props;
    const breadcrumb = [
        { title: 'Home', url: '' },
        { title: 'Wishlist', url: '' },
    ];

    let content;
    if (wishlist.length) {
        const itemsList = wishlist.map((item) => {
            let image;
            if (item.images !== null) {
                image = (
                    <div className="product-image">
                        <Link className="product-image__body">
                            <img className="product-image__img" src={item.images} alt="" />
                        </Link>
                    </div>
                );
            }

            const renderAddToCarButton = ({ run, loading }) => {
                const classes = classNames('btn btn-primary btn-sm', {
                    'btn-loading': loading,
                });
                return <button type="button" onClick={run} className={classes}>Add To Cart</button>;
            };

            const renderRemoveButton = ({ run, loading }) => {
                const classes = classNames('btn btn-light btn-sm btn-svg-icon', {
                    'btn-loading': loading,
                });
                return <button type="button" onClick={run} className={classes} aria-label="Remove"><Cross12Svg /></button>;
            };

            return (
                <tr key={item.id} className="wishlist__row">
                    <td className="wishlist__column wishlist__column--image">
                        {image}
                    </td>
                    <td className="wishlist__column wishlist__column--product">
                        <Link to={url.product(item)} className="wishlist__product-name">{item.name}</Link>
                    </td>                   
                    <td className="wishlist__column wishlist__column--product">
                        {/* <CurrencyFormat
                            value={item.price}
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => <div>  {item.currency} {' '} {value}</div>}
                        /> */}
                        {userCurrency() === item.currency ? (
                            <CurrencyFormat
                                value={Math.round((Number(item.price) / Number(Rates[item.currency])) * Number(Rates[userCurrency()]))
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                renderText={(value) => (
                                    <div>
                                        {userCurrency()}{" "}{value}
                                    </div>
                                )}
                            />
                        ) : (
                            <CurrencyFormat
                                value={((Number(item.price) / Number(Rates[item.currency])) * Number(Rates[userCurrency()])).toFixed(2)
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                renderText={(value) => (
                                    <div>
                                        {userCurrency()}{" "}{value}
                                    </div>
                                )}
                            />
                        )
                        }
                    </td>
                    {/*    <td >*/}
                    {/*    <CurrencyFormat*/}
                    {/*        value={item.wholesale_price}*/}
                    {/*        displayType={"text"}*/}
                    {/*        thousandSeparator={true}*/}
                    {/*        renderText={(value) => <div>  {item.currency} {' '} {value}</div>}*/}
                    {/*    />*/}
                    {/*</td>*/}
                    <td className="wishlist__column wishlist__column--tocart">
                        <AsyncAction
                            action={() => digitalcartAddItem(item)}
                            render={renderAddToCarButton}
                        />
                    </td>
                    <td className="wishlist__column wishlist__column--remove">
                        <AsyncAction
                            action={() => digitalwishlistRemoveItem(item.id, item.product_token)}
                            render={renderRemoveButton}
                        />
                    </td>
                </tr>
            );
        });

        content = (
            <div className="block">
                <div className="container">
                    <table className="wishlist">
                        <thead className="wishlist__head">
                            <tr className="wishlist__row">
                                <th className="wishlist__column wishlist__column--image">Image</th>
                                <th >Product Name</th>
                                <th >Product Price</th>
                                <th className="wishlist__column wishlist__column--tocart" aria-label="Add to cart" />
                                <th className="wishlist__column wishlist__column--remove" aria-label="Remove" />
                            </tr>
                        </thead>
                        <tbody className="wishlist__body">
                            {itemsList}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    } else {
        content = (
            <div className="block block-empty">
                <div className="container">
                    <div className="block-empty__body">
                        <div className="block-empty__message">Your wish list is empty!</div>
                        <div className="block-empty__actions">
                            <Link to="/" className="btn btn-primary btn-sm">Continue</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`Wish List — ${theme.name}`}</title>
            </Helmet>
            <PageHeader breadcrumb={breadcrumb} />
            {content}
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({
    wishlist: state.digitalwishlist,
    Rates: state.RateList,
});

const mapDispatchToProps = {
    digitalcartAddItem,
    digitalwishlistRemoveItem,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ShopDigitalWishlist);
