import {AGENT_PROFILE_DATA,AGENT_EDIT_PROFILE} from "./AgentActionTypes";

export default function agentReducer(state=[],action) {
    switch(action.type){

        case AGENT_PROFILE_DATA:
            return action.payload
        case AGENT_EDIT_PROFILE:
            return action.payload
        default:
            return state;
    }
}
