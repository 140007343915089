// react
import React, { useEffect } from "react";
// third-party
import classNames from "classnames";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
// import OverflowScrolling from 'react-overflow-scrolling';
// application
import AsyncAction from "../shared/AsyncAction";
// import Currency from '../shared/Currency';
import Indicator from "./Indicator";
import { Cross10Svg, DollarSvg } from "../../svg";
import { quoteRemoveItem, QuoteDataFromDb } from "../../store/quote";
import { Scrollbars } from "react-custom-scrollbars";
import {customertoken, agentcustomerToken, agenttoken} from "../../api/shop";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import { Container } from 'react-bootstrap'
import AccountNavPageLogin from "../account/AccountNavPageLogin";
import RegisterNav from "../account/RegisterNav";
import {ChangeCurrentCustomer} from "../../store/currentCustomer";

function IndicatorCart(props) {
    const dispatch = useDispatch();
    const iconquote = (<DollarSvg />);
    useEffect(() => {
        if (customertoken() || agentcustomerToken()) {
            dispatch(QuoteDataFromDb());
        }
    }, []);
    function RegisterPage(props) {
        const page = (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="container text-center">
                        Create Account
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <RegisterNav />
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onLogin}>Login</Button>
                    <Button className='btn-danger' onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
        if (authetications.isLogin && (customertoken() || agentcustomerToken())) {
            return (<div />)

        } else {
            return page
        }
    }

    const { authetications, quotation, quoteRemoveItem, CurrentCustomer,ChangeCurrentCustomer} = props;
    // console.log("gfhbgbjlb quotation",quotation);
    const [loginModal, setLoginModal] = React.useState(false);
    const [registerModal, setRegisterModal] = React.useState(false);

    function LoginPage(props) {
        const page = (
            <Modal {...props} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="container text-center" id="contained-modal-title-vcenter">
                        Login Page
                    </Modal.Title>
                </Modal.Header>
                <Container>
                    <AccountNavPageLogin />
                </Container>
                <Modal.Footer>
                    <Button onClick={props.Register}>Register</Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );

        if (authetications.isLogin && (customertoken() || agentcustomerToken())) {
            return (<div />)

        } else {
            return page
        }


    }

    function LoginAction() {
        setLoginModal(true)
        setRegisterModal(false)
    }

    function HideModal() {
        setLoginModal(false)
    }

    function RegisterAction() {
        setLoginModal(false)
        setRegisterModal(true)
    }

    let dropdown;
    let totals;
    let button;

    const items = quotation.items.map((item) => {
        let options;
        let image;

        if (item.options) {
            options = (
                <ul className="dropcart__product-options">
                    {
                        agenttoken() && agentcustomerToken() ?
                            <p className="container row mt-1">
                                    <span className="col-4 font-weight-bold">
                                        CUSTOMER:
                                    </span>
                                {" "}{" "}
                                <span className="col-6 text-capitalize font-weight-bold">
                                        {CurrentCustomer}
                                    </span>
                                {" "}{" "}
                                <AsyncAction
                                    action={() => ChangeCurrentCustomer("")}
                                    render={({run, loading}) => (
                                        <Link
                                            className="col-2 text-capitalize text-success font-weight-bold"
                                            onClick={run}
                                        >
                                            Change
                                        </Link>
                                    )}
                                />
                            </p> :
                            null
                    }
                    <hr/>
                    {item.options.map((option, index) => (
                        <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
                    ))}
                </ul>
            );
        }
        // if (item.product.images.length) {
            image = (
                <div className="product-image dropcart__product-image">
                    <Link className="product-image__body">
                        <img
                            className="product-image__img"
                            src={item.product.images}
                            alt=""
                        />
                    </Link>
                </div>
            );
        // }
        const removeButton = (
            <AsyncAction
                action={() => quoteRemoveItem(item.id, item.product.product_token)}
                render={({ run, loading }) => {
                    const classes = classNames(
                        "dropcart__product-remove btn btn-light btn-sm btn-svg-icon",
                        {
                            "btn-loading": loading,
                        }
                    );
                    return (
                        <button type="button" onClick={run} className={classes}>
                            <Cross10Svg />
                        </button>
                    );
                }}
            />
        );
        return (
            <div key={item.id} className="dropcart__product ">
                {image}
                <div className="dropcart__product-info">
                    <div className={"dropcart__product-name"}>
                        <Link>{item.product.name}</Link>
                    </div>
                    {options}
                    <div className="dropcart__product-meta">
                        <span className="dropcart__product-quantity">
                            {item.quantity}
                             &nbsp;&nbsp;
                             Quantity
                            </span>
                    </div>
                </div>
                {removeButton}

            </div>
        );
    });
    //button returns
    if (authetications.isLogin && (customertoken() || agentcustomerToken())) {
        button = (
            <Link
                className="btn btn-primary btn-block rounded shadow-lg"
                to="/quote-list"
            >
                Continue
            </Link>)
    } else {
        button = (
            <Button variant="primary" onClick={() => setLoginModal(true)}>
                Login
            </Button>
        )
    }

    if (quotation.quantity) {
        dropdown = (
            <div className="dropcart">
                {
                    agenttoken() && agentcustomerToken() ?
                        <p className="container row mt-1">
                                    <span className="col-4 font-weight-bold">
                                        CUSTOMER:
                                    </span>
                            {" "}{" "}
                            <span className="col-6 text-capitalize font-weight-bold">
                                        {CurrentCustomer}
                                    </span>
                            {" "}{" "}
                            <AsyncAction
                                action={() => ChangeCurrentCustomer("")}
                                render={({run, loading}) => (
                                    <Link
                                        className="col-2 text-capitalize text-success font-weight-bold"
                                        onClick={run}
                                    >
                                        Change
                                    </Link>
                                )}
                            />
                        </p> :
                        null
                }
                <hr/>
                <Scrollbars style={{ height: 200 }}>
                    <div className="dropcart__products-list">{items}</div>
                </Scrollbars>
                <div className="dropcart__buttons">
                    {button}
                    <LoginPage show={loginModal} onHide={() => HideModal()} Register={() => RegisterAction()} />
                    <RegisterPage show={registerModal} onHide={() => setRegisterModal(false)}
                        onLogin={() => LoginAction()} />
                </div>
            </div>
        );
    } else {
        dropdown = (
            <div className="dropcart">
                <div className="dropcart__empty">Your shopping Quote is empty!</div>
            </div>
        );
    }

    return (
        <Indicator
            dropdown={dropdown}
            value={quotation.items.length}
            icon={iconquote}
        />
    );
}

const mapStateToProps = (state) => ({
    authetications: state.auth,
    quotation: state.quotation,
    CurrentCustomer: state.CurrentCustomer
});

const mapDispatchToProps = {
    quoteRemoveItem,
    ChangeCurrentCustomer,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);
