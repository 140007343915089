// react
import React from "react";
// third-party
import { Helmet } from "react-helmet-async";
// application
import PageHeader from "../shared/PageHeader";
// data stubs
import theme from "../../data/theme";
import { connect } from "react-redux";
import { NewPassword } from "../../store/auth/userActions";
import AsyncAction from "../shared/AsyncAction";
import classNames from "classnames";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
const initialValues = {
  phone_email: "",
};
//creating the validation schema
const validationSchema = yup.object().shape({
  code: yup
    .string()
    .required("This is a required field"),
    
password: yup
    .string()
    .required("Please enter your  password")
    .matches(/^.*(?=.{6,}).*$/, "Password must contain at least 6 characters"),
});

function Forgotpassword(props) {
  const { NewPassword} = props;
  //using useFormik
  const formik = useFormik({
    initialValues,
    validationSchema,
  });

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Login — ${theme.name}`}</title>
      </Helmet>
      <PageHeader />
      <form onSubmit={formik.handleSubmit}>        
        <div className="form-group">
          <label htmlFor="login-password">Verification code</label>
          <input
            id="code"
            name="code"
            type="password"
            className="form-control"
            placeholder="Enter verification code"
            value={formik.values.code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.code && formik.errors.code ? (
            <div className="text-danger">{formik.errors.code}</div>
          ) : null}
        </div>
        <div className="form-group">
          <label htmlFor="login-password">New Password</label>
          <input
            id="password"
            name="password"
            type="password"
            className="form-control"
            placeholder="Enter new password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="text-danger">{formik.errors.password}</div>
          ) : null}
        </div>
        <div className="form-group">
          <div className="text-right">
          <AsyncAction
          action={() => NewPassword(formik.values)}
          render={({ run, loading }) => (
            <button
              type="submit"
              data-toggle="tooltip"
              data-placement="right"
              title="login"
              onClick={run}
              className={classNames("btn btn-primary rounded", {
                "btn-loading": loading,
              })}
              disabled={!(formik.isValid && formik.dirty)}
            >
             Create Now
            </button>
          )}
            />
          </div>
        </div>
        <div className="row mt-4 container">
          <Link to="/account/login" className="text-muted">
            Go back to ? <span className="text-primary">Login now</span>
          </Link>
        </div>
      </form>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  authentication: state.auth,
});
const mapDispatchToProps = {
  NewPassword,
};
export default connect(mapStateToProps, mapDispatchToProps)(Forgotpassword);
