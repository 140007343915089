import React, { Fragment,useState,useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Axios from "axios";

const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
// Destructure props
const FirstStep = ({
  handleNext,
  handleChange,
  values: { FullName, email, phone,Country },
  filedError,
  isError,
}) => {
  // Check if all values are not empty
  const isEmpty = FullName.length > 0 && phone.length > 0;
  const [countries, setCountries] = useState([1]);

  useEffect(() => {
  const serviceOption = async () => {
    // eslint-disable-next-line camelcase
    const country_data = await Axios.get(
      `${window.$name}/api/adminstrator/get-countries`
    ).then((response) => response.data);
    setCountries(country_data);
  };
  serviceOption();
}, []);

  return (
    <Fragment>
    <h6 className="card-title text-center">Shop quality product at affordable prices</h6>
      <Grid container spacing={2} className="justify-content-center" noValidate>
      <div className="form-row ">
          <div className="form-group col-md-4">
          <TextField
              fullWidth
              size="small"
              label="Full Name"
              variant="outlined"
              name="FullName"
              placeholder="Your Full Name"
              defaultValue={FullName}
              onChange={handleChange("FullName")}
              margin="normal"
              error={filedError.FullName !== ""}
              helperText={
                filedError.FullName !== ""
                  ? `${filedError.FullName}`
                  : ""
              }
              required
            />
          </div>
          <div className="form-group col-md-4">
          <TextField
              fullWidth
              size="small"
              label="Email"
              variant="outlined"
              name="email"
              placeholder="Your Email Address"
              defaultValue={email}
              onChange={handleChange("email")}
              margin="normal"
              error={filedError.email !== ""}
              helperText={filedError.email !== "" ? `${filedError.email}` : ""}
            />
          </div>

          <div className="form-group col-md-4">
          <TextField
              fullWidth
              size="small"
              label="Phone Number"
              variant="outlined"
              name="phone"
              placeholder="Your Phone Number"
              defaultValue={phone}
              onChange={handleChange("phone")}
              margin="normal"
              error={filedError.phone !== ""}
              helperText={
                filedError.phone !== "" ? `${filedError.phone}` : ""
              }
              required
            />
          </div>
        <div className="form-group col-md-12">
        <InputLabel>Select Your country</InputLabel>
        <FormControl
          size="small"
          fullWidth
          variant="outlined"
          required
          margin="normal"
        >
          <Select
           name="Country"
           onChange={handleChange("Country")}
           >
            {countries.map((s, idx) => (
              <MenuItem key={idx} value={s.id}>
                {s.country}
              </MenuItem>
            ))}
          </Select>
          </FormControl>
          </div>
          {/* <div className="form-group col-md-6">

          </div>
          <div className="form-group col-md-6">

          </div> */}
        </div>

      </Grid>
      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button
          variant="contained"
          disabled={!isEmpty || isError}
          color="primary"
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </Fragment>
  );
};

export default FirstStep;
