import {PROFILE_DATA,EDIT_PROFILE} from "./profileActionType";

export default function profileReducer(state=[],action) {
    switch(action.type){

        case PROFILE_DATA:
            return action.payload
        case EDIT_PROFILE:
            return action.payload
        default:
            return state;
    }
}
