// react
import React, {useEffect, useMemo, useState} from "react";
import axios from "axios";
// third-party
import {DropzoneArea} from "material-ui-dropzone";
import {toast} from "react-toastify";
import {Helmet} from "react-helmet-async";
import {Link} from "react-router-dom";
import theme from "../../data/theme";
import {customertoken} from "../../api/shop";
import {FetchShippingAddress} from "../../store/shippingAddress";
import {AddAddress, ChangeAddress} from "../../store/shippingAddress";
import {connect, useDispatch} from "react-redux";
// import {  } from "../../store/profile";
import {EditProfile, Fetchprofile} from "../../store/profile";
import AsyncAction from "../shared/AsyncAction";
import {useFormik} from "formik";
import * as yup from "yup";
import Moment from "react-moment";
import {Pagination, Search} from "../DataTable";
import {CartDataFromDb} from "../../store/cart";
import store from '../../store/store';
import {FetchAgentCommission} from "../../store/commissionAgent";
import {Spinner} from "reactstrap";
import {makeStyles} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import CurrencyFormat from "react-currency-format";
import {userCurrency} from "../../api/digitalApi";

const useStyles = makeStyles((theme) => ({
    root: {
        color: green[500],
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
}));

function AccountAgentCommission(props) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(FetchAgentCommission());
    }, [dispatch])
    const {AgentCommission} = props
    console.log(AgentCommission)
    //order area
    const classes = useStyles();
  return (
        <div className="dashboard">
            <Helmet>
                <title>{`Ezytrade Agent — ${theme.name}`}</title>
            </Helmet>
            <div className="cardTop card">
                <div className="card-header">
                    {/* <div className="">RECENT ORDERS</div> */}
                    <div className="d-flex align-items-end flex-column">
                        {/*<div className="mt-auto p-2">*/}
                        {/*    <Search*/}
                        {/*        onSearch={(value) => {*/}
                        {/*            setSearch(value);*/}
                        {/*            setCurrentPage(1);*/}
                        {/*        }}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="card-divider"/>
                <div className="card-table">
                    <div className="table-responsive-sm">
                        {AgentCommission.length === 0 ?
                            (
                                <div className="d-flex justify-content-center">
                                    <div className={classes.root}>
                          <span className="d-flex justify-content-center">
                            <Spinner size="xl"/>
                          </span>
                                        <span className="d-flex mt-10 justify-content-center font-weight-bold">
                            Data Loading...
                          </span>
                                    </div>
                                </div>

                            ) : (
                                <table>
                                    <thead>
                                    <tr className="text-center">
                                        <th>Order No</th>
                                        <th>Order status</th>
                                        <th>Commission Amount</th>
                                        <th>Transfer Status</th>
                                        <th>Created Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {AgentCommission.slice(0, 5).map((order) => (
                                        <tr className="text-center">
                                            <td>{order.order_token}</td>
                                            {order.order_status === "Paid" ? (
                                                <td className="text-success font-weight-bold">Paid</td>
                                            ) : (
                                                <td className="text-danger font-weight-bold">
                                                    Not paid
                                                </td>
                                            )}
                                            <td>
                                                <CurrencyFormat
                                                    value={order.commission}
                                                    displayType={"text"}
                                                    thousandSeparator={true}
                                                    renderText={(value) => (
                                                        <div>
                                                             {userCurrency()}{" "}{value}
                                                           {/*{" "} {value}*/}
                                                        </div>
                                                    )}
                                                />
                                            </td>
                                            {order.transfer_status ? (
                                                <td className="text-success font-weight-bold">Transfered</td>
                                            ) : (
                                                <td className="text-danger font-weight-bold">
                                                    Not Transfered
                                                </td>
                                            )}
                                            <td>
                                                <Moment format="DD/MM/YYYY">{order.created_at}</Moment>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            )}
                    </div>
                </div>
                <div className="card-divider"/>
                <div className="card-footer">
                    {/* <div className="d-flex align-items-center flex-column">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div> */}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    AgentCommission: state.AgentCommission
});

const mapDispatchToProps = {
    FetchAgentCommission
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountAgentCommission);
