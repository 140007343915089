import {BOOKING_DATA} from "./DirectoryActionTypes";

export default function  DirectoryReducer(state=[],action) {
    switch(action.type){

        case BOOKING_DATA:
            return action.payload
        default:
            return state;
    }
}
