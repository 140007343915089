// react
import React from "react";
import ShopPageProduct from "./ShopPageProduct";
import Rating from "../shared/Rating";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import ShowMoreText from "react-show-more-text";
function CompanyProfile(props) {
  const { Product } = props;
  function productInfo(){
    return{
      shopName:Product.shop.seller_info,
      description:Product.shop.seller_info
    }
  }
  return (
    <div className="typography">
      <h3>Company Profile</h3>
      <p>
        <div className="review">
          {/*<div className="review__avatar"><img src={review.avatar} alt="" /></div>*/}
          <div className=" review__content">
            <div className=" review__author ">
              Shop Name : {productInfo().shopName.business_name}
            </div>
            <p className="flex align-center">Business Descriptions</p>
            <div className=" review__text">
              <ShowMoreText
                lines={8}
                more="Read more"
                less="Read less"
                className="content-css"
                anchorClass="my-anchor-css-class"
                expanded={false}
              >
                {ReactHtmlParser(
                    productInfo().description.business_descriptions
                )}
              </ShowMoreText>
            </div>
          </div>
        </div>
      </p>
    </div>
  );
}
// CompanyProfile.defaultProps = {
//     layout: "standard",
//     sidebarPosition: "start",
// };
export default CompanyProfile;
