// react
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { url } from "../../services/utils";
import { handleBacks, handleSubmits } from "./checkout";
import theme from "../../data/theme";
import CurrencyFormat from "react-currency-format";
import Axios from "axios";
import { toast } from "react-toastify";
import { agentcustomerToken, agenttoken, PaymentToken, customertoken, userCurrency } from "../../api/shop";
import { digitalcartRemove } from "../../store/digitalCart";
import PaymentOptioncomponent from "../shop/paymentOption";
import "../blocks/files.css";

class CofirmdigitalOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            paymentOption_token: false
        };
    }
    toggle(value) {
        this.setState({
            paymentOption_token: value,
        });
    }

    cartNeedUpdate() {
        const { quantities } = this.state;
        const { cart } = this.props;
        return (
            quantities.filter((x) => {
                const item = cart.items.find((item) => item.id === x.itemId);
                return item && item.quantity !== x.value && x.value !== "";
            }).length > 0
        );
    }
    renderItems() {
        const { cart, activestep } = this.props;
        // console.log(cart);

        return cart.items.map((item) => {
            let image;
            let options;
            if (item.product.images.length > 0) {
                image = (
                    <div className="product-image">
                        <Link
                            to={url.product(item.product)}
                            className="product-image__body"
                        >
                            <img
                                className="product-image__img"
                                src={item.product.images[0]}
                                alt=""
                            />
                        </Link>
                    </div>
                );
            }
            if (item.options.length > 0) {
                options = (
                    <ul className="cart-table__options">
                        {item.options.map((option, index) => (
                            <li
                                key={index}
                            >{`${option.optionTitle}: ${option.valueTitle}`}</li>
                        ))}
                    </ul>
                );
            }
            return (
                <tr key={item.id} className="cart-table__row">
                    <td className="cart-table__column cart-table__column--image">
                        {image}
                    </td>
                    <td className="cart-table__column cart-table__column--product">
                        <Link
                            to={url.product(item.product)}
                            className="cart-table__product-name"
                        >
                            {item.product.name}
                        </Link>
                        {options}
                    </td>
                    <td
                        className="cart-table__column cart-table__column--price"
                        data-title="Price"
                    >
                        {/*<Currency value={item.price}/>*/}
                        <CurrencyFormat
                            value={item.price}
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                                <div>
                                    {" "}
                                    {userCurrency()} {value}
                                </div>
                            )}
                        />
                    </td>
                    <td
                        className="cart-table__column cart-table__column--quantity"
                        data-title="Quantity"
                    >
                        {item.quantity}
                    </td>
                    <td
                        className="cart-table__column cart-table__column--total"
                        data-title="Total"
                    >
                        {/*<Currency value={item.total}/>*/}
                        <CurrencyFormat
                            value={item.total}
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                                <div>
                                    {" "}
                                    {userCurrency()} {value}
                                </div>
                            )}
                        />
                    </td>
                    <td className="cart-table__column cart-table__column--remove" />
                </tr>
            );
        });
    }

    renderCart() {

        const { cart, digitalcartRemove, activeStep } = this.props;
        const agentcustomer_Token = agentcustomerToken();
        const agentToken = agenttoken();
        function ConfirmOrder() {
            let cartdetails = localStorage.getItem("digitalcartData");
            if (agentcustomer_Token !== null && agentToken !== null) {
                let data = {
                    user_token: agentcustomer_Token,
                    payment_option: PaymentToken(),
                    total_amount: cart.subtotal,
                    agent_token: agentToken,
                    currency: userCurrency(),
                    push_number: localStorage.getItem("phone"),
                    total_vat: cart.vattotal,
                    cart_details: cartdetails,
                };

                Axios.post(window.$name + "/api/order/create-digital-order", data)
                    .then((response) => {
                        toast.success(` "${response.data.message}"`);
                        localStorage.setItem(
                            "success_data",
                            JSON.stringify(response.data.payload)
                        );
                        localStorage.removeItem("paymentOption_token");
                        digitalcartRemove("");
                        handleSubmits(activeStep)
                    })
                    .catch(function (error) {
                        if (error.response) {
                            toast.error(
                                `"${error.response.data.message}" Error Status  "${error.response.status}" `
                            );
                        } else if (error.request) {
                            toast.error(`"${error.request}" `);
                        } else {
                            toast.error(`"${error.message}" `);
                        }
                    });
            } else {
                let data = {
                    user_token: customertoken(),
                    payment_option: PaymentToken(),
                    total_amount: cart.subtotal,
                    agent_token: "",
                    currency: userCurrency(),
                    push_number: localStorage.getItem("phone"),
                    total_vat: cart.vattotal,
                    cart_details: cartdetails,
                };
                Axios.post(window.$name + "/api/order/create-digital-order", data)
                    .then((response) => {
                        toast.success(` "${response.data.message}"`);
                        localStorage.setItem(
                            "success_data",
                            JSON.stringify(response.data.payload)
                        );
                        localStorage.removeItem("paymentOption_token");
                        digitalcartRemove();
                        handleSubmits(activeStep)
                    })
                    .catch(function (error) {
                        if (error.response) {
                            toast.error(
                                `"${error.response.data.message}" Error Status  "${error.response.status}" `
                            );
                        } else if (error.request) {
                            toast.error(`"${error.request}" `);
                        } else {
                            toast.error(`"${error.message}" `);
                        }
                    });
            }
        }

        return (
            <div className="cart d-flex justify-content-center">
                <div className="card ">
                    <div className="card-title">
                        <br />
                        <h5 className="text-center">
                            SELECT PAYMENT OPTION AND CONFIRM YOUR ORDER ITEMS
                        </h5>
                    </div>
                    <div className="card-body">
                        <PaymentOptioncomponent onClick={this.toggle.bind(this)} />
                        <br />
                        <table className="cart__table cart-table">
                            <thead className="cart-table__head">
                                <tr className="cart-table__row">
                                    <th className="cart-table__column cart-table__column--image">
                                        Image
                                </th>
                                    <th className="cart-table__column cart-table__column--product">
                                        Product name
                                </th>

                                    <th className="cart-table__column cart-table__column--price">
                                        Price
                                </th>
                                    <th className="cart-table__column cart-table__column--quantity">
                                        Quantity
                                </th>
                                    <th className="cart-table__column cart-table__column--total">
                                        Total
                                </th>
                                    <th
                                        className="cart-table__column cart-table__column--remove"
                                        aria-label="Remove"
                                    />
                                </tr>
                            </thead>
                            <tbody className="cart-table__body">{this.renderItems()}</tbody>
                        </table>

                        <div className="cart__actions">
                            <div className="container">
                                <div className="row">
                                    <div className="col-3">
                                        <h6>
                                            <label>{cart.items.length} ITEMS</label>
                                        </h6>
                                    </div>
                                    <div className="col-3">
                                        <h6>
                                            <label>TOTAL PRICE</label>
                                        </h6>
                                    </div>
                                    <div className="col-3 ">
                                        <h6>
                                            <CurrencyFormat
                                                value={(cart.subtotal).toFixed(2)}
                                                displayType={"text"}
                                                thousandSeparator={true}
                                                renderText={(value) => (
                                                    <div>
                                                        {" "}
                                                        {userCurrency()} {"  "} {value}
                                                    </div>
                                                )}
                                            />
                                        </h6>
                                    </div>
                                    <div className="col-3">
                                        {localStorage.getItem("pushValue") === true ? (
                                            <button
                                                disabled={
                                                    !this.state.paymentOption_token ||
                                                    localStorage.getItem("pushValue") === false
                                                }
                                                onClick={() => ConfirmOrder()}
                                                type="submit"
                                                className="btn-sm btn btn-primary "
                                            >
                                                CONFIRM
                                            </button>
                                        ) : (
                                            <button
                                                disabled={
                                                    !this.state.paymentOption_token
                                                }
                                                onClick={() => ConfirmOrder()}
                                                type="submit"
                                                className="btn-sm btn btn-primary "
                                            >
                                                CONFIRM
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
            </div>
        );
    }

    render() {
        let content;
        content = this.renderCart();
        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Confirm Order — ${theme.name}`}</title>
                </Helmet>
                <div className="mt-4">
                    {content}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.digitalcart,
});

const mapDispatchToProps = {
    digitalcartRemove,
};

export default connect(mapStateToProps, mapDispatchToProps)(CofirmdigitalOrder);
