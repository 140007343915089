import {DIGITAL_WISHLIST_ADD_ITEM, DIGITAL_WISHLIST_REMOVE_ALL, DIGITAL_WISHLIST_REMOVE_ITEM} from './digitalwishlistActionTypes';


function digitaladdItem(state, product) {
    // console.log("product wish ",product)
    const itemIndex = state.findIndex((x) => x.id === product.id);

    if (itemIndex === -1) {
        return [
            ...state,
            JSON.parse(JSON.stringify(product)),
        ];
    }

    return state;
}

const initialState = [];

export default function digitalwishlistReducer(state = initialState, action) {
    switch (action.type) {
    case DIGITAL_WISHLIST_ADD_ITEM:
        return digitaladdItem(state, action.product);

    case DIGITAL_WISHLIST_REMOVE_ITEM:
        return state.filter((x) => x.id !== action.productId);

    case DIGITAL_WISHLIST_REMOVE_ALL:
        return state = [];

    default:
        return state;
    }
}
