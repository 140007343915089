import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import theme from "../../../data/theme";
import {connect, useDispatch} from "react-redux";
import axios from "axios";
import {useFormik} from "formik";
import * as yup from "yup";
import {AddListingData, FetchListing} from "../../../store/Listings";
import {useHistory} from "react-router-dom";
import {DropzoneArea} from "material-ui-dropzone";
import {toast} from "react-toastify";
import {customertoken} from "../../../api/shop";
//creating the validation schema
const validationSchema = yup.object().shape({
    service_name: yup
        .string()
        .required("This field is required"),
    service_description: yup
        .string()
        .required("This field is required"),
    category_token: yup
        .string()
        .required("This field is required"),
    listing_subcategory_secure_token: yup
        .string()
        .required("This field is required"),
    product_cost: yup
        .string()
        .required("This field is required"),
    cost_currency: yup
        .string()
        .required("This field is required"),
    product_price: yup
        .string()
        .required("This field is required"),
    product_currency: yup
        .string()
        .required("This field is required"),
    schedule_time: yup
        .string()
        .required("This field is required"),
});

function AddListing(props) {
    const dispatch = useDispatch()
    const id = props.location.state;
    const [arrayHolidayData, setHolidaysData] = useState([]);
    const [enquiry, setEnquiry] = useState(false);
    const [prepaid, setPrepaid] = useState("");
    const [booking, setBooking] = useState(false);
    const [count, setCount] = useState(1)
    const [stepActive1, setActiveStep1] = useState("disabled");
    const [stepActive2, setActiveStep2] = useState("disabled");
    const [stepActive3, setActiveStep3] = useState("disabled");
    const [stepActive4, setActiveStep4] = useState("disabled");
    const [stepActive5, setActiveStep5] = useState("disabled");
    const [imageFiles, setImages] = useState([]);
    const [imageFile, setImage] = useState([]);
    const [imageId, setImageId] = useState([]);
    const [arrayData, setArray] = useState([]);
    const [ServiceId, setServiceId] = useState(null);
    const [currencies, setCurrency] = useState([]);

    function handleImages(files) {
        setImages(files);
    }

    const history = useHistory()
    useEffect(() => {
        if (count === 2 || count >= 2) {
            setActiveStep2("active")
        } else {
            setActiveStep2("disabled")
        }
    })
    useEffect(() => {
        if (count === 3 || count >= 3) {
            setActiveStep3("active")
        } else {
            setActiveStep3("disabled")
        }
    })
    useEffect(() => {
        if (count === 4 || count >= 4) {
            setActiveStep4("active")
        } else {
            setActiveStep4("disabled")
        }
    })
    useEffect(() => {
        if (count === 5 || count >= 5) {
            setActiveStep5("active")
        } else {
            setActiveStep5("disabled")
        }
    })

    function NextSteps() {
        // console.log("gfdghfgfdgh")
        if (count > 0) {
            setTimeout(() => setCount(count + 1), 1000);
        }
    }

    function BackSteps() {
        if (count > 1 || count >= 4) {
            setTimeout(setCount(count - 1), 1000);
        }

    }

    const handleSelect = (NewValue) => {
        setArray(oldArray => [...oldArray, NewValue]);
        toast.success("Service selected ,Successfully");
        return arrayData
    }

    function handleRemove(id) {
        const newArray = arrayData.filter((item) => item.subcategory_service_id !== id);
        setArray(newArray)
        toast.success("Service removed ,Successfully");
    }

    const handleHolidays = (NewValue) => {
        setHolidaysData(oldArray => [...oldArray, NewValue]);
        toast.success("Holiday selected ,Successfully");
        return arrayHolidayData
    }

    function RemoveHoliday(id) {
        const newArray = arrayHolidayData.filter((item) => item.id !== id);
        setHolidaysData(newArray)
        toast.success("Holiday removed ,Successfully");
    }

    const formik = useFormik({
        initialValues: {
            service_name: "",
            service_description: "",
            category_token: "",
            listing_subcategory_secure_token: "",
            product_cost: "",
            cost_currency: "",
            product_price: "",
            price_currency: "",
            schedule_time: "",
            publish:  false,

        },
        validationSchema,
    });
    const [state, setState] = useState({
        country_id: "",
        region_id: "",
        district_id: "",
        category_token: "",
        // allow_booking: false,
        listing_subcategory_secure_token: "",
        listing_plan_secure_token: "",
        listing_plan_price_secure_token: "",
    });
    const [countries, setCountry] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const results = await axios
                .get(window.$name + "/api/adminstrator/get-countries")
                .then((response) => response.data);
            setCountry(results);
        };

        fetchData();
    }, []);

    const [regions, setRegions] = useState([]);
    const GetRegions = (e) => {
        const {id, value} = e.target;
        formik.values.country_id = e.target.value;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
        axios
            .get(window.$name + "/api/adminstrator/get-regions", {
                params: {
                    country_id: e.target.value,
                },
            })
            .then((res) => {
                setRegions(res.data);
            });
    };

    const [district, setDistrict] = useState([]);
    const GetDistrict = (e) => {
        const {id, value} = e.target;
        formik.values.region_id = e.target.value;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
        axios
            .get(window.$name + "/api/adminstrator/get-districts", {
                params: {
                    region_id: e.target.value,
                },
            })
            .then((res) => {
                setDistrict(res.data);
            });
    };

    const [category, setCategory] = useState([]);
    useEffect(() => {
        const fetchCategory = async () => {
            const responseData = await axios
                .get(window.$name + "/api/directory/get/listings/categories")
                .then((response) => response.data.listing_categories);
            setCategory(responseData);
        };
        const fetchCurrency = async () => {
            const currency = await axios
                .get(window.$name + "/api/adminstrator/get-currency")
                .then((response) => response.data);
            setCurrency(currency);
        };
        fetchCategory();
        fetchCurrency();
    }, []);

    const [subcategory, setSubCategory] = useState([]);
    const GetSub = (e) => {
        const {id, value} = e.target;
        formik.values.category_token = e.target.value;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
        axios
            .get(window.$name + `/api/directory/get/listings/subcategories/${e.target.value}`)
            .then((res) => {
                setSubCategory(res.data.listing_subcategories);
            });
    };


    function CreateListing(value, id ,imageFiles) {

        const formData = new FormData();
        formData.append('service_name', value.service_name);
        formData.append('service_description', value.service_description);
        formData.append('cost_currency', value.cost_currency);
        formData.append('price_currency', value.price_currency);
        formData.append('product_cost', value.product_cost);
        formData.append('product_price', value.product_price);
        formData.append('schedule_time', value.schedule_time);
        formData.append('publish', value.publish);
        formData.append('category_token', value.category_token);
        formData.append('listing_subcategory_secure_token', value.listing_subcategory_secure_token);
        formData.append('listing_id', id);
        imageFiles.forEach(image => {
            formData.append('images[]', image);
        });

        toast.info(`Please wait, the upload is on process`);

        axios.post(window.$name + `/api/service-product/upload-listing-service-product`, formData).then((response) => {
                if (response.status === 201) {
                    dispatch(FetchListing())
                    history.push("/account/directory")
                    toast.success(` "${response.data.message}"`)

                } else {
                    toast.error("something is wrong")
                }
            });

    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{`add listing — ${theme.name}`}</title>
            </Helmet>
            <div>
                <div className="step">
                    <section className="signup-step-container">
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-8">
                                <div className="wizard">
                                    <div className="wizard-inner">
                                        <div className="connecting-line"></div>
                                        <ul className="nav nav-tabs" role="tablist">
                                            <li role="presentation" className="active">
                                                <a href="#step1" data-toggle="tab" aria-controls="step1" role="tab"
                                                   aria-expanded="true"><span className="round-tab">1 </span> <i>
                                                    <h6>
                                                        Service Information
                                                    </h6>
                                                </i>
                                                </a>
                                            </li>
                                            <li role="presentation" className={stepActive2}>
                                                <a href="#step2" data-toggle="tab" aria-controls="step2" role="tab"
                                                   aria-expanded="false"><span className="round-tab">2</span> <i>
                                                    <h6>
                                                        Service Category
                                                    </h6>
                                                </i>
                                                </a>
                                            </li>
                                            <li role="presentation" className={stepActive3}>
                                                <a href="#step3" data-toggle="tab" aria-controls="step3"
                                                   role="tab"><span
                                                    className="round-tab">3</span> <i><h6>Service Prices</h6></i></a>
                                            </li>
                                            <li role="presentation" className={stepActive4}>
                                                <a href="#step4" data-toggle="tab" aria-controls="step4"
                                                   role="tab"><span
                                                    className="round-tab">4</span> <i><h6>Schedule Time</h6></i></a>
                                            </li>
                                            <li role="presentation" className={stepActive5}>
                                                <a href="#step5" data-toggle="tab" aria-controls="step5"
                                                   role="tab"><span
                                                    className="round-tab">5</span> <i><h6>Service images</h6></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                    <form role="form" className="login-box">
                                        <div className="tab-content" id="main_form">
                                            {count === 1 ? (
                                                    <div className="tab-pane active" role="tabpanel">
                                                        {/*<h4 className="text-center">Company Details</h4>*/}
                                                        <div className="row">
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label htmlFor="inputCity">Service Name</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="service_name"
                                                                        value={formik.values.service_name}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                    {formik.touched.service_name && formik.errors.service_name ? (
                                                                        <div
                                                                            className="text-danger">{formik.errors.service_name}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label htmlFor="inputCity">Service description</label>
                                                                    <textarea
                                                                        id="service_description"
                                                                        name="service_description"
                                                                        className="form-control"
                                                                        value={formik.values.service_description}
                                                                        onChange={formik.handleChange}
                                                                        onBlur={formik.handleBlur}
                                                                    />
                                                                    {formik.touched.service_description && formik.errors.service_description ? (
                                                                        <div
                                                                            className="text-danger">{formik.errors.service_description}</div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {formik.values.service_name !== "" && formik.values.service_description !== "" ?
                                                            <ul className="list-inline pull-right">
                                                                <li>
                                                                    <button type="button"
                                                                            onClick={() => NextSteps()}
                                                                            className="default-btn next-step cursor-pointer">Continue
                                                                        to
                                                                        next step
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                            :
                                                            <div>

                                                            </div>
                                                        }
                                                    </div>
                                                )
                                                : count === 2 ? (
                                                        <div className="tab-pane active" role="tabpanel">
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="inputState">Category</label>
                                                                        <select
                                                                            className="form-control"
                                                                            id="category_token"
                                                                            name="category_token"
                                                                            onChange={(e) => GetSub(e)}
                                                                            onBlur={formik.handleBlur}
                                                                            value={formik.values.category_token}
                                                                        >
                                                                            <option value="">select category.....</option>
                                                                            {category.map((index) => (
                                                                                <option key={index.id}
                                                                                        value={index.secure_token}>
                                                                                    {index.category_name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        {formik.touched.category_token && formik.errors.category_token ? (
                                                                            <div
                                                                                className="text-danger">{formik.errors.category_token}</div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="inputCity">Subcategory</label>
                                                                        <select
                                                                            onChange={formik.handleChange}
                                                                            className="form-control"
                                                                            id="listing_subcategory_secure_token"
                                                                            name="listing_subcategory_secure_token"
                                                                            onBlur={formik.handleBlur}
                                                                            value={formik.values.listing_subcategory_secure_token}
                                                                        >
                                                                            <option value="">Select a subcategory...
                                                                            </option>
                                                                            {subcategory.map((index) => (
                                                                                <option key={index.id}
                                                                                        value={index.id}>
                                                                                    {index.subcategory_name}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                        {formik.touched.listing_subcategory_secure_token && formik.errors.listing_subcategory_secure_token ? (
                                                                            <div className="text-danger">
                                                                                {formik.errors.listing_subcategory_secure_token}
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ul className="list-inline d-flex justify-content-between">
                                                                <li>
                                                                    <button type="button" onClick={() => BackSteps()}
                                                                            className="default-btn prev-step cursor-pointer">Back
                                                                    </button>
                                                                </li>

                                                                {formik.values.category_token !== "" && formik.values.listing_subcategory_secure_token !== "" ?
                                                                    <li>
                                                                        <button type="button" onClick={() => NextSteps()}
                                                                                className="default-btn next-step cursor-pointer">Continue
                                                                            to
                                                                            next step
                                                                        </button>
                                                                    </li>
                                                                    :
                                                                    <div></div>
                                                                }
                                                            </ul>
                                                        </div>
                                                    )
                                                    : count === 3 ? (
                                                            <div className="tab-pane active" role="tabpanel">
                                                                {/*<h4 className="text-center">Contact Details</h4>*/}
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="inputCity">Cost</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="product_cost"
                                                                                name="product_cost"
                                                                                value={formik.values.product_cost}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                            {formik.touched.product_cost && formik.errors.product_cost ? (
                                                                                <div
                                                                                    className="text-danger">{formik.errors.product_cost}</div>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="inputCity">Cost Currency</label>
                                                                            <select
                                                                                className="form-control"
                                                                                id="cost_currency"
                                                                                name="cost_currency"
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                value={formik.values.cost_currency}
                                                                            >
                                                                                <option value="">select Currency.....</option>
                                                                                {currencies.map((index) => (
                                                                                    <option key={index.code}
                                                                                            value={index.code}>
                                                                                        {index.code}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    {formik.touched.cost_currency && formik.errors.cost_currency ? (
                                                                        <div className="text-danger">
                                                                            {formik.errors.cost_currency}
                                                                        </div>
                                                                    ) : null}

                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="inputCity">Selling Price</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                id="product_price"
                                                                                value={formik.values.product_price}
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                            />
                                                                            {formik.touched.product_price && formik.errors.product_price ? (
                                                                                <div
                                                                                    className="text-danger">{formik.errors.product_price}</div>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-6">
                                                                        <div className="form-group">
                                                                            <label htmlFor="inputCity">Selling Price Currency</label>
                                                                            <select
                                                                                className="form-control"
                                                                                id="price_currency"
                                                                                name="price_currency"
                                                                                onChange={formik.handleChange}
                                                                                onBlur={formik.handleBlur}
                                                                                value={formik.values.price_currency}
                                                                            >
                                                                                <option value="">select Currency.....</option>
                                                                                {currencies.map((index) => (
                                                                                    <option key={index.code}
                                                                                            value={index.code}>
                                                                                        {index.code}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    {formik.touched.price_currency && formik.errors.price_currency ? (
                                                                        <div className="text-danger">
                                                                            {formik.errors.price_currency}
                                                                        </div>
                                                                    ) : null}
                                                                </div>

                                                                <ul className="list-inline d-flex justify-content-between">
                                                                    <li>
                                                                        <button type="button" onClick={() => BackSteps()}
                                                                                className="default-btn prev-step cursor-pointer">Back
                                                                        </button>
                                                                    </li>

                                                                    <li>
                                                                        <button type="button" onClick={() => NextSteps()}
                                                                                className="default-btn next-step cursor-pointer">
                                                                            Continue
                                                                            to
                                                                            next step
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                            </div>

                                                        )
                                                        : count === 4 ? (
                                                                <div className="tab-pane active" role="tabpanel">
                                                                    {/*<h4 className="text-center">Location Details</h4>*/}
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            <div className="form-group">
                                                                                <label htmlFor="inputCity">Select Schedule Time</label>
                                                                                <select
                                                                                    className="form-control"
                                                                                    id="schedule_time"
                                                                                    name="schedule_time"
                                                                                    onChange={formik.handleChange}
                                                                                    onBlur={formik.handleBlur}
                                                                                    value={formik.values.schedule_time}
                                                                                >
                                                                                    <option value="">Select Schedule Time.....</option>
                                                                                    <option value="starting">Require Starting Time</option>
                                                                                    <option value="both">Require Starting Time and End Time</option>
                                                                                    <option value="none">Don't Require Schedule Time</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <ul className="list-inline  d-flex justify-content-between">
                                                                        <li>
                                                                            <button type="button"
                                                                                    onClick={() => BackSteps()}
                                                                                    className="default-btn prev-step cursor-pointer">
                                                                                Back
                                                                            </button>
                                                                        </li>
                                                                        {formik.values.schedule_time !== "" ?
                                                                            <li>
                                                                                <button type="button"
                                                                                        onClick={() => NextSteps()}
                                                                                        className="default-btn next-step cursor-pointer">
                                                                                    Continue
                                                                                    to
                                                                                    next step
                                                                                </button>
                                                                            </li>
                                                                            :
                                                                            <div></div>
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            )
                                                            : count === 5 ? (
                                                                    <div className="tab-pane active" role="tabpanel">
                                                                        {/*<h4 className="text-center">Location Details</h4>*/}
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <DropzoneArea
                                                                                    dropzoneText="Drag and Drop  Service Images"
                                                                                    acceptedFiles={['image/*']}
                                                                                    filesLimit={6}
                                                                                    showAlerts={false}
                                                                                    onChange={files => handleImages(files)}
                                                                                />
                                                                            </div>
                                                                            <br /><br />
                                                                            <div className="col-md-6">
                                                                                <div className="form-group">
                                                                                    <label>
                                                                                        <input type="radio"
                                                                                               id="publish"
                                                                                               name="publish"
                                                                                               onChange={formik.handleChange}
                                                                                               onBlur={formik.handleBlur}
                                                                                               value={true}
                                                                                        />
                                                                                        Save And Publish
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <ul className="list-inline  d-flex justify-content-between">
                                                                            <li>
                                                                                <button type="button"
                                                                                        onClick={() => BackSteps()}
                                                                                        className="default-btn prev-step cursor-pointer">
                                                                                    Back
                                                                                </button>
                                                                            </li>
                                                                            <li>
                                                                                <div className="justify-content-between">
                                                                                    <button
                                                                                        type="button"
                                                                                        onClick={() => CreateListing(formik.values, id ,imageFiles)}
                                                                                        className="default-btn next-step cursor-pointer">
                                                                                        Upload
                                                                                    </button>
                                                                                </div>

                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                )
                                                                :
                                                                <div className="clearfix">Unknown page</div>
                                            }
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddListing)
