import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";
//creating the validation schema
const validationSchema = yup.object().shape({
  phone: yup.string().required("Email is a required field"),
});
const initialValues = {
  phone: "",
};
function PaymentOption(props) {
  const [PaymentOption, setPaymentOption] = useState([]);

  function handlePhone(phone) {
    localStorage.setItem("phone", phone);
  }
  const [value, setValue] = useState(false);
  const { onClick } = props;
  //using useFormik
  const formik = useFormik({
    initialValues,
    validationSchema,
  });

  function handleChange(value, mode) {
    console.log(value);
    if (mode !== "push") {
      localStorage.setItem("phone", "");
      localStorage.setItem("pushValue", false);
    } else {
      localStorage.setItem("pushValue", true);
    }
    localStorage.setItem("paymentOption_token", value.secure_token);
    if (value.payment_number === "Master Card") {
      localStorage.setItem("masterCard", value.payment_number);
    }
    setValue(true);
    return value;
  }
  // console.log(formik.values)
  useEffect(() => {
    const getpaymentopt = async () => {
      const results = await axios
        .get(window.$gateway + "/payment-provider/get-payment-option-icons")
        .then((response) => response.data);
      setPaymentOption(results);
    };
    getpaymentopt();
  }, []);
  const mystyle = {
    image: {
      height: "120px",
      width: "120px",
      marginTop: -10,
    },
    input: {
      marginLeft: 30,
    },
    width: "65%",
  };
  return (
    <div container d-flex justify-content-center>
      <h6>SELECT PAYMENT OPTION</h6>
      {PaymentOption.length === 0 ? (
        <div className="d-flex justify-content-center container">
          <div>
            <Spinner
              style={{ width: "3rem", height: "3rem" }}
              color="success"
            />
            <h6 className="text-success">Please wait payment option....</h6>
          </div>
        </div>
      ) : (
        <>
          <div className="row d-flex justify-content-center container ">
            <div
              class="modal fade"
              id="exampleModalCenter"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLongTitle">
                      Enter Tigo Phone Number
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        name="phone"
                        id="phone"
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="example 255716465381"
                      />
                      {formik.touched.phone && formik.errors.phone ? (
                        <div className="text-danger">{formik.errors.phone}</div>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <button
                        type="button"
                        disabled={!(formik.isValid && formik.dirty)}
                        onClick={() => handlePhone(formik.values.phone)}
                        data-dismiss="modal"
                        aria-label="Close"
                        class="btn btn-primary btn-block"
                      >
                        submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {PaymentOption.map((value) => (
              <div key={value.id} className="col-xs-6 col-sm-3 col-md-3 d-flex justify-content-center">
                <div style={mystyle}>
                  <div style={mystyle.input}>
                    {value.payment_mode === "push" ? (
                      <input
                        className="form-check-input"
                        type="radio"
                        name="Radiobox"
                        id="exampleRadios"
                        onChange={() => handleChange(value, value.payment_mode)}
                        value="option"
                        onClick={() => onClick(true)}
                        data-toggle="modal"
                        data-target="#exampleModalCenter"
                      />
                    ) : (
                      <input
                        className="form-check-input"
                        type="radio"
                        name="Radiobox"
                        id="exampleRadios"
                        onChange={() => handleChange(value, value.payment_mode)}
                        value="option"
                        onClick={() => onClick(true)}
                      />
                    )}

                    <p className="card-text text-uppercase font-weight-bold">
                      {" "}
                      {value.payment_option_name}{" "}
                    </p>
                  </div>
                  <img
                      className="img-fluid"
                    // style={mystyle.image}
                    src={value.pay_options.secure_url}
                    alt="Card image cap"
                  />
                </div>
              </div>
            ))}
          </div>
          {value == false ? (
            <div className="row d-flex justify-content-center  container text-danger">
              Payment Option is Required
            </div>
          ) : (
            <></>
          )}
        </>
      )}

      <br />
    </div>
  );
}
export default PaymentOption;
