import { toast } from "react-toastify";
import { REGISTER_USER, LOGIN_USER } from "./userActionTypes";
import { WishlistDataFromDb } from "../wishlist";
import { CartDataFromDb } from "../cart";
import { Fetchprofile } from "../profile";
import { FetchShippingAddress } from "../shippingAddress";
import Axios from "axios";
import { customertoken, agenttoken } from "../../api/shop";
export function ChangePassword(data,history) {
  const customer_token = customertoken();
  return async (dispatch) =>
    Axios.post(window.$name + "/api/auth/edit-user-password", {
      user_token: customer_token,
      lastPassword: data.currentPassword,
      newpassword: data.NewPassword,
    })
      .then(function (response) {
        toast.success(`"${response.data.message}" `);
        history.push("/account/login");
        // window.location.href = "/account/login";
      })
      .catch(function (error) {
        if (error.response) {
          toast.error(
            `"${error.response.data.message}" Error Status  "${error.response.status}" `
          );
        } else if (error.request) {
          toast.error(`"${error.request}" `);
        } else {
          toast.error(`"${error.message}" `);
        }
      });
}

export function EmailVerification(data) {
  return async (dispatch) =>
    Axios.post(window.$name + "/api/auth/forget-user-password", {
      email: data.email
    })
      .then(function (response) {
        if (response.status === 201) {
          toast.success(`${response.data.message}`);
          window.location.href = "/account/new-password";
        }
        else if (response.status === 202) {
          toast.danger(`${response.data.message}`);
        }

      })
      .catch(function (error) {
        if (error.response) {
          toast.error(
            `"${error.response.data.message}" Error Status  "${error.response.status}" `
          );
        } else if (error.request) {
          toast.error(`"${error.request}" `);
        } else {
          toast.error(`"${error.message}" `);
        }
      });
}
export function NewPassword(data) {
  return async (dispatch) =>
    Axios.post(window.$name + "/api/auth/reset-user-password", {
      verification_token: data.code,
      password: data.password
    })
      .then(function (response) {
        if (response.status === 201) {
          toast.success(`${response.data.message}`);
          window.location.href = "/account/login";
        }

      })
      .catch(function (error) {
        if (error.response) {
          toast.error(
            `"${error.response.data.message}" Error Status  "${error.response.status}" `
          );
        } else if (error.request) {
          toast.error(`"${error.request}" `);
        } else {
          toast.error(`"${error.message}" `);
        }
      });
}
export function RegisterUSer(data,history) {
  toast.info(`Processing...........!!`);
  return async (dispatch) =>
    Axios.post(window.$name + "/api/auth/register-user", data)
      .then(function (response) {
        if (response.status === 201) {
          toast.info(`Waiting for registering  ..?`);
          data = { isLogin: true };
          dispatch({ type: LOGIN_USER, payload: data });
          localStorage.setItem("customer_token", response.data.user);
          localStorage.setItem("customer_id", response.data.user);
          AddcartDataFromRegister();
          AddWishlistData();
          // dispatch(FetchShippingAddress())
          // dispatch(Fetchprofile());
          dispatch(WishlistDataFromDb());
          dispatch(CartDataFromDb());
          toast.success(`${response.data.message}"`);
          history.push('/')
          dispatch(Fetchprofile());
        }
      }).catch(function (error) {
          // console.log(error.response)
          // // if (error.response) {
          if (error.response.status === 401 && error.response.data.message[0].email !== undefined && error.response.data.message[0].email !== "") {
            toast.error("The email has already been taken.");
          }
          else if (error.response.status === 401 && error.response.data.message[0].user_phoneNumber !== undefined && error.response.data.message[0].user_phoneNumber !== "") {
            toast.error("The user phone number has already been taken.");
          }
        });
}

function AddcartDataFromRegister() {
  const carts = JSON.parse(localStorage.getItem("state"));
  let cartArray = carts.cart.items;
  cartArray.forEach((data) => {
    let detail_data = {
      secure_token: customertoken(),
      product_token: data.product.product_token,
      outlet_token: data.product.outlets_token,
      shop_id: data.product.shop_id,
      quantity: data.quantity,
      seller_token: data.product.seller_token,
      product_detail: "",
      total_amount: data.total,
      currency: data.product.currency,
      vat: data.product.vat,
    };
    Axios.post(window.$name + "/api/cart/add-to-cart", detail_data)
      .then(function (response) {
        toast.success(`"${response.data.message}" `);
      })
      .catch(function (error) {
        if (error.response) {
          toast.error(
            `"${error.response.data.message}" Error Status  "${error.response.status}" `
          );
        } else if (error.request) {
          toast.error(`"${error.request}"`);
        } else {
          toast.error(`"${error.message}" `);
        }
      });
  });
}

//wishlist data
function AddWishlistData() {
  const wishlist = JSON.parse(localStorage.getItem("state"));
  const wishlist_data = wishlist.wishlist;
  wishlist_data.forEach((data) => {
    let detail_data = {
      secure_token: customertoken(),
      product_token: data.product_token,
      outlet_token: data.outlets_token,
      shop_id: data.shop_id,
      quantity: 1,
      seller_token: data.seller_token,
      product_detail: "",
      total_amount: data.price,
      currency: data.currency,
      vat: data.vat,
    };
    Axios.post(window.$name + "/api/wishlist/add-to-wish", detail_data)
      .then(function (response) {
        toast.success(`Product Added to Cart Successfully `);
      })
      .catch(function (error) {
        if (error.response) {
          toast.error(
            `"${error.response.data.message}" Error Status  "${error.response.status}" `
          );
        } else if (error.request) {
          toast.error(`"${error.request}" `);
        } else {
          toast.error(`"${error.message}" `);
        }
      });
  });
}
export function AgentPassword(data) {
  const customer_token = agenttoken();
  return async (dispatch) =>
    Axios.post(window.$name + "/api/auth/edit-user-password", {
      user_token: customer_token,
      lastPassword: data.currentPassword,
      newpassword: data.NewPassword,
    })
      .then(function (response) {
        toast.success(`${response.data.message}`);
        window.localStorage.clear();
        window.location.href = "/account/login";
      })
      .catch(function (error) {
        if (error.response) {
          toast.error(
            `"${error.response.data.message}" Error Status  "${error.response.status}" `
          );
        } else if (error.request) {
          toast.error(`"${error.request}" `);
        } else {
          toast.error(`"${error.message}" `);
        }
      });
}
