import { toast } from 'react-toastify';
import { QUOTE_ADD_ITEM, FETCH_QUOTE, QUOTE_REMOVE_ITEM, QUOTE_LOGIN_ITEM, QUOTE_REMOVE_ALL } from './quoteActionTypes';
import Axios from "axios";
import { agenttoken, customertoken, agentcustomerToken } from "../../api/shop";
export function quoteAddItemSuccess(product, options = [], quantity) {
  toast.success(`Product "${product.name}" added to quote!`);
  return {
    type: QUOTE_ADD_ITEM,
    product,
    options,
    quantity,
  };
}

export function quoteRemove() {
  return {
    type: QUOTE_REMOVE_ALL,
  };
}

export function quoteAddLoginItemSuccess(product, options = [], quantity) {
  return {
    type: QUOTE_LOGIN_ITEM,
    product,
    options,
    quantity,
  };
}

export function quoteRemoveItemSuccess(itemId) {
  return {
    type: QUOTE_REMOVE_ITEM,
    itemId,
  };
}


export function QuoteDataFromDb( options = [],) {
  const agentcustomer_Token = agentcustomerToken();
  const agentToken = agenttoken();
  return (dispatch) =>
    new Promise((resolve) => {
      setTimeout(() => {
        if (agentcustomer_Token !== null && agentToken !== null) {
          Axios.post(window.$name + "/api/quote/get-quote-cart", {
            buyer_token: agentcustomer_Token,
          })
            .then(function (response) {
              let quote_list = response.data.quotes_product;
              // console.log(" cart data",response.data);
              dispatch(quoteRemove());
              quote_list.forEach((item) => {
                let product = {
                  id:item.id,
                  shop_id:item.id,
                  seller_token:item.secure_token,
                  name:item.product.product_name,
                  slug:item.product.product_name,
                  images:item.product.images.productImage_name,
                  preference_color: item.preference_color,
                  preference_size: item.preference_size,
                  product_token: item.product.product_token,
                  seller_token: item.product.seller_token,
                  buyer_token: agentcustomerToken(),
                  outlet_token: item.product.secure_token,
                  quantity: item.quantity,
                  quotes_description: item.quotes_description,
                  start_at: item.start_at,
                  end_at: item.end_at
                };
                const quantity = item.quantity;
                // console.log(" cart data",product.vat);
                dispatch( quoteAddLoginItemSuccess(product, options, quantity));
              });
            })
            .catch(function (error) {
              console.log(error);
            });}
        else {
          Axios.post(window.$name + "/api/quote/get-quote-cart", {
            buyer_token: customertoken(),
          })
            .then(function (response) {
              let quote_list = response.data.quotes_product;
              // console.log(" cart data",response.data);
              dispatch(quoteRemove());
              quote_list.forEach((item) => {
                let product = {
                  id:item.id,
                  shop_id:item.id,
                  seller_token:item.secure_token,
                  name:item.product.product_name,
                  slug:item.product.product_name,
                  images:item.product.images.productImage_name,
                  preference_color: item.preference_color,
                  preference_size: item.preference_size,
                  product_token: item.product.secure_token,
                  seller_token: item.product.seller_token,
                  buyer_token:customertoken(),
                  outlet_token: item.product.secure_token,
                  quantity: item.quantity,
                  quotes_description: item.quotes_description,
                  start_at: item.start_at,
                  end_at: item.end_at
                };
                const quantity = item.quantity;
                dispatch( quoteAddLoginItemSuccess(product, options, quantity));
              });
              // console.log(response.data);
              // localStorage.setItem("cartData", JSON.stringify(response.data));
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    });
}



export function QuoteAddItem(product, options = [], quantity) {
  // console.log(product)
  return (dispatch) => (
    new Promise((resolve) => {
      setTimeout(() => {
        if (agentcustomerToken() !== null && agenttoken() !== null) {
          let data = {
            product_token: product.product_token,
            buyer_token: agentcustomerToken(),
            preference_color: product.preference_color,
            preference_size: product.preference_size,
            outlet_token: product.outlets_token,
            quantity: quantity,
            quotes_description: product.quotes_description,
            start_at: product.start_at,
            end_at: product.end_at
          };
          Axios.post(window.$name + "/api/quote/add-quote-cart", data)
            .then(function (response) {
              if (response.status === 201) {
                toast.success(response.data.message);
                localStorage.removeItem("quoteList");
                dispatch(quoteAddLoginItemSuccess(data, options, quantity));
                dispatch(QuoteDataFromDb());
              }

            })
            .catch(function (error) {
              if (error.response) {
                toast.error(
                  `${error.response.data.message} Error Status  ${error.response.status}`
                );
              } else if (error.request) {
                toast.error(`${error.request} `);
              } else {
                toast.error(`${error.message} `);
              }
            });
        }
        else if (customertoken()) {
          let data = {
            product_token: product.product_token,
            buyer_token: customertoken(),
            preference_color: product.preference_color,
            preference_size: product.preference_size,
            outlet_token: product.outlets_token,
            quantity: quantity,
            quotes_description: product.quotes_description,
            start_at: product.start_at,
            end_at: product.end_at
          };
          Axios.post(window.$name + "/api/quote/add-quote-cart", data)
            .then(function (response) {
              if (response.status === 201) {
                toast.success(response.data.message);
                localStorage.removeItem("quoteList");
                dispatch(quoteAddLoginItemSuccess(data, options, quantity));
                dispatch(QuoteDataFromDb());
              }

            })
            .catch(function (error) {
              if (error.response) {
                toast.error(
                  `${error.response.data.message} Error Status  ${error.response.status}`
                );
              } else if (error.request) {
                toast.error(`${error.request} `);
              } else {
                toast.error(`${error.message} `);
              }
            });
        }
        else {
          dispatch(quoteAddItemSuccess(product, options, quantity));
        }


        resolve();
      }, 500);
    })
  );
}

export function postQuote(Quotation, address) {
  console.log(Quotation);
  return (dispatch) =>
    new Promise((resolve) => {
      setTimeout(() => {
        if (agentcustomerToken() !== null && agenttoken() !== null) {
          Quotation.items.forEach((item) => {
            let product = {
              delivery_option_token: localStorage.getItem("deliveryOptions"),
              product_token: item.product.secure_token,
              shipping_address_token: address,
              buyer_token: agentcustomerToken(),
              preference_color: item.product.preference_color,
              preference_size: item.product.preference_size,
              outlet_token: item.product.outlet_token,
              quantity: item.quantity,
              quotes_description: item.product.quotes_description,
              start_at: item.product.start_at,
              end_at: item.product.end_at
            };
            Axios.post(window.$name + "/api/quote/request-quote", product)
              .then(function (response) {
                if (response.status === 201) {
                  toast.success(response.data.message);
                  localStorage.removeItem("quoteList");
                }

              })
              .catch(function (error) {
                if (error.response) {
                  toast.error(
                    `${error.response.data.message} Error Status  ${error.response.status}`
                  );
                } else if (error.request) {
                  toast.error(`${error.request} `);
                } else {
                  toast.error(`${error.message} `);
                }
              });
          });
        }
        else {
          Quotation.items.forEach((item) => {
            let product = {
              delivery_option_token: localStorage.getItem("deliveryOptions"),
              product_token: item.product.secure_token,
              shipping_address_token: address,
              buyer_token: customertoken(),
              preference_color: item.product.preference_color,
              preference_size: item.product.preference_size,
              outlet_token: item.product.outlets_token,
              quantity: item.quantity,
              quotes_description: item.product.quotes_description,
              start_at: item.product.start_at,
              end_at: item.product.end_at
            };
            Axios.post(window.$name + "/api/quote/request-quote", product)
              .then(function (response) {
                if (response.status === 201) {
                  toast.success(response.data.message);
                  localStorage.removeItem("quoteList");
                }

              })
              .catch(function (error) {
                if (error.response) {
                  toast.error(
                    `${error.response.data.message} Error Status  ${error.response.status}`
                  );
                } else if (error.request) {
                  toast.error(`${error.request} `);
                } else {
                  toast.error(`${error.message} `);
                }
              });
          });
        }
        resolve();

      }, 500);


    });



}

export function quoteRemoveItem(itemId) {
  return (dispatch) => (
    new Promise((resolve) => {
      setTimeout(() => {
        dispatch(quoteRemoveItemSuccess(itemId));

        resolve();
      }, 500);
    })
  );
}


