import {toast} from "react-toastify";
import {LOGIN_USER} from "./userActionTypes";
import Axios from "axios";
import {customertoken} from "../../api/shop";
import {WishlistDataFromDb} from "../wishlist";
import {AddcartData, CartDataFromDb, cartRemove} from "../cart";
import {Fetchprofile} from "../profile";
import {FetchShippingAddress} from "../shippingAddress";
import {FetchAgentprofile} from "../Agent";
import {FetchPaymentSettings} from "../paymentAgent";
import {QuoteDataFromDb} from '../quote';
import React from "react"

export function LogoutUser(history) {
    return (dispatch) =>
        new Promise((resolve) => {
            setTimeout(() => {
                dispatch(cartRemove());
                window.localStorage.clear();
                window.location.href = "/"
                // history.push('/')
                resolve();
            }, 500);
        });
}

export function ActivateLoginUSer(data,Token) {
    return async (dispatch) =>
        Axios.post(window.$name + "/api/auth/user-login", {
            token:Token,
            password:data
        })
            .then(function (response) {
                if (response.status === 201 && !response.data.user.is_agent) {
                    data = {
                        isLogin: true,
                    };
                    dispatch({type: LOGIN_USER, payload: data});
                    toast.success(
                        `Dear ${response.data.user.fullname} you have ${response.data.message} `
                    );
                    localStorage.setItem("customer_token", response.data.user.secure_token);
                    AddcartData();
                    AddWishlistData();
                    dispatch(FetchShippingAddress());
                    dispatch(Fetchprofile());
                    dispatch(WishlistDataFromDb());
                    dispatch(CartDataFromDb());
                    window.location.reload();
                } else {
                    toast.info("something went wrong");
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(`${error.response.data.message}`);
                } else if (error.request) {
                    toast.error(`${error.request} `);
                } else {
                    toast.error(`${error.message} `);
                }
            });
}

// agent login function
export function LoginAgent(data,history) {
    return async (dispatch) =>
        Axios.post(window.$name + "/api/auth/agent-login", data)
            .then(function (response) {
                if (response.status === 200 && response.data.user.is_agent && !response.data.user.approve_agent
                ) {
                    toast.warning(`Dear ${response.data.user.fullname} your account is no approved. You will be notified once approved.`
                    );
                } else if (response.status === 200 && response.data.user.is_agent && response.data.user.approve_agent
                ) {
                    data = {
                        isLogin: true,
                    };
                    dispatch({type: LOGIN_USER, payload: data});
                    toast.success(`Dear ${response.data.user.fullname} you have ${response.data.message} `
                    );
                    localStorage.setItem("agent_token", response.data.user.secure_token);
                    dispatch(FetchPaymentSettings());
                    // AddcartData();
                    // AddWishlistData();
                    // dispatch(FetchShippingAddress());
                    // dispatch(Fetchprofile());
                    dispatch(FetchAgentprofile());
                    history.push("/agent/dashboard")
                } else {
                    toast.error("Your not authorized this only for agent");
                }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(`${error.response.data.message}`);
                } else if (error.request) {
                    toast.error(`${error.request} `);
                } else {
                    toast.error(`${error.message} `);
                }
            });
}


export function LoginUSer(data, fromroute,history,Token) {
    if (fromroute !== undefined) {
        let passLink = fromroute.pathname;
        return async (dispatch) =>
            Axios.post(window.$name + "/api/auth/user-login", {
                token:Token,
                password:data
            })
                .then(function (response) {
                    console.log(response.data);
                    if (response.status === 201) {
                        data = {
                            isLogin: true,
                        };
                        dispatch({type: LOGIN_USER, payload: data});
                        toast.success(
                            `Dear ${response.data.user.fullname} you have ${response.data.message}`
                        );
                        localStorage.setItem(
                            "customer_token",
                            response.data.user.secure_token
                        );
                        localStorage.setItem(
                            "customer_id",
                            response.data.user.id
                        );
                        AddcartData();
                        AddWishlistData();
                        dispatch(FetchShippingAddress());
                        dispatch(Fetchprofile());
                        dispatch(FetchPaymentSettings());
                        dispatch(FetchAgentprofile());
                        dispatch(WishlistDataFromDb());
                        dispatch(CartDataFromDb());
                        dispatch(QuoteDataFromDb());
                        window.location.replace(passLink);
                        // history.push(passLink)
                        dispatch(Fetchprofile());
                    } else {
                        toast.info(` ${response.data.message}`);
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        toast.error(`${error.response.data.message}`);
                    } else if (error.request) {
                        toast.error(`${error.request} `);
                    } else {
                        toast.error(`${error.message} `);
                    }
                });
    } else {
        return async (dispatch) =>
            Axios.post(window.$name + "/api/auth/user-login", {
                token:Token,
                password:data
            })
                .then(function (response) {
                    if (response.status === 201) {
                        data = {
                            isLogin: true,
                        };
                        dispatch({type: LOGIN_USER, payload: data});
                        toast.success(`Dear ${response.data.user.fullname} you have ${response.data.message} `
                        );
                        localStorage.setItem("customer_token", response.data.user.secure_token);
                        localStorage.setItem("customer_id", response.data.user.id);
                        AddcartData();
                        AddWishlistData();
                        dispatch(FetchShippingAddress());
                        dispatch(Fetchprofile());
                        dispatch(WishlistDataFromDb());
                        dispatch(QuoteDataFromDb());
                        dispatch(CartDataFromDb());
                        // console.log("dhdfdshfdhsjfhjdsfhj")
                        history.push('/');
                        // window.location.href = "/";
                        // window.location.reload(true);
                        // }
                        // window.location.reload();
                        // window.location.href = "/";

                    } else {
                        toast.info(` ${response.data.message}`);
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        toast.error(`${error.response.data.message}`);
                    } else if (error.request) {
                        toast.error(`${error.request} `);
                    } else {
                        toast.error(`${error.message} `);
                    }
                });
    }
}

//cart data

//wishlist data
function AddWishlistData() {
    const wishlist = JSON.parse(localStorage.getItem("state"));
    const wishlist_data = wishlist.wishlist;
    wishlist_data.forEach((data) => {
        let detail_data = {
            secure_token: customertoken(),
            product_token: data.product_token,
            outlet_token: data.outlets_token,
            shop_id: data.shop_id,
            quantity: 1,
            // custom_quantity:data.unit,
            // custom_unit_measure:data.unitMeasure,
            seller_token: data.seller_token,
            product_detail: "",
            total_amount: data.price,
            currency: data.currency,
            vat: data.vat,
        };
        Axios.post(window.$name + "/api/wishlist/add-to-wish", detail_data)
            .then(function (response) {
                toast.success(`Product Added to Cart Successfully `);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
    });
}
