// react
import React, { useEffect, useState } from 'react';

// third-party
import { Link } from 'react-router-dom';

// application
import Megamenu from './Megamenu';
import Menu from './Menu';
import { ArrowRoundedRight6x9Svg } from '../../svg';

// data stubs
import { loadAllCategories } from '../../api/shop';
import { url } from '../../services/utils';

function convertCategoriesToMenuItems(categories) {
    return categories.map((category) => {
        const item = {
            title: category.name,
            url: url.category(category),
        };

        if (category.children.length > 0) {
            item.submenu = convertCategoriesToMenuItems(category.children);
        }

        return item;
    });
}

function convertCategoriesToDepartmentLinks(categories) {
    return categories.map((category) => {
        const item = {
            title: category.name,
            url: url.category(category),
        };

        if (category.children.length > 0) {
            item.submenu = {
                type: 'menu',
                menu: convertCategoriesToMenuItems(category.children),
            };
        }

        return item;
    });
}

function DepartmentsLinks() {
    const [data, setData] = useState([]);

    useEffect(() => {
        loadAllCategories().then(([tree]) => {
            setData(convertCategoriesToDepartmentLinks(tree));
        });
    }, []);

    const linksList = data.slice(0,10).map((department, index) => {
        let arrow = null;
        let submenu = null;
        let itemClass = '';

        if (department.submenu) {
            arrow = <ArrowRoundedRight6x9Svg className="departments__link-arrow" />;
        }

        if (department.submenu && department.submenu.type === 'menu') {
            itemClass = 'departments__item--menu';
            submenu = (
                <div className="departments__menu">
                    <Menu items={department.submenu.menu} />
                </div>
            );
        }

        if (department.submenu && department.submenu.type === 'megamenu') {
            submenu = (
                <div className={`departments__megamenu departments__megamenu--${department.submenu.menu.size}`}>
                    <Megamenu menu={department.submenu.menu} location="department" />
                </div>
            );
        }

        return (
            <li key={index} className={`departments__item ${itemClass}`}>
                <Link to={department.url}>
                    {department.title}
                    {arrow}
                </Link>
                {submenu}
            </li>
        );
    });

    return (
        <ul className="departments__links">
            {linksList}
            <Link className="container text-white"

                to={'/shop/all-products'}>
                All Categories
            </Link>
        </ul>
    );
}

export default DepartmentsLinks;