// react
import React, { useEffect, useState } from 'react';

// third-party
import { connect ,useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

// application
import Dropdown from './currencyDropdown';
import { currencyChange } from '../../store/currency';
// data stubs
import currencies from '../../data/shopCurrencies';
import { Spinner } from "reactstrap";
import { userCurrency } from '../../api/shop';
import axios from "axios";
import {FetchRate} from '../../store/RateData';
function DropdownCurrency(props) {
    const {FetchRate} =props;
    const [CurrentCurrency, SetCurrency] = useState([]);
    const [Rates, setRates] = useState([]);
    const dispatch = useDispatch();
    // console.log(Rates)
    //user currency     
    useEffect(() => {
        if (!userCurrency()) {
            const getuserdata = async () => {
                const results="TZS"
                // const results = await axios.get("https://pro.ip-api.com/json/?fields=status,currency&key=Pd8zViLGDwyFIVT")
                //     .then((response) => response.data.currency);
                localStorage.setItem("userCurrency", results);
                SetCurrency(results);              
            };
            getuserdata();
        }
    }, []);

    // rate for change depend on user need
    useEffect(() => {
        const getRates = async () => {
            const results = await axios.get(window.$name + "/api/business/get-currency")
                .then((response) => JSON.parse(response.data.rate));
                setRates(results);
                dispatch(FetchRate(results));
        };
        getRates();
    }, []);
    const title = (
        <React.Fragment>
            <FormattedMessage id="topbar.currency" defaultMessage="Currency" />
            {': '}
            <span className="topbar__item-value">

                {CurrentCurrency.length !== 0 || userCurrency() ? (
                    <>{userCurrency()}</>

                ) : (
                        <Spinner size="sm" />
                    )
                }
            </span>
        </React.Fragment>
    );

    return (
        <>
            {Rates.length !== 0 ? (
                <Dropdown
                    title={title}
                    withIcons
                    items={Rates}
                />

            ) : (<>
                <FormattedMessage id="topbar.currency" defaultMessage="Currency" />
                {': '}
                <Spinner size="sm" />
            </>
                )
            }
        </>
    );


}

const mapStateToProps = (state) => ({
    currency: state.currency,
    locale: state.locale,
});

const mapDispatchToProps = {
    currencyChange,
    FetchRate
};

export default connect(mapStateToProps, mapDispatchToProps)(DropdownCurrency);
