// react
import React, { Component } from "react";
// third-party
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import { myownShippingOpt } from "../../store/ShippingOpt";
// data stubs
import theme from "../../data/theme";
import { handleNexts } from "./checkout";
import { Row } from "reactstrap";
import { userCurrency } from "../../api/shop";
import AsyncAction from "../shared/AsyncAction";
import { Cross12Svg } from "../../svg";
import InputNumber from "../shared/InputNumber";
import { url } from "../../services/utils";
import classNames from "classnames";
import { quoteRemoveItem } from "../../store/quote";
class BuynowProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            quantities: [],
            shippingOption: [],
            deliveryoption: false,
            showing: true,
        };
    }

    toggle(id) {
        this.setState({
            deliveryoption: true,
        });
    }

    componentDidMount() {
        fetch(window.$name + "/api/adminstrator/get-customer-shipping")
            .then((response) => response.json())
            .then((datalist) => this.setState({ shippingOption: datalist }));
    }

    getItemQuantity(item) {
        const { quantities } = this.state;
        const quantity = quantities.find((x) => x.itemId === item.id);
        return quantity ? quantity.value : item.quantity;
    }

    renderItems() {
        const { Quotation, quoteRemoveItem } = this.props;
        // console.log(Quotation.items);
        return Quotation.items.map((item) => {
            let image;
            let options;

            if (item.product.images.length > 0) {
                image = (
                    <div className="product-image">
                        <Link
                            to={url.product(item.product)}
                            className="product-image__body"
                        >
                            <img
                                className="product-image__img"
                                src={item.product.images}
                                alt=""
                            />
                        </Link>
                    </div>
                );
            }

            if (item.options.length > 0) {
                options = (
                    <ul className="cart-table__options">
                        {item.options.map((option, index) => (
                            <li
                                key={index}
                            >{`${option.optionTitle}: ${option.valueTitle}`}</li>
                        ))}
                    </ul>
                );
            }

            const removeButton = (
                <AsyncAction
                    action={() => quoteRemoveItem(item.id, item.product.product_token)}
                    render={({ run, loading }) => {
                        const classes = classNames("btn btn-light btn-sm btn-svg-icon", {
                            "btn-loading": loading,
                        });
                        return (
                            <button type="button" onClick={run} className={classes}>
                                <Cross12Svg />
                            </button>
                        );
                    }}
                />
            );

            return (
                <tr key={item.id} className="cart-table__row">
                    <td className="cart-table__column cart-table__column--image">
                        {image}
                    </td>
                    <td className="cart-table__column cart-table__column--quantity">
                        {item.product.preference_color !== "" && item.product.preference_size !== "" ? (
                            <>
                                <Row>
                                    Color :&nbsp;&nbsp; {item.product.preference_color}
                                </Row>
                                <Row>
                                    Size :&nbsp;&nbsp;   {item.product.preference_size}
                                </Row>
                            </>
                        ) : item.product.preference_color !== "" && item.product.preference_size === "" ? (
                            <Row>
                                Color :&nbsp;&nbsp; {item.product.preference_color}
                            </Row>
                        ) : item.product.preference_color === "" && item.product.preference_size !== "" ? (
                            <Row>
                                Size :&nbsp;&nbsp; {item.product.preference_size}
                            </Row>
                        ) :
                                    <span>Empty</span>
                        }
                    </td>

                    <td className="cart-table__column cart-table__column--price">
                        {item.product.name}

                        {options}
                    </td>
                    <td
                        className="cart-table__column cart-table__column--quantity"
                        data-title="Quantity"
                    >
                        {/* <InputNumber
                            onChange={(quantity) => this.handleChangeQuantity(item, quantity)}
                            value={this.getItemQuantity(item)}
                            min={1}
                        /> */}
                        {item.quantity}
                    </td>
                    <td className="cart-table__column cart-table__column--remove">
                        {removeButton}
                    </td>
                </tr>
            );
        });
    }

    renderTotals() {
        const { Quotation } = this.props;

        if (Quotation.extraLines.length <= 0) {
            return null;
        }

        return (
            <React.Fragment>
                <thead className="cart__totals-header">
                    <tr>
                        <th>TOTAL PRICE</th>
                        <td></td>
                    </tr>
                </thead>
                <tbody className="cart__totals-body">{/* {extraLines} */}</tbody>
            </React.Fragment>
        );
    }
    renderCart() {
        const { Rates } = this.props
        let buynow = JSON.parse(localStorage.getItem("BuynowData"));
        console.log(buynow)
        const { shippingOption } = this.state;
        const { myownShippingOpt, activeStep, MyownShipping } = this.props;
        function handleChange(data) {
            localStorage.setItem("deliveryOptions", data.secure_token);
            return data;
        }
        localStorage.getItem("deliveryOptions");
        return (
            <div className="cart block">
                <div className="container">
                    <table className="cart__table cart-table">
                        <thead className="cart-table__head">
                            <tr className="cart-table__row">
                                <th className="cart-table__column cart-table__column">
                                    Product Image
                                </th>
                                <th className="cart-table__column cart-table__column--price">
                                    Preference
                                </th>
                                <th className="cart-table__column cart-table__column--price">
                                    Product Name
                                </th>
                                <th className="cart-table__column cart-table__column--quantity">
                                    Quantity
                                </th>
                                <th
                                    className="cart-table__column cart-table__column--remove"
                                    aria-label="Remove"
                                >
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody className="cart-table__body">{this.renderItems()}</tbody>
                    </table>

                    <div className="row justify-content-end pt-md-5 pt-4">
                        <div className="col-12 col-md-7 col-lg-6 col-xl-5">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="text-center">Select Delivery Option</h5>
                                    <br />
                                    <div>
                                        {shippingOption.map((option) => (
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="Radiobox"
                                                    id="exampleRadios"
                                                    onChange={() => handleChange(option)}
                                                    value="Radiobox"
                                                    onClick={() => myownShippingOpt(option.customer_shipping, true)
                                                    }
                                                />
                                                <label className="form-check-label" for="exampleRadios">
                                                    {option.customer_shipping}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="mt-3">
                                        {!MyownShipping.True && localStorage.getItem("deliveryOptions") === null ? (
                                            <div className="text-center text-danger">
                                                Delivery Option is Required
                                            </div>
                                        ) : (
                                                <></>
                                            )}

                                        <button
                                            type="submit"
                                            disabled={!MyownShipping.True && localStorage.getItem("deliveryOptions") === null}
                                            onClick={() => handleNexts(activeStep)}
                                            className="btn rounded shadow-lg  btn-primary  btn-block cart__checkout-button"
                                        >
                                            Continue
                                      </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }

    render() {
        return (
            <React.Fragment>
                <Helmet>
                    <title>{`Checkout — ${theme.name}`}</title>
                </Helmet>
                {this.renderCart()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    Quotation: state.quotation,
    MyownShipping: state.MyownShipping
});

const mapDispatchToProps = {
    myownShippingOpt,
    quoteRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(BuynowProduct);
