import React from 'react'
import BannerOneHeroHeading from './BannerOneHeroHeading'
import BannerOneSearchInput from './BannerOneSearchInput'
import BannerOneCategories from './BannerOneCategories'
import '../../../assets/css/style.css'
import {Spinner} from "reactstrap";
import PromotedListing from "../PromotedListing";

function BannerOne(props) {
    const {Category,promoted} = props
    return (
        <>
            {Category === undefined ?
                <div className="text-center" style={{
                    marginTop: '10%',
                    marginLeft:'50%',
                    marginRight:'50%'
                }}>
                    <Spinner style={{width: '3rem', height: '3rem'}}  />
                </div>  :
                <section className="hero-wrapper">
                    <div className="hero-overlay"></div>
                    <div className="container">
                        <div className="row" style={{marginTop: '-12rem'}}>
                            <div className="col-lg-12">

                                {/* Banner One Hero Heading */}
                                <BannerOneHeroHeading/>

                                {/* Banner One Search Input */}
                                <BannerOneSearchInput Category={Category}/>

                                {/* Banner One Categories */}
                                {/*<BannerOneCategories Category={Category}/>*/}
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <PromotedListing data={promoted}/>
                    </div>
                </section>
            }
        </>
    )
}

export default BannerOne
