import React from 'react';
import {FaRegCalendarAlt} from 'react-icons/fa'
import '../../../assets/css/style.css'
import {useFormik} from "formik";
import * as yup from "yup";
import {connect} from "react-redux";
import {AddBooking} from "../../../store/booking/DirectoryActions";
import AsyncAction from "../../shared/AsyncAction";
import Button from "../bookmarkButton";
import Axios from "axios";
import {toast} from "react-toastify";
import {FetchListingDetail} from "../../../store/ListingDetails";

const validationSchema = yup.object().shape({
    arrival_date: yup
        .string()
        .required("This field is required"),
    departure_date: yup
        .string()
        .required("This field is required"),
    address: yup
        .string()
        .required("This field is required"),
    city: yup
        .string()
        .required("This field is required"),
    comment: yup
        .string()
        .required("This field is required"),
    first_name: yup
        .string()
        .required("This field is required"),
    last_name: yup
        .string()
        .required("This field is required"),
    phone_number: yup
        .string()
        .required("This field is required"),
    email: yup
        .string()
        .email('Invalid email')
        .required("This field is required"),
    postal_code: yup
        .string()
        .required("This field is required"),

});

function EnquiryNotLogin(props) {
    const {Booking} = props
    // console.log(Booking)
    //using useFormik
    const formik = useFormik({
        initialValues: {
            listing_id: Booking.listing.id,
            first_name: "",
            last_name: "",
            email: "",
            phone_number: "",
            comment: "",
            arrival_date: "",
            departure_date: "",
            city: "",
            address: "",
            postal_code: "",
        },
        validationSchema,

    });

    function MakeBookingEnquiryLocal() {
        const dataNeeded = {
            listing_id: Booking.listing.id,
            first_name: formik.values.first_name,
            last_name: formik.values.last_name,
            email: formik.values.email,
            phone_number: formik.values.phone_number,
            comment: formik.values.comment,
            arrival_date: formik.values.arrival_date,
            departure_date: formik.values.departure_date,
            city: formik.values.city,
            address: formik.values.address,
            postal_code: formik.values.postal_code,
        }
        const makeBooking = async () => {
            const data = await Axios.post(`${window.$name}/api/directory/make/enquiry`, dataNeeded)
                .then((response) => {
                    toast.success(` ${response.data.message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                })
                .catch((error) => {
                    if (error.response) {
                        toast.error(` ${error.response.data.message}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000,
                        });
                    } else if (error.request) {
                        toast.error(` ${error.request}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000,
                        });
                    } else {
                        toast.error(` ${error.message}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000,
                        });
                    }
                });
        };
        makeBooking();
    }

    return (
        <>
            <div className="sidebar-widget date-widget">
                <h3 className="widget-title">
                  Enquiry form
                </h3>
                <div className="title-shape"></div>
                <div className="contact-form-action padding-top-35px">

                    <div className="form-row">
                        <div className="form-group">
                            <label htmlFor="inputCity">First Name</label>
                            <input
                                className="form-control"
                                onChange={formik.handleChange}
                                type="text"
                                id="first_name"
                                name="first_name"
                                onBlur={formik.handleBlur}
                                value={formik.values.first_name}
                            />
                            {formik.touched.first_name && formik.errors.first_name ? (
                                <div className="text-danger">{formik.errors.first_name}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputCity">LastName</label>
                            <input
                                className="form-control"
                                onChange={formik.handleChange}
                                type="text"
                                id="last_name"
                                name="last_name"
                                onBlur={formik.handleBlur}
                                value={formik.values.last_name}
                            />
                            {formik.touched.last_name && formik.errors.last_name ? (
                                <div className="text-danger">{formik.errors.last_name}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputCity">Email</label>
                            <input
                                className="form-control"
                                onChange={formik.handleChange}
                                type="email"
                                id="email"
                                name="email"
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                            />
                            {formik.touched.email && formik.errors.email ? (
                                <div className="text-danger">{formik.errors.email}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputCity">Phone Number</label>
                            <input
                                className="form-control"
                                onChange={formik.handleChange}
                                type="text"
                                id="phone_number"
                                name="phone_number"
                                onBlur={formik.handleBlur}
                                value={formik.values.phone_number}
                            />
                            {formik.touched.phone_number && formik.errors.phone_number ? (
                                <div className="text-danger">{formik.errors.phone_number}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputCity">Arrival date</label>
                            <input
                                className="date-range form-control"
                                onChange={formik.handleChange}
                                type="date"
                                id="arrival_date"
                                name="arrival_date"
                                onBlur={formik.handleBlur}
                                value={formik.values.arrival_date}
                            />
                            {formik.touched.arrival_date && formik.errors.arrival_date ? (
                                <div className="text-danger">{formik.errors.arrival_date}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                            <label htmlFor="inputCity">Departure</label>
                            <input
                                className="date-range form-control"
                                onChange={formik.handleChange}
                                type="date"
                                id="departure_date"
                                name="departure_date"
                                onBlur={formik.handleBlur}
                                value={formik.values.departure_date}
                            />
                            {formik.touched.departure_date && formik.errors.departure_date ? (
                                <div className="text-danger">{formik.errors.departure_date}</div>
                            ) : null}
                        </div>

                    </div>
                </div>
                <div className="mt-4 booking-content d-flex align-items-center justify-content-between text-center">
                    <div className="input-number-group">
                        <p>City</p>
                        <input
                            className="input-number"
                            type="text"
                            name="city"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.city}
                        />
                        {formik.touched.city && formik.errors.city ? (
                            <div className="text-danger">{formik.errors.city}</div>
                        ) : null}
                    </div>
                    <div className="input-number-group">
                        <p>Address</p>
                        <input
                            className="input-number"
                            type="text"
                            name="address"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.address}
                        />
                        {formik.touched.address && formik.errors.address ? (
                            <div className="text-danger">{formik.errors.address}</div>
                        ) : null}

                    </div>
                </div>

                <div className="booking-content d-flex align-items-center justify-content-between text-center mt-4">

                    <div className="input-number-group">
                        <p>postal code</p>
                        <input
                            className="input-number"
                            type="text"
                            id="postal_code"
                            name="postal_code"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.postal_code}
                        />
                        {formik.touched.postal_code && formik.errors.postal_code ? (
                            <div className="text-danger">{formik.errors.postal_code}</div>
                        ) : null}
                    </div>

                </div>
                <div className="form-group mt-3">
                    <label htmlFor="inputCity">Comment</label>
                    <textarea
                        id="comment"
                        name="comment"
                        className="form-control"
                        value={formik.values.comment}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    {formik.touched.comment && formik.errors.comment ? (
                        <div className="text-danger">{formik.errors.comment}</div>
                    ) : null}
                </div>
                <div className="mt-5">
                    <div className="booking-content d-flex align-items-center justify-content-between text-center">
                        <div className="input-number-group">
                            <button
                                type="button"
                                onClick={() => MakeBookingEnquiryLocal()}
                                className={"btn btn-block btn-primary rounded "}
                                disabled={!(formik.isValid && formik.dirty)}
                            >
                                Enquiry Now
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    // shippingAddress: state.shippingAddress,
});
const mapDispatchToProps = {
    AddBooking
};
export default connect(mapStateToProps, mapDispatchToProps)(EnquiryNotLogin);
