// react
import React from "react";
// third-party
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// application

import CurrencyFormat from "react-currency-format";
import { agenttoken } from "../../api/shop";
function WidgetDigitalProducts(props) {
  const { title, products } = props;
    // console.log(products);
  const productsList = products.map((product) => {
    let image;
    let tobedisplayed;
    let price;
    let discount;
      const currency = JSON.parse(product.rate);
      const userCurrency = localStorage.getItem("userCurrency");
      function pacentageDiscount() {
          if (product.discount) {
              const discountAmount = ((Number((product.discount.discount))
                  / Number(currency[product.currency])) * Number(currency[userCurrency])).toFixed(2)
              const pricetobeConverted = (((Number(product.price) + Number(product.vat)) / Number(currency[product.currency])) * Number(currency[userCurrency])).toFixed(2)
              const priceDiscount = Math.round(((Number(discountAmount) * 100) / Number(pricetobeConverted)))
              // const = (Number(USD));
              return priceDiscount
          }
      }
      function PriceDisplay() {
          if (product.discount) {
              const USD = (Number(product.price) + Number(product.vat)
                  - Number(product.discount.discount))
                  / Number(currency[product.currency]);
              const pricediscount = (Number(USD) * Number(currency[userCurrency])).toFixed(2);
              return pricediscount
          } else {
              const USD = Math.round(Number(product.price) + Number(product.vat))
                  / Number(currency[product.currency]);
              const priceNodiscount = (Number(USD) * Number(currency[userCurrency])).toFixed(2);
              return priceNodiscount
          }
      }
      // console.log(product);
    if (agenttoken() && product.ezytrade_commission !== null && product.agent_commission !==null) {
      const product_price = Math.round(
        Number(product.price) + Number(product.vat)
      );
      // const ezyPercentage=product.ezytrade_commission ezyPercentage.ezy_percentage
      const ezytrade_amount =
        (product_price * product.ezytrade_commission.ezy_percentage) / 100;
      const agentamount = Math.round((Number(ezytrade_amount) *  product.agent_commission.agent_percentage) / 100);
      tobedisplayed = (
        <div 
        data-toggle="tooltip"
        data-placement="right"
        title="Agent Commission Amount"
        >
          <CurrencyFormat
            value={agentamount}
            displayType={"text"}
            thousandSeparator={true}
            renderText={(value) => (
              <div >
                {product.currency} {value}
              </div>
            )}
          />
        </div>
      );
    }
    else if(agenttoken()){
      tobedisplayed = (
        <div 
        data-toggle="tooltip"
        data-placement="right"
        title="Agent Commission Amount"
        >
          <CurrencyFormat
            value={0}
            displayType={"text"}
            thousandSeparator={true}
            renderText={(value) => (
              <div >
                {product.currency} {value}
              </div>
            )}
          />
        </div>
      );
    }
    if (product.images && product.images.length > 0) {
      image = (
        <div className="widget-products__image">
          <div className="product-image">
            <Link
              to={`/shop/products/${product.product_token}/${product.outlets_token}/${product.Category}`}
              className="product-image__body"
            >
              <img
                className="product-image__img"
                src={product.images}
                alt=""
              />
            </Link>
          </div>
        </div>
      );
    }
    
    if (product.discount !== null) {
      discount = (
        <div className="product-card__badge product-card__badge--sale">
         {pacentageDiscount()}{" "}
          % OFF
        </div>
      );
      price = (
        <React.Fragment>
          <span className="badge badge-pill">{discount}</span>{" "}
          <span className="widget-products__new-price">
          <CurrencyFormat
              // value={Math.round(
              //   Number(product.price) +
              //     Number(product.vat) -
              //     Number(product.discount.discount)
              // )}
              value={PriceDisplay()}
              displayType={"text"}
              thousandSeparator={true}
              renderText={(value) => (
                <div>

                    {userCurrency}{" "}{value}
                  {/*{product.currency} {" "} {value}*/}
                </div>
              )}
            />
          </span>{" "}
          <span className="widget-products__old-price">
          <CurrencyFormat
              value={(((Number(product.price) + Number(product.vat)) / Number(currency[product.currency])) * Number(currency[userCurrency])).toFixed(2)
              }
              displayType={"text"}
              thousandSeparator={true}
              renderText={(value) => (
                <div>
                    {" "}
                  {/*{product.currency} {value}*/}
                    {userCurrency}{" "} {value}
                </div>
              )}
            />
          </span>
          {" "}         
          <span>{tobedisplayed}</span>
        </React.Fragment>
      );
    } else {
      price = (
        <>

        <CurrencyFormat
          value={PriceDisplay()}
          displayType={"text"}
          thousandSeparator={true}
          renderText={(value) => (
            <div>

                {userCurrency} {" "}{value}
              {/*{product.currency} {value}*/}
            </div>
          )}
        />
        <div>{tobedisplayed}</div>
        </>
      );
    }

    return (
      <div key={product.id} className="widget-products__item">
        {image}
        <div className="widget-products__info">
          <div className="widget-products__name">
            <Link
              to={`/shop/products/${product.product_token}/${product.outlets_token}/${product.Category}`}
            >
              {product.name}
            </Link>
          </div>
          <div className="widget-products__prices">
            {!product.price_tag ? <> {price}</> 
            : 
            <>
           <Link
              to={`/shop/products/${product.product_token}/${product.outlets_token}/${product.Category}`}
            >
            Request Quote
            </Link>
            </>}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className="widget-products widget">
      <h4 className="widget__title">{title}</h4>
      <div className="widget-products__list">{productsList}</div>
    </div>
  );
}

WidgetDigitalProducts.propTypes = {
  /**
   * widget title
   */
  title: PropTypes.node,
  /**
   * array of product objects
   */
  products: PropTypes.array,
};

WidgetDigitalProducts.defaultProps = {
  products: [],
};

export default WidgetDigitalProducts;
