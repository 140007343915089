import {
  QUOTE_ADD_ITEM,
  QUOTE_REMOVE_ALL,
  QUOTE_REMOVE_ITEM,
  QUOTE_LOGIN_ITEM
} from "./quoteActionTypes";
import Axios from "axios";
import { customertoken } from "../../api/shop";
import { toast } from "react-toastify";

/**
 * @param {array} items
 * @param {object} product
 * @param {array} options
 * @return {number}
 */
function findItemIndex(items, product, options) {
  return items.findIndex((item) => {
    if (
      item.product.id !== product.id ||
      item.options.length !== options.length
    ) {
      return false;
    }
    for (let i = 0; i < options.length; i += 1) {
      const option = options[i];
      const itemOption = item.options.find(
        (itemOption) =>
          itemOption.optionId === option.optionId &&
          itemOption.valueId === option.valueId
      );
      if (!itemOption) {
        return false;
      }
    }

    return true;
  });
}

function calcSubtotal(items) {
  return items.reduce((subtotal, item) => subtotal + item.total, 0);
}

function calcQuantity(items) {
  return items.reduce((quantity, item) => quantity + item.quantity, 0);
}

function calcTotal(subtotal, extraLines) {
  return subtotal + extraLines.reduce((total, extraLine) => total, 0);
}

function quoteaddItem(state, product, options, quantity) {
  const itemIndex = findItemIndex(state.items, product, options);
  let newItems;
  let { lastItemId } = state;
  if (itemIndex === -1) {
    lastItemId += 1;
    newItems = [
      ...state.items,
      {
        id: lastItemId,
        product: JSON.parse(JSON.stringify(product)),
        options: JSON.parse(JSON.stringify(options)),
        quantity,
      },
    ];
  } else {
    const item = state.items[itemIndex];
    newItems = [
      ...state.items.slice(0, itemIndex),
      {
        ...item,
        quantity: item.quantity + quantity,
      },
      ...state.items.slice(itemIndex + 1),
    ];
  }
  return {
    ...state,
    lastItemId,
    items: newItems,
    quantity: calcQuantity(newItems),
  };
}
function quoteaddLOGINItem(state, product, options, quantity) {
    const itemIndex = findItemIndex(state.items, product, options);
    let newItems;
    let {lastItemId} = state;
    if (itemIndex === -1) {
        lastItemId += 1;
        newItems = [...state.items, {
            id: lastItemId,
            product: JSON.parse(JSON.stringify(product)),
            options: JSON.parse(JSON.stringify(options)),
            quantity,
        }];
    } else {
        const item = state.items[itemIndex];
        newItems = [
            ...state.items.slice(0, itemIndex),
            {
                ...item,
                quantity: item.quantity + quantity,
            },
            ...state.items.slice(itemIndex + 1),
        ];
    }
    return {
        ...state,
        lastItemId,
        items: newItems,
        quantity: calcQuantity(newItems),
    };
}
function qouteremoveItem(state, itemId) {
  const { items } = state;
  const newItems = items.filter((item) => item.id !== itemId);

  const subtotal = calcSubtotal(newItems);
  const total = calcTotal(subtotal, state.extraLines);

  return {
    ...state,
    items: newItems,
    quantity: calcQuantity(newItems),
    subtotal,
    total,
  };
}

function deleteAll(state) {
  const { items } = state;
  const newItems = [];
  const subtotal = 0;
  const total = 0;
  const vattotal = 0;
  return {
    ...state,
    items: newItems,
    quantity: 0,
    subtotal,
    vattotal,
    // totalamount,
    total,
  };
}

const initialState = {
  lastItemId: 0,
  quantity: 0,
  items: [],
  extraLines: [
    // shipping, taxes, fees, .etc
    {
      type: "shipping",
      title: "Shipping",
      price: 0,
    },
    {
      type: "tax",
      title: "Tax",
      price: 0,
    },
  ],
  total: 0,
};

export default function QuoteReducer(state = initialState, action) {
  switch (action.type) {
    case QUOTE_ADD_ITEM:
      return quoteaddItem(
        state,
        action.product,
        action.options,
        action.quantity
      );
    case QUOTE_LOGIN_ITEM:
        return quoteaddLOGINItem(state, action.product, action.options, action.quantity);

    case QUOTE_REMOVE_ALL:
      return deleteAll(state);
    case QUOTE_REMOVE_ITEM:
      return qouteremoveItem(state, action.itemId);

    default:
      return state;
  }
}
