import {customerId, customertoken} from "../../api/shop";
import Axios from "axios";
import {BOOKING_DATA} from "./DirectoryActionTypes";
import {toast} from "react-toastify";

export function FetchBooking() {
    return async (dispatch) =>
        Axios.get(`${window.$name}/api/directory/consumer/show/listings/booking/${customertoken()}`)
            .then(function (response) {
                // console.log("agent", response.data);
                // if (response.data.status === 401) {
                //   dispatch({ type: BOOKING_DATA, payload: [] });
                // } else {
                dispatch({type: BOOKING_DATA, payload: response.data});
                // }
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

export function DeleteBooking(booking_token) {
    return async (dispatch) =>
        Axios.get(`${window.$name}/api/directory/consumer/cancel/listings/booking/${booking_token}`)
            .then(function (response) {
                toast.success(`${response.data.message}`);
                dispatch(FetchBooking());
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

export function AddBooking(value) {
    const data = {
        shop_user_id:customerId(),
        listing_id: value.listing_id,
        adult: value.adult,
        child: value.child,
        starting_date:value.start_date,
        end_date:value.end_date,
        starting_time:value.start_time,
        end_time:value.end_time,
        comment:value.comment
    }
    Axios.post(window.$name + `/api/directory/consumer/make/listing/booking/${customertoken()}`, data)
        .then(function (response) {
            toast.success(`${response.data.message}`);
        })
        .catch(function (error) {
            if (error.response) {
                toast.error("something is wrong");
            }
        });

}
