import qs from "query-string";
import {getCategories} from "../fake-server/endpoints/categories";
import {
    fetchAllpopularProducts,
    fetchCategories,
    getAllCategories,
    fetchNewArrivals,
    fechProducts,
    ProductFilter, fetchServiceFeatureProduct, fetchServiceLatestProduct,
} from "./serviceEzytradeApi";
import axios from "axios";
// console.log(fetchAllProducts())
let categoriesPromise = null;
let categoriesWithImagesPromise = null;
let categoriesdata = null;
let productsPromise = null;

function convertCategoryLvl3ToTemplateFormat(apiCategory, parent) {
    return {
        customFields: {original: apiCategory, originalLevel: 3},
        id: `${parent.id}-${apiCategory.subcategory_sub}`,
        image: null,
        items: 0,
        name: apiCategory.subcategory_sub,
        parent,
        children: [],
        slug: apiCategory.secure_token,
    };
}

function convertCategoryLvl2ToTemplateFormat(apiCategory, parent) {
    const category = {
        customFields: {original: apiCategory, originalLevel: 2},
        id: `${parent.id}-${apiCategory.id}`,
        image: null,
        items: 0,
        name: apiCategory.subcategory,
        parent,
        children: [],
        slug: apiCategory.subcategory_token,
    };

    category.children = apiCategory.subcategories_model
        ? apiCategory.subcategories_model.map((sub) =>
            convertCategoryLvl3ToTemplateFormat(sub, category)
        )
        : [];

    return category;
}

function convertCategoryLvl1ToTemplateFormat(apiCategory) {
    const category = {
        customFields: {original: apiCategory, originalLevel: 1},
        id: apiCategory.id.toString(),
        image: null,
        items: 0,
        name: apiCategory.category,
        parent: null,
        children: [],
        slug: apiCategory.secure_token,
    };

    category.children = apiCategory.category_model.map((sub) =>
        convertCategoryLvl2ToTemplateFormat(sub, category)
    );

    return category;
}

function convertCategoryLvl1WithImageToTemplateFormat(apiCategory) {
    const category = {
        customFields: {original: apiCategory, originalLevel: 1},
        id: apiCategory.id.toString(),
        image: apiCategory.category_Image,
        items: 0,
        name: apiCategory.category,
        parent: null,
        children: [],
        slug: apiCategory.secure_token,
    };
    category.children = apiCategory.header.map((sub) =>
        convertCategoryLvl2ToTemplateFormat(sub, category)
    );
    return category;
}

// loadAllCategories
function prepareCategory(category, depth) {
    let children;

    if (depth && depth > 0) {
        children = category.children.map((x) => prepareCategory(x, depth - 1));
    }
    return JSON.parse(
        JSON.stringify({
            ...category,
            parent: category.parent ? prepareCategory(category.parent) : null,
            children,
        })
    );
}

function makeListFromTree(categories) {
    return categories
        .map((category) => [category, ...makeListFromTree(category.children)])
        .reduce((acc, part) => [...acc, ...part], []);
}

function getCategoriesPath(category) {
    return category.parent
        ? [...getCategoriesPath(category.parent), category]
        : [category];
}

// clear local storage
export function loadAllCategories() {
    if (categoriesPromise === null) {
        categoriesPromise = fetch(
            window.$name + "/api/adminstrator/get-all-categories"
        )
            .then((response) => response.json())
            .then((json) => {
                // console.log(json);
                // megaData(json);
                return json.map(convertCategoryLvl1ToTemplateFormat);
            })
            .then((categories) => [categories, makeListFromTree(categories)]);
    }

    return categoriesPromise;
}

export function customertoken() {
    let token;
    token = localStorage.getItem("customer_token");
    return token;
}

export function userCurrency() {
    let currency;
    currency = localStorage.getItem("userCurrency");
    return currency;
}

export function dataquery() {
    let data;
    data = localStorage.getItem("dataquery");
    return data;
}

export function datacategory() {
    let data;
    data = localStorage.getItem("datacategory");
    return data;
}

export function agenttoken() {
    let token;
    token = localStorage.getItem("agent_token");
    return token;
}

export function agentcustomerToken() {
    let token;
    token = localStorage.getItem("agentcustomer_token");
    return token;
}

function outletToken() {
    let token;
    token = localStorage.getItem("outlet_token");
    return token;
}

export function PaymentToken() {
    let token;
    token = localStorage.getItem("paymentOption_token");
    return token;
}

export function loadAllCategoriesWithImages() {
    if (categoriesWithImagesPromise === null) {
        categoriesWithImagesPromise = fetch(
            window.$name + "/api/adminstrator/get-all-categories-images"
        )
            .then((response) => response.json())
            .then((json) => {
                return json.map(convertCategoryLvl1WithImageToTemplateFormat);
            })
            .then((categories) => [categories, makeListFromTree(categories)]);
    }

    return categoriesWithImagesPromise;
}

function toServiceJson(list) {
    // console.log(list);
    if (list) {
        const data = list.map((item) => ({
                id: item.id,
                name: item.product_name,
                description: item.product_description,
                images: item.service_image,
                token: item.token
            })
        )
        return data
    }

}

function toListingJson(list) {
    //  console.log("list",list);
    if (list) {
        const data = list.listings.map((item) => ({
                id: item.id,
                url_head:item.url_head,
                name: item.listing_title,
                location:item.location.country.country,
                images: item.image_search.image_url,
                token: item.secure_token
            })
        )
        return data
    }

}

// console.log(toProductJson());

function convertProductToTemplateFormat(apiProduct, rate) {
    // console.log(apiProduct)
    return {
        attributes: [],
        availability: "in-stock",
        badges: [],
        brand: apiProduct.product.brand,
        categories: [],
        compareAtPrice: null,
        customFields: {
            original: apiProduct,
        },
        rate: rate.rate,
        price_tag: apiProduct.shop_price.price_tag,
        vat: apiProduct.shop_price.total_retail_vat,
        wholesale_vat: parseInt(apiProduct.shop_price.total_wholesale_vat),
        acquistion_cost: parseInt(apiProduct.product.acquistion_cost),
        acquistion_currency: apiProduct.product.acquistion_currency,
        vat_check: apiProduct.shop_price.vat_check,
        ezytrade_commission: apiProduct.product.ezy_commmission,
        agent_commission: apiProduct.product.agent_commmission,
        discount: apiProduct.product.discount,
        customUoM: apiProduct.product.customUoM,
        unitMeasure: apiProduct.product.unitMeasure,
        id: apiProduct.id,
        images: apiProduct.product.images.productImage_name,
        name: apiProduct.product.product_name,
        price: parseFloat(apiProduct.shop_price.retailprice),
        digital: apiProduct.product.is_digital,
        rating: 0,
        reviews: 0,
        shop_id: apiProduct.shop_id,
        seller_token: apiProduct.product.business.secure_token,
        // seller_token: apiProduct.secure_token,
        sku: apiProduct.product.product_number,
        slug: `product-${apiProduct.id}`,
        secure_token: apiProduct.secure_token,
        product_token: apiProduct.product_token,
        outlets_token: apiProduct.outlet_token,
        currency: apiProduct.shop_price.retail_currency,
        Category: apiProduct.product.categories.category,
        category: apiProduct.product.categories.category,
        // Category: apiProduct.Category.category,
        // category: apiProduct.Category.category,
    };
}

function loadProducts(options = {}, filters = {}) {
    const queryParams = {};
    // const outletToken='6lqQQA1aHnWuqqnO';
    if (options.page) {
        queryParams.page = options.page;
    }
    // brand filter with category only
    if (filters.category && filters.brand) {
        if (filters.category.customFields.originalLevel === 1) {
            queryParams.category_name = filters.category.name;
        } else if (filters.category.customFields.originalLevel === 2) {
            queryParams.subcategory_header_name = filters.category.name;
        } else if (filters.category.customFields.originalLevel === 3) {
            queryParams.subcategory_name = filters.category.name;
        }
    }
    // brand filter only
    else if (filters.brand) {
        queryParams.brand = filters.brand.name;
    }
    //  category filter without brand
    else if (filters.category) {
        if (filters.category.customFields.originalLevel === 1) {
            queryParams.category = filters.category.name;
        } else if (filters.category.customFields.originalLevel === 2) {
            queryParams.subcategory_header = filters.category.name;
        } else if (filters.category.customFields.originalLevel === 3) {
            queryParams.subcategory = filters.category.name;
        }
    }
    if (filters.brand) {
        queryParams.brand = filters.brand
    }
    return fetch(
        // window.$name +`/api/consumer/outlet-products?outlet_token=${outletToken()}&${qs.stringify(queryParams)}`
        window.$name + `/api/consumer/all-products?digital=true${qs.stringify(queryParams)}`
    ).then((response) => response.json());
}

export function loadSearchedProduct(options = {}, filters = {}) {
    const queryData = dataquery();
    const categoryData = datacategory();
    const queryParams = {};
    // console.log(options,filters,queryData,categoryData);
    // const outletToken='6lqQQA1aHnWuqqnO';
    if (options.page) {
        queryParams.page = options.page;
    }
    // brand filter with category only
    if (filters.category && filters.brand) {
        if (filters.category.customFields.originalLevel === 1) {
            queryParams.category_name = filters.category.name;
        } else if (filters.category.customFields.originalLevel === 2) {
            queryParams.subcategory_header_name = filters.category.name;
        } else if (filters.category.customFields.originalLevel === 3) {
            queryParams.subcategory_name = filters.category.name;
        }
    }
    // brand filter only
    else if (filters.brand) {
        queryParams.brand = filters.brand.name;
    }
    //  category filter without brand
    else if (filters.category) {
        if (filters.category.customFields.originalLevel === 1) {
            queryParams.category = filters.category.name;
        } else if (filters.category.customFields.originalLevel === 2) {
            queryParams.subcategory_header = filters.category.name;
        } else if (filters.category.customFields.originalLevel === 3) {
            queryParams.subcategory = filters.category.name;
        }
    }
    if (filters.brand) {
        queryParams.brand = filters.brand
    }
    // if (!queryData && !query) {
    //   return fetch(
    //     // window.$name +`/api/consumer/outlet-products?outlet_token=${outletToken()}&${qs.stringify(queryParams)}`
    //     window.$name + `/api/consumer/all-products?${qs.stringify(queryParams)}`
    //   ).then((response) => response.json());
    // }
    // else {
    if (categoryData === "") {

        return fetch(window.$name + `/api/product/search-product?search=${queryData}&${qs.stringify(queryParams)}`)
            .then((response) => response.json());
    } else {
        //  console.log("have ")
        return fetch(window.$name + `/api/product/search-product?search=${queryData}&category=${categoryData}&${qs.stringify(queryParams)}`)
            .then((response) => response.json());
    }

    // }

}

// (loadSearchedProduct())
const serviceShopApi = {
    /**
     * Returns array of categories.
     *
     * @param {object?} options
     * @param {number?} options.depth
     *
     * @return {Promise<Array<object>>}
     */
    getCategories: (options = {}) => {
        return fetchCategories(options);
    },
    /**
     * Returns category by slug.
     *
     * @param {string} slug
     * @param {object?} options
     * @param {number?} options.depth
     *
     * @return {Promise<object>}
     */

    getCategoryBySlug: async (slug, options = {}) => {
        const [, categoriesList] = await loadAllCategories();
        const category = categoriesList.find((x) => x.slug === slug);

        if (category) {
            return prepareCategory(category, options.depth);
        }

        //  throw Error('Not found');
    },
    /**
     * Returns product.
     *
     * @param {string} slug
     *
     * @return {Promise<object>}
     */

    getProductBySlug: (slug) => {
        return Promise.resolve(ProductFilter()).then(function (result) {
            const product = result.find((x) => x.slug === slug);
            return product ? Promise.resolve(product) : Promise.reject();
        });
    },


    /**
     * Return products list.
     *
     * @param {object?} options
     * @param {number?} options.page
     * @param {number?} options.limit
     * @param {string?} options.sort
     * @param {Object.<string, string>?} filters
     *
     * @return {Promise<object>}
     */
    getProductsList: async (options = {}, filters = {}) => {
        const [categoriesTree, categoriesList] = await loadAllCategories();
        const category = categoriesList.find((x) => x.slug === filters.category);
        const json = await loadProducts(options, {...filters, category});
        // const json = await loadSearchedProduct(options, { ...filters, category });
        // console.log(json);
        const listFilters = [];
        // categories filter
        if (category) {
            listFilters.push({
                items: [prepareCategory(category, 1)],
                name: "Categories",
                slug: "category",
                type: "category",
                value: filters.category,
            });
        } else {
            listFilters.push({
                items: categoriesTree.map((x) => prepareCategory(x)),
                name: "Categories",
                slug: "category",
                type: "category",
                value: null,
            });
        }

        // brands filter
        if (json.brands.length > 0) {
            const filterValue = [];

            if (filters.brand) {
                filterValue.push(filters.brand);
            }
            listFilters.push({
                items: json.brands.map((brandName) => {
                    return {
                        slug: brandName,
                        name: brandName,
                        // count: 1,
                    };
                }),
                name: "Brand",
                slug: "brand",
                type: "check",
                value: filterValue,
            });
        }
        const items = Object.keys(json.products.data).map((key) => {
            return convertProductToTemplateFormat(json.products.data[key], json.rates);
        });
        return {
            page: json.products.current_page,
            limit: json.products.per_page,
            sort: "default",
            total: json.products.total,
            pages: json.products.last_page,
            from: json.products.from,
            to: json.products.to,
            items,
            filters: listFilters,
        };
    },
    getSearchedData: async (options = {}, filters = {}) => {
        const [categoriesTree, categoriesList] = await loadAllCategories();
        const category = categoriesList.find((x) => x.slug === filters.category);
        const json = await loadSearchedProduct(options, {...filters, category});
        // console.log(json);
        const listFilters = [];
        // categories filter
        if (category) {
            listFilters.push({
                items: [prepareCategory(category, 1)],
                name: "Categories",
                slug: "category",
                type: "category",
                value: filters.category,
            });
        } else {
            listFilters.push({
                items: categoriesTree.map((x) => prepareCategory(x)),
                name: "Categories",
                slug: "category",
                type: "category",
                value: null,
            });
        }

        // brands filter
        if (json.brand.length > 0) {
            const filterValue = [];

            if (filters.brand) {
                filterValue.push(filters.brand);
            }
            listFilters.push({
                items: json.brand.map((brandName) => {
                    return {
                        slug: brandName,
                        name: brandName,
                        // count: 1,
                    };
                }),
                name: "Brand",
                slug: "brand",
                type: "check",
                value: filterValue,
            });
        }
        const items = Object.keys(json.products.data).map((key) => {
            return convertProductToTemplateFormat(json.products.data[key], json.rates);
        });
        return {
            page: json.products.current_page,
            limit: json.products.per_page,
            sort: "default",
            total: json.products.total,
            pages: json.products.last_page,
            from: json.products.from,
            to: json.products.to,
            items,
            filters: listFilters,
        };
    },
    /**
     * Returns array of featured products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getFeaturedProducts: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/featured-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/featured-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());
        // This is for demonstration purposes only. Remove it and use the code above.
        return fechProducts();
    },
    /**
     * Returns array of latest products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    /**
     * Returns an array of top rated products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getTopRatedProducts: (options = {}) => {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/top-rated-products.json?limit=3&category=power-tools
         *
         * where:
         * - 3           = options.limit
         * - power-tools = options.category
         */
        // return fetch(`https://example.com/api/top-rated-products.json?${qs.stringify(options)}`)
        //     .then((response) => response.json());
        // This is for demonstration purposes only. Remove it and use the code above.
        return fechProducts();
    },
    /**
     * Returns an array of most popular products.
     *
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getPopularProducts: (options = {}) => {
        return fetchAllpopularProducts();
    },
    NewArrivalsProducts: (options = {}) => {
        return fetchNewArrivals();
    },
    getfeaturedProducts: (options = {}) => {
        return fechProducts();
    },
    getFeaturedService: (options = {}) => {
        return fetchServiceFeatureProduct();
    },
    getLatestService: (options = {}) => {
        return fetchServiceLatestProduct();
    },
    /**
     * Returns search suggestions.
     *
     * @param {string}  query
     * @param {string} filters
     * @param {object?} options
     * @param {number?} options.limit
     * @param {string?} options.category
     *
     * @return {Promise<Array<object>>}
     */
    getSuggestions: (query, options = {}) => {

        // if (options.category === undefined) {

        return axios.post(window.$name + '/api/consumer/search-service', {
            search: query
        })
            // /api/product/search-product?search=${query}`)
            .then((response) =>
                // console.log(response.data)
                toServiceJson(response.data)
            );
        // }
        // else {
        //     return axios.get(window.$name + `/api/product/search-product?search=${query}&category=${options.category}`)
        //         .then((response) =>
        //             toProductJson(response.data.products.data, response.data.rates.rate)
        //         );
        // }
    },
    getSuggestedListings: (query, options = {}) => {

        // if (options.category === undefined) {

        return axios.post(window.$name + '/api/directory/search', {
            string: query
        })
            .then((response) =>
                //    console.log(response.data)
                 toListingJson(response.data)
            );
    },
    getCustomers: (query) => {
        return axios.get(window.$name + `/api/agent/search-user?agent_token=${agenttoken()}&search_user=${query}`)
            .then((response) =>
                response.data
            );
    }
};

export default serviceShopApi;
