// react
import React, { useEffect, useState } from "react";
// third-party
import classNames from "classnames";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import AsyncAction from "../shared/AsyncAction";
import { Cross10Svg, Heart20Svg } from "../../svg";
import { Scrollbars } from "react-custom-scrollbars";
import Indicator from "./Indicator";
import { customertoken, agentcustomerToken, userCurrency } from "../../api/shop";
import { digitalWishlistDataFromDb,  digitalwishlistRemoveItem } from "../../store/digitalWishlist";
function IndicatorDigitalWishlist(props) {
    const [state, setState] = useState(false);
    const clickDropdown = () => setState(false)
    const dispatch = useDispatch();
    useEffect(() => {
        if (customertoken() || agentcustomerToken()) {
            dispatch(digitalWishlistDataFromDb());
        }
    }, []);
    const { wishlist,Rates,  digitalwishlistRemoveItem } = props;
    let dropdown;
    const items = wishlist.map((item) => {
        let options;
        let image;

        if (item.images.length) {
            image = (
                <div className="product-image dropcart__product-image">
                    <Link className="product-image__body">
                        <img
                            className="product-image__img"
                            src={item.images}
                            alt=""
                        />
                    </Link>
                </div>
            );
        }

        const removeButton = (
            <AsyncAction
                action={() =>  digitalwishlistRemoveItem(item.id, item.product_token)}
                render={({ run, loading }) => {
                    const classes = classNames(
                        "dropcart__product-remove btn btn-light btn-sm btn-svg-icon",
                        {
                            "btn-loading": loading,
                        }
                    );
                    return (
                        <button type="button" onClick={run} className={classes}>
                            <Cross10Svg />
                        </button>
                    );
                }}
            />
        );

        return (
            <div key={item.id} className="dropcart__product ">
                {image}
                <div className="dropcart__product-info">
                    <div className={"dropcart__product-name"}>
                        <Link >{item.name}</Link>
                    </div>
                    {options}
                    <div className="dropcart__product-meta">
                        <span className="dropcart__product-quantity">{item.unit}</span>
                        {" × "}
                        <span className="dropcart__product-price">
                            {/* <CurrencyFormat
                                value={item.price}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={item.currency}
                                renderText={(value) => <div>{value}</div>}
                            /> */}
                             {userCurrency() === item.currency ? (
                            <CurrencyFormat
                              value={Math.round((Number(item.price) / Number(Rates[item.currency])) * Number(Rates[userCurrency()]))
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              renderText={(value) => (
                                <div>
                                  {userCurrency()}{" "}{value}
                                </div>
                              )}
                            />
                          ) : (
                              <CurrencyFormat
                                value={((Number(item.price) / Number(Rates[item.currency])) * Number(Rates[userCurrency()])).toFixed(2)
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                renderText={(value) => (
                                  <div>
                                    {userCurrency()}{" "}{value}
                                  </div>
                                )}
                              />
                            )
                          }
                        </span>
                    </div>
                </div>
                {removeButton}

            </div>
        );
    });
    //button returns

    if (wishlist.length) {
        dropdown = (
            <div className="dropcart">
                <Scrollbars style={{ height: 200 }}>
                    <div className="dropcart__products-list">{items}</div>
                </Scrollbars>
                <Link to="/digital/wishlist" className="btn btn-primary btn-sm btn-block rounded shadow-lg">My wishlist</Link>
                {/*<Link to="/shop/wishlist">*/}
            </div>

        );
    } else {
        dropdown = (
            <>
                {wishlist.length <= 0 ?
                    <div className="dropcart">
                        <div className="dropcart__empty">Your wish list is empty!</div>
                    </div> :
                    <></>
                }
            </>
        );
    }

    return (
        <Indicator
            dropdown={dropdown}
            value={wishlist.length}
            icon={<Heart20Svg />}
            onClick={clickDropdown}
        />
    );
}

const mapStateToProps = (state) => ({
    wishlist: state.digitalwishlist,
    Rates:state.RateList,
});

const mapDispatchToProps = {
    digitalwishlistRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorDigitalWishlist);
