// react
import React, { Component } from "react";
// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import AsyncAction from "../shared/AsyncAction";
import { cartRemoveItem, cartUpdateQuantities } from "../../store/cart";
import {myownShippingOpt} from "../../store/ShippingOpt";
import { Cross12Svg } from "../../svg";
import { url } from "../../services/utils";
// data stubs
import theme from "../../data/theme";
import { handleNexts } from "./checkout";
// import { useToasts } from "react-toast-notifications";
import InputNumber from "../shared/InputNumber";
import {Row} from "reactstrap";
import {userCurrency } from "../../api/shop";
class ShopPageCart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantities: [],
      shippingOption: [],
      deliveryoption: false,
      showing: true,
    };
  }

  toggle(id) {
    this.setState({
      deliveryoption: true,
    });
  }

  componentDidMount() {
    fetch(window.$name + "/api/adminstrator/get-customer-shipping")
      .then((response) => response.json())
      .then((datalist) => this.setState({ shippingOption: datalist }));
  }

  getItemQuantity(item) {
    const { quantities } = this.state;
    const quantity = quantities.find((x) => x.itemId === item.id);
    return quantity ? quantity.value : item.quantity;
  }

  handleChangeQuantity = (item, quantity) => {
    this.setState((state) => {
      const stateQuantity = state.quantities.find(
        (x) =>
          x.itemId === item.id && x.product_token === item.product.product_token
      );
      if (!stateQuantity) {
        state.quantities.push({
          itemId: item.id,
          value: quantity,
          product_token: item.product.product_token,
        });
      } else {
        stateQuantity.value = quantity;
      }
      return {
        quantities: state.quantities,
      };
    });
  };

  cartNeedUpdate() {
    const { quantities } = this.state;
    const { cart } = this.props;
    return (
      quantities.filter((x) => {
        const item = cart.items.find((item) => item.id === x.itemId);
        return item && item.quantity !== x.value && x.value !== "";
      }).length > 0
    );
  }
  renderItems() {
    const { cart, cartRemoveItem } = this.props;
    return cart.items.map((item) => {
      let image;
      let options;
      if (item.product.images.length > 0) {
        image = (
          <div className="product-image">
            <Link
              to={url.product(item.product)}
              className="product-image__body"
            >
              <img
                className="product-image__img"
                src={item.product.images}
                alt=""
              />
            </Link>
          </div>
        );
      }

      if (item.options.length > 0) {
        options = (
          <ul className="cart-table__options">
            {item.options.map((option, index) => (
              <li
                key={index}
              >{`${option.optionTitle}: ${option.valueTitle}`}</li>
            ))}
          </ul>
        );
      }

      const removeButton = (
        <AsyncAction
          action={() => cartRemoveItem(item.id, item.product.product_token)}
          render={({ run, loading }) => {
            const classes = classNames("btn btn-light btn-sm btn-svg-icon", {
              "btn-loading": loading,
            });
            return (
              <button type="button" onClick={run} className={classes}>
                <Cross12Svg />
              </button>
            );
          }}
        />
      );

      return (
        <tr key={item.id} className="cart-table__row">
          <td className="cart-table__column cart-table__column--image">
            {image}
          </td>
          <td className="cart-table__column cart-table__column--product">
            <Link
              to={url.product(item.product)}
              className="cart-table__product-name"
            >
              {item.product.name}
            </Link>
            {options}
          </td>
          <td   className="cart-table__column cart-table__column--quantity">
            { item.product.preference_color!==null && item.product.preference_size !==null?(
                <>
                  <Row>
                    <Link >
                      Color :&nbsp;&nbsp; {item.product.preference_color}
                    </Link>
                  </Row>
                  <Row>
                    Size :&nbsp;&nbsp;   <Link >{item.product.preference_size}</Link>
                  </Row>

                </>
            ):item.product.preference_color!==null && item.product.preference_size ===null?(
                <Row>
                  <Link >
                    Color :&nbsp;&nbsp; {item.product.preference_color}
                  </Link>
                </Row>
            ):item.product.preference_color===null && item.product.preference_size !==null?(
                    <Row>
                      <Link >
                        Size :&nbsp;&nbsp; {item.product.preference_size}
                      </Link>
                    </Row>
                ):
                <span>Empty</span>
            }
          </td>
          <td
            className="cart-table__column cart-table__column--price"
            data-title="Price"
          >
            <CurrencyFormat
              value={item.price}
              displayType={"text"}
              thousandSeparator={true}
              renderText={(value) => (
                <div>
                  {userCurrency()} {value}
                </div>
              )}
            />
          </td>
          <td
            className="cart-table__column cart-table__column--quantity"
            data-title="Quantity"
          >
            <InputNumber
              onChange={(quantity) => this.handleChangeQuantity(item, quantity)}
              value={this.getItemQuantity(item)}
              min={1}
            />
          </td>
          <td
            className="cart-table__column cart-table__column--total"
            data-title="Total"
          >
            <CurrencyFormat
              value={item.total}
              displayType={"text"}
              thousandSeparator={true}
              renderText={(value) => (
                <div>
                  {" "}
                  {userCurrency()} {value}
                </div>
              )}
            />
          </td>
          <td className="cart-table__column cart-table__column--remove">
            {removeButton}
          </td>
        </tr>
      );
    });
  }

  renderTotals() {
    const { cart } = this.props;
  // console.log(cart)
    if (cart.extraLines.length <= 0) {
      return null;
    }

    return (
      <React.Fragment>
        <thead className="cart__totals-footer">
          <tr>
            <th>Amount</th>
            <td>
              <CurrencyFormat
                value={(cart.subtotal - cart.vattotal).toFixed(2)}
                displayType={"text"}
                thousandSeparator={true}
                renderText={(value) => (
                  <div>
                    {" "}
                    {userCurrency()} {value}
                  </div>
                )}
              />
            </td>
          </tr>
        </thead>
        <tbody className="cart__totals-body">{/* {extraLines} */}</tbody>
      </React.Fragment>
    );
  }
  renderCart() {
    const { shippingOption } = this.state;
    // const { deliveryoption } = this.state;
    // const { showing } = this.state;
    const { cart, cartUpdateQuantities,myownShippingOpt,activeStep,MyownShipping } = this.props;
    const { quantities } = this.state;
    // console.log(MyownShipping);
    //update qty
    const updateCartButton = (
      <AsyncAction
        action={() => cartUpdateQuantities(quantities)}
        render={({ run, loading }) => {
          const classes = classNames("btn btn-primary cart__update-button", {
            "btn-loading": loading,
          });
          return (
            <button
              type="button"
              onClick={run}
              className={classes}
              disabled={!this.cartNeedUpdate()}
            >
              Update Cart
            </button>
          );
        }}
      />
    );

    function handleChange(data) {
      // const { dispatch } = this.props; 
      localStorage.setItem("deliveryOptions", data.secure_token);
      return data;
    }
  
    localStorage.getItem("deliveryOptions");
    return (
      <div className="cart block">
        <div className="container">
          <table className="cart__table cart-table">
            <thead className="cart-table__head">
              <tr className="cart-table__row">
                <th className="cart-table__column cart-table__column">Image</th>
                <th className="cart-table__column cart-table__column--price">
                  Product
                </th>
                <th className="cart-table__column cart-table__column--price">
                  Preference
                </th>
                <th className="cart-table__column cart-table__column--price">
                  Price
                </th>
                <th className="cart-table__column cart-table__column--quantity">
                  Quantity
                </th>
                <th className="cart-table__column cart-table__column--total">
                  Total
                </th>
                <th
                  className="cart-table__column cart-table__column--remove"
                  aria-label="Remove"
                />
              </tr>
            </thead>
            <tbody className="cart-table__body">{this.renderItems()}</tbody>
          </table>
          <div className="cart__actions">
            <Link to="/" className="btn btn-light">
              Continue Shopping
            </Link>
            {updateCartButton}
          </div>

          <div className="row justify-content-end pt-md-5 pt-4">
            <div className="col-12 col-md-7 col-lg-6 col-xl-5">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title text-center">CART SUMMARY</h3>
                  <table className="cart__totals">
                    {this.renderTotals()}
                    <tfoot className="cart__totals-footer">
                      <tr>
                        <th>VAT</th>
                        <td>
                          <CurrencyFormat
                            value={(cart.vattotal).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                              <div>
                                {" "}
                                {userCurrency()} {value}
                              </div>
                            )}
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>Total Amount</th>
                        <td>
                          <CurrencyFormat
                            value={(cart.subtotal).toFixed(2)}
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                              <div>
                                {" "}
                                {userCurrency()} {"  "} {value}
                              </div>
                            )}
                          />
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                  <hr />
                  <h5 className="text-center">Select Delivery Option</h5>
                  <div>
                    {shippingOption.map((option) => (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Radiobox"
                          id="exampleRadios"
                          onChange={() => handleChange(option)}
                          value="Radiobox"
                          onClick={() => myownShippingOpt(option.customer_shipping,true)
                          }
                        />
                        <label className="form-check-label" for="exampleRadios">
                          {option.customer_shipping}
                        </label>
                      </div>
                    ))}
                  </div>
                  <div className="mt-3">
                    {!MyownShipping.True && localStorage.getItem("deliveryOptions")===null ? (
                      <div className="text-center text-danger">
                        Delivery Option is Required
                      </div>
                    ) : (
                      <></>
                    )}

                    <button
                      type="submit"
                      disabled={!MyownShipping.True && localStorage.getItem("deliveryOptions")===null }
                      onClick={() => handleNexts(activeStep)}
                      className="btn rounded shadow-lg  btn-primary  btn-block cart__checkout-button"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
        </div>
      </div>
    );
  }

  render() {
    const { cart } = this.props;
    let content;
    if (cart.quantity) {
      content = this.renderCart();
    } else {
      content = (
        <div className="block block-empty">
          <div className="container">
            <div className="block-empty__body">
              <div className="block-empty__message">
                Your shopping cart is empty!
              </div>
              <div className="block-empty__actions">
                <Link to="/" className="btn btn-primary btn-sm">
                  Continue
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>{`Checkout — ${theme.name}`}</title>
        </Helmet>
        {content}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  cart: state.cart,
  MyownShipping:state.MyownShipping
});

const mapDispatchToProps = {
  cartRemoveItem,
  cartUpdateQuantities,
  myownShippingOpt
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCart);
