import Axios from "axios";
import {LEADS_DATA,} from "./LeadsActionTypes";
import {toast} from "react-toastify";
import {agenttoken} from "../../api/shop";

export function FetchProductLeads() {
    return async (dispatch) =>
        Axios.post(window.$name + "/api/leads/agent/leads", {agent_token:agenttoken()})
            .then(function (response) {
                    dispatch({type: LEADS_DATA, payload: response.data});
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}


export function CreateProductLeads(agentId,customerId,leads,history) {
    const data = {
        agent_id:agentId,
        customer_id:customerId,
        leads_products:leads
    };
// console.log(data)
    return async (dispatch) =>
        Axios.post(window.$name + "/api/leads/create/leads", data)
            .then(function (response) {
                toast.success(`${response.data.message}`);
                dispatch(FetchProductLeads());
                history.push("/agent/Leads")
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error(
                        `"${error.response.data.message}" Error Status  "${error.response.status}" `
                    );
                } else if (error.request) {
                    toast.error(`"${error.request}" `);
                } else {
                    toast.error(`"${error.message}" `);
                }
            });
}

