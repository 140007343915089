// react
import React, {useEffect, useState} from 'react';

// third-party
import { Helmet } from 'react-helmet-async';

// data stubs
import theme from '../../data/theme';
import * as yup from "yup";
import {useFormik} from "formik";
import axios from "axios";
import AsyncAction from "../shared/AsyncAction";
import {EditAddress} from "../../store/shippingAddress";
import {connect} from "react-redux";

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
//creating the validation schema
const validationSchema = yup.object().shape({

    fullname: yup
        .string()
        .required("Please enter your full name")
        .min(4, "Fullname must be at least 4  characters"),
    mobile: yup
        .string()
        .required("Phone number is required!"),
        // .matches(phoneRegExp, 'Phone Number is not valid')
        // .min(10, "Must be at least 10 characters")
        // .max(14, "Phone number is too long, Not Great than 13 characters"),

    // .min(10, "Your mobile number must be at least 10 digits")
    // .max(12, "mobile number must be less than 12 digits"),
    othermobile: yup
        .string(),
        // .required("Phone number is required!")
        // .matches(phoneRegExp, 'Phone Number is not valid')
        // .min(10, "Must be at least 10 characters")
        // .max(13, "Phone number is too long, Not Great than 13 characters"),

    // postcode: yup
    //     .string(),
    country: yup
        .string()
        .required("Please select country"),
    region: yup
        .string()
        .required("Please Select Region"),
    district: yup
        .string()
        .required("Please Select district"),

    // street: yup
    //     .string()
    //     .required("Please enter your Street name"),
    // housenumber: yup
    //     .string()
    //     .required("Please enter your house number"),
    addresstype: yup
        .string()
        .required("Please select Address Type"),

});

function AccountPageEditAddress(props) {
    const addrerssdata = props.location.state;
    // console.log(addrerssdata);
        //using useFormik
        const formik = useFormik({
            initialValues :{
                fullname: addrerssdata[0].address_fullname,
                mobile: addrerssdata[0].address_mobileNumber,
                othermobile:addrerssdata[0].address_mobileNumber2,
                postcode:addrerssdata[0].address_postcode,
                country:addrerssdata[0].address_Country,
                region: addrerssdata[0].address_Region,
                district:addrerssdata[0].address_district,
                street:addrerssdata[0].area,
                housenumber:addrerssdata[0].address_house_number,
                addresstype:addrerssdata[0].address_AddressType,
                address_token:addrerssdata[0].secure_token,
                additional:addrerssdata[0].additional_delivery_notes,
            },
            validationSchema,
        });
        // console.log(formik.values)
        const {EditAddress} = props;
        //  country select
        const [state, setState] = useState({
            fullname: "",
            mobile: "",
            othermobile: "",
            postcode: "",
            country: "",
            region: "",
            district: "",
            street: "",
            housenumber: "",
            addresstype: "",
            additional: "",
        });
        const [countries, setCountry] = useState([]);
        useEffect(() => {
            const fetchData = async () => {
                const results = await axios
                    .get(window.$name + "/api/adminstrator/get-countries")
                    .then((response) => response.data);
                setCountry(results);
            };

            fetchData();
        }, []);

        const [regions, setRegions] = useState([]);

        const GetRegions = (e) => {
            const {id, value} = e.target;
            formik.values.country = e.target.value;
            setState((prevState) => ({
                ...prevState,
                [id]: value,
            }));
            axios.get(window.$name + "/api/adminstrator/get-regions", {
                params: {
                    country_id: e.target.value,
                },
            })
                .then((res) => {
                    setRegions(res.data);
                });
        };

        const [district, setDistrict] = useState([]);

        const GetDistrict = (e) => {
            const {id, value} = e.target;
            formik.values.region = e.target.value;
            setState((prevState) => ({
                ...prevState,
                [id]: value,
            }));
            axios
                .get(window.$name + "/api/adminstrator/get-districts", {
                    params: {
                        region_id: e.target.value,
                    },
                })
                .then((res) => {
                    setDistrict(res.data);
                });
        };

    return (
        <div className="card">
            <Helmet>
                <title>{`Edit Address — ${theme.name}`}</title>
            </Helmet>

            <div className="card-header">
                <h5>Edit Address</h5>
            </div>
            <div className="card-divider" />
                <div className="card-body">
                    {addrerssdata.map((data)=>(
                        <>
                     <div className="form-row">
                        <div className="form-group col-md-6">
                            <label htmlFor="checkout-country">Alternative phone Number</label>
                            <input
                                type="text"
                                className="form-control"
                                id="othermobile"
                                value={formik.values.othermobile}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {/*{formik.touched.othermobile && formik.errors.othermobile ? (*/}
                            {/*    <div className="text-danger">{formik.errors.othermobile}</div>*/}
                            {/*) : null}*/}
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="checkout-country">Post Code</label>
                            <input
                                type="text"
                                className="form-control"
                                id="postcode"
                                value={formik.values.postcode}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                            {formik.touched.postcode && formik.errors.postcode ? (
                                <div className="text-danger">{formik.errors.postcode}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="form-group">
                    <label htmlFor="checkout-country">Country</label>

                    {/* <div className="form-group">
                      <label>Select List</label> */}
                      <select
                        className="form-control"
                        id="country"
                        name="country"
                        onChange={(e) => GetRegions(e)}
                        onBlur={formik.handleBlur}
                        value={formik.values.country}
                      >
                        <option value="">{addrerssdata[0].country.country}</option>
                        {countries.map((country) => (
                          <option key={country.id} value={country.id}>
                            {country.country}
                          </option>
                        ))}
                      </select>
                      {formik.touched.country && formik.errors.country ? (
                        <div className="text-danger">
                          {formik.errors.country}
                        </div>
                      ) : null}

                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-country">Region</label>
                      <select
                        onChange={(e) => GetDistrict(e)}
                        id="region"
                        className="form-control"
                        name="region"
                        onBlur={formik.handleBlur}
                        value={formik.values.region}
                      >
                        <option value="">{addrerssdata[0].region.region}</option>
                        {regions.map((reg) => (
                          <option key={reg.id} value={reg.id}>
                            {reg.region}
                          </option>
                        ))}
                      </select>
                      {formik.touched.region && formik.errors.region ? (
                      <div className="text-danger">{formik.errors.region}</div>
                    ) : null}

                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="checkout-country">District</label>
                      <select
                        onChange={formik.handleChange}
                        id="district"
                        className="form-control"
                        name="district"
                        onBlur={formik.handleBlur}
                        value={formik.values.district}
                      >
                        <option value="">{formik.values.district}</option>
                        {district.map((dist) => (
                          <option key={dist.id} value={dist.district}>
                            {dist.district}
                          </option>
                        ))}
                      </select>
                      {formik.touched.district && formik.errors.district ? (
                      <div className="text-danger">{formik.errors.district}</div>
                    ) : null}

                    </div>
                  </div>
                    <div className="form-group">
                        <label htmlFor="checkout-street-address">Street</label>
                        <input
                            type="text"
                            className="form-control"
                            id="street"
                            placeholder="Street Address"
                            value={formik.values.street}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.street && formik.errors.street ? (
                            <div className="text-danger">{formik.errors.street}</div>
                        ) : null}
                    </div>
                    <div className="form-row">
                        <div className="form-group col-md-6">
                            <label htmlFor="checkout-email">House Number</label>
                            <input
                                type="email"
                                className="form-control"
                                id="housenumber"
                                value={formik.values.housenumber}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />

                            {formik.touched.housenumber && formik.errors.housenumber ? (
                                <div className="text-danger">{formik.errors.housenumber}</div>
                            ) : null}
                        </div>
                        <div className="form-group col-md-6">
                            <label htmlFor="checkout-phone">Address Type</label>
                            <select
                                onChange={formik.handleChange}
                                id="addresstype"
                                className="form-control"
                                value={formik.values.addresstype}
                                onBlur={formik.handleBlur}
                            >
                                <option value="">{addrerssdata[0].address_AddressType}</option>
                                <option>Home</option>
                                <option>Office</option>
                            </select>
                            {formik.touched.addresstype && formik.errors.addresstype ? (
                                <div className="text-danger">{formik.errors.addresstype}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="checkout-street-address">
                            Additional Delivery Information (
                            <small> Not Compulsory </small>)
                        </label>
                        <textarea
                            type="text"
                            className="form-control mt-2"
                            id="additional"
                            placeholder="Additional delivery information"
                            value={formik.values.additional}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />

                        {formik.touched.additional && formik.errors.additional ? (
                            <div className="text-danger">{formik.errors.additional}</div>
                        ) : null}
                    </div>
                    {/*<AsyncAction*/}
                    {/*    action={() => EditAddress(formik.values)}*/}
                    {/*    render={({run, loading}) => (*/}
                    {/*        <button*/}
                    {/*            type="button"*/}
                    {/*            onClick={run}*/}
                    {/*            className={"btn btn-block btn-primary rounded "}*/}
                    {/*            // disabled={!(formik.isValid && formik.dirty)}*/}
                    {/*        >*/}
                    {/*            Edit address*/}
                    {/*        </button>*/}
                    {/*    )}*/}
                    {/*/>*/}
                    </>
                        ))}
                    <AsyncAction
                        action={() => EditAddress(formik.values)}
                        render={({run, loading}) => (
                            <button
                                type="button"
                                onClick={run}
                                className={"btn btn-block btn-primary rounded "}
                                disabled={!(formik.isValid && formik.dirty)}
                            >
                                Save Address
                            </button>
                        )}
                    />
                </div>


        </div>
    );
}
const mapStateToProps = (state) => ({
    // shippingAddress: state.shippingAddress,
});
const mapDispatchToProps = {
    EditAddress,
};
export default connect(mapStateToProps, mapDispatchToProps)(AccountPageEditAddress);
