// react
import React from 'react';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import Currency from '../shared/Currency';
import { Check100Svg } from '../../svg';

// data stubs
import order from '../../data/accountOrderDetails';
import theme from '../../data/theme';
import { url } from '../../services/utils';
import { userCurrency } from "../../api/shop";
import CurrencyFormat from "react-currency-format";

export default function ShopPageOrderSuccess() {

    const successOrder=JSON.parse(localStorage.getItem('success_data'));

    return (
        <div className="block order-success">
            <Helmet>
                <title>{`Order Success — ${theme.name}`}</title>
            </Helmet>
            <div className="container">
                <div className="order-success__body">
                    <div className="order-success__header">
                        <Check100Svg className="order-success__icon" />
                        <h1 className="order-success__title">Thank you</h1>
                        <div className="order-success__subtitle">Your order has been received</div>
                        <div className="order-success__actions">
                            <Link to="/" className="btn btn-xs btn-secondary">Go To Homepage</Link>
                        </div>
                    </div>
                    <div className="order-success__meta">
                        <ul className="order-success__meta-list">
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Order number:</span>
                                <span className="order-success__meta-value">{successOrder.order_number}</span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Total amount:</span>
                                <span className="order-success__meta-value">
                          <CurrencyFormat
                            value={successOrder.total_amount
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                              <div>
                                {userCurrency()}{" "}{value}
                                {/*{product.wholesale_currency} {value}*/}
                              </div>
                            )}
                          />
                           
                                    </span>
                            </li>
                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Payment number:</span>
                                <span className="order-success__meta-value">{successOrder.order_number}</span>
                            </li>

                            <li className="order-success__meta-item">
                                <span className="order-success__meta-title">Payment method:</span>
                                <span className="order-success__meta-value">{successOrder.payment_method}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}