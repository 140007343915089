import {FETCH_MY_ADDRESS } from "./myshippingActionTypes";
import Axios from "axios";
import { customertoken, agenttoken, agentcustomerToken } from "../../api/shop";
import { toast } from "react-toastify";

export function FetchMyShipping() {
  const customer_token = customertoken();
  const agentcustomer_Token = agentcustomerToken();
  const agentToken = agenttoken();
  if (agentcustomer_Token !== null && agentToken !== null) {
    return async (dispatch) =>
      Axios.post(window.$name + "/api/order/get-address", {
        user_token: agentcustomer_Token,
        is_pickup:true
      })
        .then(function (response) {
          // console.log(response.data.pickup_address)
          if (response.data.status === 401) {
            dispatch({ type:FETCH_MY_ADDRESS, payload: null });
          } else {
            dispatch({ type:FETCH_MY_ADDRESS, payload: response.data.pickup_address });
          }
        })
        .catch(function (error) {
          if (error.response) {
            dispatch({ type:FETCH_MY_ADDRESS, payload: null });
            toast.error(
              `"${error.response.data.message}" Error Status  "${error.response.status}" `
            );
          } else if (error.request) {
            dispatch({ type:FETCH_MY_ADDRESS, payload: null });
            toast.error(`"${error.request}" `);
          } else {
            dispatch({ type:FETCH_MY_ADDRESS, payload: null });
            toast.error(`"${error.message}" `);
          }
        });
  } else {
    return async (dispatch) =>
      Axios.post(window.$name + "/api/order/get-address", {
        user_token: customer_token,
        is_pickup:true
      })
        .then(function (response) {
          if (response.data.status === 401) {
            dispatch({ type:FETCH_MY_ADDRESS, payload: null });
          } else {
            dispatch({ type:FETCH_MY_ADDRESS, payload: response.data.pickup_address });
          }
        })
        .catch(function (error) {
          if (error.response) {
            dispatch({ type:FETCH_MY_ADDRESS, payload: null });
            toast.error(
              `"${error.response.data.message}" Error Status  "${error.response.status}" `
            );
          } else if (error.request) {
            dispatch({ type:FETCH_MY_ADDRESS, payload: null });
            toast.error(`"${error.request}" `);
          } else {
            dispatch({ type:FETCH_MY_ADDRESS, payload: null });
            toast.error(`"${error.message}" `);
          }
        });
  }
}
//function for add shipping opt

export function AddMyShipping(address) {
  console.log(address)
  const customer_token = customertoken();
  const agentcustomer_Token = agentcustomerToken();
  const agentToken = agenttoken();
  if (agentcustomer_Token !== null && agentToken !== null) {
    return async (dispatch) =>
      Axios.post(window.$name + "/api/order/add-new-address", {
        user_token: agentcustomer_Token,
        address_fullname: address.fullname,
        address_mobileNumber: address.mobile,
        address_Country: address.country,
        address_Region: address.region,
        address_district: address.district,
        // pickup_date: address.pickup_date,
        // pickup_time: address.pickup_time,
        id_type: address.idtype,
        id_number: address.idnumber,
        area: address.street,
        is_pickup: true,
        additional_pickup_details: address.additional,
      })
        .then(function (response) {
          toast.success(`"${response.data.message}" `);
          dispatch(FetchMyShipping());
        })
        .catch(function (error) {
          if (error.response) {
            toast.error(
              `"${error.response.data.message}" Error Status  "${error.response.status}" `
            );
          } else if (error.request) {
            toast.error(`"${error.request}" `);
          } else {
            toast.error(`"${error.message}" `);
          }
        });
  } else {
    return async (dispatch) =>
      Axios.post(window.$name + "/api/order/add-new-address", {
        user_token: customer_token,
        address_fullname: address.fullname,
        address_mobileNumber: address.mobile,
        address_Country: address.country,
        address_Region: address.region,
        address_district: address.district,
        // pickup_date: address.pickup_date,
        // pickup_time: address.pickup_time,
        id_type: address.idtype,
        id_number: address.idnumber,
        area: address.street,
        is_pickup: true,
        additional_pickup_details: address.additional,
      })
        .then(function (response) {
          toast.success(`"${response.data.message}" `);
          dispatch(FetchMyShipping());
        })
        .catch(function (error) {
          if (error.response) {
            toast.error(
              `"${error.response.data.message}" Error Status  "${error.response.status}" `
            );
          } else if (error.request) {
            toast.error(`"${error.request}" `);
          } else {
            toast.error(`"${error.message}" `);
          }
        });
  }
}
// end here
export function AddAddress(address) {
  const customer_token = customertoken();
  const agentcustomer_Token = agentcustomerToken();
  const agentToken = agenttoken();
  if (agentcustomer_Token !== null && agentToken !== null) {
    return async (dispatch) =>
      Axios.post(window.$name + "/api/order/add-new-address", {
        user_token: agentcustomer_Token,
        address_fullname: address.fullname,
        address_mobileNumber: address.mobile,
        address_mobileNumber2: address.othermobile,
        address_Country: address.country,
        address_Region: address.region,
        address_district: address.district,
        address_house_number: address.housenumber,
        address_postcode: address.postcode,
        address_AddressType: address.addresstype,
        area: address.street,
        is_pickup: false,
        additional_delivery_notes: address.additional,
      })
        .then(function (response) {
          toast.success(`"${response.data.message}" `);
          dispatch(FetchMyShipping());
        })
        .catch(function (error) {
          if (error.response) {
            toast.error(
              `"${error.response.data.message}" Error Status  "${error.response.status}" `
            );
          } else if (error.request) {
            toast.error(`"${error.request}" `);
          } else {
            toast.error(`"${error.message}" `);
          }
        });
  } else {
    return async (dispatch) =>
      Axios.post(window.$name + "/api/order/add-new-address", {
        user_token: customer_token,
        address_fullname: address.fullname,
        address_mobileNumber: address.mobile,
        address_mobileNumber2: address.othermobile,
        address_Country: address.country,
        address_Region: address.region,
        address_district: address.district,
        address_house_number: address.housenumber,
        address_postcode: address.postcode,
        address_AddressType: address.addresstype,
        area: address.street,
        is_pickup: false,
        additional_delivery_notes: address.additional,
      })
        .then(function (response) {
          toast.success(`"${response.data.message}" `);
          dispatch(FetchMyShipping());
        })
        .catch(function (error) {
          if (error.response) {
            toast.error(
              `"${error.response.data.message}" Error Status  "${error.response.status}" `
            );
          } else if (error.request) {
            toast.error(`"${error.request}" `);
          } else {
            toast.error(`"${error.message}" `);
          }
        });
  }
}

export function EditAddress(address) {
  const customer_token = customertoken();
  const agentcustomer_Token = agentcustomerToken();
  const agentToken = agenttoken();
  if (agentcustomer_Token !== null && agentToken !== null) {
    return async (dispatch) =>
      Axios.post(window.$name + "/api/order/updating-shipping-address", {
        user_token: agentcustomer_Token,
        address_token: address.address_token,
        address_fullname: address.fullname,
        address_mobileNumber: address.mobile,
        address_mobileNumber2: address.othermobile,
        address_Country: address.country,
        address_Region: address.region,
        address_district: address.district,
        address_house_number: address.housenumber,
        address_postcode: address.postcode,
        address_AddressType: address.addresstype,
        area: address.street,
        additional_delivery_notes: address.additional,
      })
        .then(function (response) {
          toast.success(`"${response.data.message}" `);
          dispatch(FetchMyShipping());
        })
        .catch(function (error) {
          if (error.response) {
            toast.error(
              `"${error.response.data.message}" Error Status  "${error.response.status}" `
            );
          } else if (error.request) {
            toast.error(`"${error.request}" `);
          } else {
            toast.error(`"${error.message}" `);
          }
        });
  } else {
    return async (dispatch) =>
      Axios.post(window.$name + "/api/order/updating-shipping-address", {
        user_token: customer_token,
        address_token: address.address_token,
        address_fullname: address.fullname,
        address_mobileNumber: address.mobile,
        address_mobileNumber2: address.othermobile,
        address_Country: address.country,
        address_Region: address.region,
        address_district: address.district,
        address_house_number: address.housenumber,
        address_postcode: address.postcode,
        address_AddressType: address.addresstype,
        area: address.street,
        additional_delivery_notes: address.additional,
      })
        .then(function (response) {
          toast.success(`"${response.data.message}" `);
          dispatch(FetchMyShipping());
          window.location.href = "/account/dashboard";
        })
        .catch(function (error) {
          if (error.response) {
            toast.error(
              `"${error.response.data.message}" Error Status  "${error.response.status}" `
            );
          } else if (error.request) {
            toast.error(`"${error.request}" `);
          } else {
            toast.error(`"${error.message}" `);
          }
        });
  }
}

export function ChangeAddress(address_token) {
  const customer_token = customertoken();
  const agentcustomer_Token = agentcustomerToken();
  const agentToken = agenttoken();
  if (agentcustomer_Token !== null && agentToken !== null) {
    return async (dispatch) =>
      Axios.post(window.$name + "/api/order/update-active-address", {
        user_token: agentcustomer_Token,
        address_token: address_token,
        is_pickup:true
      })
        .then(function (response) {
          toast.success(`${response.data.message}`);
          dispatch(FetchMyShipping());
        })
        .catch(function (error) {
          if (error.response) {
            toast.error(
              `"${error.response.data.message}" Error Status  "${error.response.status}" `
            );
          } else if (error.request) {
            toast.error(`"${error.request}" `);
          } else {
            toast.error(`"${error.message}" `);
          }
        });
  } else {
    return async (dispatch) =>
      Axios.post(window.$name + "/api/order/update-active-address", {
        user_token: customer_token,
        address_token: address_token,
        is_pickup:true
      })
        .then(function (response) {
          toast.success(`"${response.data.message}" `);
          dispatch(FetchMyShipping());
        })
        .catch(function (error) {
          if (error.response) {
            toast.error(
              `"${error.response.data.message}" Error Status  "${error.response.status}" `
            );
          } else if (error.request) {
            toast.error(`"${error.request}" `);
          } else {
            toast.error(`"${error.message}" `);
          }
        });
  }
}

export function DeleteAddress(address_token) {
  return async (dispatch) =>
    Axios.post(window.$name + "/api/order/remove-address", {
      address_token: address_token,
    })
      .then(function (response) {
        toast.error(`${response.data.message} `);
        dispatch(FetchMyShipping());
      })
      .catch(function (error) {
        if (error.response) {
          toast.error(
            `"${error.response.data.message}" Error Status  "${error.response.status}" `
          );
        } else if (error.request) {
          toast.error(`"${error.request}" `);
        } else {
          toast.error(`"${error.message}" `);
        }
      });
}

