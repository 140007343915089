import {DELETE_MASTER_NUMBER_DATA,MASTER_NUMBER_DATA} from "./masterNumberActionTypes";

export default function masterNumberReducer(state=[],action) {
    switch(action.type){
        case MASTER_NUMBER_DATA:
            return action.payload
        case DELETE_MASTER_NUMBER_DATA:
            return action.payload
        default:
            return state;
    }
}
