import axois from "axios";


const toProductJson = (list, rate) =>

    list.map((item) => ({
        id: item.id,
        name: item.product.product_name,
        rate: rate.rate,
        slug: item.product.product_name,
        price: parseInt(item.shop_price.retailprice),
        price_tag: item.shop_price.price_tag,
        vat_check: item.shop_price.vat_check,
        vat: parseInt(item.shop_price.total_retail_vat),
        acquistion_cost: parseInt(item.product.acquistion_cost),
        acquistion_currency: item.product.acquistion_currency,
        ezytrade_commission: item.product.ezy_commmission,
        agent_commission: item.product.agent_commmission,
        wholesale_price: parseInt(item.shop_price.wholesaleprice),
        wholesale_vat: parseInt(item.shop_price.total_wholesale_vat),
        currency: item.shop_price.retail_currency,
        currency_wholesale: item.shop_price.wholesale_currency,
        discount: item.product.discount,
        customUoM: item.product.customUoM,
        unitMeasure: item.product.unitMeasure,
        compareAtPrice: null,
        status: item.product.is_featured,
        images: item.product.images.productImage_name,
        shop_id: item.shop_id,
        seller_token: item.product.business.secure_token,
        secure_token: item.secure_token,
        product_token: item.product_token,
        outlets_token: item.outlet_token,
        Category: item.product.categories.category,
        digital: item.product.is_digital,
        // Category: item.Category.category,
        badges: [],
        rating: 5,
        reviews: 3,
        brand: item.product.brand,
        categories: [],
        attributes: [],
        customFields: {},
    }));

const toProductPopularJson = (list, rate) =>
    list.map((item) => ({
        id: item.id,
        name: item.product.product_name,
        rate: rate.rate,
        slug: item.product.product_name,
        price: parseInt(item.shop_price.retailprice),
        price_tag: item.shop_price.price_tag,
        vat_check: item.shop_price.vat_check,
        vat: parseInt(item.shop_price.total_retail_vat),
        acquistion_cost: parseInt(item.product.acquistion_cost),
        acquistion_currency: item.product.acquistion_currency,
        ezytrade_commission: item.product.ezy_commmission,
        agent_commission: item.product.agent_commmission,
        wholesale_price: parseInt(item.shop_price.wholesaleprice),
        wholesale_vat: parseInt(item.shop_price.total_wholesale_vat),
        currency: item.shop_price.retail_currency,
        currency_wholesale: item.shop_price.wholesale_currency,
        discount: item.product.discount,
        customUoM: item.product.customUoM,
        unitMeasure: item.product.unitMeasure,
        compareAtPrice: null,
        status: item.product.is_featured,
        images: item.product.images.productImage_name,
        shop_id: item.shop_id,
        seller_token: item.product.business.secure_token,
        secure_token: item.secure_token,
        product_token: item.product_token,
        outlets_token: item.outlet_token,
        Category: item.product.categories.category,
        digital: item.product.is_digital,
        // Category: item.Category.category,
        badges: [],
        rating: 5,
        reviews: 3,
        brand: item.product.brand,
        categories: [],
        attributes: [],
        customFields: {},
    }));
// new arrival products format
const toProductNewarrivalJson = (list, rate) =>
    list.map((item) => ({
        id: item.id,
        name: item.product.product_name,
        slug: item.product.product_name,
        rate: rate.rate,
        price: parseInt(item.shop_price.retailprice),
        price_tag: item.shop_price.price_tag,
        vat_check: item.shop_price.vat_check,
        vat: parseInt(item.shop_price.total_retail_vat),
        acquistion_cost: parseInt(item.product.acquistion_cost),
        acquistion_currency: item.product.acquistion_currency,
        ezytrade_commission: item.product.ezy_commmission,
        agent_commission: item.product.agent_commmission,
        wholesale_price: parseInt(item.shop_price.wholesaleprice),
        wholesale_vat: parseInt(item.shop_price.total_wholesale_vat),
        currency: item.shop_price.retail_currency,
        currency_wholesale: item.shop_price.wholesale_currency,
        discount: item.product.discount,
        customUoM: item.product.customUoM,
        unitMeasure: item.product.unitMeasure,
        compareAtPrice: null,
        status: item.product.is_featured,
        images: item.product.images.productImage_name,
        shop_id: item.shop_id,
        seller_token: item.product.business.secure_token,
        secure_token: item.secure_token,
        product_token: item.product_token,
        outlets_token: item.outlet_token,
        Category: item.product.categories.category,
        digital: item.product.is_digital,
        badges: [],
        rating: 5,
        reviews: 3,
        brand: item.product.brand,
        categories: [],
        attributes: [],
        customFields: {},
    }));


const FilterProduct = (list) =>
    list.map((item) => ({
        id: item.id,
        name: item.product.product_name,
        slug: item.product.product_name,
        price: parseInt(item.shop_price.retailprice),
        price_tag: item.shop_price.price_tag,
        vat_check: item.shop_price.vat_check,
        vat: parseInt(item.shop_price.total_retail_vat),
        acquistion_cost: parseInt(item.product.acquistion_cost),
        acquistion_currency: item.product.acquistion_currency,
        ezytrade_commission: item.product.ezy_commmission,
        agent_commission: item.product.agent_commmission,
        wholesale_price: parseInt(item.shop_price.wholesaleprice),
        wholesale_vat: parseInt(item.shop_price.total_wholesale_vat),
        currency: item.shop_price.retail_currency,
        customUoM: item.product.customUoM,
        compareAtPrice: null,
        status: item.product.is_featured,
        images: item.product.images.productImage_name,
        secure_token: item.secure_token,
        product_token: item.product_token,
        outlets_token: item.outlet_token,
        digital: item.product.is_digital,
        badges: [],
        rating: 5,
        reviews: 3,
        availability: "in-stock",
        brand: item.product.brand,
        categories: [],
        attributes: [],
        customFields: {},
    }));

const toCategoryJson = (list) =>
    list
        .filter((item) => item.category_model.length > 0)
        .map((item) => ({
            name: item.category_model[0].subcategory,
            slug: item.category_model[0].id,
        }));
const topopularCategoryJson = (list) =>
    list.map((itemcategory) => ({
        name: itemcategory.category,
        secure_token: itemcategory.secure_token,
        images: itemcategory.category_Image.secure_url,
        product_number: itemcategory.total_product,
    }));
export const fechProducts = async (tab) => {
    const response = await axois.get(
        window.$name + `/api/consumer/all-featured-product?digital=true`
        // window.$name + "/api/consumer/featured-products"
    );
    // console.log("tes featured here",response.data);
    return toProductJson(response.data.products.data, response.data.rates);
};
export const ProductFilter = async (tab) => {
    const response = await axois.get(
        window.$name + `/api/consumer/all-featured-product?digital=true`
    );
    // console.log("tes featured here",response.data.data);
    return FilterProduct(response.data.data);
};

export const Countries = async () => {
    let country;
    await axois
        .get(window.$name + "/api/adminstrator/get-countries")
        .then((res) => {
            country = res.data;
        });
    return country;
};

export const fetchAllProducts = async (tab) => {
    const response = await axois.get(window.$name + `/api/consumer/from-outlets?digital=true`);
    // console.log("product json outlets",response.data.products.data);
    return toProductJson(response.data.products.data);
};
export const fetchAllpopularProducts = async (tab) => {
    const response = await axois.get(
        window.$name + `/api/consumer/popular-product?digital=true`
    );
    // console.log("product json popular",response);
    return toProductPopularJson(response.data.products.data, response.data.rates);
};

export const fetchNewArrivals = async (tab) => {
    const response = await axois.get(window.$name + `/api/consumer/new-arrivals?digital=true`);
    // console.log(response);
    return toProductNewarrivalJson(response.data.products.data, response.data.rates);
};

export const fetchCategories = async (options) => {
    const response = await axois.get(
        window.$name + "/api/adminstrator/get-all-categories"
    );
    return toCategoryJson(response.data);
};
export const getAllCategories = async () => {
    const response = await axois.get(
        window.$name + "/api/adminstrator/get-all-categories-images"
    );
    // console.log(response)
    return topopularCategoryJson(response.data);
};
