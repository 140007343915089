// react
import React, {useEffect, useState} from "react";
// third-party
import classNames from "classnames";
import {connect, useDispatch} from "react-redux";
import {Link} from "react-router-dom";
// import OverflowScrolling from 'react-overflow-scrolling';
// application
import CurrencyFormat from "react-currency-format";
import AsyncAction from "../shared/AsyncAction";
// import Currency from '../shared/Currency';
import Indicator from "./Indicator";
import {Cart20Svg, Cross10Svg} from "../../svg";
import {CartDataFromDb, cartRemoveItem} from "../../store/cart";
import {url} from "../../services/utils";
import {Scrollbars} from "react-custom-scrollbars";
import {customertoken, agentcustomerToken, userCurrency, agenttoken} from "../../api/shop";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import {Container} from 'react-bootstrap'
import AccountNavPageLogin from "../account/AccountNavPageLogin";
import RegisterNav from "../account/RegisterNav";
import {ChangeCurrentCustomer} from "../../store/currentCustomer";
import {useHistory} from "react-router";

function IndicatorCart(props) {
    const [state, setState] = useState(false);
    const checkoutclick = () => setState(true)
    const clickDropdown = () => setState(false)

    function RegisterPage(props) {
        const page = (
            <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="container text-center">
                        Create Account
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="show-grid">
                    <Container>
                        <RegisterNav/>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onLogin}>Login</Button>
                    <Button className='btn-danger' onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
        if (authetications.isLogin && customertoken()) {
            return (<div/>)

        } else {
            return page
        }
    }

    const dispatch = useDispatch();
    useEffect(() => {
        if (customertoken() || agentcustomerToken()) {
            dispatch(CartDataFromDb());
        }
    }, []);

    const {cart, authetications, cartRemoveItem, CurrentCustomer,ChangeCurrentCustomer} = props;
    const [loginModal, setLoginModal] = React.useState(false);
    const [registerModal, setRegisterModal] = React.useState(false);

    function LoginPage(props) {
        const page = (
            <Modal {...props} centered>
                <Modal.Header closeButton>
                    <Modal.Title className="container text-center" id="contained-modal-title-vcenter">
                        Login Page
                    </Modal.Title>
                </Modal.Header>
                <Container>
                    <AccountNavPageLogin/>
                </Container>
                <Modal.Footer>
                    <Button onClick={props.Register}>Register</Button>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );

        if (authetications.isLogin && customertoken()) {
            return (<div/>)

        } else {
            return page
        }


    }


    function LoginAction() {
        setLoginModal(true)
        setRegisterModal(false)
    }

    function HideModal() {
        setLoginModal(false)
    }

    function RegisterAction() {
        setLoginModal(false)
        setRegisterModal(true)
    }

    let dropdown;
    let totals;
    let button;
    const items = cart.items.map((item) => {
        let options;
        let image;

        if (item.options) {
            options = (
                <ul className="dropcart__product-options">
                    {item.options.map((option, index) => (
                        <li key={index}>{`${option.optionTitle}: ${option.valueTitle}`}</li>
                    ))}
                </ul>
            );
        }

        if (item.product.images.length) {
            image = (
                <div className="product-image dropcart__product-image">
                    <Link className="product-image__body">
                        <img
                            className="product-image__img"
                            src={item.product.images}
                            alt=""
                        />
                    </Link>
                </div>
            );
        }


        const removeButton = (
            <AsyncAction
                action={() => cartRemoveItem(item.id, item.product.product_token)}
                render={({run, loading}) => {
                    const classes = classNames(
                        "dropcart__product-remove btn btn-light btn-sm btn-svg-icon",
                        {
                            "btn-loading": loading,
                        }
                    );
                    return (
                        <button type="button" onClick={run} className={classes}>
                            <Cross10Svg/>
                        </button>
                    );
                }}
            />
        );

        return (
            <div key={item.id} className="dropcart__product ">
                {image}
                <div className="dropcart__product-info">
                    <div className={"dropcart__product-name"}>
                        <Link>{item.product.name}</Link>
                    </div>
                    {options}
                    <div className="dropcart__product-meta">
                        <span className="dropcart__product-quantity">{item.quantity}</span>
                        {" × "}
                        <span className="dropcart__product-price">
                            <CurrencyFormat
                                value={item.price}
                                displayType={"text"}
                                thousandSeparator={true}
                                renderText={(value) => (
                                    <div>
                                        {userCurrency()}{" "}{value}
                                    </div>
                                )}
                            />
                        </span>
                    </div>
                </div>
                {removeButton}

            </div>
        );
    });
    //button returns
    if ((authetications.isLogin && customertoken()) || agentcustomerToken()) {
        button = (
            <Link
                onClick={checkoutclick}
                className="btn btn-primary btn-block rounded shadow-lg"
                to="/shop/checkout"
            >
                Checkout
            </Link>)
    } else {
         button=(
            <div className="row">
                <Button className="col-md-6" variant="primary" onClick={() => setLoginModal(true)}>
                    Login
                </Button>
                <Link
                    className=" col-md-6 btn btn-primary  rounded shadow-lg"
                    to="/shop/checkout"
                >
                    Checkout
                </Link>
            </div>
            )
    }


    if (cart.quantity && state === false) {
        dropdown = (

            <div className="dropcart">
                {
                    agenttoken() && agentcustomerToken() ?
                        <p className="container row mt-1">
                                    <span className="col-4 font-weight-bold">
                                        CUSTOMER:
                                    </span>
                            {" "}{" "}
                            <span className="col-6 text-capitalize font-weight-bold">
                                        {CurrentCustomer}
                                    </span>
                            {" "}{" "}
                            <AsyncAction
                                action={() => ChangeCurrentCustomer("")}
                                render={({run, loading}) => (
                                    <Link
                                        className="col-2 text-capitalize text-success font-weight-bold"
                                        onClick={run}
                                    >
                                        Change
                                    </Link>
                                )}
                            />
                        </p> :
                        null
                }
                <hr/>

                <Scrollbars style={{height: 200}}>
                    <div className="dropcart__products-list">{items}</div>
                </Scrollbars>
                <div className="dropcart__totals">
                    <table>
                        <tbody>
                        {totals}
                        <tr>
                            <th>Total</th>
                            <td>
                                {/* <CurrencyFormat
                                            value={Math(cart.subtotal)}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            renderText={(value) => (
                                                <div>
                                                    {userCurrency()}{" "}{value}
                                                </div>
                                                )}
                                        /> */}
                                {userCurrency() === "TZS" ? (
                                    <CurrencyFormat
                                        value={Math.round(Number(cart.subtotal))}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        renderText={(value) => (
                                            <div>
                                                {userCurrency()}{" "}{value}
                                            </div>
                                        )}
                                    />
                                ) : (
                                    <CurrencyFormat
                                        value={Number(cart.subtotal).toFixed(2)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        renderText={(value) => (
                                            <div>
                                                {userCurrency()}{" "}{value}
                                            </div>
                                        )}
                                    />
                                )
                                }

                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="dropcart__buttons">
                    {/* <Link className="btn btn-secondary" to="/shop/cart">View Cart</Link> */}
                    {button}
                    <LoginPage show={loginModal} onHide={() => HideModal()} Register={() => RegisterAction()}/>
                    <RegisterPage show={registerModal} onHide={() => setRegisterModal(false)}
                                  onLogin={() => LoginAction()}/>
                </div>
            </div>

        );
    } else {
        dropdown = (
            <>
                {!cart.quantity && state === false ?
                    <div className="dropcart">
                        {
                            agenttoken() && agentcustomerToken() ?
                                <p className="container row mt-1">
                                    <span className="col-4 font-weight-bold">
                                        CUSTOMER:
                                    </span>
                                    {" "}{" "}
                                    <span className="col-6 text-capitalize font-weight-bold">
                                        {CurrentCustomer}
                                    </span>
                                    {" "}{" "}
                                    <AsyncAction
                                        action={() => ChangeCurrentCustomer("")}
                                        render={({run, loading}) => (
                                            <Link
                                                className="col-2 text-capitalize text-success font-weight-bold"
                                                onClick={run}
                                            >
                                                Change
                                            </Link>
                                        )}
                                    />
                                </p> :
                                null
                        }
                        <hr/>
                        <div className="dropcart__empty">Your shopping cart is empty!</div>
                    </div> :
                    <></>
                }
            </>
        );
    }

return (
    <Indicator
        dropdown={dropdown}
        value={cart.items.length}
        icon={<Cart20Svg/>}
        onClick={clickDropdown}
    />
);
}

const mapStateToProps = (state) => (
    {
        cart: state.cart,
            authetications: state.auth,
            CurrentCustomer: state.CurrentCustomer
    }
);

const mapDispatchToProps =
    {
        ChangeCurrentCustomer,
        cartRemoveItem,
    }
;

export default connect(mapStateToProps, mapDispatchToProps)(IndicatorCart);
