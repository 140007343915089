import {AGENT_COMMISSION} from "./CommissionActionTypes";

export default function  CommissionReducer(state=[],action) {
    switch(action.type){

        case AGENT_COMMISSION:
            return action.payload
        default:
            return state;
    }
}
