import { toast } from "react-toastify";
import {
  DIGITAL_WISHLIST_ADD_ITEM,
  DIGITAL_WISHLIST_REMOVE_ALL,
  DIGITAL_WISHLIST_REMOVE_ITEM,
} from "./digitalwishlistActionTypes";
import { customertoken, agenttoken, agentcustomerToken } from "../../api/shop";
import Axios from "axios";
import { digitalCartDataFromDb } from '../digitalCart';
export function digitalwishlistAddItemSuccess(product) {
  toast.success(`Product "${product.name}" added to wish list!`);
  return {
    type: DIGITAL_WISHLIST_ADD_ITEM,
    product,
  };
}

export function digitalwishlistLoggedAddItemSuccess(product) {

  return {
    type: DIGITAL_WISHLIST_ADD_ITEM,
    product,
  };
}

export function digitalwishlistRemoveItemSuccess(productId) {
  return {
    type: DIGITAL_WISHLIST_REMOVE_ITEM,
    productId,
  };
}
export function digitalwishlistRemoveAll() {
  return {
    type: DIGITAL_WISHLIST_REMOVE_ALL,
  };
}

export function digitalWishlistDataFromDb(options = []) {
  const agentcustomer_Token = agentcustomerToken();
  const agentToken = agenttoken();
  return (dispatch) =>
    new Promise((resolve) => {
      setTimeout(() => {
        if (agentcustomer_Token !== null && agentToken !== null) {
          dispatch(digitalwishlistRemoveAll());
          let dataDetails = {
            secure_token: agentcustomer_Token,
          };
          Axios.post(window.$name + "/api/wishlist/get-digital-wishlist", dataDetails).then(
            function (response) {
              let cart_list = response.data;
              if (response.status !== 205) {
                cart_list.forEach((item) => {
                  let product = {
                    id: item.id,
                    name: item.product.product_name,
                    slug: item.product.product_name,
                    price: Number(item.total_amount / item.quantity),
                    vat: item.vat,
                    wholesale_price: Number(item.price.wholesaleprice) + Number(item.price.total_wholesale_vat),
                    currency: item.currency,
                    currency_wholesale: item.currency,
                    discount: 0,
                    compareAtPrice: null,
                    status: item.product.is_featured,
                    images: item.product_images.productImage_name,
                    shop_id: item.outlet_info.id,
                    seller_token: item.seller_info.secure_token,
                    secure_token: item.secure_token,
                    product_token: item.product_token,
                    outlets_token: item.outlet_token,
                    total_amount: item.total_amount,
                    Category: "",
                    badges: [],
                    rating: 5,
                    reviews: 3,
                    brand: item.product.brand,
                    categories: [],
                    attributes: [],
                    customFields: {},

                  };

                  dispatch(digitalwishlistLoggedAddItemSuccess(product));
                });
              }
            }
          );
        }
        else {
          dispatch(digitalwishlistRemoveAll());
          let dataDetails = {
            secure_token: customertoken(),
          };
          Axios.post(window.$name + "/api/wishlist/get-digital-wishlist", dataDetails).then(
            function (response) {
              let cart_list = response.data;
              // console.log("1232345566", response.data);
              if (response.status !== 205) {
                cart_list.forEach((item) => {
                  let product = {
                    id: item.id,
                    name: item.product.product_name,
                    slug: item.product.product_name,
                    price: Number(item.total_amount / item.quantity),
                    vat: item.vat,
                    wholesale_price: Number(item.price.wholesaleprice) + Number(item.price.total_wholesale_vat),
                    currency: item.currency,
                    currency_wholesale: item.currency,
                    discount: 0,
                    compareAtPrice: null,
                    status: item.product.is_featured,
                    images: item.product_images.productImage_name,
                    shop_id: item.outlet_info.id,
                    seller_token: item.seller_info.secure_token,
                    secure_token: item.secure_token,
                    product_token: item.product_token,
                    outlets_token: item.outlet_token,
                    total_amount: item.total_amount,
                    Category: "",
                    badges: [],
                    rating: 5,
                    reviews: 3,
                    brand: item.product.brand,
                    categories: [],
                    attributes: [],
                    customFields: {},
                  };
                  dispatch(digitalwishlistLoggedAddItemSuccess(product));
                });
              }
            }
          );
        }
      }, 500);
    });
}

export function digitalwishlistAddItem(product, options = [], quantity = 1) {
  // console.log(product);
  const agentcustomer_Token = agentcustomerToken();
  const agentToken = agenttoken();
  // sending request to server, timeout is used as a stub
  // console.log('tag', product)
  return (dispatch) =>
    new Promise((resolve) => {
      setTimeout(() => {
        if (agentcustomer_Token !== null && agentToken !== null) {
          let detail_data = {
            secure_token: agentcustomer_Token,
            product_token: product.product_token,
            outlet_token: product.outlets_token,
            quantity: 1,
            seller_token: product.seller_token,
            total_amount: product.price,
            currency: product.currency,
            vat: product.vat,
          };
          Axios.post(window.$name + "/api/wishlist/add-digital-wishlist", detail_data)
            .then(function (response) {
              toast.success(`Product "${product.name}" added to wishlist!`);
              dispatch(digitalwishlistLoggedAddItemSuccess(product));
              // dispatch(digitalCartDataFromDb())
              dispatch(digitalWishlistDataFromDb());

            })
            .catch(function (error) {
              if (error.response) {
                toast.error(
                  `"${error.response.data.message}" Error Status  "${error.response.status}" `
                );
              } else if (error.request) {
                toast.error(`"${error.request}" `);
              } else {
                toast.error(`"${error.message}" `);
              }
            });
        }
        else if (customertoken()) {
          let detail_data = {
            secure_token: customertoken(),
            product_token: product.product_token,
            outlet_token: product.outlets_token,
            quantity: 1,
            seller_token: product.seller_token,
            total_amount: product.price,
            currency: product.currency,
            vat: product.vat,
          };
          Axios.post(window.$name + "/api/wishlist/add-digital-wishlist", detail_data)
            .then(function (response) {
              toast.success(`Product "${product.name}" added to wishlist!`);
              // console.log(product);
              dispatch(digitalwishlistLoggedAddItemSuccess(product));
              dispatch(digitalCartDataFromDb())
              dispatch(digitalWishlistDataFromDb());

            })
            .catch(function (error) {
              if (error.response) {
                toast.error(
                  `"${error.response.data.message}" Error Status  "${error.response.status}" `
                );
              } else if (error.request) {
                toast.error(`"${error.request}" `);
              } else {
                toast.error(`"${error.message}" `);
              }
            });
        } else {
          dispatch(digitalwishlistAddItemSuccess(product));
        }
        resolve();
      }, 500);
    });

}

export function digitalwishlistRemoveItem(productId, product_token) {
  const agentcustomer_Token = agentcustomerToken();
  const agentToken = agenttoken();
  // sending request to server, timeout is used as a stub
  return (dispatch) =>
    new Promise((resolve) => {
      setTimeout(() => {
        if (agentcustomer_Token !== null && agentToken !== null) {
          let data_details = {
            secure_token: agentcustomer_Token,
            product_token: product_token,
          };
          Axios.post(
            window.$name + "/api/wishlist/remove-from-list",
            data_details
          )
            .then(function (response) {
              toast.success(`Deleted successfully from wishlist!`);
              dispatch(digitalwishlistRemoveItemSuccess(productId));
            })
            .catch(function (error) {
              if (error.response) {
                toast.error(
                  `"${error.response.data.message}" Error Status  "${error.response.status}" `
                );
              } else if (error.request) {
                toast.error(`"${error.request}" `);
              } else {
                toast.error(`"${error.message}" `);
              }
            });
        } else if (customertoken()) {
          let data_details = {
            secure_token: customertoken(),
            product_token: product_token,
          };
          Axios.post(
            window.$name + "/api/wishlist/remove-from-list",
            data_details
          )
            .then(function (response) {
              toast.success(`Deleted successfully from wishlist!`);
              dispatch(digitalwishlistRemoveItemSuccess(productId));
            })
            .catch(function (error) {
              if (error.response) {
                toast.error(
                  `"${error.response.data.message}" Error Status  "${error.response.status}" `
                );
              } else if (error.request) {
                toast.error(`"${error.request}" `);
              } else {
                toast.error(`"${error.message}" `);
              }
            });
        } else {
          dispatch(digitalwishlistRemoveItemSuccess(productId));
        }
        resolve();
      }, 500);
    });
}
