import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import StepConnector from "@material-ui/core/StepConnector";
import Typography from "@material-ui/core/Typography";
import AddressPage from "./AddressStage";
import QuoteProducts from './quoteProduct'
import ShopPageOrderSuccess from "./ShopPageOrderSuccess";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import RateReviewOutlinedIcon from '@material-ui/icons/RateReviewOutlined';
// import ConfirmQuote from './confirmQuote';
import QuoteListpage from './shopPageQuote';
// import PageHeader from "../shared/PageHeader";
import { Helmet } from "react-helmet-async";
import theme from "../../data/theme";
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#784af4',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
});

const breadcrumb = [
    { title: "Home", url: "" },
    { title: "Quote List", url: "" },
];

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
        </div>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 95deg,rgb(255, 211, 51) 0%,rgb(255, 211, 51) 50%,rgb(255, 211, 51) 100%)',
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient(95deg,rgb(255, 211, 51) 0%,rgb(255, 211, 51) 50%,rgb(255, 211, 51) 100%)',
        },
        display: 'inline-block'
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(255, 211, 51) 0%, rgb(255, 211, 51) 60%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient(136deg, rgb(255, 211, 51) 0%, rgb(255, 211, 51) 60%, rgb(138,35,135) 100%)',
    },
});
function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
    const icons = {
        1: <ShoppingCartOutlinedIcon />,
        2: <HomeOutlinedIcon />,
        3: <RateReviewOutlinedIcon />,
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}
ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */


    icon: PropTypes.node,
};
function getSteps() {
    return ["Products", "Address", "Submit"];
}
function getStepContent(step, setActiveStep) {
    // console.log(step)
    switch (step) {
        case 0:
            return <QuoteProducts activeStep={setActiveStep} stepNumber={step} />;
        case 1:
            return <AddressPage activeStep={setActiveStep} stepNumber={step} />;
        case 2:
            return <QuoteListpage activeStep={setActiveStep} stepNumber={step} />;
        case 3:
            return <ShopPageOrderSuccess />;
        default:
            return 'Unknown step';
    }
}
export function handleSubmits(setActiveStep) {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
}
export function handleNexts(setActiveStep) {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
}
export function handleBacks(setActiveStep) {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
}
export default function CustomizedSteppers() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const steps = getSteps();
    const handleNext = () => {
        handleNexts(setActiveStep);
    };
    const handleBack = () => {
        handleBacks(setActiveStep);
    };
    const handleSubmit = () => {
        handleSubmits(setActiveStep);
    };
    const handleStep = (step) => () => {
        setActiveStep(step);
    };
    return (
        <React.Fragment>
            <Helmet>
                <title>{`qoutation — ${theme.name}`}</title>
            </Helmet>
            {/* <PageHeader  breadcrumb={breadcrumb} /> */}
            <div className={classes.root}>
                <Stepper nonLinear alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                    {steps.map((label, index) => (
                        <Step key={label}>

                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>

                        </Step>
                    ))}
                </Stepper>
                <div>
                    {activeStep === steps.length ? (
                        <div>
                            <Typography className={classes.instructions}>
                                <ShopPageOrderSuccess />
                            </Typography>
                        </div>
                    ) : (
                            <div>
                                <Typography className={classes.instructions}>{getStepContent(activeStep, setActiveStep)}</Typography>

                            </div>
                        )}
                </div>
            </div>
        </React.Fragment>


    );
}
