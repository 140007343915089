import React, { useEffect, useState } from "react";
//  third-party
import { useDispatch, connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import theme from "../../data/theme";
import axios from "axios";
import { Scrollbars } from "react-custom-scrollbars";
import { handleNexts, handleBacks } from "./checkout";
import { Link } from "@material-ui/core";
import { Countries } from "../../api/ezytradeApi";
import { FetchShippingAddress } from "../../store/shippingAddress";
import AsyncAction from "../shared/AsyncAction";
import InputMask from "react-input-mask";
import {
  AddAddress,
  ChangeAddress,
  DeleteAddress,
} from "../../store/shippingAddress";
import * as yup from "yup";
import { useFormik } from "formik";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
//creating the validation schema
const validationSchema = yup.object().shape({
  fullname: yup
    .string()
    .required("Please enter your full name")
    .min(4, "Fullname must be at least 4  characters"),
  postcode:yup
    .string()
    .required("This field is required!"),
  mobile: yup
    .string()
    // .test("len", "Phone number too short must be 12 number.", (val) => {
    //   const val_length_without_dashes = val.replace(/-|_/g, "").length;
    //   return val_length_without_dashes === 13;
    // })
    .required("Phone number is required!"),
  // .min(10, "Your mobile number must be at least 10 digits")
  // .max(12, "mobile number must be less than 12 digits"),
  othermobile: yup.number("mobile number container only numeric characters"),
  country: yup.string().required("Please select country"),
  region: yup.string().required("Please Select Region"),
  district: yup.string().required("Please Select district"),
  // street: yup
  //     .string()
  //     .required("Please enter your Street name"),
  // housenumber: yup
  //     .string(),
  addresstype: yup.string().required("Please select Address Type"),
  // additional: yup
  //     .string(),
});
function ShopPageCheckout(props) {
  const { AddAddress, profile, ChangeAddress, DeleteAddress ,stepNumber,activeStep ,isLogin } = props;
  const [countries, setCountry] = useState([]);
  const [regions, setRegions] = useState([]);
  const formik = useFormik({
    initialValues: {
      fullname: "",
      mobile: "",
      email: "",
      // postcode: "",
      // country: "",
      // region: "",
      // district: "",
      // street: "",
      // housenumber: "",
      // addresstype: "",
      additional: "",
      // fullname: "",
      // mobile: "",
      // othermobile: "",
      // postcode: "",
      // country: "",
      // region: "",
      // district: "",
      // street: "",
      // housenumber: "",
      // addresstype: "",
      // additional: "",
    },
    validationSchema,
  });
  function handleFullName(fullname) {
    localStorage.setItem("full_name", fullname);
  }
  function handlePhone(phone) {
    localStorage.setItem("phone_number", phone);
  }
  function handleEmail(email) {
    localStorage.setItem("email", email);
  }
  function handleLocation(location) {
    localStorage.setItem("location", location);
  }
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(FetchShippingAddress());
  // }, []);
  //  country select
  const [state, setState] = useState({
    fullname: "",
    mobile: "",
    email: "",
    // postcode: "",
    // country: "",
    // region: "",
    // district: "",
    // street: "",
    // housenumber: "",
    // addresstype: "",
    additional: "",
  });
  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };
 
  useEffect(() => {
    const fetchData = async () => {
      const results = await axios
        .get(window.$name + "/api/adminstrator/get-countries")
        .then((response) => response.data);
      setCountry(results);
    };
    fetchData();
  }, []); 
  const GetRegions = (e) => {
    const { id, value } = e.target;
    formik.values.country = e.target.value;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    axios
      .get(window.$name + "/api/adminstrator/get-regions", {
        params: {
          country_id: e.target.value,
        },
      })
      .then((res) => {
        setRegions(res.data);
      });
  };
  const [district, setDistrict] = useState([]);
  const GetDistrict = (e) => {
    const { id, value } = e.target;
    formik.values.region = e.target.value;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    axios
      .get(window.$name + "/api/adminstrator/get-districts", {
        params: {
          region_id: e.target.value,
        },
      })
      .then((res) => {
        setDistrict(res.data);
      });
  };
  //  fetch shipping address

    return (
      <div className="checkout">
        <Helmet>
          <title>{`Address — ${theme.name}`}</title>
        </Helmet>
        <div className="block">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-12 col-lg-6 col-xl-7">
                <div className="card mb-lg-0 shadow-lg rounded">
                  <div className="card-body">
                    <h3 className="card-title text-center">
                      GUEST INFORMATION
                    </h3>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="checkout-first-name">Full Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="fullname"
                          value={formik.values.fullname}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.fullname && formik.errors.fullname ? (
                          <div className="text-danger">
                            {formik.errors.fullname}
                          </div>
                        ) : null}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="checkout-last-name">Phone Number</label>
                        <input
                          type="text"
                          className="form-control"
                          id="mobile"
                          value={formik.values.mobile}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.mobile && formik.errors.mobile ? (
                          <div className="text-danger">
                            {formik.errors.mobile}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label htmlFor="checkout-country">
                          Email Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="checkout-street-address">
                        Location
                      </label>
                      <textarea
                        type="text"
                        className="form-control mt-2"
                        id="additional"
                        placeholder="Additional delivery information"
                        value={formik.values.additional}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />

                      {formik.touched.additional && formik.errors.additional ? (
                        <div className="text-danger">
                          {formik.errors.additional}
                        </div>
                      ) : null}
                    </div>
                           <button
                                  type="submit"
                                  disabled={!formik.values.additional || !formik.values.mobile || !formik.values.fullname}
                                  onClick={() => {
                                    handleFullName(formik.values.fullname);
                                    handlePhone(formik.values.mobile);
                                    handleEmail(formik.values.email);
                                    handleLocation(formik.values.additional)
                                    handleNexts(activeStep);
                                  }}
                                  className="btn-block btn btn-primary"
                                >
                                  {" "}
                                  <i className="fa fa-truck text-black mr-2">
                                    {" "}
                                  </i>
                                  DELIVER TO THIS ADDRESS
                                </button>
                  </div>
                </div>
                <br />
                {
                  stepNumber>0?(
                     <button
                  className="btn btn-secondary"
                  onClick={() => handleBacks(activeStep)}
                >
                  {" "}
                  <i className="fa fa-backward text-black"> </i> Previous
                </button>
                  ):null
                }
               
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
const mapStateToProps = (state) => ({
  // shippingAddress: state.shippingAddress.user_address,
  profile: state.profile,
  isLogin: state.auth.isLogin
});

const mapDispatchToProps = {
  AddAddress,
  ChangeAddress,
  DeleteAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckout);
