import React from 'react';
import WidgetBooking from "./widgets/WidgetBooking";
import WidgetOpenHours from "./widgets/WidgetOpenHours";
import WidgetFollow from "./widgets/WidgetFollow";
import {agentcustomerToken, customertoken} from "../../api/shop";
import NotLoginBooking from "./widgets/UserNotLoginBooking";
import EnquiryFormLogin from "./widgets/EnquiryFormLogin";
import EnquiryNotLogin from "./widgets/EnquiryFormNotLOgin";
import WidgetPromotedListing from "./promotedListingsDetailspage";

function ListingDetailsSidebar(props) {
    const {data} = props
    return (
        <div>
            <div className="sidebar section-bg mt-5">
                <WidgetOpenHours open={data.listing_time} holidays={data.listing.holidays} schedule={data.listing.schedule}/>
                {data.listing.allow_booking ?
                    <>
                        {/* {customertoken() || agentcustomerToken() ?
                            <WidgetBooking Booking={data}/>
                            :
                            <NotLoginBooking Booking={data}/>
                        } */}
                    </>
                      :
                   <>
                       {customertoken() || agentcustomerToken() ?
                           <EnquiryFormLogin Booking={data}/>
                           :
                           <EnquiryNotLogin Booking={data}/>
                       }
                   </>

                }
                <WidgetFollow data={data}/>

                <WidgetPromotedListing data={data.promoted_listings}/>
            </div>
        </div>
    );
}

export default ListingDetailsSidebar;
