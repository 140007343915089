import {PARTIAL_DELETE_ORDER_DATA,PARTIAL_ORDER_DATA} from "./orderPartialActionTypes";

export default function orderPartialReducer(state=[],action) {
    switch(action.type){
        case PARTIAL_ORDER_DATA:
            return action.payload
        case PARTIAL_DELETE_ORDER_DATA:
            return action.payload
        default:
            return state;
    }
}
