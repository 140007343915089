// react
import React, { useState, useEffect } from "react";
// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
// application
// data stubs
import theme from "../../data/theme";
import CurrencyFormat from "react-currency-format";
import Moment from "react-moment";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { FetchQuoteDetail } from "../../store/QuoteList";
import axios from "axios";
import { Spinner, Row } from "reactstrap";
import { userCurrency } from "../../api/shop";
const useStyles = makeStyles({
  root: {
    width: 200,
    display: "flex",
    alignItems: "center",
  },
});

function ShopPageQuoteitems(props) {
  const quoteItem = props.location.state;
  // console.log(quoteItem);
  const { FetchQuoteDetail } = props;
  const [details, setDetails] = useState([]);
  const [datasend, setDatasent] = useState([]);
  const Rates = quoteItem[1].Rates;
  useEffect(() => {
    const getqouteDetails = async () => {
      const results = await axios.post(window.$name + "/api/quote/quotes-details", {
        quotes_token: quoteItem[0].quote.secure_token,
      })
        .then((response) => response.data);
      setDetails(results.quotes);
      setDatasent(results);

    };
    getqouteDetails();
  }, []);
  console.log(details);
  return (
    <div className="block order-success">
      <Helmet>
        <title>{`Order items — ${theme.name}`}</title>
      </Helmet>
      <div className="container">
        {details.length === 0 ?
          (
            <>
              <span className="d-flex justify-content-center">
                <Spinner size="xl" />
              </span>
              <span className="d-flex mt-10 justify-content-center font-weight-bold">
                Data Loading...
            </span>
            </>
          ) : (

            <div className="order-success__body">
              <div className="order-success__meta">
                <ul className="order-success__meta-list">
                  <li className="order-success__meta-item">
                    <span className="order-success__meta-title">Amount:</span>
                    <span className="order-success__meta-value">
                      {/* <CurrencyFormat
                        value={details.quotes.totalAmountPaid - details.quotes.totalVatPaid}
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => (
                          <div>
                            {" "}
                            {details.quotes.requestCurrency} {"  "} {value}
                          </div>
                        )}
                      /> */}
                      {userCurrency() === details.requestCurrency ? (
                        <CurrencyFormat
                          value={Math.round((Number(details.totalAmountPaid - details.totalVatPaid) / Number(Rates[details.requestCurrency])) * Number(Rates[userCurrency()]))
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          renderText={(value) => (
                            <div>
                              {userCurrency()}{" "}{value}
                              {/*{product.wholesale_currency} {value}*/}
                            </div>
                          )}
                        />
                      ) : (
                          <CurrencyFormat
                            value={((Number(details.totalAmountPaid - details.totalVatPaid) / Number(Rates[details.requestCurrency])) * Number(Rates[userCurrency()])).toFixed(2)
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                              <div>
                                {userCurrency()}{" "}{value}
                                {/*{product.wholesale_currency} {value}*/}
                              </div>
                            )}
                          />
                        )
                      }
                    </span>
                  </li>
                  <li className="order-success__meta-item">
                    <span className="order-success__meta-title">Total Vat:</span>
                    <span className="order-success__meta-value">
                      {/* <CurrencyFormat
                        value={details.quotes.totalVatPaid}
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => (
                          <div>
                            {" "}
                            {details.quotes.requestCurrency} {"  "} {value}
                          </div>
                        )}
                      /> */}
                      {userCurrency() === details.requestCurrency ? (
                        <CurrencyFormat
                          value={Math.round((Number(details.totalVatPaid) / Number(Rates[details.requestCurrency])) * Number(Rates[userCurrency()]))
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          renderText={(value) => (
                            <div>
                              {userCurrency()}{" "}{value}
                              {/*{product.wholesale_currency} {value}*/}
                            </div>
                          )}
                        />
                      ) : (
                          <CurrencyFormat
                            value={((Number(details.totalVatPaid) / Number(Rates[details.requestCurrency])) * Number(Rates[userCurrency()])).toFixed(2)
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                              <div>
                                {userCurrency()}{" "}{value}
                                {/*{product.wholesale_currency} {value}*/}
                              </div>
                            )}
                          />
                        )
                      }
                    </span>
                  </li>
                  <li className="order-success__meta-item">
                    <span className="order-success__meta-title">
                      Total Amount:
                  </span>
                    <span className="order-success__meta-value">
                      {/* <CurrencyFormat
                        value={details.quotes.totalAmountPaid}
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => (
                          <div>
                            {" "}
                            {details.quotes.requestCurrency} {"  "} {value}
                          </div>
                        )}
                      /> */}

                      {userCurrency() === details.requestCurrency ? (
                        <CurrencyFormat
                          value={Math.round((Number(details.totalAmountPaid) / Number(Rates[details.requestCurrency])) * Number(Rates[userCurrency()]))
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          renderText={(value) => (
                            <div>
                              {userCurrency()}{" "}{value}
                              {/*{product.wholesale_currency} {value}*/}
                            </div>
                          )}
                        />
                      ) : (
                          <CurrencyFormat
                            value={((Number(details.totalAmountPaid) / Number(Rates[details.requestCurrency])) * Number(Rates[userCurrency()])).toFixed(2)
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                              <div>
                                {userCurrency()}{" "}{value}
                                {/*{product.wholesale_currency} {value}*/}
                              </div>
                            )}
                          />
                        )
                      }
                    </span>
                  </li>
                  <li className="order-success__meta-item">
                    <span className="order-success__meta-title">Created at:</span>
                    <span className="order-success__meta-value">
                      <Moment format="DD/MM/YYYY">{details.created_at}</Moment>
                    </span>
                  </li>
                  <li className="order-success__meta-item">
                    <span className="order-success__meta-title">Status:</span>
                    {details.status !== "Pending" ? (
                      <span className="text-success order-success__meta-value">
                        Accepted
                      </span>
                    ) : (
                        <span className="text-danger order-success__meta-value">
                          Pending
                        </span>
                      )}
                  </li>
                </ul>
              </div>
              <div className="card">
                <div className="order-list">
                  <table>
                    <thead className="order-list__header">
                      <tr>
                        <td className="order-list__column-label" colSpan="2">
                          Product Image
                      </td>
                        <td className="order-list__column-label">name</td>
                        <td className="order-list__column-label">Preference</td>
                        <td className="order-list__column-quantity">Qty</td>
                        <td className="order-list__column-quantity">Sub Total</td>
                        <td className="order-list__column-quantity">VAT</td>
                        <td className="order-list__column-quantity">Action</td>
                      </tr>
                    </thead>
                    <tbody className="h-10 overflow-auto w-auto order-list__products">

                      <tr>
                        <td colSpan="2" className="order-list__column-image">
                          <div className="product-image">
                            <div className="product-image__body">
                              <img
                                className="product-image__img"
                                src={details.product.images.productImage_name}
                                alt=""
                              />
                            </div>
                          </div>
                        </td>
                        <td className="order-list__column-product text-center"
                        >
                          {details.product.product_name}
                        </td>
                        <td className="order-list__column-product">
                          {details.preference_color !== null && details.preference_size !== null ? (
                            <>
                              <Row>
                                Color :&nbsp;{details.preference_color}
                              </Row>
                              <Row>
                                Size :&nbsp;{details.preference_size}
                              </Row>
                            </>
                          ) : details.preference_color !== null && details.preference_size === null ? (
                            <Row>
                              Color :&nbsp;{details.preference_color}
                            </Row>
                          ) : details.preference_color === null && details.preference_size !== null ? (
                            <Row>
                              Size :&nbsp;{details.preference_size}
                            </Row>
                          ) :
                                <span>Empty</span>
                          }
                        </td>

                        <td
                          className="order-list__column-quantity"
                          data-title="Qty:"
                        >
                          {details.request_qty}
                        </td>
                        <td className="order-list__column-total">
                         
                          {userCurrency() === details.requestCurrency ? (
                            <CurrencyFormat
                              value={Math.round((Number(details.totalAmountPaid - details.totalVatPaid) / Number(Rates[details.requestCurrency])) * Number(Rates[userCurrency()]))
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              renderText={(value) => (
                                <div>
                                  {userCurrency()}{" "}{value}
                                  {/*{product.wholesale_currency} {value}*/}
                                </div>
                              )}
                            />
                          ) : (
                              <CurrencyFormat
                                value={((Number(details.totalAmountPaid - details.totalVatPaid) / Number(Rates[details.requestCurrency])) * Number(Rates[userCurrency()])).toFixed(2)
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                renderText={(value) => (
                                  <div>
                                    {userCurrency()}{" "}{value}
                                    {/*{product.wholesale_currency} {value}*/}
                                  </div>
                                )}
                              />
                            )
                          }
                        </td>
                        <td className="order-list__column-total">
                         
                         {userCurrency() === details.requestCurrency ? (
                           <CurrencyFormat
                             value={Math.round((Number(details.totalVatPaid) / Number(Rates[details.requestCurrency])) * Number(Rates[userCurrency()]))
                             }
                             displayType={"text"}
                             thousandSeparator={true}
                             renderText={(value) => (
                               <div>
                                 {userCurrency()}{" "}{value}
                                 {/*{product.wholesale_currency} {value}*/}
                               </div>
                             )}
                           />
                         ) : (
                             <CurrencyFormat
                               value={((Number(details.totalVatPaid) / Number(Rates[details.requestCurrency])) * Number(Rates[userCurrency()])).toFixed(2)
                               }
                               displayType={"text"}
                               thousandSeparator={true}
                               renderText={(value) => (
                                 <div>
                                   {userCurrency()}{" "}{value}
                                   {/*{product.wholesale_currency} {value}*/}
                                 </div>
                               )}
                             />
                           )
                         }
                       </td>
                        <td className="order-list__column-quantity"
                          data-title="Action:"
                        >
                          {details.status !== "Pending" ? (
                            <Link
                              to="/shop/quote-checkout"
                              onClick={() => { FetchQuoteDetail(datasend) }}
                              className="btn btn-rounded btn-sm text-primary "
                            >
                              Make Order
                            </Link>
                          ) : null
                          }

                        </td>
                        <hr />
                      </tr>

                    </tbody>

                    <tfoot className="order-list__footer">
                      <tr>
                        <th className="order-list__column-label" colSpan="4">
                          Total
                      </th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="order-list__column-total">
                          {/* <CurrencyFormat
                            value={details.quotes.totalAmountPaid}
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                              <div>
                                {" "}
                                {details.quotes.requestCurrency} {"  "} {value}
                              </div>
                            )}
                          /> */}
                          {userCurrency() === details.requestCurrency ? (
                            <CurrencyFormat
                              value={Math.round((Number(details.totalAmountPaid) / Number(Rates[details.requestCurrency])) * Number(Rates[userCurrency()]))
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                              renderText={(value) => (
                                <div>
                                  {userCurrency()}{" "}{value}
                                  {/*{product.wholesale_currency} {value}*/}
                                </div>
                              )}
                            />
                          ) : (
                              <CurrencyFormat
                                value={((Number(details.totalAmountPaid) / Number(Rates[details.requestCurrency])) * Number(Rates[userCurrency()])).toFixed(2)
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                renderText={(value) => (
                                  <div>
                                    {userCurrency()}{" "}{value}
                                    {/*{product.wholesale_currency} {value}*/}
                                  </div>
                                )}
                              />
                            )
                          }
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>


          )
        }
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  // shippingAddress: state.shippingAddress,
  // profile: state.profile,
});

const mapDispatchToProps = {
  FetchQuoteDetail
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageQuoteitems);
