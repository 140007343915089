// react
import React, {Component} from "react";
import {Link} from "react-router-dom";
// third-party
import classNames from "classnames";
// application
import PropTypes from 'prop-types';
import departmentsAria from "../../services/departmentsArea";
import languages from "../../i18n";
import StroykaSlick from "../shared/StroykaSlick";
import {connect} from "react-redux";
// import {Carousel, CarouselControl, CarouselIndicators, CarouselItem} from 'reactstrap';

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
};

class BlockSlideShow extends Component {
    departmentsAreaRef = null;
    media = window.matchMedia("(min-width: 992px)");

    constructor(props) {
        super(props);
        this.state = {
            slides: [],
            activeIndex: 0
        };
        // console.log(this.state.slides);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
        this.goToIndex = this.goToIndex.bind(this);
        this.onExiting = this.onExiting.bind(this);
        this.onExited = this.onExited.bind(this);
    }

    onExiting() {
        this.animating = true;
    }

    onExited() {
        this.animating = false;
    }

    next() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === this.state.slides.length - 1 ? 0 : this.state.activeIndex + 1;
        this.setState({activeIndex: nextIndex});
    }

    previous() {
        if (this.animating) return;
        const nextIndex = this.state.activeIndex === 0 ? this.state.slides.length - 1 : this.state.activeIndex - 1;
        this.setState({activeIndex: nextIndex});
    }

    goToIndex(newIndex) {
        if (this.animating) return;
        this.setState({activeIndex: newIndex});
    }


    componentDidMount() {
        fetch(window.$name + '/api/adminstrator/show-banners')
            .then((response) => response.json())
            .then((datalist) => this.setState({slides: datalist}));

        if (this.media.addEventListener) {
            this.media.addEventListener("change", this.onChangeMedia);
        } else {
            this.media.addListener(this.onChangeMedia);
        }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener("change", this.onChangeMedia);
        } else {
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    render() {

        const {slides} = this.state;
        const {locale, withDepartments} = this.props;
        const {direction} = languages[locale];
        const {activeIndex} = this.state;
        const blockClasses = classNames("block-slideshow block", {
            "block-slideshow--layout--full": !withDepartments,
            "block-slideshow--layout--with-departments": withDepartments,
        });
        const layoutClasses = classNames("col-12", {
            "col-lg-12": !withDepartments,
            "col-lg-9": withDepartments,
        });

        // const banner = slides.map((slide, index) => {

            // if () {
            //     return (
            //         <CarouselItem
            //             onExiting={this.onExiting}
            //             onExited={this.onExited}
            //             key={index}
            //         >
            //             {/*{ slide.view_option === 'webview' && !slides.length <= 0 &&*/}
            //                 <img src={slide.secure_url} alt={slide.view_option}/>
            //             {/*}*/}
            //         </CarouselItem>
                // )
            // } else {
            //     return (
            //         <CarouselItem
            //             onExiting={this.onExiting}
            //             onExited={this.onExited}
            //             // key={index}
            //         >
            //             {/*<img*/}
            //             {/*    src="http://res.cloudinary.com/business-test/image/upload/c_fit,h_395,w_840/bpgif2jmypwxfmwko3sq.png"*/}
            //             {/*    alt="alt"/>*/}
            //         </CarouselItem>
            //
            //
            //     )
            // }
        // });
             const  banner = slides.map((slide, index) => {
                  if (!withDepartments || slide.view_option === 'webview')  {
                      return (
                                   <div key={index} className="block-slideshow__slide">

                                          <div
                                              className="block-slideshow__slide-image  block-slideshow__slide-image--desktop"
                                              style={{
                                                  backgroundImage: `url(${slide.secure_url})`,
                                              }}
                                          />
                                       {/*<CarouselItem*/}
                                       {/*    onExiting={this.onExiting}*/}
                                       {/*    onExited={this.onExited}*/}
                                       {/*    // key={index}*/}
                                       {/*>*/}
                                       {/*    <img src={slide.secure_url} alt="1" />*/}
                                       {/*    /!*<CarouselCaption captionText="one" captionHeader="one" />*!/*/}
                                       {/*</CarouselItem>*/}
                                       {/*   <div className="block-slideshow__slide-content">*/}
                                       {/*       <div className="block-slideshow__slide-button">*/}
                                       {/*           <Link*/}
                                       {/*               to="/shop/all-products"*/}
                                       {/*               className="btn btn-primary btn-lg"*/}
                                       {/*           >*/}
                                       {/*               Shop Now*/}
                                       {/*           </Link>*/}
                                       {/*       </div>*/}
                                       {/*   </div>*/}

                                      </div>


                      );
                  }

        });

        const bannermobile = slides.map((slide, index) => {
            if (withDepartments && slide.view_option === 'mobileView') {
                return (
                    <div key={index} className="block-slideshow__slide">
                        <div
                            className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                            style={{backgroundImage: `url(${slide.secure_url})`,}}
                        />
                        <div className="block-slideshow__slide-content">
                            <div className="block-slideshow__slide-button">
                                <Link
                                    to="/shop/all-products"
                                    className="btn btn-primary btn-lg"
                                >
                                    Shop Now
                                </Link>
                            </div>
                        </div>
                    </div>
                )
            }
        });
        return (
            <div className={blockClasses}>
                <div className="container">
                    <div className="row">
                        {withDepartments && (
                            <div
                                className="col-3 d-lg-block d-none"
                                ref={this.setDepartmentsAreaRef}
                            />
                        )}
                        <div className={layoutClasses}>
                            <div className="d-none d-md-block d-xl-block d-lg-block block-slideshow__body">
                                {/*<Carousel*/}
                                {/*    activeIndex={activeIndex}*/}
                                {/*    next={this.next}*/}
                                {/*    previous={this.previous}*/}
                                {/*>*/}
                                {/*    <CarouselIndicators items={this.state.slides} activeIndex={activeIndex}*/}
                                {/*                        onClickHandler={this.goToIndex}/>*/}
                                {/*    {banner}*/}
                                {/*    <CarouselControl direction='prev' directionText='Previous'*/}
                                {/*                     onClickHandler={this.previous}/>*/}
                                {/*    <CarouselControl direction='next' directionText='Next' onClickHandler={this.next}/>*/}
                                {/*</Carousel>*/}
                                <StroykaSlick {...slickSettings}>
                                    {banner}
                                </StroykaSlick>
                            </div>
                            <div className=" d-sm-none .d-md-block block-slideshow__body">
                                <StroykaSlick {...slickSettings}>
                                    {bannermobile}
                                </StroykaSlick>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

BlockSlideShow.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
};

BlockSlideShow.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});
export default connect(mapStateToProps)(BlockSlideShow);
