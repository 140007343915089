import {REPORT_DATA} from "./reportActionTypes";
export default function reportReducer(state=[],action) {
    switch(action.type){

        case REPORT_DATA:
            return action.payload
        default:
            return state;
    }
}
