import React from 'react';
import {Link} from "react-router-dom";
import {GiChickenOven, GiWineGlass} from 'react-icons/gi'
import {TiPlane, TiBrush} from 'react-icons/ti'
import {RiHotelBedLine} from 'react-icons/ri'
import {IoMdMusicalNotes, IoIosFitness} from 'react-icons/io'
import {FiShoppingCart} from 'react-icons/fi'
import img1 from '../../assets/images/cta-bg.jpg';
import {Spinner} from "reactstrap";

function  PopularCategories (props) {
    const {Category}=props
    const states = {
        items: [
            {
                icon: <GiChickenOven />,
                title: 'Restaurants',
                stitle: '12 Listings',
                url: '#',
                img: img1
            },
            {
                icon: <TiPlane />,
                title: 'Travels',
                stitle: '22 Listings',
                url: '#',
                img: img1
            },
            {
                icon: <RiHotelBedLine />,
                title: 'Hotels',
                stitle: '33 Listings',
                url: '#',
                img: img1
            },
            {
                icon: <IoMdMusicalNotes />,
                title: 'Events',
                stitle: '44 Listings',
                url: '#',
                img: img1
            },
            {
                icon: <FiShoppingCart />,
                title: 'Shops',
                stitle: '48 Listings',
                url: '#',
                img: img1
            },
            {
                icon: <IoIosFitness />,
                title: 'Fitness',
                stitle: '50 Listings',
                url: '#',
                img: img1
            },
            {
                icon: <GiWineGlass />,
                title: 'Food & Drink',
                stitle: '14 Listings',
                url: '#',
                img: img1
            },
            {
                icon: <TiBrush />,
                title: 'Art & Design',
                stitle: '20 Listings',
                url: '#',
                img: img1
            }
        ]
    }
    return (
            <>
                <>
                {Category === undefined ?
                    <div className="text-center" style={{
                        marginTop: '10%',
                        marginLeft:'50%',
                        marginRight:'50%'
                    }}>
                        <Spinner style={{width: '3rem', height: '3rem'}}  />
                    </div>
                    :
                   <>
                {Category.map((item, index) => {
                    return (
                      
                        <div className="col-lg-3 column-td-6" key={index}>
                            <div className="category-item mb-4 mt-0 ml-0 mr-0 p-0">
                                <figure className="category-fig m-0">
                                    <img src={img1} alt="category" className="cat-img" />
                                    <figcaption className="fig-caption">
                                        <Link to={`/category/listings/${item.secure_token}`} className="cat-fig-box">
                                            <div className="cat-content">
                                                <h4 className="cat__title mb-3">{item.category_name}</h4>
                                                <span className="badge">{item.listing_count} Listings</span>
                                            </div>
                                        </Link>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                    )
                })}
                </>
                }
                </>

            </>
        );

}

export default PopularCategories;