import React from "react";
import {Route,Redirect}from "react-router-dom";
import {connect}from "react-redux";

export const PrivateRoute = ({authentication, component: Component, ...rest}) => (

    <Route {...rest} component={props => (
        authentication.isLogin ? (
            <Component {...props}/>

        ) : (
            <Redirect to={{
                pathname: '/account/login',
                state: { from: props.location }
            }}/>
        )
    )}/>

)

const mapStateToProps = (state) => ({
    authentication: state.auth,
});

export default connect(mapStateToProps)(PrivateRoute);
