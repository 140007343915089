// react
import React from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import PageHeader from "../shared/PageHeader";
import Rating from "../shared/Rating";
import { cartAddItem } from "../../store/cart";
import { Cross12Svg } from "../../svg";
import { url } from "../../services/utils";
import { wishlistRemoveItem } from "../../store/wishlist";
import { Scrollbars } from "react-custom-scrollbars";
import InputNumber from "../shared/InputNumber";
// data stubs
import theme from "../../data/theme";

function ShopPageWishlist(props) {
  const { wishlist, cartAddItem, wishlistRemoveItem } = props;
  const breadcrumb = [
    { title: "Home", url: "" },
    { title: "Group Wishlist", url: "" },
  ];

  let content;
  if (wishlist.length) {
    const itemsList = wishlist.map((item) => {
      let image;

      if (item.images.length > 0) {
        image = (
          <div className="product-image">
            <Link to={url.product(item)} className="product-image__body">
              <img className="product-image__img" src={item.images[0]} alt="" />
            </Link>
          </div>
        );
      }

      const renderAddToCarButton = ({ run, loading }) => {
        const classes = classNames("btn btn-primary btn-sm", {
          "btn-loading": loading,
        });

        return (
          <button
            type="button"
            data-toggle="modal"
            data-target="#exampleModalLong"
            className={classes}
          >
            Assigne To Member
          </button>
        );
      };

      const renderRemoveButton = ({ run, loading }) => {
        const classes = classNames("btn btn-light btn-sm btn-svg-icon", {
          "btn-loading": loading,
        });

        return (
          <button
            type="button"
            onClick={run}
            className={classes}
            aria-label="Remove"
          >
            <Cross12Svg />
          </button>
        );
      };

      return (
        <tr key={item.id} className="wishlist__row">
          <td className="wishlist__column wishlist__column--image">{image}</td>
          <td className="wishlist__column wishlist__column--product">
            <Link to={url.product(item)} className="wishlist__product-name">
              {item.name}
            </Link>
            <div className="wishlist__product-rating">
              <Rating value={item.rating} />
              <div className="wishlist__product-rating-legend">{`${item.reviews} Reviews`}</div>
            </div>
          </td>
          <td className="wishlist__column wishlist__column--stock">
            <div className="badge badge-success">In Stock</div>
          </td>
          <td className="wishlist__column wishlist__column--price">
            <Currency value={item.price} />
          </td>
          <td className="wishlist__column wishlist__column--tocart">
            <AsyncAction render={renderAddToCarButton} />
            <div
              className="modal fade"
              id="exampleModalLong"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5
                      className="modal-title container text-center"
                      id="exampleModalLongTitle"
                    >
                      ASSIGN PRODUCT TO MEMBER
                    </h5>
                    <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <Scrollbars style={{ height: 300 }}>
                      <ul class="list-group">
                        <li class="list-group-item list-group-item-action">
                          <span className="row">
                            <span className="col">Yusuph kaondo</span>
                            <span className="col">
                              <InputNumber
                                id="product-quantity"
                                aria-label="Quantity"
                                className="product__quantity"
                                size="sm"
                                min={1}
                                value="1"
                                //   onChange={this.handleChangeQuantity}
                              />
                            </span>
                          </span>
                        </li>
                        <li class="list-group-item list-group-item-action">
                          <span className="row">
                            <span className="col">Yusuph kaondo</span>
                            <span className="col">
                              <InputNumber
                                id="product-quantity"
                                aria-label="Quantity"
                                className="product__quantity"
                                size="sm"
                                min={1}
                                value="1"
                                //   onChange={this.handleChangeQuantity}
                              />
                            </span>
                          </span>
                        </li>
                        <li class="list-group-item list-group-item-action">
                          <span className="row">
                            <span className="col">Yusuph kaondo</span>
                            <span className="col">
                              <InputNumber
                                id="product-quantity"
                                aria-label="Quantity"
                                className="product__quantity"
                                size="sm"
                                min={1}
                                value="1"
                                //   onChange={this.handleChangeQuantity}
                              />
                            </span>
                          </span>
                        </li>
                        <li class="list-group-item list-group-item-action">
                          <span className="row">
                            <span className="col">Yusuph kaondo</span>
                            <span className="col">
                              <InputNumber
                                id="product-quantity"
                                aria-label="Quantity"
                                className="product__quantity"
                                size="sm"
                                min={1}
                                value="1"
                                //   onChange={this.handleChangeQuantity}
                              />
                            </span>
                          </span>
                        </li>
                        <li class="list-group-item list-group-item-action">
                          <span className="row">
                            <span className="col">Yusuph kaondo</span>
                            <span className="col">
                              <InputNumber
                                id="product-quantity"
                                aria-label="Quantity"
                                className="product__quantity"
                                size="sm"
                                min={1}
                                value="1"
                                //   onChange={this.handleChangeQuantity}
                              />
                            </span>
                          </span>
                        </li>
                        <li class="list-group-item list-group-item-action">
                          <span className="row">
                            <span className="col">Yusuph kaondo</span>
                            <span className="col">
                              <InputNumber
                                id="product-quantity"
                                aria-label="Quantity"
                                className="product__quantity"
                                size="sm"
                                min={1}
                                value="1"
                                //   onChange={this.handleChangeQuantity}
                              />
                            </span>
                          </span>
                        </li>
                        <li class="list-group-item list-group-item-action">
                          <span className="row">
                            <span className="col">Yusuph kaondo</span>
                            <span className="col">
                              <InputNumber
                                id="product-quantity"
                                aria-label="Quantity"
                                className="product__quantity"
                                size="sm"
                                min={1}
                                value="1"
                                //   onChange={this.handleChangeQuantity}
                              />
                            </span>
                          </span>
                        </li>
                        <li class="list-group-item list-group-item-action">
                          <span className="row">
                            <span className="col">Yusuph kaondo</span>
                            <span className="col">
                              <InputNumber
                                id="product-quantity"
                                aria-label="Quantity"
                                className="product__quantity"
                                size="sm"
                                min={1}
                                value="1"
                                //   onChange={this.handleChangeQuantity}
                              />
                            </span>
                          </span>
                        </li>
                      </ul>
                      <div className="mt-3 d-flex justify-content-center">
                      <Link className="btn btn-block btn-primary rounded-pill">
                        Assign To Members {" "}
                        <i className="fa fa-save" aria-hidden="true"></i>
                      </Link>
                      </div>
                      <br></br>
                    </Scrollbars>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td className="wishlist__column wishlist__column--remove">
            <AsyncAction
              action={() => wishlistRemoveItem(item.id)}
              render={renderRemoveButton}
            />
          </td>
        </tr>
      );
    });

    content = (
      <div className="block">
        <div className="container">
          <table className="wishlist">
            <thead className="wishlist__head">
              <tr className="wishlist__row">
                <th className="wishlist__column wishlist__column--image">
                  Image
                </th>
                <th className="wishlist__column wishlist__column--product">
                  Product
                </th>
                <th className="wishlist__column wishlist__column--stock">
                  Stock Status
                </th>
                <th className="wishlist__column wishlist__column--price">
                  Price
                </th>
                <th
                  className="wishlist__column wishlist__column--tocart"
                  aria-label="Add to cart"
                />
                <th
                  className="wishlist__column wishlist__column--remove"
                  aria-label="Remove"
                />
              </tr>
            </thead>
            <tbody className="wishlist__body">{itemsList}</tbody>
          </table>
        </div>
      </div>
    );
  } else {
    content = (
      <div className="block block-empty">
        <div className="container">
          <div className="block-empty__body">
            <div className="block-empty__message">
              Group wish list is empty!
            </div>
            <div className="block-empty__actions">
              <Link to="/" className="btn btn-primary btn-sm">
                Continue
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Wish List — ${theme.name}`}</title>
      </Helmet>

      <PageHeader header="Wishlist" breadcrumb={breadcrumb} />

      {content}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  wishlist: state.wishlist,
});

const mapDispatchToProps = {
  cartAddItem,
  wishlistRemoveItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageWishlist);
