import { BUY_PRODUCT_DIGITAL, DELETE_PRODUCT_DIGITAL } from "./digitalbuyActionTypes";
import Axios from "axios";
import { toast } from "react-toastify";
import { agenttoken, customertoken, agentcustomerToken } from "../../api/shop";
import { FetchRate } from "../RateData";
export function DigitalBuyNow(product, options = [], quantity = 1) {
  // console.log("mjkfgd product buy now",product);
  return {
    type: BUY_PRODUCT_DIGITAL,
    product,
    options,
    quantity,
  };
}
export function Delete() {
  return {
    type: DELETE_PRODUCT_DIGITAL,
  };
}
export function digitalBuyDataFromDb() {
  const agentcustomer_Token = agentcustomerToken();
  const agentToken = agenttoken();
  return (dispatch) =>
    new Promise((resolve) => {
      setTimeout(() => {
        if (agentcustomer_Token !== null && agentToken !== null) {
          Axios.post(window.$name + "/api/buynow/get-digital-buy-now", {
            user_token: agentcustomer_Token,
          })
            .then(function (response) {
              localStorage.setItem("digitalBuynowData", JSON.stringify(response.data));
              window.location='/digitalBuynow/comfirm'; 
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          Axios.post(window.$name + "/api/buynow/get-digital-buy-now", {
            user_token: customertoken(),
          })
            .then(function (response) {
                // console.log(response.data);
              localStorage.setItem("digitalBuynowData", JSON.stringify(response.data));
              window.location='/digitalBuynow/comfirm'; 
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    });
}
export function digitalAddBuynow(product, quantity) {  
  // console.log(product);
  const agentcustomer_Token = agentcustomerToken();
  const agentToken = agenttoken();
  return (dispatch) =>
    new Promise((resolve) => {
      setTimeout(() => {
        if (agentcustomer_Token !== null && agentToken !== null) {
          let data = {
            secure_token: agentcustomer_Token,
            product_token: product.product_token,
            outlet_token: product.outlets_token,
            quantity: quantity,
            seller_token: product.seller_token,
            total_amount: product.price,
            currency: product.currency,
            vat: product.vat,
          };
          Axios.post(window.$name + "/api/buynow/add-digital-buy-now", data)
            .then(function (response) {
              if (response.status === 201) {               
                dispatch(digitalBuyDataFromDb()); 
                dispatch(FetchRate(product.rate));                              
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          let data = {
            secure_token: customertoken(),
            product_token: product.product_token,
            outlet_token: product.outlets_token,
            quantity: quantity,
            seller_token: product.seller_token,
            total_amount: product.price,
            currency: product.currency,
            vat: product.vat,
          };
          Axios.post(window.$name + "/api/buynow/add-digital-buy-now", data)
            .then(function (response) {
              if (response.status === 201) {
                // toast.success(`Product ${product.name} buy now!`);
               dispatch(digitalBuyDataFromDb()); 
               dispatch(FetchRate(product.rate));
                
                // window.location='/shop/checkout-Buynow'; 
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    });

  }

