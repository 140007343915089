// react
import React, { Component } from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { url } from "../../services/utils";
import theme from "../../data/theme";
import CurrencyFormat from "react-currency-format";
import Axios from "axios";
import { toast } from "react-toastify";
import { Delete } from "../../store/buynow";
import { handleBacks, handleSubmits } from "./BuyNow";
import { customertoken, agenttoken,PaymentToken, agentcustomerToken, userCurrency } from "../../api/shop";
import { Row } from "reactstrap";
import PaymentOptioncomponent from "../shop/paymentOption";
// third-party
class ConfirmBuyNow extends Component {
  constructor(props) {
    super(props);
    this.state = {
        paymentOption_token:false
    };
}   
toggle(value) {   
    this.setState({    
        paymentOption_token: value,
    });
}
  cartNeedUpdate() {
    const { quantities } = this.state;
    const { cart } = this.props;

    return (
      quantities.filter((x) => {
        const item = cart.items.find((item) => item.id === x.itemId);
        return item && item.quantity !== x.value && x.value !== "";
      }).length > 0
    );
  }
  renderItems() {
    const { Rates} = this.props
    let buynow = JSON.parse(localStorage.getItem("BuynowData"));
    // console.log(buynow)
    let Amount_total = buynow[0].total_amount * buynow[0].quantity;
    return (
      <tr key={buynow[0].id} className="cart-table__row">
        <td className="cart-table__column cart-table__column--image">
          <div className="product-image">
            <Link
              className="product-image__body"
            >
              <img
                className="product-image__img"
                src={buynow[0].product_images.productImage_name}
                alt=""
              />
            </Link>
          </div>
        </td>
        <td className="cart-table__column cart-table__column--product">
          <Link className="cart-table__product-name">
            {buynow[0].product.product_name}
          </Link>
          {/* {options} */}
        </td>
        <td className="cart-table__column cart-table__column--product">
          {buynow[0].preference_color !== null && buynow[0].preference_size !== null ? (
            <>
              <Row>
                <Link className="cart-table__product-name">
                  Color :&nbsp;&nbsp; {buynow[0].preference_color}
                </Link>
              </Row>
              <Row>
                Size :&nbsp;&nbsp;   <Link >{buynow[0].preference_size}</Link>
              </Row>
            </>
          ) : buynow[0].preference_color !== null && buynow[0].preference_size === null ? (
            <Row>
              <Link className="cart-table__product-name">
                Color :&nbsp;&nbsp; {buynow[0].preference_color}
              </Link>
            </Row>
          ) : buynow[0].preference_color === null && buynow[0].preference_size !== null ? (
            <Row>
              <Link className="cart-table__product-name">
                Size :&nbsp;&nbsp; {buynow[0].preference_size}
              </Link>
            </Row>
          ) :
                <span>Empty</span>
          }


        </td>
        <td
          className="cart-table__column cart-table__column--price"
          data-title="Price"
        >
          {/*<Currency value={item.price}/>*/}
          {/* <CurrencyFormat
                    value={buynow[0].total_amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    renderText={(value) => (
                        <div>
                            {" "}

                            {buynow[0].currency} {value}
                        </div>
                    )}
                /> */}
          {userCurrency() === buynow[0].currency ? (
            <CurrencyFormat
              value={Math.round((Number(buynow[0].total_amount) / Number(Rates[buynow[0].currency])) * Number(Rates[userCurrency()]))
              }
              displayType={"text"}
              thousandSeparator={true}
              renderText={(value) => (
                <div>
                  {userCurrency()}{" "}{value}
                </div>
              )}
            />
          ) : (
              <CurrencyFormat
                value={((Number(buynow[0].total_amount) / Number(Rates[buynow[0].currency])) * Number(Rates[userCurrency()])).toFixed(2)
                }
                displayType={"text"}
                thousandSeparator={true}
                renderText={(value) => (
                  <div>
                    {userCurrency()}{" "}{value}
                  </div>
                )}
              />
            )
          }
        </td>
        <td
          className="cart-table__column cart-table__column--quantity"
          data-title="Quantity"
        >
          {buynow[0].quantity}
        </td>
        <td
          className="cart-table__column cart-table__column--total"
          data-title="Total"
        >
          {/* <CurrencyFormat
                    value={}
                    displayType={"text"}
                    thousandSeparator={true}
                    renderText={(value) => (
                        <div>
                            {" "}
                            {buynow[0].currency} {value}
                        </div>
                    )}
                /> */}
          {userCurrency() === buynow[0].currency ? (
            <CurrencyFormat
              value={Math.round((Number(Amount_total) / Number(Rates[buynow[0].currency])) * Number(Rates[userCurrency()]))
              }
              displayType={"text"}
              thousandSeparator={true}
              renderText={(value) => (
                <div>
                  {userCurrency()}{" "}{value}
                </div>
              )}
            />
          ) : (
              <CurrencyFormat
                value={((Number(Amount_total) / Number(Rates[buynow[0].currency])) * Number(Rates[userCurrency()])).toFixed(2)
                }
                displayType={"text"}
                thousandSeparator={true}
                renderText={(value) => (
                  <div>
                    {userCurrency()}{" "}{value}
                  </div>
                )}
              />
            )
          }
        </td>
        <td className="cart-table__column cart-table__column--remove" />
      </tr>
    );
  }

  renderCart() {

    const { activeStep, Delete, Rates, MyownShipping, Myownaddress, shipping } = this.props;
    const agentcustomer_Token = agentcustomerToken();
    const agentToken = agenttoken();
    const details = JSON.parse(localStorage.getItem("BuynowData"));
    // console.log(details);
    let amount = details[0].total_amount * details[0].quantity;
    function ConfirmOrder(shipping_token) {
      let deliveryOption = localStorage.getItem("deliveryOptions");
      let cartdetails = localStorage.getItem("BuynowData");      
      if (agentcustomer_Token !== null && agentToken !== null) {
        let data = {
          user_token: agentcustomer_Token,
          shippingAddress_token: shipping_token,
          payment_option: PaymentToken(),
          total_amount: amount,
          agent_token: agentToken,
          currency: userCurrency(),
          total_vat: details[0].vat * details[0].quantity,
          shipping_options: deliveryOption,
          cart_details: cartdetails,
        };

        Axios.post(window.$name + '/api/order/create-order-buy-now', data).then((response) => {
          toast.success(` "${response.data.message}"`);
          localStorage.setItem('success_data', JSON.stringify(response.data.payload))
          localStorage.removeItem('deliveryOptions');
          Delete();
          // localStorage.removeItem('CartData');
          localStorage.removeItem('paymentOption_token');
          if (localStorage.getItem("masterCard") === "Master Card") {
            window.location.href="/wait-for-nmb"
          } else {
            handleSubmits(activeStep);
          }
        }).catch(function (error) {
          if (error.response) {
            toast.error(`${error.response.data.message} `);
          } else if (error.request) {
            toast.error(`${error.request} `);
          } else {
            toast.error(`${error.message} `);
          }
        })
      }
      else {
        let data = {
          user_token: customertoken(),
          shippingAddress_token: shipping_token,
          payment_option:PaymentToken(),
          total_amount: amount,
          agent_token: "",
          currency: userCurrency(),
          total_vat: details[0].vat * details[0].quantity,
          shipping_options: deliveryOption,
          cart_details: cartdetails,
        };

        Axios.post(window.$name + '/api/order/create-order-buy-now', data).then((response) => {
          toast.success(` "${response.data.message}"`);
          localStorage.setItem('success_data', JSON.stringify(response.data.payload))
          localStorage.removeItem('deliveryOptions');
          Delete();
          // localStorage.removeItem('CartData');
          localStorage.removeItem('paymentOption_token');
          if (localStorage.getItem("masterCard") === "Master Card") {
            window.location.href="/wait-for-nmb"
          } else {
            handleSubmits(activeStep);
          }
        }).catch(function (error) {
          if (error.response) {
            toast.error(`${error.response.data.message} `);
          } else if (error.request) {
            toast.error(`${error.request} `);
          } else {
            toast.error(`${error.message} `);
          }
        })
      }
    }
   
    return (
      <div className="cart d-flex justify-content-center">
        <div className="card">
          <div className="card-title">
            <br />
            <h5 className="text-center">
              REVIEW,SELECT PAYMENT OPTION AND CONFIRM YOUR ORDER ITEMS
            </h5>
          </div>
          <div className="card-body -mt-6 ">           
            {
              MyownShipping.data === "I will arrange Delivery" ? (
                <div>
                  <h3> Pickup information</h3>
                  {Myownaddress.map((address) => (
                    <div>
                      {address.is_active ? (
                        <div>
                          <p>
                            Full Name :   &nbsp;&nbsp;{address.address_fullname}
                          </p>
                          <p>
                            Mobile :  &nbsp;&nbsp;{address.address_mobileNumber}
                          </p>                         
                          <p>Id Type :  &nbsp;&nbsp;{address.id_type}
                          </p>
                          <p>
                            Id Number :  &nbsp;&nbsp;{address.id_number}
                          </p>                         
                        </div>
                      ) : (
                          <div />
                        )}
                    </div>
                  ))}
                </div>
              ) : (
                  <div>
                    <p className="font-weight-bold">Shipping Address to:</p>
                    {shipping.map((address) => (
                      <div>
                        {address.is_active ? (
                          <div>
                            <p>
                              <span className="text-uppercase">
                                {address.address_fullname}
                              </span>
                            </p>
                            <p>
                              {address.address_mobileNumber}
                                                or
                                                {" "}
                              {address.address_mobileNumber2}
                            </p>
                            <p className="text-capitalize">
                              {address.country.country}, {address.region.region},{" "}
                              {address.address_district}
                            </p>
                            <p>Post Code : {address.address_postcode} </p>
                            <p>House Number : {address.address_house_number}</p>
                            <p>Area Name : {address.area}</p>
                            <p>Address Type : {address.address_AddressType}</p>
                          </div>
                        ) : (
                            <div />
                          )}
                      </div>
                    ))}
                  </div>

                )
            }

            <hr />
           
           <PaymentOptioncomponent onClick={this.toggle.bind(this)}/>
            <br />
            <table className="cart__table cart-table">
              <thead className="cart-table__head">
                <tr className="cart-table__row">
                  <th className="cart-table__column cart-table__column--image">
                    Product Image
                  </th>
                  <th className="cart-table__column cart-table__column--product">
                    Product name
                  </th>
                  <th className="cart-table__column cart-table__column--product">
                    Preference
                    </th>
                  <th className="cart-table__column cart-table__column--price">
                    Price
                  </th>
                  <th className="cart-table__column cart-table__column--quantity">
                    Quantity
                  </th>
                  <th className="cart-table__column cart-table__column--total">
                    Total
                  </th>
                  <th
                    className="cart-table__column cart-table__column--remove"
                    aria-label="Remove"
                  />
                </tr>
              </thead>
              <tbody className="cart-table__body">{this.renderItems()}</tbody>
            </table>
            <div className="cart__actions">
              <div class="container">
                <div className="row">
                  <div className="col-3">
                    <h6>
                      <label>{details.length} ITEMS</label>
                    </h6>
                  </div>
                  <div className="col-3">
                    <h6>
                      <label>TOTAL PRICE</label>
                    </h6>
                  </div>

                  <div className="col-3 ">
                    <h6>
                      {/* <CurrencyFormat
                        value={amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => (
                          <div>
                            {" "}
                            {details[0].currency} {"  "} {value}
                          </div>
                        )}
                      /> */}
                      {userCurrency() === details[0].currency ? (
                        <CurrencyFormat
                          value={Math.round((Number(amount) / Number(Rates[details[0].currency])) * Number(Rates[userCurrency()]))
                          }
                          displayType={"text"}
                          thousandSeparator={true}
                          renderText={(value) => (
                            <div>
                              {userCurrency()}{" "}{value}
                            </div>
                          )}
                        />
                      ) : (
                          <CurrencyFormat
                            value={((Number(amount) / Number(Rates[details[0].currency])) * Number(Rates[userCurrency()])).toFixed(2)
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            renderText={(value) => (
                              <div>
                                {userCurrency()}{" "}{value}
                              </div>
                            )}
                          />
                        )
                      }
                    </h6>
                  </div>
                  {/* <div className="col-3">
                    {shipping.map((address) => (
                      <div>
                        {address.is_active ? (
                          <button
                            onClick={() => ConfirmOrder(address.secure_token)}
                            type="submit"
                            disabled={
                              !this.state.paymentOption_token
                            }
                            className="btn-sm btn btn-primary rounded shadow-lg"
                          >
                            CONFIRM ORDER
                          </button>
                        ) : (
                            <div />
                          )}
                      </div>
                    ))}
                  </div> */}
                  <div className="col-3">
                    {
                      MyownShipping.data === "I will arrange Delivery" ? (
                        <>
                          {Myownaddress.map((address) => (
                            <div>
                              {address.is_active ? (
                                <button
                                  disabled={!this.state.paymentOption_token}
                                  onClick={() => ConfirmOrder(address.secure_token)}
                                  type="submit"
                                  className="btn-sm btn btn-primary"
                                >
                                  CONFIRM
                                </button>
                              ) : (
                                  <div />
                                )}
                            </div>
                          ))}
                        </>
                      ) : (
                          <>
                            {shipping.map((address) => (
                              <div>
                                {address.is_active ? (
                                  <button
                                    disabled={!this.state.paymentOption_token}
                                    onClick={() => ConfirmOrder(address.secure_token)}
                                    type="submit"
                                    className="btn-sm btn btn-primary"
                                  >
                                    CONFIRM
                                  </button>
                                ) : (
                                    <div />
                                  )}
                              </div>
                            ))}
                          </>
                        )
                    }

                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <button
              className="btn btn-secondary"
              onClick={() => handleBacks(activeStep)}
            >
              {" "}
              <i className="fa fa-backward text-black"> </i> Previous
             </button>
          </div>
        </div>
      </div>
    );
  }
  render() {
    let content;
    content = this.renderCart();
    return (
      <React.Fragment>
        <Helmet>
          <title>{`Confirm Order — ${theme.name}`}</title>
        </Helmet>
        {content}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  buy: state.buynow,
  Rates: state.RateList,
  Myownaddress: state.Myownaddresss,
  MyownShipping: state.MyownShipping,
  shipping: state.shippingAddress.user_address,
});

const mapDispatchToProps = {
  Delete,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmBuyNow);
