// react
import React from 'react';
// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Moment from "react-moment";
import { url } from '../../services/utils';
import {agentcustomerToken,agenttoken} from '../../api/shop';


function Suggestions(props) {
    const {
        context,
        className,
        customers,
    } = props;
    const rootClasses = classNames(`suggestions suggestions--location--${context}`, className);
    function handleCustomer(data) {
        localStorage.setItem("agentcustomer_token", data);
        if (agenttoken() !== null && agentcustomerToken() !== null) {
            window.location.href=("/");
        }
    }
    const list = (
        <div className="card-table">
            <div className="table-responsive-sm">
                <table>
                    <thead>
                        <tr className="text-center">
                            <th>full name</th>
                            <th>email</th>
                            <th>phone number</th>
                            <th>created</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customers && customers.slice(0, 5).map((index) => (
                            <tr className="text-center">
                                <td>{index.fullname}</td>
                                <td>{index.email}</td>
                                <td>{index.user_phoneNumber}</td>
                                <td>
                                    <Moment format="DD/MM/YYYY">{index.created_at}</Moment>
                                </td>
                                <td>
                                    <Link
                                        onClick={() => handleCustomer(index.secure_token)}
                                    >
                                        <i class="fa fa-shopping-cart" aria-hidden="true"></i>
                      &nbsp;&nbsp; Buy on behalf
                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>

    );
    return (
        <div className={rootClasses}>
            {
                customers && customers.length > 0 ? (
                    <>
                        <ul className="suggestions__list">
                            {list}
                        </ul>
                    </>
                ) : (
                        <div className="d-flex justify-content-center">
                            <img src="images/nodata-found.png" alt="" />

                        </div>
                    )
            }

        </div>
    );
}
const mapStateToProps = () => ({});
const mapDispatchToProps = {
};
export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Suggestions);
