// react
import React, { useState } from 'react';
// application
import Pagination from '../shared/Pagination';
// import { Pagination } from "../DataTable";
// import Rating from '../shared/Rating';
import Rating from "@material-ui/lab/Rating";
// data stubs
import reviews from '../../data/shopProductReviews';
import productReducer from '../../store/product';
import { Progress, Container, Col, Row } from 'reactstrap';
import Moment from "react-moment";
function ProductTabReviews(props) {
    const { Product, review_rates, dispatch } = props;
    const [Reviews, setReview] = React.useState("");
    const [currentPage, setCurrentPage] = useState(1);
    // console.log(Product.review)
    //   const handlePageChange =setCurrentPage(2);
    // const   handlePageChange = (pageNumber)=> {
    //     console.log(`active page is ${pageNumber}`);
    //     setCurrentPage(pageNumber);
    //   }
    const totalRating = Number(review_rates.oneRating) + Number(review_rates.twoRating) + Number(review_rates.threeRating) + Number(review_rates.fourRating) + Number(review_rates.fiveRating)
    //    rate percentage
    const oneRatingPercentage = (Number(review_rates.oneRating) / totalRating * 100);
    const twoRatingPercentage = (Number(review_rates.twoRating) / totalRating * 100);
    const threeRatingPercentage = (Number(review_rates.threeRating) / totalRating * 100);
    const fourRatingPercentage = (Number(review_rates.fourRating) / totalRating * 100);
    const fiveRatingPercentage = (Number(review_rates.fiveRating) / totalRating * 100);
    const reviewsList = Product.review.map((review, index) => {
        if (Reviews === "5" && review.rating === "5") {
            return (
                <li key={index} className="reviews-list__item">
                    <div className="review">
                        <div className="review__avatar"><img src="images/avatars/profile.webp" alt="" /></div>
                        <div className=" review__content">
                            <div className=" review__author">{review.user_name.username}</div>
                            <div className=" review__rating">
                                <Row>{review.rating} Star &nbsp; <Rating value={review.rating} /></Row>
                            </div>
                            <div className=" review__text">{review.description}</div>
                            <div className=" review__date">
                                <Moment format="DD/MM/YYYY">
                                    {review.created_at}
                                </Moment>
                            </div>
                        </div>
                    </div>
                </li>
            )
        }
        else if (Reviews === "4" && review.rating === "4") {
            return (
                <li key={index} className="reviews-list__item">
                    <div className="review">
                        <div className="review__avatar"><img src="images/avatars/profile.webp" alt="" /></div>
                        <div className=" review__content">
                            <div className=" review__author">{review.user_name.username}</div>
                            <div className=" review__rating">
                                <Row>{review.rating} Star &nbsp; <Rating value={review.rating} /></Row>
                            </div>
                            <div className=" review__text">{review.description}</div>
                            <div className=" review__date">
                                <Moment format="DD/MM/YYYY">
                                    {review.created_at}
                                </Moment>
                            </div>
                        </div>
                    </div>
                </li>
            )
        }
        else if (Reviews === "3" && review.rating === "3") {
            return (
                <li key={index} className="reviews-list__item">
                    <div className="review">
                        <div className="review__avatar"><img src="images/avatars/profile.webp" alt="" /></div>
                        <div className=" review__content">
                            <div className=" review__author">{review.user_name.username}</div>
                            <div className=" review__rating">
                                <Row>{review.rating} Star &nbsp; <Rating value={review.rating} /></Row>
                            </div>
                            <div className=" review__text">{review.description}</div>
                            <div className=" review__date">
                                <Moment format="DD/MM/YYYY">
                                    {review.created_at}
                                </Moment>
                            </div>
                        </div>
                    </div>
                </li>
            )
        }
        else if (Reviews === "2" && review.rating === "2") {
            return (
                <li key={index} className="reviews-list__item">
                    <div className="review">
                        <div className="review__avatar"><img src="images/avatars/profile.webp" alt="" /></div>
                        <div className=" review__content">
                            <div className=" review__author">{review.user_name.username}</div>
                            <div className=" review__rating">
                                <Row>{review.rating} Star &nbsp; <Rating value={review.rating} /></Row>
                            </div>
                            <div className=" review__text">{review.description}</div>
                            <div className=" review__date">
                                <Moment format="DD/MM/YYYY">
                                    {review.created_at}
                                </Moment>
                            </div>
                        </div>
                    </div>
                </li>
            )
        }
        else if (Reviews === "1" && review.rating === "1") {
            return (
                <li key={index} className="reviews-list__item">
                    <div className="review">
                        <div className="review__avatar"><img src="images/avatars/profile.webp" alt="" /></div>
                        <div className=" review__content">
                            <div className=" review__author">{review.user_name.username}</div>
                            <div className=" review__rating">
                                <Row>{review.rating} Star &nbsp; <Rating value={review.rating} /></Row>
                            </div>
                            <div className=" review__text">{review.description}</div>
                            <div className=" review__date">
                                <Moment format="DD/MM/YYYY">
                                    {review.created_at}
                                </Moment>
                            </div>
                        </div>
                    </div>
                </li>
            )
        }
        else if (Reviews === "") {
            return (
                <li key={index} className="reviews-list__item">
                    <div className="review">
                        <div className="review__avatar"><img src="images/avatars/profile.webp" alt="" /></div>
                        <div className=" review__content">
                            <div className=" review__author">{review.user_name.username}</div>
                            <div className=" review__rating">
                                <Row>{review.rating} Star &nbsp; <Rating value={review.rating} /></Row>
                            </div>
                            <div className=" review__text">{review.description}</div>
                            <div className=" review__date">
                                <Moment format="DD/MM/YYYY">
                                    {review.created_at}
                                </Moment>
                            </div>
                        </div>
                    </div>
                </li>
            )
        }
    });

    const five_rate = (
        <Container>
            <Row>
                <Col xs="1.5">
                    5 Star
                    </Col>
                <Col>
                    <Progress color="warning" value={fiveRatingPercentage}
                        onClick={() => {
                            setReview("5")
                        }}
                    />
                </Col>
                <Col xs="3">
                    {fiveRatingPercentage} %
                </Col>

            </Row>
        </Container>
    );
    const four_rate = (
        <Container>
            <Row>
                <Col xs="1.5"> 4 Star</Col>
                <Col>
                    <Progress color="warning" value={fourRatingPercentage}
                        onClick={() => {
                            setReview("4")
                        }}
                    />
                </Col>
                <Col xs="3">
                    {fourRatingPercentage} %
                </Col>
            </Row>
        </Container>
    );
    const three_rate = (
        <Container>
            <Row>
                <Col xs="1.5"> 3 Star</Col>
                <Col>
                    <Progress color="warning" value={threeRatingPercentage}
                        onClick={() => {
                            setReview("3")
                        }}
                    />
                </Col>
                <Col xs="3">
                    {threeRatingPercentage} %
                </Col>
            </Row>
        </Container>
    );
    const two_rate = (
        <Container>
            <Row>
                <Col xs="1.5"> 2 Star</Col>
                <Col>
                    <Progress color="warning" value={twoRatingPercentage}
                        onClick={() => {
                            setReview("2")
                        }}
                    />
                </Col>
                <Col xs="3">
                    {twoRatingPercentage} %
                </Col>
            </Row>
        </Container>
    );
    const one_rate = (
        <Container>
            <Row>
                <Col xs="1.5"> 1 Star</Col>
                <Col>
                    <Progress color="warning" value={oneRatingPercentage}
                        onClick={() => {
                            setReview("1")
                        }}
                    />
                </Col>
                <Col xs="3">
                    {oneRatingPercentage} %
                </Col>
            </Row>
        </Container>
    );
    return (
        <div className="reviews-view">

            <h3 className="reviews-view__header">Customer Reviews({Product.review.length})</h3>
            {/* not mobile view only  */}
            {Product.review.length > 0 ? (
                <>
                    <Row>
                        <Col xs="9" className="d-none d-md-block d-xl-block d-lg-block">
                            <div>{five_rate}</div>
                            <div className="mt-2">{four_rate}</div>
                            <div className="mt-2">{three_rate}</div>
                            <div className="mt-2">{two_rate}</div>
                            <div className="mt-2">{one_rate}</div>
                        </Col>
                        <Col xs="3" className="d-none d-md-block d-xl-block d-lg-block my-5">
                            <Row className="mt-3">
                                <span >{Number(review_rates.average)}</span> /<span className="text-muted">5</span>
                        &nbsp; &nbsp;<Rating value={review_rates.average} precision={0.5} />
                            </Row>
                        </Col>
                    </Row>
                    {/* for mobile view only  */}
                    <div className="d-sm-none .d-md-block">
                        <Row>{five_rate}</Row>
                        <Row className="mt-2">{four_rate}</Row>
                        <Row className="mt-2">{three_rate}</Row>
                        <Row className="mt-2">{two_rate}</Row>
                        <Row className="mt-2">{one_rate}</Row>
                        <Row>
                            <span className="font-weight-bold">{Number(review_rates.average)}</span> /<span className="text-muted">5</span>
                    &nbsp;
                    <Rating name="simple-controlled" value={review_rates.average} precision={0.5} />
                            {/* <Rating value={review_rates.average} /> */}
                        </Row>
                    </div>
                    <div className="reviews-view__list mt-4">
                        <div className="reviews-list">
                            <hr />
                            <ol className="reviews-list__content">
                                {reviewsList}
                            </ol>
                            <div className="reviews-list__pagination">
                                <Pagination
                                    current={currentPage || Product.review.length}
                                    siblings={1}
                                    total={Product.review.length}
                                    onPageChange={(page) => setCurrentPage(page)}
                                // onChange={handlePageChange(Product.review.length)}
                                />
                                {/* <Pagination current={1} siblings={5} total={Product.review.length} /> */}
                            </div>
                        </div>
                    </div>

                </>
            ) :
                (
                    <div className="d-flex justify-content-center">
                        <img src="images/nodata-found.png" alt="" />
                    </div>
                )
            }

        </div>
    );
}

export default ProductTabReviews;