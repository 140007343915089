// react
import React, { Component } from "react";
// third-party
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
// application
import AsyncAction from "./AsyncAction";
import InputNumber from "./InputNumber";
import ProductGallery from "./ProductGallery";
import Rating from "./Rating";
import { cartAddItem } from "../../store/cart";
import { compareAddItem } from "../../store/compare";
import { Compare16Svg, Wishlist16Svg, DollarSvg } from "../../svg";
import { wishlistAddItem } from "../../store/wishlist";
import CurrencyFormat from "react-currency-format";
import Moment from "react-moment";
import axios from "axios";
import { ProductDetails } from "../../store/buynow";
import { QuoteAddItem } from "../../store/quote";
import {digitalAddBuynow} from '../../store/digitalbuynow'
import Shop from "./OthershopModal";
import Login from"../account/activateLogin";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import ShowMoreText from "react-show-more-text";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import subDays from "date-fns/subDays";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import {SetLink} from "../../store/Links";
import {customertoken, agentcustomerToken, agenttoken} from "../../api/shop";
class Product extends Component {
  constructor(props) {
    super(props);
    const { product ,links,rate ,review_rates, Referral} = props;
    // console.log(" console data",Referral);
    this.buyingHandle = this.buyingHandle.bind(this);
    this.priceHandle = this.priceHandle.bind(this);
    this.buyingHandleother = this.buyingHandleother.bind(this);
    this.priceHandleother = this.priceHandleother.bind(this);
    this.startDateChange.bind(this);
    this.endDateChange.bind(this);
    this.handleDiscription = this.handleDiscription.bind(this);
    this.state = {
      quantitywholesale: product.product_shop.minimum_quantity,
      quantity: product.product_shop.retail_minimum_quantity,
      quantityother: 1,
      unit: 1,
      buying_option: "retail",
      price_option: "retail",
      buying_optionother: "retailother",
      price_optionother: "retailother",
      preferenseColor:"",
      preferenseSize:"",
      product: product,
      productdata: [],
      // ratedata:[],
      preferenceValidation: false,
      preferenceValidationsize: false,
      quotequantity:product.product_shop.minimum_quantity,
      Quotedescription: "",
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  handleDiscription = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  startDateChange = (date) => {
    this.setState({
      startDate: date,
    });
  };
  endDateChange = (date) => {
    this.setState({
      endDate: date,
    });
  };
  togglePreference(id) {
    this.setState({
      preferenceValidation: true,
    });
  }
  togglePreferencesize(id) {
    this.setState({
      preferenceValidationsize: true,
    });
  }
  handleChangeQuantity = (quantity) => {
    this.setState({ quantity });
  };
  handleChangequotequantinty = (quotequantity) => {
    this.setState({ quotequantity });
  };
  handleChangeunit = (unit) => {
    this.setState({ unit });
  };

  handleChangeWholesaleQuantity = (quantitywholesale) => {
    this.setState({ quantitywholesale });
  };
  handleChangeQuantityother = (quantityother) => {
    this.setState({ quantityother });
  };
  componentDidMount() {
    axios
      .post(window.$name + "/api/consumer/other-shops", {
        product_token: this.state.product.product_token,
      })
      .then((response) => {
        // console.log(response)
        if(response){
          this.setState({ productdata: response.data.products});
          // this.setState({ ratedata: response.data.rates});
        }

      });
  }

  handleChangeColor(data) {
    this.setState({
      preferenseColor:data.preference_choice
       });
  }
  handleChangeSize(data) {
    this.setState({
   preferenseSize:data.preference_choice
    });
  }
  buyingHandle(e) {
    this.setState({
      buying_option: e.target.value,
    });
  }
  priceHandle(e) {
    this.setState({
      price_option: e.target.value,
    });
  }
  buyingHandleother(e) {
    this.setState({
      buying_optionother: e.target.value,
    });
  }
  priceHandleother(e) {
    this.setState({
      price_optionother: e.target.value,
    });
  }
  render() {
    const {
      product,
      layout,
      links,
      wishlistAddItem,
      compareAddItem,
      cartAddItem,
      QuoteAddItem,
      ProductDetails,
      review_rates,
      rate,
      digitalAddBuynow,
        Referral
    } = this.props;
    // console.log(product);
    const { quantity } = this.state;
    const { unit } = this.state;
    const { quantitywholesale } = this.state;
    const { quantityother } = this.state;
    const { buying_option } = this.state;
    const { price_option } = this.state;
    const { buying_optionother } = this.state;
    const { price_optionother } = this.state;
    const { productdata } = this.state;
    // const {ratedata}=this.state;
    const { quotequantity } = this.state;
    const { Quotedescription } = this.state;
    const { startDate } = this.state;
    const { endDate } = this.state;
    const { preferenseColor}=this.state;
    const {preferenseSize}=this.state;
    const outletToken=productInfo().url_name;
    const currency = JSON.parse(rate.rate);
    // const currency2= JSON.parse(ratedata.rate);
    // console.log(currency2);
    const userCurrency = localStorage.getItem("userCurrency");
    localStorage.setItem("links",links);
    // console.log(quantity);

    // handlereferral link
    function HandleReferral(){
      let agentReferralToken
      if(agenttoken()){
        if(Referral==="ezytrade"){
          agentReferralToken=""
          return agentReferralToken
        }
        else{
          agentReferralToken=Referral
          return agentReferralToken
        }
      }
      else{
        if(Referral==="ezytrade"){
          agentReferralToken=""
          return agentReferralToken
        }
        else{
          agentReferralToken=Referral
          return agentReferralToken
        }
      }

    }

    // end here
// console.log("handle referral link",HandleReferral());
    //function for handle minmum quantity
    function handleQuantity(){
      let value;
      if (buying_option === "retail" ){
        value=product.product_shop.retail_minimum_quantity;
        return value        
      }
      else{
         value= product.product_shop.minimum_quantity
         return value   
      }
  
    }
    // console.log(product);
    function productInfo() {
      return {
        product_name:product.product_shop.product,
        acqustion:product.product_shop.product,
        agent_commmission:product.product_shop.product,
        url_name:product.product_shop.url_name,
        isDigital:product.product_shop.product.is_digital,
        images:product.product_shop.product,
        monthlyProductionCapacity:product.product_shop.product,
        discription:product.product_shop.product,
        discount:product.product_shop.product,
        customUoM:product.product_shop.product,
        unitMeasure:product.product_shop.product,
        product_preference:product.product_shop.product,
        outletName: product.product_shop.shop,
        district:product.product_shop.shop,
        seller_token:product.product_shop.shop,
        region:product.product_shop.shop,
        productNumber:product.product_shop.product,
        brand:product.product_shop.product,
          
      };
    }
    const description = ReactHtmlParser(productInfo().discription.product_description);
    // console.log(productInfo().images.product_images[0].productImage_name)
    // console.log(currency);
    let Price_data;
    let discounted;
    let preferences;
    let quotemodal;
    let quoteForm;
    let Loginmodal;
    Loginmodal=(
        <>
          <div className="product__actions-item product__actions-item--addtocart">
            <button
                data-toggle="modal"
                data-target="#Login"
                type="button"
                title="Add to quote"
                className={classnames("btn   btn-success btn-md")}
            >
            Buy Now
            </button>
          </div>
          <div
              className="modal fade"
              id="Login"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLongTitle"
              aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content container">
                <div className="modal-header">
                  <h5
                      className="modal-title"
                      id="exampleModalLongTitle"
                  >
                  Please login into your Account
                  </h5>
                  <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              <Login />
              </div>
            </div>
          </div>
        </>
    ) 
    // data arrange for wishlish,cart and  buy now action
    function unitQuantity() {
      if (productInfo().customUoM.customUoM) {
        let quantitymeasure;
        quantitymeasure = unit;
        return quantitymeasure;
      } else {
        let quantitymeasure;
        quantitymeasure = 1;
        return quantitymeasure;
      }
    }
    // console.log(productInfo().product_preference.product_preference)
    function otherDetails(){
      return{
        region:productInfo().region.region,
        preference:productInfo().product_preference.product_preference,
      }
    }
    function product_details() {
      return {
        id: product.id,
        shop_id: product.shop_id,
        rate:currency,
        seller_token:productInfo().seller_token.seller_info.secure_token,
        vat: parseInt(total_vat()),
        name: productInfo().product_name.product_name,
        slug: productInfo().product_name.product_name,
        retail_acqustion:productInfo().acqustion.acquistion_cost,
        retail_acquistion_currency:productInfo().acquistion_currency,
        wholesale_acquistion:productInfo().acqustion.wholesale_acquistion_cost,
        wholesale_acquistion_currency: productInfo().wholesale_acquistion_currency,
        agent_commission:productInfo().agent_commmission.agent_commmission,
        preference_color: preferenseColor,
        preference_size: preferenseSize,
        price: amount(),
        wholesale_price: parseInt(product.wholesaleprice),
        currency: userCurrency,
        currency_wholesale:userCurrency,
        discount: productInfo().discount.discount,
        unitMeasure:productInfo().unitMeasure.unitMeasure,
        unit: unitQuantity(),
        compareAtPrice: null,
        status:productInfo().product_name.is_featured,
        images: productInfo().images.product_images[0].productImage_name,
        secure_token: product.secure_token,
        product_token: product.product_token,
        outlets_token: product.outlet_token,
        subcategory: productInfo().product_name.subcategory,
        badges: [],
        rating: 5,
        reviews: 3,
        brand: productInfo().brand.brand,
        categories: [],
        attributes: [],
        customFields: [],
        preference:productInfo().product_preference.product_preference,
      };
    }

    function quote_details() {
      return {
        id: product.id,
        shop_id: product.shop_id,
        seller_token:productInfo().seller_token.seller_info.secure_token,
        name:productInfo().product_name.product_name,
        slug: productInfo().product_name.product_name,
        images: productInfo().images.product_images[0].productImage_name,
        preference_color: preferenseColor,
        preference_size: preferenseSize,
        product_token: product.product_token,
        outlets_token: product.outlet_token,
        quotes_description: Quotedescription,
        start_at: startDate,
        end_at: endDate,
      };
    }
    //product Preferences
  // console.log( product_details().agent_commission)
 //unit measure
 function unitMeasure(){
  if (buying_option === "wholesale") {
    let Measure;
      Measure= productInfo().unitMeasure.unitMeasure
return Measure 
 } 
 else{
   let Measure
   Measure= productInfo().unitMeasure.retails_uom
   return Measure
 }
}  
    if (product_details().preference.length >= 1) {
      let color = (
        <>
          <div className="product__option-label">Color</div>
          <div className="row container ">
            {product_details().preference.map((preferences) => (
              <div>
                {preferences.preference_type ==="Color" ? (
                    <div className="product-color">                     
                        <div className="product-color-item border rounded">
                          <img
                              src={preferences.preferences_image.preference_image}
                              alt=""
                              className="avatar-md"
                          />
                        </div>
                        <p>
                          <input
                              type="radio"
                              name="color"
                              onChange={() => this.handleChangeColor(preferences)}
                              value="Radio"
                              onClick={() => this.togglePreference(preferences.preference_type)}
                          />
                          <small className="text-lowercase font-weight-bold">
                            {" "}
                            {preferences.preference_choice}
                          </small>
                        </p>
                      {/*</Link>*/}
                    </div>

                    // <div className="row">
                  //   <div className="order-list__column-image">
                  //     <div className="product-image">
                  //       <div className="container product-image__body ">
                  //         <img
                  //           className=" border border-warning product-image__img img-thumbnail product-gallery__carousel-image "
                  //           alt="ghf"
                  //           src={preferences.preferences_image.preference_image}
                  //         />
                  //       </div>
                  //     </div>
                  //   </div>
                  //   <div className="container">
                  //     <input
                  //       type="radio"
                  //       name="color"
                  //       onChange={() => handleChange(preferences)}
                  //       value="Radiobox"
                  //       onClick={() => this.togglePreference(preferences.id)}
                  //     />
                  //     <small className="text-lowercase">
                  //       {" "}
                  //       {preferences.preference_choice}
                  //     </small>
                  //   </div>
                  // </div>
                  //
                ) : (
                    <div className="product__option-label"></div>

                )}
              </div>
            ))}
          </div>
        </>
      );

      let size = (
        <div>
          <div className="product__option-label">Size</div>
          <div className="row container">
            {product_details().preference.map((preferences) => (
              <div>
                {preferences.preference_type ==="Size"? (
                  <div>
                    <div className="form-group product__option">
                      <div className="input-radio-label">
                        <div className="input-radio-label__list">
                          <label>
                            <input
                              type="radio"
                              name="material"
                              onChange={() => this.handleChangeSize(preferences)}
                              value="Radiobox"
                              onClick={() =>
                                this.togglePreferencesize(preferences.id)
                              }
                            />
                            {/*<small className="text-capitalize"> {preferences.preference_choice}</small>*/}
                            <span className="ext-capitalize">
                              {preferences.preference_choice}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div />
                )}
              </div>
            ))}
          </div>
        </div>
      );
      preferences = (
        <div>
          <div>{color}</div>
          <div>{size}</div>
          {!this.state.preferenceValidation &&
          !this.state.preferenceValidationsize ? (
            <div className="text-center text-danger d-flex justify-content-center">
              Preferences is required
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    } else {
      preferences = <div />;
    }

    quoteForm=(
      <Form className="mb-4">
      <div className="row product__name container">
        Price range &nbsp;&nbsp;
        <CurrencyFormat
          value={product.price_start}
          displayType={"text"}
          thousandSeparator={true}
          renderText={(value) => (
            <div>
              {product.price_start_currency} {value}
            </div>
          )}
        />
        &nbsp;&nbsp; <h5>-</h5> &nbsp;&nbsp;
        <CurrencyFormat
          value={product.price_end}
          displayType={"text"}
          thousandSeparator={true}
          renderText={(value) => (
            <div>
              {product.price_end_currency} {value}
            </div>
          )}
        />
      </div>
      <FormGroup className="mb-4" row>
        <Label
          htmlFor="projectname"
          className="col-form-label col-lg-2"
        >
          Quantity
        </Label>
        <Col lg="10">
          <InputNumber
            id="product-quantity"
            aria-label="Quantity"
            className="product__quantity"
            size="sm"
            min={1}
            value={quotequantity}
            onChange={this.handleChangequotequantinty}
          />
        </Col>
      </FormGroup>
      {product_details().preference.length >= 1?(
        <div>
        <span>Choose Preference</span>
        {preferences}
      </div>
      ):null
      }      
      <div class="form-row">
        Date required
        &nbsp;
       <lable> between</lable>
       &nbsp;&nbsp;
        <div class="col">
          <DatePicker
            className="form-control"
            selected={this.state.startDate}
            onChange={this.startDateChange}
            minDate={subDays(new Date(), 0)}
          />
        </div>
        <lable>and</lable>
        &nbsp;
        <div class="col">
          <DatePicker
            className="form-control"
            selected={this.state.endDate}
            onChange={this.endDateChange}
            minDate={subDays(new Date(), 0)}
          />
        </div>
      </div>

      <FormGroup className="mt-4" row>
        <Label
          htmlFor="projectdesc"
          className="col-form-label col-lg-2"
        >
          Description
        </Label>
        <Col lg="10">
          <textarea
            className="form-control"
            id="Quotedescription"
            rows="3"
            name="Quotedescription"
            value={this.state.Quotedescription}
            onChange={this.handleDiscription}
            placeholder="Enter Description"
          />
        </Col>
      </FormGroup>
      <FormGroup>
        <div className="float-right">
          <AsyncAction
            action={() =>
              QuoteAddItem(quote_details(), [], quotequantity)
            }
            render={({ run, loading }) => (
              <button
                type="button"
                data-toggle="tooltip"
                title="Request A Quote"
                onClick={run}
                className={classnames("btn btn-primary btn-md", {
                  "btn-loading": loading,
                })}
              >
                <span className="row">
                  <DollarSvg /> &nbsp;Add to quote
                </span>
              </button>
            )}
          />
        </div>
      </FormGroup>
    </Form>
   
   );
    {
      /* model for quotationstart here*/
    }
    quotemodal = (
      <>
        <div className="product__actions-item product__actions-item--compare">
          <button
            className="btn btn-primary rounded-pill"
            data-toggle="modal"
            data-target="#quoteProduct"
            type="button"
            title="Add to quote"
            className={classnames("btn btn-secondary btn-md")}
          >
            <span className="row">
              <DollarSvg />
            </span>
          </button>
        </div>
        <div
          className="modal fade"
          id="quoteProduct"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLongTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content container">
              <div className="modal-header">
                <h5
                  className="modal-title"
                  id="exampleModalLongTitle"
                >
                 Tell us your requirements
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <Form className="mb-4">
      <div className="row product__name container">
        Price range &nbsp;&nbsp;
        <CurrencyFormat
          value={product.price_start}
          displayType={"text"}
          thousandSeparator={true}
          renderText={(value) => (
            <div>
              {product.price_start_currency} {value}
            </div>
          )}
        />
        &nbsp;&nbsp; <h5>-</h5> &nbsp;&nbsp;
        <CurrencyFormat
          value={product.price_end}
          displayType={"text"}
          thousandSeparator={true}
          renderText={(value) => (
            <div>
              {product.price_end_currency} {value}
            </div>
          )}
        />
      </div>
      <FormGroup className="mb-4" row>
        <Label
          htmlFor="projectname"
          className="col-form-label col-lg-2"
        >
          Quantity
        </Label>
        <Col lg="10">
          <InputNumber
            id="product-quantity"
            aria-label="Quantity"
            className="product__quantity"
            size="sm"
            min={1}
            value={quotequantity}
            onChange={this.handleChangequotequantinty}
          />
        </Col>
      </FormGroup>
      {product_details().preference.length >= 1?(
        <div>
        <span>Choose Preference</span>
        {preferences}
      </div>
      ):null
      }      
      <div class="form-row">
        Date required
        &nbsp;
       <lable> between</lable>
       &nbsp;&nbsp;
        <div className="col">
          <DatePicker
            className="form-control"
            selected={this.state.startDate}
            onChange={this.startDateChange}
            minDate={subDays(new Date(), 0)}
          />
        </div>
        <lable>and</lable>
        &nbsp;
        <div className="col">
          <DatePicker
            className="form-control"
            selected={this.state.endDate}
            onChange={this.endDateChange}
            minDate={subDays(new Date(), 0)}
          />
        </div>
      </div>

      <FormGroup className="mt-4" row>
        <Label
          htmlFor="projectdesc"
          className="col-form-label col-lg-2"
        >
          Description
        </Label>
        <Col lg="10">
          <textarea
            className="form-control"
            id="Quotedescription"
            rows="3"
            name="Quotedescription"
            value={this.state.Quotedescription}
            onChange={this.handleDiscription}
            placeholder="Enter Description"
          />
        </Col>
      </FormGroup>
      <FormGroup>
        <div className="float-right">
          <AsyncAction
            action={() =>
              QuoteAddItem(quote_details(), [], quotequantity)
            }
            render={({ run, loading }) => (
              <button
                type="button"
                data-toggle="tooltip"
                title="Request A Quote"
                onClick={run}
                className={classnames("btn btn-primary btn-md", {
                  "btn-loading": loading,
                })}
              >
                <span className="row"
                data-dismiss="modal"
                >
                  <DollarSvg /> &nbsp;Add to quote
                </span>
              </button>
            )}
          />
        </div>
      </FormGroup>
    </Form>
   
            </div>
          </div>
        </div>
      </>
    );
    {
      /*model for quotation retails*/
    }
    let tobedisplayed;
    // calculate commission
    if (agenttoken() && product.agent_commission !== null && product_details().agent_commission !==null)
    {

    if(buying_option === "retail"){
      const agentamount = Math.round(
          (((Number(product.retailprice)-Number(product_details().retail_acqustion)) * Number(product_details().agent_commission.agent_percentage)) /
              100)
      );
      const USD = agentamount
          / Number(currency[product.retail_currency]);

      if (userCurrency === product.retail_currency) {
        tobedisplayed = (
            <div
                data-toggle="tooltip"
                data-placement="right"
                title="Agent Commission Amount"
            >
              <CurrencyFormat
                  value={Math.round(Number(USD) * Number(currency[userCurrency]))}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value) => (
                      <div>
                        {userCurrency} {value}
                      </div>
                  )}
              />
            </div>
        );
      }
      else {
        tobedisplayed = (
              <CurrencyFormat
                  value={(Number(USD) * Number(currency[userCurrency])).toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value) => (
                      <div>
                        {userCurrency} {value}
                      </div>
                  )}
              />

        )
      }
    }
    else{
      const agentamount = Math.round(
          (((Number(product.wholesaleprice)-Number(product_details().wholesale_acquistion)) * Number(product_details().agent_commission.agent_percentage))
              /
              100)
      );
      const USD = agentamount
          / Number(currency[product.wholesale_currency]);

      if (userCurrency === product.wholesale_currency) {
        tobedisplayed = (
            <div
                data-toggle="tooltip"
                data-placement="right"
                title="Agent Commission Amount"
            >
              <CurrencyFormat
                  value={Math.round(Number(USD) * Number(currency[userCurrency]))}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value) => (
                      <div>
                        {userCurrency} {value}
                      </div>
                  )}
              />
            </div>
        );
      }
      else {
        tobedisplayed = (
              <CurrencyFormat
                  value={(Number(USD) * Number(currency[userCurrency])).toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  renderText={(value) => (
                      <div>
                        {userCurrency} {value}
                      </div>
                  )}
              />
        )
      }
    }


    } else if (agenttoken()) {
      tobedisplayed = (
            <CurrencyFormat
                value={0}
                displayType={"text"}
                thousandSeparator={true}
                renderText={(value) => (
                    <div>
                      {userCurrency} {value}
                      {/*{product.currency} {value}*/}
                    </div>
                )}
            />
      );
    }

    // end here

    // total vat

    function total_vat() {
      if (buying_option === "wholesale") {
        let USD;
        let sum;
        USD =Number(product.total_wholesale_vat)
            / Number(currency[product.wholesale_currency]);
        if(userCurrency === product.wholesale_currency) {
          sum = Math.round(Number(USD) * Number(currency[userCurrency]));
          // sum = Number(product.total_wholesale_vat);
          return sum;
        }
        else{
          sum = (Number(USD) * Number(currency[userCurrency])).toFixed(2);
          // sum = Number(product.total_wholesale_vat);
          return sum;
        }
      } else {
        let sum;
        let USD;
         USD =Number(product.total_retail_vat)
            / Number(currency[product.retail_currency]);
        if(userCurrency === product.retail_currency) {
          sum = Math.round(Number(USD) * Number(currency[userCurrency]));
          // sum = Number(product.total_retail_vat);
          return sum;
        }else{
          sum = (Number(USD) * Number(currency[userCurrency])).toFixed(2);
          // sum = Number(product.total_retail_vat);
          return sum;
        }
      }
    }
    //total amount
    function total_amount() {
      if (buying_option === "wholesale") {
        if (productInfo().customUoM.customUoM) {
          let sum;
          let USD;
          USD =Math.round(
              Number(product.wholesaleprice) +
              Number(product.total_wholesale_vat)
              )
              / Number(currency[product.wholesale_currency]);
          if(userCurrency === product.wholesale_currency) {
            sum =
                unit *
                quantitywholesale *
                Math.round(Number(USD) * Number(currency[userCurrency]))
            // Math.round(
            //   Number(product.wholesaleprice) +
            //     Number(product.total_wholesale_vat)
            // );
            return sum;
          }
          else{
            sum =
                unit *
                quantitywholesale *
                (Number(USD) * Number(currency[userCurrency])).toFixed(2)
            // Math.round(
            //   Number(product.wholesaleprice) +
            //     Number(product.total_wholesale_vat)
            // );
            return sum;
          }

        } else {
          let sum;
          let USD;
          USD =Math.round(
              Number(product.wholesaleprice) +
              Number(product.total_wholesale_vat)
              )
              / Number(currency[product.wholesale_currency]);
          if(userCurrency === product.wholesale_currency) {
            sum =
                quantitywholesale *
                Math.round(Number(USD) * Number(currency[userCurrency]))
            return sum;
          }
          else{
            sum =
                quantitywholesale *
                (Number(USD) * Number(currency[userCurrency])).toFixed(2)
            return sum
          }
        }
      } else {
        if (productInfo().customUoM.customUoM) {
          if (productInfo().discount.discount) {
            let sum;
            let USD;
            USD =((Number(product.retailprice) + Number(product.total_retail_vat)) -
                Number(productInfo().discount.discount.discount))
                / Number(currency[product.retail_currency]);
            if(userCurrency === product.retail_currency) {
              sum =
                  unit *
                  quantity *
                  Math.round(Number(USD) * Number(currency[userCurrency]))
              return sum;
            }
            else{
              sum =
                  unit *
                  quantity *
                  (Number(USD) * Number(currency[userCurrency])).toFixed(2)
              return sum;
            }
          } else {
            let sum;
            let USD;
            USD =(Number(product.retailprice) + Number(product.total_retail_vat))
                / Number(currency[product.retail_currency]);
            if(userCurrency === product.retail_currency) {
              sum =
                  unit *
                  quantity *
                  Math.round(Number(USD) * Number(currency[userCurrency]))
              return sum;
            }
            else{
              sum =
                  unit *
                  quantity *
                  (Number(USD) * Number(currency[userCurrency])).toFixed(2)
              return sum;
            }
          }
        }
        if (productInfo().discount.discount) {
          let sum;
          let USD;
          USD =((Number(product.retailprice) + Number(product.total_retail_vat)) -
              Number(productInfo().discount.discount.discount))
              / Number(currency[product.retail_currency]);
          if(userCurrency === product.retail_currency) {
            sum =
                quantity *
                Math.round(Number(USD) * Number(currency[userCurrency]))
            return sum;
          }
          else{
            sum =
                quantity *
                (Number(USD) * Number(currency[userCurrency])).toFixed(2)
            return sum;
          }
        }
        let sum;
        let USD;
        USD =(Number(product.retailprice) + Number(product.total_retail_vat))
            / Number(currency[product.retail_currency]);
        if(userCurrency === product.retail_currency) {
          sum =
              quantity *
              Math.round(Number(USD) * Number(currency[userCurrency]))
          return sum;
        }
        else{
          sum =
              quantity *
              (Number(USD) * Number(currency[userCurrency])).toFixed(2)
          return sum;
        }
      }
    }
    //amount to be taken
    function amount() {
      if (buying_option === "wholesale") {
        if (productInfo().customUoM.customUoM) {
          let sum;
          let USD;
          USD =Math.round(
              Number(product.wholesaleprice) +
              Number(product.total_wholesale_vat)
              )
              / Number(currency[product.wholesale_currency]);
          if(userCurrency === product.wholesale_currency) {
            sum =
                unit *
                Math.round(Number(USD) * Number(currency[userCurrency]))
            return sum;
          }
          else{
            sum =
                unit *
                (Number(USD) * Number(currency[userCurrency])).toFixed(2)
            return sum;
          }
        } else {
          let sum;
          let USD;
          USD =Math.round(
              Number(product.wholesaleprice) +
              Number(product.total_wholesale_vat)
              )
              / Number(currency[product.wholesale_currency]);
          if(userCurrency === product.wholesale_currency) {
            sum =
                Math.round(Number(USD) * Number(currency[userCurrency]))
            return sum;
          }
          else{
            sum =
                (Number(USD) * Number(currency[userCurrency])).toFixed(2)
            return sum;
          }
        }
      } else {
        if (productInfo().customUoM.customUoM) {
          if (productInfo().discount.discount) {
            let sum;
            let USD;
            USD =((Number(product.retailprice) + Number(product.total_retail_vat)) -
                Number(productInfo().discount.discount.discount))
                / Number(currency[product.retail_currency]);
            if(userCurrency === product.retail_currency) {
              sum =
                  unit *
                  Math.round(Number(USD) * Number(currency[userCurrency]))
              return sum;
            }
            else{
              sum =
                  unit *
                  (Number(USD) * Number(currency[userCurrency])).toFixed(2)
              return sum;
            }
          } else {
            let sum;
            let USD;
            USD =(Number(product.retailprice) + Number(product.total_retail_vat))
                / Number(currency[product.retail_currency]);
            if(userCurrency === product.retail_currency) {
              sum =
                  unit *
                  Math.round(Number(USD) * Number(currency[userCurrency]))
              return sum;
            }
            else{
              sum =
                  unit *
                  (Number(USD) * Number(currency[userCurrency])).toFixed(2)
              return sum;
            }
          }
        } else {
          if (productInfo().discount.discount) {
            let sum;
            let USD;
            USD =((Number(product.retailprice) + Number(product.total_retail_vat)) -
                Number(productInfo().discount.discount.discount))
                / Number(currency[product.retail_currency]);
            if(userCurrency === product.retail_currency) {
              sum = Math.round(Number(USD) * Number(currency[userCurrency]))
              return sum;
            }
            else{
              sum = (Number(USD) * Number(currency[userCurrency])).toFixed(2)
              return sum;
            }
          }
          let sum;
          let USD;
          USD =(Number(product.retailprice) + Number(product.total_retail_vat))
              / Number(currency[product.retail_currency]);
          if(userCurrency === product.retail_currency) {
            sum = Math.round(Number(USD) * Number(currency[userCurrency]))
            return sum;
          }
          else{
            sum = (Number(USD) * Number(currency[userCurrency])).toFixed(2)
            return sum;
          }
        }

      }
    }

    if (buying_option === "wholesale") {
      Price_data = (

        <CurrencyFormat
          value={total_amount()}
          displayType={"text"}
          thousandSeparator={true}
          renderText={(value) => (
            <div>
              {userCurrency}{" "}{value}
              {/*{product.wholesale_currency} {value}*/}
            </div>
          )}
        />

      );
    } else {
      Price_data = (
        <CurrencyFormat
          value={total_amount()}
          displayType={"text"}
          thousandSeparator={true}
          renderText={(value) => (
            <div>
              {userCurrency}{" "}{value}
              {/*{product.retail_currency} {value}*/}
            </div>
          )}
        />
      );
    }

    if (productInfo().discount.discount) {
      if (buying_option === "wholesale") {
        discounted = (
          <div className=" container product-card__prices">
            <small className="product-card__new-price">
              {userCurrency === product.wholesaleprice ?(
                  <CurrencyFormat
                      // value={Math.round(
                      //   Number(product.wholesaleprice) +
                      //     Number(product.total_wholesale_vat)
                      // )}
                      value={Math.round(((Number(product.wholesaleprice) + Number(product.total_wholesale_vat)) / Number(currency [product.wholesale_currency])) * Number(currency [userCurrency]))
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      renderText={(value) => (
                          <div>
                            {userCurrency}{" "}{value}
                            {/*{product.wholesale_currency} {value}*/}
                          </div>
                      )}
                  />
              ):(
                  <CurrencyFormat
                      // value={Math.round(
                      //   Number(product.wholesaleprice) +
                      //     Number(product.total_wholesale_vat)
                      // )}
                      value={(((Number(product.wholesaleprice) + Number(product.total_wholesale_vat)) / Number(currency [product.wholesale_currency])) * Number(currency [userCurrency])).toFixed(2)
                      }
                      displayType={"text"}
                      thousandSeparator={true}
                      renderText={(value) => (
                          <div>
                            {userCurrency}{" "}{value}
                            {/*{product.wholesale_currency} {value}*/}
                          </div>
                      )}
                  />
              )

              }

            </small>
          </div>
        );
      } else {
        discounted = (
          <div className="container product-card__prices">
            <span className="product-card__new-price">
               {userCurrency === product.retail_currency ? (
                   <CurrencyFormat
                       value={
                         Math.round((Number(product.retailprice) +
                             Number(product.total_retail_vat) -
                             Number(productInfo().discount.discount.discount))/
                             Number(currency [product.retail_currency]) * Number(currency [userCurrency]))}
                       displayType={"text"}
                       thousandSeparator={true}
                       renderText={(value) => (
                           <div>
                             {userCurrency}{" "}{value}
                             {/*{product.retail_currency} {value}*/}
                           </div>
                       )}
                   />
               ) : (
                   <CurrencyFormat
                       value={
                         ((Number(product.retailprice) +
                             Number(product.total_retail_vat) -
                             Number(productInfo().discount.discount.discount))/
                             Number(currency [product.retail_currency]) * Number(currency [userCurrency])).toFixed(2)}
                       displayType={"text"}
                       thousandSeparator={true}
                       renderText={(value) => (
                           <div>
                             {userCurrency}{" "}{value}
                             {/*{product.retail_currency} {value}*/}
                           </div>
                       )}
                   />
               )
               }

           </span>{" "}
            <span className="product-card__old-price">
               {userCurrency === product.retail_currency ? (
              <CurrencyFormat
                // value={Math.round(
                //   Number(product.retailprice) + Number(product.total_retail_vat)
                // )}

                  value={Math.round(((Number(product.retailprice) + Number(product.total_retail_vat)) / Number(currency [product.retail_currency])) * Number(currency [userCurrency]))
                  }
                displayType={"text"}
                thousandSeparator={true}
                renderText={(value) => (
                  <div>
                    {userCurrency}{" "}{value}
                    {/*{product.retail_currency} {value}*/}
                  </div>
                )}
              />
                   ):(
                   <CurrencyFormat
                       // value={Math.round(
                       //   Number(product.retailprice) + Number(product.total_retail_vat)
                       // )}
                       value={(((Number(product.retailprice) + Number(product.total_retail_vat)) / Number(currency [product.retail_currency])) * Number(currency [userCurrency])).toFixed(2)
                       }
                       displayType={"text"}
                       thousandSeparator={true}
                       renderText={(value) => (
                           <div>
                             {userCurrency}{" "}{value}
                             {/*{product.retail_currency} {value}*/}
                           </div>
                       )}
                   />
               )
               }
            </span>
            &nbsp;
            <small className="text-body text-lowercase">per  {unitMeasure()}</small>

            <div className="small">
              Discount Ends{" "}
              <Moment fromNow>{productInfo().discount.discount.end_times}</Moment>
            </div>
          </div>
        );
      }
    } else {
      if (buying_option === "wholesale") {
        discounted = (
          <div className="container product-card__prices">
            <span className="product-card__new-price">
              <div className="row">
                {userCurrency === product.wholesale_currency ? (
                    <CurrencyFormat
                        // value={Math.round(
                        //   Number(product.wholesaleprice) +
                        //     Number(product.total_wholesale_vat)
                        // )}
                        value={Math.round(Math.round(Number(product.wholesaleprice) + Number(product.total_wholesale_vat)) / Number(currency [product.wholesale_currency]) * Number(currency [userCurrency]))
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => (
                            <div>
                              {userCurrency}{" "}{value}
                              {/*{product.wholesale_currency} {value}*/}
                            </div>
                        )}
                    />
                ) : (
                    <CurrencyFormat
                        // value={Math.round(
                        //   Number(product.wholesaleprice) +
                        //     Number(product.total_wholesale_vat)
                        // )}
                        value={(Math.round(Number(product.wholesaleprice) + Number(product.total_wholesale_vat)) / Number(currency [product.wholesale_currency]) * Number(currency [userCurrency])).toFixed(2)
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => (
                            <div>
                              {userCurrency}{" "}{value}
                              {/*{product.wholesale_currency} {value}*/}
                            </div>
                        )}
                    />
                )
                }
                &nbsp;&nbsp;
               <div className="text-body text-lowercase">
                 <small>per  {unitMeasure()}</small>
               </div>
        </div>
            </span>
          </div>
        );
      } else {
        discounted = (
          <div className="container product-card__prices">
            <span className="product-card__new-price">
              <div className="row">
                 {userCurrency === product.retail_currency ? (
                     <CurrencyFormat
                         // value={Math.round(
                         //   Number(product.retailprice) + Number(product.total_retail_vat)
                         // )}
                         value={Math.round((Math.round(Number(product.retailprice) +
                             Number(product.total_retail_vat)) / Number(currency [product.retail_currency])) * Number(currency [userCurrency]))
                         }
                         displayType={"text"}
                         thousandSeparator={true}
                         renderText={(value) => (
                             <div>
                               {userCurrency}{" "}{value}
                               {/*{product.retail_currency} {value}*/}
                             </div>
                         )}
                     />
                 ) : (
                     <CurrencyFormat
                         // value={Math.round(
                         //   Number(product.retailprice) + Number(product.total_retail_vat)
                         // )}
                         value={((Math.round(Number(product.retailprice) +
                             Number(product.total_retail_vat)) / Number(currency [product.retail_currency])) * Number(currency [userCurrency])).toFixed(2)
                         }
                         displayType={"text"}
                         thousandSeparator={true}
                         renderText={(value) => (
                             <div>
                               {userCurrency}{" "}{value}
                               {/*{product.retail_currency} {value}*/}
                             </div>
                         )}
                     />
                 )
                 }
                &nbsp;
                <div className="text-body text-lowercase">
                 <small>per  {unitMeasure()}</small>
               </div>
          </div>
            </span>
          </div>
        );
      }
    }
    return (
      <div className={`product product--layout--${layout}`}>
        <div className="product__content">
          <ProductGallery layout={layout} images={productInfo().images.product_images} />
          <div className="product__info">
            <div className="product__wishlist-compare">
              <AsyncAction
                action={() => wishlistAddItem(product.Product)}
                render={({ run, loading }) => (
                  <button
                    type="button"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Wishlist"
                    onClick={run}
                    className={classnames("btn btn-sm btn-light btn-svg-icon", {
                      "btn-loading": loading,
                    })}
                  >
                    <Wishlist16Svg />
                  </button>
                )}
              />
              <AsyncAction
                action={() => compareAddItem(product.Product)}
                render={({ run, loading }) => (
                  <button
                    type="button"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Compare"
                    onClick={run}
                    className={classnames("btn btn-sm btn-light btn-svg-icon", {
                      "btn-loading": loading,
                    })}
                  >
                    <Compare16Svg />
                  </button>
                )}
              />
            </div>
            <h2 className="product__name text-capitalize">
              {productInfo().product_name.product_name}
            </h2>
            <div className="product__rating">
              <div className="product__rating-stars">
                <Rating value={review_rates.average} />
              </div>
              <div className="product__rating-legend">
                <Link>Reviews </Link>
              </div>
            </div>
            <div className="product__description">
              {/* {he.decode(
                  product.Product.product_description.replace(/<[^>]+>/g, "")
                )}  
                          */}
              <ShowMoreText
                lines={5}
                more="Read more"
                less="Read less"
                className="content-css"
                anchorClass="my-anchor-css-class"
                expanded={false}
              >
                {description}
              </ShowMoreText>
            </div>
            <ul className="product__meta">
              <li className="product__meta-availability">
                Monthly Capacity:{" "}
                <span className="text-success font-weight-bold">
                  {productInfo().monthlyProductionCapacity.monthlyProductionCapacity}
                </span>
              </li>
              <li>
                Brand:
                <span className="text-success font-weight-bold">
                  {productInfo().brand.brand}
                </span>
              </li>
              <li>
                Product number:
                <span className="text-success font-weight-bold">
                  {productInfo(). productNumber.product_number}
                </span>{" "}
              </li>
            </ul>
          </div>
           {/*digital products*/}
          {productInfo().isDigital ?(
             <div className="product__sidebar">
                    <br/>
                    {product.price_tag ? (
                        <div>
                          {quoteForm}
                        </div>
                    ) : (
                        <form className="product__options">
                          <div className="row">
                            <div className="col">
                              <div className="product__prices">
                                {!product.price_tag ? <>{discounted}</> : <></>}
                              </div>
                              <div className="form-group product__option">
                        <span className="text-uppercase container">
                          Quantity
                        </span>
                                <div className="row product__actions">
                                  <div className="product__actions-item">
                                    {buying_option === "retail" ? (
                                        <InputNumber
                                            id="product-quantity"
                                            aria-label="Quantity"
                                            className="product__quantity"
                                            size="sm"
                                            min={handleQuantity()}
                                            value={quantity}
                                            onChange={this.handleChangeQuantity}
                                        />
                                    ) : (
                                        <InputNumber
                                            id="product-quantity"
                                            aria-label="Quantity"
                                            className="product__quantity"
                                            size="sm"
                                            min={handleQuantity()}
                                            value={quantitywholesale}
                                            onChange={this.handleChangeWholesaleQuantity}
                                        />
                                    )}
                                  </div>
                                  {!product.price_tag ? (
                                      <>
                              <span className="text-uppercase">
                                {Price_data}
                              </span>
                                      </>
                                  ) : (
                                      <span className="text-uppercase"></span>
                                  )}
                                </div>
                              </div>
                              <span>
                        {product.vat_check && !product.price_tag ? (
                            <>vat inclusive</>
                        ) : (
                            <> vat exclusive</>
                        )}
                      </span>
                            </div>
                            <div className="col mt-3">
                              <div className="card border-white">
                                <ul className="list-group list-group-flush">
                                  <li className="list-group-item  border-white">
                                    <label>PRICE OPTIONS</label>
                                  </li>
                                  <li className="list-group-item  border-white">
                                    <label>
                              <span className="filter-list__input input-radio">
                                <span className="input-radio__body">
                                  <input
                                      className="input-radio__input"
                                      type="radio"
                                      name="options"
                                      onClick={this.buyingHandle}
                                      checked={buying_option === "retail"}
                                      value="retail"
                                  />
                                  <span className="input-radio__circle"/>
                                </span>
                              </span>
                                      Retail
                                    </label>
                                  </li>
                                  <li className="list-group-item">
                                    <label>
                              <span className="filter-list__input input-radio">
                                <span className="input-radio__body">
                                  <input
                                      className="input-radio__input"
                                      type="radio"
                                      name="options"
                                      value="wholesale"
                                      checked={buying_option === "wholesale"}
                                      onClick={this.buyingHandle}
                                  />
                                  <span className="input-radio__circle"/>
                                </span>
                              </span>
                                      Wholesale
                                    </label>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          {/*agent commision display here*/}

                          {agenttoken()?
                              <div className="mt-3">
                                Agent commission :{tobedisplayed}
                              </div>
                              :null
                          }
                          {/*end here*/}
                          <div className="form-group product__option">
                            <div className="product__actions mt-4">
                              {buying_option === "retail" ? (
                                  <>
                                    {customertoken() || agentcustomerToken() ? (
                                        <div className="product__actions-item product__actions-item--addtocart">
                                          <AsyncAction
                                              action={() =>
                                                  digitalAddBuynow(product_details(), quantity,HandleReferral())
                                              }
                                              render={({run, loading}) => (
                                                  <button
                                                      type="button"
                                                      onClick={run}
                                                      disabled={!quantity}
                                                      className={classnames(
                                                          "btn   btn-success btn-md",
                                                          {
                                                            "btn-loading": loading,
                                                          }
                                                      )}
                                                  >
                                                    Buy Now
                                                  </button>
                                              )}
                                          />
                                        </div>

                                    ) : (
                                        <>
                                          {Loginmodal}
                                        </>
                                    )

                                    }
                                  </>
                              ) : (
                                  <>
                                    {customertoken() || agentcustomerToken() ? (
                                        <div className="product__actions-item product__actions-item--addtocart">
                                          <AsyncAction
                                              action={() =>
                                                 digitalAddBuynow(
                                                      product_details(),
                                                      quantitywholesale,HandleReferral()
                                                  )
                                              }
                                              render={({run, loading}) => (
                                                  <button
                                                      type="button"
                                                      onClick={run}
                                                      disabled={!quantitywholesale}
                                                      className={classnames(
                                                          "btn   btn-success btn-md",
                                                          {
                                                            "btn-loading": loading,
                                                          }
                                                      )}
                                                  >
                                                    Buy Now
                                                  </button>
                                              )}
                                          />
                                        </div>
                                    ) : (
                                        <>
                                          {Loginmodal}
                                        </>
                                    )

                                    }
                                  </>
                              )}
                            </div>
                          </div>
                        </form>
                    )}
                    <div className="row">
                      <p className="mt-2">
                  <span className="container">
                     Buy From : {productInfo().outletName.outlet_name},&nbsp;
                    {productInfo().district.district},&nbsp;{otherDetails().region.region}
                  </span>
                      </p>
                    </div>

                    <p>
                  <span>
                    <Link className="text-capitalize"
                          to={`/shops/${outletToken}`}
                    >
                  Visit {productInfo().outletName.outlet_name} Store
                  </Link>
                  </span>
                    </p>
                    {/*</div>*/}
                    <div className="product__footer">
                      <div className="product__tags tags">
                        <div className="tags__list">
                          {productdata.length > 0 ? (
                              <Link
                                  className="btn btn-primary rounded-pill"
                                  data-toggle="modal"
                                  data-target="#exampleModalLong"
                              >
                                Buy From Others Outlets{" "}
                                <i className="text-secondary fas fa-store-alt"/>
                              </Link>
                          ) : (
                              <div/>
                          )}
                        </div>
                      </div>
                      {/* model for other outlets  start here*/}
                      <div
                          className="modal fade"
                          id="exampleModalLong"
                          tabIndex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLongTitle"
                          aria-hidden="true"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                  className="modal-title container text-center"
                                  id="exampleModalLongTitle"
                              >
                                OTHER OUTLETS AVAILABLE
                              </h5>
                              <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            {/* start of outlets  */}
                            <Scrollbars style={{height: 350}}>
                              {/* firsts store start here  */}
                              {productdata.map((shop) => (
                                  <Shop product={shop} rate={currency} Referral={HandleReferral()}/>
                              ))}
                              {/* firsts store end here  */}
                            </Scrollbars>
                            {/* end of outlet  */}

                            <div className="modal-footer">
                              <button
                                  type="button"
                                  className="btn btn-primary btn-sm"
                                  data-dismiss="modal"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/*model for other outlets  end here*/}
                    </div>
                  </div>
              )
            :product_details().preference.length>= 1 && productInfo().customUoM.customUoM ? (
            <div className="product__sidebar">
              <br />
              {product.price_tag ? (
                <div>
                  {quoteForm}
                </div>
              ) : (
                <form className="product__options">
                  <div className="row">
                    <div className="col">
                      <div className="product__prices">
                        {!product.price_tag ? (
                          <>
                            <small>{discounted}</small>
                          </>
                        ) : (
                          <small></small>
                        )}
                      </div>
                      {/*preferences data*/}
                      {preferences}
                      {/*end of preferences data*/}
                    </div>
                    <div className="col mt-3">
                      <div className="card border-white">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item  border-white">
                            <label>PRICE OPTIONS</label>
                          </li>
                          <li className="list-group-item  border-white">
                            <label>
                              <span className="filter-list__input input-radio">
                                <span className="input-radio__body">
                                  <input
                                    className="input-radio__input"
                                    type="radio"
                                    name="options"
                                    onClick={this.buyingHandle}
                                    checked={buying_option === "retail"}
                                    value="retail"
                                  />
                                  <span className="input-radio__circle" />
                                </span>
                              </span>
                              Retail
                            </label>
                          </li>
                          <li className="list-group-item">
                            <label>
                              <span className="filter-list__input input-radio">
                                <span className="input-radio__body">
                                  <input
                                    className="input-radio__input"
                                    type="radio"
                                    name="options"
                                    value="wholesale"
                                    checked={buying_option === "wholesale"}
                                    onClick={this.buyingHandle}
                                  />
                                  <span className="input-radio__circle" />
                                </span>
                              </span>
                              Wholesale
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="form-group product__option">
                    <div className="row">
                      <div className="col product__actions">
                        <div className="product__actions-item">
                          <div className="row product__actions-item">
                            <span>Custom Unit Measure</span>
                            <InputNumber
                              id="product-quantity"
                              aria-label="Quantity"
                              className="product__quantity"
                              size="sm"
                              min={1}
                              value={unit}
                              onChange={this.handleChangeunit}
                            />
                            <span className="col">
                              {unitMeasure()}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col product__actions">
                        <div className="product__actions-item">
                          <span>Quantity</span>
                          {buying_option === "retail" ? (
                            <InputNumber
                              id="product-quantity"
                              aria-label="Quantity"
                              className="product__quantity"
                              size="sm"
                              min={handleQuantity()}
                              value={quantity}
                              onChange={this.handleChangeQuantity}
                            />
                          ) : (
                            <InputNumber
                              id="product-quantity"
                              aria-label="Quantity"
                              className="product__quantity"
                              size="sm"
                              min={handleQuantity()}
                              value={quantitywholesale}
                              onChange={this.handleChangeWholesaleQuantity}
                            />
                          )}
                          {!product.price_tag ? (
                            <>
                              <span>{Price_data}</span>
                            </>
                          ) : (
                            <span></span>
                          )}
                        </div>
                      </div>
                    </div>
                    <span>
                      {product.vat_check && !product.price_tag ? (
                        <>vat inclusive</>
                      ) : (
                        <> vat exclusive</>
                      )}
                    </span>
                  </div>
                  <div className="form-group product__option">
                    <div className="product__actions mt-4">
                      {buying_option === "retail" ? (
                        <>
                          {customertoken() || agentcustomerToken() ?(
                                  <div className="product__actions-item product__actions-item--addtocart">
                                    <AsyncAction
                                        action={() =>
                                            ProductDetails(product_details(), quantity,HandleReferral())
                                        }
                                        render={({ run, loading }) => (
                                            <button
                                                type="button"
                                                onClick={run}
                                                disabled={
                                                  !quantity ||
                                                  !this.state.preferenceValidation ||
                                                  !this.state.preferenceValidationsize
                                                }
                                                className={classnames(
                                                    "btn   btn-success btn-md",
                                                    {
                                                      "btn-loading": loading,
                                                    }
                                                )}
                                            >
                                              Buy Now
                                            </button>
                                        )}
                                    />
                                  </div>

                              ):(
                              <>
                                {Loginmodal}
                              </>
                          )

                          }

                          <div className="product__actions-item product__actions-item--addtocart">
                            <AsyncAction
                              action={() =>
                                cartAddItem(product_details(), [], quantity,HandleReferral())
                              }
                              render={({ run, loading }) => (
                                <button
                                  type="button"
                                  onClick={run}
                                  disabled={
                                    !quantity ||
                                    !this.state.preferenceValidation ||
                                    !this.state.preferenceValidationsize
                                  }
                                  className={classnames(
                                    "btn   btn-primary btn-md",
                                    {
                                      "btn-loading": loading,
                                    }
                                  )}
                                >
                                  Add to cart
                                </button>
                              )}
                            />
                          </div>
                          <div className="product__actions-item product__actions-item--wishlist">
                            <AsyncAction
                              action={() => wishlistAddItem(product_details(),HandleReferral())}
                              render={({ run, loading }) => (
                                <button
                                  type="button"
                                  data-toggle="tooltip"
                                  title="Wishlist"
                                  onClick={run}
                                  disabled={
                                    !this.state.preferenceValidation ||
                                    !this.state.preferenceValidationsize
                                  }
                                  className={classnames("btn btn-secondary", {
                                    "btn-loading": loading,
                                  })}
                                >
                                  <i
                                    className="fa fa-heart text-danger"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                            />
                          </div>
                          {quotemodal}
                        </>
                      ) : (
                        <>
                          {customertoken() || agentcustomerToken()?(
                              <div className="product__actions-item product__actions-item--addtocart">
                                <AsyncAction
                                    action={() =>
                                        ProductDetails(
                                            product_details(),
                                            quantitywholesale,HandleReferral()
                                        )
                                    }
                                    render={({ run, loading }) => (
                                        <button
                                            type="button"
                                            onClick={run}
                                            disabled={
                                              !quantitywholesale ||
                                              !this.state.preferenceValidation ||
                                              !this.state.preferenceValidationsize
                                            }
                                            className="product__actions-item product__actions-item--addtocart"
                                            className={classnames(
                                                "btn   btn-success btn-md",
                                                {
                                                  "btn-loading": loading,
                                                }
                                            )}
                                        >
                                          Buy Now
                                        </button>
                                    )}
                                />
                              </div>

                          ):(
                              <>
                                {Loginmodal}
                              </>
                          )

                          }

                          <div className="product__actions-item product__actions-item--addtocart">
                            <AsyncAction
                              action={() =>
                                cartAddItem(
                                  product_details(),
                                  [],
                                  quantitywholesale,HandleReferral()
                                )
                              }
                              render={({ run, loading }) => (
                                <button
                                  type="button"
                                  onClick={run}
                                  disabled={
                                    !quantitywholesale ||
                                    !this.state.preferenceValidation ||
                                    !this.state.preferenceValidationsize
                                  }
                                  className={classnames(
                                    "btn   btn-primary btn-md",
                                    {
                                      "btn-loading": loading,
                                    }
                                  )}
                                >
                                  Add to cart
                                </button>
                              )}
                            />
                          </div>
                          <div className="product__actions-item product__actions-item--wishlist">
                            <AsyncAction
                              action={() => wishlistAddItem(product_details(),HandleReferral())}
                              render={({ run, loading }) => (
                                <button
                                  type="button"
                                  data-toggle="tooltip"
                                  title="Wishlist"
                                  onClick={run}
                                  disabled={
                                    !this.state.preferenceValidation ||
                                    !this.state.preferenceValidationsize
                                  }
                                  className={classnames("btn btn-secondary", {
                                    "btn-loading": loading,
                                  })}
                                >
                                  <i
                                    className="fa fa-heart text-danger"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                            />
                          </div>
                          {quotemodal}
                        </>
                      )}
                      <div
                        className="modal fade"
                        id="Modalgroupbuy"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLongTitle"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title container text-center"
                                id="exampleModalLongTitle"
                              >
                                SELECT GROUP
                              </h5>
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <Scrollbars style={{ height: 300 }}>
                                <ul class="list-group">
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">Vijana Group</span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">
                                        Kupatana Group
                                      </span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">TYD Group</span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">Wajanja Group</span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">Care Group</span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">
                                        Cras justo odio
                                      </span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">Care Group</span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">Care Group</span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                </ul>
                              </Scrollbars>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              {/* <button type="button" class="btn btn-primary">
                Save changes
              </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* popup model for group buy option start here  */}
                    </div>
                  </div>
                </form>
              )}
              <div >
                <p className="mt-2">
                  <span>
                      Buy From : {productInfo().outletName.outlet_name},&nbsp;
                    {productInfo().district.district},&nbsp; {otherDetails().region.region}
                  </span>
                </p>
              </div>
              <p>
                  <span>
                    <Link className="text-capitalize"
                        to={`/shops/${outletToken}`}
                    >
                 Visit  {productInfo().outletName.outlet_name} Store
                  </Link>
                  </span>
              </p>
              <div className="product__footer">
                <div className="product__tags tags">
                  <div className="tags__list">
                    {productdata.length > 0 ? (
                      <Link
                        className="btn btn-primary rounded-pill"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Buy From Others Outlets{" "}
                        <i className="text-secondary fas fa-store-alt" />
                      </Link>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
                {/* model for other outlets  start here*/}
                <div
                  className="modal fade"
                  id="exampleModalLong"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLongTitle"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5
                          className="modal-title container text-center"
                          id="exampleModalLongTitle"
                        >
                          OTHER OUTLETS AVAILABLE
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      {/* start of outlets  */}
                      <Scrollbars style={{ height: 350 }}
                      >
                        {/* firsts store start here  */}
                        {productdata.map((shop) => (
                          <Shop product={shop} rate={currency}/>
                        ))}
                        {/* firsts store end here  */}
                      </Scrollbars>
                      {/* end of outlet  */}

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {/*model for other outlets  end here*/}
              </div>
            </div>
          ) : product_details().preference.length >= 1 && !productInfo().customUoM.customUoM ? (
            <div className="product__sidebar">
              {product.price_tag ? (
                <div>
                  {quoteForm}
             </div>
              ) : (
                <form className="product__options">
                  <div className="row container product__prices">
                    {!product.price_tag ? (
                        <>
                        {discounted}
                        </>
                    ) : (
                        <></>
                    )}
                  </div>
                  <div  className="row">
                    <div className="col product__option mt-2">
                      <div className="product__actions">
                        <h7>Quantity</h7>
                        <div>
                          {buying_option === "retail" ? (
                              <InputNumber
                                  id="product-quantity"
                                  aria-label="Quantity"
                                  // className="product__quantity"
                                  size="md"
                                  min={handleQuantity()}
                                  value={quantity}
                                  onChange={this.handleChangeQuantity}
                              />
                          ) : (
                              <InputNumber
                                  id="product-quantity"
                                  aria-label="Quantity"
                                  // className="product__quantity"
                                  size="sm"
                                  min={handleQuantity()}
                                  value={quantitywholesale}
                                  onChange={this.handleChangeWholesaleQuantity}
                              />
                          )}
                          {!product.price_tag ? (
                            <h6 className="text-uppercase mt-3">{Price_data}</h6>
                            ) : (
                            <></>
                        )}
                        </div>
                      </div>
                      {/*<span>*/}
                    {product.vat_check && !product.price_tag ? (
                        <>vat inclusive</>
                    ) : (
                        <> vat exclusive</>
                    )}
                  {/*</span>*/}
                    </div>

                    <div className="col">
                      <div className="card border-white">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item  border-white">
                            <h6>
                              <small>PRICE OPTIONS </small>
                            </h6>
                          </li>
                          <li className="list-group-item  border-white">
                            <label>
                              <span className="filter-list__input input-radio">
                                <span className="input-radio__body">
                                  <input
                                    className="input-radio__input"
                                    type="radio"
                                    name="options"
                                    onClick={this.buyingHandle}
                                    checked={buying_option === "retail"}
                                    value="retail"
                                  />
                                  <span className="input-radio__circle" />
                                </span>
                              </span>
                              Retail
                            </label>
                          </li>
                          <li className="list-group-item">
                            <label>
                              <span className="filter-list__input input-radio">
                                <span className="input-radio__body">
                                  <input
                                    className="input-radio__input"
                                    type="radio"
                                    name="options"
                                    value="wholesale"
                                    checked={buying_option === "wholesale"}
                                    onClick={this.buyingHandle}
                                  />
                                  <span className="input-radio__circle" />
                                </span>
                              </span>
                              Wholesale
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/*<div >*/}
                    {/*preferences data*/}
                    {preferences}
                    {/*end of preferences data*/}
                  {/*</div>*/}
                  {/*agent commision display here*/}

                  {agenttoken()?
                      <div className="mt-3">
                        Agent commission :{tobedisplayed}
                      </div>
                      :null
                  }
                  {/*end here*/}
                  <div className="form-group product__option">
                    <div className="product__actions mt-4">
                      {buying_option === "retail" ? (
                        <>
                          {customertoken() || agentcustomerToken()?(
                              <div className="product__actions-item product__actions-item--addtocart">
                                <AsyncAction
                                    action={() =>
                                        ProductDetails(product_details(), quantity,HandleReferral())
                                    }
                                    render={({ run, loading }) => (
                                        <button
                                            type="button"
                                            onClick={run}
                                            disabled={
                                              !quantity ||
                                              !this.state.preferenceValidation
                                            }
                                            className={classnames(
                                                "btn   btn-success btn-md",
                                                {
                                                  "btn-loading": loading,
                                                }
                                            )}
                                        >
                                          Buy Now
                                        </button>
                                    )}
                                />
                              </div>
                          ):(
                              <>
                                {Loginmodal}
                              </>
                          )

                          }
                        <div className="product__actions-item product__actions-item--addtocart">
                            <AsyncAction
                              action={() =>
                                cartAddItem(product_details(), [], quantity,HandleReferral())
                              }
                              render={({ run, loading }) => (
                                <button
                                  type="button"
                                  onClick={run}
                                  disabled={
                                    !quantity ||
                                    !this.state.preferenceValidation
                                  }
                                  className={classnames(
                                    "btn   btn-primary btn-md",
                                    {
                                      "btn-loading": loading,
                                    }
                                  )}
                                >
                                  Add to cart
                                </button>
                              )}
                            />
                          </div>
                          <div className="product__actions-item product__actions-item--wishlist">
                            <AsyncAction
                              action={() => wishlistAddItem(product_details(),HandleReferral())}
                              render={({ run, loading }) => (
                                <button
                                  type="button"
                                  data-toggle="tooltip"
                                  title="Wishlist"
                                  onClick={run}
                                  disabled={
                                    !this.state.preferenceValidation ||
                                    !this.state.preferenceValidationsize
                                  }
                                  className={classnames("btn btn-secondary", {
                                    "btn-loading": loading,
                                  })}
                                >
                                  <i
                                    className="fa fa-heart text-danger"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                            />
                          </div>
                          {quotemodal}
                        </>
                      ) : (
                        <>
                          {customertoken() || agentcustomerToken() ?(
                              <div className="product__actions-item product__actions-item--addtocart">
                                <AsyncAction
                                    action={() =>
                                        ProductDetails(
                                            product_details(),
                                            quantitywholesale,HandleReferral()
                                        )
                                    }
                                    render={({ run, loading }) => (
                                        <button
                                            type="button"
                                            onClick={run}
                                            disabled={
                                              !quantitywholesale ||
                                              !this.state.preferenceValidation
                                            }
                                            className={classnames(
                                                "btn   btn-success btn-md",
                                                {
                                                  "btn-loading": loading,
                                                }
                                            )}
                                        >
                                          Buy Now
                                        </button>
                                    )}
                                />
                              </div>
                          ):(
                              <>
                                {Loginmodal}
                              </>
                          )

                          }
                          <div className="product__actions-item product__actions-item--addtocart">
                            <AsyncAction
                              action={() =>
                                cartAddItem(
                                  product_details(),
                                  [],
                                  quantitywholesale,HandleReferral()
                                )
                              }
                              render={({ run, loading }) => (
                                <button
                                  type="button"
                                  onClick={run}
                                  disabled={
                                    !quantitywholesale ||
                                    !this.state.preferenceValidation
                                  }
                                  className={classnames(
                                    "btn   btn-primary btn-md",
                                    {
                                      "btn-loading": loading,
                                    }
                                  )}
                                >
                                  Add to cart
                                </button>
                              )}
                            />
                          </div>
                          <div className="product__actions-item product__actions-item--wishlist">
                            <AsyncAction
                              action={() => wishlistAddItem(product_details(),HandleReferral())}
                              render={({ run, loading }) => (
                                <button
                                  type="button"
                                  data-toggle="tooltip"
                                  title="Wishlist"
                                  onClick={run}
                                  className={classnames("btn btn-secondary", {
                                    "btn-loading": loading,
                                  })}
                                >
                                  <i
                                    className="fa fa-heart text-danger"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                            />
                          </div>
                          {quotemodal}
                        </>
                      )}
                    </div>
                  </div>
                </form>
              )}
              <div>
                <p className="mt-2">
                  <span>
                    Buy From : {productInfo().outletName.outlet_name},&nbsp;
                    {productInfo().district.district},&nbsp;{otherDetails().region.region}
                  </span>
                </p>
              </div>
              <p>
                  <span className="text-capitalize">
                    <Link 
                        to={`/shops/${outletToken}`}
                    >
                 Visit {productInfo().outletName.outlet_name} &nbsp; Store
                  </Link>
                  </span>
              </p>
              <div className="product__footer">
                <div className="product__tags tags">
                  <div className="tags__list">
                    {productdata.length > 0 ? (
                      <Link
                        className="btn btn-primary rounded-pill"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Buy From Others Outlets{" "}
                        <i className="text-secondary fas fa-store-alt" />
                      </Link>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
                {/* model for other outlets  start here*/}
                <div
                  className="modal fade"
                  id="exampleModalLong"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLongTitle"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5
                          className="modal-title container text-center"
                          id="exampleModalLongTitle"
                        >
                          OTHER OUTLETS AVAILABLE
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      {/* start of outlets  */}
                      <Scrollbars style={{ height: 350 }}>
                        {/* firsts store start here  */}
                        {productdata.map((shop) => (
                          <Shop product={shop} rate={currency}/>
                        ))}
                        {/* firsts store end here  */}
                      </Scrollbars>
                      {/* end of outlet  */}

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : productInfo().customUoM.customUoM ? (
            <div className="product__sidebar">
              <br />
              {product.price_tag ? (
                <div>
                  {quoteForm}
              </div>
              ) : (
                <form className="product__options">
                  <div className="row">
                    <div className="col">
                      <div className="product__prices">
                        {!product.price_tag ? (
                          <>
                            <small>{discounted}</small>
                          </>
                        ) : (
                          <small></small>
                        )}
                      </div>
                      <div className="product__actions">
                        <div className="product__actions-item">
                          <div className="row product__actions-item">
                            <span>Custom unit of measure</span>
                            <InputNumber
                              id="product-quantity"
                              aria-label="Quantity"
                              className="product__quantity"
                              size="sm"
                              min={1}
                              value={unit}
                              onChange={this.handleChangeunit}
                            />
                            <span className="col">
                              {unitMeasure()}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="product__actions">
                        <div className="product__actions-item">
                          <span>Quantity</span>
                          {buying_option === "retail" ? (
                            <InputNumber
                              id="product-quantity"
                              aria-label="Quantity"
                              className="product__quantity"
                              size="sm"
                              min={handleQuantity()}
                              value={quantity}
                              onChange={this.handleChangeQuantity}
                            />
                          ) : (
                            <InputNumber
                              id="product-quantity"
                              aria-label="Quantity"
                              className="product__quantity"
                              size="sm"
                              min={handleQuantity()}
                              value={quantitywholesale}
                              onChange={this.handleChangeWholesaleQuantity}
                            />
                          )}
                          {!product.price_tag ? (
                            <>
                              <span>{Price_data}</span>
                            </>
                          ) : (
                            <span></span>
                          )}
                        </div>
                      </div>
                      <span>
                        {product.vat_check && !product.price_tag ? (
                          <>vat inclusive</>
                        ) : (
                          <> vat exclusive</>
                        )}
                      </span>
                    </div>
                    <div className="col mt-3">
                      <div className="card border-white">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item  border-white">
                            <label>PRICE OPTIONS</label>
                          </li>
                          <li className="list-group-item  border-white">
                            <label>
                              <span className="filter-list__input input-radio">
                                <span className="input-radio__body">
                                  <input
                                    className="input-radio__input"
                                    type="radio"
                                    name="options"
                                    onClick={this.buyingHandle}
                                    checked={buying_option === "retail"}
                                    value="retail"
                                  />
                                  <span className="input-radio__circle" />
                                </span>
                              </span>
                              Retail
                            </label>
                          </li>
                          <li className="list-group-item">
                            <label>
                              <span className="filter-list__input input-radio">
                                <span className="input-radio__body">
                                  <input
                                    className="input-radio__input"
                                    type="radio"
                                    name="options"
                                    value="wholesale"
                                    checked={buying_option === "wholesale"}
                                    onClick={this.buyingHandle}
                                  />
                                  <span className="input-radio__circle" />
                                </span>
                              </span>
                              Wholesale
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/*agent commision display here*/}

                  {agenttoken()?
                      <div className="mt-3">
                        Agent commission :{tobedisplayed}
                      </div>
                      :null
                  }
                  {/*end here*/}
                  <div className="form-group product__option">
                    <div className="product__actions mt-4">
                      {buying_option === "retail" ? (
                        <>
                          {customertoken() || agentcustomerToken()?(
                              <div className="product__actions-item product__actions-item--addtocart">
                                <AsyncAction
                                    action={() =>
                                        ProductDetails(product_details(),quantity,HandleReferral())
                                    }
                                    render={({ run, loading }) => (
                                        <button
                                            type="button"
                                            onClick={run}
                                            disabled={!quantity}
                                            className={classnames(
                                                "btn   btn-success btn-md",
                                                {
                                                  "btn-loading": loading,
                                                }
                                            )}
                                        >
                                          Buy Now
                                        </button>
                                    )}
                                />
                              </div>
                          ):(
                              <>
                                {Loginmodal}
                              </>
                          )

                          }
                       <div className="product__actions-item product__actions-item--addtocart">
                            <AsyncAction
                              action={() =>
                                cartAddItem(product_details(), [], quantity,HandleReferral())
                              }
                              render={({ run, loading }) => (
                                <button
                                  type="button"
                                  onClick={run}
                                  disabled={!quantity}
                                  className={classnames(
                                    "btn   btn-primary btn-md",
                                    {
                                      "btn-loading": loading,
                                    }
                                  )}
                                >
                                  Add to cart
                                </button>
                              )}
                            />
                          </div>
                          <div className="product__actions-item product__actions-item--wishlist">
                            <AsyncAction
                              action={() => wishlistAddItem(product_details(),HandleReferral())}
                              render={({ run, loading }) => (
                                <button
                                  type="button"
                                  data-toggle="tooltip"
                                  title="Wishlist"
                                  onClick={run}
                                  className={classnames("btn btn-secondary", {
                                    "btn-loading": loading,
                                  })}
                                >
                                  <i
                                    className="fa fa-heart text-danger"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                            />
                          </div>
                          {quotemodal}
                        </>
                      ) : (
                        <>
                          {customertoken() || agentcustomerToken()?(
                              <div className="product__actions-item product__actions-item--addtocart">
                                <AsyncAction
                                    action={() =>
                                        ProductDetails(
                                            product_details(),
                                            quantitywholesale,HandleReferral()
                                        )
                                    }
                                    render={({ run, loading }) => (
                                        <button
                                            type="button"
                                            onClick={run}
                                            disabled={!quantitywholesale}
                                            className={classnames(
                                                "btn   btn-success btn-md",
                                                {
                                                  "btn-loading": loading,
                                                }
                                            )}
                                        >
                                          Buy Now
                                        </button>
                                    )}
                                />
                              </div>
                          ):(
                              <>
                                {Loginmodal}
                              </>
                          )

                          }
                        <div className="product__actions-item product__actions-item--addtocart">
                            <AsyncAction
                              action={() =>
                                cartAddItem(
                                  product_details(),
                                  [],
                                  quantitywholesale,HandleReferral()
                                )
                              }
                              render={({ run, loading }) => (
                                <button
                                  type="button"
                                  onClick={run}
                                  disabled={!quantitywholesale}
                                  className={classnames(
                                    "btn   btn-primary btn-md",
                                    {
                                      "btn-loading": loading,
                                    }
                                  )}
                                >
                                  Add to cart
                                </button>
                              )}
                            />
                          </div>
                          <div className="product__actions-item product__actions-item--wishlist">
                            <AsyncAction
                              action={() => wishlistAddItem(product_details(),HandleReferral())}
                              render={({ run, loading }) => (
                                <button
                                  type="button"
                                  data-toggle="tooltip"
                                  title="Wishlist"
                                  onClick={run}
                                  className={classnames("btn btn-secondary", {
                                    "btn-loading": loading,
                                  })}
                                >
                                  <i
                                    className="fa fa-heart text-danger"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                            />
                          </div>
                          {quotemodal}
                        </>
                      )}
                      <div
                        className="modal fade"
                        id="Modalgroupbuy"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLongTitle"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title container text-center"
                                id="exampleModalLongTitle"
                              >
                                SELECT GROUP
                              </h5>
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <Scrollbars style={{ height: 300 }}>
                                <ul class="list-group">
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">Vijana Group</span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">
                                        Kupatana Group
                                      </span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">TYD Group</span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">Wajanja Group</span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">Care Group</span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">
                                        Cras justo odio
                                      </span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">Care Group</span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">Care Group</span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                </ul>
                              </Scrollbars>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              {/* <button type="button" class="btn btn-primary">
              Save changes
            </button> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* popup model for group buy option start here  */}
                    </div>
                  </div>
                </form>
              )}
              <div className="container">
                <p className="mt-2">
                  <span>
                     Buy From : {productInfo().outletName.outlet_name},&nbsp;
                    {productInfo().district.district}, &nbsp;{otherDetails().region.region}
                  </span>
                </p>
              </div>
              <p>
                  <span>
                    <Link className="text-capitalize"
                        to={`/shops/${outletToken}`}
                    >
                 Visit {productInfo().outletName.outlet_name} Store
                  </Link>
                  </span>
              </p>
              <div className="product__footer">
                <div className="product__tags tags">
                  <div className="tags__list">
                    {productdata.length > 0 ? (
                      <Link
                        className="btn btn-primary rounded-pill"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Buy From Others Outlets{" "}
                        <i className="text-secondary fas fa-store-alt" />
                      </Link>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
                {/* model for other outlets  start here*/}
                <div
                  className="modal fade"
                  id="exampleModalLong"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLongTitle"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5
                          className="modal-title container text-center"
                          id="exampleModalLongTitle"
                        >
                          OTHER OUTLETS AVAILABLE
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      {/* start of outlets  */}
                      <Scrollbars style={{ height: 350 }}>
                        {/* firsts store start here  */}
                        {productdata.map((shop) => (
                          <Shop product={shop} rate={currency}/>
                        ))}
                        {/* firsts store end here  */}
                      </Scrollbars>
                      {/* end of outlet  */}

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/*model for other outlets  end here*/}
              </div>
            </div>
          ) : (
            <div className="product__sidebar">
              <br />
              {product.price_tag ? (
                <div>
                  {quoteForm}
                 </div>
              ) : (
                <form className="product__options">
                  <div className="row">
                    <div className="col">
                      <div className="product__prices">
                        {!product.price_tag ? <>{discounted}</> : <></>}
                      </div>
                      <div className="form-group product__option">
                        <span className="text-uppercase container">
                          Quantity
                        </span>
                        <div className="row product__actions">
                          <div className="product__actions-item">
                            {buying_option === "retail" ? (
                              <InputNumber
                                id="product-quantity"
                                aria-label="Quantity"
                                className="product__quantity"
                                size="sm"
                                min={handleQuantity()}
                                value={quantity}
                                onChange={this.handleChangeQuantity}
                              />
                            ) : (
                              <InputNumber
                                id="product-quantity"
                                aria-label="Quantity"
                                className="product__quantity"
                                size="sm"
                                min={handleQuantity()}
                                value={quantitywholesale}
                                onChange={this.handleChangeWholesaleQuantity}
                              />
                            )}
                          </div>
                          {!product.price_tag ? (
                            <>
                              <span className="text-uppercase">
                                {Price_data}
                              </span>
                            </>
                          ) : (
                            <span className="text-uppercase"></span>
                          )}
                        </div>
                      </div>
                      <span>
                        {product.vat_check && !product.price_tag ? (
                          <>vat inclusive</>
                        ) : (
                          <> vat exclusive</>
                        )}
                      </span>
                    </div>
                    <div className="col mt-3">
                      <div className="card border-white">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item  border-white">
                            <label>PRICE OPTIONS</label>
                          </li>
                          <li className="list-group-item  border-white">
                            <label>
                              <span className="filter-list__input input-radio">
                                <span className="input-radio__body">
                                  <input
                                    className="input-radio__input"
                                    type="radio"
                                    name="options"
                                    onClick={this.buyingHandle}
                                    checked={buying_option === "retail"}
                                    value="retail"
                                  />
                                  <span className="input-radio__circle" />
                                </span>
                              </span>
                              Retail
                            </label>
                          </li>
                          <li className="list-group-item">
                            <label>
                              <span className="filter-list__input input-radio">
                                <span className="input-radio__body">
                                  <input
                                    className="input-radio__input"
                                    type="radio"
                                    name="options"
                                    value="wholesale"
                                    checked={buying_option === "wholesale"}
                                    onClick={this.buyingHandle}
                                  />
                                  <span className="input-radio__circle" />
                                </span>
                              </span>
                              Wholesale
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/*agent commision display here*/}

                  {agenttoken()?
                      <div className="mt-3">
                        Agent commission :{tobedisplayed}
                      </div>
                      :null
                  }
                   {/*end here*/}

                  <div className="form-group product__option">
                    <div className="product__actions mt-4">
                      {buying_option === "retail" ? (
                        <>
                          {customertoken() || agentcustomerToken()?(
                              <div className="product__actions-item product__actions-item--addtocart">
                                <AsyncAction
                                    action={() =>
                                        ProductDetails(product_details(), quantity,HandleReferral())
                                    }
                                    render={({ run, loading }) => (
                                        <button
                                            type="button"
                                            onClick={run}
                                            disabled={!quantity}
                                            className={classnames(
                                                "btn   btn-success btn-md",
                                                {
                                                  "btn-loading": loading,
                                                }
                                            )}
                                        >
                                          Buy Now
                                        </button>
                                    )}
                                />
                              </div>

                          ):(
                              <>
                                {Loginmodal}
                              </>
                          )

                          }
                        <div className="product__actions-item product__actions-item--addtocart">
                            <AsyncAction
                              action={() =>
                                cartAddItem(product_details(), [], quantity,HandleReferral())
                              }
                              render={({ run, loading }) => (
                                <button
                                  type="button"
                                  onClick={run}
                                  disabled={!quantity}
                                  className={classnames(
                                    "btn   btn-primary btn-md",
                                    {
                                      "btn-loading": loading,
                                    }
                                  )}
                                >
                                  Add to cart
                                </button>
                              )}
                            />
                          </div>
                          <div className="product__actions-item product__actions-item--wishlist">
                            <AsyncAction
                              action={() => wishlistAddItem(product_details(),HandleReferral())}
                              render={({ run, loading }) => (
                                <button
                                  type="button"
                                  data-toggle="tooltip"
                                  title="Wishlist"
                                  onClick={run}
                                  className={classnames("btn btn-secondary", {
                                    "btn-loading": loading,
                                  })}
                                >
                                  <i
                                    className="fa fa-heart text-danger"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                            />
                          </div>
                          {quotemodal}
                        </>
                      ) : (
                        <>
                          {customertoken() || agentcustomerToken() ?(
                              <div className="product__actions-item product__actions-item--addtocart">
                                <AsyncAction
                                    action={() =>
                                        ProductDetails(
                                            product_details(),
                                            quantitywholesale,HandleReferral()
                                        )
                                    }
                                    render={({ run, loading }) => (
                                        <button
                                            type="button"
                                            onClick={run}
                                            disabled={!quantitywholesale}
                                            className={classnames(
                                                "btn   btn-success btn-md",
                                                {
                                                  "btn-loading": loading,
                                                }
                                            )}
                                        >
                                          Buy Now
                                        </button>
                                    )}
                                />
                              </div>
                          ):(
                              <>
                              {Loginmodal}
                              </>
                          )

                          }


                          <div className="product__actions-item product__actions-item--addtocart">
                            <AsyncAction
                              action={() =>
                                cartAddItem(
                                  product_details(),
                                  [],
                                  quantitywholesale,HandleReferral()
                                )
                              }
                              render={({ run, loading }) => (
                                <button
                                  type="button"
                                  onClick={run}
                                  disabled={!quantitywholesale}
                                  className={classnames(
                                    "btn   btn-primary btn-md",
                                    {
                                      "btn-loading": loading,
                                    }
                                  )}
                                >
                                  Add to cart
                                </button>
                              )}
                            />
                          </div>
                          <div className="product__actions-item product__actions-item--wishlist">
                            <AsyncAction
                              action={() => wishlistAddItem(product_details(),HandleReferral())}
                              render={({ run, loading }) => (
                                <button
                                  type="button"
                                  data-toggle="tooltip"
                                  title="Wishlist"
                                  onClick={run}
                                  className={classnames("btn btn-secondary", {
                                    "btn-loading": loading,
                                  })}
                                >
                                  <i
                                    className="fa fa-heart text-danger"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                            />
                          </div>
                          {/* modal quotation */}
                          {quotemodal}
                        </>
                      )}

                      <div
                        className="modal fade"
                        id="Modalgroupbuy"
                        tabindex="-1"
                        role="dialog"
                        aria-labelledby="exampleModalLongTitle"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title container text-center"
                                id="exampleModalLongTitle"
                              >
                                SELECT GROUP
                              </h5>
                              <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <Scrollbars style={{ height: 300 }}>
                                <ul class="list-group">
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">Vijana Group</span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">
                                        Kupatana Group
                                      </span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">TYD Group</span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">Wajanja Group</span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">Care Group</span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">
                                        Cras justo odio
                                      </span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">Care Group</span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                  <li class="list-group-item list-group-item-action">
                                    <span className="row">
                                      <span className="col">Care Group</span>
                                      <span className="col">
                                        <Link
                                          target="_blank"
                                          to="/shop/GroupWishlist"
                                          className="btn-sm rounded-lg"
                                        >
                                          Add To this Group
                                        </Link>
                                      </span>
                                    </span>
                                  </li>
                                </ul>
                              </Scrollbars>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              {/* <button type="button" class="btn btn-primary">
                            Save changes
                          </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* popup model for group buy option start here  */}
                    </div>
                  </div>
                </form>
              )}
              <div className="row">
                <p className="mt-2">
                  <span className="container">
                     Buy From : {productInfo().outletName.outlet_name},&nbsp;
                    {productInfo().district.district},&nbsp;{otherDetails().region.region}
                  </span>
                </p>
              </div>
                <p>
                  <span>
                    <Link className="text-capitalize"
                        to={`/shops/${outletToken}`}
                    >
                  Visit   {productInfo().outletName.outlet_name} Store
                  </Link>
                  </span>
                </p>
              {/*</div>*/}
              <div className="product__footer">
                <div className="product__tags tags">
                  <div className="tags__list">
                    {productdata.length > 0 ? (
                      <Link
                        className="btn btn-primary rounded-pill"
                        data-toggle="modal"
                        data-target="#exampleModalLong"
                      >
                        Buy From Others Outlets{" "}
                        <i className="text-secondary fas fa-store-alt" />
                      </Link>
                    ) : (
                      <div />
                    )}
                  </div>
                </div>
                {/* model for other outlets  start here*/}
                <div
                  className="modal fade"
                  id="exampleModalLong"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLongTitle"
                  aria-hidden="true"
                >
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5
                          className="modal-title container text-center"
                          id="exampleModalLongTitle"
                        >
                          OTHER OUTLETS AVAILABLE
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      {/* start of outlets  */}
                      <Scrollbars style={{ height: 350 }}>
                        {/* firsts store start here  */}
                        {productdata.map((shop) => (
                          <Shop product={shop} rate={currency}/>
                        ))}
                        {/* firsts store end here  */}
                      </Scrollbars>
                      {/* end of outlet  */}

                      <div className="modal-footer">
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          data-dismiss="modal"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/*model for other outlets  end here*/}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

Product.propTypes = {
  /** product object */
  product: PropTypes.object.isRequired,
  /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
  layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
};

Product.defaultProps = {
  layout: "standard",
};

const mapDispatchToProps = {
  cartAddItem,
  wishlistAddItem,
  compareAddItem,
  QuoteAddItem,
  digitalAddBuynow,
  ProductDetails,
};

export default connect(() => ({}), mapDispatchToProps)(Product);