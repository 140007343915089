// react
import React, { useEffect, useState } from "react";
// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import theme from "../../data/theme";
import { customertoken } from "../../api/shop";
import { connect, useDispatch, useSelector } from "react-redux";
import Moment from "react-moment";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import { DeleteBooking, FetchBooking } from "../../store/booking";
import { Spinner } from "reactstrap";

const useStyles = makeStyles((theme) => ({
    root: {
        color: green[500],
        width: "100%",
        "& > * + *": {
            marginTop: theme.spacing(2),
        },
    },
}));

function Booking(props) {
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        if (customertoken()) {
            dispatch(FetchBooking());
        }
    }, [dispatch]);
    const { DeleteBooking } = props
    const DataList = useSelector((state) => state.booking);
    console.log(DataList)
    const [bookingdata, setBookingdata] = useState([]);
    const [Token, setToken] = useState(null)
    useEffect(() => {
        setBookingdata(DataList);
    }, [DataList]);
    // const [totalItems, setTotalItems] = useState(0);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [search, setSearch] = useState("");
    // const ITEMS_PER_PAGE = 5;
    // console.log(orders)

    // const bookingData = useMemo(() => {
    //     let computedBook = bookingdata;
    //     if (search) {
    //         computedBook = computedBook.filter(
    //             (booking) =>
    //                 booking.listing.listing_title.toLowerCase().includes(search.toLowerCase()) ||
    //                 booking.listing.slug.toLowerCase().includes(search.toLowerCase()) ||
    //                 booking.listing.allow_booking.toLowerCase().includes(search.toLowerCase()) ||
    //                 booking.listing.allow_postpaid.toLowerCase().includes(search.toLowerCase())
    //         );
    //     }
    //     setTotalItems(computedBook.length);
    //     //Current Page slice
    //     return computedBook.slice(
    //         (currentPage - 1) * ITEMS_PER_PAGE,
    //         (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    //     );
    // }, [bookingdata, currentPage, search]);

    return (
        <div className="dashboard">
            {bookingdata.length <= 0 ? (
                <>
                    <div className={classes.root}>

                        <span className="d-flex justify-content-center">
                            <Spinner size="xl" />
                        </span>
                        <span className="d-flex mt-10 justify-content-center font-weight-bold">
                            Data Loading...
            </span></div>

                </>
            ) : (
                <>
                    <Helmet>
                        <title>{`My Account — ${theme.name}`}</title>
                    </Helmet>
                    <div className="dashboard__orders card">
                        <div className="card-header">
                            <div className="">RECENT BOOKINGS</div>
                            {/*<div className="d-flex align-items-end flex-column">*/}
                            {/*    <div className="mt-auto p-2">*/}
                            {/*        <Search*/}
                            {/*            onSearch={(value) => {*/}
                            {/*                setSearch(value);*/}
                            {/*                setCurrentPage(1);*/}
                            {/*            }}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>

                        {/*{Rates.length === 0 ?*/}
                        {/*    (*/}
                        {/*        <div className={classes.root}>*/}
                        {/*            <span className="d-flex justify-content-center">*/}
                        {/*                <Spinner size="xl" />*/}
                        {/*            </span>*/}
                        {/*            <span className="d-flex mt-10 justify-content-center font-weight-bold">*/}
                        {/*                Data Loading...*/}
                        {/*            </span>*/}
                        {/*        </div>*/}

                        {/*    ) : (*/}
                        {/*        <>*/}
                        <div className="card-divider" />
                        <div className="card-table">
                            <div className="table-responsive-sm">
                                <table>
                                    <thead>
                                        <tr className="text-center">
                                        <th>Number</th>
                                        <th>Name</th>
                                        <th>status</th>
                                        <th>Date</th>
                                        <th>Action</th>
                                            {/* <th>Number</th>
                                            <th>Name</th>
                                            <th>Slug</th>
                                            <th>Price</th>
                                            <th>Time slot</th>
                                            <th>Adult</th>
                                            <th>Child</th>
                                            <th>Date</th>
                                            <th>Action</th> */}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {bookingdata.bookings.map((index) => (
                                            <tr className="text-center">
                                                <td>{index.booking_number}</td>
                                                <td>{index.listing_title}</td>
                                                <td>
                                                    {index.is_approved ?
                                                        <span className="text-success">
                                                            Approved
                                                        </span>
                                                        :
                                                        <span className="text-danger">
                                                            Not Approved
                                                        </span>
                                                    }
                                                </td>
                                                <td>
                                                    <Moment format="DD/MM/YYYY">
                                                        {index.created_at}
                                                    </Moment>
                                                </td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button
                                                            class="btn btn-white"
                                                            type="button"
                                                            id="dropdownMenuButton"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false"
                                                        >
                                                            <b>...</b>
                                                        </button>
                                                        <div
                                                            className="dropdown-menu"
                                                            aria-labelledby="dropdownMenuButton"
                                                        >
                                                            <Link className="dropdown-item">
                                                                More details
                                                        </Link>
                                                            <button
                                                                onClick={() =>
                                                                    setToken(index.secure_token)
                                                                }
                                                                type="button"
                                                                className="btn-primary" data-toggle="modal"
                                                                data-target="#cancelModal">
                                                                Cancel booking
                                                        </button>
                                                        </div>
                                                        {/* model for confirmation cancel order */}
                                                        <div className="modal fade" id="cancelModal" tabIndex="-1"
                                                            role="dialog"
                                                            aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                            <div className="modal-dialog" role="document">
                                                                <div className="modal-content">
                                                                    <div className="modal-header">
                                                                        {/*<h5 className="modal-title" id="exampleModalLabel">Modal title</h5>*/}
                                                                        <button type="button" className="close"
                                                                            data-dismiss="modal" aria-label="Close">
                                                                            <span aria-hidden="true">&times;</span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="modal-body">
                                                                        Are you sure want to cancel this booking ?
                                                                </div>
                                                                    <div className="modal-footer">
                                                                        <button type="button" className="btn btn-secondary"
                                                                            data-dismiss="modal">Close
                                                                    </button>
                                                                        <button
                                                                            type="button"
                                                                            data-dismiss="modal"
                                                                            onClick={() => DeleteBooking(Token)}
                                                                            className="btn btn-danger">
                                                                            Accept
                                                                    </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {/*    </>*/}
                        {/*)}*/}
                        <div className="card-footer">
                            {/* <div className="d-flex align-items-center flex-column">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div> */}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

const mapStateToProps = (state) => ({
    // Booking: state.booking

});

const mapDispatchToProps = {
    FetchBooking,
    DeleteBooking
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Booking);
