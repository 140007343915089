import React from "react";
import {Helmet} from "react-helmet-async";
import theme from "../../../data/theme";
import {connect} from "react-redux";

function ListingWorkingHours(props) {
    const{listing_time,schedule}=props
    console.log("listing_time",listing_time);
    return (
        <React.Fragment>
            <Helmet>
                <title>{`listing action — ${theme.name}`}</title>
            </Helmet>
            <div className="container-fluid mt-5">
            <ul className="list-items padding-top-30px">
                            {schedule === null || schedule.schedule === "selected" && listing_time !== null ? 
                            listing_time.map((item, i) => {
                                return (
                                    <li key={i} className="d-flex justify-content-between">
                                        {item.day}
                                        <strong className='text-black font-weight-medium'>
                                            {/*<Moment  format="LT">{item.opening_time} </Moment>*/}
                                            {item.opening_time}
                                        {" "} - {" "}
                                            {item.closing_time}

                                        </strong>
                                    </li>
                                )
                            }) : 
                                schedule.schedule === "always" ?
                                (
                                    <li className="d-flex justify-content-between">
                                       <h6 className="text-success">Open 24 hours</h6>
                                    </li>
                                ):(
                                 
                                        <li className="d-flex justify-content-between">
                                         <h6  className="text-success">Open by appointment only</h6>
                                        </li>
                                    )
                             }
                        </ul>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListingWorkingHours)
