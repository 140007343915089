import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import theme from "../../data/theme";
export default function AccountPageViewOrder() {
  const [value, setValue] = useState();
  return (
    <React.Fragment>
      <Helmet>
        <title>{`Order Details — ${theme.name}`}</title>
      </Helmet>
      <div className="card">
        <div className="order-header">
          <div className="order-header__subtitle">
            Group will <mark className="order-header__date">Expire</mark> after
            order to be delivered.
          </div>
          <div className="order-header__actions">
            <Link className=" rounded-lg" to="/shop/groupwishlist">
              CREATE NEW ORDER
            </Link>
          </div>
          <div className="order-header__title">Order Number: #3857</div>
          <div className="order-header__subtitle">Group Name: TYD Group</div>
          <div className="order-header__subtitle">Total Member: 6</div>
        </div>
        <br></br>
        <h6>Product List</h6>
        <div className="card-divider" />
        <div className="card-table">
          <div className="table-responsive-sm">
            <table>
              <thead>
                <tr>
                  <th>Order No</th>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th>Price</th>
                  <th>Total Amount</th>
                  <th>Member Assigned</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <td>G-65342</td>
                <td>Electronics</td>
                <td>45</td>
                <td>5000</td>
                <td>67598</td>
                <td>Yusuph Kaondo</td>
                <td>PAID</td>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
