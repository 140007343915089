import React, {useState} from 'react';
import {IoMdStar, IoMdStarHalf} from 'react-icons/io'
import {FiHeart} from 'react-icons/fi'
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from "react-router-dom";
import classNames from "classnames";
import CurrencyFormat from "react-currency-format";
import AsyncAction from "../../shared/AsyncAction";
import PaymentOptioncomponent from "../paymentOption";
import ServiceGallery from "../../shared/ServiceProductGallery";
import classnames from "classnames";
import {Compare16Svg, Wishlist16Svg} from "../../../svg";
import Rating from "../../shared/Rating";
import ShowMoreText from "react-show-more-text";
import ReactHtmlParser from "react-html-parser";
import {toast} from "react-toastify";
import {agentcustomerToken, customertoken} from "../../../api/shop";

function ServiceChoiceCard(props) {
    const {
        services,
        title,
        layout,
        onClick,
        serviceId,
        handleRemove
    } = props;
    // console.log(services)
    const [ModalData, setModalData] = useState([]);
    const containerClasses = classNames("product-card", {
        "product-card--layout--grid product-card--size--sm": layout === "grid-sm",
        "product-card--layout--grid product-card--size--nl": layout === "grid-nl",
        "product-card--layout--grid product-card--size--lg": layout === "grid-lg",
        "product-card--layout--list": layout === "list",
        "product-card--layout--horizontal": layout === "horizontal",
    });
    const responsive = [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        // slidesToScroll: 1,
        slidesToScroll: 2,
        arrows: true,
        // slidesToShow={4}
        centerMode: false,
        // centerPadding: "50px",
        autoplay: true
    };

    function Notification() {
        toast.info("Please login or create account to add this service choice")
    }

    return (
        <div className="mt-5">
            <h5 className="text-sm-left  font-size-20">
                {title}
            </h5>
            {/*modal for more detail about service choice */}
            <div className="modal fade bd-example-modal-lg2" tabIndex="-1" role="dialog"
                 aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content container">
                        <div className="modal-header text-center">
                            <h5 className="modal-title text-center">
                                {/*SERVICE CHOICE DETAILS*/}
                            </h5>
                        </div>
                        <div className={`product product--layout--${layout}`}>
                            <div className="product__content container">
                                {
                                    ModalData.length !== 0 ?
                                        <Slider {...settings}>
                                            {ModalData.choice_image.map((service, index) => {
                                                return (
                                                    <div>
                                                        <img
                                                            src={service.image_name}
                                                            height="340" alt="Place"/>
                                                    </div>
                                                )
                                            })}
                                        </Slider>
                                        : null
                                }

                                {/*<ServiceGallery layout={layout} images={ModalData.choice_image}/>*/}
                                <div className="product__info mt-3">
                                    <h2 className="product__name text-capitalize">
                                        {ModalData.choice_name}
                                    </h2>
                                    <div className="product__rating">
                                        <div className="product__rating-stars">
                                            <Rating value="5"/>
                                        </div>
                                        <div className="product__rating-legend">
                                            <Link>Reviews </Link>
                                        </div>
                                    </div>
                                    <div className="product__description">
                                        {/* {he.decode(
                  product.Product.product_description.replace(/<[^>]+>/g, "")
                )}
                          */}
                                        <ShowMoreText
                                            lines={10}
                                            more="Read more"
                                            less="Read less"
                                            className="content-css"
                                            anchorClass="my-anchor-css-class"
                                            expanded={false}
                                        >
                                            {ReactHtmlParser(ModalData.choice_description)}
                                        </ShowMoreText>
                                    </div>
                                    <p className="mt-4 row container">

                                        <span className="text-uppercase font-weight-bold">Price</span>:
                                        <span className="text-danger font-weight-bold ml-4">
                                            {
                                                ModalData.length !== 0 ?
                                                    <CurrencyFormat
                                                        value={ModalData.choice_price.service_price}
                                                        displayType={"text"}
                                                        thousandSeparator={true}
                                                        renderText={(value) => (
                                                            <div>
                                                                {ModalData.choice_price.currency}{" "}{value}
                                                            </div>
                                                        )}
                                                    />
                                                    : null
                                            }

                        </span>

                                    </p>
                                    {/*<div className="mt-3">*/}
                                    {/*    <b> NB:</b>This Price only for <b>{DefaultPrice.name}</b> if you want to others*/}
                                    {/*    service please*/}
                                    {/*    add*/}
                                    {/*    below*/}
                                    {/*</div>*/}

                                    {/*<div className="card-divider"/>*/}
                                    <div className="row  mt-5 container">
                                        <div className="product__actions-item product__actions-item--wishlist">
                                            <button
                                                type="button"
                                                data-dismiss="modal"
                                                className={classnames("btn btn-secondary")}
                                            >
                                                cancel
                                            </button>
                                        </div>
                                        <div className="product__actions-item product__actions-item--wishlist">
                                            {customertoken() || agentcustomerToken() ?
                                                <>
                                                    {serviceId.includes(ModalData) ?
                                                        <>
                                                            {
                                                                serviceId.filter(name => name.id === ModalData.id).map((index) => (
                                                                    <>
                                                                        {index.id === ModalData.id ?
                                                                            <button
                                                                                type="button"
                                                                                onClick={() => handleRemove(ModalData)}
                                                                                className={classNames(
                                                                                    "btn btn-success product-card__addtocart rounded-pill"
                                                                                )}
                                                                            >
                                                                                SELECTED
                                                                            </button>
                                                                            :
                                                                            null
                                                                        }
                                                                    </>
                                                                ))}
                                                        </>
                                                        :
                                                        <button
                                                            type="button"
                                                            onClick={() => onClick(ModalData)}
                                                            className={classNames(
                                                                "btn btn-primary product-card__addtocart rounded-pill"
                                                            )}
                                                        >
                                                            INCLUDE THIS
                                                        </button>

                                                    }

                                                </>
                                                :
                                                <button
                                                    data-dismiss="modal"
                                                    type="button"
                                                    onClick={() => Notification()}
                                                    className={classnames("btn btn-primary")}
                                                >
                                                    INCLUDE THIS
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    {/*modal end here */}
                                </div>
                                {/*digital products*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                {services.length < 4 ?
                    // <div className={containerClasses}>
                    <>
                    {services.map((service, index) => {
                        return (
                            <div className="col-lg-3 mt-3">
                                <div>
                                    <div>
                                        <Link className="card-image-wrap">
                                            <div className="card-image">
                                                <img
                                                    src={service.choice_image[0].image_name}
                                                    className="card__img" height="220" alt="Place"/>
                                            </div>
                                        </Link>
                                        <div className="card-content-wrap">
                                            <div className="card-content">
                                                <Link>
                                                    {/*<span className="badge text-black  text-capitalize"> test</span>*/}
                                                    <h5 className="text-capitalize text-muted"
                                                        style={{color: 'black'}}>
                                                        {service.choice_name.length > 26
                                                            ? `${service.choice_name.substring(0, 26)}...`
                                                            : service.choice_name}
                                                    </h5>
                                                    <span className="card-sub"
                                                          data-toggle="modal"
                                                          data-target=".bd-example-modal-lg2"
                                                          onClick={() => setModalData(service)}
                                                    >
                         {service.choice_description.length > 15
                             ? `${service.choice_description.substring(0, 15)}... READ MORE`
                             : service.choice_description}
                            </span>
                                                    <div className="product-card__prices row">
                                                        <div className="col-8 float-left">
                                                            <CurrencyFormat
                                                                value={service.choice_price.service_price}
                                                                displayType={"text"}
                                                                thousandSeparator={true}
                                                                renderText={(value) => (
                                                                    <div>
                                                                        {service.choice_price.currency} {value}
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </Link>
                                                <a href="" className="author-img">
                                                    <img src={service.choice_image[0].image_name} alt="author-img"/>
                                                </a>

                                            </div>
                                            <div className="product-card__actions container">
                                                {customertoken() || agentcustomerToken() ?
                                                    <>
                                                        {serviceId.includes(service) ?
                                                            <>
                                                                {
                                                                    serviceId.filter(name => name.id === service.id).map((index) => (
                                                                        <>
                                                                            {index.id === service.id ?
                                                                                <button
                                                                                    type="button"
                                                                                    onClick={() => handleRemove(service)}
                                                                                    className={classNames(
                                                                                        "btn btn-success product-card__addtocart rounded-pill"
                                                                                    )}
                                                                                >
                                                                                    SELECTED
                                                                                </button>
                                                                                :
                                                                                null
                                                                            }
                                                                        </>
                                                                    ))}
                                                            </>
                                                            :
                                                            <button
                                                                type="button"
                                                                onClick={() => onClick(service)}
                                                                className={classNames(
                                                                    "btn btn-primary product-card__addtocart rounded-pill"
                                                                )}
                                                            >
                                                                INCLUDE THIS
                                                            </button>

                                                        }

                                                    </>
                                                    :
                                                    <button
                                                        data-dismiss="modal"
                                                        type="button"
                                                        onClick={() => Notification()}
                                                        className={classnames("btn btn-primary")}
                                                    >
                                                        INCLUDE THIS
                                                    </button>
                                                }
                                            </div>

                                            <div className="rating-row mt-3">
                                                <div className="rating-rating">
                                                    {[
                                                        <IoMdStar/>,
                                                        <IoMdStar/>,
                                                        <IoMdStar/>,
                                                        <IoMdStarHalf/>,
                                                        <IoMdStar
                                                            className="last-star"/>].map((rating, index) => {
                                                        return (
                                                            <span key={index}>{rating}</span>
                                                        )
                                                    })}
                                                    {/*<span className="rating-count">{item.ratingNum}</span>*/}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        )
                    })}
                    {/*// </div>*/}
                        </>
                        :
                        <div className="col-lg-12 mt-3">
                        <Slider className="places-carousel" dots={false} infinite={true} slidesToScroll={2}
                        arrows={true} slidesToShow={4} centerMode={false} centerPadding="50px"
                        autoplay={true} responsive={responsive}>
                    {services.map((service, index) => {
                        return (
                        <div className="card-item  bg-light" key={index}>
                        <Link>
                        <div>
                        <Link className="card-image-wrap">
                        <div className="card-image">
                        <img
                        src={service.choice_image[0].image_name}
                        className="card__img" height="220" alt="Place"/>
                        </div>
                        </Link>
                        <div className="card-content-wrap">
                        <div className="card-content">
                        <Link>
                    {/*<span className="badge text-black  text-capitalize"> test</span>*/}
                        <h5 className="text-capitalize text-muted"
                        style={{color: 'black'}}>
                    {service.choice_name.length > 26
                        ? `${service.choice_name.substring(0, 26)}...`
                        : service.choice_name}
                        </h5>
                        <span className="card-sub"
                        data-toggle="modal"
                        data-target=".bd-example-modal-lg2"
                        onClick={() => setModalData(service)}
                        >
                    {service.choice_description.length > 17
                        ? `${service.choice_description.substring(0, 17)}... READ MORE`
                        : service.choice_description}
                        </span>
                        <div className="product-card__prices row">
                        <div className="col-8 float-left">
                        <CurrencyFormat
                        value={service.choice_price.service_price}
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => (
                        <div>
                    {service.choice_price.currency} {value}
                        </div>
                        )}
                        />
                        </div>
                        </div>
                        </Link>
                        <a href="" className="author-img">
                        <img src={service.choice_image[0].image_name}
                        alt="author-img"/>
                        </a>

                        </div>
                        <div className="product-card__actions">
                    {/*<div className="product-card__buttons">*/}
                    {service.is_default ?
                        <button
                        type="button"
                        // onClick={run}
                        className={classNames(
                        "btn btn-success product-card__addtocart rounded-pill"
                        )}
                        >
                        SELECTED
                        </button>

                        :
                        <>
                    {customertoken() ?

                        <>
                    {serviceId.includes(service)?
                        <>
                    {
                        serviceId.filter(name => name.id === service.id).map((index) => (
                        <>
                    {index.id === service.id?
                        <button
                        type="button"
                        onClick={() => handleRemove(service)}
                        className={classNames(
                        "btn btn-success product-card__addtocart rounded-pill"
                        )}
                        >
                        SELECTED
                        </button>
                        :
                        null
                    }
                        </>
                        ))}
                        </>
                        :
                        <button
                        type="button"
                        onClick={() => onClick(service)}
                        className={classNames(
                        "btn btn-primary product-card__addtocart rounded-pill"
                        )}
                        >
                        INCLUDE THIS
                        </button>

                    }


                        </>


                        :
                        <button
                        data-dismiss="modal"
                        type="button"
                        onClick={() => Notification()}
                        className={classnames("btn btn-primary")}
                        >
                        INCLUDE THIS
                        </button>

                    }
                        </>


                    }
                    {/*</div>*/}

                        </div>

                        <div className="rating-row">
                        <div className="rating-rating">
                    {[
                        <IoMdStar/>,
                        <IoMdStar/>,
                        <IoMdStar/>,
                        <IoMdStarHalf/>,
                        <IoMdStar
                        className="last-star"/>].map((rating, index) => {
                        return (
                        <span key={index}>{rating}</span>
                        )
                    })}
                    {/*<span className="rating-count">{item.ratingNum}</span>*/}
                        </div>
                        </div>
                        </div>
                        </div>


                        </Link>
                        </div>
                        )
                    })}
                        </Slider>
                        </div>
                    }

                    </div>

                    </div>
                    );
                }

export default ServiceChoiceCard;