import React from "react";
import {Helmet} from "react-helmet-async";
import theme from "../../../data/theme";
import {connect} from "react-redux";

function ListingLocation(props) {
    const {listing_locations}=props
    return (
        <React.Fragment>
            <Helmet>
                <title>{`listing action — ${theme.name}`}</title>
            </Helmet>
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-md-4">
                        <div >
                            <div >
                                <div>
                                    <p><span className="font-weight-bold">Country</span> : {listing_locations.country}</p>
                                    <p><span className="font-weight-bold">Region</span> : {listing_locations.region}</p>
                                    <p> <span className="font-weight-bold">District</span> : {listing_locations.district}</p>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListingLocation)
