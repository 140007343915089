// react
import React, { useState, useEffect } from "react";
// third-party
import { Helmet } from "react-helmet-async";
// application
// data stubs
import theme from "../../data/theme";
import CurrencyFormat from "react-currency-format";
import Moment from "react-moment";
import Axios from "axios";
import { Spinner } from "reactstrap";
import {userCurrency} from "../../api/shop";
function ShopPageOrderSuccess(props) {
  const orderItem = props.location.state;
  const [order, setOrders] = useState([]);
  useEffect(() => {
    const orderList = async () => {
      const results = {
        order_number: orderItem,
      };
      const data = await Axios.post(`${window.$name}/api/order/get-order-info`, results)
        .then((response) => response.data);
      setOrders(data);
    };
    orderList();
  }, []);

  return (
    <div className="block order-success">
      <Helmet>
        <title>{`Order Success — ${theme.name}`}</title>
      </Helmet>
      <div className="container">
        {order.length <= 0 ? (
          <div>
            <span className="d-flex justify-content-center">
              <Spinner size="xl" />
            </span>
            <span className="d-flex mt-10 justify-content-center font-weight-bold">
              Data Loading...
            </span>
          </div>
        ) : (
            <div className="order-success__body">
              <div className="order-success__meta">
                <ul className="order-success__meta-list">
                  <li className="order-success__meta-item">
                    <span className="order-success__meta-title">
                      Order number:
                  </span>
                    <span className="order-success__meta-value">
                      {order.order_number}
                    </span>
                  </li>
                  <li className="order-success__meta-item">
                    <span className="order-success__meta-title">Created at:</span>
                    <span className="order-success__meta-value">
                      <Moment format="DD/MM/YYYY">{order.created_at}</Moment>
                    </span>
                  </li>
                  <li className="order-success__meta-item">
                    <span className="order-success__meta-title">Total:</span>
                    <span className="order-success__meta-value">
                      <CurrencyFormat
                        value={order.total_amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        renderText={(value) => (
                          <div>
                            {" "}
                            {"TZS"} {"  "} {value}
                          </div>
                        )}
                      />
                    </span>
                  </li>
                  <li className="order-success__meta-item">
                    <span className="order-success__meta-title">Status:</span>
                    <>
                      {order.order_status === "pending" ? (
                        <span className=" text-danger order-success__meta-value">
                          Pending
                        </span>
                      ) : order.order_status === "Paid" ? (
                        <span className="text-success order-success__meta-value">
                          Paid
                        </span>
                      ) : order.order_status === "Shipped" ? (
                        <span className="text-warning order-success__meta-value">
                          Shipped
                        </span>
                      ) : (
                              <span className="text-primary order-success__meta-value">
                                Delivered
                              </span>
                            )}
                    </>
                  </li>
                </ul>
              </div>
                {order.shipping_address !==null?
                    <div className="row mt-3 no-gutters mx-n2">
                        <div className="col-sm-6 col-12 px-2">
                            <div className="card address-card">
                                <div className="address-card__body">
                                    <div className="address-card__badge text-dark">
                                        Shipping Address
                                    </div>
                                    <div className="address-card__name text-uppercase">
                                        {order.shipping_address.address_fullname}
                                    </div>
                                    <div className="address-card__row">
                                        {order.shipping_address.country.country}, &nbsp;
                                        {order.shipping_address.region.region}, &nbsp;
                                        {order.shipping_address.area}
                                    </div>
                                    <div className="address-card__row">
                                        <div className="address-card__row-title">
                                            Phone Number
                                        </div>
                                        <div className="address-card__row-content">
                                            {order.shipping_address.address_mobileNumber}
                                            &nbsp; or &nbsp;{" "}
                                            {order.shipping_address.address_mobileNumber}
                                        </div>
                                    </div>
                                    <div className="address-card__row">
                        <span className="address-card__row-title">
                          Address Type:
                      </span>
                                        &nbsp;
                                        {order.shipping_address.address_AddressType}
                                    </div>
                                    <div className="address-card__row">
                        <span className="address-card__row-title">
                          {" "}
                            Post Code:
                      </span>
                                        &nbsp;
                                        {order.shipping_address.address_postcode}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-12 px-2 " />
                    </div>
                    :null

                }
                {order.is_service_price ?
                    <div className="container">
                        <p>
                            <span>Main service</span>{" "}{" "}

                            <span className="font-weight-bold">{order.cart_details.product_name}</span>
                        </p>
                    </div>
                    :null
                }
             <div className="card mt-4">
                <div className="order-list">
                    {order.is_service_price ?
                        <table>
                            <thead className="order-list__header">
                            <tr>
                                <td className="order-list__column-label" colSpan="2">
                                    Choice Image
                                </td>
                                <td className="order-list__column-label">Choice name</td>
                                <td className="order-list__column-total">Sub Total</td>
                            </tr>
                            </thead>
                            <tbody className="h-10 overflow-auto w-auto order-list__products">
                            {order.cart_details.choices.map((item) => (
                                <tr>
                                    <td colSpan="2" className="order-list__column-image">
                                        <div className="product-image">
                                            <div className="product-image__body">
                                                <img
                                                    className="product-image__img"
                                                    src={item.choice_image[0].image_name}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    <td className="order-list__column-product">
                                        <div className="order-list__options">
                                            <ul className="order-list__options-list ">
                                                {item.choice_name}
                                            </ul>
                                        </div>
                                    </td>
                                    <td className="order-list__column-total">
                                        <CurrencyFormat
                                            value={item.choice_price.service_price}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            renderText={(value) => (
                                                <div>
                                                    {userCurrency()}{" "}{value}
                                                    {/*{product.wholesale_currency} {value}*/}
                                                </div>
                                            )}
                                        />
                                        {/*{userCurrency() === item.choice_price.currency ? (*/}
                                        {/*//     <CurrencyFormat*/}
                                        {/*//         value={Math.round(((Number(item.choice_price.service_price))*/}
                                        {/*//             / Number(OrderRates[item.choice_price.currency])) * Number(OrderRates[userCurrency()]))*/}
                                        {/*//         }*/}
                                        {/*//         displayType={"text"}*/}
                                        {/*//         thousandSeparator={true}*/}
                                        {/*//         renderText={(value) => (*/}
                                        {/*//             <div>*/}
                                        {/*//                 {userCurrency()}{" "}{value}*/}
                                        {/*//                 /!*{product.wholesale_currency} {value}*!/*/}
                                        {/*//             </div>*/}
                                        {/*//         )}*/}
                                        {/*//     />*/}
                                        {/*// ) : (*/}
                                        {/*//     <CurrencyFormat*/}
                                        {/*//         value={(((Number(item.choice_price.service_price)) / Number(OrderRates[item.choice_price.currency])) * Number(OrderRates[userCurrency()])).toFixed(2)*/}
                                        {/*//         }*/}
                                        {/*//         displayType={"text"}*/}
                                        {/*//         thousandSeparator={true}*/}
                                        {/*//         renderText={(value) => (*/}
                                        {/*//             <div>*/}
                                        {/*//                 {userCurrency()}{" "}{value}*/}
                                        {/*//                 /!*{product.wholesale_currency} {value}*!/*/}
                                        {/*//             </div>*/}
                                        {/*//         )}*/}
                                        {/*//     />*/}
                                        {/*// )*/}
                                        {/*// }*/}


                                    </td>
                                </tr>
                            ))}
                            </tbody>
                            <tfoot className="order-list__footer">
                            <tr>
                                <th className="order-list__column-label" colSpan="4">
                                    Total
                                </th>
                                <td></td>
                                <td></td>
                                <td className="order-list__column-total">
                                     <CurrencyFormat
                          value={order.total_amount}
                          displayType={"text"}
                          thousandSeparator={true}
                          renderText={(value) => (
                            <div>
                              {" "}
                              {userCurrency()} {""} {value}
                            </div>
                          )}
                        />
                                    {/*{userCurrency() === order.currency ? (*/}
                                    {/*    <CurrencyFormat*/}
                                    {/*        value={Math.round((Number(order.total_amount) / Number(OrderRates[order.currency])) * Number(OrderRates[userCurrency()]))*/}
                                    {/*        }*/}
                                    {/*        displayType={"text"}*/}
                                    {/*        thousandSeparator={true}*/}
                                    {/*        renderText={(value) => (*/}
                                    {/*            <div>*/}
                                    {/*                {userCurrency()}{" "}{value}*/}
                                    {/*                /!*{product.wholesale_currency} {value}*!/*/}
                                    {/*            </div>*/}
                                    {/*        )}*/}
                                    {/*    />*/}
                                    {/*) : (*/}
                                    {/*    <CurrencyFormat*/}
                                    {/*        value={((Number(order.total_amount) / Number(OrderRates[order.currency])) * Number(OrderRates[userCurrency()])).toFixed(2)*/}
                                    {/*        }*/}
                                    {/*        displayType={"text"}*/}
                                    {/*        thousandSeparator={true}*/}
                                    {/*        renderText={(value) => (*/}
                                    {/*            <div>*/}
                                    {/*                {userCurrency()}{" "}{value}*/}
                                    {/*                /!*{product.wholesale_currency} {value}*!/*/}
                                    {/*            </div>*/}
                                    {/*        )}*/}
                                    {/*    />*/}
                                    {/*)*/}
                                    {/*}*/}
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                        :
                        <table>
                            <thead className="order-list__header">
                            <tr>
                                <td className="order-list__column-label" colSpan="2">
                                    Product Image
                                </td>
                                <td className="order-list__column-label">Product name</td>
                                <td className="order-list__column-quantity">Qty</td>
                                <td className="order-list__column-total">Sub Total</td>
                            </tr>
                            </thead>
                            <tbody className="h-10 overflow-auto w-auto order-list__products">
                            {JSON.parse(order.cart_details).map((item) => (
                                <tr>
                                    <td colSpan="2" className="order-list__column-image">
                                        <div className="product-image">
                                            <div className="product-image__body">
                                                <img
                                                    className="product-image__img"
                                                    src={item.product_images.productImage_name}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    <td className="order-list__column-product">
                                        <div className="order-list__options">
                                            <ul className="order-list__options-list ">
                                                {item.product.product_name}
                                            </ul>
                                        </div>
                                    </td>

                                    <td
                                        className="order-list__column-quantity"
                                        data-title="Qty:"
                                    >
                                        {item.quantity}
                                    </td>
                                    <td className="order-list__column-total">
                                        <CurrencyFormat
                                            value={item.total_amount}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                            renderText={(value) => (
                                                <div>
                                                    {" "}
                                                    {item.currency} {"  "} {value}
                                                </div>
                                            )}
                                        />
                                    </td>
                                    <hr/>
                                </tr>
                            ))}
                            </tbody>
                            <tfoot className="order-list__footer">
                            <tr>
                                <th className="order-list__column-label" colSpan="3">
                                    Total
                                </th>
                                <td></td>
                                <td className="order-list__column-total">
                                    <CurrencyFormat
                                        value={order.total_amount}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        renderText={(value) => (
                                            <div>
                                                {" "}
                                                {"TZS"} {""} {value}
                                            </div>
                                        )}
                                    />
                                </td>
                            </tr>
                            </tfoot>
                        </table>
                    }
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
export default ShopPageOrderSuccess;
