// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";

// data stubs
import theme from "../../data/theme";

export default function AccountPageEditAddress() {
  return (
    <div className="card">
      <Helmet>
        <title>{`Edit Address — ${theme.name}`}</title>
      </Helmet>
      <div className="card-header">
        <h5>Create Group</h5>
      </div>
      <div className="card-divider" />
      <div className="card-body">
        <div className="row no-gutters d-flex justify-content-center">
          <div className="col-12 col-lg-10 col-xl-8">
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="checkout-first-name">Group Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="checkout-first-name"
                  placeholder="Group Name"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="checkout-last-name">Group Type</label>
                <select
                  id="checkout-country"
                  className="form-control form-control-select2"
                >
                  <option>Select a Group Type.....</option>
                  <option>Flash</option>
                  <option>Permanent</option>
                </select>
              </div>
            </div>
            <div className="form-group mt-3 mb-0">
              <button
                className="btn btn-primary shadow-lg rounded-lg form-control"
                type="button"
              >
                Create Group
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
