import React from 'react';
import {FaRegCalendarAlt} from 'react-icons/fa'
import '../../../assets/css/style.css'
import {useFormik} from "formik";
import * as yup from "yup";
import {connect} from "react-redux";
import {AddBooking} from "../../../store/booking/DirectoryActions";
import AsyncAction from "../../shared/AsyncAction";
import Button from "../bookmarkButton";

const validationSchema = yup.object().shape({
    start_date: yup
        .string()
        .required("This field is required"),
    end_date: yup
        .string()
        .required("This field is required"),
    start_time: yup
        .string()
        .required("This field is required"),
    end_time: yup
        .string()
        .required("This field is required"),
    adult: yup
        .string()
        .required("This field is required"),
    child: yup
        .string()
        .required("This field is required"),
    comment: yup
        .string()
        .required("This field is required"),

});

function WidgetBooking(props) {
    const {Booking,AddBooking} = props
    // console.log(Booking)
    //using useFormik
    const formik = useFormik({
        initialValues: {
            listing_id: Booking.listing.id,
            start_date: "",
            end_date: "",
            start_time:"",
            end_time:"",
            adult: "",
            child: "",
            comment:""
        },
        validationSchema,

    });

    return (
        <>
            <div className="sidebar-widget date-widget">
                <h3 className="widget-title">
                    Booking
                </h3>
                <div className="title-shape"></div>
                <div className="contact-form-action padding-top-35px">
                    <form>
                        <div className="form-group">
                                <span className="form-icon">
                                    <FaRegCalendarAlt/>
                                </span>
                            <input
                                className="date-range form-control"
                                onChange={formik.handleChange}
                                type="date"
                                id="start_date"
                                name="start_date"
                                onBlur={formik.handleBlur}
                                value={formik.values.start_date}
                            />
                            {formik.touched.start_date && formik.errors.start_date ? (
                                <div className="text-danger">{formik.errors.start_date}</div>
                            ) : null}
                        </div>
                        <div className="form-group">
                                <span className="form-icon">
                                    <FaRegCalendarAlt/>
                                </span>
                            <input
                                className="date-range form-control"
                                onChange={formik.handleChange}
                                type="date"
                                id="end_date"
                                name="end_date"
                                onBlur={formik.handleBlur}
                                value={formik.values.end_date}
                            />
                            {formik.touched.end_date && formik.errors.end_date ? (
                                <div className="text-danger">{formik.errors.end_date}</div>
                            ) : null}
                        </div>

                    </form>
                </div>
                <div className="mt-4 booking-content d-flex align-items-center justify-content-between text-center">
                <div className="input-number-group">
                    <p>Time start</p>
                    <input
                        className="input-number"
                        type="time"
                        name="start_time"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.start_time}
                    />
                    {formik.touched.start_time && formik.errors.start_time ? (
                        <div className="text-danger">{formik.errors.start_time}</div>
                    ) : null}
                </div>
                <div className="input-number-group">
                    <p>Time End</p>
                    <input
                        className="input-number"
                        type="time"
                        name="end_time"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.end_time}
                    />
                    {formik.touched.end_time && formik.errors.end_time ? (
                        <div className="text-danger">{formik.errors.end_time}</div>
                    ) : null}

                </div>
            </div>

                <div className="booking-content d-flex align-items-center justify-content-between text-center mt-4">
                    <div className="input-number-group">
                        <p>Adults</p>
                        <input
                            className="input-number"
                            type="number"
                            min="1"
                            max="1000"
                            name="adult"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.adult}
                        />
                        {formik.touched.adult && formik.errors.adult ? (
                            <div className="text-danger">{formik.errors.adult}</div>
                        ) : null}
                    </div>
                    <div className="input-number-group">
                        <p>Children</p>
                        <input
                            className="input-number"
                            type="number"
                            min="1"
                            max="1000"
                            name="child"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.child}
                        />
                        {formik.touched.child && formik.errors.child ? (
                            <div className="text-danger">{formik.errors.child}</div>
                        ) : null}
                   </div>

                </div>
                <div className="form-group mt-3">
                <label htmlFor="inputCity">Comment</label>
                <textarea
                    id="comment"
                    name="comment"
                    className="form-control"
                    value={formik.values.comment}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                {formik.touched.comment && formik.errors.comment ? (
                    <div className="text-danger">{formik.errors.comment}</div>
                ) : null}
            </div>
              <div className="mt-5">
                    <div className="booking-content d-flex align-items-center justify-content-between text-center">
                        <div className="input-number-group">
                            {/*<AsyncAction*/}
                            {/*    action={() => AddBooking(formik.values)}*/}
                            {/*    render={({run, loading}) => (*/}
                                <button
                                    type="button"
                                    onClick={()=>AddBooking(formik.values)}
                                    className={"btn btn-block btn-primary rounded "}
                                    disabled={!(formik.isValid && formik.dirty)}
                                >
                                    Book Now
                                </button>
                            {/*)}*/}
                            {/*    />*/}
                        </div>
                        <div className="input-number-group">
                            <Button id={Booking.listing.id}/>
                        </div>
                    </div>

               </div>
            </div>
        </>
    );
}
const mapStateToProps = (state) => ({
    // shippingAddress: state.shippingAddress,
});
const mapDispatchToProps = {
    AddBooking
};
export default connect(mapStateToProps, mapDispatchToProps)(WidgetBooking);
