
import {LEADS_DATA} from "./LeadsActionTypes";
export default function LeadsReducer(state=[],action) {
    switch(action.type){
        case LEADS_DATA:
            return action.payload
        default:
            return state;
    }
}
