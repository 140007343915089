import React from "react";
import {Helmet} from "react-helmet-async";
import theme from "../../../data/theme";
import {connect} from "react-redux";
import ReactPlayer from "react-player/youtube";
import ListingAction from "./ListingAction";

function ListingBasicInfo(props) {
    const {ListingInfo}=props
    return (
        <React.Fragment>
            <Helmet>
                <title>{`listing action — ${theme.name}`}</title>
            </Helmet>
            <div className="container-fluid mt-5">
                <div className="row">
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="inputCity" className="font-size-18 font-weight-bold">Title</label>
                            <p>{ListingInfo.listing.listing_title}</p>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="inputCity" className="font-size-18 font-weight-bold">Slug</label>
                            <p>{ListingInfo.listing.slug}</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="inputCity" className="font-size-18 font-weight-bold">Status</label>
                            <p>
                                {ListingInfo.listing.is_published?
                                    <span className="text-success">
                                        Published
                                    </span>
                                    :
                                    <span className="text-danger">
                                        Not Published
                                    </span>
                                }

                            </p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="inputCity" className="font-size-18 font-weight-bold">Description</label>
                            <p>{ListingInfo.listing.listing_description}</p> </div>
                    </div>
                    <div className="col-md-3">
                        <div className="form-group">
                            <label htmlFor="inputCity" className="font-size-18 font-weight-bold">Approval</label>
                            <p>
                                {ListingInfo.is_approved?
                                    <span className="text-success">
                                        Approved
                                    </span>
                                    :
                                    <span className="text-danger">
                                        Not Approved
                                    </span>
                                }

                            </p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="inputCity" className="font-size-18 font-weight-bold">Video</label>
                            <ReactPlayer
                                url={ListingInfo.listing.video_url}
                                playing={false}
                            />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <ListingAction listingData={ListingInfo}/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListingBasicInfo)
