// react
import React, { Component } from "react";
// third-party

import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
// import CurrencyFormat from "react-currency-format";
import AsyncAction from "../shared/AsyncAction";
import { Cross12Svg } from "../../svg";
import InputNumber from "../shared/InputNumber";
import { url } from "../../services/utils";
import classNames from "classnames";
import { quoteRemoveItem, postQuote } from "../../store/quote";
import { cartUpdateQuantities } from "../../store/cart";
import { handleBacks } from "./QouteSteps";
// data stubs
import theme from "../../data/theme";
import { agenttoken, customertoken, agentcustomerToken } from "../../api/shop";
import { Row } from "reactstrap";

class ShopPageCart extends Component {
  constructor(props) {
    super(props);
    this.modules = {
      toolbar: [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["clean"],
      ],
    };

    this.formats = [
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "list",
      "bullet",
      "align",
      "color",
      "background",
    ];
    this.rteChange = this.rteChange.bind(this);
    this.state = {
      quantities: [],
      shippingOption: [],
      deliveryoption: false,
      showing: true,
      comments: "",
    };
  }
  toggleDelivery(id) {
    this.setState({
      deliveryoption: true,
    });
  }


  rteChange = (content, delta, source, editor) => {
    //console.log(editor.getHTML()); // rich text
    //console.log(editor.getText()); // plain text
    //console.log(editor.getLength()); // number of characters
  };
  toggle(id) {
    this.setState({
      deliveryoption: localStorage.getItem("deliveryOptions"),
    });
  }

  componentDidMount() {
    fetch(window.$name + "/api/adminstrator/get-customer-shipping")
      .then((response) => response.json())
      .then((datalist) => this.setState({ shippingOption: datalist }));
  }

  getItemQuantity(item) {
    const { quantities } = this.state;
    const quantity = quantities.find((x) => x.itemId === item.id);
    return quantity ? quantity.value : item.quantity;
  }

  handleChangeQuantity = (item, quantity) => {
    this.setState((state) => {
      const stateQuantity = state.quantities.find(
        (x) =>
          x.itemId === item.id && x.product_token === item.product.product_token
      );
      if (!stateQuantity) {
        state.quantities.push({
          itemId: item.id,
          value: quantity,
          product_token: item.product.product_token,
        });
      } else {
        stateQuantity.value = quantity;
      }
      return {
        quantities: state.quantities,
      };
    });
  };

  cartNeedUpdate() {
    const { quantities } = this.state;
    const { Quotation } = this.props;
    return (
      quantities.filter((x) => {
        const item = Quotation.items.find((item) => item.id === x.itemId);
        return item && item.quantity !== x.value && x.value !== "";
      }).length > 0
    );
  }

  renderItems() {
    const { Quotation, quoteRemoveItem } = this.props;
    // console.log(Quotation.items);
    return Quotation.items.map((item) => {
      let image;
      let options;

      if (item.product.images.length > 0) {
        image = (
          <div className="product-image">
            <Link
              to={url.product(item.product)}
              className="product-image__body"
            >
              <img
                className="product-image__img"
                src={item.product.images}
                alt=""
              />
            </Link>
          </div>
        );
      }

      if (item.options.length > 0) {
        options = (
          <ul className="cart-table__options">
            {item.options.map((option, index) => (
              <li
                key={index}
              >{`${option.optionTitle}: ${option.valueTitle}`}</li>
            ))}
          </ul>
        );
      }

      const removeButton = (
        <AsyncAction
          action={() => quoteRemoveItem(item.id, item.product.product_token)}
          render={({ run, loading }) => {
            const classes = classNames("btn btn-light btn-sm btn-svg-icon", {
              "btn-loading": loading,
            });
            return (
              <button type="button" onClick={run} className={classes}>
                <Cross12Svg />
              </button>
            );
          }}
        />
      );

      return (
        <tr key={item.id} className="cart-table__row">
          <td className="cart-table__column cart-table__column--image">
            {image}
          </td>
          <td className="cart-table__column cart-table__column--quantity">
            {item.product.preference_color !== "" && item.product.preference_size !== "" ? (
              <>
                <Row>
                  Color :&nbsp;&nbsp; {item.product.preference_color}
                </Row>
                <Row>
                  Size :&nbsp;&nbsp;   {item.product.preference_size}
                </Row>
              </>
            ) : item.product.preference_color !== "" && item.product.preference_size === "" ? (
              <Row>
                Color :&nbsp;&nbsp; {item.product.preference_color}
              </Row>
            ) : item.product.preference_color === "" && item.product.preference_size !== "" ? (
              <Row>
                Size :&nbsp;&nbsp; {item.product.preference_size}
              </Row>
            ) :
                  <span>Empty</span>
            }
          </td>

          <td className="cart-table__column cart-table__column--price">
            {item.product.name}

            {options}
          </td>
          <td
            className="cart-table__column cart-table__column--quantity"
            data-title="Quantity"
          >
            <InputNumber
              onChange={(quantity) => this.handleChangeQuantity(item, quantity)}
              value={this.getItemQuantity(item)}
              min={1}
            />
          </td>
          <td className="cart-table__column cart-table__column--remove">
            {removeButton}
          </td>
        </tr>
      );
    });
  }
  renderTotals() {
    const { Quotation } = this.props;
    if (Quotation.extraLines.length <= 0) {
      return null;
    }
    return (
      <React.Fragment>
        <thead className="cart__totals-header">
          <tr>
            <th>TOTAL PRICE</th>
            <td></td>
          </tr>
        </thead>
        <tbody className="cart__totals-body">{/* {extraLines} */}</tbody>
      </React.Fragment>
    );
  }
  renderCart() {
    const { Quotation, quoteRemoveItem, activeStep, shipping, MyownShipping, Myownaddress, } = this.props;
    // console.log(activeStep)
    const { shippingOption } = this.state;
    // const { deliveryoption } = this.state;
    // const { showing } = this.state;

    function handleChange(data) {
      localStorage.setItem("deliveryOptions", data.secure_token);
      return data;
    }
    // localStorage.getItem("deliveryOptions");
    return (
      <div className="cart block">
        <div className="container">
          {
            MyownShipping.data === "I will arrange Delivery" ? (
              <div>
                <h3> Pickup information</h3>
                {Myownaddress.map((address) => (
                  <div>
                    {address.is_active ? (
                      <div>
                        <p>
                          Full Name :   &nbsp;&nbsp;{address.address_fullname}
                        </p>
                        <p>
                          Mobile :  &nbsp;&nbsp;{address.address_mobileNumber}
                        </p>
                        <p>
                          Country :  &nbsp;{address.country.country}
                        </p>
                        <p>
                          Region:  &nbsp;&nbsp;{address.region.region}
                        </p>
                        <p>
                          District:  &nbsp;&nbsp;{address.address_district}
                        </p>
                        <p>
                          Area:  &nbsp;&nbsp;  &nbsp;&nbsp;{address.area}
                        </p>
                        <p>Id Type :  &nbsp;&nbsp;{address.id_type}
                        </p>
                        <p>
                          Id Number :  &nbsp;&nbsp;{address.id_number}
                        </p>                                              
                      </div>
                    ) : (
                        <div />
                      )}
                  </div>
                ))}
              </div>
            ) : (
                <div>
                  <p className="font-weight-bold">Shipping Address to:</p>
                  {shipping.map((address) => (
                    <div>
                      {address.is_active ? (
                        <div>
                          <p>
                            <span className="text-uppercase">
                              {address.address_fullname}
                            </span>
                          </p>
                          <p>
                            {address.address_mobileNumber}
                                                or
                                                {" "}
                            {address.address_mobileNumber2}
                          </p>
                          <p className="text-capitalize">
                            {address.country.country}, {address.region.region},{" "}
                            {address.address_district}
                          </p>
                          <p>Post Code : {address.address_postcode} </p>
                          <p>House Number : {address.address_house_number}</p>
                          <p>Area Name : {address.area}</p>
                          <p>Address Type : {address.address_AddressType}</p>                       
                        </div>
                      ) : (
                          <div />
                        )}
                    </div>
                  ))}
                </div>

              )
          }
          <br />
          <table className="cart__table cart-table">
            <thead className="cart-table__head">
              <tr className="cart-table__row">
                <th className="cart-table__column cart-table__column">
                  Product Image
                </th>
                <th className="cart-table__column cart-table__column--price">
                  Preference
                </th>
                <th className="cart-table__column cart-table__column--price">
                  Product Name
                </th>
                <th className="cart-table__column cart-table__column--quantity">
                  Quantity
                </th>
                <th
                  className="cart-table__column cart-table__column--remove"
                  aria-label="Remove"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="cart-table__body">{this.renderItems()}</tbody>
          </table>
          <br />
          <div className="row">
            <div className="col d-flex justify-content-start">
              <button
                className="btn btn-secondary"
                onClick={() => handleBacks(activeStep)}
              >
                {" "}
                <i className="fa fa-backward text-black"> </i> Previous
                        </button>
            </div>
            <div className="col d-flex justify-content-end">
              {
                MyownShipping.data === "I will arrange Delivery" ? (
                  <>
                    {Myownaddress.map((address) => (
                      <div>
                        {address.is_active ? (
                          <button
                            disabled={!localStorage.getItem("deliveryOptions")}
                            onClick={postQuote(Quotation, address.secure_token)}
                            type="submit"
                            className="btn btn-warning"
                          >
                            SUBMIT QOUTE
                          </button>
                        ) : (
                            <div />
                          )}
                      </div>
                    ))}
                  </>
                ) : (
                    <>
                      {shipping.map((address) => (
                        <div>
                          {address.is_active ? (
                            <button
                              disabled={!localStorage.getItem("deliveryOptions")}
                              onClick={postQuote(Quotation, address.secure_token)}
                              type="submit"
                              className="btn btn-warning"
                            >
                              SUBMIT QOUTE
                            </button>
                          ) : (
                              <div />
                            )}
                        </div>
                      ))}
                    </>
                  )
              }

            </div>

          </div>
        </div>
      </div>
    );
  }
  render() {
    const { Quotation } = this.props;
    let content;
    if (Quotation.quantity) {
      content = this.renderCart();
    } else {
      content = (
        <div className="block block-empty">
          <div className="container">
            <div className="block-empty__body">
              <div className="block-empty__message">
                Your shopping Quote is empty!
              </div>
              <div className="block-empty__actions">
                <Link to="/" className="btn btn-primary btn-sm">
                  Continue
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>{`qoutation — ${theme.name}`}</title>
        </Helmet>

        {content}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  Quotation: state.quotation,
  shipping: state.shippingAddress.user_address,
  Myownaddress: state.Myownaddresss,
  MyownShipping: state.MyownShipping,
});

const mapDispatchToProps = {
  quoteRemoveItem,
  cartUpdateQuantities,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCart);
