// react
import React, {useEffect, useMemo, useState} from "react";
import axios from "axios";
// third-party
import {Helmet} from "react-helmet-async";
import theme from "../../../data/theme";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {connect, useDispatch, useSelector} from "react-redux";
import AddListing from "./add_listing";
import AvailableListing from "./availableLIstings";
import {customertoken} from "../../../api/shop";
import {FetchListing} from "../../../store/Listings";
import DeletedListing from "./DeletedListings";
import {FetchDeleteListing} from "../../../store/DeleteLitsting";
import {FetchListingBookingApprove} from '../../../store/ListingApprove';
import BookingListingApprove from './ApproveBooking';
import {Link} from "react-router-dom";
import {FetchUserListing} from "../../../store/UserListing";
import PromotedListing from "./PromotedListing";

function AccountPageListing(props) {
    const dispatch = useDispatch();
    useEffect(() => {
        if (customertoken()) {
            dispatch(FetchListing());
            dispatch(FetchDeleteListing())
            dispatch(FetchListingBookingApprove())
            dispatch(FetchUserListing())
        }
    }, [dispatch]);
    const DataList = useSelector((state) => state.ListingData);
    const DeletedData = useSelector((state) => state.DeleteListing);
    const UserlistingData = useSelector((state) => state.UserListingData);
    const ApproveBookingData = useSelector((state) => state.BookingApprove)
    const [ListingValue, setListingValue] = useState([]);
    const [ListingDeleted, setListingDeleted] = useState([]);
    const [ApproveBookingAvailable, setApproveBooking] = useState([]);
    const [UserListingValue, setUserListing] = useState([]);
    useEffect(() => {
        setListingValue(DataList);
    }, [DataList]);
    useEffect(() => {
        setListingDeleted(DeletedData)
    }, [DeletedData]);
    useEffect(() => {
        setApproveBooking(ApproveBookingData)
    }, [ApproveBookingData]);
    useEffect(() => {
        setUserListing(UserlistingData)
    }, [UserlistingData]);
    return (
        <React.Fragment>
            <Helmet>
                <title>{`Listings — ${theme.name}`}</title>
            </Helmet>
            <div>
                {ListingValue.length === 0 ?
                    <div className="text-center">
                        <div className="justify-content-center">
                            <p className="font-weight-bold font-size-23">
                                NO LISTING AVAILABLE
                            </p>
                            <Link to="add-listing">
                                <button className="btn btn-sm btn-outline-dark text-center font-size-19 rounded-pill">
                                    Add listing
                                </button>
                            </Link>
                        </div>
                    </div>
                    :
                    <>
                        <div className="d-flex justify-content-end">
                            <Link className="text-success" to="/account/add-listing">
                                <button className="btn-sm btn-outline-dark rounded-pill text-black font-size-15">
                                    Add New Listing
                                </button>

                            </Link>
                        </div>
                        <div className="card mt-3">
                            <Tabs>
                                <TabList>
                                    {/*<Tab>*/}
                                    {/*    <span className="text-primary  font-size-20">Add Listing</span>*/}
                                    {/*</Tab>*/}
                                    <Tab>
                                        <span
                                            className="text-dark font-weight-bold  font-size-18">Available Listings</span>
                                    </Tab>
                                    <Tab>
                                        <span
                                            className="text-dark font-weight-bold  font-size-18">Deleted Listings</span>
                                    </Tab>
                                    <Tab>
                                        <span className="text-dark font-weight-bold  font-size-18">Booking</span>
                                    </Tab>
                                    <Tab>
                                        <span className="text-dark font-weight-bold  font-size-18">Promoted</span>
                                    </Tab>
                                </TabList>
                                <TabPanel>
                                    <AvailableListing ListingValue={ListingValue}/>
                                </TabPanel>
                                <TabPanel>
                                    <DeletedListing ListingDeleted={ListingDeleted}/>
                                </TabPanel>
                                <TabPanel>
                                    <BookingListingApprove BookingApprove={ApproveBookingAvailable}/>
                                </TabPanel>
                                <TabPanel>
                                    <PromotedListing ListingValue={UserListingValue}/>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </>

                }
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPageListing)