import React, {useEffect} from 'react';
import ListingDetailsSidebar from "./ListingDetailsSidebar";
import ListingDetailsGallery from "./ListingDetailsGallery";
import {Spinner} from 'reactstrap';
import ReactPlayer from 'react-player/youtube'
import '../../assets/css/style.css'
import Axios from "axios";
import Listingbreadcrumb from "./Listingbreadcrumb";
import Related from "./relatetedListing";
import CustomerFeedback from "./widgets/CustomerFeedback";
import ListingDetailsComments from "./contact/ListingDetailsComments";
import ReviewFields from "./contact/ReviewFields";
import {customertoken} from '../../api/digitalApi';
import {agentcustomerToken} from '../../api/shop';
import CurrencyFormat from "react-currency-format";
import {Link} from "react-router-dom";
import ListingMap from "./googleMap"


function ListingDetails(props) {
    const {Token, Url} = props;
    const ListingToken = Token
    const [ListingsData, setListings] = React.useState([]);
    useEffect(() => {
        const dataAvailable = async () => {
            const data = await Axios.get(`${window.$name}/api/directory/listings/details/${Url}`)
                .then((response) => response.data);
            setListings(data);
        };
        dataAvailable();
    }, []);
    console.log("ListingsData",ListingsData)
    return (
        <main className="listing-details">
            {ListingsData.length <= 0 ?
                <div className="text-center" style={{
                    marginTop: '10%',
                    marginLeft: '50%',
                    marginRight: '50%'
                }}>
                    <Spinner style={{width: '3rem', height: '3rem'}}/>
                </div>
                :
                <>
                    <Listingbreadcrumb title={ListingsData.listing} location={ListingsData.listing_locations}
                                       image={ListingsData.listing_images}/>
                    <section className="single-listing-area padding-top-35px">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="single-listing-wrap">
                                        <div className="listing-description padding-top-40px padding-bottom-35px">
                                            <h2 className="widget-title">
                                                Description
                                            </h2>
                                            <div className="title-shape"></div>
                                            <div className="section-heading mt-4">
                                                <p className="sec__desc font-size-16">
                                                    {ListingsData.listing.listing_description}
                                                </p>
                                            </div>
                                        </div>

                                        <ListingDetailsGallery images={ListingsData.listing_images}/>

                                        <div className="feature-listing padding-bottom-20px">
                                            <h2 className="widget-title">
                                                Services
                                            </h2>
                                            <div className="title-shape"></div>
                                            <ul className="list-items mt-4">
                                                {ListingsData.listing.services.map((item, i) => {
                                                    return (
                                                        <div>
                                                         <Link  to={`/service/product/${item.token}/${item.listing_categories_id}`}>
                                                            <li key={item.id}>
                                                                <i className="text-warning fa fa-check-circle font-size-18"></i> {item.product_name}
                                                            </li>
                                                            </Link>
                                                        </div>
                                                    )
                                                })}
                                                    </ul>
                                                    </div>
                                                    <div className="listing-map gmaps">
                                                    <h2 className="widget-title">
                                                    Location
                                                    </h2>
                                                    <div className="title-shape margin-bottom-35px">
                                                    </div>
                                                    <div className="row">
                                                    <div
                                                    className="col"> Country {" "} : {ListingsData.listing_locations.country}</div>
                                                    <div
                                                    className="col"> Region {" "} : {ListingsData.listing_locations.region}</div>
                                                    <div
                                                    className="col"> District {" "} : {ListingsData.listing_locations.district}</div>
                                                    </div>
                                                    </div>
                                                {/*<ContactInfo />*/}
                                                    <br/>
                                                    <div className="video-listing padding-bottom-40px">
                                                    <h2 className="widget-title">
                                                    Listing video
                                                    </h2>
                                                    <div className="title-shape"></div>
                                                    <div className=" margin-top-35px text-center">
                                                    <ReactPlayer url={ListingsData.listing.video_url} widith="100%"/>
                                                    </div>
                                                    </div>
                                                    <CustomerFeedback/>

                                                    {/*<div className="comments-wrap">*/}
                                                    {/*<h2 className="widget-title">*/}
                                                    {/*2 Reviews*/}
                                                    {/*</h2>*/}
                                                    {/*<div className="title-shape"></div>*/}
                                                    {/*<ListingDetailsComments/>*/}
                                                    {/*</div>*/}
                                                {
                                                    customertoken() || agentcustomerToken() ?
                                                    <ReviewFields id={ListingsData.listing.id}/>
                                                    :
                                                    null
                                                }

                                                    </div>
                                                    </div>
                                                    <div className="col-lg-4 mt-5">
                                                    <ListingDetailsSidebar data={ListingsData}/>
                                                    <br />
                                                    {ListingsData.listing_locations.latitude || ListingsData.listing_locations.latitude ? (
                                                           <ListingMap data={ListingsData} />
                                                    ):(
                                                             <div />
                                                    )}
                                                    </div>
                                                    </div>
                                                    </div>

                                                    </section>
                                                    <section className="card-area padding-top-80px padding-bottom-100px">
                                                    <div className="container">
                                                    <div className="row">
                                                    <div className="col-lg-12">
                                                    <div className="section-heading">
                                                    <h2 className="widget-title">
                                                    Related Listings
                                                    </h2>
                                                    <div className="title-shape"></div>
                                                    </div>
                                                    </div>
                                                    </div>
                                                    <Related data={ListingsData.related_listings}/>
                                                    </div>
                                                    </section>
                                                    </>
                                                }

                                            </main>
                                            );

                                            }

export default ListingDetails;
