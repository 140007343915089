import React from 'react';
import {IoMdStar, IoMdStarHalf} from 'react-icons/io'
import { FiHeart } from 'react-icons/fi'
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {Link} from "react-router-dom";

function  PromotedListing (props) {
    const {data}=props
const  responsive = [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
        return (
            <div className="mt-4">
                <h5 className="text-sm-left  font-size-20">
                    Promoted Listings
                </h5>
                <div className="row mt-3">
                    {
                        data.length <4 ?
                            <div className="col-lg-3 mt-3">
                                <div>
                                    {data.map((item, index) => {
                                        return (
                                            <div className="card-item  bg-light" key={index}>
                                                <Link to={`/listing-details/${item.listing.url_head}`}>
                                                    <a href="" className="card-image-wrap">
                                                        <div className="card-image">
                                                            <img src={item.listing.image[0].image_url} className="card__img" alt="Place" />
                                                            {/* <span className="badge">promoted</span> */}
                                                        </div>
                                                    </a>
                                                    <div className="card-content-wrap">
                                                        <div className="card-content">
                                                            <Link to={`/listing-details/${item.listing.url_head}`}>
                                                                <span className="badge text-black  text-capitalize"> {item.listing.slug}</span>
                                                                <h5 className=" text-capitalize text-muted" style={{color:'black'}}>
                                                                    {item.listing.listing_title.length > 26
                                                                        ? `${item.listing.listing_title.substring(0, 26)}...`
                                                                        : item.listing.listing_title}
                                                                </h5>
                                                                {/* <span  className="card-sub">
                                                            {item.listing.listing_description.length > 90
                                                                ? `${item.listing.listing_description.substring(0, 90)}...`
                                                                : item.listing.listing_description}
                                                        </span> */}
                                                            </Link>
                                                            <a href="" className="author-img">
                                                                <img src={item.listing.image[0].image_url} alt="author-img"/>
                                                            </a>
                                                        </div>
                                                        {/* <div className="rating-row">
                                                            <div className="rating-rating">
                                                                {  [<IoMdStar/>,
                                                                    <IoMdStar/>,
                                                                    <IoMdStar/>,
                                                                    <IoMdStarHalf/>,
                                                                    <IoMdStar className="last-star"/>,
                                                                ].map((rating, index) => {
                                                                    return (
                                                                        <span key={index}>{rating}</span>
                                                                    )
                                                                })}
                                                                <span className="rating-count">{item.ratingNum}</span>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                            :
                            <div className="col-lg-12 mt-3">
                                <Slider className="places-carousel" dots={false} infinite={true} slidesToScroll={2} arrows={true} slidesToShow={4} centerMode={false} centerPadding="50px" autoplay={true} responsive={responsive}>
                                    {data.map((item, index) => {
                                        return (
                                            <div className="card-item  bg-light" key={index}>
                                                <Link to={`/listing-details/${item.listing.url_head}`}>
                                                    <a href="" className="card-image-wrap">
                                                        <div className="card-image">
                                                            <img src={item.listing.image[0].image_url} className="card__img" alt="Place" />
                                                            <span className="badge">promoted</span>
                                                        </div>
                                                    </a>
                                                    <div className="card-content-wrap">
                                                        <div className="card-content">
                                                            <Link to={`/listing-details/${item.listing.url_head}`}>
                                                                <span className="badge text-black  text-capitalize"> {item.listing.slug}</span>
                                                                <h5 className=" text-capitalize text-muted" style={{color:'black'}}>
                                                                    {item.listing.listing_title.length > 26
                                                                        ? `${item.listing.listing_title.substring(0, 26)}...`
                                                                        : item.listing.listing_title}
                                                                </h5>
                                                                <span  className="card-sub">
                                                            {item.listing.listing_description.length > 90
                                                                ? `${item.listing.listing_description.substring(0, 90)}...`
                                                                : item.listing.listing_description}
                                                        </span>
                                                            </Link>
                                                            <a href="" className="author-img">
                                                                <img src={item.listing.image[0].image_url} alt="author-img"/>
                                                            </a>
                                                        </div>
                                                        <div className="rating-row">
                                                            <div className="rating-rating">
                                                                {  [<IoMdStar/>,
                                                                    <IoMdStar/>,
                                                                    <IoMdStar/>,
                                                                    <IoMdStarHalf/>,
                                                                    <IoMdStar className="last-star"/>,
                                                                ].map((rating, index) => {
                                                                    return (
                                                                        <span key={index}>{rating}</span>
                                                                    )
                                                                })}
                                                                <span className="rating-count">{item.ratingNum}</span>
                                                            </div>
                                                            <div className="listing-info">
                                                                <ul>
                                                                    <li><span className="info__count"><FiHeart /></span> 115</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </Slider>
                            </div>
                    }

                </div>

            </div>
       );
}

export default PromotedListing;