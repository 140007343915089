import React, { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

const isEmail = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
// Destructure props
const FirstStep = ({
  handleNext,
  handleChange,
  values: { BusinessName, TIN, License,Licensefile,veoLetter },
  filedError,
  isError,
}) => {
  // Check if all values are not empty
  const isEmpty = BusinessName.length > 0 && License.length > 0 && TIN.length > 0;

  return (
    <Fragment>
      <Grid container spacing={2} className="justify-content-center" noValidate>
      <div className="form-row ">
          <div className="form-group col-md-4">
          <TextField
              fullWidth
              size="small"
              label="Business Name"
              variant="outlined"
              name="BusinessName"
              placeholder="Your Business Name"
              defaultValue={BusinessName}
              onChange={handleChange("BusinessName")}
              margin="normal"
              error={filedError.BusinessName !== ""}
              helperText={
                filedError.BusinessName !== ""
                  ? `${filedError.BusinessName}`
                  : ""
              }
              required
            />
          </div>
          <div className="form-group col-md-4">
          <TextField
              fullWidth
              size="small"
              label="TIN"
              variant="outlined"
              name="TIN"
              placeholder="Your last name"
              defaultValue={TIN}
              onChange={handleChange("TIN")}
              margin="normal"
              error={filedError.TIN !== ""}
              helperText={filedError.TIN !== "" ? `${filedError.TIN}` : ""}
              required
            />
          </div>

          <div className="form-group col-md-4">
          <TextField
              fullWidth
              size="small"
              label="License Number"
              variant="outlined"
              name="License"
              placeholder="Your last name"
              defaultValue={License}
              onChange={handleChange("License")}
              margin="normal"
              error={filedError.License !== ""}
              helperText={
                filedError.License !== "" ? `${filedError.License}` : ""
              }
              required
            />
          </div>
          {/* <div className="form-group col-md-6">
       
          </div>
          <div className="form-group col-md-6">
            
          </div> */}
        </div>
      
      </Grid>
      <div
        style={{ display: "flex", marginTop: 50, justifyContent: "flex-end" }}
      >
        <Button
          variant="contained"
          disabled={!isEmpty || isError}
          color="primary"
          onClick={handleNext}
        >
          Next
        </Button>
      </div>
    </Fragment>
  );
};

export default FirstStep;
