import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet-async";
import theme from "../../../data/theme";
import {connect, useDispatch} from "react-redux";
import {Pagination, Search} from "../../DataTable";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import Axios from "axios";
import {FetchListing} from "../../../store/Listings";
import {FetchDeleteListing} from "../../../store/DeleteLitsting";
import * as yup from "yup";
import axios from "axios";
import {useFormik} from "formik";
import {toast} from "react-toastify";

const validationSchema = yup.object().shape({
    listing_plan_secure_token: yup
        .string()
        .required("This field is required"),
    listing_plan_price_secure_token: yup
        .string()
        .required("This field is required"),
});

function ListingPromote(props) {
    const dispatch = useDispatch();
    const {ListingValue} = props
    const [ListingId, setListingId] = useState("")
    const [ListingToken, setListingToken] = useState("")
    const [state, setState] = useState({
        listing_plan_secure_token: "",
        listing_plan_price_secure_token: "",
    });
    // console.log("ListingValue",ListingValue)
    const [plan, setPlan] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            const results = await axios
                .get(window.$name + "/api/directory/get/listings/plan")
                .then((response) => response.data.listing_plans);
            setPlan(results);
        };
        fetchData();
    }, []);

    const [planPrice, setPlanPrice] = useState([]);
    const GetPlanPrice = (e) => {
        const {id, value} = e.target;
        formik.values.listing_plan_secure_token = e.target.value;
        setState((prevState) => ({
            ...prevState,
            [id]: value,
        }));
        axios
            .get(window.$name + `/api/directory/get/listings/plan/price/${e.target.value}`)
            .then((res) => {
                setPlanPrice(res.data.plan_price);
            });
    };
    // console.log(ListingId)
    function DeleteListing() {
        Axios.post(`${window.$name}/api/directory/consumer/delete/listings/${ListingId}`)
            .then((response) => response.data);
        dispatch(FetchDeleteListing())
        dispatch(FetchListing())
    }
    //promote listings
    function PromoteListing() {
        const Data = {
            listing_plan_secure_token: formik.values.listing_plan_secure_token,
            listing_plan_price_secure_token: formik.values.listing_plan_price_secure_token
        }
        console.log(Data)
        Axios.post(`${window.$name}/api/directory/consumer/promote/listings/${ListingToken}`,
            Data
        )
            .then(function (response) {
                toast.success(`${response.data.message}`);
            })
            .catch(function (error) {
                if (error.response) {
                    toast.error("something is wrong");
                }
            });
    }
    const handleData = (id) => {
        setListingId(id);
    }
    //using useFormik
    const formik = useFormik({
        initialValues: {
            listing_plan_secure_token: "",
            listing_plan_price_secure_token: "",
        },
        validationSchema,
    });
    const Listings = (
        <>
            {ListingValue.map((index) => (
                <tr>
                    <td>{index.listing.listing_title}</td>
                    <td>{index.listing.slug}</td>
                    <td className="text-success">
                        {index.listing.is_published ?
                            <h6 className="text-success">
                                Published
                            </h6>
                            :
                            <h6 className="text-danger">
                                Not Published
                            </h6>
                        }
                    </td>
                    <td className="text-danger">
                        {index.listing.is_approved ?
                            <h6 className="text-success">
                                Approved
                            </h6>
                            :
                            <h6 className="text-danger">
                                Not Approved
                            </h6>
                        }
                    </td>
                    <td>
                        <Moment format="DD/MM/YYYY">{index.created_at}</Moment>
                    </td>
                    <td>
                        <div className="dropdown">
                            <button
                                className="btn btn-white"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <b>...</b>
                            </button>
                            <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                            >
                                <button
                                    className="dropdown-item text-danger"
                                    onClick={() => handleData(index.id, index.secure_token)}
                                    data-toggle="modal"
                                    data-target="#cancelModal2"
                                >
                                    Delete
                                </button>
                                <button
                                    onClick={() => setListingToken(index.secure_token)}
                                    type="button"
                                    className="dropdown-item"
                                    data-toggle="modal"
                                    data-target="#PromoteModal">
                                    Promote
                                </button>
                                <Link
                                    className="dropdown-item"
                                    to={{
                                        pathname: "/listing_detail_page",
                                        state: index.secure_token
                                    }}
                                >
                                    View more
                                </Link>

                            </div>
                        </div>
                    </td>
                </tr>
            ))
            }

        </>

    );
    return (
        <React.Fragment>
            <Helmet>
                <title>{`add listing — ${theme.name}`}</title>
            </Helmet>
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header">
                        <div className="d-flex align-items-end flex-column">
                            <div className="mt-auto p-2">
                                <Search
                                    // onSearch={(value) => {
                                    //     setSearch(value);
                                    //     setCurrentPage(1);
                                    // }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card-divider"/>
                    <div className="card-table">
                        <div className="table-responsive-sm">
                            <table>
                                <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Slug</th>
                                    <th>Status</th>
                                    <th>Approval</th>
                                    <th>Data</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>{Listings}</tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card-divider"/>
                    <div className="card-footer">
                        <div className="d-flex align-items-center flex-column">
                            <Pagination
                                // total={totalItems}
                                // itemsPerPage={ITEMS_PER_PAGE}
                                // currentPage={currentPage}
                                // onPageChange={(page) => setCurrentPage(page)}
                            />
                        </div>
                    </div>
                </div>
                {/*promote listing*/}
                <div className="modal fade" id="PromoteModal" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title text-center" id="exampleModalLongTitle">Promote Listing</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="inputCity">Plan</label>
                                            <select
                                                className="form-control"
                                                id="listing_plan_secure_token"
                                                name="listing_plan_secure_token"
                                                onChange={(e) => GetPlanPrice(e)}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.listing_plan_secure_token}
                                            >
                                                <option value="">select plan.....</option>
                                                {plan.map((index) => (
                                                    <option key={index.secure_token}
                                                            value={index.secure_token}>
                                                        {index.plan_name}
                                                    </option>
                                                ))}
                                            </select>
                                            {formik.touched.listing_plan_secure_token && formik.errors.listing_plan_secure_token ? (
                                                <div className="text-danger">
                                                    {formik.errors.listing_plan_secure_token}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="inputCity">Plan Price</label>
                                            <select
                                                onChange={formik.handleChange}
                                                id="listing_plan_price_secure_token"
                                                className="form-control"
                                                name="listing_plan_price_secure_token"
                                                onBlur={formik.handleBlur}
                                                value={formik.values.listing_plan_price_secure_token}
                                            >
                                                <option value="">Select a plan price...</option>
                                                {planPrice.map((index) => (
                                                    <option key={index.id}
                                                            value={index.secure_token}>
                                                        {index.plan_price}
                                                    </option>
                                                ))}
                                            </select>
                                            {formik.touched.listing_plan_price_secure_token && formik.errors.listing_plan_price_secure_token ? (
                                                <div
                                                    className="text-danger">{formik.errors.listing_plan_price_secure_token}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                    onClick={()=>PromoteListing()}
                                    disabled={!(formik.isValid && formik.dirty)}
                                    type="button"
                                    className="btn btn-primary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*delete listing*/}
                <div className="modal fade" id="cancelModal2" tabIndex="-1"
                     role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button type="button" className="close"
                                        data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body"> Are you sure want to Delete this Listing</div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close
                                </button>
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    onClick={DeleteListing}
                                    className="btn btn-danger">
                                    Accept
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListingPromote)
