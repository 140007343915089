import React, {useState} from "react";
import {Helmet} from "react-helmet-async";
import theme from "../../../data/theme";
import {connect, useDispatch} from "react-redux";
import {DropzoneArea} from "material-ui-dropzone";
import {customertoken} from "../../../api/shop";
import axios from "axios";
import {toast} from "react-toastify";
import {Fetchprofile} from "../../../store/profile";
import image from '../../qytgqv99qrglspfaufdh.webp'
import {FetchListingDetail} from "../../../store/ListingDetails";
import Axios from "axios";

function ListingImage(props) {
    const dispatch = useDispatch();
    const {listing_images, ListingToken} = props
    const [imageFiles, setImages] = useState([]);
    const [imageFile, setImage] = useState([]);
    const [imageId, setImageId] = useState([]);

    function handleImages(files) {
        setImage(files)
        setImages(files);
    }

    function uploadListingImage() {
        const formData = new FormData();
        imageFiles.forEach(image => {
            formData.append('images[]', image);
        });
        axios.post(`${window.$name}/api/directory/consumer/upload/listings/image/${ListingToken}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                toast.success(` ${response.data.message}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                dispatch(FetchListingDetail(ListingToken))
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(` ${error.response.data.message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                } else if (error.request) {
                    toast.error(` ${error.request}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                } else {
                    toast.error(` ${error.message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                }
            });
    }

    function handleUpdateImage() {
        const formData = new FormData();
        formData.append("image", imageFile[0]);
        console.log(imageFile)
        axios.post(`${window.$name}/api/directory/consumer/update/listings/image/${imageId}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
            .then((response) => {
                toast.success(` ${response.data.message}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                dispatch(FetchListingDetail(ListingToken))
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(` ${error.response.data.message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                } else if (error.request) {
                    toast.error(` ${error.request}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                } else {
                    toast.error(` ${error.message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                }
            });
    }

    function DeleteImage() {
        axios.post(`${window.$name}/api/directory/consumer/delete/listings/image/${imageId}`)
            .then((response) => {
                toast.success(` ${response.data.message}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000,
                });
                dispatch(FetchListingDetail(ListingToken))
            })
            .catch((error) => {
                if (error.response) {
                    toast.error(` ${error.response.data.message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                } else if (error.request) {
                    toast.error(` ${error.request}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                } else {
                    toast.error(` ${error.message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 5000,
                    });
                }
            });
    }

// @ts-ignore
    return (
        <React.Fragment>
            <Helmet>
                <title>{`listing action — ${theme.name}`}</title>
            </Helmet>
            <div className="container-fluid mt-5">
                <div className="row mt-4">
                    {listing_images.map((index) => (
                        <div className="col-md-3">
                            <img src={index.image_url} loading="lazy"
                                 cclassName="img-fluid border-0 " height="100" style={{
                                borderRadius: '1rem',
                                // height:'10rem',
                                // width:'14rem'
                            }}/>
                            <div className="text-center mt-3" style={{
                                // zIndex: '100',
                                // position: 'relative',
                                // marginBottom: '6rem',
                                // marginTop: '-5rem',
                                // width:'5rem',
                                textAlign: 'center',
                            }}>
                                <i className="fa fa-pencil-alt text-primary font-size-20"
                                   onClick={() => setImageId(index.id)}
                                   data-toggle="modal"
                                   data-target="#exampleModalCenter"></i>
                                &nbsp;  &nbsp;  &nbsp;  &nbsp;
                                <i className="fa fa-trash text-danger font-size-20"
                                   onClick={() => setImageId(index.id)}
                                   data-toggle="modal"
                                   data-target="#cancelModal"
                                ></i>
                                <br/><br/>
                            </div>
                        </div>
                    ))}
                </div>
                <div>
                    <h4>ADD NEW IMAGE</h4>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div>
                            <DropzoneArea
                                dropzoneText="Drag and Drop  Listing Images"
                                acceptedFiles={['image/*']}
                                filesLimit={6}
                                showAlerts={false}
                                onChange={files => handleImages(files)}
                            />
                            <div className="mt-4">
                                <button onClick={uploadListingImage}
                                        className="btn btn-sm btn-success rounded-pill">Upload
                                    images
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/*update listing image*/}
                <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog"
                     aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLongTitle">Update image</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <DropzoneArea
                                    dropzoneText="Drag and Drop  Listing Images"
                                    acceptedFiles={['image/*']}
                                    filesLimit={6}
                                    showAlerts={false}
                                    onChange={files => handleImages(files)}
                                />
                            </div>
                            <div className="modal-footer">
                                <button onClick={handleUpdateImage} data-dismiss="modal" type="button"
                                        className="btn btn-primary">Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/*delete image*/}
                <div className="modal fade" id="cancelModal" tabIndex="-1"
                     role="dialog"
                     aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                {/*<h5 className="modal-title" id="exampleModalLabel">Modal title</h5>*/}
                                <button type="button" className="close"
                                        data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body"> Are you sure want to delete this image</div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close
                                </button>
                                <button
                                    type="button"
                                    data-dismiss="modal"
                                    onClick={DeleteImage}
                                    className="btn btn-danger">Accept
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListingImage)
