// react
import React, { useState } from "react";
// third-party
import { Helmet } from "react-helmet-async";
import PageHeader from "../shared/PageHeader";
import theme from "../../data/theme";
import { connect } from "react-redux";
import { RegisterUSer } from "../../store/auth";
import useForm from "./useForm";
import "./validationcss.css";
import validate from "./validateForm";
import { Link } from "react-router-dom";
import RegisterNav from "./RegisterNav";
function AccountPageRegister(props) {
  const { RegisterUSer } = props;
  const { handleChange, handleSubmit, registrationState, errors } = useForm(
    submit,
    validate
  );

  function submit() {}
  return (
    <React.Fragment>
      <Helmet>
        <title>{`Register — ${theme.name}`}</title>
      </Helmet>
      <PageHeader />
      <div className="block">
        <div className="container">
          <div className="row d-flex justify-content-center ">
            <div className="col-md-6">
              <div className="card mb-lg-0 shadow-lg rounded">
                <div className="card-body">
                  <h6 className="card-title text-center"> Create Account</h6>
                  <RegisterNav/>
                </div>
                <div className="card-divider" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapDispatchToProps = {
  RegisterUSer,
};

export default connect(() => ({}), mapDispatchToProps)(AccountPageRegister);
